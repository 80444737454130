import moment from "moment";

export const boostCampaignHelper = {
  getMinMaxDateConstraint,
  getBoostBudgetMin,
  getBoostTotal,
};

function getMinMaxDateConstraint({
  loc,
  boosted_duration,
  locFormData,
  isSearch,
}) {
  let minStartDate = null;
  let maxStartDate = null;
  let minEndDate = null;
  let maxEndDate = null;

  const today = moment(loc?.start_date).isAfter(moment().add(1, "day"), "day")
    ? moment(loc?.start_date)
    : moment().add(1, "day");

  minStartDate = moment(today);
  maxStartDate = moment(loc.end_date).subtract(boosted_duration - 1, "day");

  minEndDate = moment(locFormData?.start_date || minStartDate).add(
    boosted_duration - 1,
    "day"
  );

  maxEndDate = moment(loc?.end_date);

  // If the selected channel is "Search", calculate valid end dates (last day of each month)
  let validEndDates = [];
  if (isSearch) {
    const campaignEndDate = moment(loc?.end_date); // End date of the campaign
    const boostStartDate = moment(locFormData?.start_date); // Boost's start date
    let currentMonth = boostStartDate.clone().startOf("month"); // Start from the first day of the month of the boost's start date

    // Loop through months until we reach or exceed the campaign's end date
    while (currentMonth.isBefore(campaignEndDate, "month")) {
      // Calculate the last day of the current month
      const monthEndDate = currentMonth.endOf("month");

      // Check if the difference between boost start date and the month's end is at least the boosted_duration
      if (monthEndDate.diff(boostStartDate, "days") >= boosted_duration) {
        validEndDates.push(monthEndDate.format("YYYY-MM-DD"));
      }

      currentMonth.add(1, "month"); // Move to the next month
    }

    // Ensure the campaign's end date is included if it hasn't already been added and it satisfies the duration constraint
    if (campaignEndDate.diff(boostStartDate, "days") >= boosted_duration) {
      if (!validEndDates.includes(campaignEndDate.format("YYYY-MM-DD"))) {
        validEndDates.push(campaignEndDate.format("YYYY-MM-DD"));
      }
    }
  }

  if (isSearch) {
    minEndDate = validEndDates.length > 0 ? validEndDates[0] : maxEndDate;
  }

  return {
    minStartDate,
    maxStartDate,
    minEndDate,
    maxEndDate:
      validEndDates.length > 0
        ? validEndDates
        : [maxEndDate.format("YYYY-MM-DD")], // Use valid end dates or fallback to maxEndDate
  };
}

// function getMinMaxDateConstraint({ loc, boosted_duration, locFormData }) {
//   let minStartDate = null;
//   let maxStartDate = null;
//   let minEndDate = null;
//   let maxEndDate = null;

//   const today = moment(loc?.start_date).isAfter(moment().add(1, "day"), "day")
//     ? moment(loc?.start_date)
//     : moment().add(1, "day");

//   minStartDate = moment(today);
//   maxStartDate = moment(loc.end_date).subtract(boosted_duration - 1, "day");

//   minEndDate = moment(locFormData?.start_date || minStartDate).add(
//     boosted_duration - 1,
//     "day"
//   );
//   maxEndDate = moment(loc?.end_date);

//   if (minEndDate.isAfter(maxEndDate)) {
//     minEndDate = maxEndDate;
//   }

//   return {
//     minStartDate,
//     maxStartDate,
//     minEndDate,
//     maxEndDate,
//   };
// }

function getBoostBudgetMin({ locFormData }) {
  const { start_date, end_date } = locFormData || {};
  const diff = moment(end_date).diff(moment(start_date), "month");
  return (diff || 1) * 100;
}

function getBoostTotal(selection = []) {
  return Math.round(
    selection?.reduce((t, l) => t + parseFloat(l?.boost_budget), 0)
  );
}
