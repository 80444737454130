import React from "react";
import { connect } from "react-redux";
import { brandActions } from "../../actions";
import { useHistory } from "react-router-dom";
import { userBrandsSelector } from "../../selectors/brandSelector";
import { loggedInUserSelector } from "../../selectors/userSelector";
import { getRedirectPath } from "../../helpers/localStore";

const SelectBrand = ({ brands, setGlobalBrand }) => {
  const history = useHistory();

  /**
   * @method handleOnClickBrand
   * @description handle on click to brand
   * @param {*} brand
   */
  const handleOnClickBrand = (brand) => {
    setGlobalBrand(brand);

    // Retrieve the stored redirect path from sessionStorage or default to "/dashboard"
    const redirectPath = getRedirectPath() || "/dashboard";
    if (history.location.pathname !== redirectPath) {
      history.replace(redirectPath);
    } else {
      history.replace(`${process.env.PUBLIC_URL}/dashboard`);
    }
  };

  return (
    <div className="select-brand-page pt-3">
      <div className="container select-brand">
        <div className="row align-items-stretch justify-content-center mb-3 brands">
          {brands &&
            brands.length &&
            brands.map((brand) => (
              <div
                onClick={() => handleOnClickBrand(brand)}
                className="col-lg-4 col-sm-6 my-2"
                key={brand.id}
              >
                <div className="brand d-flex-center text20M_SemiBold548E8D text-break">
                  {brand.brand_name}
                  {/* <ImageRP
                    src={brand.brand_logo}
                    className="img-fluid"
                    type="BRAND"
                    alt={brand.brand_name}
                  /> */}
                </div>
              </div>
            ))}
        </div>
        {/* <div className="row d-flex-center mt-5">
          <div className="col-md-12 text-center">
            <p>
              Do you have other locations with a different brand on
              Hyperlocology? You can easily link your other brand accounts to
              see everything in one place <LinkAccount />.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  brands: userBrandsSelector(state),
  user: loggedInUserSelector(state),
});

export default connect(mapStateToProps, {
  setGlobalBrand: brandActions.setGlobalBrand,
})(SelectBrand);
