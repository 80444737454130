import React from "react";
import { Grid, InputLabel, makeStyles, TextField } from "@material-ui/core";
import { teal } from "@material-ui/core/colors";

const useInputStyles = makeStyles((theme) => ({
  inputWrapper: {
    "& .MuiOutlinedInput-root": {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      "& fieldset": {
        border: "none",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: ".8rem",
      padding: "11px 11px",
    },
  },
  label: {
    fontWeight: 600,
    color: "#1B262C",
    fontSize: ".92rem",
  },
  formGroup: {
    marginBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    marginTop: theme.spacing(0.5),
  },
  textField: {
    width: "100%",
  },
  fileUploadButton: {
    textTransform: "none",
    "&.error": {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  },
  selectedFile: {
    fontSize: "0.875rem",
    color: "#1B262C",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "& .filename": {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
  datePickerOverrides: {
    "& .MuiPickersCalendarHeader-switchHeader": {
      color: teal.A700,
      "& .allowKeyboardControl": {
        color: teal.A700,
      },
    },
    "& .MuiInput-root": {
      border: `1px solid ${teal[500]}`,
      borderRadius: 4,
      backgroundColor: "#fff",
      height: "40px",
      padding: "0 10px",
      marginTop: "0",
      width: "90%",
      "& .MuiInputBase-input": {
        fontSize: ".8rem",
        padding: "8px 0",
        width: "100%",
      },
    },
    "& .MuiInput-underline": {
      "&:before, &:after": {
        content: "none",
      },
    },
  },
  input: {
    fontSize: ".8rem",
    padding: "11px 14px",
    height: "auto",
  },
  inputRoot: {
    "& .MuiOutlinedInput-root": {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      "& fieldset": {
        border: "none",
      },
    },
  },
  inputLabel: {
    transform: "none",
    position: "relative",
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1.75),
  },
}));

function NumberRangeMDInputFormik({
  label,
  field,
  form: { touched, errors, setFieldValue },
  ...rest
}) {
  const classes = useInputStyles();
  const fieldErrors = touched[field.name] && errors[field.name];

  const handleChange = (type) => (event) => {
    const value = event.target.value;
    setFieldValue(field.name, {
      ...field.value,
      [type]: value ? Number(value) : "",
    });
  };

  return (
    <div className={classes.formGroup}>
      <InputLabel
        shrink
        classes={{ root: classes.label }}
        margin="dense"
        disableAnimation
        required={rest?.required}
      >
        {label}
      </InputLabel>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="number"
            value={field.value?.min || ""}
            onChange={handleChange("min")}
            variant="outlined"
            error={Boolean(fieldErrors?.min)}
            helperText={fieldErrors?.min}
            className={classes.inputWrapper}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                input: classes.input,
              },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.inputLabel,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="number"
            value={field.value?.max || ""}
            onChange={handleChange("max")}
            variant="outlined"
            error={Boolean(fieldErrors?.max)}
            helperText={fieldErrors?.max}
            className={classes.inputWrapper}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                input: classes.input,
              },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.inputLabel,
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default NumberRangeMDInputFormik;
