import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import { Link, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

import {
  emailMDInputFormik,
  passwordMDInputFormik,
} from "../../helpers/formInputs";
import {
  isLoggedInSelector,
  isLoadingSelector,
} from "../../selectors/authSelectors";
import { authActions, brandActions } from "../../actions";
import { validateLogin } from "../../helpers/validations";
import LoadingButton from "../../components/loaders/LoadingButton";
import LeftView from "../../components/auth/LeftView";
import useQuery from "../../helpers/hooks/useQuery";
import { GlobalContext } from "../../helpers/contexts/contexts";
import {
  MAINTENANCE_MODE,
  MAINTENANCE_MODE_ALLOWED_IPS,
} from "../../config/appConfig";
import MainSEO from "../../components/seo/MainSEO";
import { reloadOtherTabs } from "../../helpers/authHelper";
import MaintenancePage from "../../pages/MaintenancePage";

const LoginForm = ({ login, isLoggedIn, isLoading, setGlobalBrand }) => {
  const { email, subscribe } = useQuery();
  const history = useHistory();
  const { userBrands, loggedInUserRole, loggedInUser } =
    useContext(GlobalContext);

  const [maintenance, setMaintenance] = useState(MAINTENANCE_MODE);
  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = (values, { setSubmitting }) => {
    // if (values.password === "HyperMaster@2021") {
    //   login(values);
    // } else {
    //   setMaintenance(true);
    // }
    login(values);
    setSubmitting(false);
  };

  useEffect(() => {
    async function allowIP() {
      const res = await Axios.get("https://api.ipify.org/");
      const ipAdd = res.data;

      if (MAINTENANCE_MODE_ALLOWED_IPS.includes(ipAdd)) {
        setMaintenance(false);
      }
    }
    if (maintenance) {
      allowIP();
    }
  }, [maintenance]);

  useEffect(() => {
    if (isLoggedIn) {
      const isFirstTimeLogin = loggedInUser.user_login_count === 0;
      const firstBrand = userBrands?.[0];
      if (
        (loggedInUserRole === "PR" || loggedInUserRole === "BU") &&
        userBrands
      ) {
        if (isFirstTimeLogin || subscribe) {
          // redirect if not Kumon Brand
          if (loggedInUserRole === "BU" && firstBrand.id !== 2) {
            return history.replace("/dashboard");
          }
          // else if (firstBrand?.user_all_locations_subscribed) {
          //   return history.replace("/dashboard");
          // }
          setGlobalBrand(firstBrand);
          if (firstBrand.has_active_subscription_page) {
            return history.replace("/subscription-packages");
          }
        }
      }

      return history.replace("/home");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      reloadOtherTabs();
    }
  }, [isLoggedIn]);

  if (maintenance) {
    return <MaintenancePage />;
  }

  return (
    <div className="row login-page">
      <MainSEO title="Login" />
      <div className="col-md-6 p-0">
        <LeftView />
      </div>
      <div className="col-md-6 d-flex flex-column justify-content-center px-md-5 py-1 py-sm-5">
        <div className="login-form">
          <Typography
            component="h2"
            variant="h4"
            className="fs-39"
            gutterBottom
          >
            Login
          </Typography>
          <div className="heading6 subheading">
            Welcome back! Please login to continue.
          </div>
          <Formik
            initialValues={{ email: email || "", password: "" }}
            onSubmit={onSubmitForm}
            validate={validateLogin}
          >
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} className="mt-1 mt-sm-4 mt-md-5">
                  <Field
                    name="email"
                    label="E-Mail"
                    component={emailMDInputFormik}
                    autoFocus
                  />
                  <Field
                    name="password"
                    label="Password"
                    component={passwordMDInputFormik}
                    autoComplete="new-password"
                    autoFocus={email ? true : false}
                  />
                  <div className="row py-0 pt-0 pt-sm-2">
                    <div className="col-xl-6 col-md-12 col-sm-6 mb-3">
                      <Link
                        to="/forgot-password"
                        className={`heading9 link link-w ${
                          isLoading || maintenance ? "disabled-link" : ""
                        }`}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="col-xl-6 col-md-12 col-sm-6 mb-3">
                      <div className="heading9 font-weight-normal d-flex align-items-center justify-content-end">
                        Your brand works with us?
                        <Link
                          to="/signup"
                          className={`heading9 ml-1 link link-w ${
                            isLoading || maintenance ? "disabled-link" : ""
                          }`}
                        >
                          Signup
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-3 mt-sm-5">
                    <LoadingButton
                      type="submit"
                      text="Login"
                      isLoading={isLoading}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        {/* <div className="heading9">
                    If you do not have an account? <Link to="/"><strong>Click here</strong></Link>
                </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedInSelector(state),
  isLoading: isLoadingSelector(state),
});

export default connect(mapStateToProps, {
  ...authActions,
  setGlobalBrand: brandActions.setGlobalBrand,
})(LoginForm);
