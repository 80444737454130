import { IconButton, withStyles, Tabs, Tab } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";

export const RpGridListButton = withStyles((theme) => ({
  label: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 3,
  },
}))(IconButton);

export const RpGridListButtonOrange = withStyles(() => ({
  label: {
    border: `1px solid ${orange["400"]}`,
    backgroundColor: orange["400"],
    color: "#fff",
    borderRadius: 3,
  },
}))(IconButton);

export const RpSwitchTabs = withStyles(() => ({
  root: {
    minWidth: "auto",
    minHeight: "auto",
    width: "auto",
    background: "#fff",
    borderRadius: 20,
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

export const RpSwitchTab = withStyles((theme) => ({
  root: {
    borderRadius: 50,
    minWidth: "auto",
    minHeight: "auto",
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    // border: `1px solid ${theme.palette.primary.main}`,
    margin: "0",
    padding: "2px 16px",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "none",
    opacity: 1,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}))(Tab);

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`campaigns-tabpanel-${index}`}
      aria-labelledby={`campaigns-tab-${index}`}
      {...other}
      className="rp-campaign-tab"
    >
      {/* {value === index && children} */}
      {children}
    </div>
  );
}
