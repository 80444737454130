import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  selectedBrandSelector,
  pageSetupSelector,
  brandIsLoadingSelector,
} from "../selectors/brandSelector";
import { brandActions } from "../actions";
import { GlobalContext, LoaderContext } from "../helpers/contexts/contexts";
import RpMUIDialog from "../components/modals/RpMUIModal";
import SelectBrand from "./participant/SelectBrand";
import { loggedInUserSelector } from "../selectors/userSelector";

import PageHeader from "../components/layouts/pageHeaders/PageHeader";
import PageLayout from "../components/layouts/page/PageLayout";

const Home = ({ isLoading, selectedBrand, pageSetup, getPageSetup }) => {
  const history = useHistory();
  const loader = useContext(LoaderContext);
  const { loggedInUser } = useContext(GlobalContext);

  const [openSelectBrand, setOpenSelectBrand] = useState(false);

  useEffect(() => {
    loader(isLoading);
  }, [isLoading, loader]);

  useEffect(() => {
    if (!selectedBrand) {
      setOpenSelectBrand(true);
    } else {
      setOpenSelectBrand(false);
      getPageSetup(selectedBrand?.id);
    }
  }, [selectedBrand, getPageSetup]);

  useEffect(() => {
    if (pageSetup) {
      setOpenSelectBrand(false);
    }
  }, [pageSetup]);

  useEffect(() => {
    if (loggedInUser && selectedBrand) {
      history.replace(`/dashboard`);
    }
  }, [loggedInUser, selectedBrand, history]);

  return (
    <>
      <PageLayout className="participant-dashboard-container mx-0 mx-sm-2">
        <PageHeader showName showLastUpdate={false} showButton={true} />
      </PageLayout>
      <RpMUIDialog
        open={openSelectBrand}
        onClose={() => setOpenSelectBrand(false)}
        heading="Select Brand"
        scroll="body"
        persists
      >
        <SelectBrand />
      </RpMUIDialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: loggedInUserSelector(state),
  selectedBrand: selectedBrandSelector(state),
  pageSetup: pageSetupSelector(state),
  isLoading: brandIsLoadingSelector(state),
});

export default connect(mapStateToProps, {
  getPageSetup: brandActions.getPageSetup,
})(Home);
