import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

import RpMUIModal from "../modals/RpMUIModal";

function PaymentInfoMsg({ makePayment }) {
  const [openInfo, setOpenInfo] = useState(true);
  if (!makePayment) return null;
  return (
    <RpMUIModal
      open={openInfo && makePayment ? true : false}
      onClose={() => setOpenInfo(false)}
      maxWidth="sm"
      style={{ zIndex: "9999999" }}
      persists
    >
      <div className="text-center">
        <WarningIcon className="text-warning" fontSize="large" />
      </div>
      <Typography variant="h6" className="text-center" gutterBottom>
        Important Payment Information
      </Typography>
      <Typography variant="body1" className="text-center" gutterBottom>
        Please do not press the back button or refresh this page while your
        payment is being processed. Doing so may interrupt the transaction and
        could lead to delays or errors.
      </Typography>
      <Typography variant="body1" className="text-center">
        Thank you for your understanding!
      </Typography>
      <div className="text-center mt-5 mb-3">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenInfo(false)}
          className="px-5"
        >
          OK
        </Button>
      </div>
    </RpMUIModal>
  );
}

export default PaymentInfoMsg;
