import { authConstants, brandConstants } from "../config/constants";
import { reloadOtherTabs } from "../helpers/authHelper";

const initialState = {
  userBrands: [],
  selectedBrand: null,
  pageSetup: null,
  error: null,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case brandConstants.GET_PAGE_SETUP_REQUEST:
      return { ...state, loading: true, error: false };
    case brandConstants.SET_GLOBAL_BRAND:
      reloadOtherTabs();
      return { ...state, selectedBrand: action.payload };
    case brandConstants.GET_PAGE_SETUP_SUCCESS:
      return { ...state, pageSetup: action.payload, loading: false };
    case brandConstants.GET_BRANDS_SUCCESS:
      return {
        ...state,
        userBrands: [...action.payload],
        selectedBrand: updateSelectedBrandData(state, action.payload),
        loading: false,
        error: null,
      };
    case authConstants.UPDATE_BRAND_DATA:
      return {
        ...state,
        userBrands: [...action.payload],
        loading: false,
        error: null,
      };
    case brandConstants.GET_PAGE_SETUP_FAIL:
      return { ...state, error: action.payload, loading: false };
    case authConstants.LOGOUT:
      return { ...initialState, selectedBrand: null, userBrands: [] };

    default:
      return state;
  }
};

function updateSelectedBrandData(state, brands) {
  const selectedBrand = state.selectedBrand;
  if (selectedBrand) {
    const updatedSelectedBrand = brands.find((b) => b.id === selectedBrand.id);
    const newSelectedBrand = updatedSelectedBrand
      ? { ...selectedBrand, ...updatedSelectedBrand }
      : selectedBrand;

    return newSelectedBrand;
  }
  return null;
}
