import React, { useMemo } from "react";
import NumberFormat from "react-number-format";
import { Icon, InputAdornment, TextField } from "@material-ui/core";

const MAX_SPEND_BUDGET = 1000000;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.floatValue);
      }}
      thousandSeparator
      // isNumericString
      decimalScale={2}
      // fixedDecimalScale={2}
      allowNegative={false}
      // prefix="$"
      isAllowed={({ floatValue }) =>
        floatValue > MAX_SPEND_BUDGET ? false : true
      }
    />
  );
}

export default function LocationBrandMatch({ contribution }) {
  const value = useMemo(
    () =>
      contribution || parseFloat(contribution) === 0
        ? parseFloat(contribution)
        : "",
    [contribution]
  );

  return (
    <div className="location-budget location-brand-match">
      <label>Brand Match</label>
      <TextField
        value={value}
        name="locationBudget"
        id="locationBudget"
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: (
            <InputAdornment position="start">
              <Icon component="img" src="/img/icons/money1.png" />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        size="small"
        autoComplete="off"
        placeholder="0.00"
        disabled={true}
      />
    </div>
  );
}
