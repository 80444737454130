import React, { useState, useCallback, useEffect } from "react";
import { useContext } from "react";
import { useParams, Redirect } from "react-router-dom";
import useSearchFilter from "../../../helpers/hooks/useSearchFilter";
import { campaignService } from "../../../services/campaignService";
import { toastr } from "react-redux-toastr";
import RpVirtualList from "../../../components/virtual/RpVirtualList";
import { Typography, Checkbox, Button, Grid, Paper } from "@material-ui/core";
import NoDataFound from "../../../components/NoDataFound";
import BackLink from "../../../components/BackLink";
import MuiSearchInput from "../../../components/muiCustom/search/MuiSearchInput";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import StatusChip from "../../../components/muiCustom/chip/StatusChip";
import Switch from "../../../components/toggle/CustomSwitch";
import PauseConfirmationModal from "./PauseConfirmationModal";
import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import { getStatusCode } from "../../../helpers/utils";
import PauseHeaderTooltip from "./PauseHeaderTooltip";

/**
 * CampaignPause Component
 * Allows users to manage campaign location statuses (pause/activate)
 */
const CampaignPause = () => {
  const { campaignId } = useParams();

  const history = useHistory();
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  // Component states
  const { loggedInUser, loggedInUserRole } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]); // Stores selected locations
  const [masterToggle, setMasterToggle] = useState(false); // Controls master toggle switch
  const [campaignData, setCampaignData] = useState({});
  const [locations, setLocations] = useState([]); // Current locations state
  // Destructure campaign details from first location if available

  const {
    campaign_name,
    pause_campaign_available,
    isPMax = false,
  } = campaignData || {};

  // Search functionality using custom hook
  const [searchTerm, handleSearchChange, filteredLocations] = useSearchFilter(
    isPMax ? [locations[0]] : locations,
    ["locationName"]
  );

  // Computed value for master checkbox
  const isMasterChecked =
    filteredLocations.length > 0 &&
    filteredLocations.length === selectedLocations.length;

  /**
   * Fetches campaign locations from API
   */
  const fetchLocations = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await campaignService.getJoinedLocations({
        campaign_id: campaignId || null,
        ...(loggedInUserRole === "PR" && {
          fk_participant_id: loggedInUser?.participantData?.id || 0,
        }),
      });

      if (response?.status === 200 && response?.data) {
        const locationData = response.data?.locationData;

        // Store original status with each location
        const locationsWithOriginalStatus =
          locationData?.map((location) => ({
            ...location,
            originalStatus: location.is_pause_campaign ? 1 : 0, // Convert to 0/1
            is_pause_campaign: location.is_pause_campaign ? 1 : 0, // Convert to 0/1
          })) || [];

        setLocations(locationsWithOriginalStatus);
        setCampaignData({
          ...response?.data,
          locationData: locationsWithOriginalStatus,
        });
      } else {
        throw new Error("Invalid location data received");
      }
    } catch (error) {
      toastr.error("Error", "Failed to fetch locations");
    } finally {
      setIsLoading(false);
    }
  }, [campaignId]);
  // Fetch locations on component mount
  useEffect(() => {
    if (campaignId) {
      fetchLocations();
    }
  }, [campaignId, fetchLocations]);

  useEffect(() => {
    loader(isLoading);
  }, [isLoading]);

  /**
   * Handles checkbox selection (both master and individual)
   * @param {string} key - 'master' for master checkbox, locationId for individual
   */
  const handleCheckboxChange = (key) => {
    if (key === "master") {
      // If master checkbox is checked, select all filtered locations
      // If unchecked, clear selection
      setSelectedLocations(isMasterChecked ? [] : filteredLocations);
      if (isMasterChecked) {
        setMasterToggle(false); // Only reset master toggle when unchecking all
      }
      return;
    }

    // Handle individual checkbox
    const location = locations.find(
      (loc) => loc.system_generated_location_id === key
    );
    if (!location) return;

    setSelectedLocations((prev) => {
      const isSelected = prev.some(
        (item) =>
          item.system_generated_location_id ===
          location.system_generated_location_id
      );

      const newSelection = isSelected
        ? prev.filter(
            (item) =>
              item.system_generated_location_id !==
              location.system_generated_location_id
          )
        : [...prev, location];

      // Only reset master toggle when deselecting last location
      if (newSelection.length === 0) {
        setMasterToggle(false);
      }

      return newSelection;
    });
  };

  /**
   * Checks if any location status has changed from its original state
   */
  const hasStatusChanges = locations.some(
    (location) => location.originalStatus !== location.is_pause_campaign
  );

  /**
   * Handles master toggle switch for selected locations
   */
  const handleMasterToggle = (event) => {
    const newStatus = event.target.checked;
    setMasterToggle(newStatus);

    setLocations((prevLocations) =>
      prevLocations.map((location) => {
        const isSelected = selectedLocations.some(
          (selected) =>
            selected.system_generated_location_id ===
            location.system_generated_location_id
        );

        if (!isSelected) return location;

        return {
          ...location,
          is_pause_campaign: newStatus ? 1 : 0,
        };
      })
    );
  };

  /**
   * Handles individual location toggle switch
   */
  const handleLocationToggle = (locationId) => {
    setLocations((prevLocations) =>
      prevLocations.map((location) =>
        location.system_generated_location_id === locationId
          ? {
              ...location,
              is_pause_campaign: location.is_pause_campaign ? 0 : 1,
            }
          : location
      )
    );
  };

  /**
   * Handles Continue button click
   * Shows confirmation modal if there are status changes
   */
  const handleContinue = () => {
    if (hasStatusChanges) {
      setShowConfirmModal(true);
    }
  };

  // Location row component to be rendered by RpVirtualList
  const LocationRow = ({ loc }) => (
    <>
      <Paper elevation={0} className="pt-3 pb-3 pb-lg-2 border-bottom ">
        <Grid container spacing={1}>
          <Grid item xs={2} sm={1} className="d-flex align-items-center">
            <Checkbox
              checked={selectedLocations.some(
                (item) =>
                  item.system_generated_location_id ===
                  loc.system_generated_location_id
              )}
              onChange={() =>
                handleCheckboxChange(loc.system_generated_location_id)
              }
              color="primary"
            />
          </Grid>

          <Grid
            item
            sm={4}
            md={3}
            xs={10}
            className="d-flex align-items-center justify-content-start"
          >
            <Typography className="fw-sb" title={loc.locationName}>
              {isPMax ? "All Locations" : loc.locationName}
            </Typography>
          </Grid>
          <Grid
            item
            sm={3}
            xs={6}
            className={`d-flex align-items-center justify-content-start ${isMobileOrTablet ? "p-3" : "p-0"}`}
          >
            <StatusChip
              label={loc.is_pause_campaign ? "Live" : "Paused"}
              // status={loc.is_pause_campaign ? "success" : "warn"}
              status={getStatusCode(loc.is_pause_campaign ? 1 : 4, "hold")}
            />
          </Grid>

          <Grid
            item
            sm={3}
            xs={6}
            className={`d-flex align-items-center ${isMobileOrTablet ? "justify-content-end" : "justify-content-start"}`}
          >
            <Switch
              checked={loc.is_pause_campaign}
              onChange={() =>
                handleLocationToggle(loc.system_generated_location_id)
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
  if (
    locations &&
    locations.length > 0 &&
    (!pause_campaign_available ||
      (loggedInUser && loggedInUser.fk_role_id === 2))
  ) {
    return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />;
  }

  return (
    <>
      {/* Redirect if pause not available */}
      {/* {!pause_campaign_available ||
        (!!loggedInUser && loggedInUser.fk_role_id === 2 && (
          <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
        ))} */}

      {/* Confirmation Modal */}
      <PauseConfirmationModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        locations={locations}
        initialLocations={locations}
        campaignId={campaignId}
        isPMax={isPMax}
      />

      {/* Main Content */}
      {campaignId && campaignId !== "undefined" && pause_campaign_available ? (
        <div className="campaign-pause p-4">
          <BackLink to="/dashboard/campaigns" />

          <Typography
            component="h1"
            variant="h5"
            gutterBottom
            className="mt-2 mr-1 pause-page-title"
          >
            {`Update Status : ${campaign_name ? campaign_name : ""} Campaign`}
          </Typography>
          <Typography gutterBottom>
            Select the locations to pause or set live for this campaign. Keep in
            mind, there will be a lag in performance when pausing and then
            re-starting due to a re-ramp period for Google, Facebook, and other
            ad platforms where consumers see your ads.
          </Typography>

          {!isPMax && (
            <Grid
              container
              spacing={1}
              className="mt-2"
              justifyContent="flex-start "
            >
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <MuiSearchInput
                  value={searchTerm}
                  onChange={handleSearchChange}
                  size="small"
                  type="search"
                  variant="outlined"
                  placeholder="Search..."
                  className="mt-3 mt-lg-1 mt-lx-0"
                />
              </Grid>
            </Grid>
          )}

          <Paper elevation={2} className="mt-4">
            <Paper elevation={0}>
              <Grid container spacing={1} className="table-heading py-1 pb-2">
                <Grid item xs={6} sm={1} className="d-flex align-items-center">
                  <Checkbox
                    color="primary"
                    checked={isMasterChecked}
                    onChange={() => handleCheckboxChange("master")}
                  />
                </Grid>

                <Grid
                  item
                  sm={4}
                  md={3}
                  className="d-none d-sm-flex align-items-center"
                >
                  <Typography className="fw-b mb-0 d-none d-sm-block">
                    Location
                  </Typography>
                  <PauseHeaderTooltip label="Location" />
                </Grid>

                <Grid
                  item
                  sm={3}
                  xs={6}
                  className="d-none d-sm-flex align-items-center"
                >
                  <Typography className="fw-b mb-0 d-none d-sm-block">
                    Status
                  </Typography>
                  <PauseHeaderTooltip label="Status" />
                </Grid>

                <Grid
                  item
                  sm={3}
                  xs={6}
                  className={`d-flex align-items-center ${isMobileOrTablet ? "justify-content-end" : "justify-content-start"}`}
                >
                  <Switch
                    checked={
                      selectedLocations.length === 0 ? false : masterToggle
                    }
                    onChange={handleMasterToggle}
                    disabled={selectedLocations.length === 0}
                  />
                  <PauseHeaderTooltip label="Toggle" />
                </Grid>

                <Grid item lg={4} md={4} className="d-none d-lg-block"></Grid>
              </Grid>
            </Paper>

            {filteredLocations && filteredLocations.length === 0 ? (
              <NoDataFound />
            ) : (
              <RpVirtualList
                items={filteredLocations}
                renderItem={(loc) => (
                  <LocationRow
                    key={loc.system_generated_location_id}
                    loc={loc}
                  />
                )}
              />
            )}
          </Paper>

          <div className="d-flex justify-content-end gap-3 mt-4">
            <Button
              variant="outlined"
              className="mr-3 px-5"
              size="large"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="px-5"
              disabled={!hasStatusChanges}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>
      ) : (
        !isLoading && <NoDataFound />
      )}
    </>
  );
};

export default CampaignPause;
