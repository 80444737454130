import React, { useState, useEffect, memo, useMemo } from "react";
import { Typography } from "@material-ui/core";
import { groupBy } from "lodash-es";

import {
  RpSwitchTab,
  RpSwitchTabs,
  TabPanel,
} from "../../../helpers/mui-custom-components";
import PackageCard from "./PackageCard";
import ContactToWantToSpendMore from "./ContactToWantToSpendMore";
import RpAlert from "../../../components/alerts/RpAlert";

export default memo(function SubscriptionPackages({
  selectedBrand,
  packages,
  pageInfo,
}) {
  const [selectedTab, setSelectedTab] = useState("6");

  useEffect(() => {
    setSelectedTab(packages?.[0]?.period);
  }, [packages]);

  const packageTypes = useMemo(() => {
    const groupByPeriod = groupBy(packages, "period");
    const types = Object.keys(groupByPeriod);
    return {
      types,
      plans: groupByPeriod,
    };
  }, [packages]);

  const renderPackages = () => {
    return (
      <>
        <div className="d-flex justify-content-center my-5">
          <RpSwitchTabs
            value={selectedTab}
            onChange={(e, v) => {
              setSelectedTab(v);
            }}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            centered
          >
            {packageTypes?.types?.map((t) => (
              <RpSwitchTab label={`${t} Months`} value={t} key={t} />
            ))}
          </RpSwitchTabs>
        </div>

        {packageTypes.types.map((t) => (
          <TabPanel value={selectedTab} index={t} key={t}>
            <div className={`row justify-content-center align-items-start`}>
              {packageTypes.plans[t]?.map((plan) => (
                <div className="col-md-4 mb-5" key={plan.id}>
                  <PackageCard plan={plan} />
                </div>
              ))}
            </div>
          </TabPanel>
        ))}

        {pageInfo?.note && (
          <div className="contact">
            {selectedBrand.id === 2 ? (
              <>
                Do you require a plan outside of the options above?{" "}
                <ContactToWantToSpendMore />
              </>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: pageInfo?.note,
                }}
              ></div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="subscription-packages">
      <div className="">
        <Typography
          component="h2"
          variant="h4"
          color="primary"
          gutterBottom
          className="main-heading"
        >
          {pageInfo?.page_title ||
            "Let's get your custom local advertising launched!"}
        </Typography>

        <Typography
          component="h3"
          variant="h5"
          className="sub-heading"
          gutterBottom
        >
          1. {pageInfo?.package_title || "Learn about our plans"}
        </Typography>
        <div
          className="page-description"
          dangerouslySetInnerHTML={{ __html: pageInfo?.description }}
        ></div>
        {packages.length === 0 ? (
          <div className="d-flex justify-content-center my-5">
            <RpAlert severity="info">There are no plans available.</RpAlert>
          </div>
        ) : (
          renderPackages()
        )}
      </div>
    </div>
  );
});
