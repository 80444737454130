import { Typography } from "@material-ui/core";
import SubscribedLocationCard from "./SubscribedLocationCard";
export default function SubscribedLocationList({
  locations,
  onSelectPackage,
  onConfirm,
  fetchLocations,
  loadMoreLocations,
  totalLocationCount,
  subscriptionDescription,
}) {
  return (
    <div className="user-location-list">
      <div className="locations">
        {locations.map((location) => (
          <SubscribedLocationCard
            location={location}
            key={location.fk_location_id}
            onSelectPackage={onSelectPackage}
            onConfirm={onConfirm}
            fetchLocations={fetchLocations}
            subscriptionDescription={subscriptionDescription}
          />
        ))}
      </div>
      {totalLocationCount ? (
        <Typography className="fs-10" gutterBottom>
          Locations {locations?.length} of {totalLocationCount}
        </Typography>
      ) : null}
      {loadMoreLocations()}
    </div>
  );
}
