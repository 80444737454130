import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export default styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;
  color: #fff;
  padding-left: 240px;

  @media (max-width: 1279px) {
    padding-left: 0px;
  }

  ${(props) =>
    props.filled
      ? css`
          background-color: rgba(255, 255, 255, 1);
        `
      : css`
          background-color: rgba(0, 0, 0, 0.5);
        `}
  ${(props) =>
    props.show === true
      ? css`
          animation: ${fadeIn} 0.3s ease;
          display: flex;
        `
      : css`
          animation: ${fadeOut} 0.3s ease;
          display: none;
        `}
`;
