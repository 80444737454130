import React, { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { stripeMicroService } from "../../../services/stripe-microservice/stripeMicroService";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const EmbeddedCheckoutForm = ({ data, onError, api }) => {
  const fetchClientSecret = useCallback(async () => {
    try {
      const res = api
        ? await api(data)
        : await stripeMicroService.subscriptionPayment(data);

      if (!res?.data?.result?.client_secret) {
        throw new Error("Invalid response format");
      }

      return res.data.result.client_secret;
    } catch (err) {
      if (onError && typeof onError === "function") {
        onError(err);
      }
      return null;
    }
  }, [data, onError, api]);

  const options = { fetchClientSecret };

  return fetchClientSecret ? (
    <div id="checkout" className="w-100">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  ) : null;
};

export default EmbeddedCheckoutForm;
