import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function RpPopover({
  children,
  popContent,
  popTitle = "",
  trigger = ["hover", "focus"],
}) {
  const popover = popContent ? (
    <Popover id="rp-popover-basic">
      {popTitle && <Popover.Title as="h3">{popTitle}</Popover.Title>}
      <Popover.Content>{popContent}</Popover.Content>
    </Popover>
  ) : null;

  return (
    <OverlayTrigger trigger={trigger} placement="top" overlay={popover}>
      {children}
    </OverlayTrigger>
  );
}
