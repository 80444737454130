import React, { useState, useEffect } from "react";
import moment from "moment";
import LearnMoreButton from "../../../../components/muiCustom/buttons/LearnMoreButton";
import { Icon } from "@material-ui/core";
import { campaignFormatHelper, getFormatDate } from "../../../../helpers/utils";
import PostcardJoinNowButton from "./PostcardJoinNowButton";
import ImageRP from "../../../../components/ImageRP";

function GridViewCampaignCard({ campaign, renderArchivedJoinNowButton }) {
  const { status } = campaign || {};
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    if (campaign && campaign.campaign_type === "LIMITED") {
      const currentDate = moment();
      const parsedDate = moment(campaign.join_by_date);

      const duration = moment.duration(parsedDate.diff(currentDate));
      const hoursRemaining = duration.hours();
      const daysRemaining = parsedDate.diff(currentDate, "days");

      const formattedTimeRemaining = `${daysRemaining}d, ${hoursRemaining}h`;
      setTimeRemaining(formattedTimeRemaining);
    }
  }, [campaign]);

  return (
    <div className={`card campaign-card h-100`}>
      <div className="card-body p-3 pt-4 position-relative">
        {campaign?.campaign_type === "LIMITED" ? (
          <div className="time-left">
            <div className="time">{timeRemaining}</div>
            <div>Left to Join</div>
          </div>
        ) : null}

        <div className="row">
          <div className="col">
            <div className="detail">
              <div className="title">Campaign Name</div>
              <div
                className={`title-val  campaign-name ${
                  status === 1 || status === 3 ? "main" : ""
                }`}
              >
                {campaignFormatHelper(campaign).name()}
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12">
            <ImageRP
              src={campaign?.banner_image[0]?.attachment}
              type="CREATIVE"
              alt=""
              className="img-fluid rounded campaign-image"
              style={{ maxHeight: 150, objectFit: "contain" }}
            />
          </div>
        </div>
        {campaign?.campaign_type === "LIMITED" ? (
          <div className="row">
            <div className="col-6">
              <div className="title">Join By Date</div>
              <div className="title-val">
                {/* {joinByDateFormatHelper(campaign).joinByDate("MMM DD, YYYY")} */}
                {campaign?.join_by_date ? (
                  <>
                    <Icon
                      component="img"
                      src="/img/icons/calendar.png"
                      fontSize="small"
                    />
                    {getFormatDate(campaign?.join_by_date)}
                  </>
                ) : (
                  "N/A"
                )}
              </div>
            </div>
            <div className="col-6 text-right">
              <div className="title">Promotion Date</div>
              <div className="title-val">
                {campaignFormatHelper(campaign).startDate("MMM DD, YYYY")}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="card-footer">
        <div className="row">
          <div className="col-7 pr-1">
            {renderArchivedJoinNowButton ? (
              renderArchivedJoinNowButton()
            ) : (
              <PostcardJoinNowButton campaign={campaign} />
            )}
          </div>
          <div className="col-5 pl-0 text-right">
            <LearnMoreButton
              to={{
                pathname: `${process.env.PUBLIC_URL}/dashboard/learn-more/postcard-campaign/${campaign?.id}`,
                state: { campaignId: campaign?.id, from: "/dashboard" },
              }}
              size="medium"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GridViewCampaignCard;
