import { useState, useCallback } from "react";
import MUIConfirmDialog from "../../components/modals/MUIConfirmDialog";

const useConfirmDialog = () => {
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    title: "",
    description: "",
    dialogButtonConfig: {},
  });

  const showConfirmDialog = useCallback(
    (title, description, dialogButtonConfig) => {
      setAlertConfig({
        show: true,
        title,
        description,
        dialogButtonConfig,
      });
    },
    []
  );

  const closeConfirmDialog = useCallback(() => {
    setAlertConfig({
      show: false,
      title: "",
      description: "",
      dialogButtonConfig: {},
    });
  }, []);

  // Render the dialog when alertConfig.show is true
  const MUIConfirmDialogBox = useCallback(
    () =>
      alertConfig.show ? (
        <MUIConfirmDialog
          show={alertConfig.show}
          onClose={closeConfirmDialog}
          title={alertConfig.title}
          description={alertConfig.description}
          dialogButtonConfig={alertConfig.dialogButtonConfig}
        />
      ) : null,
    [alertConfig, closeConfirmDialog]
  );

  return {
    showConfirmDialog,
    closeConfirmDialog: closeConfirmDialog,
    MUIConfirmDialogBox: MUIConfirmDialogBox, // Provide the AlertDialog component to be used in the component tree
  };
};

export default useConfirmDialog;
