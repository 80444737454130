import { brandConstants } from "../config/constants";

export const brandActions = {
  setGlobalBrand,
  getPageSetup,
  getBrands,
};

function setGlobalBrand(brand) {
  return {
    type: brandConstants.SET_GLOBAL_BRAND,
    payload: brand,
  };
}

function getPageSetup(brandId) {
  return {
    type: brandConstants.GET_PAGE_SETUP_REQUEST,
    payload: brandId,
  };
}

function getBrands(data) {
  return {
    type: brandConstants.GET_BRANDS_REQUEST,
    payload: data,
  };
}
