import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, withFormik } from "formik";
import {
  IconButton,
  Icon,
  Typography,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import * as Yup from "yup";
import { toastr } from "react-redux-toastr";
import { CircularProgress } from "@material-ui/core";

import { campaignService } from "../../services";
import { textMDInputFormik } from "../../helpers/formInputs";
import FormikMUIAutocompleteSelectInput from "../../components/muiCustom/select/FormikMUIAutocompleteSelectInput";
import RpMuiCheckboxGroup from "../../components/muiCustom/checkbox/RpMuiCheckboxGroup";
import RpMuiFromikSlider from "../../components/muiCustom/sliders/RpMuiFromikSlider";
import {
  ADVANCED_SETTING_Q_TYPE,
  QUESTION_TYPE_RADIUS,
} from "../../config/constants";
import { logger } from "../../helpers/loggerHelper";
import { RpSweetAlert } from "../../components/alerts/RpSweetAlert";

const SettingForm = ({
  onClose,
  onFillSettingsData,
  productList,
  settingsData,
  locationName,
  isModal = true,
  handleSubmit,
  setFieldValue,
  values,
  errors,
  handleOnChangeAdvancedSettingError,
  locId,
  locationRadius,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (handleOnChangeAdvancedSettingError) {
      handleOnChangeAdvancedSettingError({ locId, errors });
    }
  }, [errors, handleOnChangeAdvancedSettingError, locId]);

  useEffect(() => {
    if (!isModal) {
      // if (Object.keys(errors)?.length) {
      //   return;
      // }
      // to auto save when advanced setting is required
      const answers = [];
      settingsData.forEach((setting) => {
        const { question_title, question_type, q_id } = setting;
        const answer = values[q_id];

        let questionTitle = question_title;
        // update the question title for radius
        if (question_type === ADVANCED_SETTING_Q_TYPE.RADIUS) {
          questionTitle = questionTitle.replace(
            "{location_radius}",
            locationRadius || QUESTION_TYPE_RADIUS.MIN
          );
        }

        if (
          !(answer === "" || (Array.isArray(answer) && answer.length === 0))
        ) {
          answers.push({
            answer,
            question_title: questionTitle,
            question_type,
            q_id,
          });
        }
      });
      onFillSettingsData(answers);
    }
  }, [values, settingsData, onFillSettingsData, isModal, errors]);

  const productOptions = productList.map((product) => {
    return {
      label: product.product_name,
      value: product.product_name,
    };
  });

  const validateFileSize = (file, maxSizeInMegabytes) => {
    const maxSizeInBytes = maxSizeInMegabytes * 1024 * 1024;
    return file.size <= maxSizeInBytes;
  };

  const handleOnChange = async (e, setFieldValue, values, q_id) => {
    const files = e.target.files;
    setLoading(true);

    try {
      const maxSizeInBytes = settingsData.find(
        (setting) => setting?.q_id === q_id
      )?.file_size;

      if (!validateFileSize(files[0], maxSizeInBytes)) {
        toastr.error(
          "Error",
          `File size exceeds the maximum allowed size (${maxSizeInBytes} MB)`
        );
        return;
      }

      const fd = new FormData();
      fd.append("attachment", files[0]);

      const res = await campaignService.advanceSettingAttachmentUpload(fd);

      if (res.status === 200) {
        const data = res.data;
        const existingValue = values[q_id];
        const isAttachmentField = settingsData.some(
          (setting) => setting?.q_id === q_id
        );

        if (isAttachmentField) {
          const updatedValue = [
            ...(Array.isArray(existingValue) ? existingValue : []),
            { fileName: data?.fileName, fileURL: data?.URL },
          ];

          setFieldValue(q_id, updatedValue);
        } else {
          setFieldValue(q_id, data?.fileName);
        }
        e.target.value = null;
        toastr.success("Success", `Attachment Uploaded`);
      } else {
        toastr.error("Error", `Error while Uploading`);
      }
    } catch (err) {
      toastr.error("Error", `Error while Uploading`);
    } finally {
      setLoading(false);
    }
  };

  const renderInputs = (question, index, setFieldValue, values) => {
    const { question_type, q_id } = question;
    const multipleOptions = question["another-repeater"];
    const uploadedFiles = values[q_id] || [];
    const hideFileInput =
      question_type === ADVANCED_SETTING_Q_TYPE.ATTACHMENT &&
      uploadedFiles.length === parseInt(question.files_quantity);
    const fileSize =
      question_type === ADVANCED_SETTING_Q_TYPE.ATTACHMENT &&
      question.file_size;
    const handleRemoveFile = (fileIndex, setFieldValue, q_id) => {
      const updatedFiles = [...values[q_id]];
      updatedFiles.splice(fileIndex, 1);
      setFieldValue(q_id, updatedFiles);
    };

    switch (question_type) {
      case ADVANCED_SETTING_Q_TYPE.SHORT_ANSWER:
        return (
          <Field
            type="text"
            name={q_id}
            component={textMDInputFormik}
            placeholder="Enter Short Answer"
          />
        );
      case ADVANCED_SETTING_Q_TYPE.LONG_ANSWER:
        return (
          <Field
            type="text"
            name={q_id}
            component={textMDInputFormik}
            placeholder="Enter Paragraph"
            minRows={2}
            maxRows={4}
            multiline
          />
        );
      case ADVANCED_SETTING_Q_TYPE.PRODUCT:
        return (
          <Field
            as="select"
            name={q_id}
            component={FormikMUIAutocompleteSelectInput}
            options={productOptions}
            multiple
            placeholder="Select"
          ></Field>
        );
      case ADVANCED_SETTING_Q_TYPE.ATTACHMENT:
        // eslint-disable-next-line no-case-declarations
        const uploadedFiles = values[q_id] || [];
        return (
          <>
            <Typography variant="caption" color="primary">
              Maximum file size - {fileSize}MB
            </Typography>
            {!hideFileInput && (
              <>
                <TextField
                  type="file"
                  name={q_id}
                  onChange={(e) =>
                    handleOnChange(e, setFieldValue, values, q_id)
                  }
                  multiple
                  value={undefined}
                  variant="outlined"
                />
                <ErrorMessage
                  component={FormHelperText}
                  name={q_id}
                  className="text-danger"
                />
              </>
            )}
            {loading && (
              <>
                <CircularProgress size={10} className="mr-2" />
                <span style={{ fontSize: "10px" }}>
                  Uploading Attachment...
                </span>
              </>
            )}
            {uploadedFiles.length > 0 && (
              <div className="file-name">
                {uploadedFiles.map((file, fileIndex) => (
                  <div key={fileIndex} className="d-flex align-items-center">
                    <span>{file.fileName}</span>
                    <IconButton
                      onClick={() =>
                        handleRemoveFile(fileIndex, setFieldValue, q_id)
                      }
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                    <br />
                  </div>
                ))}
              </div>
            )}
          </>
        );
      case ADVANCED_SETTING_Q_TYPE.MULTI_SELECTOR:
        return (
          <RpMuiCheckboxGroup
            name={q_id}
            options={multipleOptions?.map((o) => o.option_title) || []}
          />
        );
      case ADVANCED_SETTING_Q_TYPE.RADIUS:
        return (
          <div className="pr-5 pl-3 mt-4">
            <RpMuiFromikSlider name={q_id} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="advance-settings">
      {isModal && (
        <>
          <div className="d-flex justify-content-between pl-3 pr-3">
            <Typography component="h3" variant="h6" className="heading">
              Advanced Settings
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              color="inherit"
              className="p-0"
            >
              <Icon fontSize="small">highlight_off</Icon>
            </IconButton>
          </div>
          <span>
            <Typography
              component="h4"
              variant="body1"
              style={{
                color: "rgb(35 35 36);",
                marginLeft: 16,
                fontWeight: 600,
                fontSize: 14,
              }}
              className="mt-3"
            >
              <Icon
                component="img"
                src="/img/icons/location.png"
                className="mr-2"
              />
              {locationName}
            </Typography>
          </span>
        </>
      )}
      <div className="card p-lg-3">
        <div>
          {settingsData &&
            settingsData.map((setting, index) => {
              const { question_type, question_title, required } = setting;
              let questionTitle = question_title;
              if (question_type === ADVANCED_SETTING_Q_TYPE.RADIUS) {
                questionTitle = questionTitle.replace(
                  "{location_radius}",
                  locationRadius || QUESTION_TYPE_RADIUS.MIN
                );
              }
              return (
                <div key={index} className={`question-card ${question_type}`}>
                  <Typography
                    component="p"
                    variant="body2"
                    className="font-weight-bold question_title"
                    gutterBottom
                  >
                    {questionTitle}
                    {Number(required) ? (
                      <strong className="error font-weight-bold">*</strong>
                    ) : (
                      ""
                    )}
                  </Typography>
                  {renderInputs(setting, index, setFieldValue, values)}
                </div>
              );
            })}
          {isModal === true && (
            <div className="row pt-4">
              <div className="col-12 d-flex justify-content-end">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="button primary small float-right"
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AdvanceSettingForm = withFormik({
  mapPropsToValues: ({ settingsData, initialAnswers, locationRadius }) => {
    logger.info("locationRadius", locationRadius);
    const initialVal = {};
    if (!settingsData?.length) {
      return initialVal;
    }

    settingsData.forEach((setting) => {
      const savedQuestion = initialAnswers?.find(
        (a) => a.q_id === setting.q_id
      );

      const fieldName = setting?.q_id;
      const question_type = setting?.question_type;
      if (setting.question_type === ADVANCED_SETTING_Q_TYPE.MULTI_SELECTOR) {
        initialVal[fieldName] =
          (savedQuestion?.question_type === question_type &&
            savedQuestion?.answer) ||
          [];
      } else if (setting.question_type === ADVANCED_SETTING_Q_TYPE.RADIUS) {
        initialVal[fieldName] =
          (savedQuestion?.question_type === question_type &&
            savedQuestion?.answer) ||
          locationRadius ||
          QUESTION_TYPE_RADIUS.MIN;
      } else {
        initialVal[fieldName] =
          (savedQuestion?.question_type === question_type &&
            savedQuestion?.answer) ||
          "";
      }
    });
    return initialVal;
  },
  validateOnChange: true,
  validateOnMount: true,
  validationSchema: ({ settingsData }) => {
    return Yup.object({
      ...settingsData.reduce((schema, setting) => {
        let fieldValidation;

        const fieldName = setting?.q_id;

        if (setting.question_type === ADVANCED_SETTING_Q_TYPE.MULTI_SELECTOR) {
          fieldValidation = Yup.array().max(
            setting?.max_option_selection || 1,
            `Max ${
              setting?.max_option_selection || 1
            } option(s) can be selected`
          );
        } else if (setting.question_type === ADVANCED_SETTING_Q_TYPE.PRODUCT) {
          fieldValidation = Yup.array().max(
            3,
            `Max 3 option(s) can be selected`
          );
        } else if (
          setting.question_type === ADVANCED_SETTING_Q_TYPE.ATTACHMENT
        ) {
          fieldValidation = Yup.array();
        } else if (
          setting.question_type === ADVANCED_SETTING_Q_TYPE.SHORT_ANSWER
        ) {
          fieldValidation = Yup.string()
            .trim()
            .max(150, "Maximum 150 characters allowed");
        } else if (
          setting.question_type === ADVANCED_SETTING_Q_TYPE.LONG_ANSWER
        ) {
          fieldValidation = Yup.string()
            .trim()
            .max(1000, "Maximum 1000 characters allowed");
        } else {
          fieldValidation = Yup.string();
        }

        if (setting.required === "1") {
          fieldValidation = fieldValidation.required("This is required");
          if (
            setting.question_type === ADVANCED_SETTING_Q_TYPE.MULTI_SELECTOR ||
            setting.question_type === ADVANCED_SETTING_Q_TYPE.PRODUCT ||
            setting.question_type === ADVANCED_SETTING_Q_TYPE.ATTACHMENT
          ) {
            fieldValidation = fieldValidation.min(1, "This is required");
          }
        }

        schema[fieldName] = fieldValidation;
        return schema;
      }, {}),
    });
  },

  handleSubmit: (values, { props }) => {
    const { settingsData, onFillSettingsData, onClose, locationRadius } = props;

    const answers = [];
    settingsData.forEach((setting) => {
      const { question_title, question_type, q_id } = setting;
      const answer = values[q_id];

      let questionTitle = question_title;
      // update the question title for radius
      if (question_type === ADVANCED_SETTING_Q_TYPE.RADIUS) {
        questionTitle = questionTitle.replace(
          "{location_radius}",
          locationRadius || QUESTION_TYPE_RADIUS.MIN
        );
      }

      if (!(answer === "" || (Array.isArray(answer) && answer.length === 0))) {
        answers.push({
          answer,
          question_title: questionTitle,
          question_type,
          q_id,
        });
      }
    });

    onFillSettingsData(answers);

    RpSweetAlert.fire({
      imageUrl: "/img/ads.png",
      imageWidth: "100",
      html: (
        <p
          className="fw-sb fs-20"
          style={{ lineHeight: 1.8, color: "#002938" }}
        >
          Got it! Your settings for this location have been saved.
        </p>
      ),
      width: "40em",
      showConfirmButton: false,
      showCancelButton: false,
      timer: 4000,
      customClass: {
        popup: "shadow-lg",
      },
    });

    onClose();
  },

  enableReinitialize: true,
  displayName: "advanced-setting-form",
})(SettingForm);

export default AdvanceSettingForm;
