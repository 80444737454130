import React from "react";
import { Tab, Tabs, withStyles } from "@material-ui/core";

const RpSwitchTabs = withStyles(() => ({
  root: {
    minWidth: "auto",
    minHeight: "auto",
    width: "auto",
    background: "#fff",
    borderRadius: 20,
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const RpSwitchTab = withStyles((theme) => ({
  root: {
    borderRadius: 50,
    minWidth: "auto",
    minHeight: "auto",
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    // border: `1px solid ${theme.palette.primary.main}`,
    margin: "0",
    padding: "2px 16px",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "none",
    opacity: 1,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}))(Tab);

const RpSwitchTabs1 = withStyles(() => ({
  root: {
    minWidth: "auto",
    minHeight: "auto",
    width: "auto",
    background: "#fff",
    borderRadius: 5,
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const RpSwitchTab1 = withStyles((theme) => ({
  root: {
    borderRadius: 5,
    minWidth: "auto",
    minHeight: "auto",
    background: "#D8D8D8",
    color: theme.palette.primary.dark,
    // border: `1px solid ${theme.palette.primary.main}`,
    margin: -1,
    padding: "10px 1.4rem",
    fontSize: 12,
    fontWeight: 600,
    textTransform: "none",
    opacity: 1,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    zIndex: 1,
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
}))(Tab);

function SwitchTabs({
  tabs = [],
  selectedTab,
  onChange,
  tabProps = {},
  styleType = 1,
  ...rest
}) {
  const handleTabChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };
  if (styleType === 1) {
    return (
      <RpSwitchTabs1
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="off"
        {...rest}
      >
        {tabs.map((tab) => {
          // eslint-disable-next-line react/jsx-key
          return <RpSwitchTab1 label={tab} {...tabProps} />;
        })}
      </RpSwitchTabs1>
    );
  }
  return (
    <RpSwitchTabs
      value={selectedTab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="off"
      {...rest}
    >
      {tabs.map((tab) => {
        // eslint-disable-next-line react/jsx-key
        return <RpSwitchTab label={tab} {...tabProps} />;
      })}
    </RpSwitchTabs>
  );
}

export default SwitchTabs;
