import { campaignConstants } from "../config/constants";

export const campaignActions = {
  joinCampaign,
  resetIsSavedDraftSuccess,
  getCampaignReport,
  joinPostCardCampaign,
  setSaveDraftSuccess,
  resetError,
};

function joinCampaign(data) {
  return {
    type: campaignConstants.SAVE_DRAFT_REQUEST,
    payload: data,
  };
}

function joinPostCardCampaign(data) {
  return {
    type: campaignConstants.SAVE_DRAFT_POSTCARD_REQUEST,
    payload: data,
  };
}

function resetIsSavedDraftSuccess() {
  return {
    type: campaignConstants.RESET_IS_DRAFT_SUCCESS,
  };
}

function getCampaignReport(data) {
  return {
    type: campaignConstants.GET_CAMPAIGN_REPORT_REQUEST,
    payload: data,
  };
}

function setSaveDraftSuccess(data) {
  return {
    type: campaignConstants.SAVE_DRAFT_SUCCESS,
    payload: data,
  };
}

function resetError() {
  return {
    type: campaignConstants.RESET_ERROR,
    payload: null,
  };
}
