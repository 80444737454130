import React, { memo, useMemo } from "react";
import { Button } from "@material-ui/core";
// import { STATIC_IMAGES } from "../../../../helpers/staticImages";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import Location from "../subscribedLocations/Location";
import { LocSubsStatus } from "../../../../config/constants";
import RpVirtualList from "../../../../components/virtual/RpVirtualList";

/**
 * @method LocationNotActive
 * @description Render single location. US (Unsubscribed)
 * @param {*} param0
 * @returns
 */
// const LocationNotActive = ({
//   name = "",
//   type = LocSubsStatus.UNSUBSCRIBED,
//   is30DaysLeftToJoinCampaign,
// }) => {
//   const renderMessage = () => {
//     let msg = "Unsubscribed";
//     let lessThan30Msg;
//     if (type === LocSubsStatus.FALLOUT) {
//       msg = "Campaign Dates are Outside Subscription Period";
//       if (is30DaysLeftToJoinCampaign) {
//         lessThan30Msg =
//           "You have less than a month left in your subscription for this location, please renew in order to join this campaign";
//       }
//     }
//     return (
//       <span>
//         {msg}
//         {lessThan30Msg && (
//           <>
//             <br />
//             <small style={{ fontSize: "inherit" }}>{lessThan30Msg}</small>
//           </>
//         )}
//       </span>
//     );
//   };
//   return (
//     <div className="card">
//       <div className="card-body">
//         <div className="row">
//           <div className="col-md-6 mb-2 mb-md-0 location-name">{name}</div>
//           <div className="col-md-6 message d-flex align-items-center justify-content-md-end">
//             <div className="fallOutside">
//               <Icon
//                 component="img"
//                 src={
//                   type === LocSubsStatus.UNSUBSCRIBED
//                     ? STATIC_IMAGES.infoRed
//                     : STATIC_IMAGES.infoYellow
//                 }
//                 sizes="small"
//                 className="mr-2"
//               />
//               {renderMessage()}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

const InActiveLocations = ({
  fallOutSideLocations = [],
  unSubscribedLocations = [],
  selectedBrand,
  campaign,
  values,
  setFieldValue,
  setFieldError,
  expanded,
  setExpanded,
}) => {
  const isThereNoInactiveLocations = useMemo(() => {
    return [...fallOutSideLocations, ...unSubscribedLocations].length === 0;
  }, [fallOutSideLocations, unSubscribedLocations]);

  const renderFallOutSideLocations = () => {
    return (
      <RpVirtualList
        items={fallOutSideLocations}
        renderItem={(loc) => {
          const locName = `${loc.location_name}`
            .concat(`, ${loc.state}`)
            .concat(`, ${loc.brand_region}`);
          return (
            <Location
              subscriptionStatusType={LocSubsStatus.FALLOUT}
              name={locName}
              is30DaysLeftToJoinCampaign={loc.is30DaysLeftToJoinCampaign}
              loc={loc}
              locFormData={values[loc.id]}
              key={loc.id}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              expanded={expanded}
              setExpanded={setExpanded}
              campaign={campaign}
              isUnsubscribed={true}
            />
          );
        }}
      />
    );
  };

  const renderUnsubscribedLocations = () => {
    return (
      <RpVirtualList
        items={unSubscribedLocations}
        renderItem={(loc) => {
          return (
            <Location
              subscriptionStatusType={LocSubsStatus.UNSUBSCRIBED}
              loc={loc}
              locFormData={values[loc.id]}
              key={loc.id}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              expanded={expanded}
              setExpanded={setExpanded}
              campaign={campaign}
              isUnsubscribed={true}
            />
          );
        }}
      />
    );
  };

  if (isThereNoInactiveLocations) {
    return (
      <>
        <div className="my-5 text-center">
          <Alert severity="info">
            <strong>There is no inactive location.</strong>
          </Alert>
        </div>
      </>
    );
  }

  return (
    <div className="un-subscribed-locations my-5">
      {!isThereNoInactiveLocations && selectedBrand.id === 3 && (
        <div className="mb-1 d-flex justify-content-end">
          <Button
            component={Link}
            to="/self-subscribe/jinya-ramen-bar"
            color="primary"
            className="text-case-initial"
            // variant="outlined"
            // startIcon={<Icon>info</Icon>}
          >
            <u>Click here to subscribe your location(s)</u>
          </Button>
        </div>
      )}

      {renderFallOutSideLocations()}
      {renderUnsubscribedLocations()}
    </div>
  );
};

export default memo(InActiveLocations);
