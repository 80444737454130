import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";

import reducer from "../reducers";
import rootSaga from "../actions/sagas";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const enhancer =
  process.env.REACT_APP_DISABLE_REDUX_DEVTOOLS === "true"
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

if (process.env.REACT_APP_DISABLE_REDUX_DEVTOOLS === "true") {
  window.rpState = () => {
    // eslint-disable-next-line
    console.log("🔍 Current Store State:", store.getState());
  };
}

export default store;
