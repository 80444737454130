import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import { sortBy } from "lodash";

import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import { subscriptionService } from "../../../services/subscriptionService";
import StatusChip from "../../../components/muiCustom/chip/StatusChip";
import { getFormatDate } from "../../../helpers/utils";
import RpPopover from "../../../components/popover/RpPopover";
import MuiDarkButton from "../../../components/muiCustom/buttons/MuiDarkButton";
import RenewPackageSelection from "./RenewPackageSelection";
import OptOutConfirmation from "./OptOutConfirmation";
import RpMUIDialog from "../../../components/modals/RpMUIModal";
import { subscriptionHelper } from "../../../helpers/subscriptionHelper";
import {
  stripeSubscriptionStatus,
  subscriptionPaymentStatus,
  subscriptionPlanPaymentType,
} from "../../../config/constants";
import { logger } from "../../../helpers/loggerHelper";

const SubscribedLocationCard = ({
  location,
  onSelectPackage,
  onConfirm,
  fetchLocations,
  subscriptionDescription,
}) => {
  const [selectedUpcomingPkgId, setSelectedUpcomingPkgId] = useState(
    location?.upcoming_package_id
  );
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);
  const [openChange, setOpenChange] = useState(false);
  const [openOptIn, setOpenOptIn] = useState(false);
  const [openOptOutConfirm, setOpenOptOutConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [msg, setMsg] = useState(null);

  const isNewSubscription = useMemo(() => {
    return subscriptionHelper.checkIsNewSubscription(location);
  }, [location]);

  const packages = useMemo(() => {
    let pkgs = location.packageData;
    // let pkgs = isNewSubscription
    //   ? location.packageData
    //   : location?.packageData.filter((p) =>
    //       location?.brand_auto_renew
    //         ? p.eligible_for_renew
    //         : !p.eligible_for_renew
    //     );

    // if (!isNewSubscription) {
    //   pkgs = location?.packageData.filter((p) =>
    //     // location?.current_package_eligible_for_renew
    //     location?.brand_auto_renew
    //       ? p.eligible_for_renew
    //       : !p.eligible_for_renew
    //   );
    // }

    // if (
    //   location?.current_package_payment_type ==
    //   subscriptionPlanPaymentType.INVOICE
    // ) {
    //   pkgs = location?.packageData.filter(
    //     (p) => p.payment_type === subscriptionPlanPaymentType.INVOICE
    //   );
    // }

    return pkgs?.length ? sortBy(pkgs, (o) => Number(o.period)) : [];
  }, [location, isNewSubscription]);

  // const isAutoRenewDateGone = subscriptionHelper.isAutoRenewDateGone(location);

  useEffect(() => {
    loader(isLoading);
  }, [isLoading, loader]);

  useEffect(() => {
    setSelectedUpcomingPkgId(location?.upcoming_package_id);
  }, [location?.upcoming_package_id]);

  const handleOnSelectPackage = (plan) => {
    setSelectedUpcomingPkgId(plan.id);
  };

  const handleOnConfirm = () => {
    const plan = location.packageData.find(
      (p) => p.id === selectedUpcomingPkgId
    );
    const formateData = {
      locationId: location?.fk_location_id,
      packageId: plan.id,
      locationInfo: location,
      packageInfo: plan,
      is_changing: openChange,
      // is_changing: openChange || (!isAutoRenewDateGone && openOptIn),
      is_optIn: location?.brand_auto_renew ? openOptIn : false,
      is_new_subscription: isNewSubscription,
    };
    onSelectPackage(formateData);
    onConfirm();
  };

  const handleCloseModal = () => {
    setOpenChange(false);
    setOpenOptIn(false);
    setSelectedUpcomingPkgId();
  };

  const OnOptIn = async (data) => {
    setIsLoading(true);
    try {
      const res = await subscriptionService.optInAutoRenewal(data);
      if (res.status === 200) {
        toastr.success(
          "Success",
          `You have Opted-In for Auto-Renew Upcoming Subscription Successfully`
        );
        fetchLocations(true);
      }
    } catch (err) {
      logger.error("OnOptIn error", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptIn = () => {
    const selectionData = {
      locationId: location?.fk_location_id,
      locationInfo: location,
    };
    const dataToSave = {
      userId: loggedInUser.id,
      selections: [selectionData],
      brandId: selectedBrand.id,
      system_generated_brand_id: selectedBrand.system_generated_brand_id,
      fk_brand_name: selectedBrand.brand_name,
    };
    OnOptIn(dataToSave);
  };

  const isPaymentPending = useMemo(() => {
    // need to check upcoming plan payment type as well
    const isCurrentPlanStripe =
      location?.current_package_payment_type ===
      subscriptionPlanPaymentType.STRIPE;

    const isUpcomingPlanStripe =
      location?.upcoming_package_payment_type ===
      subscriptionPlanPaymentType.STRIPE;

    if (
      location?.is_current_subscription_active &&
      location?.upcoming_package_id &&
      isUpcomingPlanStripe
    ) {
      return (
        // need to check upcoming payment status as well
        location?.upcoming_payment_status === subscriptionPaymentStatus.PENDING
      );
    }

    if (isCurrentPlanStripe) {
      return location?.payment_status === subscriptionPaymentStatus.PENDING;
    }
    return false;
  }, [location]);

  const lockChangeAndCancel = useMemo(() => {
    if (location?.is_pending_activation === undefined) return false;
    return !location?.is_pending_activation;
  }, [location]);

  const renderTags = useCallback(() => {
    const isPastDue =
      location.subcription_status === stripeSubscriptionStatus.PAST_DUE;
    // const isPaymentPending =
    //   location.payment_status === subscriptionPaymentStatus.PENDING;
    return (
      <div>
        {location?.upcoming_renewal === 1 &&
        !location?.taken_upcoming_subscription &&
        !isNewSubscription ? (
          <StatusChip
            label="Upcoming Renewal"
            status="info"
            size="medium"
            className="p-3 mr-1 chip mb-1"
          />
        ) : null}
        {/* {location?.current_package_eligible_for_auto_renew && */}
        {location?.brand_auto_renew ? (
          location?.auto_renewal ? (
            <StatusChip
              label="Auto Renew: ON"
              status="success"
              size="medium"
              className="p-3 chip mb-1"
            />
          ) : (
            <StatusChip
              label="Auto Renew: OFF"
              status="error"
              size="medium"
              className="p-3 chip mb-1"
            />
          )
        ) : null}
        {isPastDue ? (
          <StatusChip
            label="Past Due"
            status="error"
            size="medium"
            className="p-3 chip mb-1 mx-1"
          />
        ) : null}
        {!isPastDue && isPaymentPending ? (
          <StatusChip
            label="Payment Pending"
            status="error"
            size="medium"
            className="p-3 chip mb-1 mx-1"
          />
        ) : null}
      </div>
    );
  }, [location, isNewSubscription, isPaymentPending]);

  const renderCurrentSubscriptionDetails = useCallback(() => {
    const startDate = getFormatDate(
      location?.subscription_start_date,
      "MM/DD/YYYY"
    );
    const endDate = getFormatDate(
      location?.subscription_end_date,
      "MM/DD/YYYY"
    );

    const packageDetails = `${location?.subscription_package} - ${location?.duration} Month`;

    const infoMsg = isNewSubscription
      ? `Reminder, You can cancel or modify your subscription till 23rd.`
      : `Reminder, You have until the 30 days prior to your renewal date to cancel or modify your subscription`;
    return (
      <div className="row mb-2">
        <div className="col-md-6 d-flex align-items-center">
          <Typography
            className="subscription-date mr-1 mt-1 mt-xl-2"
            variant="h6"
          >
            {isNewSubscription ? "Upcoming" : "Current"} Plan Date:{" "}
            <Typography component="span">
              {`${startDate} - ${endDate}`}
            </Typography>
          </Typography>
          {location?.auto_renewal || isNewSubscription ? (
            <RpPopover
              popContent={
                <Typography component="p" variant="caption">
                  {infoMsg}
                </Typography>
              }
            >
              <Icon fontSize="small" color="primary">
                info
              </Icon>
            </RpPopover>
          ) : null}
        </div>
        {location?.subscription_package ? (
          <>
            <div className="col-md-1">
              <Divider orientation="vertical" />
            </div>
            <div className="col-md-5">
              <Typography className="subscription-date" variant="h6">
                {isNewSubscription ? "Upcoming" : "Current"} Plan:{" "}
                <Typography component="span" color="primary">
                  {packageDetails}
                </Typography>
              </Typography>
            </div>
          </>
        ) : null}
      </div>
    );
  }, [location]);

  const renderUpcomingSubscriptionDetails = useCallback(() => {
    if (isNewSubscription || !location?.upcoming_start_date) return null;
    return (
      <div className="row">
        <div className="col-md-6">
          <Typography className="subscription-date" variant="h6">
            Upcoming Plan Date:{" "}
            <Typography component="span">
              {`${getFormatDate(
                location?.upcoming_start_date,
                "MM/DD/YYYY"
              )} - ${getFormatDate(location?.upcoming_end_date, "MM/DD/YYYY")}`}
            </Typography>
          </Typography>
        </div>
        {(location?.taken_upcoming_subscription &&
          location?.upcoming_pacakge_title) ||
        location?.upcoming_package ? (
          <>
            <div className="col-md-1 d-none d-md-block">
              <Divider orientation="vertical" />
            </div>
            <div className="col-md-5">
              <Typography className="subscription-date" variant="h6">
                Upcoming Plan:{" "}
                <Typography component="span" color="primary">
                  {(location?.taken_upcoming_subscription &&
                    location?.upcoming_pacakge_title) ||
                    location?.upcoming_package}{" "}
                </Typography>
              </Typography>
            </div>
          </>
        ) : null}
      </div>
    );
  }, [location]);

  const renderActionsBrandAutoON = useCallback(() => {
    const isBrandAutoOn = location?.brand_auto_renew;
    if (!isBrandAutoOn) return null;

    // brand level auto-renew ON
    // plan and location level auto-renew may be ON or OFF

    // const isCurrentPlanInvoice =
    //   location?.current_package_payment_type ===
    //   subscriptionPlanPaymentType.INVOICE;
    // const isCurrentPlanStripe =
    //   location?.current_package_payment_type ===
    //   subscriptionPlanPaymentType.STRIPE;
    // const isCurrentPlanAutoRenewOn =
    //   location?.current_package_eligible_for_auto_renew;
    const isLocationAutoRenewOn = location?.auto_renewal;

    // const isCurrentSubscriptionActive =
    //   location?.is_current_subscription_active;

    // const isUpcomingRenewal = location?.upcoming_renewal;

    const displayChangePlanButton = isLocationAutoRenewOn || isNewSubscription;
    const changePlanText = isNewSubscription ? "" : "Upcoming";
    const disabledChangePlanText = lockChangeAndCancel;

    const displayTurnAutoRenewOnButton =
      !isNewSubscription && !isLocationAutoRenewOn;

    const turnAutoRenewOnButtonText = "Turn Auto-Renew On";

    const displayTurnAutoRenewOffButton =
      isLocationAutoRenewOn || isNewSubscription;

    const turnAutoRenewOffButtonText = isNewSubscription
      ? "Cancel Upcoming Plan"
      : "Turn Auto-Renew Off";
    const disabledTurnAutoRenewOffButtonText = lockChangeAndCancel;

    return (
      <div
        className={`col-md-12 col-xl-3 d-flex flex-column justify-content-${
          location?.taken_upcoming_subscription || !location?.auto_renewal
            ? "center"
            : "between"
        } mt-3 mt-xl-0`}
      >
        {displayChangePlanButton ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (location?.taken_upcoming_subscription) {
                setMsg(
                  "Your upcoming plan is finalized. You can't change now."
                );
              } else if (!isNewSubscription && !location?.upcoming_renewal) {
                setMsg(
                  `You can change the upcoming plan before ${
                    selectedBrand?.id === 2 ? "90" : "30"
                  } days of the end date of the current subscription only!`
                );
              } else if (packages?.length) {
                setOpenChange(true);
              } else if (!packages?.length) {
                setMsg("There is no plan available for this location.");
              }
            }}
            className="mt-2"
            disabled={disabledChangePlanText}
          >
            Change {changePlanText} Plan
          </Button>
        ) : null}

        {displayTurnAutoRenewOnButton ? (
          <MuiDarkButton
            variant="contained"
            onClick={() => {
              if (!location?.upcoming_renewal) {
                setMsg(
                  `You can turn on auto-renew before ${
                    selectedBrand?.id === 2 ? "90" : "30"
                  } days of the end of the current subscription only!`
                );
              } else if (
                !location.taken_upcoming_subscription ||
                (location.taken_upcoming_by_admin &&
                  location.taken_upcoming_subscription)
              ) {
                packages?.length
                  ? setOpenOptIn(true)
                  : setMsg("There is no plan available for this location");
              } else if (
                // eslint-disable-next-line no-dupe-else-if
                !location?.brand_auto_renew &&
                !location?.upcoming_renewal
              ) {
                return;
              } else {
                handleOptIn();
              }
            }}
            disabled={
              location?.taken_upcoming_subscription ||
              // !isUpcomingRenewal ||
              (!location?.brand_auto_renew && !location?.upcoming_renewal)
            }
          >
            {turnAutoRenewOnButtonText}
          </MuiDarkButton>
        ) : null}

        {displayTurnAutoRenewOffButton ? (
          <Button
            variant="contained"
            className="opt-out-btn mt-4"
            disableElevation
            onClick={() => {
              if (location?.taken_upcoming_subscription) {
                setMsg(
                  "Your upcoming plan is finalized. You can't opt-out now."
                );
              } else {
                setOpenOptOutConfirm(true);
              }
            }}
            disabled={disabledTurnAutoRenewOffButtonText}
          >
            {turnAutoRenewOffButtonText}
          </Button>
        ) : null}
      </div>
    );
  }, [location, isNewSubscription, lockChangeAndCancel]);

  const renderActionsBrandAutoOff = useCallback(() => {
    const isBrandAutoOn = location?.brand_auto_renew;
    if (isBrandAutoOn) return null;

    // brand level auto-renew is OFF
    // plan and location auto-renew OFF too

    // const isCurrentPlanInvoice =
    //   location?.current_package_payment_type ===
    //   subscriptionPlanPaymentType.INVOICE;
    // const isCurrentPlanStripe =
    //   location?.current_package_payment_type ===
    //   subscriptionPlanPaymentType.STRIPE;

    // const isUpcomingRenewal = location?.upcoming_renewal;

    // const isSubscriptionTakenFromAdmin =
    //   !isCurrentPlanInvoice && !isCurrentPlanStripe;

    const displayChangePlanButton = isNewSubscription;
    const changePlanText = "";
    const disabledChangePlanText = lockChangeAndCancel;

    const displayTurnAutoRenewOnButton = !isNewSubscription;
    // isUpcomingRenewal && !location?.taken_upcoming_subscription;
    // isSubscriptionTakenFromAdmin || isUpcomingRenewal;

    const turnAutoRenewOnButtonText = "Select Upcoming Plan";

    const displayTurnAutoRenewOffButton = isNewSubscription;
    // location?.payment_status !== subscriptionPaymentStatus.PENDING;
    // (isUpcomingRenewal && !location?.taken_upcoming_subscription);

    const turnAutoRenewOffButtonText = "Cancel Upcoming Plan";
    const disabledTurnAutoRenewOffButtonText = lockChangeAndCancel;

    return (
      <div
        className={`col-md-12 col-xl-3 d-flex flex-column justify-content-${
          // location?.taken_upcoming_subscription || location?.auto_renewal
          !isNewSubscription ? "center" : "between"
        } mt-3 mt-xl-0`}
      >
        {displayChangePlanButton ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (location?.taken_upcoming_subscription) {
                setMsg(
                  "Your upcoming plan is finalized. You can't change now."
                );
              } else if (packages?.length) {
                setOpenChange(true);
              } else if (!packages?.length) {
                setMsg("There is no plan available for this location");
              }
            }}
            className="mt-2"
            disabled={disabledChangePlanText}
          >
            Change {changePlanText} Plan
          </Button>
        ) : null}

        {displayTurnAutoRenewOnButton ? (
          <MuiDarkButton
            variant="contained"
            onClick={() => {
              if (
                !location.taken_upcoming_subscription ||
                (location.taken_upcoming_by_admin &&
                  location.taken_upcoming_subscription)
              ) {
                packages?.length
                  ? setOpenOptIn(true)
                  : setMsg("There is no plan available for this location");
              } else if (
                !location?.brand_auto_renew &&
                !location?.upcoming_renewal
              ) {
                return;
              } else {
                handleOptIn();
              }
            }}
            disabled={
              location?.taken_upcoming_subscription ||
              (!location?.brand_auto_renew && !location?.upcoming_renewal)
            }
          >
            {turnAutoRenewOnButtonText}
          </MuiDarkButton>
        ) : null}

        {displayTurnAutoRenewOffButton ? (
          <Button
            variant="contained"
            className="opt-out-btn mt-4"
            disableElevation
            onClick={() => {
              setOpenOptOutConfirm(true);
            }}
            disabled={disabledTurnAutoRenewOffButtonText}
          >
            {turnAutoRenewOffButtonText}
          </Button>
        ) : null}
      </div>
    );
  }, [location, isNewSubscription, lockChangeAndCancel]);

  const isDisabledLoc = useMemo(() => {
    const {
      brand_auto_renew,
      upcoming_renewal,
      taken_upcoming_subscription,
      // is_current_subscription_active,
    } = location;

    if (!brand_auto_renew) {
      return (
        (!isNewSubscription && !upcoming_renewal) || taken_upcoming_subscription
      );
    }
    return false;
  }, [location, isNewSubscription]);

  return (
    <div
      className="card location border-0"
      style={{
        backgroundColor: isDisabledLoc ? "lightgrey" : "",
      }}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-xl-9 mb-2 mb-md-0">
            <div className="d-flex align-items-center flex-wrap mb-3">
              <Typography
                color="primary"
                className="mr-2 location-name"
                variant="h6"
              >
                {`${location.location_name} (${location.participantName})`}
              </Typography>
              {renderTags()}
            </div>
            {renderCurrentSubscriptionDetails()}
            {renderUpcomingSubscriptionDetails()}
          </div>
          {location?.brand_auto_renew
            ? renderActionsBrandAutoON()
            : renderActionsBrandAutoOff()}
        </div>

        <RpMUIDialog
          open={openChange || openOptIn}
          onClose={handleCloseModal}
          maxWidth="lg"
          className="change-package-dialog"
        >
          <div className="manage-active-subscription-package-details">
            <RenewPackageSelection
              packages={packages}
              close={handleCloseModal}
              onSelectPackage={handleOnSelectPackage}
              onConfirm={handleOnConfirm}
              selectedUpcomingPkgId={selectedUpcomingPkgId}
              location={location}
              subscriptionDescription={subscriptionDescription}
            />
          </div>
        </RpMUIDialog>

        <RpMUIDialog
          open={openOptOutConfirm}
          onClose={() => setOpenOptOutConfirm(false)}
          maxWidth="sm"
        >
          <OptOutConfirmation
            close={() => setOpenOptOutConfirm(false)}
            location={location}
            fetchLocations={fetchLocations}
          />
        </RpMUIDialog>

        <RpMUIDialog
          open={msg ? true : false}
          onClose={() => setMsg(null)}
          maxWidth="sm"
        >
          <>
            <div className="d-flex justify-content-end">
              <IconButton
                aria-label="close"
                onClick={() => setMsg(null)}
                color="inherit"
                className="p-0"
              >
                <Icon fontSize="small">highlight_off</Icon>
              </IconButton>
            </div>
            <div className="text-center">
              <img
                src="/img/icons/info.svg"
                alt="alert-icon"
                className="img-fluid"
              />
            </div>
            <div className="container text-center my-3">
              <Typography component="div" variant="h6">
                {msg}
              </Typography>
            </div>
          </>
        </RpMUIDialog>
      </div>
    </div>
  );
};

export default SubscribedLocationCard;
