import React from "react";
import { Button, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import PageLayout from "../layouts/page/PageLayout";

const CustomizeAds = ({ campaign_id, is_redirect }) => {
  const getButtonLink = () => {
    if (is_redirect && campaign_id) {
      return `/participant/join-campaign/${campaign_id}`;
    }
    return `${process.env.PUBLIC_URL}/digital-campaigns`;
  };

  return (
    <PageLayout className="customize-ads-container mx-0 mx-sm-2">
      <Box
        className="customize-ads-page"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <img
          className="my-4 ads-img"
          src={`${process.env.PUBLIC_URL}/img/ads.png`}
          alt="Ad"
        />
        <Typography color="primary" variant="h4" className="fw-b">
          All Set!
        </Typography>
        <Typography className="heading12 w-75 fw-sb px-md-5 text-center description mt-2">
          You can now select a preferred message for your locations
        </Typography>
        <Box className="d-flex-center my-5">
          <Button
            component={Link}
            to={getButtonLink()}
            className="customized-ads-btn"
            variant="contained"
            color="primary"
            size="large"
            replace
          >
            Customize Ads
          </Button>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default CustomizeAds;
