import React, { Fragment, memo, useState } from "react";
import ImageRP from "../ImageRP";
import RpMUIDialog from "./RpMUIModal";
import "./modalStyle.scss";
import { Box } from "@material-ui/core";

const CreativeImagePopup = ({ src, srcTwo, children, heading = "" }) => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    if (!src) return;
    setOpen(true);
  };
  return (
    <Fragment>
      <div
        onClick={handleOpen}
        className="creative-name link"
        title="Click to view"
      >
        {children}
      </div>
      <RpMUIDialog
        open={open}
        onClose={onClose}
        heading={heading}
        maxWidth="xs"
        fullWidth={false}
      >
        <Box
          minWidth={200}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <ImageRP src={src} type="CREATIVE" className="img-fluid" />
          {srcTwo && srcTwo !== "-" ? (
            <ImageRP src={srcTwo} type="CREATIVE" className="img-fluid mt-2" />
          ) : null}
        </Box>
      </RpMUIDialog>
    </Fragment>
  );
};

export default memo(CreativeImagePopup);
