import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

import "./App.scss";
import history from "./config/history";
import MainRoutes from "./helpers/routes/MainRoutes";
import {
  GlobalContextProvider,
  LoaderProvider,
} from "./helpers/contexts/contextProviders";
import ScrollTo from "./components/ScrollTo";
import muiTheme from "./config/muiTheme";
import MainSEO from "./components/seo/MainSEO";
import { createBroadcastChannel } from "./helpers/broadcastChannelHelper";
import PendingInvoiceCheck from "./containers/stripe/PendingInvoiceCheck";
import LogoutWhenMaintenanceOn from "./components/utils/LogoutWhenMaintenanceOn";

function App() {
  useEffect(() => {
    // Create a new Broadcast Channel
    const channel = createBroadcastChannel();

    // Listen for messages on the channel
    channel.addEventListener("message", (event) => {
      if (event.data === "reload") {
        // Reload the page
        // if (!!document.hidden) {
        //   window.location.reload();
        // }

        if (document.visibilityState === "hidden") {
          window.location.reload();
        }
      }
    });

    return () => {
      channel.removeEventListener("message", null);
      channel.close();
    };
  }, []);

  return (
    <div className="App">
      <MainSEO />
      <Router history={history}>
        <ThemeProvider theme={muiTheme}>
          <LoaderProvider>
            <GlobalContextProvider>
              <MainRoutes />
              <ScrollTo />
              <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                getState={(state) => state.toastr} // This is the default
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
              />
              <PendingInvoiceCheck />
            </GlobalContextProvider>
          </LoaderProvider>
        </ThemeProvider>
        <LogoutWhenMaintenanceOn />
      </Router>
    </div>
  );
}

export default App;
