import React, { useMemo } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
// import moment from "moment";
import { Typography, Icon, Button } from "@material-ui/core";
import { STATIC_IMAGES } from "../../../../helpers/staticImages";
import { LocSubsStatus } from "../../../../config/constants";
import useCustomerPortal from "../../../../helpers/hooks/useCustomerPortal";

function PostcardCampaignLocationHeader({
  locFormData,
  locName,
  // campaign,
  isUnsubscribed,
  subscriptionStatusType,
  is30DaysLeftToJoinCampaign,
  isCreative,
  loc,
}) {
  const { navigateToCustomerPortal } = useCustomerPortal();
  // const locationBudget = useMemo(() => {
  //   const budget = locFormData?.budget;
  //   const brand_contribution_amount = locFormData?.brand_contribution_amount;
  //   const totalBudget = campaign?.brand_contribution
  //     ? Number(budget) + Number(brand_contribution_amount)
  //     : budget;
  //   return Number(totalBudget).toFixed(2);
  // }, [locFormData, campaign]);

  // const budget = locFormData?.budget;

  // const start_date = locFormData?.start_date;
  // const end_date = locFormData?.end_date;
  const renderMessage = useMemo(() => {
    let msg = "Unsubscribed";
    let lessThan30Msg;
    if (loc?.isPastDue) {
      msg = "Past Due";
      lessThan30Msg = (
        <div>
          You must reactivate your subscription to launch this campaign.{" "}
          <div>
            <Button
              size="small"
              color="secondary"
              onClick={navigateToCustomerPortal}
            >
              Update Payment Information
            </Button>
          </div>
        </div>
      );
    } else if (loc?.isPaymentPending) {
      msg = "Payment Pending";
      lessThan30Msg = (
        <div>
          The subscription payment is pending. Once done, this location will be
          available to join the campaign.
        </div>
      );
    } else if (subscriptionStatusType === LocSubsStatus.FALLOUT) {
      msg = "Campaign Dates are Outside Subscription Period";
      if (is30DaysLeftToJoinCampaign) {
        lessThan30Msg =
          "You have less than a month left in your subscription for this location, please renew in order to join this campaign";
      }
    } else if (loc?.subscription !== null && isCreative === false) {
      msg = LocSubsStatus.IS_CREATIVE;
    }
    return (
      <span className="text-right">
        <div>
          <Icon
            component="img"
            src={
              subscriptionStatusType === LocSubsStatus.UNSUBSCRIBED
                ? STATIC_IMAGES.infoRed
                : STATIC_IMAGES.infoYellow
            }
            sizes="small"
            className="mr-2"
            style={{ width: ".7em", height: ".7em" }}
          />
          {msg}
        </div>
        {lessThan30Msg && (
          <>
            <br />
            <small
              style={{ fontSize: "inherit" }}
              className="d-flex flex-column align-items-end"
            >
              {lessThan30Msg}
            </small>
          </>
        )}
      </span>
    );
  }, [subscriptionStatusType, is30DaysLeftToJoinCampaign]);

  let wrapperClass = "unfilled";
  let icon = (
    <RadioButtonUncheckedIcon
      color="disabled"
      className="mr-2 d-none"
      disabled
    />
  );
  let locationName = (
    <div className="row">
      <div className="col-lg-6 mb-2 mb-lg-0 location-name">
        <Typography
          component="h3"
          variant="body2"
          className="location-name fw-b"
        >
          {locName}
        </Typography>
      </div>
      <div className="col-lg-6 message d-flex align-items-center justify-content-lg-end">
        {isUnsubscribed && (
          <div className="card" style={{ display: "inline-block" }}>
            <span className="message">
              <Typography
                component="span"
                variant="caption"
                color={
                  subscriptionStatusType === LocSubsStatus.UNSUBSCRIBED
                    ? "error"
                    : "textPrimary"
                }
                className="fallOutside d-flex align-items-center fw-b"
              >
                {renderMessage}
              </Typography>
            </span>
          </div>
        )}
      </div>
    </div>
  );
  // let locationFilledData = (
  //   <div className="location-meta">
  //     {budget ? (
  //       <div className="mr-2 budget">
  //         <span>Budget: </span>${`${locationBudget}   `}
  //       </div>
  //     ) : null}
  //     {start_date && end_date && (
  //       <div className="date">
  //         <span className="d-none d-md-inline">Start-End Date: </span>
  //         {moment(start_date).format("MMM Do, YYYY") +
  //           " - " +
  //           moment(end_date).format("MMM Do, YYYY")}
  //       </div>
  //     )}
  //   </div>
  // );

  if (
    locFormData.creatives &&
    locFormData.creatives.length &&
    ((locFormData.isMailing && locFormData.mailingCost) ||
      (locFormData.isBulkDrop && locFormData.bulkDropCost))
  ) {
    wrapperClass = "filled";
    icon = <CheckCircleIcon color="primary" className="mr-2" />;

    // return <Icon className="mr-2">task_alt</Icon>;
  } else if (
    (locFormData.creatives && locFormData.creatives.length) ||
    (locFormData.isMailing && locFormData.mailingCost) ||
    (locFormData.isBulkDrop && locFormData.bulkDropCost)
  ) {
    wrapperClass = "partially-filled";
    icon = (
      <ErrorIcon color="error" className="mr-2" title="Partially filled" />
    );
  }

  return (
    <div
      className={`w-100 d-flex location-header-content ${wrapperClass}`}
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
    >
      {icon}
      <div className="w-100">
        {locationName}
        {/* {locationFilledData} */}
      </div>
    </div>
  );
}

export default PostcardCampaignLocationHeader;
