import React, { useEffect, useContext, useMemo } from "react";

import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import JoinedCampaigns from "./JoinedCampaigns";
import UnJoinedCampaigns from "./unjoinedCampaigns/UnjoinedCampaigns";
import PageLayout from "../../../components/layouts/page/PageLayout";
import PageHeader from "../../../components/layouts/pageHeaders/PageHeader";
import UniversalWidgetTemplate from "../../../components/templates/UniversalWidgetTemplate";
import { reportService } from "../../../services/reportService";
import { renderTemplate } from "../../../helpers/reportHelper";
import NoDataFound from "../../../components/NoDataFound";
import CampaignAvailableToJoin from "../../postcardCampaign/widgets/campaignAvailableToJoin";
import PostcardCampaignJoined from "../../postcardCampaign/widgets/postcardCampaignJoined";
import useFetcher from "../../../helpers/hooks/useFetcher";
import { CUSTOM_WIDGET_PARAM } from "../../../config/constants";
import { removeCampaignByNameLike } from "../../../helpers/utils";

const ParticipantDashboard = () => {
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const reqData = useMemo(() => {
    return {
      id: selectedBrand.id,
      fk_participant_id: loggedInUser.participantData.id,
      userId: loggedInUser.id,
    };
  }, [selectedBrand, loggedInUser]);

  const { data, isLoading, isValidating } = useFetcher(
    reqData,
    reportService.getDashboardReport,
    { revalidateOnFocus: false }
  );

  const templateData = data;

  useEffect(() => {
    loader(isLoading || isValidating);
  }, [isLoading, isValidating, loader]);

  // if (!data?.template?.rows || data?.template?.rows?.length === 0) {
  //   return (
  //     <PageLayout>
  //       <NoDataFound message="There is no template defined for this participant" />
  //     </PageLayout>
  //   );
  // }

  const renderTable = (widget) => {
    if (widget.id === 148 && widget?.data) {
      return (
        <UnJoinedCampaigns
          selectedBrand={selectedBrand}
          isThereAnyActiveSubscription={
            templateData?.isThereAnyActiveSubscription
          }
          getCampaigns={data}
          unJoinedCampaigns={widget?.data?.unJoinedCampaign}
          archivedCampaigns={widget?.data?.archievedCampaign}
        />
      );
    } else if (widget.id === 149 && widget?.data) {
      const noBoostCamp = removeCampaignByNameLike(
        widget?.data?.joinedCampaign,
        ["boost", "boost:"]
      );
      return <JoinedCampaigns joinedCampaigns={noBoostCamp} />;
    } else if (widget.id === 190 && widget?.data) {
      const notDraftCampaigns =
        widget?.data?.unJoinedCampaign?.filter(
          (campaign) => campaign.participant_campaign === null
        ) || [];

      const alwaysAvailableCampaigns = notDraftCampaigns?.filter(
        (campaign) => campaign.campaign_type === "EVERGREEN"
      );

      const limitedTimeCampaigns = notDraftCampaigns?.filter(
        (campaign) => campaign.campaign_type === "LIMITED"
      );

      const draftCampaigns =
        widget?.data?.unJoinedCampaign?.filter(
          (campaign) => campaign.participant_campaign !== null
        ) || [];

      return (
        <CampaignAvailableToJoin
          selectedBrand={selectedBrand}
          isThereAnyActiveSubscription={
            templateData?.isThereAnyActiveSubscription
          }
          getCampaigns={data}
          alwaysAvailableCampaigns={alwaysAvailableCampaigns}
          limitedTimeCampaigns={limitedTimeCampaigns}
          draftCampaigns={draftCampaigns}
        />
      );
    } else if (widget.id === 191 && widget?.data) {
      const noBoostCamp = removeCampaignByNameLike(
        widget?.data?.joinedCampaign,
        ["boost", "boost:"]
      );
      return <PostcardCampaignJoined joinedCampaigns={noBoostCamp} />;
    }
    return null;
  };

  const renderPage = () => {
    if (isLoading && !templateData) return <NoDataFound message="Loading..." />;
    else if (!templateData) return <NoDataFound />;
    return (
      <div className="dashboard participant-dashboard">
        <UniversalWidgetTemplate
          type="participant"
          embedWidgetParams={{
            // [CUSTOM_WIDGET_PARAM.BrandID]:
            //   selectedBrand?.system_generated_brand_id,
            [CUSTOM_WIDGET_PARAM.ParticipantID]:
              loggedInUser?.participantData?.system_generated_participant_id,
          }}
          isTemplateData={templateData}
          hideDateSelector
        />
        {renderTemplate({
          report: templateData,
          renderTable,
          embedWidgetParams: {
            [CUSTOM_WIDGET_PARAM.BrandID]:
              selectedBrand?.system_generated_brand_id,
            [CUSTOM_WIDGET_PARAM.ParticipantID]:
              loggedInUser?.participantData?.system_generated_participant_id,
          },
        })}
      </div>
    );
  };

  return (
    <PageLayout className="participant-dashboard-container mx-0 mx-sm-2">
      <PageHeader showName showLastUpdate={false} showButton={true} />
      {renderPage()}
    </PageLayout>
  );
};

export default ParticipantDashboard;
