import React, { useMemo, useContext } from "react";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import { MUICustomAutocompleteSelect } from "../../../components/muiCustom/select/MUICustomAutocompleteSelect";
import PDFDownloadButton from "../../../components/pdf/PDFDownloadButton";

const PostcardCampaignReportFilters = ({ values, campaign, setFieldValue }) => {
  const { loggedInUserRole } = useContext(GlobalContext);
  const getLocationList = useMemo(() => {
    const list = campaign
      ? campaign?.locationDetails
        ? campaign?.locationDetails.map((l) => ({
            label: l.location_name,
            value: l.location_id,
          }))
        : null
      : [];
    return list;
  }, [campaign]);

  const getGroupList = useMemo(() => {
    const list = campaign
      ? campaign?.group_list
        ? campaign?.group_list.map((l) => ({
            label: l.group_name,
            value: l.groups_id,
          }))
        : null
      : [];
    return list;
  }, [campaign]);

  return (
    <div className="row my-4 d-flex justify-content-between">
      <div className="col-lg-9">
        <div className="row">
          <div className="col-lg-4">
            <MUICustomAutocompleteSelect
              name="location_id"
              label="Locations"
              selectProps={{
                onChange: (v) => {
                  setFieldValue("location_id", v);
                },
                value: values.location_id,
              }}
              labelProps={{
                style: {
                  color: "#000",
                },
              }}
              options={getLocationList}
              all={true}
            />
          </div>
          <div className="col-lg-4">
            {loggedInUserRole !== "PR" && (
              <>
                <MUICustomAutocompleteSelect
                  name="group_id"
                  label="Groups"
                  selectProps={{
                    onChange: (v) => {
                      setFieldValue("group_id", v);
                    },
                    value: values.group_id,
                  }}
                  labelProps={{
                    style: {
                      color: "#000",
                      padding: "0",
                    },
                  }}
                  options={getGroupList}
                  all={true}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-3 m-2 m-lg-0 align-items-end">
        <PDFDownloadButton
          label="Download PDF"
          elId="campaignReport"
          title={`CAMPAIGN REPORT`}
          fileName={`${campaign?.campaign_name}_Report_${new Date().getTime()}`}
        />
      </div>
    </div>
  );
};

export default PostcardCampaignReportFilters;
