import { Button, Icon, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import CreativeChannelsModal from "../../../../components/modals/CreativeChannelsModal";
import CreativeChannels from "./CreativeChannels";
import CreativeOptions from "./CreativeOptions";
import { GlobalContext } from "../../../../helpers/contexts/contexts";
import RpPopover from "../../../../components/popover/RpPopover";
import RpMUIDialog from "../../../../components/modals/RpMUIModal";
import ParticipantFeedback from "../../ParticipantFeedback";
import AdvanceSettingForm from "../../../campaignAdvancedSettings/AdvanceSettingForm";

export default function Creatives({
  campaign,
  creatives = [],
  initiallySelected = [],
  loc,
  locationName = "",
  onSelectCreative,
  productList,
  getAdvanceSettingData,
  onFillSettingsData,
  initialAnswers,
  initialDisabled,
}) {
  const { selectedBrand } = useContext(GlobalContext);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 766px)" });

  const [feedback, setFeedback] = useState(false);

  const [open, setOpen] = useState(false);
  const [view, setView] = useState(() => {
    if (initiallySelected && initiallySelected.length) {
      return initiallySelected[0];
    }
    return creatives && creatives.length ? creatives[0].creative_id : 0;
  });

  const [openAdvancedSetting, setAdvancedSetting] = useState(false);

  const settingsData = getAdvanceSettingData?.settings;

  const handleCloseModal = () => {
    setAdvancedSetting(false);
  };

  useEffect(() => {
    if (!isTabletOrMobile) {
      setOpen(false);
    }
  }, [isTabletOrMobile]);

  const toViewCreativeName = useMemo(() => {
    const creative = creatives.find((c) => c.creative_id === view);
    return creative ? creative.creative_name : "";
  }, [view, creatives]);

  const getCreativeOptions = useMemo(() => {
    return creatives.map((c) => {
      return {
        label: c.creative_name,
        value: c.creative_id,
      };
    });
  }, [creatives]);

  const handleOnClickView = (v) => {
    setView(v);
    if (isTabletOrMobile) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleOnSelect = (selectedCreatives) => {
    onSelectCreative(selectedCreatives);
  };

  const creativeName = useMemo(() => {
    const creative = creatives.find((c) => c.creative_id === view);
    return creative ? creative.creative_name : "";
  }, [creatives, view]);

  const renderAdvancedSetting = () => {
    if (!getAdvanceSettingData) {
      return null;
    }

    if (campaign?.campaign_advanced_setting?.is_required) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-12">
          <Button
            color="primary"
            size="small"
            onClick={() => {
              setAdvancedSetting(true);
            }}
            className="mt-3"
          >
            Advanced Settings
          </Button>
        </div>
        <RpMUIDialog
          open={openAdvancedSetting}
          onClose={handleCloseModal}
          maxWidth="md"
        >
          <AdvanceSettingForm
            settingsData={settingsData}
            onClose={handleCloseModal}
            onFillSettingsData={onFillSettingsData}
            productList={productList}
            initialAnswers={initialAnswers}
            locationName={locationName}
            locationRadius={loc.radius}
          />
        </RpMUIDialog>
      </div>
    );
  };

  return (
    <div className="creatives">
      <div className="card border-0">
        <div className="card-body p-0">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 pr-md-0 d-flex flex-column justify-content-between">
              <div>
                <Typography component="h3" variant="h6" className="heading">
                  Select the message or offer to promote for this location.
                </Typography>
                <Typography component="p" variant="body2" gutterBottom>
                  {`Select ${
                    campaign?.creative_select_flag
                      ? `up to ${campaign?.creative_select_max_value}`
                      : "One"
                  }`}
                </Typography>
                <CreativeOptions
                  creativesOptions={getCreativeOptions}
                  initiallySelected={initiallySelected}
                  onClickView={handleOnClickView}
                  onSelect={handleOnSelect}
                  currentInView={view}
                  isTabletOrMobile={isTabletOrMobile}
                  creative_select_flag={campaign?.creative_select_flag}
                  creative_select_max_value={
                    campaign?.creative_select_max_value
                  }
                  initialDisabled={initialDisabled}
                />
              </div>

              <div>
                {settingsData?.length ? <>{renderAdvancedSetting()}</> : null}
                {selectedBrand?.id !== 3 && creativeName && !initialDisabled ? (
                  <div className="my-2">
                    <RpPopover popContent="Provide Feedback">
                      <Button
                        size="small"
                        startIcon={<Icon color="primary">feedback</Icon>}
                        onClick={() => setFeedback(true)}
                      >
                        Feedback
                      </Button>
                    </RpPopover>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9 pl-md-0 d-none d-md-block">
              <CreativeChannels
                loc={loc}
                view={view}
                creatives={creatives}
                campaign={campaign}
              />
            </div>
          </div>
        </div>
      </div>
      {open && (
        <CreativeChannelsModal
          open={open}
          onClose={() => setOpen(false)}
          locationName={locationName}
          creativeName={toViewCreativeName}
        >
          <div className="px-sm-3">
            <CreativeChannels
              loc={loc}
              view={view}
              creatives={creatives}
              campaign={campaign}
            />
          </div>
        </CreativeChannelsModal>
      )}

      <RpMUIDialog
        open={feedback}
        onClose={() => setFeedback(false)}
        fullWidth
        maxWidth="md"
        heading="Creative feedback"
      >
        <ParticipantFeedback
          close={() => setFeedback(false)}
          creativeId={view}
          creativeName={creativeName}
          campaign={campaign}
          location={loc}
        />
      </RpMUIDialog>
    </div>
  );
}
