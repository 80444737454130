import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

import {
  formateKeyName,
  getFormattedData,
  RPNumberFormat,
} from "../../helpers/utils";
import TablePagination from "../tables/TablePagination";

const ProgressChart = ({ data, max = 100, labels, formats = null }) => {
  const [chartData, setChartData] = useState([]);
  // pagination
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const getPageDataIndex = () => {
    const from = (currentPage - 1) * pageSize;
    const to = from + pageSize;
    return { from, to };
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const total = Math.ceil(data.length / pageSize);
    setTotalPage(total);
    setChartData(data.slice(getPageDataIndex().from, getPageDataIndex().to));
  }, [data]);

  useEffect(() => {
    setChartData(data.slice(getPageDataIndex().from, getPageDataIndex().to));
  }, [currentPage]);

  return (
    <div className="chart progress-chart">
      <div className="d-flex justify-content-between align-items-center heading">
        <div className="text16P_Bold548E8D upper">
          {formateKeyName(labels[0])}
        </div>
        <div className="text16P_Bold548E8D upper text-right">
          {formateKeyName(labels[1])}
        </div>
      </div>
      {chartData.map((d, i) => (
        <div className="bar-container" key={i}>
          <div className="d-flex justify-content-between align-items-center">
            <div
              title={d[labels[0]]}
              className="text16P_Reg626262 text-truncate w-75"
            >
              {d[labels[0]]}
            </div>
            <div className="text16P_Reg626262">
              {getFormattedData(
                RPNumberFormat().to(Number(d[labels[1]])),
                labels[1],
                formats
              )}
            </div>
          </div>
          <ProgressBar now={d[labels[1]]} className="bar" max={max} />
        </div>
      ))}

      <div className="row">
        <div className="col-12 float-right">
          <TablePagination
            page={currentPage}
            count={totalPage}
            onChange={handlePageChange}
            className="float-right"
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressChart;
