import { useContext, useMemo } from "react";
import { Button, Icon, IconButton, Typography } from "@material-ui/core";
import { toastr } from "react-redux-toastr";

import { subscriptionService } from "../../../services/subscriptionService";
import { getFormatDate } from "../../../helpers/utils";
import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import { subscriptionHelper } from "../../../helpers/subscriptionHelper";

export default function OptOutConfirmation({
  location,
  close,
  fetchLocations,
}) {
  const { selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const isNewSubscription = subscriptionHelper.checkIsNewSubscription(location)
    ? true
    : false;

  const handleOnOptOut = async () => {
    loader(true);
    try {
      const reqData = {
        system_generated_location_id: location?.system_generated_location_id,
        fk_location_id: location?.fk_location_id,
        last_subscription_date: location?.taken_upcoming_subscription
          ? location?.upcoming_end_date
          : location?.subscription_end_date,
        taken_upcoming_subscription: location?.taken_upcoming_subscription,
        fk_brand_id: selectedBrand?.id,
        fk_user_id: location?.fk_user_id,
        fk_participant_id: location?.fk_participant_id,
        location_name: location?.location_name,
        is_canceled: isNewSubscription,
        payment_type: location.is_current_subscription_active
          ? location?.upcoming_package_payment_type
          : location?.current_package_payment_type,
      };

      const res = await subscriptionService.optOutAutoRenewal(reqData);
      if (res.status === 200) {
        fetchLocations(true);
        toastr.success(
          "Success",
          `You have successfully ${
            isNewSubscription
              ? "canceled the upcoming subscription"
              : "opt-out Auto-Renewal"
          }  for the location ${location?.location_name}`
        );
        // close();
      }
    } catch (err) {
      if (err.response.status === 406) {
        fetchLocations(true);
      }
    } finally {
      fetchLocations(true);
      loader(false);
      close();
    }
  };

  const endDate = useMemo(() => {
    const {
      subscription_end_date,
      taken_upcoming_subscription,
      upcoming_end_date,
    } = location;
    if (taken_upcoming_subscription) {
      return upcoming_end_date;
    }
    return subscription_end_date;
  }, [location]);

  return (
    <div className="opt-out-confirmation text-center">
      <div className="d-flex justify-content-end">
        <IconButton
          aria-label="close"
          onClick={close}
          color="inherit"
          className="p-0"
        >
          <Icon fontSize="small">highlight_off</Icon>
        </IconButton>
      </div>
      <div className="container">
        <div>
          <img
            src="/img/icons/info.svg"
            alt="alert-icon"
            className="img-fluid"
          />
        </div>
        <Typography
          component="h3"
          variant="h5"
          className="main-heading text-center my-4"
          gutterBottom
        >
          Are you sure you want to{" "}
          {isNewSubscription
            ? " cancel your subscription"
            : "turn off your automatic renewal"}
          ?
        </Typography>
        {!isNewSubscription && (
          <Typography variant="h6" component="p">
            You will still be able to renew your subscription at any point in
            this page. Your Subscription will conclude on <br />
            <Typography variant="h6" component="span" className="fw-bb">
              {getFormatDate(endDate, "MM/DD/YYYY")}
            </Typography>
            .
          </Typography>
        )}

        <div className="mt-5 mb-3 row justify-content-center align-items-center">
          <div className="col-4">
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={close}
            >
              No
            </Button>
          </div>
          <div className="col-5">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleOnOptOut}
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
