import React from "react";

const DataRangeDisplay = ({ pageIndex, pageSize = 10, totalRecords }) => {
  if (!totalRecords) {
    return null;
  }
  const start = pageIndex * pageSize + 1;
  const end = Math.min((pageIndex + 1) * pageSize, totalRecords);
  return (
    <div className="small">
      <span className="text-light-grey">Showing </span>
      <span className="text-dark-grey">
        {start}-{end}
      </span>
      <span className="text-light-grey"> from </span>
      <span className="text-dark-grey">{totalRecords}</span>
      <span className="text-light-grey"> data</span>
    </div>
  );
};

export default DataRangeDisplay;
