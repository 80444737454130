import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { reportUserActivity } from "./userActivityHelper";
import { Chart } from "react-chartjs-2";

export default async function pdfDownloadHelper(
  processing,
  setProcessing = () => {},
  elId,
  fileName
) {
  setProcessing(() => true);
  window.scrollTo(0, 30);
  reportUserActivity("download_pdf");
  // const mainDashboardContainer = document.getElementById('main-dashboard');
  const mainDashboardContainer = document.querySelectorAll(".container");

  for (let i = 0; i < mainDashboardContainer.length; i++) {
    mainDashboardContainer[i].classList.add("reportPdfContainer");
  }

  for (let id in Chart.instances) {
    Chart.instances[id].resize();
  }

  const input = document.getElementById(elId);

  const rect = input.getBoundingClientRect();

  const documentHeight = Math.max(
    input.scrollHeight,
    input.clientHeight,
    input.offsetHeight,
    rect.height + 100
  );

  let documentWidth = 1400;

  html2canvas(input, {
    scale: 1,
    imageTimeout: 0,
    allowTaint: true,
    useCORS: true,
    ignoreElements: function (e) {
      // Here, ignore external URL links and lazyload images
      if (e.tagName === "A" && e.host !== window.location.host) {
        return true;
      } else {
        return false;
      }
    },
    letterRendering: 1,
    logging: true,
    scrollY: -window.scrollY + 100,
    scrollX: 0,
    x: 100,

    width: documentWidth,
    height: documentHeight,

    windowWidth: documentWidth - 70,
    windowHeight: documentHeight + 20,
    onclone: (document) => {
      const reportDom = document.getElementById("campaignReport");
      if (reportDom) {
        const allEls = reportDom.querySelectorAll("div");
        if (allEls) {
          for (let i = 0; i < allEls.length; i++) {
            allEls[i].style.boxShadow = "none";
          }
        }
      }

      /** Did font-changes to prevent clipping in the auto complete */
      // const campaignFilters = document.querySelector(".campaign-filters");
      // if (campaignFilters) {
      //   const monthPickerButton = campaignFilters.querySelector(
      //     ".month-picker-button"
      //   );
      //   if (monthPickerButton) {
      //     monthPickerButton.style.fontSize = "inherit";
      //   }
      //   const pdfDownloadButton = campaignFilters.querySelector(
      //     ".pdf-download-button "
      //   );
      //   if (pdfDownloadButton) {
      //     pdfDownloadButton.style.fontSize = "large";
      //   }
      //   const autoCompleteDropDowns = campaignFilters.querySelectorAll(
      //     ".MuiAutocomplete-input "
      //   );
      //   if (autoCompleteDropDowns && autoCompleteDropDowns?.length) {
      //     autoCompleteDropDowns.forEach((el) => {
      //       el.style.fontSize = "large";
      //     });
      //   }
      // }
    },
  })
    .then((canvas) => {
      for (let i = 0; i < mainDashboardContainer.length; i++) {
        mainDashboardContainer[i].classList.remove("reportPdfContainer");
      }
      const imgData = canvas.toDataURL("image/jpeg", "0.95");

      /************************************** */

      const pdfWidth = canvas.width;
      const pdfHeight = canvas.height;

      const orientation = pdfWidth >= pdfHeight ? "l" : "p";

      const pdf = new jsPDF({
        orientation,
        unit: "px",
        hotfixes: ["px_scaling"],
        format: [canvas.width, canvas.height],
      });

      pdf.internal.pageSize.width = pdfWidth;
      pdf.internal.pageSize.height = pdfHeight;

      pdf.addImage(imgData, "JPEG", 50, 20, pdfWidth, pdfHeight - 50);

      /*********Saving***************** */
      pdf.save(`${fileName}.pdf`);
      input.style = "width:auto";
      setProcessing(false);
    })
    .catch(() => {
      setProcessing(false);
    })
    .finally(() => {
      setProcessing(false);
    });
}
