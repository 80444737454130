import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import ImageRP from "../../../components/ImageRP";
import { STATIC_IMAGES } from "../../../helpers/staticImages";
import CreatePasswordForm from "./CreatePasswordForm";
import { isLoggedInSelector } from "../../../selectors/authSelectors";

export default function CreatePassword() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { state } = useLocation();

  const { userData, token, subscribe } = state || {};

  if (!state || !token || !userData) {
    return <Redirect to="/" />;
  }

  const renderBrandLogo = () => {
    const { brandData } = userData;
    return (
      <ImageRP
        src={brandData[0]?.brand_logo}
        type="BRAND"
        style={{ maxWidth: 100, maxHeight: 60 }}
      />
    );
  };

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <div className="create-password py-2">
        <div className="container">
          {renderBrandLogo()}
          {userData?.first_name ? (
            <>
              <div className="greeting mt-4">Welcome</div>
              <h4 className="user-name">
                {userData?.first_name + " " + userData?.last_name}
              </h4>
            </>
          ) : null}
          <div className="card mt-2">
            <div className="card-body py-5">
              <div className="row align-items-center">
                <div className="col-md-6 mb-3">
                  <img
                    src={STATIC_IMAGES.videoThumbnail}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <CreatePasswordForm
                    token={token}
                    email={userData?.email}
                    subscribe={subscribe}
                    first_name={userData?.first_name}
                    last_name={userData?.last_name}
                    isNameRequired={userData?.isNameRequired}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
