import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core";
import PaginationItem from "@material-ui/lab/PaginationItem";

const usePaginationItemStyle = makeStyles((theme) => ({
  root: {
    marginRight: 5,
  },
  page: {
    color: `${theme.palette.primary.main}`,
    backgroundColor: "#04a9991a",
    border: 0,
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main}!important`,
    color: `${theme.palette.primary.contrastText}!important`,
    border: 0,
  },
}));

const TablePagination = ({
  count = 1,
  page = 1,
  onChange,
  className = "",
  size = "small",
  ...rest
}) => {
  const itemClasses = usePaginationItemStyle();
  return (
    <div className={`chart-pagination my-2 ${className}`}>
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        size={size}
        boundaryCount={2}
        siblingCount={0}
        color="primary"
        renderItem={(item) => (
          <PaginationItem {...item} classes={itemClasses} />
        )}
        variant="outlined"
        shape="rounded"
        {...rest}
      />
    </div>
  );
};

export default TablePagination;
