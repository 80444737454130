import React from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useBlockLayout,
  useFilters,
  useSortBy,
} from "react-table";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";

import TableGlobalFilter from "./TableGlobalFilter";
import TablePagination from "./TablePagination";
import TablePageSize from "./TablePageSize";
import NoDataFound from "../NoDataFound";
import DataRangeDisplay from "../DataRangeDisplay";

const TableClient = ({
  data = [],
  columns = [],
  showSearch = false,
  searchPlaceholder = "Search..",
  pagination = true,
  showPageSize = false,
  initialPageSize = 10,
  filters,
  showFilter = false,
  hiddenColumns = [],
  initialSortedBy = [],
  hideSearchLabel = false,
  totalRecords,
}) => {
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    // rows,
    state,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter,
    // The rest of these things are super handy, too ;)
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        hiddenColumns,
        sortBy: initialSortedBy,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useBlockLayout
  );

  const handlePageChange = (event, value) => {
    gotoPage(value - 1);
  };

  const getHederTitle = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? `Sorted DESC` : `Sorted ASC`;
    }
    return "";
  };

  return (
    <div className="rp-react-table">
      {showSearch && (
        <div className="row my-2 justify-content-end align-items-center">
          {showFilter && filters && filters(setFilter, state.filters)}
          <div
            className={`${
              showFilter && filters ? "col col-lg-3" : "col-md-4"
            } mb-2`}
          >
            <TableGlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder={searchPlaceholder}
              hideSearchLabel={hideSearchLabel}
            />
          </div>
        </div>
      )}
      <div className="table-responsive">
        <table {...getTableProps()} className="table table-borderless">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr
                key={i}
                {...headerGroup.getHeaderGroupProps()}
                className="w-100"
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        title: getHederTitle(column),
                      })
                    )}
                    className="w-100"
                  >
                    {column.render("Header")}
                    <div
                      style={{
                        position: "absolute",
                        right: 2,
                        top: 0,
                        bottom: 0,
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      {column.canSort ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            height: "100%",
                          }}
                        >
                          <ArrowUpward
                            style={{ fontSize: 9 }}
                            color={
                              column.isSorted && !column.isSortedDesc
                                ? "inherit"
                                : "disabled"
                            }
                          />
                          <ArrowDownward
                            style={{ fontSize: 9 }}
                            color={
                              column.isSorted && column.isSortedDesc
                                ? "inherit"
                                : "disabled"
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page && page.length ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr key={i} {...row.getRowProps()} className="w-100">
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps()}
                          className="w-100"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="42">
                  <NoDataFound height="auto" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="mt-1">
        <div className="row align-items-center">
          {showPageSize === true && (
            <div className="col-1">
              <TablePageSize pageSize={pageSize} setPageSize={setPageSize} />
            </div>
          )}
          <div className="col-lg-6 mb-2">
            <DataRangeDisplay
              pageIndex={pageIndex}
              totalRecords={totalRecords}
            />
          </div>
          {pagination === true && pageCount > 0 && (
            <div className="col d-flex justify-content-end">
              <TablePagination
                count={pageCount}
                page={pageIndex + 1}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableClient;
