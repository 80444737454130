import React, { useContext, useMemo, useEffect, useState } from "react";

import { GlobalContext, LoaderContext } from "../helpers/contexts/contexts";
import UnJoinedCampaigns from "../containers/participant/dashboard/unjoinedCampaigns/UnjoinedCampaigns";
import PageLayout from "../components/layouts/page/PageLayout";
import { campaignService } from "../services";
import useFetcher from "../helpers/hooks/useFetcher";

const LaunchDigitalCampaigns = () => {
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabType, setTabType] = useState("new_campaign");
  const [currentPage, setCurrentPage] = useState(1);

  const reqData = useMemo(() => {
    return {
      id: selectedBrand.id,
      fk_participant_id: loggedInUser.participantData.id,
      userId: loggedInUser.participantData.id,
      tabType: tabType,
      page: currentPage,
    };
  }, [selectedBrand, loggedInUser, tabType, currentPage]);

  const { data, isLoading, isValidating } = useFetcher(
    reqData,
    campaignService.getDigitalCampaigns,
    { revalidateOnFocus: false }
  );

  const launchCampaignData = data?.campaignTabsData?.data || [];
  const pageCount = data?.campaignTabsData?.pageCount || 0;
  const totalRecordsData = data?.campaignTabsData?.totalRecordsData || 0;
  const tabsCounts = data?.tabsCounts || {
    new_campaign: 0,
    archived: 0,
    draft: 0,
  };

  useEffect(() => {
    loader(isLoading || isValidating);
  }, [isLoading, loader, isValidating]);

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        setTabType("new_campaign");
        break;
      case 1:
        setTabType("draft");
        break;
      case 2:
        setTabType("archived");
        break;
      default:
        setTabType("new_campaign");
    }
  }, [selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCurrentPage(1); // Reset to first page when tab changes
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const renderPage = () => {
    return (
      <>
        <div className="dashboard participant-dashboard">
          <UnJoinedCampaigns
            selectedBrand={selectedBrand}
            getCampaigns={launchCampaignData}
            isThereAnyActiveSubscription
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
            tabsCounts={tabsCounts}
            totalRecordsData={totalRecordsData}
            pageCount={pageCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <PageLayout className="participant-dashboard-container mx-0 mx-sm-2">
        {renderPage()}
      </PageLayout>
    </>
  );
};

export default LaunchDigitalCampaigns;
