import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, InputAdornment } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      color: theme.palette.text.primary,
      borderRadius: "4px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.divider}`,
      },
      "& .MuiInputAdornment-root": {
        "& img": {
          filter: "none",
        },
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.text.primary,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: `none`,
        },
        "& .MuiInputAdornment-root": {
          "& img": {
            filter: "opacity(0.38)",
          },
        },
        "& input::placeholder": {
          color: theme.palette.action.disabled,
          opacity: 1,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.divider,
        },
      },
      "& input::placeholder": {
        color: theme.palette.text.secondary,
        opacity: 1,
      },
    },
  },
  darkTheme: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInputAdornment-root": {
        "& img": {
          filter: "brightness(0) invert(1)",
        },
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.text.primary}`,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& input::placeholder": {
        color: theme.palette.primary.contrastText,
        opacity: 0.7,
      },
    },
  },
  "@global": {
    ".MuiPickersModal-dialogRoot, .MuiPickersPopper-paper": {
      "& .MuiPickersDay-day": {
        "&:not(.MuiPickersDay-dayDisabled)": {
          // border: `1px solid ${theme.palette.primary.light}`,
          border: `1px solid ${theme.palette.grey[500]}`,
          borderRadius: "50%",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        "&.MuiPickersDay-dayDisabled": {
          color: theme.palette.text.disabled,
          border: "none",
        },
        "&.MuiPickersDay-current": {
          color: theme.palette.primary.main,
        },
        "&.MuiPickersDay-daySelected": {
          backgroundColor: `${theme.palette.primary.main} !important`,
          color: `${theme.palette.primary.contrastText} !important`,
          border: "none !important",
        },
      },
    },
  },
}));

const RpDatePicker = ({
  value,
  onChange,
  disabled = false,
  placeholder = "Select Date",
  minDate,
  maxDate,
  shouldDisableDate,
  disablePast = true,
  format = "MMM Do, YYYY",
  darkTheme = false,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateMomentUtils}>
      <DatePicker
        value={value || null}
        onChange={onChange}
        animateYearScrolling
        inputVariant="outlined"
        size="small"
        format={format}
        disableToolbar
        disabled={disabled}
        className={`${classes.root} ${darkTheme ? classes.darkTheme : ""}`}
        disablePast={disablePast}
        placeholder={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        shouldDisableDate={shouldDisableDate}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon component="img" src="/img/icons/calendar.svg" />
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default RpDatePicker;
