import React, { useMemo } from "react";
import { Typography } from "@material-ui/core";
import { subscriptionHelper } from "../../../helpers/subscriptionHelper";
import { CurrencyFormateText } from "../../../components/CurrencyFormat";

export default function BrandMatchCard({ selections }) {
  const brandMatchTotal = useMemo(() => {
    return subscriptionHelper.totalBrandMatchAmount(selections);
  }, [selections]);
  return (
    <div className="card mb-2 brand-match-card">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-6 col-xl-6">
            <Typography
              className="sub-heading"
              gutterBottom
              component="h2"
              variant="h5"
            >
              You received <CurrencyFormateText value={brandMatchTotal} /> of
              Brand Match!
            </Typography>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mt-3">
            <Typography
              component="p"
              variant="caption"
              gutterBottom
              className="mb-3"
            >
              Your brand team pays the brand match for your local marketing,
              therefore you won't be charged for the brand match amount. You
              will see the brand match amount added to your budget in your
              Hyperlocology dashboard.
            </Typography>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            {selections.map((loc) =>
              loc?.packageInfo?.is_brand_match === 1 ? (
                <div className="row mt-2" key={loc.locationInfo.locationId}>
                  <div className="col-lg-8 col-6">
                    <Typography
                      className="brand-match-detail"
                      component="span"
                      variant="body2"
                    >
                      {`${loc.locationInfo.location_name} (${loc.locationInfo.participantName})`}
                    </Typography>
                  </div>

                  <div className="col-lg-4 col-6 d-flex align-items-center justify-content-end">
                    <div className="brand-match-detail">
                      <CurrencyFormateText
                        value={loc?.packageInfo?.brand_match_amount}
                        suffix=" USD"
                      />
                    </div>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
