import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.contrastText,
    color: "#1B262C",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0px 6px 20px #00000029",
  },
}))(Tooltip);

function RpMuiToolTip({ children, content, interactive = false }) {
  return (
    <HtmlTooltip
      title={<React.Fragment>{content}</React.Fragment>}
      interactive={interactive}
    >
      {children}
    </HtmlTooltip>
  );
}

export default RpMuiToolTip;
