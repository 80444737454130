import React, { useState, useEffect } from "react";
import NotYetJoinedCampaignListViewCard from "./NotYetJoinedCampaignListViewCard";
import NotYetJoinedCampaignGridViewCard from "./NotYetJoinedCampaignGridViewCard";
import ArchivedJoinNowButton from "./ArchivedJoinNowButton";
import TablePagination from "../tables/TablePagination";

const ArchivedCampaigns = ({
  campaigns,
  pageCount,
  onArchived,
  viewType = "list",
  removeCampaignFromArchive,
}) => {
  const [campaignData, setCampaignData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (!Array.isArray(campaigns && campaigns)) {
      setCampaignData([]);
    } else {
      setCampaignData(campaigns && campaigns);
    }
  }, [campaigns && campaigns]);

  const renderArchivedJoinNowButton = (campaign) => {
    return (
      <ArchivedJoinNowButton
        campaign={campaign}
        removeCampaignFromArchive={removeCampaignFromArchive}
      />
    );
  };

  const renderListView = () => {
    return (
      <div className="row align-items-stretch list-view">
        {campaignData.map((campaign) => (
          <div className="col-12 p-2" key={campaign.id}>
            <NotYetJoinedCampaignListViewCard
              campaign={campaign.campaign}
              key={campaign.id}
              onArchived={onArchived}
              renderArchivedJoinNowButton={() =>
                renderArchivedJoinNowButton(campaign)
              }
            />
          </div>
        ))}
      </div>
    );
  };

  const renderGridView = () => {
    return (
      <div className="row align-items-stretch grid-view">
        {campaignData.map((campaign) => (
          <div className="col-xl-4 col-md-6 p-2 my-2" key={campaign.id}>
            <NotYetJoinedCampaignGridViewCard
              campaign={campaign.campaign}
              key={campaign.id}
              onArchived={onArchived}
              renderArchivedJoinNowButton={() =>
                renderArchivedJoinNowButton(campaign)
              }
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {viewType === "list" ? renderListView() : renderGridView()}
      <div className="row">
        <div className="col-12 float-right">
          <TablePagination
            page={currentPage}
            count={pageCount}
            onChange={handlePageChange}
            className="float-right"
          />
        </div>
      </div>
    </div>
  );
};

export default ArchivedCampaigns;
