import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { isEmpty } from "lodash-es";
import ModalBootstrap from "../../components/ModalBootstrap";
import Payment from "../../components/stripe/Payment";
import { selfSubscribeFormValidation } from "../../helpers/validations";
import { subscriptionService } from "../../services/subscriptionService";
import SubscriptionForm from "./SubscriptionForm";
import { toastr } from "react-redux-toastr";

const SubscriptionPlan = ({
  planData,
  brandData,
  history,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  resetForm,
  setFieldTouched,
}) => {
  const [makePayment, setMakePayment] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [participantLocations, setParticipantLocations] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchLocationsForEmail = async () => {
    setParticipantLocations([]);
    setLoading(true);
    try {
      const res = await subscriptionService.checkAndFetchParticipantLocations({
        brandId: brandData.id,
        email: values.email,
        packageId: planData.id,
      });
      if (res.status === 200) {
        const locs = res.data.participantLocations;
        setParticipantLocations(locs);
      } else {
        setParticipantLocations([]);
        toastr.error(
          "Error",
          "Email does not exists or participant with this email does not belongs to this brand"
        );
      }
    } catch (err) {
      setParticipantLocations([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      values.email &&
      values.email.trim() !== "" &&
      values.email.trim().length > 2
    ) {
      fetchLocationsForEmail();
    }
  }, []);

  const handleOnClick = () => {
    const keys = Object.keys(values);
    keys.forEach((k) => {
      setFieldTouched(k);
    });
    if (
      isEmpty(errors) &&
      values.email.trim() !== "" &&
      values.locations.length !== 0
    ) {
      setMakePayment(true);
    }
  };

  const getLocationSystemGeneratedId = (locId) => {
    const loc = participantLocations.find((l) => l.id === locId);
    if (loc) {
      return loc.system_generated_location_id;
    }
    return "";
  };

  const getAmountToPay = () => {
    return (
      parseInt(values.locations.length) *
      parseFloat(planData.amount) *
      parseInt(planData.period)
    );
  };

  const handleOnBlurEmail = () => {
    if (
      !errors?.email &&
      values.email &&
      values.email.trim() !== "" &&
      values.email.trim().length > 2
    ) {
      setFieldValue("locations", []);
      fetchLocationsForEmail();
    }

    if (errors.email) {
      setFieldValue("locations", []);
      setParticipantLocations([]);
    }
  };

  return (
    <>
      <SubscriptionForm
        values={values}
        onClick={handleOnClick}
        planData={planData}
        brandData={brandData}
        errors={errors}
        touched={touched}
        participantLocations={participantLocations}
        isLoadingLocations={loading}
        handleBlur={handleBlur}
        handleChange={handleChange}
        onBlurEmail={handleOnBlurEmail}
      />

      {makePayment && (
        <ModalBootstrap
          show={makePayment}
          onClose={() => setMakePayment(false)}
          title="Payment"
        >
          <Payment
            success={() => {
              setTimeout(() => {
                setMakePayment(false);
                setPaymentSuccess(true);
                resetForm();
              }, 2000);
            }}
            billingDetails={{
              email: values.email,
            }}
            data={{
              title: planData.title,
              email: values.email,
              amount: getAmountToPay(),
              locations: values.locations.length
                ? values.locations.map((l) => ({
                    id: l.value,
                    name: l.label,
                    system_generated_location_id: getLocationSystemGeneratedId(
                      l.value
                    ),
                  }))
                : [],
              location_ids: values.locations.length
                ? values.locations.map((l) => l.value).join(",")
                : "",
              system_generated_location_ids: values.locations.length
                ? values.locations
                    .map((l) => {
                      const sysId = getLocationSystemGeneratedId(l.value);
                      return sysId ? sysId : l.value;
                    })
                    .join(",")
                : "",
              fk_brand_id: brandData.id,
              system_generated_brand_id: brandData.system_generated_brand_id,
              fk_brand_name: brandData.brand_name,
              fk_package_id: planData.id,
            }}
            api={subscriptionService.selfSubscribe}
          />
        </ModalBootstrap>
      )}

      {paymentSuccess && (
        <ModalBootstrap
          show={paymentSuccess}
          onClose={() => setPaymentSuccess(false)}
          title="Payment Success"
        >
          <h5 className="text-center mb-3">
            You have successfully subscribed for <br />
            <strong>{planData.title}</strong>
          </h5>
          <h6 className="text-center mb-4">
            Do you have any more locations you would like to subscribe?
          </h6>
          <div className="d-flex-center">
            <button
              className="button secondary mr-3"
              onClick={() => {
                history.replace(`${process.env.PUBLIC_URL}/dashboard`);
                // history.goBack();
              }}
            >
              No
            </button>
            <button
              className="button primary"
              onClick={() => setPaymentSuccess(false)}
            >
              Yes
            </button>
          </div>
        </ModalBootstrap>
      )}
    </>
  );
};

export default withFormik({
  mapPropsToValues: ({ planData, loggedInUser }) => ({
    email: isEmpty(loggedInUser) ? "" : loggedInUser.email,
    locations: [],
    amount: planData.amount,
  }),
  validate: selfSubscribeFormValidation,
  displayName: "SubscriptionPlanForm",
})(SubscriptionPlan);
