import React, { useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { Icon, Link, Typography } from "@material-ui/core";
import Dropdown from "react-bootstrap/Dropdown";

import { authActions } from "../actions";
import { GlobalContext, LoaderContext } from "../helpers/contexts/contexts";
import { RpConfirmDialog } from "../helpers/utils";
import ImageRP from "../components/ImageRP";
import RpConfirm from "../components/RpConfirm";
import { reportUserActivity } from "../helpers/userActivityHelper";
import { clearRedirectPath } from "../helpers/localStore";

const ProfileDropDown = ({ logout }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loggedInUser } = useContext(GlobalContext);

  const loader = useContext(LoaderContext);
  const { first_name, last_name, profile_name, email } = loggedInUser || {};

  /**
   * @method handleLogout
   * @description handle logout
   */
  const handleLogout = () => {
    RpConfirmDialog(
      <RpConfirm
        message="Are your sure you want to logout?"
        heading="Logout"
      />,
      async () => {
        loader(true);
        await reportUserActivity("logout")
          .then(() => {
            logout();
            history.replace("/");
            loader(false);
            dispatch(authActions.resetHasLoggedOut());
            if (typeof window !== undefined) {
              window.location.reload();
            }
          })
          .finally(() => {
            loader(false);
            clearRedirectPath();
          });
      }
    );
  };

  return (
    // <Dropdown className="user-profile align-content-center p-2" alignRight>
    //   <Dropdown.Toggle variant="default" id="dropdown-basic">
    //     <ImageRP
    //       src={profile_name}
    //       type="PROFILE"
    //       loaderBoxStyle={{
    //         width: 35,
    //         height: 35,
    //       }}
    //     />
    //     <div className=" user-detail text-truncate d-none mx-2">
    //       <div className="heading8 white text-truncate name">
    //         {`${first_name} ${last_name}`}
    //       </div>

    //       {pathname === "/home" && loggedInUserRole === "PR" && (
    //         <div className="user_id text-truncate">
    //           ID:{" "}
    //           {loggedInUser?.participantData?.system_generated_participant_id}
    //         </div>
    //       )}
    //     </div>
    //   </Dropdown.Toggle>

    //   <Dropdown.Menu className="py-0">
    //     <ImageRP
    //       src={profile_name}
    //       type="PROFILE"
    //       loaderBoxStyle={{
    //         width: 35,
    //         height: 35,
    //       }}
    //     />
    //     <span className="user-name text-truncate ml-2">
    //       {`${first_name} ${last_name}`}
    //       <p>{email}</p>
    //     </span>
    //     <NavLink to="/dashboard/profile" className="profile-link">
    //       <Icon fontSize="small" className="mr-1">
    //         person_outline
    //       </Icon>
    //       My Profile
    //     </NavLink>
    //     <Dropdown.Divider />

    //     <Dropdown.Item onClick={handleLogout}>
    //       <Icon fontSize="small" className="mr-2" color="error">
    //         power_settings_new
    //       </Icon>
    //       Logout
    //     </Dropdown.Item>
    //   </Dropdown.Menu>
    // </Dropdown>

    <Dropdown className="user-profile align-content-center" alignRight>
      <Dropdown.Toggle
        variant="default"
        id="dropdown-basic"
        className="pr-0 pr-lg-3"
      >
        <ImageRP
          src={profile_name}
          type="PROFILE"
          loaderBoxStyle={{ width: 35, height: 35 }}
        />
        <Typography
          variant="subtitle2"
          className="user-detail text-truncate d-none d-lg-block mx-2"
        >{`${first_name} ${last_name}`}</Typography>
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-0">
        <div className="p-3 d-flex align-items-center">
          <ImageRP
            src={profile_name}
            type="PROFILE"
            loaderBoxStyle={{ width: 35, height: 35 }}
          />
          <div className="user-name text-truncate ml-2">
            <div className="heading8 text-truncate name">
              {`${first_name} ${last_name}`}
            </div>

            <div className="text-truncate user-email user-select-all">
              {email}
            </div>
            <NavLink to="/dashboard/profile" className="profile-link">
              <Icon fontSize="small">person_outline</Icon>
              My Profile
            </NavLink>
            {/* {pathname === "/home" && loggedInUserRole === "PR" && (
              <div className="user_id text-truncate">
                ID:
                {loggedInUser?.participantData?.system_generated_participant_id}
              </div>
            )} */}
          </div>
        </div>
        <Dropdown.Divider />
        <div className="d-flex justify-content-between px-2 py-3">
          <div
            className="p-2 user-logout"
            onClick={handleLogout}
            style={{ cursor: "pointer" }}
          >
            <Icon fontSize="small" className="mr-2" color="error">
              power_settings_new
            </Icon>
            Logout
          </div>
          {/* <NavLink to="#" className="profile-link p-2">
            Privacy Policy | Cookie Policy
          </NavLink> */}
          <div className="profile-links">
            <Link
              href="http://www.hyperlocology.com/privacy-policy"
              className="profile-link"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <span style={{ fontSize: "x-small" }}>|</span>
            <Link
              href="http://www.hyperlocology.com/cookie-policy"
              className="profile-link"
              target="_blank"
            >
              Cookie Policy
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default connect(null, {
  logout: authActions.logout,
})(ProfileDropDown);
