import React, { memo } from "react";
import { Paper, Typography } from "@material-ui/core";

import NumberFigure from "../../../components/NumberFigure";

export default memo(function SubscriptionOverview({ locationStats }) {
  const {
    subscribedLocationCount,
    unSubscribedLocationCount,
    upcomingRenewalCount,
  } = locationStats || {};
  return (
    <div className="subscription-overview">
      <div className="row align-items-center my-3">
        <div className="col-md-6 mb-2">
          <Typography
            component="h2"
            variant="body1"
            className="mb-0 font-weight-bold"
            color="primary"
          >
            {/* Subscription Overview */}
          </Typography>
        </div>
        {/* <div className="col-md-6 mb-2 mt-4 mt-md-0 text-right d-flex align-items-center justify-content-end">
          {loggedInUserRole === "BU" &&
          selectedBrand?.has_active_subscription_page &&
          isBrandUser === true ? (
            <>
              {selectedBrand.id !== 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/manage-subscription"
                  className="ml-2"
                  size="small"
                >
                  Manage Active Plans
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/subscription-packages"
                className="ml-2"
                size="small"
              >
                + Add Subscription
              </Button>
            </>
          ) : null}
        </div> */}
      </div>

      <div className="row">
        <div className="col-md-4 mb-2">
          <Paper elevation={0} className="p-3 stats">
            <NumberFigure
              title="Active Locations"
              value={subscribedLocationCount || 0}
              className="text-center text-md-left"
              titleClass="text18M_SemiBold83898E"
              decimalScale={0}
            />
          </Paper>
        </div>
        <div className="col-md-4 mb-2">
          <Paper elevation={0} className="p-3 stats">
            <NumberFigure
              title="Inactive Locations"
              value={unSubscribedLocationCount || 0}
              className="text-center text-md-left"
              titleClass="text18M_SemiBold83898E"
              decimalScale={0}
            />
          </Paper>
        </div>
        <div className="col-md-4 mb-2">
          <Paper elevation={0} className="p-3 stats">
            <NumberFigure
              title="Upcoming Renewals"
              value={upcomingRenewalCount || 0}
              className="text-center text-md-left"
              titleClass="text18M_SemiBold83898E"
              decimalScale={0}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
});
