import { settingsConstants } from "../config/constants";

const initialState = {
  error: null,
  loading: false,
  brandSettings: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case settingsConstants.GET_SETTINGS:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case settingsConstants.SET_SETTINGS:
      return {
        ...state,
        loading: false,
        error: false,
        brandSettings: action.payload,
      };
    default:
      return state;
  }
};
