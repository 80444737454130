import React, { useState, useEffect, memo } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";

const FAQ = ({ faqs = [] }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (faqs.length) {
      setExpanded(faqs[0].id);
    }
  }, [faqs]);

  return (
    <div className="faq w-100">
      {faqs.map((d) => (
        <Accordion
          key={d.id}
          expanded={expanded === d.id}
          onChange={handleChange(d.id)}
          className="ac-faq-card"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className="mb-0 faq-question"
              component="h5"
              variant="body1"
            >
              {d.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faq-answer" component="p">
              {d.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default memo(FAQ);
