import React from "react";
import { MenuItem, Select } from "@material-ui/core";

export default function TablePageSize({
  pageSize,
  pageSizeOptions = [10, 25, 50],
  setPageSize,
}) {
  return (
    <div className="table-page-size">
      <Select
        size="small"
        margin="dense"
        value={pageSize}
        onChange={(e) => setPageSize(e.target.value)}
        disableUnderline
        variant="outlined"
        inputProps={{
          margin: "dense",
          size: "small",
        }}
      >
        {pageSizeOptions.map((s) => (
          <MenuItem key={s} value={s}>
            {s}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
