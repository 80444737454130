import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import businessMoment from "moment-business-days";
import { uniq, uniqBy } from "lodash";
import wNumb from "wnumb";
import { Icon } from "@material-ui/core";

import { IMAGE_BASE_URL } from "../config/appConfig";
import { STATIC_IMAGES } from "./staticImages";
import { toastr } from "react-redux-toastr";
import RpPopover from "../components/popover/RpPopover";
import { logger } from "./loggerHelper";
import store from "../config/store";

/**
 * @method RPNumberFormat
 * @description return formatted number
 * @param {decimals: 2, prefix: "", suffix: "", thousand: ","} options
 */
export const RPNumberFormat = (
  options = { prefix: "", suffix: "", thousand: "," }
) => wNumb({ thousand: ",", ...options });

/**
 * @method getImage
 * @description return image
 * @param {*} image
 */
export const getImage = (image, type = "") => {
  if (image) {
    switch (type) {
      case "PROFILE":
        return `${IMAGE_BASE_URL}/uploads/profile_image/${image}`;
      case "BRAND":
        return `${IMAGE_BASE_URL}/uploads/brand_logo/${image}`;
      case "COMPANY":
        return `${IMAGE_BASE_URL}/uploads/company_logo/${image}`;
      case "CREATIVE":
        return `${IMAGE_BASE_URL}/uploads/creative/${image}`;
      case "IMG":
        return image;
      default:
        return `${process.env.PUBLIC_URL}/img/user.png`;
    }
  } else {
    switch (type) {
      case "PROFILE":
        return `${process.env.PUBLIC_URL}/img/user.png`;
      case "BRAND":
        return `${process.env.PUBLIC_URL}/img/dummyLogo.png`;
      case "COMPANY":
        return `${process.env.PUBLIC_URL}/img/dummyLogo.png`;
      case "CREATIVE":
        return `${process.env.PUBLIC_URL}/img/creative-img.png`;
      case "IMG":
        return `${process.env.PUBLIC_URL}/img/img.png`;
      default:
        return `${process.env.PUBLIC_URL}/img/user.png`;
    }
  }
};

/**
 * @method getFallbackImage
 * @description return fallback image
 * @param {*} image
 */
export const getFallbackImage = (type = "") => {
  switch (type) {
    case "PROFILE":
      return `${process.env.PUBLIC_URL}/img/user.png`;
    case "BRAND":
      return `${process.env.PUBLIC_URL}/img/dummyLogo.png`;
    case "COMPANY":
      return `${process.env.PUBLIC_URL}/img/dummyLogo.png`;
    case "CREATIVE":
      return `${process.env.PUBLIC_URL}/img/creative-img.png`;
    default:
      return `${process.env.PUBLIC_URL}/img/user.png`;
  }
};

/**
 * @method getProfileImage
 * @description return profile image
 * @param {*} image
 */
export const getProfileImage = (image) => {
  return `${IMAGE_BASE_URL}/uploads/profile_image/${image}`;
};

/**
 * @method getCampaignStatusText
 * @description return campaign status text
 * @param {*} status
 */
export const getCampaignStatusText = (status) => {
  switch (status) {
    case 1:
      return "Live"; // Active
    case 2:
      return "Scheduled";
    case 3:
      return "Completed";
    case 4:
      return "Paused";
    default:
      return "Inactive";
  }
};

/**
 * @method getBase64
 * @description return base64 of the provided file
 * @param {*} file
 */
export const getBase64 = (file) => {
  return new Promise((resolve, rejects) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return resolve(reader.result);
    };
    reader.onerror = function (error) {
      return rejects(error);
    };
  });
};

/**
 * @method getYouTubeVideoId
 * @description extract id from any yu tube url
 * @param {*} url
 */
export const getYouTubeVideoId = (url) => {
  // const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;

  const match = url.match(regExp);
  // return match && match[7].length === 11 ? match[7] : false;
  return match && match[1].length === 11 ? match[1] : false;
};

/**
 * @method isYouTubeUrl
 * @description return true for valid YouTube url
 * @param {*} ulr
 */
export const isYouTubeUrl = (url) =>
  /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/.test(url);

/**
 * @method extractVideoIdFromUrl
 * @description return youtube videoId for valid YouTube url
 * @param {*} url
 */
export function extractVideoIdFromUrl(url) {
  const match = url.match(
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/(watch\?v=|embed\/|v\/)?([a-zA-Z0-9_-]{11})/
  );
  return match && match[5] ? match[5] : null;
}

/**
 * @method parseJson
 * @description return parsed json object
 * @param {*} jsonString
 */
export const parseJson = (jsonString) => {
  if (!jsonString) return null;
  try {
    return JSON.parse(jsonString);
  } catch (err) {
    logger.error("Error in parseJson", { err });
  }
};

/**
 * @method getFormatDate
 * @description return date in a format
 * @param {*} date
 * @param {String} format
 */
export const getFormatDate = (date, format = "MMM DD, YYYY") => {
  return date && moment(date).isValid() ? moment(date).format(format) : "";
};

/**
 * @method getMonthLabels
 * @description return month labels
 */
export const getMonthLabels = (start = 0) => {
  const labels = [
    { label: "Jan", value: 1 },
    { label: "Feb", value: 2 },
    { label: "Mar", value: 3 },
    { label: "Apr", value: 4 },
    { label: "May", value: 5 },
    { label: "Jun", value: 6 },
    { label: "Jul", value: 7 },
    { label: "Aug", value: 8 },
    { label: "Sep", value: 9 },
    { label: "Oct", value: 10 },
    { label: "Nov", value: 11 },
    { label: "Dec", value: 12 },
  ];

  if (start !== 0) {
    let a = labels.slice(start);
    let b = labels.slice(0, start);
    return [...a, ...b];
  }

  return labels;
};

/**
 * @method getBrandContributionAmount
 * @description return brand contribution. Pass last parameter false for non-format value
 * @param {*} val
 */
export const getBrandContributionAmount = (
  val,
  contribution_percentage,
  contribution_max_limit,
  brand_contribution = 1,
  format = true
) => {
  if (brand_contribution === 0) {
    return;
  }

  let cont = 0;

  if (contribution_max_limit && contribution_percentage) {
    const brandPercent = (val * 1 * contribution_percentage) / 100;
    cont =
      contribution_max_limit < brandPercent
        ? contribution_max_limit.toFixed(2)
        : brandPercent.toFixed(2);
  } else if (contribution_percentage) {
    cont = ((val * 1 * contribution_percentage) / 100).toFixed(2);
  } else if (contribution_max_limit) {
    cont = contribution_max_limit.toFixed(2);
  }
  if (format) {
    return RPNumberFormat({ prefix: "$", decimals: 2 }).to(parseFloat(cont));
  }
  return parseFloat(cont);
};

/**
 * @method getChannels
 * @description return channels name
 */
export const getChannels = (package_channels) => {
  return package_channels
    ? uniq(package_channels.map((c) => c?.channel?.channel_name)).join(", ")
    : "There are none right now";
};

/**
 * @method getChannelIcons
 * @description return icons images tag for the given channels
 * @param {[]} channels
 * @returns
 */
export function getChannelIcons(channels = []) {
  if (!channels || channels.length === 0) return "";
  if (!Array.isArray(channels)) {
    return "";
  }

  const images = uniq(channels)
    .map((ch) => {
      return { name: ch, image: STATIC_IMAGES[ch.toLowerCase()] };
    })
    .filter((m) => m);

  const popContent = images.slice(3);

  const getIcon = (images) => {
    return (
      <div className="">
        {images.length
          ? images.map((m) => (
              <img
                src={m.image}
                alt={m.name}
                title={m.name}
                className="channel_image"
                width="20"
                style={{ maxWidth: 20, margin: "1.5px", borderRadius: 2 }}
                key={m.name}
              />
            ))
          : ""}
      </div>
    );
  };

  return (
    <div className="d-flex align-items-center flex-wrap">
      {getIcon(images.slice(0, 3))}
      {images.length > 3 && (
        <RpPopover popContent={popContent.length ? getIcon(popContent) : null}>
          <small className="cursor-p" style={{ marginLeft: 2 }}>
            <strong>(+{popContent.length})</strong>
          </small>
        </RpPopover>
      )}
    </div>
  );

  // return (
  //   <div className="d-flex align-items-center flex-wrap">
  //     {images.length
  //       ? images.map((m) => (
  //           <img
  //             src={m.image}
  //             alt={m.name}
  //             title={m.name}
  //             className="img-fluid channel_image"
  //             width="16"
  //             height="16"
  //             style={{ maxWidth: 16, maxHeight: 16, margin: "2px" }}
  //             key={m.name}
  //           />
  //         ))
  //       : ""}
  //   </div>
  // );
}

/**
 * @method canJoinCampaign
 * @description return boolean to decide whether participant can join a campaign or not
 * @param {*} campaign
 */
export const canJoinCampaign = (campaign) => {
  if (!campaign) return false;
  if (
    campaign?.local_selection === 1 ||
    (campaign?.local_selection === 0 &&
      campaign?.budget_type === "Location Specific")
  ) {
    if (campaign?.status === 1 || campaign?.status === 2) {
      if (
        campaign?.participant_campaign &&
        campaign?.budget_type !== "Pre-set"
      ) {
        return campaign?.participant_campaign.is_draft === true ? true : false;
      } else {
        return true;
      }
    } else if (
      campaign?.campaign_type === "Normal" ||
      (campaign?.campaign_type === "Evergreen" &&
        campaign?.evergreen_available_after_completion_flag)
    ) {
      return true;
    }
  }
  return false;
};

/**
 * @method getTwoDecimalDigit
 * @description return up to d digits after decimal without rounding
 * @param {*} n
 * @param {*} d
 */
export const getTwoDecimalDigit = (n, d = 2) => {
  let str = `${n}`.split(".");
  if (str[1]) {
    let dec = str[1].slice(0, d);
    let j = `${str[0]}.${dec}`;
    return parseFloat(j);
  }
  return n;
};

/**
 * @method formateKeyName
 * @description return formatted key name
 * @param {*} key
 */
export const formateKeyName = (key = "") => {
  return key.replace(/_/g, " ");
};

/**
 * @method getFormattedData
 * @description return formatted data
 * @param {*} data
 * @param {*} key
 */
export const getFormattedData = (data, key = "", formats = null) => {
  if (formats && key && formats[key]) {
    return formats[key].prefix + data + formats[key].suffix;
  }
  return data;
};

/**
 * @method getChartColors
 * @description return chart color set
 * @param {*} set
 */
export const getChartColors = (set = 1) => {
  const set1 = [
    "#507a96",
    "#93a4ca",
    "#8772ac",
    "#c383b8",
    "#e685aa",
    "#ff8953",
    "#ff8e91",
    "#ffba5d",
    "#8fbb7c",
    "#60c4c0",
    "#3980C0",
    "#F78E91",
    "#4BAFE1",
    "#52AF98",
  ];
  const set2 = [
    "#0F5A93",
    "#19659F",
    "#0E76BD",
    "#2F8FCC",
    "#43A6DD",
    "#60C1EC",
  ];
  const set3 = [
    "#00BFFF",
    "#17e3cf",
    "#fb607f",
    "#F38E7C",
    "#FEB420",
    "#3ccf67",
    "#9375eb",
  ];
  switch (set) {
    case 1:
      return set1;
    case 2:
      return set2;
    case 3:
      return set3;
    default:
      return set1;
  }
};

export const joinByDateFormatHelper = (campaign = {}) => {
  const { start_date } = campaign;
  return {
    joinByDate: function (format = "MMM DD, YYYY") {
      let resultDate = "";
      const parsedDate = moment(start_date);
      resultDate = parsedDate.subtract(6, "weeks").format("YYYY-MM-DD");
      return resultDate ? (
        <>
          {moment(resultDate).isValid() ? (
            <Icon
              component="img"
              src="/img/icons/calendar.png"
              fontSize="small"
            />
          ) : null}
          {getFormatDate(resultDate, format)}
        </>
      ) : (
        "N/A"
      );
    },
  };
};
/**
 * @method campaignFormat
 * @description format campaign data
 * @param {*} campaign
 */
export const campaignFormatHelper = (campaign = {}) => {
  const {
    id,
    campaign_name,
    status,
    system_generated_campaign_id,
    campaign_type,
    participant_campaign,
    start_date,
    end_date,
    channel_name,
    is_previous_record_available,
    participant_template,
    brand_owner_template,
    parent_company_template,
  } = campaign;

  return {
    isJoined: function () {
      return participant_campaign && participant_campaign.is_draft === false
        ? true
        : false;
    },
    name: function (
      url = `${process.env.PUBLIC_URL}/dashboard/campaign-report`
    ) {
      const isTemplateExists =
        (store.getState().user.role === "PR" && participant_template) ||
        ((store.getState().user.role === "BO" ||
          store.getState().user.role === "BU") &&
          brand_owner_template) ||
        (store.getState().user.role === "PC" && parent_company_template);

      const showLink =
        isTemplateExists &&
        (is_previous_record_available ||
          (this.isJoined() && (status === 1 || status === 3 || status === 4)));
      return showLink ? (
        <Link
          to={{
            pathname: `${url}/${system_generated_campaign_id}`,
            state: {
              campaignId: id,
              campaignSysId: system_generated_campaign_id,
              from: "/dashboard",
            },
          }}
          className={`title-val campaign-name text-break ${
            showLink ? "main link" : ""
          }`}
          title={campaign_name}
        >
          {campaign_name}
        </Link>
      ) : (
        <span
          className={`title-val campaign-name text-break`}
          title={campaign_name}
        >
          {campaign_name}
        </span>
      );
    },
    status: function () {
      let className = "title-val";
      let campaignStatus = status;

      if (this.isJoined()) {
        // campaign joined
        campaignStatus = participant_campaign.status;

        if (participant_campaign.status === 1) {
          className = "title-val status-active";
        }
      } else {
        // campaign not joined
        campaignStatus = status;
        if (status === 1) {
          className = "title-val status-active";
        }
      }

      return (
        <span className={className}>
          {getCampaignStatusText(campaignStatus)}
        </span>
      );
    },
    startDate: function (format = "MMM DD, YYYY") {
      let startDate = "";
      if (this.isJoined()) {
        // campaign joined
        if (campaign_type === "Evergreen") {
          startDate = participant_campaign.start_date;
        } else {
          startDate = participant_campaign.start_date;
        }
      } else {
        // campaign not joined
        if (campaign_type === "Evergreen") {
          startDate = "Evergreen";
        } else {
          startDate = start_date;
        }
      }

      if (startDate === "Evergreen") {
        return "TBD";
      }
      return startDate ? (
        <>
          {moment(startDate).isValid() ? (
            <Icon
              component="img"
              src="/img/icons/calendar.png"
              fontSize="small"
            />
          ) : null}
          {getFormatDate(startDate, format)}
        </>
      ) : (
        "N/A"
      );
    },

    endDate: function (format = "MMM DD, YYYY") {
      let endDate = "";
      if (this.isJoined()) {
        // campaign joined
        if (campaign_type === "Evergreen") {
          endDate = participant_campaign.end_date;
        } else {
          endDate = participant_campaign.end_date;
        }
      } else {
        // campaign not joined
        if (campaign_type === "Evergreen") {
          endDate = "Evergreen";
        } else {
          endDate = end_date;
        }
      }

      if (endDate === "Evergreen") {
        return "TBD";
      }

      return endDate ? (
        <>
          {moment(endDate).isValid() ? (
            <Icon
              component="img"
              src="/img/icons/calendar.png"
              fontSize="small"
            />
          ) : null}
          {getFormatDate(endDate, format)}
        </>
      ) : (
        "N/A"
      );
    },
    getChannelIcons: function () {
      if (!channel_name) return "";
      const chArr = channel_name.split(",").sort();
      return getChannelIcons(chArr);
    },
  };
};

/**
 * @method getContrastBWColor
 * @param {*} hexcolor
 */
export const getContrastBWColor = (hexcolor) => {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      .map(function (hex) {
        return hex + hex;
      })
      .join("");
  }

  // Convert to RGB value
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  // return yiq >= 128 ? "black" : "white";
  return yiq >= 140 ? "black" : "white";
};

/**
 * @method getFormattedCampaignDate
 * @description return formatted campaign date
 * @param {*} campaign
 * @param {*} otherData
 * @returns
 */
export const getFormattedCampaignDate = (campaign) => {
  if (!campaign) return "";
  if (campaign?.start_date && campaign?.end_date) {
    return `${getFormatDate(campaign?.start_date)} - ${getFormatDate(
      campaign?.end_date
    )}`;
  }
  if (campaign?.campaign_type === "Evergreen") {
    return "Evergreen";
  } else {
    return "N/A";
  }
};

export const getFormattedCampaignStartDate = (campaign) => {
  if (!campaign) return "";
  if (campaign?.start_date) {
    return `${getFormatDate(campaign?.start_date)}`;
  } else if (campaign?.campaign_type === "Evergreen") {
    return "Evergreen";
  } else {
    return "N/A";
  }
};

export const getFormattedCampaignEndDate = (campaign) => {
  if (!campaign) return "";
  if (campaign?.end_date) {
    return `${getFormatDate(campaign?.end_date)}`;
  } else if (campaign?.campaign_type === "Evergreen") {
    return "Evergreen";
  } else {
    return "N/A";
  }
};

/**
 * @method hasUniqDataBy
 * @description check is the array of object has uniq data by the given field
 * @param {*} data
 * @param {*} by
 * @returns
 */
export const hasUniqDataBy = (data, by = "") => {
  return uniqBy(data, by).length === data.length;
};

/**
 * @method makeUniqChartData
 * @description make the chart data uniq by the given field
 * @param {*} data
 * @param {*} by
 * @returns
 */
export const makeUniqChartData = (data, by) => {
  if (!by) return data;
  if (hasUniqDataBy(data, by)) {
    return data;
  }
  const doneFor = [];
  return data.map((d) => {
    if (
      data.filter((o) => o[by] === d[by]).length > 1 &&
      !doneFor.includes(d[by])
    ) {
      doneFor.push(d[by]);
      const cp = { ...d };
      delete cp[by];
      const newData = { [by]: d[by] + " ", ...cp };
      return newData;
    }
    return d;
  });
};

export const getLocationSubscriptionStatus = (loc) => {
  const subscriptionData = loc?.subscriptionData[0];

  if (!subscriptionData) return 0;

  if (!subscriptionData?.isSubscribed) {
    return 0;
  } else if (subscriptionData?.isFallOut) {
    return -1;
  } else {
    return 1;
  }
};

export const isAllParticipantLocationsSubscribed = (locs) => {
  return locs.every((loc) => {
    const subscriptionData = loc?.subscriptionData.find(
      (d) =>
        d.system_generated_location_ids === loc.system_generated_location_id
    );
    if (!subscriptionData) return false;
    if (!subscriptionData?.isSubscribed || subscriptionData?.isFallOut) {
      return false;
    }
    return true;
  });
};

export const isAllParticipantLocationsNotSubscribed = (locs) => {
  return locs.every((loc) => {
    const subscriptionData = loc?.subscriptionData.find(
      (d) =>
        d.system_generated_location_ids === loc.system_generated_location_id
    );
    if (!subscriptionData) return true;
    if (!subscriptionData?.isSubscribed || subscriptionData?.isFallOut) {
      return true;
    }
    return false;
  });
};

export const getParticipantSubscribedLocationIds = (locs) => {
  return locs
    .filter((loc) => {
      const subscriptionData = loc?.subscriptionData.find(
        (d) =>
          d.system_generated_location_ids === loc.system_generated_location_id
      );
      if (!subscriptionData) return false;
      if (!subscriptionData?.isSubscribed || subscriptionData?.isFallOut) {
        return false;
      }
      return true;
    })
    .map((l) => l.subscriptionData[0].fk_location_id);
};

export const isThereAnyLocationUnsubscribed = (locs) => {
  return locs.some((loc) => {
    const subscriptionData = loc?.subscriptionData.find(
      (d) =>
        d.system_generated_location_ids === loc.system_generated_location_id
    );
    if (!subscriptionData) return true;
    if (!subscriptionData?.isSubscribed) {
      return true;
    }
    return false;
  });
};

export const isCampaignFallOutsideAnyLocationSubscription = (locs) => {
  return locs.some((loc) => {
    const subscriptionData = loc?.subscriptionData.find(
      (d) =>
        d.system_generated_location_ids === loc.system_generated_location_id
    );
    // if (!subscriptionData) return true;

    // subscribed but subscription end before campaign end date
    if (subscriptionData?.isSubscribed && subscriptionData?.isFallOut) {
      return true;
    }

    return false;
  });
};

export const RpConfirmDialog = (
  message = "",
  onOk = () => {},
  onCancel = () => {},
  id = "rp-confirm-dialog"
) => {
  const toastrConfirmOptions = {
    onOk: onOk,
    onCancel: onCancel,
    okText: "Yes",
    cancelText: "No",
    id,
  };
  toastr.confirm(message, toastrConfirmOptions);
};

/**
 * @method getTotalSpendSum
 * @description return total of total spend
 * @param {*} widget
 * @returns
 */
export const getTotalSpendSum = (widget) => {
  if (!widget?.data?.[0]) {
    return null;
  }
  const labels = Object.keys(widget.data[0]);
  const data = widget.data;
  const formats = widget.format;
  if (!data || data.length.length === 0) return "$0";
  return RPNumberFormat({
    prefix: formats && formats[labels[1]] ? formats[labels[1]].prefix : "",
    suffix: formats && formats[labels[1]] ? formats[labels[1]].suffix : "",
    decimals: 2,
  }).to(
    data.reduce(
      (t, d) =>
        d[labels[1]] && parseFloat(d[labels[1]])
          ? t + parseFloat(d[labels[1]])
          : t,
      0
    )
  );
};

/**
 * @method isInLastNBusinessDay
 * @description return if given date is in last N business days or after the last business day
 * @param {*} date
 * @param {*} days
 * @returns {boolean|null}
 */
export function isInLastNBusinessDay(date, days = 4) {
  if (!date) return null;
  const toDay = businessMoment(date);

  if (!toDay.isValid()) return null;
  const monthBusinessDays = toDay.monthBusinessDays();

  const lastNBusinessDays = monthBusinessDays.slice(-days);
  const formattedDates = lastNBusinessDays.map((d) => d.format("YYYY-MM-DD"));

  const firstBusinessDay = moment(lastNBusinessDays[0]);
  const lastMonthDay = moment(date).endOf("month");

  const isBetween =
    toDay.isSameOrAfter(firstBusinessDay) &&
    toDay.isSameOrBefore(lastMonthDay, "day");

  // Check if the given date is within the last N business days or between first business day and end of month
  return formattedDates.includes(toDay.format("YYYY-MM-DD")) || isBetween;
}

/**
 * @method isTodayBetweenDates
 * @description return boolean if today is between the given dates
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export function isTodayBetweenDates(startDate, endDate) {
  if (!startDate || !endDate) return null;
  const start = moment(startDate);
  const end = moment(endDate);
  if (!start.isValid() || !end.isValid()) {
    return null;
  }
  return moment().isBetween(start, end);
}

/**
 * @method downloadFileAnchorTag
 * @description create anchor tag and set file and download file
 * @param {*} filename
 * @param {*} url
 */
export function downloadFileAnchorTag(url, filename = "file") {
  const anchorEl = document.createElement("a");
  anchorEl.setAttribute("href", url);
  anchorEl.setAttribute("download", filename);
  anchorEl.style.display = "none";
  document.body.appendChild(anchorEl);
  anchorEl.click();
  document.body.removeChild(anchorEl);
}

/**
 * @method makeFirstCharCap
 * @description make first char capital and return
 * @param {*} str
 * @returns
 */
export function makeFirstCharCap(str = "") {
  return str && typeof str === "string"
    ? str.charAt(0).toUpperCase() + str.slice(1)
    : str;
}

/**
 * @method addEmptyOption
 * @description add an empty option as first element of array
 * @param {*} options
 * @param {*} label
 * @returns
 */
export function addEmptyOption(options = [], label = "Select") {
  const newOptions = [...options];
  newOptions.unshift({
    label: label,
    value: "",
  });
  return newOptions;
}

/**
 * @method getStatusCode
 * @description return status code based on status string
 * @param {*} status
 * @returns
 */
export function getStatusCode(status = "", altCode = "") {
  let statusCode = "";
  switch (status) {
    case 1:
    case "Active":
    case "active":
    case "Live":
      statusCode = "success";
      break;
    case 2:
    case "Scheduled":
    case "scheduled":
      statusCode = "warn";
      break;
    case 3:
    case "Completed":
    case "completed":
      statusCode = "info";
      break;
    case 4:
    case "Inactive":
    case "inactive":
    case "Paused":
    case "paused":
      statusCode = altCode ? altCode : "error";
      break;
    default:
      statusCode = "default";
  }
  return statusCode;
}

/**
 * @method getUserTypeColor
 * @description return color code according the user type
 */
export function getUserTypeColor(role = "PR") {
  const userType = {
    PC: "#F89D2B",
    BO: "#F89D2B",
    PR: "#00C4EE",
    BU: "#00C4EE",
  };
  return userType[role];
}

export function roundDownToClosestRangeValue(number, range) {
  if (typeof number !== "number" || isNaN(number)) {
    return 0;
  } else if (!range || !Array.isArray(range)) {
    return 0;
  }

  // Sort the range values in ascending order
  range.unshift(0);
  const sortedRange = range.sort((a, b) => a - b);

  // Find the closest lower value from the sorted range
  let closestLowerValue = sortedRange[0];
  for (const value of sortedRange) {
    if (value <= number) {
      closestLowerValue = value;
    } else {
      break; // Exit the loop when we find a value greater than the number
    }
  }

  return closestLowerValue;
}

export function roundUpToClosestRangeValue(number, range) {
  if (typeof number !== "number" || isNaN(number)) {
    return 0;
  } else if (!range || !Array.isArray(range)) {
    return 0;
  }

  // Sort the range values in ascending order
  range.unshift(0);
  const sortedRange = range.sort((a, b) => a - b);

  // Find the closest higher value from the sorted range
  let closestHigherValue = sortedRange[sortedRange.length - 1];
  for (let i = sortedRange.length - 1; i >= 0; i--) {
    if (sortedRange[i] >= number) {
      closestHigherValue = sortedRange[i];
    } else {
      break; // Exit the loop when we find a value less than the number
    }
  }

  return closestHigherValue;
}

/**
 * @constant isSafariBrowser
 * @description return true if current browser is safari
 *
 */
export const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(
  navigator?.userAgent
);

/**
 * @method addParamsToIframeSrc
 * @description add params to the ifame src
 * @param {*} iframeHtml
 * @param {*} paramsObject
 * @returns
 */
export const addParamsToIframeSrc = (iframeHtml, paramsObject) => {
  // Create a temporary div to parse the iframe HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = iframeHtml;

  // Find the iframe element
  const iframeElement = tempDiv.querySelector("iframe");

  if (iframeElement) {
    // Get the current src attribute
    const currentSrc = iframeElement.getAttribute("src");

    // Convert the paramsObject to a parameter string
    const paramString = Object.entries(paramsObject)
      .map(([key, value]) => `p.${key}=${encodeURIComponent(value)}`)
      .join("&");
    // .join(encodeURIComponent("&"));

    // Add the params to the src
    const newSrc =
      currentSrc?.trim() +
      (currentSrc.includes("#") ? "" : "#") +
      paramString?.trim();

    // Update the src attribute
    iframeElement.setAttribute("src", newSrc);
    iframeElement.setAttribute("loading", "lazy");

    // Get the updated HTML
    const updatedIframeHtml = tempDiv.innerHTML;

    return updatedIframeHtml;
  } else {
    // If no iframe element found, return the original HTML
    return iframeHtml;
  }
};

/**
 * @method removeCampaignByNameLike
 * @description filter the campaigns by like campaign name
 */
export const removeCampaignByNameLike = (campaigns = [], names = []) => {
  if (!names?.length) return campaigns;
  const lowerCaseNames = names.map((name) => name.toLowerCase());
  return campaigns?.filter((c) => {
    if (typeof c?.campaign_name === "string") {
      const campaignWords = c.campaign_name.toLowerCase().split(" ");
      return !campaignWords.some((word) => lowerCaseNames.includes(word));
    }
    return true;
  });
};

/**
 * @method downloadByLink
 * @description create anchor tag and download file
 * @param {*} url
 */
export function downloadByLink(url) {
  if (!url) return;
  const anchorEl = document.createElement("a");
  anchorEl.href = url;
  anchorEl.download = "";
  anchorEl.style.display = "none";
  document.body.appendChild(anchorEl);
  anchorEl.click();
  document.body.removeChild(anchorEl);
}

/**
 * @method isTodayBetween24AndEndOfMonth
 * @description Checks if today is between the 25th of the current month and the end of the current month.
 * @return {boolean} True if today is between the 25th of the current month and the end of the current month, false otherwise.
 */
export const isTodayBetween25AndEndOfMonth = (today = moment()) => {
  const twentyFourth = moment(today).date(25);
  const endOfMonth = moment(today).endOf("month");
  return moment(today).isBetween(twentyFourth, endOfMonth, "day", "[]");
};

export const getHistoryTablesFormatDate = (date, format) => {
  return date && moment(date).isValid() ? moment(date).format(format) : "";
};

export const keyToName = (key = "") => {
  let str = key.replaceAll("_", " ");
  str = str
    ?.split(" ")
    ?.map((s) => `${s.charAt(0)?.toUpperCase()}${s.substr(1)}`)
    ?.join(" ");
  return str;
};

/**
 * Merges two arrays of objects, updating existing items with new data
 * based on a unique key while preserving the order
 * @param {Array} prevArray - Array of existing objects
 * @param {Array} newArray - Array of new objects to merge
 * @param {string} uniqueKey - Key to use for unique identification
 * @returns {Array} Merged array with updated data
 */
export const mergeArraysWithUniqueKey = (
  prevArray = [],
  newArray = [],
  uniqueKey
) => {
  const itemMap = new Map(prevArray.map((item) => [item[uniqueKey], item]));

  newArray.forEach((newItem) => {
    itemMap.set(newItem[uniqueKey], newItem);
  });

  return Array.from(itemMap.values());
};

// Define a regex to match strings starting with http:// or https://
export const urlRegex = /^https?:\/\//;

// Helper to check if value contains URLs
export const containsUrls = (value) => {
  if (typeof value === "string") return urlRegex.test(value);
  if (Array.isArray(value)) {
    return value.some((item) =>
      typeof item === "object"
        ? urlRegex.test(item.file_url)
        : urlRegex.test(item)
    );
  }
  return false;
};
