import { useMemo, useState } from "react";
import { Icon, Typography, Button } from "@material-ui/core";

import { subscriptionHelper } from "../../../helpers/subscriptionHelper";
import StatusChip from "../../../components/muiCustom/chip/StatusChip";
import RpImageTextInline from "../../../components/RpImageTextInline";
import { CurrencyFormateText } from "../../../components/CurrencyFormat";
import RpMUIDialog from "../../../components/modals/RpMUIModal";
import BrandMatchDialog from "./BrandMatchDialog";

const LocationCalculation = ({ data, isChangingUpcomingPackage }) => {
  const renderSubscriptionDate = useMemo(() => {
    const { startDate, endDate } = subscriptionHelper.calculateSubscriptionDate(
      data?.packageInfo,
      data?.locationInfo
    );

    return `${startDate.format("MMM D, YYYY")} - ${endDate.format(
      "MMM D, YYYY"
    )}`;
  }, [data]);

  const renderRenewalLabel = () => {
    let label = "Renewal";
    const isRenewing = subscriptionHelper.isRenewing(data?.locationInfo);
    const isDelayedRenewing = data?.locationInfo?.isDelayed;

    if (isDelayedRenewing) {
      label = "Expired";
    }
    if (!isRenewing && !isDelayedRenewing) return null;

    return (
      <StatusChip
        label={label}
        status={label === "Expired" ? "warn" : "info"}
        className="chip"
      />
    );
  };

  const [openBrandMatch, setOPenBrandMatch] = useState(false);

  const handleClose = () => {
    setOPenBrandMatch(false);
  };
  return (
    <>
      <div className="card mb-2 location-summary">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-xl-4 mb-2 ">
              <Typography
                className="mr-2 location-name"
                color="primary"
                component="span"
                variant="body2"
              >
                {`${data.locationInfo.location_name} (${data.locationInfo.participantName})`}
              </Typography>
              {renderRenewalLabel()}
              {isChangingUpcomingPackage ? (
                <Typography className="tag" variant="body2">
                  (NEW PLAN)
                </Typography>
              ) : null}
            </div>
            <div className="col-lg-3 col-md-6 mb-2">
              <RpImageTextInline
                alignCenter={false}
                image="/img/icons/bucket.png"
                text={
                  <>
                    <div className="title">Media plan</div>
                    <div className="val">{`${data.packageInfo.title}- ${data.packageInfo.period} Months`}</div>
                  </>
                }
              />
            </div>
            <div className="col-lg-3 col-md-6 mb-2">
              <RpImageTextInline
                alignCenter={false}
                image="/img/icons/calendar.png"
                text={
                  <>
                    <div className="title">Plan dates</div>
                    <div className="val">{renderSubscriptionDate}</div>
                  </>
                }
              />
            </div>

            <div className="col-xl-2 col-lg-3 col-md-12 mb-2 text-lg-right d-none d-lg-block">
              <div className="title">
                <Icon
                  component="img"
                  src="/img/icons/money.png"
                  fontSize="small"
                  className="mr-1"
                />
                Price
              </div>
              <div className="val">
                <CurrencyFormateText
                  value={data?.packageInfo?.amount}
                  suffix=" USD per month"
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-end my-2 d-lg-none">
            <div className="col-6 col-lg-3 val">Price</div>
            <div className="col-6 col-lg-3 col-xl-2 text-right val">
              <CurrencyFormateText
                value={data?.packageInfo?.amount}
                suffix=" USD per month"
              />
            </div>
          </div>
          {isChangingUpcomingPackage ? (
            <div className="row my-2">
              <div className="col-lg-6 col-xl-7">
                {data?.packageInfo?.is_brand_match === 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOPenBrandMatch(true)}
                    className="px-4 brand-match-btn"
                  >
                    Brand Match &nbsp;
                    <CurrencyFormateText
                      value={data?.packageInfo?.brand_match_amount}
                      suffix=" USD"
                    />
                  </Button>
                ) : null}
              </div>
              <div className="col-lg-6 col-xl-5 d-flex justify-content-between">
                <div className="subs">Fee</div>
                <div className="subs">
                  <CurrencyFormateText
                    value={data?.packageInfo?.extra_charge}
                    suffix=" USD per month"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="row justify-content-end my-2">
              <div className="col-6 col-lg-3 subs">Fee</div>
              <div className="col-6 col-lg-3 col-xl-2 text-right subs">
                <CurrencyFormateText
                  value={data?.packageInfo?.extra_charge}
                  suffix=" USD per month"
                />
              </div>
            </div>
          )}

          <div className="row justify-content-end">
            <div className="col-lg-12 col-xl-12">
              <hr />
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-3 col-xl-2 total">Total Budget:</div>
            <div className="col-9 col-lg-3 col-xl-3 text-right total">
              <CurrencyFormateText
                value={data.packageInfo.amount + data.packageInfo.extra_charge}
                suffix=" USD per month"
              />
            </div>
          </div>
        </div>
      </div>
      <RpMUIDialog open={openBrandMatch} maxWidth="sm">
        <BrandMatchDialog data={data} onClose={handleClose} />
      </RpMUIDialog>
    </>
  );
};

export default LocationCalculation;
