import queryString from "query-string";
import AxiosInstance, { takeLatestRequest } from "../config/AxiosInstance";
import { API_BASE_URL_ADMIN, API_BASE_URL } from "../config/appConfig";

export const brandService = {
  getPageSetup,
  getBrands,
  getBrandLocations,
  getBrandBySlug,
  getResourceCenterData,
  saveUserFeedback,
};

/** Page Setup APIs */
function getPageSetup(brand_id) {
  const getData = {
    status: 1,
    row: 50,
    page: 1,
    is_deleted: 0,
    brand_id: brand_id,
  };

  const query = queryString.stringify(getData);

  return AxiosInstance.get(`${API_BASE_URL_ADMIN}/pageSetup?${query}`);
}

function getBrands(data = {}) {
  const getData = {
    status: 1,
    row: 50,
    page: 1,
    is_deleted: 0,
    id: "",
    fk_participant_id: 0,
    fk_company_id: 0,
    ...data,
  };

  const query = queryString.stringify(getData);

  return AxiosInstance.get(`${API_BASE_URL_ADMIN}/brand?${query}`);
}

function getBrandLocations(data = {}, isSWR = false) {
  const getData = {
    id: "",
    row: 10,
    page: 1,
    searchKey: "",
    groupId: "",
    ...data,
  };

  const config = {
    method: "get",
    // url: `${API_BASE_URL_ADMIN}/brandWidgetLocationPagination`,
    url: `${API_BASE_URL_ADMIN}/locationReportListing`,
    params: getData,
  };

  if (isSWR) {
    // return takeLatestRequest(config, "brandWidgetLocationPagination").then(
    return takeLatestRequest(config, "locationReportListing").then(
      (r) => r.data
    );
  }
  // return takeLatestRequest(config, "brandWidgetLocationPagination");
  return takeLatestRequest(config, "locationReportListing");
}

function getBrandBySlug(slug) {
  return AxiosInstance.get(`${API_BASE_URL}/brand/${slug}`);
}

function getResourceCenterData(data, isSWR = false) {
  const query = queryString.stringify(data);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/resource-center?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(`${API_BASE_URL_ADMIN}/resource-center?${query}`);
}

function saveUserFeedback(data) {
  return AxiosInstance.post(`${API_BASE_URL_ADMIN}/participantFeedback`, data);
}
