import React, { useEffect, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useMediaQuery } from "react-responsive";
import {
  Tab,
  Tabs,
  withStyles,
  IconButton,
  Icon,
  Typography,
} from "@material-ui/core";

import CampaignsYetToJoin from "../../../../components/joinCampaigns/CampaignsYetToJoin";
import NotAvailable from "../../../../components/NotAvailable";
import ArchivedCampaignList from "../archiveCampaign/ArchivedCampaignList";
import NoDataFound from "../../../../components/NoDataFound";

const RpTabs = withStyles(() => ({
  root: {
    minWidth: "auto",
    minHeight: "auto",
    width: "auto",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const RpTab = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    minWidth: "auto",
    minHeight: "auto",
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    margin: "auto 5px",
    fontSize: ".7rem",
    fontWeight: 600,
    textTransform: "none",
    opacity: 1,
    paddingTop: 7,
    paddingBottom: 4,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}))(Tab);

const RpGridListButton = withStyles(() => ({
  label: {
    borderRadius: 3,
    border: 0,
  },
}))(IconButton);

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`campaigns-tabpanel-${index}`}
      aria-labelledby={`campaigns-tab-${index}`}
      {...other}
      className="rp-campaign-tab"
    >
      {children}
    </div>
  );
}

export default function UnJoinedCampaigns({
  selectedBrand,
  isThereAnyActiveSubscription,
  getCampaigns,
  selectedTab,
  handleTabChange,
  tabsCounts,
  pageCount,
  currentPage,
  onPageChange,
  isLoading,
}) {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  const [viewType, setViewType] = useState("grid");

  const [previousTabsCounts, setPreviousTabsCounts] = useState(tabsCounts);

  useEffect(() => {
    if (isMobileOrTablet) {
      setViewType("grid");
    }
  }, [isMobileOrTablet]);

  useEffect(() => {
    if (!isLoading) {
      setPreviousTabsCounts(tabsCounts);
    }
  }, [tabsCounts, isLoading]);

  const renderCampaigns = useCallback(
    (campaignType) => {
      if (isLoading) {
        return <NoDataFound className="my-5" message="Loading..." />;
      }
      if (!getCampaigns || getCampaigns.length === 0) {
        return <NotAvailable className="my-5" />;
      }
      if (campaignType === "archived") {
        return (
          <ArchivedCampaignList
            pageCount={pageCount}
            viewType={viewType}
            archivedCampaigns={getCampaigns}
            currentPage={currentPage}
            onPageChange={onPageChange}
            isLoading={isLoading}
          />
        );
      }
      return (
        <CampaignsYetToJoin
          pageCount={pageCount}
          campaignsNotJoined={getCampaigns}
          viewType={viewType}
          currentPage={currentPage}
          onPageChange={onPageChange}
          isLoading={isLoading}
        />
      );
    },
    [getCampaigns, pageCount, viewType]
  );

  const subscriptionMessage = (
    <h6 className="link my-5 d-flex align-items-md-center">
      <InfoOutlinedIcon className="mr-2" />
      <Link to={`/subscription-packages`}>
        Subscribe now to launch brand ad campaigns for your restaurant(s).
      </Link>
    </h6>
  );

  return (
    <>
      <div className="row justify-content-end align-items-center mt-3">
        <div className="col">
          <Typography variant="h5" className="widget-title mb-4 mb-md-auto">
            Digital Campaigns
          </Typography>
        </div>
        <div className="col-md">
          <div className="heading14 main-color text-right d-flex align-items-center justify-content-lg-end">
            <RpTabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="off"
            >
              <RpTab
                label={`New Campaigns (${previousTabsCounts?.new_campaign})`}
                icon={
                  <Icon fontSize="inherit" className="mb-0 mr-1">
                    dashboard
                  </Icon>
                }
              />
              <RpTab
                label={`Drafts (${previousTabsCounts?.draft})`}
                icon={
                  <Icon fontSize="inherit" className="mb-0 mr-1">
                    dashboard
                  </Icon>
                }
              />
              <RpTab
                label={`Archived (${previousTabsCounts?.archived})`}
                icon={
                  <Icon fontSize="inherit" className="mb-0 mr-1">
                    dashboard
                  </Icon>
                }
              />
            </RpTabs>
            <div className="ml-2 align-items-center d-none d-lg-flex">
              <RpGridListButton onClick={() => setViewType("list")}>
                <Icon
                  fontSize="small"
                  component="img"
                  src={`/img/icons/${
                    viewType === "list" ? "list_view" : "list_view_outlined"
                  }.png`}
                  alt="List"
                />
              </RpGridListButton>
              <RpGridListButton onClick={() => setViewType("grid")}>
                <Icon
                  fontSize="small"
                  component="img"
                  src={`/img/icons/${
                    viewType === "grid" ? "grid_view" : "grid_view_outlined"
                  }.png`}
                  alt="Grid"
                />
              </RpGridListButton>
            </div>
          </div>
        </div>
      </div>
      <div>
        <TabPanel value={selectedTab} index={0}>
          <div className="yet-to-join-campaigns">
            {selectedBrand.id === 3 &&
            !isThereAnyActiveSubscription &&
            selectedBrand?.has_active_subscription_page
              ? subscriptionMessage
              : renderCampaigns("new")}
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <div className="yet-to-join-campaigns">
            {selectedBrand.id === 3 &&
            !isThereAnyActiveSubscription &&
            selectedBrand?.has_active_subscription_page
              ? subscriptionMessage
              : renderCampaigns("draft")}
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <div className="yet-to-join-campaigns">
            {selectedBrand.id === 3 &&
            !isThereAnyActiveSubscription &&
            selectedBrand?.has_active_subscription_page
              ? subscriptionMessage
              : renderCampaigns("archived")}
          </div>
        </TabPanel>
      </div>
    </>
  );
}
