import React, {
  useCallback,
  useMemo,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import { MUICustomAutocompleteSelect } from "../../../components/muiCustom/select/MUICustomAutocompleteSelect";
import TableServer from "../../../components/tables/TableServer";
import { subscriptionService } from "../../../services/subscriptionService";
import { getFormatDate } from "../../../helpers/utils";
import DownloadOptedOutDetail from "./DownloadOptedOutDetail";
import useFetcher from "../../../helpers/hooks/useFetcher";
import PageHeader from "../../../components/layouts/pageHeaders/PageHeader";

export default function OptedOutSubscriptions() {
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const [filters, setFilters] = useState({
    userId: loggedInUser.id,
    brandId: selectedBrand.id,
    download: 0,
    page: 1,
    offset: 10,
    searchKey: "",
    groupKey: "",
    statusKey: "",
    sortBy: "",
    sortByDir: "",
  });

  const reqData = useMemo(() => {
    return {
      userId: loggedInUser.id,
      brandId: selectedBrand.id,
      ...filters,
      download: 0,
    };
  }, [selectedBrand?.id, loggedInUser?.id, filters]);

  const { data, isLoading: loading } = useFetcher(
    reqData,
    subscriptionService.getOptedOutData,
    { revalidateOnFocus: false }
  );

  const { locationData, groupList } = data || {};
  const totalPageCount = locationData?.totalCount || 0;
  const locationDetails = locationData?.rows || [];
  const allGroupList = groupList || [];

  useEffect(() => {
    loader(loading);
  }, [loading, loader]);

  const handleFetchData = useCallback((data) => {
    const {
      pageIndex,
      pageSize,
      searchKey,
      filters: fetchFilters,
      sortBy,
      sortByDir,
    } = data;

    const reqData = {
      ...fetchFilters,
      download: 0,
      page: pageIndex + 1,
      offset: pageSize,
      searchKey: searchKey || "",
      sortBy,
      sortByDir,
    };

    setFilters((f) => ({ ...f, ...reqData }));
  }, []);

  const pageCount = useMemo(
    () => Math.ceil(totalPageCount / 10) || 0,
    [totalPageCount]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Location",
        accessor: "location_name",
        Cell: ({ row: { original } }) => (
          <span className="text-wrap user-select-all fw-b">
            {original.location_name || "N/A"}
          </span>
        ),
      },
      {
        Header: "Group",
        accessor: "group_names",
        Cell: ({ row: { original } }) => (
          <span className="text-wrap user-select-all fw-b">
            {original.group_names || "N/A"}
          </span>
        ),
      },
      {
        Header: "Participant",
        accessor: "participant_name",
        Cell: ({ row: { original } }) => (
          <span className="text-wrap user-select-all">
            {original.first_name || "N/A"} {original.last_name || ""}
          </span>
        ),
      },
      {
        Header: "Participant Email",
        accessor: "email",
        Cell: ({ row: { original } }) => (
          <span className="text-wrap user-select-all">
            {original.email || "N/A"}
          </span>
        ),
      },
      {
        Header: "Opted-Out By",
        accessor: "opted_by",
        width: 115,
        Cell: ({ row: { original } }) => original.opted_by || "N/A",
      },
      {
        Header: "Opted-Out Date",
        accessor: "opt_out_at",
        width: 130,
        Cell: ({ row: { original } }) => (
          <span className="text-nowrap">{original.opt_out_at || "N/A"}</span>
        ),
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return locationDetails.map((l) => {
      const locData = { ...l };
      // formatting dates
      locData.opt_out_at = getFormatDate(l.opt_out_at);

      return locData;
    });
  }, [locationDetails]);

  const filterData = (setFilter, selectedValues) => {
    return (
      <>
        <div className="col-xl col-lg-8 px-4 px-xl-1 pt-2 px-lg-auto mb-md-2 mb-lg-0 d-flex align-items-end justify-content-end d-lg-none">
          {locationDetails?.length ? (
            <DownloadOptedOutDetail filters={filters} />
          ) : null}
        </div>
        <div className="col-lg-4 col-xl-3 col-md-6 col-md-4 mb-2 pr-xl-1">
          <MUICustomAutocompleteSelect
            name="location-group"
            options={allGroupList?.map((g) => ({
              label: g.group_name,
              value: g.group_name,
            }))}
            selectProps={{
              onChange: (v) => {
                setFilter("groupKey", v);
              },
              value: selectedValues?.groupKey,
            }}
            label="Groups"
            all
          />
        </div>
        <div className="col px-4 px-xl-1 pt-2 px-lg-auto mb-md-2 mb-lg-0 d-lg-flex align-items-end d-none">
          {locationDetails?.length ? (
            <DownloadOptedOutDetail filters={filters} />
          ) : null}
        </div>
      </>
    );
  };
  return (
    <>
      <div className="row align-items-center mb-4">
        {/* <div className="col">
          <Typography
            component="h2"
            variant="body2"
            className="font-weight-bold ml-2"
            gutterBottom
            style={{
              borderLeft: "4px solid #04a999",
              paddingLeft: 5,
            }}
          >
            Opt-Out Table
          </Typography>
        </div> */}
        <PageHeader title="Opt-Out Table" showHr={false} />
      </div>

      <div className="opt-out-table">
        <TableServer
          columns={columns}
          filters={filterData}
          showFilter
          data={tableData}
          fetchData={handleFetchData}
          isLoading={loading}
          showSearch={true}
          pageCount={pageCount}
          totalRecords={totalPageCount}
        />
      </div>
    </>
  );
}
