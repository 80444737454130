import React, { memo } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { useAsyncDebounce } from "react-table";
import {
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";

const useSearchInputStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.primary.main}`,
    outline: "none",
    borderRadius: theme.shape.borderRadius,
    // boxShadow: "0px 3px 10px #04A9992E",
  },
}));

const useSearchLabelStyle = makeStyles(() => ({
  root: {
    fontWeight: 600,
    color: "#1B262C",
  },
}));

const TableGlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  placeholder = "",
  debounceTime = 200,
  hideSearchLabel = false,
}) => {
  const searchClasses = useSearchInputStyle();
  const searchLabelClasses = useSearchLabelStyle();

  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value?.trim() || undefined); // Set undefined to remove the filter entirely
  }, debounceTime);

  return (
    <div className="table-search px-2">
      {!hideSearchLabel && (
        <InputLabel
          shrink
          margin="dense"
          className="mb-1"
          classes={searchLabelClasses}
        >
          Search
        </InputLabel>
      )}
      <TextField
        id="search"
        variant="outlined"
        value={value || ""}
        onChange={(e) => {
          const v = e.target.value;
          setValue(v);
          onChange(v);
        }}
        InputProps={{
          classes: { notchedOutline: "border-0" },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" style={{ fill: "#C2C2C2" }} />
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
        classes={searchClasses}
        size="small"
        inputProps={{
          autoComplete: "off",
        }}
      />
    </div>
  );
};

export default memo(TableGlobalFilter);
