import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Fab,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 9,
  },
}));

export default function MSBriefHelp() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Fab
        aria-label="Help"
        className={classes.fab}
        color="primary"
        onClick={handleClick}
        size="small"
        variant="extended"
        aria-describedby={id}
      >
        <Typography variant="caption" className="px-1">
          Help?
        </Typography>
      </Fab>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card className="help-message">
          <CardContent className="p-0">
            <Box textAlign="center">
              <Box className="header" padding={1}>
                <Typography variant="body1" className="heading">
                  Need Help?
                </Typography>
                <Close
                  className="close"
                  fontSize="small"
                  onClick={handleClose}
                />
              </Box>
              <Box padding={2}>
                <Typography color="primary">
                  You can send an email to{" "}
                </Typography>
                <Typography
                  className="email mx-1"
                  component="a"
                  href="mailto:googleadvertising@hyperlocology.com"
                  color="inherit"
                >
                  googleadvertising@hyperlocology.com
                </Typography>
                <Typography color="primary">
                  and a Hyperlocologists will get in contact soon.
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Popover>
    </div>
  );
}
