import React, { useContext, useEffect, useMemo } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";

import FAQ from "../../components/FAQ";
import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import { isYouTubeUrl, canJoinCampaign } from "../../helpers/utils";
import { campaignService } from "../../services";
import NoDataFound from "../../components/NoDataFound";
import PageLayout from "../../components/layouts/page/PageLayout";
import BackLink from "../../components/BackLink";
import { STATIC_IMAGES } from "../../helpers/staticImages";
import RpVideoPlayer from "../../components/RpVideoPlayer";
import PageHeader from "../../components/layouts/pageHeaders/PageHeader";
import { reportUserActivity } from "../../helpers/userActivityHelper";
import useFetcher from "../../helpers/hooks/useFetcher";

const PostcardCampaignLearnMore = () => {
  const { campaignId } = useParams();

  const history = useHistory();

  const { selectedBrand, loggedInUserRole, loggedInUser } =
    useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  useEffect(() => {
    reportUserActivity("faq");
  }, [loggedInUserRole]);

  const reqData = useMemo(() => {
    return {
      id: campaignId,
      fk_participant_id: loggedInUser?.participantData
        ? loggedInUser?.participantData?.id
        : 0,
    };
  }, [campaignId, loggedInUser]);

  const {
    data,
    isLoading: loading,
    isValidating,
  } = useFetcher(reqData, campaignService.gePostcardCampaignData, {
    revalidateOnFocus: false,
  });

  const campaign = data?.data;

  useEffect(() => {
    loader(loading || isValidating);
  }, [loading, loader, isValidating]);

  /** check for campaign id. If not redirect to dashboard */
  if (!campaignId) {
    history.replace(`${process.env.PUBLIC_URL}/dashboard`);
  }

  useEffect(() => {
    // if selected brand is not match with campaigns brand redirect to dashboard
    if (campaign && campaign?.fk_brand_id !== selectedBrand?.id) {
      history.replace(`${process.env.PUBLIC_URL}/dashboard`);
    }
  }, [selectedBrand]);

  if (!loading && !campaign) {
    return <NoDataFound />;
  }

  const renderPage = () => {
    if (loading || isValidating) {
      return <NoDataFound message="Loading.." />;
    }

    return (
      <>
        <div className="row">
          <div className="col-lg-10">
            <div className="title mb-2">Campaign Name</div>
            <h2 className="title-val">{campaign?.campaign_name}</h2>
          </div>
          {/* <div className="col-lg-2 d-flex align-items-center flex-wrap justify-content-lg-end">
              <div>
                <div className="title mb-2 text-lg-right">Channels</div>
                <h2 className="title-val">
                  {getChannelIcons(
                    campaign?.campaignChannelNameForCampaign?.[0]?.channelNames?.split(
                      ","
                    )
                  )}
                </h2>
              </div>
            </div> */}
        </div>

        {isYouTubeUrl(campaign?.video_url) && (
          <div>
            <section className="video-section my-4">
              <h2 className="video-heading">
                {campaign?.video_name || "Watch Video Tutorial"}:{" "}
                <span className="hype-name">Hyperlocology</span>
                <span> + </span>
                <span className="hype-name"> {selectedBrand.brand_name}</span>
              </h2>
              <h3 className="sub-video-heading">
                Check out this video to learn more!
              </h3>

              <div className="row my-5 justify-content-center">
                <div className="col-md-8 col-lg-6 d-flex-center">
                  <img
                    src={STATIC_IMAGES.vid_box_2}
                    alt=""
                    className="img-fluid vid-lm-1 d-none d-lg-block d-xl-block"
                  />
                  <RpVideoPlayer
                    url={campaign?.video_url}
                    showPlayIcon={false}
                    onClick={() => {
                      reportUserActivity("helpful_video");
                    }}
                  />
                  <img
                    src={STATIC_IMAGES.vid_box_2}
                    alt=""
                    className="img-fluid vid-lm-2 d-none d-lg-block d-xl-block"
                  />
                </div>
              </div>
            </section>
          </div>
        )}

        {campaign?.campaign_objective && (
          <section className="campaign-objective mt-4">
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
              className="font-weight-bold"
            >
              Objective
            </Typography>
            <Typography component="p" variant="body1" className="obj-camp">
              {campaign?.campaign_objective}
            </Typography>
          </section>
        )}

        {campaign?.postcard_campaign_faqs &&
        campaign?.postcard_campaign_faqs.length ? (
          <div className="card faq-card my-4 mb-5">
            <div className="card-body">
              <h2 className="faq-title">
                <span>Frequently Asked Questions</span> About This Campaign
              </h2>
              <div className="row">
                <div className="col-lg-12 mb-3 pt-4">
                  {/* <hr className="mt-0" /> */}
                  <FAQ faqs={campaign?.postcard_campaign_faqs} />
                </div>
                {/* <div className="col-lg-4 d-none d-lg-flex flex-column justify-content-around align-items-center">
                    <img
                      src={STATIC_IMAGES.faq}
                      alt="FAQ"
                      className="img-fluid"
                    />
                    {campaign?.campaign_faqs.length > 8 ? (
                      <>
                        <img
                          src="/img/faq_image_divider.png"
                          className="img-fluid my-2 px-5"
                          alt="FAQ"
                        />
                        <img
                          src={STATIC_IMAGES.faq2}
                          alt="FAQ"
                          className="img-fluid-2 pl-4"
                        />
                      </>
                    ) : null}
                  </div> */}
              </div>
            </div>
          </div>
        ) : null}

        <div className="row justify-content-center my-4">
          {loggedInUserRole === "PR" ? (
            <div className="col-md-4">
              {canJoinCampaign(campaign) ? (
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/participant/join-postcard-campaign/${campaign?.id}`,
                    state: {
                      campaignId: campaign?.campaignId,
                      from: "/dashboard/learn-more",
                    },
                  }}
                  className="button primary  w-100"
                >
                  Launch This Campaign
                </Link>
              ) : (
                <Link
                  to={`${process.env.PUBLIC_URL}/postcard-campaigns`}
                  className="button primary large w-100"
                >
                  Launch Campaign
                </Link>
              )}
            </div>
          ) : (
            <div className="col-md-4">
              <Link
                to={`${process.env.PUBLIC_URL}/postcard-report`}
                className="button primary large w-100"
              >
                Back to Campaigns
              </Link>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <PageLayout className="learn-more-container mx-lg-5 mx-3">
        <div className="learn-more">
          <BackLink />
          <PageHeader title="Learn More" />
          {renderPage()}
        </div>
      </PageLayout>
    </>
  );
};

export default PostcardCampaignLearnMore;
