import React, { useEffect, useState } from "react";
import { FormLabel, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useAsyncDebounce } from "react-table";

const MuiSearchInput = ({
  value,
  onChange,
  label,
  size = "small",
  placeholder = "Search..",
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState(value);

  const debouncedSearch = useAsyncDebounce((value) => onChange(value), 500);

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="w-100">
      {label && <FormLabel>{label}</FormLabel>}
      <TextField
        name="searchKey"
        id="searchKey"
        value={searchValue}
        onChange={handleChange}
        variant="outlined"
        placeholder={placeholder}
        fullWidth
        size={size}
        margin="normal"
        className="search"
        color="primary"
        type="search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" fontSize="small" />
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </div>
  );
};

export default MuiSearchInput;
