import { useEffect } from "react";
import { withRouter } from "react-router-dom";

export default withRouter(({ location, x = 0, y = 0 }) => {
  const { pathname } = location;
  // scroll to x,y on change path
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(x, y);
    }, 50);
  }, [pathname]);
  return null;
});
