import React, { useMemo } from "react";
import { CircularProgress, Typography } from "@material-ui/core";

import {
  getFieldLabel,
  removeNotToShowFields,
  REQUEST_FIELD_LABELS,
} from "../formConfigs";
import { containsUrls, urlRegex } from "../../../../helpers/utils";

function RequestDetails({ data, isLoading, requestType = "", requestId = "" }) {
  const formattedData = useMemo(() => {
    if (!data) return null;
    const result = Object.keys(data)
      .filter((k) => (Array.isArray(data[k]) ? data[k]?.length : data[k]))
      .filter((k) => !removeNotToShowFields.includes(k))
      .reduce((t, k) => ({ ...t, [k]: data[k] }), {});

    // Combine date range into a single field
    if (data.date_range_start_date && data.date_range_end_date) {
      result.date_range = `${data.date_range_start_date} To ${data.date_range_end_date}`;
      delete result.date_range_start_date;
      delete result.date_range_end_date;
    }

    return result;
  }, [data]);

  const styles = {
    container: {
      padding: "10px",
    },
    header: {
      marginBottom: "20px",
    },
    requestType: {
      fontWeight: "bold",
      marginBottom: "16px",
    },
    fields: {
      display: "flex",
      flexDirection: "column",
    },
    field: {
      marginBottom: "22px",
    },
    fieldLabel: {
      marginBottom: "8px",
    },
    fieldInput: {
      padding: "8px",
      border: "1px solid #000",
      borderRadius: "10px",
      backgroundColor: "#f9f9f9",
      wordWrap: "break-word",
    },
    urlField: {
      fontSize: "18px",
      display: "flex",
      alignItems: "center",
      wordBreak: "break-word",
      overflowWrap: "break-word",
      whiteSpace: "normal",
    },
  };

  const renderData = useMemo(() => {
    if (!formattedData) return null;

    // Create an ordered list of fields based on the request type's field order
    const orderedFields = Object.keys(formattedData).map((key) => ({
      key,
      label: getFieldLabel(key, requestType),
      value: formattedData[key],
    }));

    // Sort fields based on the order defined in REQUEST_FIELD_LABELS
    const sortedFields = orderedFields.sort((a, b) => {
      const requestTypeLabels = REQUEST_FIELD_LABELS[requestType] || {};

      // Get the order of fields in the request type labels
      const aOrder = Object.keys(requestTypeLabels).indexOf(a.key);
      const bOrder = Object.keys(requestTypeLabels).indexOf(b.key);
      // If both fields are in request type labels, sort by their order
      if (aOrder !== -1 && bOrder !== -1) {
        return aOrder - bOrder;
      }
      // If only one field is in request type labels, it should come first
      if (aOrder !== -1) return -1;
      if (bOrder !== -1) return 1;

      // If neither field is in any labels, maintain original order
      return 0;
    });

    // Separate URL fields and other fields
    const urlFields = sortedFields.filter((field) => containsUrls(field.value));
    const otherFields = sortedFields.filter(
      (field) => !containsUrls(field.value)
    );

    const renderField = (field) => {
      const { value } = field;

      // Handle arrays (locations, channels, etc.)
      if (Array.isArray(value)) {
        // Handle array of files/URLs
        if (field.key === "ad_copy_files" || containsUrls(value)) {
          return (
            <div className="d-flex flex-column gap-2">
              {value.map((item, index) => {
                const url = item.file_url || item;
                const fileName = item.file_name || url.split("/").pop();
                return (
                  <div key={index} style={styles.urlField}>
                    <img
                      src="/img/icons/blue-file-icon.png"
                      alt="file"
                      className="mr-3 mt-2"
                    />
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      style={{ color: "#007bff", textDecoration: "underline" }}
                    >
                      {fileName}
                    </a>
                  </div>
                );
              })}
            </div>
          );
        }

        // Handle locations and channels
        if (
          field.key === "locations" ||
          field.key === "channel" ||
          field.key === "ad_copy_files"
        ) {
          return value
            .map((item) => item.location_name || item.channel_name)
            .join(", ");
        }

        return value.join(", "); // For other arrays
      }

      // Handle single URL
      if (typeof value === "string" && urlRegex && urlRegex.test(value)) {
        return (
          <div style={styles.urlField}>
            <img
              src="/img/icons/blue-file-icon.png"
              alt="file"
              className="mr-3 mt-2"
            />
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              download
              style={{ color: "#007bff", textDecoration: "underline" }}
            >
              {value && value.split("/").pop()}
            </a>
          </div>
        );
      }

      return value || "N/A";
    };

    return (
      <div style={styles.container}>
        <div style={styles.header}>
          <div style={styles.field}>
            <Typography
              className="text-capitalize fs-12"
              variant="h6"
              style={styles.fieldLabel}
            >
              Request Id
            </Typography>
            <div style={styles.fieldInput}>
              <Typography>{requestId ? requestId : "N/A"}</Typography>
            </div>
          </div>
          <div style={styles.field}>
            <Typography
              className="text-capitalize fs-12"
              variant="h6"
              style={styles.fieldLabel}
            >
              What can we help you with?
            </Typography>
            <div style={styles.fieldInput}>
              <Typography>{requestType ? requestType : "N/A"}</Typography>
            </div>
          </div>
        </div>
        <div style={styles.fields}>
          {otherFields.length > 0 ? (
            otherFields.map((field) => (
              <div key={field.key} style={styles.field}>
                <Typography
                  className="text-capitalize fs-12"
                  variant="h6"
                  style={styles.fieldLabel}
                >
                  {field.label}
                </Typography>
                <div style={styles.fieldInput}>
                  <Typography>{renderField(field)}</Typography>
                </div>
              </div>
            ))
          ) : (
            <Typography>No fields available to display.</Typography>
          )}
          {urlFields.length > 0 &&
            urlFields.map((urlField) => (
              <div style={styles.field}>
                <Typography
                  className="text-capitalize fs-12"
                  variant="h6"
                  style={styles.fieldLabel}
                >
                  {urlField.label}
                </Typography>
                <div className="mt-1">
                  <Typography>{renderField(urlField)}</Typography>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }, [formattedData, requestType]);

  if (isLoading)
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="primary" />
      </div>
    );

  return <div>{renderData}</div>;
}

export default RequestDetails;
