import React, { useState } from "react";
import NumberFormat from "react-number-format";
import {
  FormHelperText,
  Icon,
  InputAdornment,
  TextField,
} from "@material-ui/core";

const MAX_SPEND_BUDGET = 1000000;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        // onChange({
        //   target: {
        //     name: props.name,
        //     value: values.value,
        //   },
        // });
        onChange(values.floatValue);
      }}
      thousandSeparator
      // isNumericString
      decimalScale={2}
      // fixedDecimalScale={2}
      allowNegative={false}
      // prefix="$"
      isAllowed={({ floatValue }) =>
        floatValue > MAX_SPEND_BUDGET ? false : true
      }
    />
  );
}

export default function LocationBudget({
  budget = "",
  minMaxRequired,
  onEnterBudget,
  campaignType,
  initialDisabled,
}) {
  const [isFocused, setIsFocused] = useState(false);

  const [value, setValue] = useState(
    budget || parseFloat(budget) === 0 ? parseFloat(budget) : ""
  );

  const handleChange = (v) => {
    if (Number(v) > MAX_SPEND_BUDGET) {
      return;
    }
    setValue(v);
    onEnterBudget(v);
  };

  const renderHelperText = minMaxRequired && value !== "" && isFocused;

  return (
    <div className="location-budget">
      <label>Location Budget</label>
      <TextField
        onFocus={() => setIsFocused(true)}
        value={value}
        onChange={handleChange}
        name="locationBudget"
        id="locationBudget"
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: (
            <InputAdornment position="start">
              <Icon component="img" src="/img/icons/money1.png" />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        size="small"
        autoComplete="off"
        error={minMaxRequired && value !== "" ? true : false}
        placeholder="0.00"
        disabled={
          initialDisabled ||
          (campaignType === "Location Specific" ? false : true)
        }
      />

      {renderHelperText ? (
        <FormHelperText className="fade-in">{`We recommend the budget between $${minMaxRequired?.minRequiredBudget} & $${minMaxRequired?.maxRequiredBudget}`}</FormHelperText>
      ) : null}
    </div>
  );
}
