import React, { useState, useEffect } from "react";
import NotYetJoinedCampaignListViewCard from "./NotYetJoinedCampaignListViewCard";
import NotYetJoinedCampaignGridViewCard from "./NotYetJoinedCampaignGridViewCard";
import TablePagination from "../tables/TablePagination";
import NoDataFound from "../NoDataFound";

const CampaignsYetToJoin = ({
  campaignsNotJoined = [],
  onArchived,
  pageCount,
  viewType = "list",
  currentPage,
  onPageChange,
  isLoading,
}) => {
  const [campaignData, setCampaignData] = useState([]);

  useEffect(() => {
    if (!Array.isArray(campaignsNotJoined && campaignsNotJoined)) {
      setCampaignData([]);
    } else {
      setCampaignData(campaignsNotJoined && campaignsNotJoined);
    }
  }, [campaignsNotJoined && campaignsNotJoined]);

  const renderListView = () => {
    return (
      <div className="row align-items-stretch list-view">
        {campaignData.map((campaign) => (
          <div className="col-12 p-2" key={campaign.id}>
            <NotYetJoinedCampaignListViewCard
              campaign={campaign}
              key={campaign.id}
              onArchived={onArchived}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderGridView = () => {
    return (
      <div className="row align-items-stretch grid-view">
        {campaignData.map((campaign) => (
          <div className="col-xl-4 col-md-6 p-2 my-2" key={campaign.id}>
            <NotYetJoinedCampaignGridViewCard
              campaign={campaign}
              key={campaign.id}
              onArchived={onArchived}
            />
          </div>
        ))}
      </div>
    );
  };

  if (isLoading) {
    return <NoDataFound className="my-5" message="Loading..." />;
  }

  return (
    <div>
      {viewType === "list" ? renderListView() : renderGridView()}
      <div className="row">
        <div className="col-12 float-right">
          <TablePagination
            page={currentPage}
            count={pageCount}
            onChange={(event, page) => onPageChange(event, page)}
            className="float-right"
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignsYetToJoin;
