import React, { useState } from "react";
import EmailLinkSendMessage from "./EmailLinkSendMessage";
import ResendEmailLink from "./ResendEmailLink";

export default function InvalidLinkMessage({ email, subscribe }) {
  const [hasSendEmail, setHasSendEmail] = useState(false);

  const handleOnSendEmail = () => {
    setHasSendEmail(true);
  };

  if (!email) {
    return (
      <div className="invalid-link-message text-center">
        <h2 className="heading">That link is no longer valid</h2>
      </div>
    );
  }

  if (hasSendEmail && email) {
    return <EmailLinkSendMessage email={email} />;
  }

  return (
    <div className="invalid-link-message">
      <h2 className="heading">That link is no longer valid</h2>
      <h5 className="subheading">
        Email confirmation links expire after a while and can only be used once.
        Please request a new link to access your account.
      </h5>
      <ResendEmailLink
        email={email}
        subscribe={subscribe}
        onSuccess={handleOnSendEmail}
      />
    </div>
  );
}
