import React from "react";
import { FormHelperText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  helperText: {
    marginLeft: 0,
    marginRight: 0,
  },
  inlineError: {
    display: "inline-block",
    margin: 0,
  },
}));

const RpFormError = ({
  error,
  show = true,
  className = "",
  variant = "helper", // helper, text, inline
  component = "div",
  ...rest
}) => {
  const classes = useStyles();

  if (!show || !error) return null;

  const errorMessage = String(error);

  switch (variant) {
    case "text":
      return (
        <Typography
          component={component}
          className={`${classes.error} ${className}`}
          {...rest}
        >
          {errorMessage}
        </Typography>
      );

    case "inline":
      return (
        <FormHelperText
          error
          component={component}
          className={`${classes.inlineError} ${className}`}
          {...rest}
        >
          {errorMessage}
        </FormHelperText>
      );

    case "helper":
    default:
      return (
        <FormHelperText
          error
          component={component}
          className={`${classes.helperText} ${className}`}
          {...rest}
        >
          {errorMessage}
        </FormHelperText>
      );
  }
};

export default RpFormError;
