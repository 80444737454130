import { takeLatest, call, put } from "redux-saga/effects";

import { statusCodeConstants, brandConstants } from "../../config/constants";
import { brandService } from "../../services";

function* getPageSetup(action) {
  try {
    const res = yield call(brandService.getPageSetup, action.payload);
    if (res.status === statusCodeConstants.OK && res.data) {
      yield put({
        type: brandConstants.GET_PAGE_SETUP_SUCCESS,
        payload: res.data.rows[0],
      });
    }
  } catch (err) {
    yield put({
      type: brandConstants.GET_PAGE_SETUP_FAIL,
      payload: err,
    });
  }
}

function* getBrands(action) {
  try {
    const res = yield call(brandService.getBrands, action.payload);
    if (res.status === statusCodeConstants.OK && res.data) {
      const brandData = res.data.rows;

      if (brandData && brandData.length) {
        yield put({
          type: brandConstants.GET_BRANDS_SUCCESS,
          payload: res.data.rows,
        });
      }
    }
  } catch (err) {
    yield put({
      type: brandConstants.GET_BRANDS_FAIL,
      payload: err,
    });
  }
}

/**
 * @method brandSaga
 * @description watch for action and act
 */
export default function* brandSaga() {
  yield takeLatest(brandConstants.GET_PAGE_SETUP_REQUEST, getPageSetup);
  yield takeLatest(brandConstants.GET_BRANDS_REQUEST, getBrands);
}
