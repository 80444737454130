import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { campaignActions } from "../../../actions/campaignActions";

import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import { joinCampaignHelper } from "../../../helpers/joinCampaignHelper";
import { canJoinCampaign } from "../../../helpers/utils";
import {
  isLoadingCampaignsSelector,
  isSavedSelector,
} from "../../../selectors/campaignSelectors";
import { campaignService } from "../../../services/campaignService";
import JoinPostcardCampaignForm from "./joinPostcardCampaignForm";
import NoDataFound from "../../../components/NoDataFound";
import PageLayout from "../../../components/layouts/page/PageLayout";
import { logger } from "../../../helpers/loggerHelper";

function JoinPostcardCampaign() {
  const { campaignId } = useParams();
  const history = useHistory();

  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const dispatch = useDispatch();
  const isSavedAsDraft = useSelector(isSavedSelector);
  const isLoading = useSelector(isLoadingCampaignsSelector);

  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const [productList, setProductList] = useState(null);
  const [getAdvanceSettingData, setAdvancedSettingData] = useState(null);

  useEffect(() => {
    loader(loading);
  }, [loading, loader]);

  useEffect(() => {
    loader(isLoading);
  }, [isLoading, loader]);

  useEffect(() => {
    async function fetchCampaign() {
      setLoading(true);
      try {
        const campaignReqData = {
          id: campaignId,
          brand_id: selectedBrand.id,
          fk_participant_id: loggedInUser.participantData.id,
        };
        const res = await campaignService.getPostcardCampaign(campaignReqData);
        if (res.status === 200) {
          let campaignData = res.data?.rows[0];
          const getProductList = res.data?.productList;

          let campaignAdvancedSettings =
            campaignData && campaignData?.campaign_advanced_setting;
          // const isSettingsRequired =
          //   campaignData && campaignAdvancedSettings?.is_required;

          if (!campaignData) {
            setCampaign(null);
            return;
          } else {
            if (campaignData.is_brand_paid) {
              campaignData.locations.subscribed =
                campaignData?.locations?.brandPaid &&
                campaignData.locations.brandPaid.length
                  ? campaignData.locations.brandPaid
                  : campaignData.locations.subscribed;
            }
            setCampaign(campaignData);
          }
          setProductList(getProductList);
          setAdvancedSettingData(campaignAdvancedSettings);
        }
      } catch (err) {
        logger.error("fetchCampaign error", err);
      } finally {
        setLoading(false);
      }
    }
    fetchCampaign();
  }, [campaignId, selectedBrand, loggedInUser]);

  useEffect(() => {
    if (isSavedAsDraft) {
      dispatch(campaignActions.resetIsSavedDraftSuccess());

      if (isDraft) {
        history.replace({
          pathname: `${process.env.PUBLIC_URL}/dashboard`,
        });
      } else {
        history.replace({
          pathname: `${process.env.PUBLIC_URL}/participant/postcard-confirm`,
          state: { campaignId: campaignId, from: "join-campaign" },
        });
      }
    }
  }, [isSavedAsDraft, isDraft, dispatch, campaignId]);

  const getInitialValues = useMemo(() => {
    if (!campaign) return {};
    return joinCampaignHelper.getPostCardCampInitialValues(campaign);
  }, [campaign]);

  return (
    <PageLayout className="join-campaign-page postcard-container">
      {loading ? (
        <NoDataFound message="Loading..." />
      ) : !campaign ? (
        <NoDataFound message={`No campaign found!`} />
      ) : !canJoinCampaign(campaign) ? (
        <NoDataFound
          message={
            <div className="d-flex-col-center">
              <div>
                You have already joined this campaign or it is
                in-active/completed/not customizable.
              </div>
              <Link
                to={`${process.env.PUBLIC_URL}/dashboard`}
                className="button primary btn btn-primary my-4"
              >
                Back to dashboard
              </Link>
            </div>
          }
        />
      ) : (
        <JoinPostcardCampaignForm
          campaign={campaign}
          initialValues={getInitialValues}
          loggedInUser={loggedInUser}
          submit={(data) =>
            dispatch(campaignActions.joinPostCardCampaign(data))
          }
          setIsDraft={setIsDraft}
          selectedBrand={selectedBrand}
          productList={productList}
          getAdvanceSettingData={getAdvanceSettingData}
          isDraft={isDraft}
        />
      )}
    </PageLayout>
  );
}

export default JoinPostcardCampaign;
