import React from "react";
import {
  getCampaignStatusText,
  campaignFormatHelper,
  getStatusCode,
} from "../../helpers/utils";
import LearnMoreButton from "../muiCustom/buttons/LearnMoreButton";
import StatusChip from "../muiCustom/chip/StatusChip";
import JoinNowButton from "./JoinNowButton";

const NotYetJoinedCampaignGridViewCard = ({
  campaign,
  renderArchivedJoinNowButton,
}) => {
  const { status, iSEnrolled, brand_contribution, brand_match_message } =
    campaign || {};

  return (
    <div className={`card h-100 ${brand_contribution ? "brand-match" : ""}`}>
      <div className="card-body p-3 pt-4 position-relative">
        {brand_contribution === 1 && brand_match_message && (
          <div className="brand-match-tag" title="Eligible for Brand Match">
            <span className="tag">{brand_match_message}</span>
          </div>
        )}
        <div className={`row ${brand_match_message ? "pt-3" : ""}`}>
          <div className="col">
            <div className="detail">
              <div className="title">Campaign Name</div>
              <div
                className={`title-val  campaign-name ${
                  status === 1 || status === 3 ? "main" : ""
                }`}
              >
                {campaignFormatHelper(campaign).name()}
              </div>
            </div>
          </div>
          {iSEnrolled && (status === 1 || status === 2) ? (
            <div className="col-4 text-center">
              <StatusChip
                label={getCampaignStatusText(status)}
                status={getStatusCode(status)}
              />
            </div>
          ) : null}
        </div>
        <div className="row my-4">
          <div className="col-4 pr-1">
            <div className="detail">
              <div className="title">Channels</div>
              <div className="title-val">
                {campaignFormatHelper(campaign).getChannelIcons()}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="title">Start Date</div>
            <div className="title-val fs-10">
              {campaignFormatHelper(campaign).startDate("MMM DD, YYYY")}
            </div>
          </div>
          <div className="col-4 pl-2">
            <div className="title">End Date</div>
            <div className="title-val fs-10">
              {campaignFormatHelper(campaign).endDate("MMM DD, YYYY")}
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="row">
          <div className="col-7 pr-1">
            {renderArchivedJoinNowButton ? (
              renderArchivedJoinNowButton()
            ) : (
              <JoinNowButton campaign={campaign} />
            )}
          </div>
          <div className="col-5 pl-0 text-right">
            <LearnMoreButton
              to={{
                pathname: `${process.env.PUBLIC_URL}/dashboard/learn-more/digital-campaign/${campaign?.id}`,
                state: { campaignId: campaign?.id, from: "/dashboard" },
              }}
              size="medium"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotYetJoinedCampaignGridViewCard;
