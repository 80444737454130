import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import PageLayout from "../../../components/layouts/page/PageLayout";
import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import { subscriptionService } from "../../../services/subscriptionService";
import SubscriptionOverview from "./SubscriptionOverview";
import UserSubscriptions from "./UserSubscriptions";
import OptedOutSubscriptions from "./OptedOutSubscriptions";
import PageHeader from "../../../components/layouts/pageHeaders/PageHeader";
import { Button } from "@material-ui/core";
import useFetcher from "../../../helpers/hooks/useFetcher";

export default function UserLocationsSubscriptions() {
  const { loggedInUser, loggedInUserRole, selectedBrand } =
    useContext(GlobalContext);

  const loader = useContext(LoaderContext);

  // const [locationStats, setLocationStats] = useState(null);
  // const [groupList, setGroupList] = useState([]);
  // const [isBrandUser, setIsBrandUser] = useState(false);
  const [showOptOut, setShowOptOut] = useState(false);

  const is_brand_auto_renew_on = selectedBrand.is_auto_renewal;

  // filters common state
  const [filters, setFilters] = useState({
    userId: loggedInUser.id,
    brandId: selectedBrand.id,
    download: 0,
    page: 1,
    offset: 10,
    searchKey: "",
    groupKey: "",
    statusKey: "",
    subsLenKey: "",
    sortBy: "",
    sortByDir: "",
  });

  const reqData = useMemo(() => {
    return {
      userId: loggedInUser.id,
      brandId: selectedBrand.id,
      ...filters,
      download: 0,
    };
  }, [selectedBrand?.id, loggedInUser?.id, filters]);

  const [prevData, setPrevData] = useState(null);

  const { data, isLoading: loading } = useFetcher(
    reqData,
    subscriptionService.getUserLocationsSubscriptionDetail,
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (data) {
      setPrevData(data);
    }
  }, [data]);

  const { locationData, topRowCountData, isBrandUser } = data || prevData || {};

  const locationStats = {
    subscribedLocationCount: topRowCountData?.subscribedLocationCount,
    unSubscribedLocationCount: topRowCountData?.unSubscribedLocationCount,
    upcomingRenewalCount: topRowCountData?.upcomingRenewalCount,
  };

  const groupList = topRowCountData?.groupList || [];

  useEffect(() => {
    loader(loading);
  }, [loading, loader]);

  // Reset filters
  useEffect(() => {
    if (!showOptOut) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        groupKey: "",
        searchKey: "",
        statusKey: "",
        subsLenKey: "",
      }));
    }
  }, [showOptOut]);

  const handleFetchData = useCallback((data) => {
    const {
      pageIndex,
      pageSize,
      searchKey,
      filters: fetchFilters,
      sortBy,
      sortByDir,
    } = data;

    const reqData = {
      ...fetchFilters,
      download: 0,
      page: pageIndex + 1,
      offset: pageSize,
      searchKey: searchKey || "",
      sortBy,
      sortByDir,
    };

    setFilters((f) => ({ ...f, ...reqData }));
  }, []);

  return (
    <PageLayout className="user-locations-subscriptions-container mx-0 mx-sm-2">
      <PageHeader title="Media Plan Overview" showHr={false} />
      <div className="user-locations-subscriptions mt-2">
        <SubscriptionOverview
          locationStats={locationStats}
          loggedInUserRole={loggedInUserRole}
          selectedBrand={selectedBrand}
          isBrandUser={isBrandUser}
        />

        <div className="user-subscription-list my-5">
          <div className="user-subscriptions">
            <div className="text-right float-right mt-4">
              {showOptOut ? (
                <Button
                  variant="contained"
                  className="table-btn-link"
                  disableElevation
                  onClick={() => {
                    setShowOptOut(false);
                  }}
                >
                  View Plan Table
                </Button>
              ) : (
                <>
                  {is_brand_auto_renew_on ? (
                    <Button
                      variant="contained"
                      className="table-btn-link"
                      disableElevation
                      onClick={() => {
                        setShowOptOut(true);
                      }}
                    >
                      View Opt-Out Table
                    </Button>
                  ) : null}
                </>
              )}
            </div>
            {showOptOut ? (
              <OptedOutSubscriptions />
            ) : (
              <UserSubscriptions
                isLoading={loading}
                locationData={locationData}
                fetchData={handleFetchData}
                groupList={groupList}
                filtersData={filters}
                is_brand_auto_renew_on={is_brand_auto_renew_on}
              />
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
