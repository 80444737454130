import React, { useState } from "react";
import { Icon, Popover } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";

const PauseLocationAddIcon = ({ campaignId, campaign }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateStatus = () => {
    history.push(`/campaign-pause/${campaignId}`);
    history.replace({
      pathname: `/campaign-pause/${campaignId}`,
      state: {
        campaign_pause: campaign?.pause_campaign_available || null,
      },
    });

    handleClose();
  };

  return (
    <div className="d-inline-flex align-items-center ">
      <Icon
        component="img"
        src="/img/icons/menu_more.svg"
        title="Others"
        className="d-flex align-items-center justify-content-center ml-1 cursor-p"
        onClick={handleClick}
      >
        {/* <img src="/img/icons/menu_more.svg" alt="more" className="icon-size" /> */}
      </Icon>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableScrollLock={true}
      >
        <div className="px-2 py-1 pause-campaign-popover">
          <div
            className="d-flex align-items-center py-1 px-1 rounded hover-bg cursor-p"
            onClick={handleUpdateStatus}
          >
            <EditIcon
              className="text-secondary mr-1"
              fontSize="small"
              color="action"
            />
            <span className="small fw-sb m-0 title">Update Status</span>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default PauseLocationAddIcon;
