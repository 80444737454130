import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./config/store";

if (
  process.env.REACT_APP_ENV === "production" &&
  process.env.NODE_ENV === "production"
) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

if (process.env.NODE_ENV !== "development") {
  // eslint-disable-next-line no-console
  console.log = () => {};
}

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
