import queryString from "query-string";
import AxiosInstance, { takeLatestRequest } from "../config/AxiosInstance";
import { API_BASE_URL_ADMIN } from "../config/appConfig";

export const reportService = {
  getBrandReport,
  getCompanyReport,
  getLocationReport,
  getCampaignReport,
  getUniversalWidgetReport,
  getParticipantDashboardWidget,
  getPostcardCampaignReport,
  getDashboardReport,
  getWidgetDrillUpDownData,
};

function getBrandReport(reqData, isSWR = false) {
  // return AxiosInstance.get(`${API_BASE_URL_ADMIN}/brandReport?id=${id}`);
  const query = queryString.stringify(reqData);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/brandWidgetReport?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(`${API_BASE_URL_ADMIN}/brandWidgetReport?${query}`);
}

function getCompanyReport(id) {
  return AxiosInstance.get(`${API_BASE_URL_ADMIN}/companyReport?id=${id}`);
}

function getLocationReport(data, isSWR = false) {
  const queryData = {
    ...data,
  };
  const query = queryString.stringify(queryData);
  // return AxiosInstance.get(`${API_BASE_URL_ADMIN}/locationReport?${query}`);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/locationWidgetReport?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/locationWidgetReport?${query}`
  );
}

// function getCampaignReport(data) {
//   const getData = {
//     campaign_id: "",
//     system_generated_campaign_id: "",
//     fk_brand_id: "",
//     fk_participant_id: "",
//     // start_date: "",
//     // end_date: "",
//     location_id: "",
//     group_id: "",
//     channel_id: "",
//     regions_id: "",
//     filter: "",
//     downloadReport: false,
//     ...data,
//   };

//   const query = queryString.stringify(getData);

//   /**
//    * /admin/campaignWidgetReport?campaign_id=82&system_generated_campaign_id=WPSM9890&channel_id=&end_date=2020%2F07%2F31&fk_brand_id=23&fk_participant_id=5&group_id=&location_id=&regions_id=&start_date=2020%2F07%2F09&downloadReport=true
//    */

//   return AxiosInstance.get(
//     `${API_BASE_URL_ADMIN}/campaignWidgetReport?${query}`
//   );
// }

function getCampaignReport(data, isSWR = false) {
  const getData = {
    campaign_id: "",
    system_generated_campaign_id: "",
    fk_brand_id: "",
    fk_participant_id: "",
    // start_date: "",
    // end_date: "",
    location_id: "",
    group_id: "",
    channel_id: "",
    regions_id: "",
    filter: "",
    downloadReport: false,
    ...data,
  };

  const config = {
    method: "get",
    url: `${API_BASE_URL_ADMIN}/campaignWidgetReport`,
    params: getData,
  };
  if (isSWR) {
    return takeLatestRequest(config, "campaignWidgetReport").then(
      (r) => r.data
    );
  }

  return takeLatestRequest(config, "campaignWidgetReport");
}

function getWidgetDrillUpDownData(data, isSWR = false) {
  const getData = {
    campaign_id: "",
    system_generated_campaign_id: "",
    fk_brand_id: "",
    fk_participant_id: "",
    location_id: "",
    group_id: "",
    channel_id: "",
    regions_id: "",
    filter: "",
    downloadReport: false,
    widgetId: "",
    drill_type: "",
    ...data,
  };
  const config = {
    method: "get",
    url: `${API_BASE_URL_ADMIN}/widgetReportDrillUpDown`,
    params: getData,
  };
  if (isSWR) {
    return takeLatestRequest(config, "widgetReportDrillUpDown").then(
      (r) => r.data
    );
  }

  return takeLatestRequest(config, "widgetReportDrillUpDown");
}

function getUniversalWidgetReport(reqData, isSWR = false) {
  const query = queryString.stringify(reqData);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/universalWidegtReport?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/universalWidegtReport?${query}`
  );
}

function getParticipantDashboardWidget(data, isSWR = false) {
  const queryData = {
    ...data,
  };
  const query = queryString.stringify(queryData);

  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/participantWidgetReport?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/participantWidgetReport?${query}`
  );
}

function getDashboardReport(data, isSWR = false) {
  const queryData = {
    ...data,
  };
  const query = queryString.stringify(queryData);

  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/dashboardReport?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(`${API_BASE_URL_ADMIN}/dashboardReport?${query}`);
}

function getPostcardCampaignReport(data) {
  const getData = {
    campaign_id: "",
    system_generated_campaign_id: "",
    fk_brand_id: "",
    fk_participant_id: "",
    // start_date: "",
    // end_date: "",
    location_id: "",
    group_id: "",
    channel_id: "",
    regions_id: "",
    filter: "",
    downloadReport: false,
    ...data,
  };

  const config = {
    method: "get",
    url: `${API_BASE_URL_ADMIN}/postcardCampaignWidgetReport`,
    params: getData,
  };

  return takeLatestRequest(config, "postcardCampaignWidgetReport");
}
