import { createSelector } from "reselect";

export const unJoinedCampaigns = (state) => state.campaign.unJoinedCampaigns;

export const unJoinedCampaignsSelector = createSelector(
  unJoinedCampaigns,
  (data) =>
    data.filter((d) => {
      if (d.budget_type === "Pre-set" && !d.budget) return false;
      return true;
    })
);
// export const unJoinedCampaignsSelector = (state) =>
//   state.campaign.unJoinedCampaigns;

export const joinedCampaignsSelector = (state) =>
  state.campaign.joinedCampaigns;
export const archivedCampaignsSelector = (state) =>
  state.campaign.archivedCampaigns;

export const isThereAnyActiveSubscriptionSelector = (state) =>
  state.campaign.isThereAnyActiveSubscription;
export const isLoadingCampaignsSelector = (state) => state.campaign.loading;
export const isErrorCampaignsSelector = (state) => state.campaign.error;
export const isSavedSelector = (state) => state.campaign.isSaved;

export const campaignReportSelector = (state) => state.campaign.campaignReport;
