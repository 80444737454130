import React from "react";
import { Alert } from "@material-ui/lab";

const NotAvailable = ({
  message = "There are none right now.",
  className = "text24M_Bold83898E",
  ...rest
}) => {
  // return <h6 className={`my-2 ${className}`}>{message}</h6>;
  return (
    <Alert severity="info" className={`${className}`} {...rest}>
      {message}
    </Alert>
  );
};

export default NotAvailable;
