import React from "react";

export default function ParticipantPageLayout({
  className = "",
  containerClassName = "",
  children,
}) {
  return (
    <div className={`page ${className}`}>
      <div className={`${containerClassName}`}>{children}</div>
    </div>
  );
}
