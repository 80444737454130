export default function MSPackageCard({ plan }) {
  return (
    <div className="ms-package-card">
      {plan?.recommended && (
        <div className="recommended">
          <span>Recommended</span>
        </div>
      )}
      <h4 className="package-tier text-uppercase">{plan?.tier}</h4>
      <h5 className="package-name">{plan?.name}</h5>
      <div className="price">
        ${plan?.price} <span className="price-meta">Annual Fee</span>
      </div>
      <div className="description">{plan?.description}</div>
      <div className="price-small">${plan?.price}</div>
      <div className="media-budget my-2">
        <div className="media-budget-meta">Monthly Media Budget</div>
        <div className="media-budget-range">{plan?.media_budget_range}</div>
      </div>
      <div className="perks">
        <ul>
          {plan?.perks?.map((p) => (
            <li key={p}>{p}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
