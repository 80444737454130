import React from "react";
import NumberFormat from "react-number-format";
import { Icon, InputAdornment, TextField } from "@material-ui/core";
import WeekPicker from "./ScheduleDate";

const MAX_SPEND_BUDGET = 1000000;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        // onChange({
        //   target: {
        //     name: props.name,
        //     value: values.value,
        //   },
        // });
        onChange(values.floatValue);
      }}
      thousandSeparator
      // isNumericString
      decimalScale={2}
      // fixedDecimalScale={2}
      allowNegative={false}
      prefix="$"
      suffix=" USD"
      isAllowed={({ floatValue }) =>
        floatValue > MAX_SPEND_BUDGET ? false : true
      }
    />
  );
}

function PostcardCampaignLocationBudget({
  onChangeWeek,
  locTotalCoast,
  initialWeek,
  disableWeekSelection,
  isEvergreenCampaign,
}) {
  return (
    <div className="card-footer bg-white">
      <div className="card border-0">
        <div className="card-body px-0 py-0">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-lg-4 py-2 scheduled-date week-picker">
                <label>Scheduled Date (PST)</label>
                <WeekPicker
                  onChange={onChangeWeek}
                  initialVal={initialWeek}
                  disableWeekSelection={disableWeekSelection}
                  isEvergreenCampaign={isEvergreenCampaign}
                />
              </div>
              <div className="col-lg-4"></div>
              <div className="col-lg-4 Total-cost">
                <div className="row">
                  <div className="col-md">
                    <div className="location-budget">
                      <label>Total Cost</label>
                      <TextField
                        value={locTotalCoast.toFixed(2)}
                        name="totalCost"
                        id="totalCost"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon
                                component="img"
                                src="/img/icons/money1.png"
                              />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        placeholder="0.00"
                        disabled
                      />
                      {/* {minMaxRequired && value !== "" ? (
                            <FormHelperText>{`Enter a budget between $${minMaxRequired.minRequiredBudget} & $${minMaxRequired.maxRequiredBudget}`}</FormHelperText>
                          ) : null} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostcardCampaignLocationBudget;
