import { takeLatest, call, put } from "redux-saga/effects";

import { settingsConstants, statusCodeConstants } from "../../config/constants";
import { settingsService } from "../../services";
import { logger } from "../../helpers/loggerHelper";

function* getSettings(action) {
  try {
    const res = yield call(settingsService.checkResourceCenter, action.payload);
    if (res.status === statusCodeConstants.OK && res.data) {
      const data = res.data;
      yield put({
        type: settingsConstants.SET_SETTINGS,
        payload: data,
      });
    }
  } catch (err) {
    logger.error("getSettings error", err);
    // yield put({
    //   type: settingsConstants.GET_SETTINGS,
    // });
  }
}

/**
 * @method settingsSaga
 * @description watch for action and act
 */
export default function* settingsSaga() {
  yield takeLatest(settingsConstants.GET_SETTINGS, getSettings);
}
