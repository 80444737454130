import { useCallback, useContext, useEffect } from "react";
import { Button, FormHelperText, FormLabel } from "@material-ui/core";
import { FastField, Field, getIn } from "formik";
import CloseIcon from "@material-ui/icons/Close";

import {
  emailMDInputFormik,
  textMDInputFormik,
} from "../../../helpers/formInputs";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import { morganStanleyService } from "../../../services/morganStanleyService";
import { logger } from "../../../helpers/loggerHelper";

const RPErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? (
        <FormHelperText className="text-danger">{error}</FormHelperText>
      ) : null;
    }}
  />
);

export default function AdvisorDetailFields({
  push,
  remove,
  form,
  shouldDisableMSID,
}) {
  const { loggedInUserRole } = useContext(GlobalContext);
  const { values, setFieldValue, setFieldTouched, setFieldError, handleBlur } =
    form;

  useEffect(() => {
    if (
      values?.team_or_individual !== "Team" &&
      values?.advisorDetail?.length > 1
    ) {
      for (let i = 1; i <= values?.advisorDetail?.length; i++) {
        remove(i);
      }
    }
  }, [values?.team_or_individual, values?.advisorDetail?.length, remove]);

  const checkForMSIDAssociationWithEmail = useCallback(
    async ({ email, msid, index }) => {
      try {
        if (email && msid) {
          const res = await morganStanleyService.checkValidationMsid({
            email: email || "",
            msid: msid || "",
          });
          if (res.status === 200 && !res.data) {
            setFieldError(
              `advisorDetail[${index}].msid`,
              "This MSID already associated with another email"
            );
          }
        }
      } catch (err) {
        logger.error("checkForMSIDAssociationWithEmail error", err);
      }
    },
    [setFieldError]
  );

  // const totalItems = values?.advisorDetail?.length;

  const isTeam = values?.team_or_individual === "Team";

  const renderAddMoreButton = ({ remove, push, index }) => {
    if (!isTeam) {
      return null;
    }

    return (
      <div className="col-lg-1 order-1 order-lg-0 mb-2 mt-2 text-center px-lg-0 d-none d-lg-block">
        <FormLabel className="invisible d-none d-lg-inline-block">
          Action
        </FormLabel>

        {index === 0 ? (
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() =>
              push({ first_name: "", last_name: "", email: "", msid: "" })
            }
            className="px-0 mx-0 d-none d-lg-block"
            title="Add More"
          >
            +
          </Button>
        ) : (
          <Button
            color="default"
            variant="contained"
            type="button"
            onClick={() => remove(index)}
            className="px-0 mx-0"
            title="Remove"
            disableElevation
          >
            <CloseIcon color="primary" />
          </Button>
        )}

        {/* {totalItems === 1 || totalItems - 1 === index ? (
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() =>
              push({ first_name: "", last_name: "", email: "", msid: "" })
            }
            className="px-0 mx-0"
          >
            +
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={() => remove(index)}
            className="px-0 mx-0"
          >
            X
          </Button>
        )} */}
      </div>
    );
  };

  return (
    <>
      {values?.advisorDetail?.map((advisor, index) => (
        <div className="row mb-2" key={index}>
          {index > 0 && (
            <div className="col-12 d-flex-center justify-content-end d-lg-none mt-4">
              <Button
                color="default"
                variant="contained"
                type="button"
                onClick={() => remove(index)}
                className="px-0 mx-0"
                title="Remove"
                disableElevation
                size="small"
              >
                <CloseIcon color="primary" fontSize="small" />
              </Button>
            </div>
          )}
          <div className="col-lg-6 mb-2">
            <FormLabel required>First Name</FormLabel>

            <FastField
              name={`advisorDetail[${index}].first_name`}
              component={textMDInputFormik}
              formGroupClass=" mb-0 pb-0 "
              size="small"
              disabled={loggedInUserRole === "PR" && index === 0 ? true : false}
            />
            <RPErrorMessage name={`advisorDetail[${index}].first_name`} />
          </div>
          <div className="col mb-2">
            <FormLabel required>Last Name</FormLabel>
            <FastField
              name={`advisorDetail[${index}].last_name`}
              component={textMDInputFormik}
              formGroupClass=" mb-0 pb-0 "
              size="small"
              disabled={loggedInUserRole === "PR" && index === 0 ? true : false}
            />
            <RPErrorMessage name={`advisorDetail[${index}].last_name`} />
          </div>
          {renderAddMoreButton({ remove, push, index })}
          <div className="col-lg-6 mb-2">
            <FormLabel required>Email</FormLabel>
            <FastField
              name={`advisorDetail[${index}].email`}
              component={emailMDInputFormik}
              formGroupClass=" mb-0 pb-0 "
              size="small"
              disabled={loggedInUserRole === "PR" && index === 0 ? true : false}
              onBlur={(e) => {
                handleBlur(e);
                const emailVal = e.target.value;

                const msidVal = values?.advisorDetail?.[index]?.msid;

                if (emailVal && msidVal) {
                  checkForMSIDAssociationWithEmail({
                    index,
                    msid: msidVal,
                    email: emailVal,
                  });
                }
              }}
            />
            <RPErrorMessage name={`advisorDetail[${index}].email`} />
          </div>
          <div className="col-lg-6 mb-2">
            <FormLabel required>MSID</FormLabel>
            <FastField
              name={`advisorDetail[${index}].msid`}
              component={textMDInputFormik}
              size="small"
              formGroupClass=" mb-0 pb-0 "
              onKeyUp={(e) => {
                const v = e.target.value;
                const vUpper = v.toUpperCase();
                setFieldValue(`advisorDetail[${index}].msid`, vUpper);
              }}
              onKeyDown={(e) => {
                if (!e.target.value) {
                  setFieldTouched(`advisorDetail[${index}].msid`);
                }
              }}
              disabled={
                loggedInUserRole === "PR" && index === 0 && shouldDisableMSID
                  ? // values?.advisorDetail?.[index]?.msid &&
                    // !touched?.advisorDetail?.[index]?.msid
                    true
                  : false
              }
              onBlur={(e) => {
                handleBlur(e);
                const msidVal = e.target.value;

                const emailVal = values?.advisorDetail?.[index]?.email;

                if (emailVal && msidVal) {
                  checkForMSIDAssociationWithEmail({
                    index,
                    msid: msidVal,
                    email: emailVal,
                  });
                }
              }}
            />
            <RPErrorMessage name={`advisorDetail[${index}].msid`} />
          </div>
        </div>
      ))}
      {isTeam && (
        <div className="d-lg-none d-flex-center mt-4 mb-5">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() =>
              push({ first_name: "", last_name: "", email: "", msid: "" })
            }
          >
            + Add More
          </Button>
        </div>
      )}
    </>
  );
}
