import { reactLocalStorage } from "reactjs-localstorage";
import { isEmpty } from "lodash";
import { logger } from "./loggerHelper";

export const getLocal = (NAME) => {
  const data = reactLocalStorage.getObject(NAME);

  if (data && !isEmpty(data)) {
    return data;
  }
  return undefined;
};

export const setLocal = (NAME, DATA) => {
  reactLocalStorage.setObject(NAME, DATA);
};

export const removeLocal = (NAME) => {
  reactLocalStorage.remove(NAME);
};

export const clearLocal = () => reactLocalStorage.clear();

export const getRedirectPath = () => {
  try {
    // Try sessionStorage first
    const sessionRedirect = sessionStorage.getItem("redirectAfterLogin");
    if (sessionRedirect) {
      // Clear from both storages when found in sessionStorage
      sessionStorage.removeItem("redirectAfterLogin");
      localStorage.removeItem("redirectAfterLogin");
      return sessionRedirect;
    }

    // Fall back to localStorage
    const localRedirect = localStorage.getItem("redirectAfterLogin");
    if (localRedirect) {
      // Clear from both storages when found in localStorage
      localStorage.removeItem("redirectAfterLogin");
      sessionStorage.removeItem("redirectAfterLogin");
      return localRedirect;
    }

    return null;
  } catch (error) {
    logger.error("Error accessing storage:", error);
    return null;
  }
};

export const setRedirectPath = (path) => {
  try {
    // Store in both storage types for redundancy
    localStorage.setItem("redirectAfterLogin", path);
    sessionStorage.setItem("redirectAfterLogin", path);
    setTimeout(() => {
      sessionStorage.setItem("redirectAfterLogin", path);
      localStorage.setItem("redirectAfterLogin", path);
    }, 2000);
    return true;
  } catch (error) {
    logger.error("Error setting redirect path:", error);
    return false;
  }
};

export const clearRedirectPath = () => {
  try {
    sessionStorage.removeItem("redirectAfterLogin");
    localStorage.removeItem("redirectAfterLogin");
  } catch (error) {
    logger.error("Error clearing redirect path:", error);
  }
};
