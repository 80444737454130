import React, { useState, useMemo, useEffect } from "react";
import { Button, Icon, IconButton, Typography } from "@material-ui/core";
import { groupBy } from "lodash-es";

import {
  RpSwitchTab,
  RpSwitchTabs,
  TabPanel,
} from "../../../helpers/mui-custom-components";
import RenewPackageCard from "./RenewPackageCard";

export default function RenewPackageSelection({
  packages,
  close,
  onSelectPackage,
  onConfirm,
  selectedUpcomingPkgId,
  location,
  subscriptionDescription,
}) {
  const [selectedTab, setSelectedTab] = useState("6");
  const [selectedPackagePlan, setSelectedPackagePlan] = useState(null);

  useEffect(() => {
    setSelectedTab(packages?.[0]?.period);
  }, [packages]);

  const packageTypes = useMemo(() => {
    const groupByPeriod = groupBy(packages, "period");
    const types = Object.keys(groupByPeriod);
    return {
      types,
      plans: groupByPeriod,
    };
  }, [packages]);

  return (
    <>
      <div className="d-flex justify-content-end">
        <IconButton
          aria-label="close"
          onClick={close}
          color="inherit"
          className="p-0"
        >
          <Icon fontSize="small">highlight_off</Icon>
        </IconButton>
      </div>
      <div className="container">
        <Typography
          component="h3"
          variant="h5"
          className="main-heading text-center my-4"
          gutterBottom
        >
          Select your plan
        </Typography>
        <Typography
          component="div"
          dangerouslySetInnerHTML={{ __html: subscriptionDescription }}
          className="text-center"
          gutterBottom
        />
        <div className="d-flex justify-content-center my-5">
          <RpSwitchTabs
            value={selectedTab}
            onChange={(e, v) => {
              setSelectedTab(v);
            }}
          >
            {packageTypes?.types?.map((t) => (
              <RpSwitchTab label={`${t} Months`} value={t} key={t} />
            ))}
          </RpSwitchTabs>
        </div>
        {packageTypes?.types?.map((t) => (
          <TabPanel value={selectedTab} index={t} key={t}>
            <div className={`row justify-content-center align-items-start`}>
              {packageTypes?.plans[t]?.map((plan) => (
                <div className="col-md-4 mb-5" key={plan.id}>
                  <RenewPackageCard
                    plan={plan}
                    onSelectPackage={onSelectPackage}
                    location={location}
                    setSelectedPackagePlan={setSelectedPackagePlan}
                    isSelected={
                      selectedUpcomingPkgId &&
                      String(selectedUpcomingPkgId) === String(plan.id)
                        ? true
                        : false
                    }
                  />
                </div>
              ))}
            </div>
          </TabPanel>
        ))}

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-end">
            <Button
              color="primary"
              variant="outlined"
              className="mr-2"
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onConfirm}
              className={
                !selectedUpcomingPkgId || !selectedPackagePlan
                  ? "disable-button"
                  : ""
              }
              disabled={!selectedUpcomingPkgId || !selectedPackagePlan}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
