import React from "react";
import PageLayout from "../../components/layouts/page/PageLayout";

function CreateCampaign() {
  return (
    <PageLayout>
      <iframe
        width={"100%"}
        height={"100%"}
        style={{ border: "none", minHeight: "90vh" }}
        title="Create Campaign"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src="https://docs.google.com/forms/d/e/1FAIpQLSfjSUAmLEcl5MK8ms_QmTu6rhVaYQHtAAEtzXOpbCl5FJwSKQ/viewform"
      ></iframe>
    </PageLayout>
  );
}

export default CreateCampaign;
