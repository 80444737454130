import React, { memo } from "react";
import { Helmet } from "react-helmet-async";

function MainSEO({
  title,
  description = "Brand Directed. Locally Perfected. The Hyperlocology advertising platform gives you the power of sophisticated digital marketing, backed by your brand, in an easy-to-use dashboard. Promote your business like a pro in just a few clicks!",
  url,
  image = "https://app.hyperlocology.com/img/Hyperlocology.png",
}) {
  const formattedTitle = title ? `${title} - Hyperlocology` : "Hyperlocology";
  const formattedURL = url
    ? `https://app.hyperlocology.com/${url}`
    : "https://app.hyperlocology.com/";

  return (
    <Helmet>
      <link rel="canonical" href={formattedURL} />

      <title>{formattedTitle}</title>
      <meta name="title" content={formattedTitle} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={formattedURL} />
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={formattedURL} />
      <meta property="twitter:title" content={formattedTitle} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
  );
}

export default memo(MainSEO);
