import React from "react";
// import { CircularProgress } from "@material-ui/core";

import { requestService } from "../../../services/requestService";
import useFetcher from "../../../helpers/hooks/useFetcher";
import { logger } from "../../../helpers/loggerHelper";
import { CircularProgress } from "@material-ui/core";
// import { formateKeyName } from "../../../helpers/utils";
// import { DISPLAY_FIELDS } from "./constants";
import SingleRequestDetail from "./displayBrandRequest/SingleRequestDetail";

function RequestDetails({ id }) {
  logger.info("id", id);
  const { data, isLoading } = useFetcher(
    id,
    requestService.getBrandRequestGetById,
    {
      revalidateOnFocus: false,
    }
  );

  logger.info("data", data);

  // const formattedData = useMemo(() => {
  //   const removeKey = [
  //     "id",
  //     "fk_user_id",
  //     "fk_brand_id",
  //     "fk_brand_request_id",
  //     "submitted_date",
  //     "user_name",
  //   ];
  //   if (!data) return null;
  //   return Object.keys(data)
  //     ?.filter((k) => (Array.isArray(data[k]) ? data[k]?.length : data[k]))
  //     ?.filter((k) => !removeKey.includes(k))
  //     ?.reduce((t, k) => ({ ...t, [k]: data[k] }), {});
  // }, [data]);

  // const renderData = useMemo(() => {
  //   if (!formattedData) return null;

  //   const requestType = formattedData?.brand_request_type;

  //   logger.info("formattedData", formattedData);
  //   logger.info("requestType", requestType);

  //   const displayFields = DISPLAY_FIELDS[requestType];

  //   const renderField = (k) => {
  //     if (!k) return "";
  //     const { key, type, name, fileNameKey } = k;
  //     const dt = formattedData[key];
  //     logger.info("dt", dt);
  //     if (Array.isArray(dt)) {
  //       return dt?.map((d) => d.channel_name)?.join(", ");
  //     } else if (dt?.toString()?.includes("http") || type === "file") {
  //       return (
  //         <a href={dt} target="_blank" download>
  //           {formattedData[fileNameKey]}
  //         </a>
  //       );
  //     } else {
  //       return dt;
  //     }
  //   };
  //   return (
  //     <>
  //       <div>
  //         <Typography className="text-capitalize fs-12" variant="h6">
  //           What can we help you with?
  //         </Typography>
  //         <Typography>{requestType}</Typography>
  //       </div>
  //       {displayFields.map((k) => {
  //         return (
  //           <div key={k.label}>
  //             <Typography className="text-capitalize fs-12" variant="h6">
  //               {formateKeyName(k.label)}
  //             </Typography>
  //             <Typography>{renderField(k)}</Typography>
  //           </div>
  //         );
  //       })}
  //     </>
  //   );
  // }, [formattedData]);

  if (isLoading)
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="primary" />
      </div>
    );
  return (
    <div className="brand-request-details">
      <SingleRequestDetail
        data={data}
        requestType={data?.brand_request_type}
        requestId={data?.system_generated_request_id}
        isLoading={isLoading}
      />
    </div>
  );
  // return <div>{renderData}</div>;
}

export default RequestDetails;
