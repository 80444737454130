import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { getFormatDate } from "../../helpers/utils";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "../../components/tables/TablePagination";
import ImageRP from "../../components/ImageRP";

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    height: "100%",
  },
  image: {
    minHeight: "200px",
  },
  dateLine: {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      left: "0",
      bottom: "-2px",
      width: "9%",
      height: "2px",
      backgroundColor: "#04a999",
    },
  },
  title: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
  },
});

export default function WhatsNewSection({
  whatsNew,
  totalNewData,
  newDataPageIndex,
  newDataPageSize,
  handleWhatsNewPage,
}) {
  const classes = useStyles();
  const totalCount = Math.ceil(totalNewData / newDataPageSize);
  return (
    <div>
      <section className="rc-section text-center my-4">
        <div className="row my-5 justify-content-center position-relative align-items-stretch">
          {whatsNew &&
            whatsNew.map((u) => {
              return (
                <div className="col-md-4 col-lg-3 mb-4" key={u.id}>
                  <Card className={classes.root}>
                    {/* <CardActionArea className={classes.image}> */}
                    <a
                      href={u.new_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="title_url"
                    >
                      <ImageRP
                        src={u.new_img}
                        type="IMG"
                        className="sg-image"
                      ></ImageRP>
                    </a>
                    {/* </CardActionArea> */}
                    <CardContent>
                      <Typography
                        component="span"
                        className={`d-flex justify-content-start rc-date mt-3 ${classes.dateLine}`}
                      >
                        {getFormatDate(
                          u?.new_date ? u?.new_date : u?.createdAt
                        )}
                      </Typography>
                      <Typography
                        component="h5"
                        variant="body1"
                        className={`rc-title pt-3 ${classes.title}`}
                      >
                        <a
                          href={u.new_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="title_url"
                        >
                          {u.new_title}
                        </a>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
        </div>
        <div className="row d-flex row d-flex justify-content-center paging">
          <TablePagination
            count={totalCount}
            page={newDataPageIndex}
            onChange={handleWhatsNewPage}
            variant="outlined"
            shape="rounded"
            className="float-right"
          />
        </div>
      </section>
    </div>
  );
}
