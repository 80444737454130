import React from "react";
import { Navbar } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import ProfileDropDown from "../../../containers/ProfileDropDown";
import HeaderBrandLogoDropDown from "../../../containers/HeaderBrandLogoDropDown";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    color: theme.palette.primary.main,
  },
}));

const MainHeader = ({ handleDrawerToggle }) => {
  const classes = useStyles();
  return (
    <>
      <Navbar
        fixed="top"
        expand="lg"
        variant="light"
        className="main-header py-1"
      >
        <div className="d-flex justify-content-between px-sm-4 w-100">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <HeaderBrandLogoDropDown />
          <div style={{ flexGrow: 1 }}></div>
          <ProfileDropDown />
        </div>
      </Navbar>
    </>
  );
};

export default MainHeader;
