import React, { useEffect, useMemo, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import moment from "moment";
import { Icon } from "@material-ui/core";
import businessMoment from "moment-business-days";
import { withStyles } from "@material-ui/styles";
import { CAMPAIGN_CONST } from "../../../../config/constants";

const CustomDatePikerStyle = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.primary.contrastText}!important`,
    border: "none!important",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 3px 10px #04A9992E",
    marginBottom: 8,
    "&.MuiOutlinedInput-root": {
      border: 0,
    },
  },
}))(DatePicker);

export default function LocationDate({
  onSelectStartDate,
  onSelectEndDate,
  start_date,
  end_date,
  campaign,
  loc,
  canChangeDate,
  initialDisabled,
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(end_date ? moment(end_date) : null);

  useEffect(() => {
    const { campaign_type, evergreen_participant_date_flag } = campaign;
    if (start_date) {
      let sDate = moment(start_date);
      if (campaign_type === "Evergreen" && evergreen_participant_date_flag) {
        let minStartDate = CAMPAIGN_CONST.BUFFER.JOIN_CAL_DAYS_BRAND.includes(
          campaign?.fk_brand_id
        )
          ? moment().add(CAMPAIGN_CONST.BUFFER.JOIN_CAL_DAYS, "day")
          : businessMoment().businessAdd(
              CAMPAIGN_CONST.BUFFER.JOIN_BUSINESS_DAYS
            );

        if (
          minStartDate.isBefore(
            moment(loc?.subscription?.subscription_start_date)
          )
        ) {
          minStartDate = moment(loc?.subscription?.subscription_start_date);
        }

        if (sDate.isBefore(minStartDate)) {
          sDate = minStartDate;
        }
      }
      setStartDate(sDate);
    }
  }, [start_date, campaign, loc?.subscription?.subscription_end_date]);

  const getEndDateConstraints = (startDate) => {
    const { evergreen_participant_max_value, evergreen_participant_min_value } =
      campaign;

    const minDate = moment(startDate)
      .add(evergreen_participant_min_value, "day")
      .subtract(1, "day");
    let maxDate = moment(startDate)
      .add(evergreen_participant_max_value, "day")
      .subtract(1, "day");

    // user can't select date after its subscription end date
    if (
      loc?.subscription &&
      maxDate.isAfter(moment(loc?.subscription?.subscription_end_date))
    ) {
      maxDate = moment(loc?.subscription?.subscription_end_date);
    }
    return { minDate, maxDate };
  };

  const dateConstraints = useMemo(() => {
    const {
      campaign_type,
      evergreen_participant_date_flag,
      evergreen_participant_min_value,
    } = campaign;

    let minStartDate;
    let maxStartDate;
    let minDate;
    let maxDate;
    let disabled = true;

    if (campaign_type === "Evergreen" && evergreen_participant_date_flag) {
      minStartDate = CAMPAIGN_CONST.BUFFER.JOIN_CAL_DAYS_BRAND.includes(
        campaign?.fk_brand_id
      )
        ? moment().add(CAMPAIGN_CONST.BUFFER.JOIN_CAL_DAYS, "day")
        : businessMoment().businessAdd(
            CAMPAIGN_CONST.BUFFER.JOIN_BUSINESS_DAYS
          );

      if (
        minStartDate.isBefore(
          moment(loc?.subscription?.subscription_start_date)
        )
      ) {
        minStartDate = moment(loc?.subscription?.subscription_start_date);
      }

      minDate = getEndDateConstraints(startDate || minStartDate).minDate;
      maxDate = getEndDateConstraints(startDate || minStartDate).maxDate;

      if (moment(loc?.subscription?.subscription_end_date).date() === 30) {
        maxStartDate = moment(loc?.subscription?.subscription_end_date)
          .subtract(evergreen_participant_min_value, "days")
          .add(2, "days");
      } else {
        maxStartDate = moment(loc?.subscription?.subscription_end_date)
          .subtract(evergreen_participant_min_value, "days")
          .add(1, "days");
      }
      disabled = false;
    }

    return {
      minStartDate,
      maxStartDate,
      minDate,
      maxDate,
      disabled,
    };
  }, [campaign, startDate]);

  const handleStartDateChange = (d) => {
    setStartDate(d);
    onSelectStartDate(d);

    const { campaign_type, evergreen_participant_date_flag } = campaign;

    if (endDate && moment(endDate).isValid()) {
      if (endDate.isBefore(d, "day")) {
        setEndDate(d);
        onSelectEndDate(d);
      } else if (
        campaign_type === "Evergreen" &&
        evergreen_participant_date_flag &&
        endDate.isBefore(getEndDateConstraints(d).minDate, "day")
      ) {
        const min_end_date = getEndDateConstraints(d).minDate;

        setEndDate(min_end_date);
        onSelectEndDate(min_end_date);
      }
    }
  };

  const handleEndDateChange = (d) => {
    setEndDate(d);
    onSelectEndDate(d);
  };

  return (
    <div className="location-date">
      <label>{canChangeDate ? "Select " : ""}Campaign Dates</label>
      <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <div className="row">
          <div className="col-sm">
            <div className="rp-date-input-box">
              <CustomDatePikerStyle
                // label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                animateYearScrolling
                // disablePast
                inputVariant="outlined"
                size="small"
                format="MMM Do, YYYY"
                disableToolbar
                // readOnly
                disabled={initialDisabled || dateConstraints.disabled}
                className="rp-date-picker"
                minDate={dateConstraints.minStartDate}
                maxDate={dateConstraints.maxStartDate}
                id={`start_date_${loc.id}`}
                placeholder="Start Date"
                FormHelperTextProps={{
                  style: {
                    position: "absolute",
                    top: "95%",
                    lineHeight: 1,
                  },
                }}
              />
              {canChangeDate && (
                <label
                  className="calendar-icon"
                  htmlFor={`start_date_${loc.id}`}
                >
                  <Icon
                    fontSize="small"
                    style={{
                      color:
                        initialDisabled || dateConstraints.disabled
                          ? "gray"
                          : "#fff",
                    }}
                  >
                    calendar_month
                  </Icon>
                  {/* <img
                    src={STATIC_IMAGES.calendar}
                    alt=""
                    width="16"
                    height="16"
                  /> */}
                </label>
              )}
            </div>
          </div>
          <div className="col-sm">
            <div className="rp-date-input-box">
              <CustomDatePikerStyle
                // label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                animateYearScrolling
                disablePast
                inputVariant="outlined"
                size="small"
                format="MMM Do, YYYY"
                disableToolbar
                className="rp-date-picker"
                disabled={
                  initialDisabled || dateConstraints.disabled || !startDate
                }
                minDate={dateConstraints.minDate}
                maxDate={dateConstraints.maxDate}
                minDateMessage=""
                id={`end_date_${loc.id}`}
                placeholder="End Date"
                FormHelperTextProps={{
                  style: {
                    position: "absolute",
                    top: "95%",
                    lineHeight: 1,
                  },
                }}
              />
              {canChangeDate && (
                <label className="calendar-icon" htmlFor={`end_date_${loc.id}`}>
                  <Icon
                    fontSize="small"
                    style={{
                      color:
                        dateConstraints.disabled || !startDate
                          ? "gray"
                          : "#fff",
                    }}
                  >
                    calendar_month
                  </Icon>
                  {/* <img
                    src={STATIC_IMAGES.calendar}
                    alt=""
                    width="16"
                    height="16"
                  /> */}
                </label>
              )}
            </div>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
}
