import React from "react";
import { Icon, Typography } from "@material-ui/core";

import RpMuiToolTip from "../../../components/muiCustom/popover/RpMuiToolTip";

const headerTooltipContent = {
  Location:
    "These are your locations that have active advertising and are eligible for a boost. If you don't see a location, it may not be eligible based on campaign timing or other factors.",
  "Boost Channel":
    "These are the channels your ads are appearing on. To boost only one channel, select the channel here. Otherwise, boosting all channels will optimize results.",
  "Boost Start Date":
    "This is the date you want to start spending more money on advertising via a boost.",
  "Boost End Date":
    "This is the date you want to end the boost, and spend will reduce back to the original amount.",
  "Current budget for the selected dates":
    "Based on the boost dates you've selected, this is the current budget for that time period. This allows you to see what you are spending without a boost.",
  "Add Boost Budget":
    "This is the amount of additional money you want to spend, above your current budget, between the start and end dates you've selected.",
};

function BoostHeaderTooltip({ label = "" }) {
  return (
    <RpMuiToolTip
      content={
        <Typography component="p" variant="caption" className="fs-10">
          {headerTooltipContent[label]}
        </Typography>
      }
    >
      <Icon
        component="img"
        src="/img/icons/info_green.svg"
        fontSize="small"
        color="primary"
        style={{ width: 15, height: 15 }}
        className="ml-1 cursor-p"
      />
    </RpMuiToolTip>
  );
}

export default BoostHeaderTooltip;
