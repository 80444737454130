import React, { memo, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Creatives from "../creatives/Creatives";
import LocationDate from "./LocationDate";
import LocationBudget from "./LocationBudget";
import LocationHeader from "./LocationHeader";
import { joinCampaignHelper } from "../../../../helpers/joinCampaignHelper";
import LocationBrandMatch from "./LocationBrandMatch";

const Location = ({
  campaign,
  loc,
  locFormData = {},
  expanded,
  setFieldValue,
  setFieldError,
  setExpanded,
  productList,
  getAdvanceSettingData,
  isUnsubscribed = false,
  subscriptionStatusType,
}) => {
  const handleOnExpand = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((l) => l !== panel));
    }
  };

  const shouldExpandIt = useMemo(() => {
    return (id) => expanded.includes(id);
  }, [expanded]);

  const locName = useMemo(
    () =>
      `${loc.location_name}`
        .concat(`, ${loc.state}`)
        .concat(`${loc.brand_region ? ", " + loc.brand_region : ""}`),
    [loc]
  );

  const minMaxRequired = useMemo(() => {
    return joinCampaignHelper.getRequiredMinMax(locFormData, campaign);
  }, [campaign, locFormData]);

  const handleOnSelectCreative = (selectedCreatives) => {
    const oldVal = { ...locFormData };
    setFieldValue(loc.id, { ...oldVal, creatives: selectedCreatives });
  };

  // to get advance settings data
  const handleOnFillSettingData = (selectedSettingsData) => {
    const oldVal = { ...locFormData };
    setFieldValue(loc.id, {
      ...oldVal,
      advance_setting: selectedSettingsData,
    });
  };
  const handleOnEnterBudget = (enteredBudget) => {
    const oldVal = { ...locFormData };
    const brand_contribution_amount =
      joinCampaignHelper.getBrandContributionAmount({
        amount: enteredBudget * 1,
        brand_contribution: campaign?.brand_contribution,
        brand_contribution_type: campaign?.brand_contribution_type,
        contribution_percentage: campaign?.contribution_percentage,
        contribution_max_limit: campaign?.contribution_max_limit,
        brand_match_preset_amount:
          loc?.budget?.brand_match_preset_amount * 1 || 0,
      });

    const isInvalidBudget = joinCampaignHelper.getRequiredMinMax(
      { ...locFormData, budget: enteredBudget },
      campaign
    );

    setFieldValue(loc.id, {
      ...oldVal,
      budget: enteredBudget,
      brand_contribution_amount:
        enteredBudget && isInvalidBudget
          ? 0
          : Number(brand_contribution_amount),
    });
  };

  const handleOnSelectStartDate = (d) => {
    const oldVal = { ...locFormData };
    setFieldValue(loc.id, {
      ...oldVal,
      start_date: moment(d).format("YYYY-MM-DD"),
    });
  };

  const handleOnSelectEndDate = (d) => {
    const oldVal = { ...locFormData };
    setFieldValue(loc.id, {
      ...oldVal,
      end_date: moment(d).format("YYYY-MM-DD"),
    });
  };

  return (
    <Accordion
      expanded={shouldExpandIt(loc.id)}
      onChange={handleOnExpand(loc.id)}
      className={`location ${loc?.isPastDue ? "past_due" : ""}`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="location-header"
      >
        <LocationHeader
          loc={loc}
          locFormData={locFormData}
          locName={locName}
          campaign={campaign}
          isUnsubscribed={isUnsubscribed}
          isCreative={loc?.is_creative}
          subscriptionStatusType={subscriptionStatusType}
        />
      </AccordionSummary>
      <AccordionDetails className="location-details">
        <div className="card w-100">
          <div className="card-body">
            <Creatives
              creatives={loc.creatives}
              onSelectCreative={handleOnSelectCreative}
              initiallySelected={locFormData.creatives}
              locationName={loc?.location_name}
              loc={loc}
              campaign={campaign}
              productList={productList}
              getAdvanceSettingData={getAdvanceSettingData}
              onFillSettingsData={handleOnFillSettingData}
              initialAnswers={locFormData.advance_setting}
              initialDisabled={isUnsubscribed}
            />
          </div>
          <div className="card-footer bg-white">
            <div className="card border-0">
              <div className="card-body px-0 py-0">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-5 py-2">
                      <LocationDate
                        onSelectStartDate={handleOnSelectStartDate}
                        onSelectEndDate={handleOnSelectEndDate}
                        start_date={locFormData?.start_date}
                        end_date={locFormData?.end_date}
                        campaign={campaign}
                        setFieldError={setFieldError}
                        loc={loc}
                        canChangeDate={joinCampaignHelper.canChangeDate(
                          campaign
                        )}
                        initialDisabled={isUnsubscribed}
                      />
                    </div>
                    <div className="col-lg-7 location-budget-input-container">
                      <div className="row">
                        <div className="col-md">
                          <div className="location-budget-box py-2">
                            <LocationBudget
                              onEnterBudget={handleOnEnterBudget}
                              budget={locFormData.budget}
                              locFormData={locFormData}
                              minMaxRequired={minMaxRequired}
                              campaignType={campaign?.budget_type}
                              // ability_to_set_budget={
                              //   campaign?.ability_to_set_budget
                              // }
                              // canChangeBudget={joinCampaignHelper.canChangeBudget(
                              //   campaign
                              // )}
                              initialDisabled={isUnsubscribed}
                            />
                          </div>
                        </div>
                        {campaign?.brand_contribution === 1 && (
                          <div className="col-md">
                            <div className="location-budget-box border-0 py-2">
                              <LocationBrandMatch
                                contribution={
                                  locFormData?.brand_contribution_amount
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(Location);
