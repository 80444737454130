import React from "react";
import ParticipantRoutes from "../../../helpers/routes/ParticipantRoutes";

const ParticipantLayout = () => {
  return (
    <>
      {/* <MainHeader /> */}
      <div className="participant-layout">
        <ParticipantRoutes />
      </div>
    </>
  );
};

export default ParticipantLayout;
