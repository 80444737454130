import { MSPackagesData } from "../../../helpers/staticData";
import MSPackageCard from "../subscriptionPackages/MSPackageCard";

export default function MSActivationBriefPackageDetails() {
  return (
    <div className="packages-detail my-5">
      <h3 className="section-heading mb-4">
        2. Google Advertising Program Tier Options
      </h3>
      <p>
        There are three tiers that you can participate in within this program:
        Tier 1, Tier 2 and Tier 3. Tier 1 branded keywords run in all states
        you’re registered in, Tier 2 non-branded keywords and Tier 3 banner ads
        run in a specified radius surrounding your location. For more
        information about the three program options, visit the Google Adwords
        program 3DR page.
      </p>

      <div className="row align-items-stretch my-5">
        {MSPackagesData.map((p) => (
          <div className="col-lg-4 mb-5" key={p.id}>
            <MSPackageCard plan={p} />
          </div>
        ))}
      </div>
    </div>
  );
}
