import React, { useState, useEffect, useContext, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { withFormik } from "formik";
import moment from "moment";

import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import { reportService } from "../../services/reportService";
import { renderTemplate } from "../../helpers/reportHelper";
import NoDataFound from "../../components/NoDataFound";
import CampaignReportFilters from "./CampaignReportFilters";
import ProgressChart from "../../components/charts/ProgressChart";
import TableChart from "../../components/charts/TableChart";
import AreaChart from "../../components/charts/AreaChart";
import DoughnutChart from "../../components/charts/DoughnutChart";
import TotalSpendChart from "../../components/charts/TotalSpendChart";
import BarChart from "../../components/charts/BarChart";
import PageLayout from "../../components/layouts/page/PageLayout";
import BackLink from "../../components/BackLink";
import PageHeader from "../../components/layouts/pageHeaders/PageHeader";
import useQuery from "../../helpers/hooks/useQuery";
import CampaignReportDetail from "./CampaignReportDetail";
import useFetcher from "../../helpers/hooks/useFetcher";
import { CUSTOM_WIDGET_PARAM } from "../../config/constants";
import StackedBarChart from "../../components/charts/StackedBarChart";
import { campaignReportHelper } from "../../helpers/campaignReportHelper";
import {
  formatDate,
  isBrandHasDrillFeature,
  parseDateString,
} from "../../helpers/drillUpDownHelper";
// import { parseDateString, formatDate } from "../../helpers/drillUpDownHelper";

const CampaignReport = ({ values, errors, handleChange, setFieldValue }) => {
  const { campaignSysId } = useParams();
  const { locationID } = useQuery();

  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const [contentLoaded, setContentLoaded] = useState(false);
  const [campaignReport, setCampaignReport] = useState(null);

  const reqData = useMemo(() => {
    const endPeriod = moment(
      `${values?.customRange?.to?.year} - ${values?.customRange?.to?.month}`,
      "YYYY-MM"
    );
    return {
      system_generated_campaign_id: `${campaignSysId}`,
      fk_brand_id: selectedBrand.id,
      fk_participant_id: loggedInUser?.participantData
        ? loggedInUser?.participantData?.id
        : 0,
      participant_system_id: loggedInUser?.participantData
        ? loggedInUser?.participantData?.system_generated_participant_id
        : "",
      userId: loggedInUser.id,
      fk_role_id: loggedInUser.fk_role_id,
      regions_id: values.region_id ? values.region_id : "",
      group_id: values.group_id ? values.group_id : "",
      location_id: values.location_id ? values.location_id : "",
      channel_id: values.channel_id ? values.channel_id : "",
      filter: values.filter,
      // start_period:
      //   values.filter === "custom" && values.customRange
      //     ? formatDate(parseDateString(values.customRange[0]))
      //     : "",
      // end_period:
      //   values.filter === "custom" && values.customRange
      //     ? formatDate(parseDateString(values.customRange[1]))
      //     : "",
      start_period:
        values.filter === "custom" && values.customRange
          ? isBrandHasDrillFeature(selectedBrand.system_generated_brand_id)
            ? formatDate(parseDateString(values.customRange[0]))
            : `${values.customRange.from.year}-${values.customRange.from.month}-01`
          : "",
      end_period:
        values.filter === "custom" && values.customRange
          ? isBrandHasDrillFeature(selectedBrand.system_generated_brand_id)
            ? formatDate(parseDateString(values.customRange[1]))
            : `${
                endPeriod && endPeriod.isValid()
                  ? endPeriod.endOf("month").format("YYYY-MM-DD")
                  : ""
              }`
          : "",
    };
  }, [selectedBrand?.id, loggedInUser, campaignSysId, values]);

  const { data: report, isLoading: loading } = useFetcher(
    Object.values(errors).length === 0 ||
      Object.values(errors).every((v) => v === "")
      ? reqData
      : null,
    reportService.getCampaignReport,
    { revalidateOnFocus: false }
  );

  const initialRange = useMemo(() => {
    return campaignReportHelper.getInitialCustomDateRange(report);
  }, [report]);

  useEffect(() => {
    setFieldValue("location_id", "");
  }, [values.group_id, setFieldValue]);

  useEffect(() => {
    if (values.filter !== "custom") {
      setFieldValue("customRange", "");
    }
  }, [values.filter, setFieldValue]);

  useEffect(() => {
    loader(loading);
  }, [loading, loader]);

  useEffect(() => {
    if (locationID) {
      setFieldValue("location_id", locationID);
    }
  }, [locationID, setFieldValue]);

  useEffect(() => {
    if (!loading) {
      setContentLoaded(true);
    }
    if (!isEmpty(report)) {
      setCampaignReport(report);
    }
  }, [loading]);

  useEffect(() => {
    //when no widget added for previously selected channel
    if (report && values?.channel_id) {
      const template = report?.template;
      const channelAvailable = template?.rows.some((row) =>
        row.columnRows.some((colRow) =>
          colRow.columns.some(
            (col) => col.widget && col.widget.channel === values.channel_id
          )
        )
      );

      if (!channelAvailable) {
        setFieldValue("channel_id", "");
      }
    }
  }, [report, values?.channel_id, setFieldValue]);

  const reportData = !loading && !isEmpty(report) ? report : campaignReport; //To set previous data until fetching new filtered data

  if ((!reportData || isEmpty(reportData)) && !loading) {
    return (
      <NoDataFound
        message={
          <div>
            <div>No Data found!</div>
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard`}
              className="button primary btn btn-primary my-4"
            >
              Back to dashboard
            </Link>
          </div>
        }
      />
    );
  }

  const renderChartWidget = (widget) => {
    if (!widget.data || widget.data.length === 0) {
      return <NoDataFound message="There is no record" height="100%" />;
    }

    let chartToRender = null;

    if (!Array.isArray(widget.data)) {
      return chartToRender;
    }

    const labels = Object.keys(widget.data[0]);

    if (!labels || labels.length === 0) {
      return chartToRender;
    }

    if (widget.chart_type === "progress") {
      const max = widget.data[0][labels[1]];
      chartToRender = (
        <ProgressChart
          data={widget.data}
          labels={labels}
          max={max ? max : 100}
          formats={widget?.format}
        />
      );
    } else if (widget.chart_type === "area") {
      chartToRender = (
        <AreaChart
          data={widget.data}
          labels={labels}
          title={widget.alt_name || widget.name}
          formats={widget?.format}
          widgetId={widget.id}
          values={values}
          initialRange={initialRange}
        />
      );
    } else if (widget.chart_type === "doughnut") {
      chartToRender = (
        <DoughnutChart
          data={widget.data}
          labels={labels}
          title={widget.alt_name || widget.name}
          formats={widget?.format}
        />
      );
    } else if (widget.chart_type === "bar") {
      chartToRender = (
        <BarChart
          data={widget.data}
          labels={labels}
          title={widget.alt_name || widget.name}
          formats={widget?.format}
        />
      );
    } else if (widget.chart_type === "stacked_bar") {
      chartToRender = (
        <StackedBarChart
          data={widget.data}
          title={widget.alt_name || widget.name}
          formats={widget?.format}
        />
      );
    } else if (widget.chart_type === "total_spend") {
      chartToRender = (
        <TotalSpendChart
          data={widget.data}
          widgetId={widget.id}
          labels={labels}
          title={widget.alt_name || widget.name}
          formats={widget?.format}
          showTotal
          values={values}
          initialRange={initialRange}
        />
      );
    }

    return chartToRender;
  };

  const renderTable = (widget) => {
    if (!widget.data || widget.data.length === 0) {
      return <NoDataFound message="There is no record" height="100%" />;
    }

    if (Array.isArray(widget.data)) {
      let headings = Object.keys(widget.data[0]);
      if (headings.includes("creative_image")) {
        headings = headings.filter((h) => h !== "creative_image");
      }
      return (
        <TableChart
          data={widget.data}
          headings={headings}
          formats={widget?.format}
        />
      );
    }

    return null;
  };

  return (
    <>
      <PageLayout className="campaign-report-container mx-0 mx-sm-2">
        <BackLink />
        <PageHeader title="Campaign Report" showLastUpdate />
        {contentLoaded ? (
          <div id="campaignReport" className="report campaign-report mt-0 mb-5">
            <div className="card campaign-detail">
              <div className="card-body">
                <CampaignReportDetail campaign={reportData} values={values} />
              </div>
            </div>

            <CampaignReportFilters
              handleChange={handleChange}
              values={values}
              campaign={report}
              initialRange={initialRange}
              setFieldValue={setFieldValue}
            />
            {renderTemplate({
              isLoading: loading,
              report,
              renderTable,
              renderChartWidget,
              channel_name: values.channel_id,
              reportType: "campaign",
              embedWidgetParams: {
                [CUSTOM_WIDGET_PARAM.BrandID]:
                  selectedBrand?.system_generated_brand_id,
                [CUSTOM_WIDGET_PARAM.CampaignID]: campaignSysId,
              },
              values,
            })}
          </div>
        ) : (
          <NoDataFound message="Loading..." />
        )}
      </PageLayout>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      start_date: "",
      end_date: "",
      location_id: "",
      channel_id: "",
      group_id: "",
      region_id: "",
      filter: "custom",
      customRange: "",
    };
  },
  enableReinitialize: true,
})(CampaignReport);
