import React, { useContext, useState } from "react";
import { Tab, Tabs, Typography, withStyles } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";

import PageLayout from "../../../components/layouts/page/PageLayout";
import CurrentSubscriptions from "./CurrentSubscriptions";
import SubscriptionHistory from "./SubscriptionHistory";
import InvoiceHistory from "./InvoiceHistory";
import { GlobalContext } from "../../../helpers/contexts/contexts";

const RpTabs = withStyles(() => ({
  root: {
    minWidth: "auto",
    minHeight: "auto",
    width: "auto",
    background: "#e5f6f500",
    padding: "5px 10px",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const RpTab = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    minWidth: "auto",
    minHeight: "auto",
    margin: "auto 5px",
    fontSize: ".7rem",
    textTransform: "none",
    opacity: 1,
    paddingTop: 7,
    paddingBottom: 4,
  },
  selected: {
    background: "rgba(4, 169, 153, .2)",
    color: "rgba(4, 169, 153, 1)",
    fontWeight: 600,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}))(Tab);

function SubscriptionInvoice() {
  const { selectedBrand } = useContext(GlobalContext);
  const [selectedTab, setSelectedTab] = useState(0);

  const isMobileAndTab = useMediaQuery({
    query: "(max-width: 976px)",
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`campaigns-tabpanel-${index}`}
        aria-labelledby={`campaigns-tab-${index}`}
        {...other}
        className="rp-campaign-tab"
      >
        {children}
      </div>
    );
  }
  return (
    <PageLayout className="mx-0 mx-sm-2">
      <Typography variant="h4" component="h1" gutterBottom className="mb-5">
        Media Plan History
      </Typography>
      <div
        style={{ background: "rgba(4, 169, 153, .1)" }}
        className="w-100 px-sm-1 py-3 mb-2 rounded"
      >
        <RpTabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          variant={isMobileAndTab ? "scrollable" : "standard"}
          scrollButtons={isMobileAndTab ? "on" : "off"}
          centered={isMobileAndTab ? true : false}
          selectionFollowsFocus
        >
          <RpTab label={`Current Plans`} />
          <RpTab label={`Plan History`} />
          {selectedBrand?.id !== 1 && selectedBrand?.id !== 2 ? (
            <RpTab label={`Invoice History`} />
          ) : null}
        </RpTabs>
      </div>
      {selectedTab === 0 ? (
        <TabPanel value={selectedTab} index={0}>
          <CurrentSubscriptions />
        </TabPanel>
      ) : null}
      {selectedTab === 1 ? (
        <TabPanel value={selectedTab} index={1}>
          <SubscriptionHistory />
        </TabPanel>
      ) : null}
      {selectedTab === 2 ? (
        <TabPanel value={selectedTab} index={2}>
          <InvoiceHistory />
        </TabPanel>
      ) : null}
    </PageLayout>
  );
}

export default SubscriptionInvoice;
