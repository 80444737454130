import React from "react";
import { withStyles, StepConnector } from "@material-ui/core";
import RpSteps from "./RpSteps";

const CustomStepConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#239084",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#239084",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#239084",
    borderRadius: 1,
  },
})(StepConnector);

function CheckoutStepper(step) {
  return (
    <RpSteps
      activeStep={step?.activeStep}
      steps={["Choose your plan", "Review your order", "Payment"]}
      connector={<CustomStepConnector />}
    />
  );
}

export default CheckoutStepper;
