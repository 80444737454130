import React, { useContext } from "react";
import { Typography } from "@material-ui/core";

import PageLayout from "../../components/layouts/page/PageLayout";
import PostcardCampaignList from "./PostcardCampaignList";
import { GlobalContext } from "../../helpers/contexts/contexts";

const PostcardReportsPage = () => {
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);

  return (
    <PageLayout className="admin-dashboard-container mx-0 mx-sm-2">
      <div className="dashboard participant-dashboard">
        <Typography variant="h5" className="widget-title mb-4 mb-md-auto">
          Direct Mail Reports
        </Typography>
        <PostcardCampaignList
          selectedBrand={selectedBrand}
          loggedInUser={loggedInUser}
        />
      </div>
    </PageLayout>
  );
};

export default PostcardReportsPage;
