import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import LoadingButton from "../../../components/loaders/LoadingButton";
import { authService } from "../../../services";
import { logger } from "../../../helpers/loggerHelper";

export default function ResendEmailLink({
  email,
  subscribe,
  buttonText = "Resend email",
  onSuccess,
  text = false,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = async () => {
    setIsLoading(true);
    try {
      const res = await authService.resendToken({ email, subscribe });
      if (res.status === 200) {
        onSuccess();
        toastr.success("Success", "Validation link is send on your email");
      }
    } catch (err) {
      logger.error("Error in resendToken", { err });
    } finally {
      setIsLoading(false);
    }
  };
  if (text) {
    return (
      <button
        onClick={handleOnClick}
        type="button"
        className="btn btn-link link py-0 m-0 px-1 border-0"
      >
        {isLoading ? <strong>Sending..</strong> : text}
      </button>
    );
  }
  return (
    <div className="resend-email-link">
      <div className="mt-5 text-center">
        <LoadingButton
          onClick={handleOnClick}
          text={buttonText}
          className={"primary mx-auto"}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
