import React, { memo, useCallback } from "react";
import {
  Grid,
  Checkbox,
  FormLabel,
  Paper,
  FormControlLabel,
  Divider,
  Typography,
} from "@material-ui/core";
import { useFormikContext } from "formik";

import RpMuiSelect from "../../../components/muiCustom/select/RpMuiSelect";
import { boostCampaignHelper } from "../../../helpers/boostCampaignHelper";
import BoostHeaderTooltip from "./BoostHeaderTooltip";
import RpVirtualList from "../../../components/virtual/RpVirtualList";
import RpDatePicker from "../../../components/muiCustom/date/RpDatePicker";
import RpFormError from "../../../components/muiCustom/error/RpFormError";
import RpCurrencyField from "../../../components/muiCustom/textField/RpCurrencyField";
import { CHANNELS } from "../../../config/constants";
import { getFormatDate } from "../../../helpers/utils";

const MobileLabel = ({ label }) => {
  return (
    <FormLabel className="d-lg-none fw-sb text-dark d-block">
      {label}
      <BoostHeaderTooltip label={label} />
    </FormLabel>
  );
};

function BoostLocationSetting({
  // campaignId,
  locationData,
  channelsList,
  is_selected_boost_type,
  boosted_duration,
  campaignHasSearchChannel,
  campaignHasOnlyPMaxChannel,
  LOC_INIT_STATE,
}) {
  const { values, setFieldValue, setFieldTouched, errors } = useFormikContext();
  // console.log("values", values); // Debugging the structure of values
  // console.log("errors", errors); // Debugging the structure of values
  // console.log("touched", touched); // Debugging the structure of values

  // Update handlers to use location ID directly
  const handleOnSelectStartDate = (date, locationId) => {
    setFieldValue(
      `selectedLoc.${locationId}.start_date`,
      date ? date.format("YYYY-MM-DD") : ""
    );
    setFieldValue(`selectedLoc.${locationId}.end_date`, "");
    setFieldValue(`selectedLoc.${locationId}.current_budget`, "");
  };

  const handleOnSelectEndDate = (date, locationId) => {
    setFieldValue(
      `selectedLoc.${locationId}.end_date`,
      date ? date.format("YYYY-MM-DD") : ""
    );
  };

  const handleOnBoostBudgetChange = (event, locationId) => {
    setFieldValue(`selectedLoc.${locationId}.boost_budget`, event.target.value);
    setFieldTouched(`selectedLoc.${locationId}.boost_budget`, true);
  };

  // const handleChangeInput = (v, locationId) => {
  //   setFieldValue(`selectedLoc.${locationId}.current_budget`, v);
  // };

  const isSearchSelected = useCallback(
    (lc, checkInSelectionOnly = false) => {
      // Early return for non-selection check cases
      if (!checkInSelectionOnly) {
        if (!campaignHasSearchChannel) return false;
        if (!is_selected_boost_type) return campaignHasSearchChannel;
      }

      // Return false if no channels
      if (!lc?.channels?.length) return false;

      // Memoize channel IDs using useMemo
      const searchId = channelsList.find(
        (c) =>
          c?.id === CHANNELS.SEARCH.id ||
          c?.label?.toLowerCase() === CHANNELS.SEARCH.lowerCaseName
      )?.id;

      const pMaxId = channelsList.find(
        (c) =>
          c?.id === CHANNELS.PMAX.id ||
          c?.label?.toLowerCase() === CHANNELS.PMAX.lowerCaseName
      )?.id;

      // Check if either search or pMax channels are included
      return lc.channels.includes(searchId) || lc.channels.includes(pMaxId);
    },
    [campaignHasSearchChannel, is_selected_boost_type, channelsList] // Removed unused dependency campaignHasOnlyPMaxChannel
  );

  return (
    <>
      <RpVirtualList
        items={locationData}
        renderItem={(loc, index) => {
          const locationId = loc.fk_location_id;
          const lc = values?.selectedLoc?.[locationId];

          const isLocationSelected = lc?.locationId === locationId;
          const isSearch = isSearchSelected(lc);

          const dateConstraints = boostCampaignHelper.getMinMaxDateConstraint({
            loc,
            boosted_duration,
            locFormData: lc,
            isSearch: isSearch,
          });

          return (
            <>
              <Paper
                className="p-2 pt-3 pb-3 pb-lg-1"
                key={locationId}
                elevation={0}
                square
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md>
                    <MobileLabel label="Location" />
                    <FormControlLabel
                      name={`selectedLoc.${locationId}.locationId`}
                      control={
                        <Checkbox
                          color="primary"
                          checked={isLocationSelected}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setFieldValue(`selectedLoc.${locationId}`, {
                              ...LOC_INIT_STATE,
                              locationId: isChecked ? locationId : "",
                              channels: isChecked
                                ? channelsList?.map((c) => c.id)
                                : [],
                            });
                          }}
                        />
                      }
                      label={
                        <div className="fw-sb">
                          <div>
                            {campaignHasOnlyPMaxChannel
                              ? "All Locations"
                              : loc?.location_name}
                          </div>
                          {loc?.last_boosted_start_date &&
                            loc?.last_boosted_end_date && (
                              <Typography
                                variant="caption"
                                className="fs-10 fw-sb"
                                component="div"
                              >
                                Last Boosted:{" "}
                                <span className="text-nowrap">
                                  {`${getFormatDate(loc?.last_boosted_start_date, "MM/DD/YYYY")} - ${getFormatDate(loc?.last_boosted_end_date, "MM/DD/YYYY")}`}
                                </span>
                              </Typography>
                            )}
                        </div>
                      }
                    />
                  </Grid>

                  {!!is_selected_boost_type && (
                    <Grid item xs={12} md>
                      <MobileLabel label="Boost Type" />
                      <RpMuiSelect
                        options={channelsList?.map((c) => ({
                          label: c.label,
                          value: c.id,
                        }))}
                        name={`selectedLoc.${locationId}.channels`}
                        value={lc?.channels || []}
                        onChange={(selectedValues) => {
                          setFieldValue(
                            `selectedLoc.${locationId}.channels`,
                            selectedValues || []
                          );

                          const wasSearch = isSearchSelected(lc, true);
                          const willBeSearch =
                            selectedValues.includes(CHANNELS.SEARCH.id) ||
                            selectedValues.includes(CHANNELS.PMAX.id);

                          // Reset dependent fields if search/pmax status changes
                          if (wasSearch !== willBeSearch) {
                            setFieldValue(
                              `selectedLoc.${locationId}.end_date`,
                              ""
                            );
                            setFieldValue(
                              `selectedLoc.${locationId}.current_budget`,
                              ""
                            );
                          }
                        }}
                        disabled={!isLocationSelected}
                        multiple
                        placeholder="Channels"
                        darkTheme
                      />
                      <RpFormError
                        error={errors?.selectedLoc?.[locationId]?.channels}
                        show={isLocationSelected}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md>
                    <MobileLabel label="Boost Start Date" />
                    <RpDatePicker
                      value={lc?.start_date || null}
                      onChange={(date) =>
                        handleOnSelectStartDate(date, locationId)
                      }
                      disabled={!isLocationSelected}
                      placeholder="Start Date"
                      minDate={dateConstraints.minStartDate}
                      maxDate={dateConstraints.maxStartDate}
                      darkTheme
                    />
                    <RpFormError
                      error={errors?.selectedLoc?.[locationId]?.start_date}
                      show={isLocationSelected}
                    />
                  </Grid>

                  <Grid item xs={12} md>
                    <MobileLabel label="Boost End Date" />
                    <RpDatePicker
                      value={lc?.end_date || null}
                      onChange={(date) =>
                        handleOnSelectEndDate(date, locationId)
                      }
                      disabled={!isLocationSelected || !lc?.start_date}
                      placeholder="End Date"
                      minDate={dateConstraints.minEndDate}
                      maxDate={
                        dateConstraints.maxEndDate[
                          dateConstraints.maxEndDate.length - 1
                        ]
                      }
                      shouldDisableDate={(date) => {
                        if (!isSearch) return false;
                        const validEndDates = dateConstraints.maxEndDate;
                        return !validEndDates.includes(
                          date.format("YYYY-MM-DD")
                        );
                      }}
                      darkTheme
                    />
                    <RpFormError
                      error={errors?.selectedLoc?.[locationId]?.end_date}
                      show={isLocationSelected}
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                    {!!lc?.current_budget && (
                      <MobileLabel label="Current budget for selected dates" />
                    )}
                    <CurrentBudget
                      start_date={getFormatDate(lc?.start_date, "YYYY-MM-DD")}
                      end_date={getFormatDate(lc?.end_date, "YYYY-MM-DD")}
                      currentBudget={lc?.current_budget}
                      handleChangeInput={(v) =>
                        handleChangeInput(v, locationId)
                      }
                      system_generated_location_id={
                        loc?.system_generated_location_id
                      }
                      fk_campaign_id={campaignId}
                      fk_participant_id={loc?.fk_participant_id}
                    />
                  </Grid> */}

                  <Grid item xs={12} md>
                    <MobileLabel label="Add Boost Budget" />
                    <RpCurrencyField
                      name={`selectedLoc.${locationId}.boost_budget`}
                      placeholder="$0.00 USD"
                      value={lc?.boost_budget || ""}
                      onChange={(e) => handleOnBoostBudgetChange(e, locationId)}
                      disabled={!isLocationSelected}
                      error={Boolean(
                        isLocationSelected &&
                          errors?.selectedLoc?.[locationId]?.boost_budget
                      )}
                      helperText={
                        <RpFormError
                          error={
                            errors?.selectedLoc?.[locationId]?.boost_budget
                          }
                          show={isLocationSelected}
                          component="span"
                        />
                      }
                      decimalScale={0}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Divider
                className={index === locationData.length - 1 ? "d-none" : ""}
              />
            </>
          );
        }}
      />
    </>
  );

  // return <>{locationData?.map((loc, index) => {})}</>;
}

export default memo(BoostLocationSetting);
