// import {
//     useLocation
// } from "react-router-dom";

import history from "../../config/history";
import queryString from "query-string";

/**
 * Custome hook to fetch query string params
 */
export default () => {
  // return new URLSearchParams(useLocation().search);
  return queryString.parse(history.location.search);
};
