import React, { memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  heading: {
    fontWeight: 700,
    fontFamily: "Montserrat",
    fontSize: 20,
    color: "#0F3447",
    marginRight: 50,
    marginLeft: 50,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, persists, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" align="center" className={classes.heading}>
        {children}
      </Typography>
      {onClose && !persists ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

const RpMUIDialog = ({
  open,
  onClose,
  heading = "",
  children,
  persists = false,
  ...rest
}) => {
  const handleOnClose = (e, reason) => {
    if (persists && reason === "backdropClick") {
      return;
    }
    onClose();
  };
  return (
    <Dialog
      onClose={handleOnClose}
      aria-labelledby={`${heading || "rp-mui"}-dialog-title`}
      open={open}
      disableEscapeKeyDown={persists}
      fullWidth
      {...rest}
    >
      {heading && (
        <DialogTitle
          id={`${heading || "rp-mui"}-dialog-title`}
          persists={persists}
          onClose={handleOnClose}
        >
          {heading}
        </DialogTitle>
      )}
      <DialogContent dividers>{children}</DialogContent>
      {/* <DialogActions>
        <Button autoFocus onClick={handleOnClose} color="primary">
          Save changes
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default memo(RpMUIDialog);
