import React from "react";
import { Link } from "react-router-dom";

const NotFound404 = () => {
  return (
    <div className="not-found-404">
      <div className="card shadow">
        <div className="card-body d-flex-center flex-column">
          <img
            className="my-2"
            src={`${process.env.PUBLIC_URL}/img/Group2146.svg`}
            alt=""
          />
          <h2 className="heading3">404 Not found</h2>
          <p className="heading12 px-md-5 text-center">
            The page you are looking for was not found!
          </p>
          <Link
            to={`${process.env.PUBLIC_URL}/dashboard`}
            className="button primary large my-4"
          >
            Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
};
export default NotFound404;
