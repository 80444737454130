import AxiosInstance from "../config/AxiosInstance";
import { API_BASE_URL_ADMIN } from "../config/appConfig";

export const paymentService = {
  charge,
};

function charge(data) {
  return AxiosInstance.post(
    `${API_BASE_URL_ADMIN}/makePaymentAndJoinCampaign`,
    data
  );
}
