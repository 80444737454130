import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Checkbox,
  Chip,
  ClickAwayListener,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
} from "@material-ui/core";
import { FastField, useFormikContext } from "formik";

import { checkboxGroupMDInput } from "../../../helpers/formInputs";

export default function StateRegisteredInField({ statesOptions = [] }) {
  const dropDownRef = useRef();
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (anchorEl) {
      const halfWindow = window.innerHeight / 2;
      const ElTop = dropDownRef.current.getBoundingClientRect();

      if (ElTop && ElTop.top > halfWindow) {
        dropDownRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [anchorEl]);

  const getLabel = useCallback(
    (v) => {
      return statesOptions.find((s) => `${s.value}` === `${v}`)?.label || "";
    },
    [statesOptions]
  );

  const isAllSelected = useMemo(() => {
    return statesOptions?.length === values?.state_registered_in?.length;
  }, [statesOptions, values?.state_registered_in]);

  const handleOpen = () => {
    setAnchorEl(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnRemove = (s) => () => {
    const currentValue = values?.state_registered_in || [];
    setFieldValue(
      "state_registered_in",
      currentValue.filter((st) => st !== s)
    );
  };

  const handleOnChangeSelectAll = () => {
    setFieldValue(
      "state_registered_in",
      isAllSelected ? [] : statesOptions?.map((s) => `${s.value}`)
    );
  };

  return (
    <div className="my-4">
      <FormLabel required>States Registered In</FormLabel>
      <div>
        <ClickAwayListener
          onClickAway={handleClose}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >
          <div
            aria-controls="states-select-menu"
            aria-haspopup="true"
            role="button"
            className="state-list-box"
            onClick={() => setFieldTouched("state_registered_in", true)}
          >
            <div className="data-box w-100">
              {values?.state_registered_in?.length ? (
                values?.state_registered_in?.map((s) => (
                  <Chip
                    label={getLabel(s)}
                    key={s}
                    onDelete={handleOnRemove(s)}
                    className="mr-2 mb-2"
                    size="small"
                  />
                ))
              ) : (
                <div className="w-100 h-100 placeholder" onClick={handleOpen}>
                  Select
                </div>
              )}
            </div>
            {anchorEl ? (
              <Icon
                className="expand_icon"
                color="action"
                onClick={handleClose}
              >
                close
              </Icon>
            ) : (
              <Icon className="expand_icon" color="action" onClick={handleOpen}>
                arrow_drop_down
              </Icon>
            )}

            {anchorEl ? (
              <div
                className={`rp-custom-drop-down ${anchorEl ? "open" : ""}`}
                ref={dropDownRef}
              >
                <Grid container spacing={0} className="my-2">
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleOnChangeSelectAll}
                          color="primary"
                          checked={isAllSelected}
                          size="small"
                        />
                      }
                      label={isAllSelected ? "Unselect All" : "Select All"}
                    />
                  </Grid>
                  {statesOptions?.map((a) => (
                    <Grid item xs={6} key={a.value}>
                      <FastField
                        name="state_registered_in"
                        label={<span className="d-flex-center">{a.label}</span>}
                        value={`${a.value}`}
                        component={checkboxGroupMDInput}
                        formGroupClass="m-0 p-0"
                        max={5}
                        size="small"
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
        {errors?.state_registered_in && touched?.state_registered_in ? (
          <FormHelperText className="text-danger">
            {errors?.state_registered_in}
          </FormHelperText>
        ) : null}
      </div>
    </div>
  );
}
