import AxiosInstance from "../config/AxiosInstance";
import { API_BASE_URL_OPEN, API_BASE_URL_ADMIN } from "../config/appConfig";

export const authService = {
  login,
  forgotPassword,
  validateResetPassToken,
  resetPassword,
  checkCurrentPassword,
  updatePassword,
  validateLinkToken,
  setNewPassword,
  resendToken,
  signUp,
};

function login(user) {
  return AxiosInstance.post(`${API_BASE_URL_OPEN}/login`, user);
}

function forgotPassword(email) {
  return AxiosInstance.post(`${API_BASE_URL_OPEN}/forgotPassword`, email);
}

function validateResetPassToken(token) {
  const sanitizedToken = encodeURIComponent(token.trim());
  return AxiosInstance.get(
    `${API_BASE_URL_OPEN}/forgotPassword?token=${sanitizedToken}`
  );
}

function resetPassword(data) {
  return AxiosInstance.put(`${API_BASE_URL_OPEN}/forgotPassword`, data);
}

function checkCurrentPassword(data) {
  return AxiosInstance.patch(`${API_BASE_URL_ADMIN}/checkpassword`, data);
}

function updatePassword(data) {
  return AxiosInstance.patch(`${API_BASE_URL_ADMIN}/updatePassword`, data);
}

/************* */
function validateLinkToken(token) {
  return AxiosInstance.get(`${API_BASE_URL_OPEN}/verifyToken?token=${token}`);
}

function setNewPassword(data) {
  return AxiosInstance.put(`${API_BASE_URL_OPEN}/setNewPassword`, data);
}

function resendToken(data) {
  return AxiosInstance.post(`${API_BASE_URL_OPEN}/resendToken`, data);
}

function signUp(data) {
  return AxiosInstance.post(`${API_BASE_URL_OPEN}/signUp`, data);
}
