import React, { useMemo } from "react";
import CreativeCarousel from "../../../../components/carousels/CreativeCarousel";

export default function CreativeChannels({ creatives, view }) {
  const getCreativeChannels = useMemo(() => {
    const creative = creatives.find((c) => c.creative_id === view);
    return creative ? creative.package_channels : [];
  }, [creatives, view]);

  // const creativeName = useMemo(() => {
  //   const creative = creatives.find((c) => c.creative_id === view);
  //   return creative ? creative.creative_name : "";
  // }, [creatives, view]);

  return (
    <>
      <div className="creative-channels">
        {/* {selectedBrand?.id !== 3 && creativeName ? (
          <div className="d-flex align-items-center justify-content-end mb-2 mb-sm-0">
            <RpPopover popContent="Provide Feedback">
              <Button
                size="small"
                endIcon={<Icon color="primary">feedback</Icon>}
                onClick={() => setFeedback(true)}
              ></Button>
            </RpPopover>
          </div>
        ) : null} */}
        <div className="card">
          <div className="cord-body">
            <CreativeCarousel channels={getCreativeChannels} />
          </div>
        </div>
      </div>
      {/* <RpMUIDialog
        open={feedback}
        onClose={() => setFeedback(false)}
        fullWidth
        maxWidth="md"
        heading="Creative feedback"
      >
        <ParticipantFeedback
          close={() => setFeedback(false)}
          creativeId={view}
          creativeName={creativeName}
          campaign={campaign}
          location={loc}
        />
      </RpMUIDialog> */}
    </>
  );
}
