import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createTheme({
  palette: {
    primary: {
      // main: "#09aba7",
      main: "#04a999", //"#229084",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#F5239C", // "#07304b",
      contrastText: "#ffffff",
    },
    dark: {
      main: "#003044",
      dark: "#00141c",
      contrastText: "#ffffff",
    },
    error: {
      main: "#EA3A3D",
    },
    warning: {
      main: "#F9B959",
    },
    success: {
      main: "#1AD598",
    },
    text: {
      primary: "#143C4B",
    },
  },

  typography: {
    fontFamily: [
      "Open Sans",
      "Montserrat",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 5,
  },
});

theme.overrides = {
  MuiButton: {
    root: {
      textTransform: "none",
      fontWeight: 500,
    },
    containedPrimary: {
      "&:hover": {
        backgroundColor: `${theme.palette.primary.dark}!important`,
      },
    },
    containedSecondary: {
      "&:hover": {
        backgroundColor: `${theme.palette.secondary.dark}!important`,
      },
    },
    outlinedPrimary: {
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}!important`,
        color: `${theme.palette.primary.contrastText}!important`,
      },
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: ".92rem",
    },
    asterisk: {
      color: `${theme.palette.error.main}!important`,
    },
  },
  InputLabel: {
    asterisk: {
      color: `${theme.palette.error.main}!important`,
    },
  },
  MuiDialog: {
    scrollPaper: {
      marginTop: "30px",
    },
    scrollBody: {
      marginTop: "30px",
    },
  },
  MuiPaper: {
    elevation2: {
      boxShadow: "2px 13px 50px #5160681A",
    },
  },
};

theme = responsiveFontSizes(theme);
export default theme;
