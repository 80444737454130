import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const DEFAULT = {
  BG_COLOR: "#96e2da",
};

Chart.defaults.global.responsive = true;
Chart.defaults.global.animation.duration = 0;

Chart.defaults.global = {
  ...Chart.defaults.global,
  defaultColor: DEFAULT.BG_COLOR,
  defaultFontSize: 10,
  defaultFontColor: "#516068",
  // defaultFontStyle: "bold",
};

Chart.defaults.global.hover = {
  mode: "nearest",
  animationDuration: 0,
};

Chart.defaults.global.tooltips = {
  ...Chart.defaults.global.tooltips,
  backgroundColor: "rgba(255, 255, 255, 1)",
  titleFontColor: "#000",
  titleFontStyle: "normal",
  bodyFontColor: "#000",
  bodyFontStyle: "bold",
  footerFontColor: "#000",
  borderColor: "#eee",
  borderWidth: 1,
  displayColors: false,
};

/** config each element type */
Chart.defaults.global.elements = Object.keys(
  Chart.defaults.global.elements
).reduce((t, key) => {
  const ele = Chart.defaults.global.elements[key];
  return {
    ...t,
    [key]: {
      ...ele,
      backgroundColor: DEFAULT.BG_COLOR,
    },
  };
}, {});

// Chart.defaults.global.interaction = {
//   ...Chart.defaults.global.interaction,
//   hoverBackgroundColor: "#04A999",
//   hoverBorderWidth: 0,
// };

Chart.pluginService.register({
  plugins: [ChartDataLabels],
  beforeInit: function (chart) {
    chart.legend.afterFit = function () {
      this.height = this.height + 30;
    };
  },
});

export const datasetDefaultOptions = {
  hoverBackgroundColor: "#04A999",
  // hoverBorderWidth: 0,
  // backgroundColor: DEFAULT.BG_COLOR,
};

export const areaDatasetDefaultOptions = {
  backgroundColor: DEFAULT.BG_COLOR,
  borderWidth: 2,
  borderColor: ["#04A999"],
  pointRadius: 5,
  pointHoverRadius: 6,
  pointHitRadius: 0,
  pointBackgroundColor: "#fff",
  hoverBorderWidth: 2,
  hoverBorderColor: ["#04A999"],
};
