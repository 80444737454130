import { IS_PROD } from "./appConfig";

import { BRAND_REQUEST_TEMPLATE_BASE_URL } from "./appConfig";

export const localStorageConstant = {
  TOKEN_NAME: "token",
};

export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",

  LOGOUT: "LOGOUT",
  RESET_HAS_LOGGED_OUT: "RESET_HAS_LOGGED_OUT",

  UPDATE_BRAND_DATA: "UPDATE_BRAND_DATA",
};

export const resetPasswordConstants = {
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAIL: "FORGOT_PASSWORD_FAIL",

  VALIDATE_RESET_PASS_TOKEN_REQUEST: "VALIDATE_RESET_PASS_TOKEN_REQUEST",
  VALIDATE_RESET_PASS_TOKEN_SUCCESS: "VALIDATE_RESET_PASS_TOKEN_SUCCESS",
  VALIDATE_RESET_PASS_TOKEN_FAIL: "VALIDATE_RESET_PASS_TOKEN_FAIL",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",

  RESET_STATE: "RESET_STATE",
};

export const userConstants = {
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAIL: "GET_USER_FAIL",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",
};

export const brandConstants = {
  SET_GLOBAL_BRAND: "SET_GLOBAL_BRAND",

  GET_PAGE_SETUP_REQUEST: "GET_PAGE_SETUP_REQUEST",
  GET_PAGE_SETUP_SUCCESS: "GET_PAGE_SETUP_SUCCESS",
  GET_PAGE_SETUP_FAIL: "GET_PAGE_SETUP_FAIL",

  GET_BRANDS_REQUEST: "GET_BRANDS_REQUEST",
  GET_BRANDS_SUCCESS: "GET_BRANDS_SUCCESS",
  GET_BRANDS_FAIL: "GET_BRANDS_FAIL",
};

export const campaignConstants = {
  SAVE_DRAFT_REQUEST: "SAVE_DRAFT_REQUEST",
  SAVE_DRAFT_POSTCARD_REQUEST: "SAVE_DRAFT_POSTCARD_REQUEST",
  SAVE_DRAFT_SUCCESS: "SAVE_DRAFT_SUCCESS",
  SAVE_DRAFT_FAIL: "SAVE_DRAFT_FAIL",

  RESET_IS_DRAFT_SUCCESS: "RESET_IS_DRAFT_SUCCESS",

  GET_CAMPAIGN_REPORT_REQUEST: "GET_CAMPAIGN_REPORT_REQUEST",
  GET_CAMPAIGN_REPORT_SUCCESS: "GET_CAMPAIGN_REPORT_SUCCESS",
  GET_CAMPAIGN_REPORT_FAIL: "GET_CAMPAIGN_REPORT_FAIL",
  RESET_ERROR: "RESET_ERROR",
};

export const statusCodeConstants = {
  OK: 200,
  CREATED: 201,
};

export const FILE_TYPE_MIME_TYPE = {
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
};

export const AUTO_LOGOUT = {
  CHANNEL_NAME: "tabReloadChannel",
};

export const settingsConstants = {
  SET_SETTINGS: "SET_SETTINGS ",
  GET_SETTINGS: "GET_SETTINGS",
};

export const MAILING_TYPES = {
  S: "saturated",
  T: "targeted",
};

export const LocSubsStatus = {
  UNSUBSCRIBED: "Unsubscribed",
  FALLOUT: "FallOut",
  LESS_THAN_30: "SubscriptionRemainedLessThan30Days",
  IS_CREATIVE: "Creatives are not available",
};

export const CUSTOM_WIDGET_PARAM = {
  LocationID: "LocationID",
  BrandID: "BrandID",
  CampaignID: "CampaignID",
  ParticipantID: "ParticipantID",
  GroupID: "GroupID",
  StartDate: "StartMonth",
  EndDate: "EndMonth",
};

export const subscriptionPlanLength = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 18,
];

export const brandsWithCsvDownloadOption = [
  "BD393497", // The Salad Station
  "BD702554", // Top Rail Fence
  "BD201330", // Window Hero
  "BD674975", // The Designery
  "BD416667", // Anytime Fitness
  "BD371710", // Waxing the City
  "BD372455", // Do it Best
  "BD793200", // Papa Murphy's
  "BD822958", // Discover Strength
  "BD235520", // The Peach Cobbler Factory
  "BD653181", // 	Tupelo Honey
];

export const subscriptionPlanFeeType = {
  SUB_FEE_AND_MEDIA_COST: "1", // "Subscription fee + media cost",
  MEDIA_COST_ONLY: "2", // "Media cost only",
  SUB_FEE_ONLY: "3", //"Subscription fee only",
};

export const subscriptionPlanPaymentType = {
  INVOICE: "invoice",
  STRIPE: "stripe",
};

export const subscriptionPaymentStatus = {
  UNPAID: "1",
  PENDING: "2",
  PAID: "3",
};

export const stripeSubscriptionStatus = {
  ACTIVE: "1",
  INCOMPLETE: "2",
  INCOMPLETE_EXPIRED: "3",
  PAST_DUE: "4",
  CANCELED: "5",
  EXPIRED: "6",
};

export const FILTERS = {
  YTD: "ytd",
  LAST_QUARTER: "last_quarter",
  CUSTOM: "custom",
};

export const DRILL_LEVELS = {
  YEAR: "year",
  QUARTER: "quarter",
  MONTH: "month",
  DAY: "day",
};

export const WIDGETS_WITH_DRILL_FEATURE = [
  29, 30, 33, 44, 45, 46, 47, 48, 56, 72, 73, 74, 75, 76, 99, 107, 111, 112,
  113, 114, 115, 132, 134, 137,
];

/**
 * @constant
 * @description brands have drill feature
 * add 'all' to display to all brands
 * BD301908 Ana Test Brand
 * BD832735 THE TEN SPOT
 * BD175207 Popeyes Louisiana Kitchen
 */
export const BRANDS_HAVE_DRILL_FEATURE = ["BD301908", "BD832735", "BD175207"]; // add 'all' to display to all brands

/**
 * @constant
 * @description advanced setting question types
 */
export const ADVANCED_SETTING_Q_TYPE = {
  SHORT_ANSWER: "short_answer",
  LONG_ANSWER: "paragraph",
  PRODUCT: "product",
  ATTACHMENT: "attachment",
  MULTI_SELECTOR: "multiple_selection",
  RADIUS: "radius",
};

/**
 * @constant QUESTION_TYPE_RADIUS
 * @description properties of the question type radius
 */
export const QUESTION_TYPE_RADIUS = {
  MIN: 1,
  MAX: 25,
};

export const BRAND_REQUEST_TYPE = {
  CREATIVE_REQUEST: "Creative Request",
  BUDGET_APPROVAL_CONFIRMATION_REQUEST:
    "Budget Approval / Confirmation / Request",
  PAUSE_REQUEST_CLOSED_LOCATION: "Pause Request / Closed Location",
  NEW_STORE_OPENING: "New Store Opening",
  NEW_CAMPAIGN: "New Campaign",
  GENERAL_REQUEST: "General Request",
  REPORT_REQUEST: "Report Request",
  TARGETING_CHANGES: "Targeting Changes",
};

export const BOOST_CAMP_PAY_TYPE = {
  INVOICE: "invoice",
  STRIPE: "credit",
};

/**
 * @constant
 * @description campaign constants
 */
export const CAMPAIGN_CONST = {
  BUDGET_TYPE: {
    LOCATION_SPECIFIC: "Location Specific",
    PRESET: "Pre-set",
  },
  TYPE: {
    NORMAL: "Normal",
    EVERGREEN: "Evergreen",
  },
  BUFFER: {
    JOIN_BUSINESS_DAYS: 5, // in business days
    JOIN_CAL_DAYS: 1, // in calendar days
    JOIN_CAL_DAYS_BRAND: IS_PROD ? ["BD175207", 86] : [24],
  },
};

export const CHANNELS = {
  SEARCH: {
    id: 1,
    name: "Search",
    lowerCaseName: "search",
  },
  PMAX: {
    id: 17,
    name: "PMax",
    lowerCaseName: "pmax",
  },
};

export const BRAND_REQUEST_TEMPLATE_URL = {
  BUDGET_SHEET: `${BRAND_REQUEST_TEMPLATE_BASE_URL}/Budget_Sheet_Template.xlsx`,
  LOCATION_UPLOAD_SHEET: `${BRAND_REQUEST_TEMPLATE_BASE_URL}/Location_Upload_Sheet_Template.xlsx`,
  SEARCH_CREATIVE_TEMPLATE: `${BRAND_REQUEST_TEMPLATE_BASE_URL}/Search_Creative_Template_RSA.xlsx`,
  SOCIAL_AD_COPY_TEMPLATE: `${BRAND_REQUEST_TEMPLATE_BASE_URL}/Social_Ad_Copy_Template.xlsx`,
  CREATIVE_SPECIFICATION_TEMPLATE: `${BRAND_REQUEST_TEMPLATE_BASE_URL}/Creative_Specs_Template.pdf`,
};
