import { Icon } from "@material-ui/core";

export default function RpImageTextInline({
  text = "",
  image = "",
  textProps = {},
  imageProps = {},
  containerProps = {},
  alignCenter = true,
}) {
  return (
    <div
      className={`d-flex ${alignCenter ? "align-items-center" : ""}`}
      {...containerProps}
    >
      {image && (
        <Icon
          component="img"
          src={image}
          width={10}
          height={10}
          className="mr-1"
          fontSize="small"
          {...imageProps}
        />
      )}
      <div {...textProps}>{text}</div>
    </div>
  );
}
