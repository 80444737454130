import { Button, FormHelperText, FormLabel } from "@material-ui/core";
import {
  Form,
  FieldArray,
  useFormikContext,
  ErrorMessage,
  FastField,
} from "formik";

import {
  checkboxGroupMDInput,
  textMDInputFormik,
} from "../../../helpers/formInputs";
import { addEmptyOption } from "../../../helpers/utils";
import MSActivationBriefPackageDetails from "./MSActivationBriefPackageDetails";
import AdvisorDetailFields from "./AdvisorDetailFields";
import DurationField from "./DurationField";
import EmailListFields from "./EmailListFields";
import StateRegisteredInField from "./StateRegisteredInField";
import FormikMUIAutocompleteSelectInput from "../../../components/muiCustom/select/FormikMUIAutocompleteSelectInput";
import { MSPackagesData } from "../../../helpers/staticData";
import MSBriefHelp from "./MSBriefHelp";

export default function MSActivationBriefForm({
  msStaticData,
  isNewSubmission,
  shouldDisableMSID,
}) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className="card ms-activation-brief-form">
      <MSBriefHelp />
      <div className="card-body p-md-5">
        <h3 className="section-heading mb-4">
          1. Financial Advisor Information
        </h3>

        <Form>
          <div className="row">
            <div className="col-md-6">
              <FormLabel required>Team or Individual</FormLabel>
              <FastField
                name="team_or_individual"
                component={FormikMUIAutocompleteSelectInput}
                options={[
                  {
                    label: "Individual",
                    value: "Individual",
                  },
                  {
                    label: "Team",
                    value: "Team",
                  },
                ]}
              />
            </div>
          </div>
          <p>
            {values?.team_or_individual === "Team"
              ? `Please add all the Financial Advisors on your team.`
              : `Please enter the name of the Financial Advisor for whom you are
            filling out this brief.`}
          </p>
          <FieldArray
            name="advisorDetail"
            // component={AdvisorDetailFields}
            shouldDisableMSID={shouldDisableMSID}
            render={(arrayHelpers) => (
              <AdvisorDetailFields
                {...arrayHelpers}
                shouldDisableMSID={shouldDisableMSID}
              />
            )}
          />

          <div className="row">
            <div className="col-md-6">
              <FormLabel required>Approved Team Name</FormLabel>
              <FastField
                name="approved_team_name"
                component={textMDInputFormik}
                size="small"
                disabled={isNewSubmission ? true : false}
              />
            </div>

            <div className="col-md-6">
              <FormLabel required>Functional Title</FormLabel>
              {msStaticData?.functional_title && (
                <FastField
                  name="functional_title"
                  component={FormikMUIAutocompleteSelectInput}
                  options={addEmptyOption(
                    msStaticData?.functional_title?.map((d) => ({
                      label: d.title,
                      value: d.id,
                    })) || []
                  )}
                />
              )}
            </div>
            <div className="col-md-6">
              <FormLabel required>Line of Business</FormLabel>
              {msStaticData?.line_of_business && (
                <FastField
                  name="line_of_business"
                  component={FormikMUIAutocompleteSelectInput}
                  options={addEmptyOption(
                    msStaticData?.line_of_business?.map((d) => ({
                      label: d.title,
                      value: d.id,
                    })) || []
                  )}
                />
              )}
            </div>
          </div>

          <MSActivationBriefPackageDetails />

          <div className="row">
            <div className="col-md-6">
              <FormLabel required>Tier</FormLabel>
              <FastField
                name="tier"
                component={FormikMUIAutocompleteSelectInput}
                options={MSPackagesData.map((p) => ({
                  label: p.tier,
                  value: p.tier,
                }))}
              />
            </div>

            <div className="col-md-6">
              <FormLabel required>Duration</FormLabel>
              <DurationField />
            </div>
          </div>

          <div className="my-4">
            Based on the demand in your area, our team will create a monthly
            media budget recommendation for you after the submission of this
            form.
          </div>

          <div>
            <FormLabel>
              Investment Areas of Focus *{" "}
              <small>(We recommend you choose between 3 and 5)</small>
            </FormLabel>
            <ErrorMessage
              name="area_of_focus"
              component={FormHelperText}
              className="text-danger"
            />

            <div className="row">
              {msStaticData?.investment_areas_focus
                ? [
                    ...(msStaticData?.investment_areas_focus?.map((d) => ({
                      label: d.title,
                      value: d.id,
                    })) || []),
                    { label: "Other", value: -1 },
                  ]?.map((a) => (
                    <div className="col-md-6" key={a.value}>
                      <FastField
                        name="area_of_focus"
                        label={
                          <span className="d-flex-center">
                            <span>{a.label}</span>
                            {String(a.value) === "-1" &&
                            values?.["area_of_focus"]?.includes(
                              `${a.value}`
                            ) ? (
                              <div className="other_area_of_focus">
                                <FastField
                                  name="other_area_of_focus"
                                  component={textMDInputFormik}
                                  size="small"
                                  className="ml-2"
                                  formGroupClass="m-0 p-0"
                                />
                              </div>
                            ) : null}
                          </span>
                        }
                        value={`${a.value}`}
                        component={checkboxGroupMDInput}
                        formGroupClass={`m-0 p-0 ${
                          `${a.value}` === "-1" &&
                          values?.["area_of_focus"]?.includes(`${a.value}`)
                            ? "other_area_of_focus_form_group"
                            : ""
                        }`}
                        max={5}
                      />
                    </div>
                  ))
                : null}
            </div>
          </div>

          <div className="mt-5 mb-2">
            <FormLabel required>
              Towns, Cities, Prominent Neighborhoods or Streets in Your Area{" "}
            </FormLabel>
            <div>
              <small>
                (Locations to be used in content creation (keywords, ads) and
                for geographic targeting parameters)
              </small>
            </div>
            <FastField
              name="street_in_your_area"
              component={textMDInputFormik}
              size="small"
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <StateRegisteredInField
                statesOptions={
                  msStaticData?.states?.map((d) => ({
                    label: d.state_code,
                    value: d.id,
                  })) || []
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormLabel>
                Office Street Address *{" "}
                <small>(Your ads will be localized using this address)</small>
              </FormLabel>
              <FastField
                name="street_address"
                component={textMDInputFormik}
                size="small"
              />
            </div>

            <div className="col-md-6">
              <FormLabel required>Office City</FormLabel>
              <FastField
                name="city"
                component={textMDInputFormik}
                size="small"
              />
            </div>
            <div className="col-md-6">
              <FormLabel required>Office State</FormLabel>
              {msStaticData?.states && (
                <FastField
                  name="state"
                  component={FormikMUIAutocompleteSelectInput}
                  options={addEmptyOption(
                    msStaticData?.states?.map((d) => ({
                      label: `${d.state_name} (${d.state_code})`,
                      value: d.id,
                    })) || []
                  )}
                />
              )}
            </div>
            <div className="col-md-6">
              <FormLabel required>Office Zip Code</FormLabel>
              <FastField
                name="zip_code"
                component={textMDInputFormik}
                size="small"
                onKeyUp={(e) => {
                  const v = e.target.value;
                  const vUpper = v.toUpperCase();
                  setFieldValue("zip_code", vUpper);
                }}
              />
            </div>
            <div className="col-md-6">
              <FormLabel>
                Office Phone Number *{" "}
                <small>(To be included in external advertising)</small>
              </FormLabel>
              <FastField
                name="phone"
                component={textMDInputFormik}
                size="small"
              />
            </div>
            <div className="col-md-6">
              <FormLabel>
                Size of Marketing Territory *{" "}
                <small>
                  (Your ads will be active in this radius around your address)
                </small>
              </FormLabel>
              <FastField
                name="radius"
                component={textMDInputFormik}
                size="small"
                placeholder="We recommend 30-50 miles"
              />
            </div>
            <div className="col-md-12">
              <FormLabel required>Morgan Stanley FA/Team Website URL</FormLabel>
              <FastField
                name="landing_page_url"
                component={textMDInputFormik}
                size="small"
              />
            </div>
          </div>

          <EmailListFields />

          <div className="my-4 text-center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="px-5"
            >
              Continue
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
