// channels
import instagram from "../assets/img/channels/instagram.svg";
import twitter from "../assets/img/channels/twitter.svg";
import facebook from "../assets/img/channels/facebook.svg";
import youtube from "../assets/img/channels/youtube.svg";
import pinterest from "../assets/img/channels/pinterest.svg";
import linkedin from "../assets/img/channels/linkedin.svg";
import display from "../assets/img/channels/display.svg";
import search from "../assets/img/channels/search.svg";
import ecomm from "../assets/img/channels/ecomm.svg";
import video from "../assets/img/channels/video.svg";
import audio from "../assets/img/channels/audio.svg";
import yelp from "../assets/img/channels/yelp.png";
import waze from "../assets/img/channels/waze.png";
import dooh from "../assets/img/channels/dooh.png";
import ctv from "../assets/img/channels/ctv.png";
import pmax from "../assets/img/channels/pmax.png";

import bulbIcon from "../assets/img/bulb_icon.svg";
import rightAppend from "../assets/img/rightappend.svg";
import gridIcon from "../assets/img/grid.svg";
import listIcon from "../assets/img/list.svg";
import home from "../assets/img/home_icon.svg";
import faq from "../assets/img/faq1.png";
import faq2 from "../assets/img/faq2.png";
import videoThumbnail from "../assets/img/videoThumbnail.png";
import videoThumbnail1 from "../assets/img/videoThumbnail1.png";
import vid_box_1 from "../assets/img/vid_box_1.png";
import vid_box_2 from "../assets/img/vid_box_2.png";
import how_it_works_1 from "../assets/img/how_it_works_1.png";
import how_it_works_2 from "../assets/img/how_it_works_2.png";
import how_it_works_3 from "../assets/img/how_it_works_3.png";
import how_it_works_img_1 from "../assets/img/how_it_works_img_1.png";
import how_it_works_img_2 from "../assets/img/how_it_works_img_2.png";
import how_it_works_img_3 from "../assets/img/how_it_works_img_3.png";
import box_hit_1 from "../assets/img/box_hit.png";
import home_feature_image from "../assets/img/home_feature_image_hit.png";
import downArrow from "../assets/img/downArrow.svg";
import home_hero_bg from "../assets/img/home_hero_bg.png";
import infoRed from "../assets/img/info_red.svg";
import infoYellow from "../assets/img/info_yellow.svg";
import infoWhite from "../assets/img/info-white.svg";
import calendar from "../assets/img/calendar.svg";
import user from "../assets/img/user.svg";
import locationYellow from "../assets/img/location_yellow.svg";
import profileBg from "../assets/img/profileBg.svg";
import infoTheme from "../assets/img/info-theme.svg";
import envelopeIcon from "../assets/img/envelope_icon.png";
import handsetIcon from "../assets/img/handset_icon.png";
import youtubeIcon from "../assets/img/youtube.png";
import rcFaq from "../assets/img/rc-faq.svg";
import rcArticle from "../assets/img/rc-article.svg";
import rcEducationVideo from "../assets/img/rc-education.svg";
import rcWhatsNew from "../assets/img/rc-graph.svg";
import maintenance from "../assets/img/maintenance.png";
import feedbackImg from "../assets/img/userFeedback.svg";

export const STATIC_IMAGES = {
  twitter,
  instagram,
  facebook,
  youtube,
  linkedin,
  pinterest,
  display,
  search,
  ecomm,
  video,
  vid_box_1,
  vid_box_2,
  audio,
  yelp,
  waze,
  dooh,
  ctv,
  pmax,
  bulbIcon,
  rightAppend,
  gridIcon,
  listIcon,
  home,
  faq,
  faq2,
  videoThumbnail,
  videoThumbnail1,
  how_it_works_1,
  how_it_works_2,
  how_it_works_3,
  how_it_works_img_1,
  how_it_works_img_2,
  how_it_works_img_3,
  box_hit_1,
  home_feature_image,
  downArrow,
  home_hero_bg,
  infoRed,
  infoYellow,
  infoWhite,
  calendar,
  user,
  locationYellow,
  profileBg,
  infoTheme,
  envelopeIcon,
  handsetIcon,
  youtubeIcon,
  rcFaq,
  rcArticle,
  rcEducationVideo,
  rcWhatsNew,
  maintenance,
  feedbackImg,
};
