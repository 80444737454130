import React, { useCallback } from "react";
import {
  Chip,
  InputLabel,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  CloseRounded,
  CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import { useDropzone } from "react-dropzone";

const maxSize = 150 * 1024 * 1024; // 150 MB

const useDropzoneStyles = makeStyles((theme) => ({
  dropzone: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    textAlign: "center",
    cursor: "pointer",
    transition: "border 0.3s ease-in-out",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      borderColor: theme.palette.primary.dark,
    },
    "&.error": {
      borderColor: theme.palette.error.main,
    },
  },
  icon: {
    fontSize: 48,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  fileInfo: {
    marginTop: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: "0.75rem",
  },
  fileTypeNote: {
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#666",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "8px",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
  },
  chip: {
    backgroundColor: "#f0f9f7",
    color: "#04a999",
    height: "24px",
    fontSize: "11px",
  },
}));

const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const RpDropzone = ({
  field,
  form: { errors, setFieldValue, isValid },
  label,
  accept,
  className = "",
  downloadTemplate,
  multiple,
  ...rest
}) => {
  const classes = useDropzoneStyles();

  const remove = useCallback(
    (e) => {
      e.stopPropagation();
      setFieldValue(field.name, "");
    },
    [field.name]
  );

  const fileTypes = accept
    ? (Array.isArray(accept) ? accept : accept.split(",")).map((type) =>
        type.trim()
      )
    : null;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = multiple ? acceptedFiles : acceptedFiles[0];
      setFieldValue(field.name, files);
    },
    [field.name, setFieldValue, multiple]
  );

  // Custom file validator
  const validateFile = useCallback(
    (file) => {
      if (!fileTypes) return null;

      // Check file size first (50MB = 52428800 bytes)
      if (file.size > maxSize) {
        return {
          code: "file-too-large",
          message: `File is too large. Maximum file size is 150MB`,
        };
      }

      const fileExtension = file.name
        ? `.${file.name.split(".").pop().toLowerCase()}`
        : "";
      const isValidExtension = fileTypes.some(
        (type) =>
          type &&
          fileExtension &&
          type.toLowerCase() === fileExtension.toLowerCase()
      );

      if (!isValidExtension) {
        return {
          code: "file-invalid-type",
          message: `File type must be ${fileTypes.join(", ")}`,
        };
      }

      return null;
    },
    [fileTypes, multiple, field.value]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      maxSize: maxSize,
      multiple: multiple || false,
      validator: validateFile,
      // Don't set accept here
    });

  // Custom error message handling
  const getErrorMessage = (fileRejections, formError) => {
    if (fileRejections?.length > 0) {
      const rejection = fileRejections[0];
      if (rejection.errors[0].code === "file-too-large") {
        return `File size (${formatFileSize(rejection.file.size)}) exceeds maximum limit of 150MB`;
      }
      return rejection.errors[0].message;
    }
    return formError;
  };

  const hasError =
    !isValid && (errors[field.name] || fileRejections.length > 0);
  const errorMessage = getErrorMessage(fileRejections, errors[field.name]);

  return (
    <div className={`${classes.inputWrapper} ${className}`}>
      <div className="d-md-flex align-items-start mb-2">
        <InputLabel
          shrink
          classes={{ root: classes.label }}
          margin="dense"
          disableAnimation
          style={{
            color: "#1B262C",
            fontSize: ".92rem",
            fontWeight: "700",
            padding: "4px 0px 3px 0px",
            flex: "1",
          }}
          required={rest?.required}
        >
          {label}
        </InputLabel>
        <div className="d-flex">{downloadTemplate}</div>
      </div>
      <Paper
        {...getRootProps()}
        elevation={0}
        className={`${classes.dropzone} ${hasError ? "error" : ""}`}
      >
        <input
          {...getInputProps({
            accept: fileTypes ? fileTypes.join(",") : undefined,
          })}
        />
        <CloudUploadIcon className={classes.icon} />

        {field.value ? (
          <div className={classes.fileInfo}>
            <Typography variant="body2">
              <CloseRounded
                onClick={remove}
                className="mx-1 mr-2 text-danger fw-b shadow"
                titleAccess="Remove"
              />
              Selected:{" "}
              {multiple ? (
                Array.from(field.value).map((file, index) => (
                  <div key={index}>
                    {file.name} ({formatFileSize(file.size)})
                  </div>
                ))
              ) : (
                <>
                  {field.value.name} ({formatFileSize(field.value.size)})
                </>
              )}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Maximum file size: 150MB
            </Typography>
          </div>
        ) : (
          <div>
            <Typography variant="body1">
              {isDragActive
                ? "Drop the files here"
                : `Drag and drop ${multiple ? "files" : "a file"} here, or click to select`}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Maximum file size: 150MB
            </Typography>
          </div>
        )}

        {hasError && (
          <Typography className={classes.errorText}>{errorMessage}</Typography>
        )}
      </Paper>
      {fileTypes && (
        <div className={classes.fileTypeNote}>
          <Typography variant="caption" className={classes.label}>
            Accepted file types:
          </Typography>
          <div className={classes.chipContainer}>
            {fileTypes.map((type) => (
              <Chip
                key={type}
                label={type}
                size="small"
                className={classes.chip}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
