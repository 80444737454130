import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function FAQSection({ faq = [] }) {
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  useEffect(() => {
    if (faq.length) {
      setExpandedAccordion(faq[0].id);
    }
  }, [faq]);

  return (
    <div className="faq-section w-100 my-4">
      {faq &&
        faq.map((data) => (
          <Accordion
            key={data.id}
            className="ac-faq-card"
            expanded={expandedAccordion === data.id}
            onChange={handleChange(data.id)}
            TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                className="mb-0 faq-question"
                component="h5"
                variant="body1"
              >
                {data.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className="faq-answer"
                component="div"
                dangerouslySetInnerHTML={{ __html: data.answer }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}
