import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Visibility } from "@material-ui/icons";
import { Icon, IconButton } from "@material-ui/core";

import TableServer from "../../../components/tables/TableServer";
import { logger } from "../../../helpers/loggerHelper";
import useFetcher from "../../../helpers/hooks/useFetcher";
import { requestService } from "../../../services/requestService";
import RpMUIModal from "../../../components/modals/RpMUIModal";
import RequestDetails from "./RequestDetails";
import { LoaderContext } from "../../../helpers/contexts/contexts";

// Styles object
const styles = {
  tableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "initial",
    minHeight: "36px",
  },
};

function RequestListing() {
  const loader = useContext(LoaderContext);
  const [open, setOpen] = useState(null);
  logger.info("open", open);

  const [filters, setFilters] = useState({
    page: 1,
    row: 2,
    sortBy: "",
    sortByDir: "",
  });

  const { data, isLoading, isValidating } = useFetcher(
    filters,
    requestService.getBrandRequestListing,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    loader(isLoading || isValidating);
  }, [loader, isLoading, isValidating]);

  const resData = data?.data || [];
  const { total_pages, total } = data?.meta || {};

  logger.info("data listing", data);
  const columns = useMemo(
    () => [
      {
        Header: "Request Id",
        accessor: "system_generated_request_id",
        width: 200,
        Cell: ({ value }) => <div style={styles.tableCell}>{value}</div>,
      },
      {
        Header: "Request Type",
        accessor: "brand_request_type",
        width: 200,
        Cell: ({ value }) => <div style={styles.tableCell}>{value}</div>,
      },
      {
        Header: "Submitted By",
        accessor: "user_name",
        width: 200,
        Cell: ({ value }) => <div style={styles.tableCell}>{value}</div>,
      },
      {
        Header: "Submitted On",
        accessor: "submitted_date",
        width: 200,
        Cell: ({ value }) => <div style={styles.tableCell}>{value}</div>,
      },
      {
        Header: "View",
        accessor: "view",
        width: 100,
        disableSortBy: true,
        Cell: ({ row }) => (
          <div style={styles.tableCell}>
            <IconButton
              onClick={() => setOpen(row?.original?.id)}
              color="inherit"
              className="p-0"
            >
              <Visibility />
            </IconButton>
          </div>
        ),
      },
    ],
    []
  );

  const handleCloseModal = () => {
    setOpen(null);
  };

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, searchKey, sortBy, sortByDir }) => {
      setFilters((p) => ({
        ...p,
        page: pageIndex + 1,
        row: pageSize,
        sortBy: sortBy,
        sortByDir: sortByDir,
        search: searchKey,
      }));
    },
    []
  );

  return (
    <div className="p-0">
      <TableServer
        columns={columns}
        data={resData}
        showSearch={false}
        fetchData={handleFetchData}
        isLoading={isLoading}
        pageCount={total_pages}
        // hideSearchLabel={hideSearchLabel}
        // filters={filters}
        totalRecords={total}
        // initialPageSize={}
        // useLayout
        containerClass="border-left-0 border-right-0 border-top-0"
      />

      {!!open && (
        <RpMUIModal open={!!open} onClose={handleCloseModal} scroll="body">
          <div className="d-flex justify-content-end align-items-center">
            <Icon
              src="/img/icons/black-cross-icon.png"
              alt="close"
              onClick={handleCloseModal}
              component="img"
              className="cursor-p"
            />
          </div>
          <RequestDetails id={open} />
        </RpMUIModal>
      )}
    </div>
  );
}

export default RequestListing;
