import React, { useMemo } from "react";
import ImageRP from "../../../../../components/ImageRP";

export default function CreativeChannels({ creatives, view }) {
  const getCreativeChannels = useMemo(() => {
    const creative = creatives.find((c) => c.creative_id === view);
    return creative ? creative.package_channels : [];
  }, [creatives, view]);

  return (
    <>
      <div className="creative-channels">
        <div className="card h-100">
          <div className="card-body creative-cerd text-center">
            {getCreativeChannels?.map((c) => (
              <div className="mb-1" key={c.id}>
                {/* <Typography color="primary" variant="body2">{c?.creative_name}</Typography> */}
                <ImageRP
                  src={c?.attachment}
                  type="CREATIVE"
                  alt=""
                  className="img-fluid rounded"
                  style={{
                    maxHeight: 185,
                    objectFit: "contain",
                    maxWidth: 240,
                    display: "inline-block",
                    margin:
                      getCreativeChannels.length === 1 ? "5rem 0" : "auto",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
