import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Button, Typography } from "@material-ui/core";

import {
  isErrorCampaignsSelector,
  isLoadingCampaignsSelector,
  isSavedSelector,
} from "../../../selectors/campaignSelectors";
import { campaignActions } from "../../../actions/campaignActions";
import {
  LoaderContext,
  GlobalContext,
} from "../../../helpers/contexts/contexts";
import NoDataFound from "../../../components/NoDataFound";
import { campaignService } from "../../../services/campaignService";
import ParticipantPageLayout from "../../../components/layouts/page/ParticipantPageLayout";
import ConfirmJoinCampaignTotalCard from "../../../components/joinCampaigns/ConfirmJoinCampaignTotalCard";
import { joinCampaignHelper } from "../../../helpers/joinCampaignHelper";
import { canJoinCampaign } from "../../../helpers/utils";
import SelectedLocationCard from "./SelectedLocationCard";
import PageLayout from "../../../components/layouts/page/PageLayout";
import ConfirmStripeEmbedCheckoutPopup from "../../../components/stripe/embedCheckout/ConfirmStripeEmbedCheckoutPopup";
import { stripeMicroService } from "../../../services/stripe-microservice/stripeMicroService";
import { logger } from "../../../helpers/loggerHelper";

const JoinCampaignConfirmationPage = ({
  isSaved,
  isError,
  joinCampaignNow,
  isLoadingCampaigns,
  // setSaveDraftSuccess,
}) => {
  const loader = useContext(LoaderContext);
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [openPayment, setOpenPayment] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getCampaign() {
      setLoading(true);
      try {
        const campaignReqData = {
          id: state.campaignId,
          brand_id: selectedBrand.id,
          fk_participant_id: loggedInUser.participantData.id,
        };
        const res = await campaignService.getPostcardCampaign(campaignReqData);
        if (res.status === 200) {
          const campaignData = res.data.rows[0];
          setCampaign(campaignData || null);
        }
      } catch (err) {
        logger.error("Error in getCampaign", { err });
      } finally {
        setLoading(false);
      }
    }

    getCampaign();
  }, [state, selectedBrand, loggedInUser]);

  useEffect(() => {
    loader(isLoadingCampaigns || loading);
  }, [isLoadingCampaigns, loading, loader]);

  useEffect(() => {
    if (isSaved) {
      dispatch(campaignActions.resetIsSavedDraftSuccess());
      history.replace({
        pathname: `${process.env.PUBLIC_URL}/dashboard/thank-you`,
        state: {
          from: "join_campaign_confirm",
          campaignType: campaign?.campaign_type,
        },
      });
    }
  }, [isSaved]);

  useEffect(() => {
    if (isError?.response?.data) {
      const redirect = isError?.response?.data?.redirect;
      if (redirect) {
        history.replace(`${process.env.PUBLIC_URL}/dashboard`);
      }
    }
  }, [isError, history]);

  const totals = useMemo(() => {
    if (campaign) {
      return joinCampaignHelper.getTotalCalculations(campaign);
    }
    return {};
  }, [campaign]);

  const dataToSave = useCallback(
    (payment_status = 0) => {
      const { participant_campaign } = campaign || {};
      const confirmData = {
        email_id: loggedInUser.email,
        fk_campaign_id: participant_campaign.fk_campaign_id,
        // system_generated_campaign_id:
        //   participant_campaign.system_generated_campaign_id,
        fk_campaign_name: participant_campaign.fk_campaign_name,
        fk_participant_id: loggedInUser?.participantData?.id,
        is_draft: false,
        start_date: participant_campaign.start_date,
        end_date: participant_campaign.end_date,
        locationData: JSON.stringify(participant_campaign.locationData),
        user_id: loggedInUser.id,
        user_name: `${loggedInUser.first_name} ${loggedInUser.last_name}`,
        payment_status: payment_status,
        total_budget: participant_campaign.total_budget,
        splitBudgetEqually: participant_campaign.splitBudgetEqually,
        campaign_type: participant_campaign.campaign_type,
        duration: participant_campaign.duration,
        creditCardProcessingFee: totals?.creditCardProcessingFee || 0,
        campaign_cat: "Postcard",
        budget_type: campaign?.budget_type,
      };

      if (campaign.budget_type === "Pre-set" && campaign.local_selection) {
        // Auto enrolled and now only creatives are selecting
        confirmData.is_draft = false;
        confirmData.is_enrolled = false;
        confirmData.is_creative_selected = 0;
      }
      return confirmData;
    },
    [campaign, totals, loggedInUser]
  );

  const handleConfirm = (payment_status = 0) => {
    const confirmData = dataToSave(payment_status);
    joinCampaignNow(confirmData);
  };

  const renderContent = () => {
    if (loading) {
      return <NoDataFound message="Loading..." />;
    }

    if (!campaign) {
      return <NoDataFound />;
    }

    if (
      !state ||
      !state.campaignId ||
      !campaign?.participant_campaign ||
      (!campaign && !campaign?.id)
    ) {
      history.replace(`${process.env.PUBLIC_URL}/dashboard`);
    } else if (!canJoinCampaign(campaign)) {
      return (
        <NoDataFound
          message={
            <div className="d-flex-col-center">
              <div>
                You have already joined this campaign or it is
                in-active/completed/not customizable.
              </div>
              <Link
                to={`${process.env.PUBLIC_URL}/dashboard`}
                className="button primary btn btn-primary my-4"
              >
                Back to dashboard
              </Link>
            </div>
          }
        />
      );
    }

    const renderLocationsList = () => {
      if (!campaign?.participant_campaign?.locationData.length) return null;
      return (
        <div className="row">
          {campaign.participant_campaign.locationData.map((loc) => (
            <div className="col-12 mb-2" key={loc?.locationId}>
              <SelectedLocationCard location={loc} />
            </div>
          ))}
        </div>
      );
    };

    const renderTotals = () => (
      <ConfirmJoinCampaignTotalCard
        totals={totals}
        enable_payment_through_portal={campaign?.enable_payment_through_portal}
      />
    );

    return (
      <>
        <div className="join-campaign-confirmation-sub-header">
          <div className="container">
            <div className="row">
              <div className="col d-flex-center">
                <Typography component="h2" variant="body1">
                  {campaign?.campaign_name}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <ParticipantPageLayout className="confirm-page mx-lg-5 mx-3">
          <div className="join-campaign-confirmation">
            <Typography
              component="h1"
              variant="h4"
              className="page-title"
              color="primary"
            >
              Review and Confirm
            </Typography>

            {renderLocationsList()}
            {renderTotals()}

            <div className="row my-5 align-items-center">
              <div className="col-lg-7 note mb-4 mb-lg-0">
                <Typography variant="caption">
                  Once 'Confirm' is selected, you can't make changes, your
                  selections will be final for this campaign.
                </Typography>
              </div>
              <div className="col-lg-5 d-flex align-items-center justify-content-between justify-content-lg-end">
                <Button
                  color="primary"
                  variant="outlined"
                  component={Link}
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/participant/join-postcard-campaign/${state?.campaignId}`,
                    state: {
                      campaignId: state?.campaignId,
                      from: "participant/postcard-confirm",
                    },
                  }}
                >
                  Make Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    campaign?.enable_payment_through_portal
                      ? setOpenPayment(true)
                      : handleConfirm(0);
                  }}
                  className="ml-3 px-5"
                >
                  Confirm
                </Button>
              </div>
            </div>

            {openPayment ? (
              <ConfirmStripeEmbedCheckoutPopup
                makePayment={openPayment}
                title="Payment"
                data={{
                  email: loggedInUser.email,
                  amount: totals.amountYouPay,
                  fk_brand_id: selectedBrand.id,
                  system_generated_brand_id:
                    selectedBrand.system_generated_brand_id,
                  fk_brand_name: selectedBrand.brand_name,
                  ...dataToSave(1),
                }}
                onClose={() => setOpenPayment(false)}
                return_uri={`/join-campaign-return?join_campaign=true`}
                api={stripeMicroService.joinCampaignPayment}
              />
            ) : null}
          </div>
        </ParticipantPageLayout>
      </>
    );
  };

  return <PageLayout className="confirmation">{renderContent()}</PageLayout>;
};

const mapStateToProps = (state) => {
  return {
    isLoadingCampaigns: isLoadingCampaignsSelector(state),
    isSaved: isSavedSelector(state),
    isError: isErrorCampaignsSelector(state),
  };
};

export default connect(mapStateToProps, {
  joinCampaignNow: campaignActions.joinPostCardCampaign,
  setSaveDraftSuccess: campaignActions.setSaveDraftSuccess,
})(JoinCampaignConfirmationPage);
