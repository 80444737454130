import React, { useCallback, useMemo } from "react";
import { Drawer } from "@material-ui/core";
// import { Cancel } from "@material-ui/icons";
// import { toastr } from "react-redux-toastr";
import EmbeddedCheckoutForm from ".";
import CheckoutStepper from "../../../containers/subscriptionPackages/CheckoutStepper";
import RpPreventNavigation from "../../utils/RpPreventNavigation";
import PaymentInfoMsg from "../PaymentInfoMsg";
import { STRIPE_MAX_AMOUNT } from "../../../config/appConfig";
import NotSupportedAmountMsg from "../NotSupportedAmountMsg";
import { logger } from "../../../helpers/loggerHelper";

function ConfirmStripeEmbedCheckoutPopup({
  makePayment = false,
  data = {},
  onClose,
  return_uri = `/subscription-return`,
  api,
  // setActiveStep,
  locSubscription,
}) {
  // const handleClose = useCallback(() => {
  //   toastr.confirm(
  //     <React.Fragment>
  //       <Typography variant="h6" gutterBottom className="text-center my-2">
  //         Are you sure you want to cancel?
  //       </Typography>
  //     </React.Fragment>,
  //     {
  //       onOk: () => {
  //         if (locSubscription) {
  //           if (setActiveStep && typeof setActiveStep === "function") {
  //             setActiveStep(1);
  //           }
  //         }
  //         if (onClose && typeof onClose === "function") {
  //           onClose();
  //         }
  //       },
  //       okText: "Yes",
  //       cancelText: "No",
  //     }
  //   );
  // }, [onClose]);

  const handleError = useCallback(
    (err) => {
      logger.error("EmbeddedCheckoutForm error", err);
      if (onClose && typeof onClose === "function") {
        onClose();
      }
    },
    [onClose]
  );

  const isMoreThanStripeMaxAmount = useMemo(() => {
    const { amount } = data || {};

    return amount && !isNaN(amount) && Number(amount) > STRIPE_MAX_AMOUNT
      ? amount
      : false;
  }, [data]);

  if (isMoreThanStripeMaxAmount)
    return (
      <NotSupportedAmountMsg
        makePayment={makePayment}
        onOkNotSupportedAmount={onClose}
      />
    );

  return (
    <>
      <RpPreventNavigation prevent={makePayment ? true : false} />
      <Drawer
        open={makePayment}
        anchor="bottom"
        className="payment-drawer"
        transitionDuration={0}
        disableEnforceFocus
      >
        {/* <div className="text-right cursor-p">
          <Cancel className="mr-2" onClick={handleClose} color="action" />
        </div> */}
        <div>
          {locSubscription && (
            <div>
              <CheckoutStepper activeStep={2} />
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center mt-5">
            <EmbeddedCheckoutForm
              data={{
                ...data,
                return_url: `${window.location.origin}${return_uri}`,
              }}
              onError={handleError}
              api={api}
            />
          </div>
        </div>
      </Drawer>
      <PaymentInfoMsg makePayment={makePayment} />
    </>
  );
}

export default ConfirmStripeEmbedCheckoutPopup;
