import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  ListItemText,
  Popper,
  createStyles,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) =>
  createStyles({
    popper: {
      zIndex: "9999 !important",
      width: "100%",
    },
    root: {
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#fff",
        color: theme.palette.text.primary,
        borderRadius: "4px",
        minHeight: "40px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.divider}`,
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.text.primary,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.text.primary}`,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.palette.primary.main}`,
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.action.disabledBackground,
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `none`,
          },
          "& .MuiSvgIcon-root": {
            color: theme.palette.action.disabled,
          },
          "& .MuiAutocomplete-tag": {
            backgroundColor: `${theme.palette.action.disabled}22`,
            color: theme.palette.action.disabled,
          },
          "& input::placeholder": {
            color: theme.palette.action.disabled,
            opacity: 1,
          },
        },
        "& input::placeholder": {
          color: theme.palette.text.secondary,
          opacity: 1,
        },
      },
      "& .MuiAutocomplete-endAdornment": {
        right: "8px",
        top: "50%",
        transform: "translateY(-50%)",
        position: "absolute",
        zIndex: 2,
      },
      "& .MuiAutocomplete-inputRoot": {
        paddingRight: "48px !important",
        flexWrap: "nowrap",
        height: "100%",
        overflow: "hidden",
      },
      "& .MuiAutocomplete-input": {
        padding: "0 !important",
        width: "0 !important",
        minWidth: "30px",
      },
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary,
        height: "24px",
        margin: "2px",
        display: "none",
        alignItems: "center",
        maxWidth: "calc(100% - 35px)",
        "&:first-of-type": {
          display: "inline-flex",
        },
        "& .MuiChip-label": {
          fontSize: "0.7rem",
          padding: "0 6px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontWeight: 600,
        },
        "& .MuiChip-deleteIcon": {
          color: theme.palette.text.secondary,
          fontSize: "16px",
          margin: "0 2px",
          "&:hover": {
            color: theme.palette.text.primary,
          },
        },
      },
    },
    darkTheme: {
      "& .MuiOutlinedInput-root": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.contrastText,
        },
        "& input::placeholder": {
          color: theme.palette.primary.contrastText,
          opacity: 0.7,
        },
      },
      "& .MuiAutocomplete-tag": {
        backgroundColor: `${theme.palette.primary.contrastText}22`,
        color: theme.palette.primary.contrastText,
        "& .MuiChip-deleteIcon": {
          color: theme.palette.primary.contrastText,
          "&:hover": {
            opacity: 0.8,
          },
        },
      },
    },
  })
);

const RpMuiSelect = ({
  options = [],
  value = [],
  onChange,
  multiple = true,
  label = "",
  placeholder = "Select Options",
  required = false,
  helperText = "",
  styles = {},
  labelKey = "label",
  valueKey = "value",
  limitTags = 1,
  size = "small",
  darkTheme = false,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  // Calculate if all options are selected
  const isAllSelected =
    options.length > 0 && selectedOptions.length === options.length;

  // Determine if there's an error
  const hasError = touched && required && selectedOptions.length === 0;

  // Handle selection changes
  const handleChange = (event, newValue) => {
    setTouched(true);
    if (!multiple) {
      const newOption = newValue?.[valueKey];
      setSelectedOptions(newOption ? [newOption] : []);
      onChange?.(newOption ? [newOption] : []);
      return;
    }

    // Handle "Select All" click
    if (newValue.some((item) => item[valueKey] === "select-all")) {
      const newSelectedOptions = isAllSelected
        ? []
        : options.map((opt) => opt[valueKey]);
      setSelectedOptions(newSelectedOptions);
      onChange?.(newSelectedOptions);
      return;
    }

    // Handle normal selection
    const newSelectedOptions = newValue.map((item) => item[valueKey]);
    setSelectedOptions(newSelectedOptions);
    onChange?.(newSelectedOptions);
  };

  // Get the display value for the Autocomplete
  const getDisplayValue = () => {
    if (!multiple) {
      const selectedOption = options.find(
        (opt) => opt[valueKey] === selectedOptions[0]
      );
      return selectedOption ? [selectedOption] : [];
    }

    return options.filter((option) =>
      selectedOptions.includes(option[valueKey])
    );
  };

  // Prepare options list with Select All
  const getOptionsList = () => {
    if (!multiple) return options;

    return options.length > 0
      ? [{ [labelKey]: "Select All", [valueKey]: "select-all" }, ...options]
      : [];
  };

  // Add this function to handle placeholder visibility
  const getPlaceholder = () => {
    if (multiple && selectedOptions.length > 0) {
      return ""; // Hide placeholder when options are selected in multiple mode
    }
    if (!multiple && selectedOptions[0]) {
      return ""; // Hide placeholder when an option is selected in single mode
    }
    return placeholder;
  };

  return (
    <div style={styles.root}>
      <Autocomplete
        limitTags={limitTags}
        multiple={multiple}
        size={size}
        disableCloseOnSelect
        className={`${classes.root} ${darkTheme ? classes.darkTheme : ""}`}
        {...rest}
        options={getOptionsList()}
        getOptionLabel={(option) => option[labelKey] || ""}
        renderOption={(option) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={
                option[valueKey] === "select-all"
                  ? isAllSelected
                  : selectedOptions.includes(option[valueKey])
              }
              size="small"
              color="primary"
              style={styles.checkbox}
            />
            <ListItemText
              primary={option[labelKey]}
              primaryTypographyProps={{
                style: {
                  fontSize: ".8rem",
                  ...styles?.option,
                },
              }}
            />
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={getPlaceholder()}
            style={{
              ...params.style,
              ...styles.input,
            }}
            InputLabelProps={{
              style: styles.label,
            }}
            required={required}
            error={hasError}
            onBlur={() => setTouched(true)}
          />
        )}
        value={getDisplayValue()}
        onChange={handleChange}
        PopperComponent={(props) => (
          <Popper
            {...props}
            className={classes.popper}
            placement="bottom-start"
          />
        )}
      />
      {hasError && helperText && (
        <FormHelperText error>{helperText}</FormHelperText>
      )}
    </div>
  );
};

export default RpMuiSelect;
