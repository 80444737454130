import React from "react";
import { Icon, Typography } from "@material-ui/core";

import RpMuiToolTip from "../../../components/muiCustom/popover/RpMuiToolTip";

const headerTooltipContent = {
  Location: "These locations are currently enrolled in this campaign.",
  Status:
    "This shows the status of advertising for each location. Locations with advertising past their end date will not appear on this list.",
  Toggle: "Set Live or Pause all locations.",
};

function PauseHeaderTooltip({ label = "" }) {
  return (
    <RpMuiToolTip
      content={
        <Typography component="p" variant="caption" className="fs-10">
          {headerTooltipContent[label]}
        </Typography>
      }
    >
      <Icon
        component="img"
        src="/img/icons/info_green.svg"
        fontSize="small"
        color="primary"
        style={{ width: 15, height: 15 }}
        className="ml-1 cursor-p d-none d-lg-block"
      />
    </RpMuiToolTip>
  );
}

export default PauseHeaderTooltip;
