import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import ArchivedCampaigns from "../../../../components/joinCampaigns/ArchivedCampaigns";
import NoDataFound from "../../../../components/NoDataFound";
import NotAvailable from "../../../../components/NotAvailable";

import { LoaderContext } from "../../../../helpers/contexts/contexts";

import { campaignService } from "../../../../services/campaignService";
import { logger } from "../../../../helpers/loggerHelper";

const ArchivedCampaignList = ({
  viewType,
  archivedCampaigns,
  pageCount,
  isLoading,
}) => {
  const loader = useContext(LoaderContext);

  const history = useHistory();

  const removeCampaignFromArchive = async (campaign, id) => {
    try {
      loader(true);
      const res = await campaignService.removeCampaignFromArchive(id);
      if (res.status === 200) {
        history.replace({
          pathname:
            campaign.status === 3 || campaign.status === 0
              ? `${process.env.PUBLIC_URL}/dashboard`
              : `${process.env.PUBLIC_URL}/participant/join-campaign/${campaign?.id}`,
          state: {
            campaignId: campaign?.id,
            from: "/dashboard",
          },
        });
      }
    } catch (err) {
      logger.error("Error in removeCampaignFromArchive", { err });
    } finally {
      loader(false);
    }
  };

  if (isLoading) {
    return <NoDataFound className="my-5" message="Loading..." />;
  }

  return (
    <div className="archived-campaigns">
      {archivedCampaigns.length ? (
        <ArchivedCampaigns
          campaigns={archivedCampaigns}
          viewType={viewType}
          removeCampaignFromArchive={removeCampaignFromArchive}
          pageCount={pageCount}
        />
      ) : (
        <div>
          <NotAvailable className="my-5" />
        </div>
      )}
    </div>
  );
};

export default ArchivedCampaignList;
