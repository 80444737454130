import React from "react";
import { Typography, Icon, IconButton } from "@material-ui/core";

import { CurrencyFormateText } from "../../../components/CurrencyFormat";

export default function BrandMatchDialog({ data, onClose }) {
  return (
    <>
      <div className="d-flex justify-content-end">
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="inherit"
          className="p-0"
        >
          <Icon fontSize="small">highlight_off</Icon>
        </IconButton>
      </div>
      <div className="brand-match-card">
        <div className="card-body">
          <div className="row justify-content-center">
            <Typography
              className="sub-heading"
              gutterBottom
              component="h2"
              variant="h5"
            >
              You received ${data?.packageInfo?.brand_match_amount} of brand
              match!
            </Typography>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 justify-content-center">
              <Typography
                component="p"
                variant="caption"
                gutterBottom
                className="mb-3 text-center"
              >
                Your brand team pays the brand match for your local marketing,
                therefore you won't be charged for the brand match amount. You
                will see the brand match amount added to your budget in your
                Hyperlocology dashboard.
              </Typography>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <Typography
                component="h5"
                variant="h5"
                gutterBottom
                className="mt-2"
              >
                <CurrencyFormateText
                  value={data?.packageInfo?.brand_match_amount}
                  suffix=" USD"
                />
              </Typography>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <Typography component="h6" variant="h6" gutterBottom>
                {data?.locationInfo?.location_name} (
                {data?.locationInfo?.participantName})
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
