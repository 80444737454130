import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tabs, Tab, Typography } from "@material-ui/core";

import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import ParticipantPageLayout from "../../components/layouts/page/ParticipantPageLayout";
import AllSectionTabs from "./AllSectionTabs";
import { brandService } from "../../services";
import { STATIC_IMAGES } from "../../helpers/staticImages";
import { reportUserActivity } from "../../helpers/userActivityHelper";
import NoDataFound from "../../components/NoDataFound";
import useFetcher from "../../helpers/hooks/useFetcher";
import PageLayout from "../../components/layouts/page/PageLayout";
import { settingSelector } from "../../selectors/settingSelectors";

const TAB_ORDER = [
  "whats_new",
  "faqs",
  "helpful_articles",
  "educational_videos",
];

function tabSectionProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function ResourceCenter() {
  const history = useHistory();
  const { selectedBrand, loggedInUserRole, loggedInUser } =
    useContext(GlobalContext);
  const settings = useSelector(settingSelector);
  const loader = useContext(LoaderContext);
  const [value, setValue] = useState(0);

  let tabs;

  const [filters, setFilters] = useState({
    pageIndex: 1,
    pageSize: 8,
    articlesPageIndex: 1,
    articlesPageSize: 8,
    newDataPageIndex: 1,
    newDataPageSize: 8,
  });

  const reqData = useMemo(() => {
    return {
      brandId: selectedBrand?.id,
      pageSize: filters?.pageSize,
      pageIndex: filters?.pageIndex,
      articlesPageIndex: filters?.articlesPageIndex,
      articlesPageSize: filters?.articlesPageSize,
      newDataPageIndex: filters?.newDataPageIndex,
      newDataPageSize: filters?.newDataPageSize,
    };
  }, [selectedBrand?.id, loggedInUser?.id, filters]);

  // handle functions
  const handlePageChange = (event, value) => {
    setFilters({ ...filters, pageIndex: value });
  };

  const handleArticlePageChange = (event, value) => {
    setFilters({ ...filters, articlesPageIndex: value });
  };
  const handleWhatsNewPage = (event, value) => {
    setFilters({ ...filters, newDataPageIndex: value });
  };

  if (!selectedBrand?.id) {
    history.replace(`${process.env.PUBLIC_URL}/dashboard`);
  }

  const hasBrandResources = useSelector(
    (state) => state.settings?.brandSettings?.has_brand_resource
  );
  useEffect(() => {
    if (!hasBrandResources) {
      history.replace(`${process.env.PUBLIC_URL}/dashboard`);
    }
  }, [hasBrandResources, history]);

  useEffect(() => {
    reportUserActivity("resource_center");
  }, [loggedInUserRole]);

  function modifyTabName(tab) {
    const tabMapping = {
      faqs: "FAQ",
      educational_videos: "Educational Videos",
      helpful_articles: "Helpful Content",
      whats_new: "What's New",
    };

    return tabMapping[tab] || tab;
  }

  const { data, isLoading } = useFetcher(
    reqData,
    brandService.getResourceCenterData,
    { revalidateOnFocus: false }
  );
  const resourceData = data;

  useEffect(() => {
    if (settings?.brandSettings?.has_brand_resource === false) {
      history.replace("/dashboard");
    }
  }, [settings?.brandSettings?.has_brand_resource, selectedBrand]);

  const checkTabs = resourceData
    ? TAB_ORDER.filter((k) => {
        if (k === "whats_new") {
          return resourceData[k][0].newAddedData.length > 0;
        } else if (k === "educational_videos") {
          return resourceData[k][0].videos.length > 0;
        } else if (k === "helpful_articles") {
          return resourceData[k][0].articles.length > 0;
        } else {
          return resourceData[k].length > 0;
        }
      }).map(modifyTabName)
    : [];
  tabs = checkTabs;

  const faqData = resourceData?.faqs || [];
  const educationalVideos = resourceData?.educational_videos[0]?.videos || [];
  const articles = resourceData?.helpful_articles[0]?.articles || [];
  const whatsNew = resourceData?.whats_new[0]?.newAddedData || [];
  const totalVideos =
    resourceData?.educational_videos[0]?.totalVideosCount || 0;
  const totalArticles =
    resourceData?.helpful_articles[0]?.totalArticlesCount || 0;
  const totalNewData = resourceData?.whats_new[0]?.totalCount || 0;

  const fetchResourceData = useCallback((data) => {
    setFilters({
      pageIndex: 1,
      pageSize: 8,
      articlesPageIndex: 1,
      articlesPageSize: 8,
      newDataPageIndex: 1,
      newDataPageSize: 8,
      ...data,
    });
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      fetchResourceData({
        pageIndex: 1,
        pageSize: 8,
        articlesPageIndex: 1,
        articlesPageSize: 8,
        newDataPageIndex: 1,
        newDataPageSize: 8,
      });
    }
  }, [selectedBrand, fetchResourceData]);

  useEffect(() => {
    loader(isLoading);
  }, [isLoading, loader]);

  const handleActivity = (currentTab) => {
    if (currentTab === "FAQ") {
      reportUserActivity("Brand_faq");
    } else if (currentTab === "Educational Videos") {
      reportUserActivity("Educational_video");
    } else if (currentTab === "Helpful Content") {
      reportUserActivity("Helpful_articles");
    } else if (currentTab === "What's New") {
      reportUserActivity("Whats_new");
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const currentTab = event.target?.textContent;
    handleActivity(currentTab);
  };

  const tabImages = {
    FAQ: STATIC_IMAGES.rcFaq,
    "Educational Videos": STATIC_IMAGES.rcEducationVideo,
    "Helpful Content": STATIC_IMAGES.rcArticle,
    "What's New": STATIC_IMAGES.rcWhatsNew,
  };

  function getImageSrcForTab(tab) {
    // const tabIndex = tabs.indexOf(tab);
    if (tab === "FAQ") {
      return tabImages["FAQ"];
    } else if (tab === "Educational Videos") {
      return tabImages["Educational Videos"];
    } else if (tab === "Helpful Content") {
      return tabImages["Helpful Content"];
    } else if (tab === "What's New") {
      return tabImages["What's New"];
    }
  }

  useEffect(() => {
    if (tabs.length && !tabs[value]) {
      const firstTabWithData = tabs[0]; // First tab with data
      setValue(tabs.indexOf(firstTabWithData)); // Set to first tab with data
    }
  }, [tabs, value]);

  return (
    <>
      <PageLayout className="resources">
        <div className=" resource-center">
          <div className="row justify-content-center mb-5 mx-0">
            <div className="col-md-12 col-lg-12">
              <Typography
                component="h1"
                variant="h5"
                className="main-heading text-center my-4"
                gutterBottom
              >
                Education Center
              </Typography>
              <Typography
                component="div"
                className="text-center subheading"
                gutterBottom
              >
                {/* Helpful videos and content about your local advertising and the
                Hyperlocology platform. */}
                Learn about everything from customer success stories, product
                info, to viewpoints from the core team
              </Typography>
            </div>
          </div>
          <div className="container">
            <div className="container tabs-container">
              {!isLoading && tabs && tabs.length > 0 ? (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  // scrollButtons="auto"
                  // scrollButtons="on"
                  scrollButtons={tabs.length > 1 ? "on" : "off"}
                  aria-label="scrollable auto tabs example"
                  className={tabs?.length === 1 ? "all-section" : ""}
                >
                  {tabs?.map((tab, i) => {
                    const imageSrc = getImageSrcForTab(tab);
                    return (
                      <Tab
                        label={
                          <div>
                            <img
                              src={imageSrc}
                              alt={tab}
                              className={`tab-image ${
                                value === i ? "selected-image" : ""
                              }`}
                            />
                            {tab}
                          </div>
                        }
                        key={i}
                        {...tabSectionProps(i)}
                      />
                    );
                  })}
                </Tabs>
              ) : null}
            </div>
          </div>
        </div>
        <ParticipantPageLayout className="resource-section">
          <div className="sections">
            <div className="container">
              {tabs && tabs.length ? (
                <AllSectionTabs
                  tabs={tabs}
                  value={value}
                  faqData={faqData}
                  educationalVideos={educationalVideos}
                  totalVideos={totalVideos}
                  handlePageChange={handlePageChange}
                  pageIndex={filters?.pageIndex}
                  pageSize={filters?.pageSize}
                  articles={articles}
                  totalArticles={totalArticles}
                  articlesPageIndex={filters?.articlesPageIndex}
                  articlesPageSize={filters?.articlesPageSize}
                  handleArticlePageChange={handleArticlePageChange}
                  whatsNew={whatsNew}
                  newDataPageIndex={filters?.newDataPageIndex}
                  newDataPageSize={filters?.newDataPageSize}
                  totalNewData={totalNewData}
                  handleWhatsNewPage={handleWhatsNewPage}
                />
              ) : (
                <NoDataFound height={"50vh"} message="Loading..." />
              )}
            </div>
          </div>
        </ParticipantPageLayout>
      </PageLayout>
    </>
  );
}
