import React, { useCallback, useContext, useState } from "react";
import { Icon } from "@material-ui/core";

import { subscriptionService } from "../../../services/subscriptionService";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import { downloadFileAnchorTag } from "../../../helpers/utils";
import { FILE_TYPE_MIME_TYPE } from "../../../config/constants";
import MuiDarkButton from "../../../components/muiCustom/buttons/MuiDarkButton";

function DownloadOptedOutDetail({ filters }) {
  const { loggedInUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState();

  const getUserLocations = useCallback(
    async (data = {}) => {
      setLoading(true);
      try {
        const reqData = {
          ...filters,
          ...data,
          download: 1,
        };
        const res = await subscriptionService.getOptedOutData(reqData);

        if (res.status === 200) {
          const blob = new Blob([res.data], {
            type: FILE_TYPE_MIME_TYPE.xlsx,
          });
          const objectUrl = URL.createObjectURL(blob);
          //window.open(objectUrl);

          const fileName = `${loggedInUser.first_name}_${
            loggedInUser.last_name
          }-user-${
            loggedInUser.id
          }-opt-out-detail-${new Date().toLocaleDateString()}.xlsx`;
          downloadFileAnchorTag(objectUrl, fileName);
        }
      } catch (err) {
        if (err?.__CANCEL__) {
          setTimeout(() => {
            setLoading(true);
          }, 100);
        }
      } finally {
        setLoading(false);
      }
    },
    [filters, loggedInUser]
  );

  return (
    <div>
      <MuiDarkButton
        variant="contained"
        onClick={() => getUserLocations()}
        startIcon={<Icon>download</Icon>}
        fullWidth
        disableElevation
      >
        {loading ? "Downloading.." : "Download"}
      </MuiDarkButton>
    </div>
  );
}

export default DownloadOptedOutDetail;
