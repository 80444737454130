import React from "react";

const NoDataFound = ({ message = "No data found", height }) => {
  return (
    <div
      className="d-flex-col-center mh-100 w-100"
      style={{ height: height ? height : "50vh" }}
    >
      <h6 className="text-center text-secondary">{message}</h6>
    </div>
  );
};

export default NoDataFound;
