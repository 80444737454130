import React, { useState } from "react";
import { TextField, FormHelperText } from "@material-ui/core";

function TargetIdeal({ mailingQnt = 0, onChange }) {
  const [qnt, setQnt] = useState(mailingQnt);
  const [message, setMessage] = useState("");

  const handleOnChange = (e) => {
    const v = e.target.value;
    setQnt(v);
    if (v && v < 500) {
      setMessage("Enter a quantity higher than 500");
    } else {
      setMessage("");
    }
    if (onChange && typeof onChange === "function") {
      onChange(Number(v));
    }
  };

  const handleOnBlur = (e) => {
    const v = Number(e.target.value);
    setMessage("");
    if (v && v < 500) {
      setMessage("Enter a quantity higher than 500");
      if (onChange && typeof onChange === "function") {
        onChange(0);
      }
    }
  };

  return (
    <>
      <div className="col-6"></div>
      <div className="div-content col-6 pl-xl-0">
        <label className="label">Quantity</label>
        <TextField
          name="quantity"
          variant="outlined"
          size="small"
          autoComplete="off"
          placeholder="0.00"
          value={qnt}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          error={message && qnt !== "" ? true : false}
          // helperText={message}
        />
        <FormHelperText>{message}</FormHelperText>
      </div>
    </>
  );
}

export default TargetIdeal;
