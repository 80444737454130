import React, { useContext } from "react";
import { connect } from "react-redux";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { brandActions } from "../actions";
import { GlobalContext } from "../helpers/contexts/contexts";
import ImageRP from "../components/ImageRP";
import { userBrandsSelector } from "../selectors/brandSelector";

const hideBrandToggleOnRoute = [
  "/subscription-packages",
  "/dashboard/campaign-report/",
  "/dashboard/location-report/",
  "/participant/join-campaign/",
];

const HeaderBrandLogoDropDown = ({ brands, setGlobalBrand }) => {
  const { pathname } = useLocation();

  const { selectedBrand, loggedInUserRole, loggedInUser } =
    useContext(GlobalContext);
  /**
   * @method handleOnClickBrand
   * @description handle on click brand
   * @param {*} brand
   */
  const handleOnClickBrand = (brand) => {
    setGlobalBrand(brand);
  };

  const renderDropdownToggle = () => {
    if (!brands || brands.length === 0 || brands.length === 1) {
      return null;
    } else if (hideBrandToggleOnRoute.some((r) => pathname.search(r) > -1)) {
      return null;
    } else if (loggedInUserRole === "BO" || loggedInUserRole === "BU") {
      return null;
    }

    return (
      <>
        <Dropdown.Toggle
          split
          variant="default"
          id="dropdown-basic"
          title="Switch Brand"
        ></Dropdown.Toggle>
        <Dropdown.Menu className="py-0">
          {brands.map((brand, i) => (
            <React.Fragment key={brand.id}>
              {i !== 0 && <Dropdown.Divider className="my-0" />}
              <Dropdown.Item
                onClick={() => handleOnClickBrand(brand)}
                active={brand.id === selectedBrand?.id}
                title={brand?.brand_name}
                className="py-2"
              >
                {brand.brand_name}
              </Dropdown.Item>
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </>
    );
  };

  return (
    <Dropdown as={ButtonGroup} className="header-brand-logo-drop-down">
      <Link
        to={`${process.env.PUBLIC_URL}/dashboard`}
        className="navbar-brand mr-0"
      >
        <Button variant="default" className="p-0 brand-image">
          <ImageRP
            className=""
            // style={{ height: "63px" }}
            src={
              loggedInUserRole === "BO" || loggedInUserRole === "BU"
                ? loggedInUser?.logo_name
                : selectedBrand?.brand_logo
            }
            type="BRAND"
            title={selectedBrand?.brand_name || ""}
          />
        </Button>
      </Link>

      {renderDropdownToggle()}
    </Dropdown>
  );
};

const mapStateToProps = (state) => ({
  brands: userBrandsSelector(state),
});

export default connect(mapStateToProps, {
  setGlobalBrand: brandActions.setGlobalBrand,
})(HeaderBrandLogoDropDown);
