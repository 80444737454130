import React from "react";
import RpImageTextInline from "../../components/RpImageTextInline";

const LocationDetails = ({ report }) => {
  return (
    <div className="location-detail mb-3">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 col-sm-6 mb-3">
              <RpImageTextInline
                image="/img/icons/location.png"
                text={
                  <>
                    <div className="title">Location Name</div>
                    <div className="value us-all text-break">
                      {report.location_name}
                    </div>
                  </>
                }
                alignCenter={false}
              />
            </div>
            <div className="col-lg-4 col-sm-6 mb-3">
              <RpImageTextInline
                image="/img/icons/map.png"
                text={
                  <>
                    <div className="title">Location ID</div>
                    <div className="value us-all">
                      {report?.system_generated_location_id}
                    </div>
                  </>
                }
                alignCenter={false}
              />
            </div>
            <div className="col-lg-4 col-sm-6 mb-3">
              <RpImageTextInline
                image="/img/icons/mail.png"
                text={
                  <>
                    <div className="title">Email</div>
                    <div className="value us-all">
                      {report?.participant.user.email}
                    </div>
                  </>
                }
                alignCenter={false}
              />
            </div>
            <div className="col-lg-4 col-sm-6 mb-3">
              <RpImageTextInline
                image="/img/icons/user.png"
                text={
                  <>
                    <div className="title">Participant Name</div>
                    <div className="value text-break">
                      {`${report.participant.user.first_name} ${report.participant.user.last_name}`}
                    </div>
                  </>
                }
                alignCenter={false}
              />
            </div>
            <div className="col-lg-4 col-sm-6 mb-3">
              <RpImageTextInline
                image="/img/icons/user.png"
                text={
                  <>
                    <div className="title">Participant ID</div>
                    <div className="value us-all">
                      {report.participant.system_generated_participant_id}
                    </div>
                  </>
                }
                alignCenter={false}
              />
            </div>
            <div className="col-lg-4 col-sm-6 mb-3">
              <RpImageTextInline
                image="/img/icons/home.png"
                text={
                  <>
                    <div className="title">Address</div>
                    <div className="value">
                      {`${report.street_address} ${report.city}, ${report.state} ${report.zip_code}`}
                    </div>
                  </>
                }
                alignCenter={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationDetails;
