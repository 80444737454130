import React from "react";
import { Icon } from "@material-ui/core";
import { getFormatDate } from "../../../helpers/utils";
import { CurrencyFormateText } from "../../../components/CurrencyFormat";
import RpImageTextInline from "../../../components/RpImageTextInline";
import { MAILING_TYPES } from "../../../config/constants";
import ImageRP from "../../../components/ImageRP";

export default function SelectedLocationCard({ location }) {
  const AddressListFile = () => {
    return (
      <>
        <div className="card mt-2">
          <div className="card-body">
            <div className="row d-flex address-file">
              <div className="col-lg-4 col-md-4 col-sm-6 ">
                <div className="title-val">Mailing Addresses</div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-6">
                {location.mailingType === MAILING_TYPES.T ? (
                  <a href={location?.postcard_address} className="file-name">
                    Postcard_address_upload.xlsx
                  </a>
                ) : (
                  <div className="no-file">N/A</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const CreativeImage = () => {
    return (
      <div className="card mt-2 creative-image-card">
        <div className="card-body">
          <div className="row">
            {location.creativeData?.map((creative) => (
              <>
                {creative.attachment?.map((attachment, index) => (
                  <div
                    className={`col-6 ${
                      creative.attachment?.length === 1
                        ? "col-12 text-center"
                        : "col-6"
                    } ${
                      index === 0 && creative.attachment?.length !== 1
                        ? "d-flex justify-content-end"
                        : ""
                    }`}
                    key={index}
                  >
                    <ImageRP
                      src={attachment}
                      type="CREATIVE"
                      alt=""
                      className="img-fluid rounded"
                      style={{ maxHeight: 200, objectFit: "contain" }}
                    />
                  </div>
                ))}
              </>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const mailingCost = location.mailingCost || 0;
  const bulkDropCost = location.bulkDropCost || 0;
  const totalCost = mailingCost + bulkDropCost;
  return (
    <>
      <div className="card mt-4">
        <div className="card-body">
          <div className="row selected-campaign-detail">
            <div className="col-lg-4 col-md-4 col-sm-6 mb-2">
              <RpImageTextInline
                alignCenter={false}
                image="/img/icons/location.png"
                text={
                  <>
                    <div className="title">Location</div>
                    <div className="title-val">{location?.locationName}</div>
                  </>
                }
              />
            </div>
            <div className="col-lg-8 col-md-8 col-sm-6 mb-2">
              <RpImageTextInline
                alignCenter={false}
                image="/img/icons/calendar.png"
                text={
                  <>
                    <div className="title">Scheduled Date</div>
                    <div className="title-val">
                      {getFormatDate(location?.schedule_date, "MMM Do, YYYY")}
                    </div>
                  </>
                }
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
              <div className="d-flex">
                <label className="title">Mailing : </label>
                <div className="ml-2">
                  <RpImageTextInline
                    image={
                      location.isMailing && location.mailingCost
                        ? "/img/icons/checked.png"
                        : "/img/icons/cross.png"
                    }
                  />
                </div>
              </div>

              {location.isMailing && location.mailingCost ? (
                <>
                  <div className="d-flex">
                    <label className="title">Mailing Quantity : </label>
                    <div className="ml-2 title-val">{location?.mailingQnt}</div>
                  </div>
                  <div className="d-flex">
                    <label className="title">Mailing Cost : </label>
                    <div className="ml-2 title-val color-change">
                      <CurrencyFormateText
                        value={location?.mailingCost}
                        suffix=" USD"
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
              <div className="d-flex">
                <label className="title">Bulk Drop : </label>
                <div className="ml-2">
                  <RpImageTextInline
                    image={
                      location?.isBulkDrop
                        ? "/img/icons/checked.png"
                        : "/img/icons/cross.png"
                    }
                  />
                </div>
              </div>

              {location.isBulkDrop ? (
                <>
                  <div className="d-flex">
                    <label className="title">Bulk Quantity : </label>
                    <div className="ml-2 title-val">
                      {location?.bulkDropQnt}
                    </div>
                  </div>
                  <div className="d-flex">
                    <label className="title">Bulk Cost : </label>
                    <div className="ml-2 title-val color-change">
                      <CurrencyFormateText
                        value={location?.bulkDropCost}
                        suffix=" USD"
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 mb-2 text-lg-right text-md-right total-amount-align">
              <div className="title-val">
                <Icon
                  component="img"
                  src="/img/icons/budget.png"
                  fontSize="small"
                  className="mr-1"
                />
                Total Cost
              </div>
              <div className="title-val">
                <CurrencyFormateText value={totalCost} suffix=" USD" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {AddressListFile()}
      {CreativeImage()}
      <hr />
    </>
  );
}
