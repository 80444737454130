import queryString from "query-string";
import AxiosInstance from "../config/AxiosInstance";
import { API_BASE_URL_ADMIN } from "../config/appConfig";

export const campaignService = {
  getCampaign,
  joinCampaign,
  getCampaignReport,
  sendCreativeFeedback,
  addCampaignToArchive,
  removeCampaignFromArchive,
  participantArchiveCampaigns,
  getSingleCampaign,
  joinPostCardCampaign,
  getPostcardCampaign,
  postcardAddressUpload,
  gePostcardCampaignData,
  advanceSettingAttachmentUpload,
  getCampaignReportListing,
  getDigitalCampaigns,
  postcardReportListing,
  launchPostcardCampaigns,
  getCampaignReportCsv,
  getUnlinkCampaignReportCsv,
  getAllCampaignBoostLocations,
  getCurrentBudgetCalculation,
  saveCampaignBoostBudget,
  getJoinedLocations,
  requestPauseCampaign,
};

function getSingleCampaign(data) {
  const getData = {
    id: "",
    status: 1,
    row: 50,
    page: 1,
    is_deleted: 0,
    ...data,
  };

  const query = queryString.stringify(getData);

  return AxiosInstance.get(`${API_BASE_URL_ADMIN}/campaign?${query}`);
}

function getPostcardCampaign(data) {
  const getData = {
    id: "",
    status: 1,
    row: 50,
    page: 1,
    is_deleted: 0,
    ...data,
  };

  const query = queryString.stringify(getData);

  return AxiosInstance.get(`${API_BASE_URL_ADMIN}/postcardCampaign?${query}`);
}

// http://localhost:3030/api/admin/postcardCampaignData?fk_participant_id=37&id=6

function gePostcardCampaignData(data) {
  const query = queryString.stringify(data);
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/postcardCampaignData?${query}`
  );
}

function getCampaign(data, isSWR = false) {
  const query = queryString.stringify(data);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/campaignData?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(`${API_BASE_URL_ADMIN}/campaignData?${query}`);
}

function joinCampaign(data) {
  return AxiosInstance.post(`${API_BASE_URL_ADMIN}/campaignParticipant`, data);
}

function joinPostCardCampaign(data) {
  return AxiosInstance.post(
    `${API_BASE_URL_ADMIN}/postcardCampaignParticipant`,
    data
  );
}

function getCampaignReport(data) {
  const getData = {
    campaign_id: "",
    location_id: "",
    start_date: "",
    end_date: "",
    group_id: "",
    channel_id: "",
    regions_id: "",
    downloadReport: false,
    ...data,
  };

  const query = queryString.stringify(getData);

  return AxiosInstance.get(`${API_BASE_URL_ADMIN}/campaignReport?${query}`);
}

function getCampaignReportCsv(data) {
  const getData = {
    campaign_id: "",
    location_id: "",
    group_id: "",
    channel_id: "",
    downloadReport: false,
    ...data,
  };

  const query = queryString.stringify(getData);

  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/downloadCampaignReportCsv?${query}`
  );
}

function getUnlinkCampaignReportCsv(data) {
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/downloadCampaignReportCsvUnlink?path=${data}`
  );
}

function sendCreativeFeedback(data) {
  return AxiosInstance.post(`${API_BASE_URL_ADMIN}/feedback`, data);
}

function addCampaignToArchive(data) {
  return AxiosInstance.post(`${API_BASE_URL_ADMIN}/addCampaignToArchive`, data);
}

function removeCampaignFromArchive(id) {
  return AxiosInstance.delete(
    `${API_BASE_URL_ADMIN}/removeCampaignFromArchive/${id}`
  );
}

function participantArchiveCampaigns(data) {
  const getData = {
    // fk_campaign_id: "",
    fk_participant_id: "",
    ...data,
  };

  const query = queryString.stringify(getData);
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/participantArchiveCampaigns?${query}`
  );
}

function postcardAddressUpload(data) {
  return AxiosInstance.post(
    `${API_BASE_URL_ADMIN}/postcardAddressUpload`,
    data
  );
}

function advanceSettingAttachmentUpload(data) {
  return AxiosInstance.post(
    `${API_BASE_URL_ADMIN}/advanceSetting/attachmentUpload`,
    data
  );
}

function getCampaignReportListing(data, isSWR = false) {
  // const config = {
  //   method: "get",
  //   url: `${API_BASE_URL_ADMIN}/campaignReportListing`,
  //   params: getData,
  // };
  // if (isSWR) {
  //   return takeLatestRequest(config, "campaignReportListing").then(
  //     (r) => r.data
  //   );
  // }
  // return takeLatestRequest(config, "campaignReportListing");

  const query = queryString.stringify(data);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/campaignReportListing?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/campaignReportListing?${query}`
  );
}

function getDigitalCampaigns(data, isSWR = false) {
  const query = queryString.stringify(data);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/launchCampaignReportListing?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/launchCampaignReportListing?${query}`
  );
}

function postcardReportListing(data, isSWR = false) {
  const query = queryString.stringify(data);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/postcardReportListing?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/postcardReportListing?${query}`
  );
}

function launchPostcardCampaigns(data, isSWR = false) {
  const query = queryString.stringify(data);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/launchPostcardReportListing?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/launchPostcardReportListing?${query}`
  );
}

function getAllCampaignBoostLocations(data, isSWR = false) {
  const query = queryString.stringify(data);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL_ADMIN}/getAllCampaignBoostLocations?${query}`
    ).then((r) => r.data);
  }
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/getAllCampaignBoostLocations?${query}`
  );
}

function getCurrentBudgetCalculation(data) {
  return AxiosInstance.post(
    `${API_BASE_URL_ADMIN}/getCurrentBudgetCalculation`,
    data
  );
}

function saveCampaignBoostBudget(data) {
  return AxiosInstance.post(
    `${API_BASE_URL_ADMIN}/saveCapmaignBoostBudget `,
    data
  );
}

function getJoinedLocations(data) {
  return AxiosInstance.post(`${API_BASE_URL_ADMIN}/getJoinedLocations`, data);
}

function requestPauseCampaign(data) {
  return AxiosInstance.post(`${API_BASE_URL_ADMIN}/requestPauseCampaign`, {
    campaign_id: data?.campaign_id || "",
    location_ids: data?.location_ids || [],
    ...data,
  });
}
