import { settingsConstants } from "../config/constants";

export const settingActions = {
  getSettings,
};

function getSettings(brandId) {
  return {
    type: settingsConstants.GET_SETTINGS,
    payload: brandId,
  };
}
