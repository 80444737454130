import { authConstants } from "../config/constants";
import { isUserLoggedIn, reloadOtherTabs } from "../helpers/authHelper";

const initialState = {
  isUserLoggedIn: isUserLoggedIn() || false,
  error: null,
  loading: false,
  hasLoggedOut: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        isUserLoggedIn: false,
      };
    case authConstants.LOGIN_SUCCESS:
      const { token } = action.payload;
      return {
        ...state,
        isUserLoggedIn: isUserLoggedIn(token),
        loading: false,
        error: null,
        hasLoggedOut: false,
      };
    case authConstants.LOGIN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isUserLoggedIn: false,
      };
    case authConstants.LOGOUT:
      reloadOtherTabs();
      return {
        ...initialState,
        isUserLoggedIn: false,
        hasLoggedOut: true,
      };
    case authConstants.RESET_HAS_LOGGED_OUT:
      return {
        ...state,
        hasLoggedOut: false,
      };

    default:
      return state;
  }
};
