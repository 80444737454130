import React from "react";
import { useParams } from "react-router-dom";

import DigitalCampaignLearnMore from "../containers/learnMore/DigitalCampaignLearnMore";
import PostcardCampaignLearnMore from "../containers/learnMore/PostcardCampaignLearnMore";

const CampaignType = {
  "digital-campaign": <DigitalCampaignLearnMore />,
  "postcard-campaign": <PostcardCampaignLearnMore />,
};

const LearnMorePage = () => {
  const { campaignType } = useParams();

  return CampaignType[campaignType];
};

export default LearnMorePage;
