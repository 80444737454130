import React, { useEffect, useRef, useState } from "react";
import ResendEmailLink from "./ResendEmailLink";

export default function EmailLinkSendMessage({ email, subscribe }) {
  const timeInterval = useRef();
  const [countDown, setCountDown] = useState(30);
  // const [hasSendEmail, setHasSendEmail] = useState(false);

  const startCountdown = () => {
    setCountDown(30);
    timeInterval.current = setInterval(() => {
      setCountDown((t) => t - 1);
    }, 1000);
  };

  useEffect(() => {
    if (countDown === 0) {
      clearInterval(timeInterval.current);
      timeInterval.current = null;
    }
  }, [countDown]);

  useEffect(() => {
    startCountdown();
    return () => {
      clearInterval(timeInterval.current);
      timeInterval.current = null;
    };
  }, [timeInterval]);

  const handleOnSendEmail = () => {
    // setHasSendEmail(true);
    startCountdown();
  };

  return (
    <div className="invalid-link-message">
      <h2 className="heading">Check your email</h2>
      <p className="subheading">
        We have sent an email to <strong>{email}</strong>
      </p>
      <p className="subheading">
        Click the link in the email to confirm your address and activate your
        account.
      </p>
      <div className="text-center mt-5 font-weight-bold text-muted">
        <h6 className="font-weight-bold">Didn’t get the email?</h6>
        <h6 className="align-middle font-weight-bold d-flex align-items-center justify-content-center">
          Check your spam folder or
          {countDown === 0 ? (
            <ResendEmailLink
              email={email}
              subscribe={subscribe}
              onSuccess={handleOnSendEmail}
              text="Click Here"
            />
          ) : (
            <span className="ml-1">{`resend in ${countDown}s`}</span>
          )}
        </h6>
      </div>
    </div>
  );
}
