import React, { memo } from "react";

const CurrentAvailableCampaignCard = ({
  img = "",
  campaignName = "",
  date = "",
}) => {
  return (
    <div className="card shadow-sm">
      <div className="row no-gutters">
        <div className="col-md-6 order-md-1">
          <img src={img} alt="" className="img-fluid" />
        </div>
        <div className="col-md-6">
          <div className="card-body campaign-detail">
            <h3 className="campaign-name">{campaignName}</h3>
            <div>
              <div className="d-heading">Dates:</div>
              <div className="dates">{date}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CurrentAvailableCampaignCard);
