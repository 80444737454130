import React, { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { GlobalContext, LoaderContext } from "./contexts";
import { isLoggedInSelector } from "../../selectors/authSelectors";
import LoaderDiv from "../../components/styledComponents/LoaderDiv";
import { CircularProgress } from "@material-ui/core";
import {
  userBrandsSelector,
  selectedBrandSelector,
} from "../../selectors/brandSelector";
import { brandActions } from "../../actions";
import {
  loggedInUserRoleSelector,
  loggedInUserSelector,
} from "../../selectors/userSelector";

const mapStateToProps = (state) => {
  const loggedInUser = loggedInUserSelector(state);
  const loggedInUserRole = loggedInUserRoleSelector(state);
  const isLoggedIn = isLoggedInSelector(state);

  return {
    loggedInUser,
    loggedInUserRole,
    selectedBrand: selectedBrandSelector(state),
    isLoggedIn,
    userBrands: userBrandsSelector(state),
  };
};
/**
 * @method GlobalContextProvider
 * @description Global context provider
 */
export const GlobalContextProvider = connect(mapStateToProps, {
  getPageSetup: brandActions.getPageSetup,
  getBrands: brandActions.getBrands,
})(
  ({
    children,
    /** States */
    loggedInUser,
    loggedInUserRole,
    selectedBrand,
    userBrands,
    isLoggedIn,
    /** Actions */
    getPageSetup,
    getBrands,
  }) => {
    useEffect(() => {
      /** Call API related to selected brand  */

      if (isLoggedIn && selectedBrand && loggedInUser && loggedInUser?.id) {
        // if (loggedInUserRole === "PR" || loggedInUserRole === "PC") {
        // }
        let getBrandsRequestData = {
          fk_participant_id: 0,
          fk_company_id: 0,
        };

        if (loggedInUserRole === "PR") {
          getBrandsRequestData.fk_participant_id = loggedInUser?.participantData
            ? loggedInUser?.participantData?.id
            : 0;
        } else if (loggedInUserRole === "PC") {
          getBrandsRequestData.fk_company_id = loggedInUser?.companyData
            ? loggedInUser?.companyData?.id
            : 0;
        } else {
          getBrandsRequestData.id = selectedBrand.id;
        }

        getBrands(getBrandsRequestData);

        getPageSetup(selectedBrand.id);
      }
    }, [selectedBrand?.id]);

    const value = useMemo(
      () => ({
        loggedInUser,
        loggedInUserRole,
        selectedBrand,
        isLoggedIn,
        userBrands,
      }),
      [loggedInUser, loggedInUserRole, selectedBrand, isLoggedIn, userBrands]
    );

    return (
      <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
    );
  }
);

/**
 * @method LoaderProvider
 * @description Loader context provider
 */

export const LoaderProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <LoaderDiv show={show ? true : false} filled={show === 1 ? true : false}>
        <CircularProgress style={{ color: show === 1 ? "#000" : "#fff" }} />
        {/* <h6 className="my-2">Loading...</h6> */}
      </LoaderDiv>

      <LoaderContext.Provider value={setShow}>
        {children}
      </LoaderContext.Provider>
    </>
  );
};
