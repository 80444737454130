import React from "react";
import Box from "@material-ui/core/Box";
import FAQSection from "./FAQSection";
import EducationalVideoSection from "./EducationalVideoSection";
import { Typography } from "@material-ui/core";
import ArticleSection from "./ArticlesSection";
import WhatsNewSection from "./WhatsNewSection";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function AllSectionTabs({
  tabs,
  value,
  faqData,
  pageIndex,
  pageSize,
  handlePageChange,
  educationalVideos,
  totalVideos,
  articles,
  totalArticles,
  articlesPageIndex,
  articlesPageSize,
  handleArticlePageChange,
  whatsNew,
  totalNewData,
  newDataPageIndex,
  newDataPageSize,
  handleWhatsNewPage,
}) {
  return (
    <div className="all-section">
      {tabs?.map((tab, i) => (
        <TabPanel value={value} index={i} key={i}>
          {(() => {
            switch (tab) {
              case "FAQ":
                return <FAQSection faq={faqData} />;
              case "Educational Videos":
                return (
                  <EducationalVideoSection
                    educationalVideos={educationalVideos}
                    handlePageChange={handlePageChange}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    totalVideos={totalVideos}
                  />
                );
              case "Helpful Content":
                return (
                  <ArticleSection
                    articles={articles}
                    totalArticles={totalArticles}
                    articlesPageIndex={articlesPageIndex}
                    articlesPageSize={articlesPageSize}
                    handleArticlePageChange={handleArticlePageChange}
                  />
                );
              case "What's New":
                return (
                  <WhatsNewSection
                    whatsNew={whatsNew}
                    totalNewData={totalNewData}
                    newDataPageSize={newDataPageSize}
                    newDataPageIndex={newDataPageIndex}
                    handleWhatsNewPage={handleWhatsNewPage}
                  />
                );
              default:
                return null;
            }
          })()}
        </TabPanel>
      ))}
    </div>
  );
}
