import React, { Fragment } from "react";

import { CurrencyFormateText } from "../CurrencyFormat";

export default function ConfirmJoinCampaignTotalCard({
  totals,
  enable_payment_through_portal,
}) {
  const {
    totalCampaignBudget,
    brandContribution,
    budgetYouPay,
    // creditCardProcessingFee,
    amountYouPay,
  } = totals;

  const renderPaymentInfo = () => {
    if (!enable_payment_through_portal) return null;
    return (
      <>
        {/* {creditCardProcessingFee !== 0 && (
          <>
            <div className="col-7 border-top col-md-10 title-other pt-2">
              Amount You Pay:
            </div>
            <div className="col-5 border-top col-md-2 amount-other pt-2">
              <CurrencyFormateText value={budgetYouPay} suffix=" USD" />
            </div>
            <div className="col-7 col-md-10 title-other py-1">
              Credit Card Processing Fee:
            </div>
            <div className="col-5 col-md-2 amount-other py-1">
              <CurrencyFormateText
                value={creditCardProcessingFee}
                suffix=" USD"
              />
            </div>
          </>
        )} */}
        <div className="col-7 col-md-10 title-to-pay">Total You Pay:</div>
        <div className="col-5 col-md-2 amount-to-pay">
          <CurrencyFormateText value={amountYouPay} suffix=" USD" />
        </div>
      </>
    );
  };

  const renderBrandMatch = () => {
    return brandContribution ? (
      <>
        <div className="col-7 mb-lg-1 col-md-10 title-other">Budget:</div>
        <div className="col-5 col-md-2 amount-other">
          <CurrencyFormateText value={budgetYouPay} suffix=" USD" />
        </div>
        <div className="col-7 mb-lg-1 col-md-10 title">Brand Match:</div>
        <div className="col-5 col-md-2 amount">
          {!enable_payment_through_portal ? "+" : null}
          <CurrencyFormateText value={brandContribution} suffix=" USD" />
        </div>
      </>
    ) : null;
  };

  const renderAmountPay = () => {
    return brandContribution ? (
      <div className="row border-top mt-1">
        <div className="col-7 col-md-10 mt-2 title-to-pay pt-2">
          Amount You Pay:
        </div>
        <div className="col-5 mt-2 col-md-2 amount-to-pay pt-2">
          <CurrencyFormateText value={budgetYouPay} suffix=" USD" />
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="totals">
      <div className="card">
        <div className="card-body">
          <div className="row">
            {renderBrandMatch()}
            <div className="col-7 col-md-10 title mb-1">
              Total Location Budget:
            </div>
            <div className="col-5 col-md-2 amount mb-1">
              <CurrencyFormateText value={totalCampaignBudget} suffix=" USD" />
            </div>

            {renderPaymentInfo()}
          </div>
          {!enable_payment_through_portal ? renderAmountPay() : null}
        </div>
      </div>
    </div>
  );
}
