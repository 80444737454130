import React, { useMemo } from "react";
import { Typography } from "@material-ui/core";

import { CurrencyFormateText } from "../../../components/CurrencyFormat";
import { subscriptionPlanFeeType } from "../../../config/constants";

export default function PackageCard({ plan }) {
  const renderCoast = useMemo(() => {
    const getCoastContent = (amount, extraCharge, type) => (
      <>
        <div className="media-fee">
          <Typography component="h5" variant="h3" className="total">
            <CurrencyFormateText value={amount} fixedDecimalScale={false} />
          </Typography>
          <Typography className="total-info ml-2">
            {type === "media" || type === "both" ? "media cost" : "fee"}
            <br /> per month
          </Typography>
        </div>
        {type === "both" && (
          <div className="subscription-fee ml-4">
            <Typography component="h5" variant="h4" className="total">
              +
              <CurrencyFormateText
                value={extraCharge}
                fixedDecimalScale={false}
              />
            </Typography>
            <Typography className="total-info ml-2">
              fee <br />
              per month
            </Typography>
          </div>
        )}
      </>
    );

    if (plan.fees_type === subscriptionPlanFeeType.SUB_FEE_AND_MEDIA_COST) {
      return getCoastContent(plan.amount, plan.extra_charge, "both");
    } else if (plan.fees_type === subscriptionPlanFeeType.SUB_FEE_ONLY) {
      return getCoastContent(plan.extra_charge, null, "subscription");
    } else {
      return getCoastContent(plan.amount, null, "media");
    }
  }, [plan]);

  return (
    <>
      <div
        className={`card package-card h-100 ${
          plan.is_recommended ? "recommended_package" : ""
        } ${plan.is_brand_match ? "brand-match-card" : ""}`}
      >
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <Typography component="h4" variant="h6" className="name">
              {plan.title}{" "}
            </Typography>
            {plan.is_recommended ? (
              <div className="text-center mb-3">
                <span className="recommended">Recommended</span>
              </div>
            ) : null}
          </div>

          {renderCoast}
          <div
            className="description mt-3"
            dangerouslySetInnerHTML={{ __html: plan.description }}
          ></div>
        </div>
      </div>
    </>
  );
}
