export const MSFunctionalTitleOptions = [
  { label: "Financial Advisor", value: "Financial Advisor" },
  { label: "Wealth Advisor", value: "Wealth Advisor" },
  { label: "Private Wealth Advisor", value: "Private Wealth Advisor" },
  { label: "Institutional Consultant", value: "Institutional Consultant" },
  {
    label: "International Wealth Advisor",
    value: "International Wealth Advisor",
  },
];

export const MSLineOfBusinessOptions = [
  { label: "Wealth Management", value: "Wealth Management" },
  { label: "Greystone Consulting", value: "Greystone Consulting" },
  { label: "Private Wealth Management", value: "Private Wealth Management" },
];

export const MSInvestmentAreasOfFocus = [
  { label: "Charitable Giving", value: "Charitable Giving" },
  { label: "Estate Planning", value: "Estate Planning" },
  { label: "Family Finances", value: "Family Finances" },
  { label: "General Investment Advice", value: "General Investment Advice" },
  {
    label: "Global Sports & Entertainment",
    value: "Global Sports & Entertainment",
  },
  {
    label: "Help With Special Needs and Disabilities",
    value: "Help With Special Needs and Disabilities",
  },
  { label: "Institution", value: "Institution" },
  { label: "Liability Management", value: "Liability Management" },
  { label: "LGBT Financial Planning", value: "LGBT Financial Planning" },
  {
    label: "Navigating Divorce Finances",
    value: "Navigating Divorce Finances",
  },
  { label: "Pre-Liquidity Event", value: "Pre-Liquidity Event" },
  { label: "Retirement Planning", value: "Retirement Planning" },
  {
    label: "Small Business Financial Planning",
    value: "Small Business Financial Planning",
  },
  {
    label: "Socially and Environmentally Responsible Investing",
    value: "Socially and Environmentally Responsible Investing",
  },
  { label: "Tax Planning", value: "Tax Planning" },
  {
    label: "Ultra High Net Worth Investing",
    value: "Ultra High Net Worth Investing",
  },
  {
    label: "Wealth Transfer Planning/Inheritance",
    value: "Wealth Transfer Planning/Inheritance",
  },
  { label: "Women & Finance", value: "Women & Finance" },
  { label: "Other", value: "Other" },
];

export const MSStateOptions = [
  { label: "AL", value: "AL" },
  { label: "AK", value: "AK" },
  { label: "CT", value: "CT" },
  { label: "DE", value: "DE" },
  { label: "IL", value: "IN" },
  { label: "MD", value: "MD" },
  { label: "ME", value: "ME" },
  { label: "MO", value: "MO" },
  { label: "MT", value: "MT" },
  { label: "NM", value: "NM" },
  { label: "NY", value: "NY" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
];

export const MSPackagesData = [
  {
    id: 1,
    tier: "Tier 1",
    name: "Basic Program",
    price: 200,
    description: `Coverage of Branded Keywords and General Wealth Management Non-Branded
  Keywords`,
    media_budget_range: "N/A",
    perks: [
      `Branded keyword (name/team name) coverage within Google`,
      `General wealth management non-branded keywords (ex: financial
    advisor near me)`,
    ],
    recommended: false,
  },
  {
    id: 2,
    tier: "Tier 2",
    name: "Advanced Program",
    price: 200,
    description: `Coverage of Brand Keywords and Campaign Setup and Maintenance`,
    media_budget_range: "$100 - $200 Range",
    perks: [
      `Non-Brand keyword coverage tailored to your investment areas of focus`,
    ],
    recommended: false,
  },
  {
    id: 3,
    tier: "Tier 3",
    name: "Pro Program",
    price: 300,
    description: `Coverage of Brand Keywords and Campaign Setup and Maintenance`,
    media_budget_range: "$200 - $500 Range",
    perks: [
      `Non-Brand keyword coverage tailored to your investment areas of focus`,
      `Pre-approved banner ads running on third-party websites next to contextually relevant content`,
    ],
    recommended: true,
  },
];
