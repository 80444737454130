import React, { useContext, useMemo } from "react";
import { Divider, Icon, Typography } from "@material-ui/core";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import { getFormatDate } from "../../../helpers/utils";

export default function PageHeader({
  title = "",
  icon = "",
  iconImage = "",
  gritting = "Welcome back",
  message = "",
  hideBelowSection = false,
  showName = false,
  showLastUpdate = false,
  showHr = true,
}) {
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);

  const lastUpdatedDate = useMemo(() => {
    return selectedBrand?.dashboard_last_updated
      ? getFormatDate(selectedBrand?.dashboard_last_updated)
      : "";
  }, [selectedBrand]);

  return (
    <div className="page-header">
      {hideBelowSection === false && (
        <div className="row mb-3 align-items-center d-flex justify-content-between">
          <div className="col">
            {title ? (
              <div className="page-title">
                {iconImage ? (
                  <Icon
                    className="img-fluid page-title-icon page-title-icon-image"
                    component="img"
                    src={iconImage}
                  />
                ) : null}
                {icon ? (
                  <Icon className="page-title-icon" color="primary">
                    {icon}
                  </Icon>
                ) : null}

                <Typography component="h1" variant="h5">
                  {title}
                </Typography>
              </div>
            ) : null}
            {showName && (
              <Typography component="h1" variant="h5">
                Hi! {gritting}{" "}
                <Typography
                  component="span"
                  variant="h5"
                  className="text-capitalize"
                  color="primary"
                >
                  {loggedInUser?.first_name.concat(
                    ` ${loggedInUser?.last_name}`
                  )}
                  !
                </Typography>
              </Typography>
            )}
            {message && (
              <div className="w-100">
                <Typography variant="caption">{message}</Typography>
              </div>
            )}
          </div>
          {showLastUpdate && (
            <div className="col-sm-4">
              {lastUpdatedDate && (
                <div className="last-updated">
                  <Typography variant="body2">Data as of</Typography>
                  <Typography
                    variant="body1"
                    color="primary"
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {lastUpdatedDate}
                  </Typography>
                </div>
              )}
            </div>
          )}

          {/* {settings?.brandSettings?.has_brand_resource ? (
            <div className="col-12 pt-1 d-flex justify-content-end">
              {showButton && (
                <MuiDarkButton
                  size="small"
                  component={Link}
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/resource-center/${selectedBrand?.id}`,
                    state: { from: "/dashboard" },
                  }}
                  variant="contained"
                  disableElevation
                  className="px-3 mt-2"
                >
                  Resource Center
                </MuiDarkButton>
              )}
            </div>
          ) : null} */}
        </div>
      )}
      {showHr && <Divider style={{ margin: "20px 0" }} />}
    </div>
  );
}
