import React, { memo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageRP from "../ImageRP";
import { Icon, Typography } from "@material-ui/core";

const CreativeCarousel = ({ channels = [] }) => {
  const settings = {
    infinite: false,

    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // className: "finite-center",
          // centerMode: true,
          // centerPadding: "40px",
        },
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // className: "finite-center",
          // centerMode: true,
          // centerPadding: "30px",
          // arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // className: "finite-center",
          // centerMode: true,
          // centerPadding: "30px",
          // arrows: false,
        },
      },
    ],

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="creative-carousel d-flex align-items-center justify-content-center">
      <Slider {...settings} className="w-100">
        {channels.map((ch) => (
          <div key={ch.id}>
            <div className="creative-channel">
              {ch.channel?.channel_name ? (
                <Typography variant="caption" component="p" gutterBottom>
                  Channel:{" "}
                  <Typography
                    color="primary"
                    variant="caption"
                    className="fw-b"
                  >
                    {ch.channel?.channel_name}
                  </Typography>
                </Typography>
              ) : (
                ""
              )}

              <ImageRP
                src={ch.attachment}
                type="CREATIVE"
                alt={ch.channel?.channel_name}
                className="img-fluid"
              />
              <Typography variant="caption" component="p" className="mt-2">
                <Typography color="primary" variant="caption" className="fw-b">
                  {ch.creative_name}
                </Typography>
              </Typography>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <Icon color={onClick ? "primary" : "disabled"}>navigate_next</Icon>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <Icon color={onClick ? "primary" : "disabled"}>navigate_before</Icon>
    </div>
  );
}

export default memo(CreativeCarousel);
