import React, { useState, useEffect, useMemo, useContext } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { RPNumberFormat } from "../../helpers/utils";
import { datasetDefaultOptions } from "./ChartJsConfig";
import DrillControl from "./DrillControl";
import {
  WIDGETS_WITH_DRILL_FEATURE,
  DRILL_LEVELS,
} from "../../config/constants";
import {
  formatAndSortData,
  isBrandHasDrillFeature,
} from "../../helpers/drillUpDownHelper";
import { GlobalContext } from "../../helpers/contexts/contexts";

const TotalSpendChart = ({
  data = [],
  labels,
  option = {},
  scales = {},
  title = "",
  formats = null,
  showTotal = false,
  widgetId,
  values,
  initialRange,
}) => {
  const { selectedBrand } = useContext(GlobalContext);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [drillLevel, setDrillLevel] = useState(DRILL_LEVELS.MONTH);

  const options = useMemo(
    () => ({
      tooltips: {
        //intersect: false,
        callbacks: {
          title: (tooltipItem) => {
            const label = tooltipItem[0].label;
            if (drillLevel === DRILL_LEVELS.DAY) {
              return moment(label, "YYYY-MM-DD").format("DD MMM YYYY");
            } else if (
              drillLevel === DRILL_LEVELS.QUARTER ||
              drillLevel === DRILL_LEVELS.YEAR
            ) {
              return label;
            } else {
              return moment(label, "YYYY-MM-DD").format("MMM YYYY");
            }
          },
          label: (tooltipItem, data) => {
            const label = data.datasets[0].label;
            const val = data.datasets[0].data[tooltipItem.index] || {};
            const format = formats ? formats[labels[1]] : null;
            if (!val.y) return "";
            if (formats && format) {
              // Adding prefix and comma format
              return `${label}: ${RPNumberFormat({
                prefix: format.prefix || "$",

                suffix: format.suffix,
              }).to(val.y)}`;
            }
            // Default to using $ if no format is provided
            return `${label}: ${RPNumberFormat({ prefix: "$" }).to(val.y)}`;
          },
        },
      },
      legend: {
        display: false,
        align: "end",
        labels: {
          boxWidth: 10,
          fontFamily: "Montserrat",
          fontColor: "#548e8d",
          fontStyle: "bold",
          padding: 5,
          marginBottom: 1,
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        fontColor: "#808080",
        fontFamily: "Open Sans",
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              callback: (value) => {
                if (drillLevel === DRILL_LEVELS.QUARTER) {
                  return value; // Display as "Q1-2025"
                } else if (drillLevel === DRILL_LEVELS.YEAR) {
                  return value; // Display as "2025"
                } else if (drillLevel === DRILL_LEVELS.DAY) {
                  return moment(value, "YYYY-MM-DD").format("DD"); // Display as "01 JAN"
                } else {
                  return moment(value, "YYYY-MM-DD").format("MMM");
                }
              },
            },
          },
        ],
        yAxes: [
          {
            id: "y-axis-0",
            position: "left",
            ticks: {
              min: 0,
              maxTicksLimit: 5,
              callback: (value) => {
                const format = formats ? formats[labels[1]] : null;
                if (!value) return "";
                if (formats && format) {
                  return RPNumberFormat({
                    prefix: format.prefix,
                    suffix: format.suffix,
                  }).to(value);
                }
                return RPNumberFormat().to(value);
              },
            },
          },
        ],
        ...scales,
      },
      ...option,
    }),
    [option, scales, drillLevel]
  );

  const getDataSet = (data, currentDrillLevel = drillLevel) => {
    const formatData = formatAndSortData(data, labels, currentDrillLevel);

    const datasets = [
      {
        label: title,
        data: formatData,
        yAxisID: "y-axis-0",
        ...datasetDefaultOptions,
      },
    ];

    return {
      labels: formatData.map((d) => d.x),
      datasets: datasets,
    };
  };

  useEffect(() => {
    const initData = {
      labels: getDataSet()[0]?.data
        ? getDataSet()[0]?.data?.map((d) => d.x)
        : [],
      datasets: getDataSet(),
    };
    setChartData(initData);
  }, [data]);

  useEffect(() => {
    setChartData(getDataSet(data));
  }, [data]);

  const getTotalSpendSum = () => {
    if ((data && !data) || data.length.length === 0) return "$0";
    return RPNumberFormat({
      prefix: formats && formats[labels[1]] ? formats[labels[1]].prefix : "",
      suffix: formats && formats[labels[1]] ? formats[labels[1]].suffix : "",
      decimals: 2,
    }).to(
      data.reduce(
        (t, d) =>
          d[labels[1]] && parseFloat(d[labels[1]])
            ? t + parseFloat(d[labels[1]])
            : t,
        0
      )
    );
  };

  const handleDrillChange = (newDrillLevel, newData) => {
    setDrillLevel(newDrillLevel);
    setChartData(() => getDataSet(newData, newDrillLevel));
  };

  return (
    <div className="bar-chart total-spend-bar-chart">
      {showTotal && data && data.length ? (
        <div className="row text16P_Bold548E8D text-right mb-1">
          <div className="col-6">
            <Typography
              color="primary"
              variant="body2"
              className="fw-b text-left"
            >
              {`Total: ${getTotalSpendSum()}`}
            </Typography>
          </div>
          <div className="col-6">
            {isBrandHasDrillFeature(selectedBrand?.system_generated_brand_id) &&
              widgetId &&
              WIDGETS_WITH_DRILL_FEATURE.includes(widgetId) && (
                <DrillControl
                  drillLevel={drillLevel}
                  setDrillLevel={setDrillLevel}
                  onDrillChange={handleDrillChange}
                  widgetId={widgetId}
                  values={values}
                  initialRange={initialRange}
                />
              )}
          </div>
        </div>
      ) : null}
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default TotalSpendChart;
