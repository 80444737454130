import moment from "moment";
import { sortBy } from "lodash";
import {
  BRANDS_HAVE_DRILL_FEATURE,
  DRILL_LEVELS,
  FILTERS,
} from "../config/constants";

export const parseDateString = (dateString) => {
  const [year, month, day] = dateString.split("-").map(Number);
  return { year, month, day };
};

export const formatDate = (date) => {
  if (date) {
    const year = date.year;
    const month = String(date.month).padStart(2, "0");
    const day = String(date.day).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return "";
};

export const formatAndSortData = (data, labels, currentDrillLevel) => {
  let formatData = [];

  formatData =
    data &&
    data.map((d) => {
      let formattedX = d[labels[0]];
      let formattedY = d[labels[1]];

      if (currentDrillLevel === "quarter") {
        formattedX = d[labels[0]];
      } else if (currentDrillLevel === "year") {
        formattedX = d[labels[0]];
      } else if (currentDrillLevel === "day") {
        formattedX = moment(d[labels[0]]).format("YYYY-MM-DD");
      } else {
        formattedX = moment(d[labels[0]], [
          "YYYY-M",
          "YYYY-MM",
          "YYYY-MM-DD",
        ]).format("YYYY-MM-DD");
      }

      return { x: formattedX, y: formattedY };
    });

  // Sorting data as per drill level selected
  if (currentDrillLevel === "month" || currentDrillLevel === "day") {
    formatData = sortBy(formatData, (o) => moment(o.x, "YYYY-MM-DD"));
  } else if (currentDrillLevel === "quarter") {
    formatData = sortBy(formatData, (o) => {
      const [quarter, year] = o.x.split("-");
      return `${year}-${quarter}`;
    });
  } else if (currentDrillLevel === "year") {
    formatData = sortBy(formatData, "x");
  }

  return formatData;
};

export const isDateRangeOutOfBounds = (values, initialRange, drillLevel) => {
  const isCustomRange = values.filter === FILTERS.CUSTOM;

  const formatDate = (year, month, day) =>
    `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

  const isDateRangeOutOfRange =
    isCustomRange && values.customRange
      ? moment(values.customRange[1]).diff(
          moment(values.customRange[0]),
          "days"
        ) > 60
      : false;

  const isInitialDateRangeOutOfRange = initialRange
    ? moment(
        formatDate(
          initialRange.to.year,
          initialRange.to.month,
          initialRange.to.date
        )
      ).diff(
        moment(
          formatDate(
            initialRange.from.year,
            initialRange.from.month,
            initialRange.from.date
          )
        ),
        "days"
      ) > 60
    : false;

  if (
    drillLevel === DRILL_LEVELS.MONTH &&
    (isDateRangeOutOfRange || isInitialDateRangeOutOfRange)
  ) {
    return true;
  } else if (values.filter === FILTERS.YTD) {
    const startOfYear = moment().startOf("year");
    const today = moment();
    const daysDifference = today.diff(startOfYear, "days");
    return drillLevel === DRILL_LEVELS.MONTH && daysDifference > 60;
  } else if (values.filter === FILTERS.LAST_QUARTER) {
    return drillLevel === DRILL_LEVELS.MONTH;
  } else {
    return false;
  }
};

export const getInitialRange = (initialRange, today) => {
  const validInitialRange =
    initialRange && initialRange.from && initialRange.to;
  const initialStart = validInitialRange
    ? moment(
        `${initialRange.from.year}-${initialRange.from.month}-${initialRange.from.date}`,
        "YYYY-MM-DD"
      )
    : today;
  const initialEnd = validInitialRange
    ? moment(
        `${initialRange.to.year}-${initialRange.to.month}-${initialRange.to.date}`,
        "YYYY-MM-DD"
      )
    : today;

  return [initialStart, initialEnd];
};

export const isBrandHasDrillFeature = (system_generated_brand_id) => {
  return (
    BRANDS_HAVE_DRILL_FEATURE?.includes("all") ||
    BRANDS_HAVE_DRILL_FEATURE?.includes(system_generated_brand_id)
  );
};
