import React, { useContext, useState, useRef, useEffect, useMemo } from "react";
import { toastr } from "react-redux-toastr";
import { useParams } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import { Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";

import MuiDarkButton from "../muiCustom/buttons/MuiDarkButton";
import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import pdfDownloadHelper from "../../helpers/pdfDownloadHelper";
import { campaignService } from "../../services";
import { reportUserActivity } from "../../helpers/userActivityHelper";
import { downloadByLink } from "../../helpers/utils";
import {
  formatDate,
  isBrandHasDrillFeature,
  parseDateString,
} from "../../helpers/drillUpDownHelper";
import { logger } from "../../helpers/loggerHelper";

const options = ["CSV", "PDF"];

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "999",
    fontSize: "0.8rem",
    width: "90%",
    "& .MuiListItem-gutters": {
      padding: "6px 2px",
      display: "flex",
      justifyContent: "space-between",
    },
    "& .Mui-selected": {
      backgroundColor: "rgb(229 247 246)",
    },
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  radioLabel: {
    display: "flex",
    alignItems: "center",
  },
  radioCircle: {
    padding: "4px",
    "& .MuiSvgIcon-root": {
      width: "14px",
      height: "14px",
      "& path": {
        fill: "#04a999",
      },
    },
  },
  label: {
    fontSize: "0.75rem",
    marginLeft: "10px",
    marginBottom: "5px",
  },
  smallText: {
    fontSize: "0.75rem",
  },
  checkIcon: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    fontSize: "medium",
  },
  dropdownButton: {
    height: "36px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonDownload: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  MenuList: {
    width: "100%",
  },
}));

export default function SelectDownloadReports({
  fileName,
  campaign,
  values,
  elId,
  initialRange,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);
  const { campaignSysId } = useParams();
  const [processing, setProcessing] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    loader(processing);
  }, [processing, loader]);

  // const startPeriod = useMemo(() => {
  //   if (values.filter === "custom" && values.customRange) {
  //     return formatDate(parseDateString(values.customRange[0]));
  //   }
  //   if (initialRange) {
  //     return `${initialRange.from.year}-${String(
  //       initialRange.from.month
  //     ).padStart(2, "0")}`;
  //   }
  //   return "";
  // }, [values.filter, values.customRange, initialRange]);

  // const endPeriod = useMemo(() => {
  //   if (values.filter === "custom" && values.customRange) {
  //     return formatDate(parseDateString(values.customRange[1]));
  //   }
  //   if (initialRange) {
  //     return `${initialRange.to.year}-${String(initialRange.to.month).padStart(
  //       2,
  //       "0"
  //     )}`;
  //   }
  //   return "";
  // }, [values.filter, values.customRange, initialRange]);

  const startPeriod = useMemo(() => {
    if (isBrandHasDrillFeature(selectedBrand.system_generated_brand_id)) {
      if (values.filter === "custom" && values.customRange) {
        return formatDate(parseDateString(values.customRange[0]));
      }
      if (initialRange) {
        return `${initialRange.from.year}-${String(
          initialRange.from.month
        ).padStart(2, "0")}`;
      }
      return "";
    }
    return values.filter === "custom" && values.customRange
      ? `${values.customRange.from.year}-${values.customRange.from.month}`
      : initialRange && `${initialRange.from.year}-${initialRange.from.month}`;
  }, [values.filter, values.customRange, initialRange]);

  const endPeriod = useMemo(() => {
    if (isBrandHasDrillFeature(selectedBrand.system_generated_brand_id)) {
      if (values.filter === "custom" && values.customRange) {
        return formatDate(parseDateString(values.customRange[1]));
      }
      if (initialRange) {
        return `${initialRange.to.year}-${String(
          initialRange.to.month
        ).padStart(2, "0")}`;
      }
      return "";
    }
    return values.filter === "custom" && values.customRange
      ? `${values.customRange.to.year}-${values.customRange.to.month}`
      : initialRange && `${initialRange.to.year}-${initialRange.to.month}`;
  }, [values.filter, values.customRange, initialRange]);

  // request Data for csv download
  const requestData = useMemo(() => {
    if (!campaign || !campaign.id) {
      return null;
    }

    return {
      campaign_id: campaign.id,
      system_generated_campaign_id: `${campaignSysId}`,
      system_generated_brand_id: selectedBrand.system_generated_brand_id,
      fk_brand_id: selectedBrand.id,
      location_id: values.location_id || "",
      group_id: values.group_id || "",
      channel_id: values.channel_id || "",
      userId: loggedInUser?.id,
      fk_participant_id: loggedInUser?.participantData?.id || 0,
      downloadReport: true,
      filter: values.filter,
      participant_system_id:
        loggedInUser?.participantData?.system_generated_participant_id,
      fk_role_id: loggedInUser.fk_role_id,
      start_period: startPeriod,
      end_period: endPeriod,
    };
  }, [campaign, selectedBrand?.id, loggedInUser, campaignSysId, values]);

  const handleClick = async () => {
    if (selectedIndex === 1) {
      pdfDownloadHelper(processing, setProcessing, elId, fileName);
    } else {
      try {
        setProcessing(true);
        reportUserActivity("download_csv");
        toastr.info("CSV Download", "CSV download started");
        const res = await campaignService.getCampaignReportCsv(requestData);
        if (res.status === 200) {
          const fileUnlinkPath = res.data ? res.data : null;
          downloadByLink(fileUnlinkPath);
          setProcessing(false);
          toastr.success("Success", "CSV Report downloaded successfully");
          // Schedule unlinking after 2 seconds
          if (fileUnlinkPath) {
            setTimeout(() => {
              campaignService.getUnlinkCampaignReportCsv(fileUnlinkPath);
            }, 2000);
          } else {
            logger.error("File unlink path is null or undefined");
          }
        } else {
          setProcessing(false);
        }
      } catch (error) {
        toastr.error("Error", "Error fetching campaign report");
        setProcessing(false);
      }
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        className="w-100"
      >
        <MuiDarkButton
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className={classes.dropdownButton}
        >
          Download {options[selectedIndex]}
          <ArrowDropDownIcon />
        </MuiDarkButton>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.root}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" className={classes.MenuList}>
                  <Typography className={classes.label}>
                    Choose a format:
                  </Typography>

                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      className={classes.buttonGroup}
                    >
                      <div className={classes.radioLabel}>
                        <Radio
                          checked={index === selectedIndex}
                          className={classes.radioCircle}
                        />
                        <Typography className={classes.smallText}>
                          {option}
                        </Typography>
                      </div>
                      {index === selectedIndex && (
                        <CheckIcon className={classes.checkIcon} />
                      )}
                    </MenuItem>
                  ))}
                  <div className={classes.buttonDownload}>
                    {" "}
                    <button
                      className="button primary small btn-download"
                      onClick={() => {
                        setOpen(() => false);
                        setTimeout(() => {
                          handleClick();
                        }, 0);
                      }}
                    >
                      Download
                    </button>
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
