import { takeEvery, call, put } from "redux-saga/effects";
import {
  resetPasswordConstants,
  statusCodeConstants,
} from "../../config/constants";
import { authService } from "../../services";

function* forgotPassword(action) {
  try {
    const res = yield call(authService.forgotPassword, action.payload);
    if (res.status === statusCodeConstants.OK && res.data) {
      yield put({
        type: resetPasswordConstants.FORGOT_PASSWORD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    yield put({
      type: resetPasswordConstants.FORGOT_PASSWORD_FAIL,
      payload: err,
    });
  }
}

function* validatePasswordRequest(action) {
  try {
    const res = yield call(authService.validateResetPassToken, action.payload);

    if (res.status === statusCodeConstants.OK && res.data) {
      yield put({
        type: resetPasswordConstants.VALIDATE_RESET_PASS_TOKEN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (err.response) {
      yield put({
        type: resetPasswordConstants.VALIDATE_RESET_PASS_TOKEN_FAIL,
        payload: err.response.data.message,
      });
    }
  }
}

function* resetPassword(action) {
  try {
    const res = yield call(authService.resetPassword, action.payload);

    if (res.status === statusCodeConstants.CREATED) {
      yield put({
        type: resetPasswordConstants.RESET_PASSWORD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    yield put({
      type: resetPasswordConstants.RESET_PASSWORD_FAIL,
      payload: err,
    });
  }
}

/**
 * @method authSaga
 * @description watch for action and act
 */
export default function* authSaga() {
  yield takeEvery(
    resetPasswordConstants.FORGOT_PASSWORD_REQUEST,
    forgotPassword
  );
  yield takeEvery(
    resetPasswordConstants.VALIDATE_RESET_PASS_TOKEN_REQUEST,
    validatePasswordRequest
  );
  yield takeEvery(resetPasswordConstants.RESET_PASSWORD_REQUEST, resetPassword);
}
