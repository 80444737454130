import { useState, useMemo, useCallback } from "react";

const useSearchFilter = (data = [], searchKeyProperties = []) => {
  const [searchKey, setSearchKey] = useState("");

  const normalizedSearchKeyProperties = useMemo(
    () =>
      Array.isArray(searchKeyProperties)
        ? searchKeyProperties
        : [searchKeyProperties], // If it's a string, convert it to an array
    []
  );
  // Handle search input change
  const handleSearchChange = useCallback((e) => {
    setSearchKey(e?.target?.value ?? (e || ""));
  }, []);

  const filteredItems = useMemo(() => {
    if (!searchKey || normalizedSearchKeyProperties.length === 0) return data; // If no search key or no properties, return all items

    return data.filter((item) =>
      normalizedSearchKeyProperties.some((key) =>
        item[key]?.toString().toLowerCase().includes(searchKey.toLowerCase())
      )
    );
  }, [searchKey, data, normalizedSearchKeyProperties]);

  return [searchKey, handleSearchChange, filteredItems];
};

export default useSearchFilter;
