import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { canJoinCampaign } from "../../helpers/utils";

export default function JoinNowButton({ campaign }) {
  const { id, budget_type } = campaign || {};

  if (!canJoinCampaign(campaign)) return null;

  const customizeOrJoinText = () => {
    // return enable_payment_through_portal === 1 ? "Join Now" : "Customize Now"
    if (budget_type === "Location Specific") {
      return "Join Now";
    } else {
      return "Customize Now";
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to={{
        pathname: `${process.env.PUBLIC_URL}/participant/join-campaign/${id}`,
        state: {
          campaignId: id,
          from: "/dashboard",
        },
      }}
      disableElevation
      // disabled={status === 3}
      className="fs-11"
      fullWidth
    >
      {customizeOrJoinText()}
    </Button>
  );
}
