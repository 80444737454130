import { userConstants, authConstants } from "../config/constants";
import { getUserRole } from "../config/appConfig";

const initialState = {
  role: "",
  user: {},
  error: null,
  loading: false,
  isUserUpdated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        isUserUpdated: false,
      };
    case userConstants.GET_USER_SUCCESS:
      let updatedUserData = { ...state.user, ...action.payload };
      // updating brand owner image
      updatedUserData.brandData = updatedUserData?.brandData.map((b) => {
        if (b.fk_user_id === updatedUserData.id) {
          return { ...b, brand_logo: updatedUserData.logo_name };
        }
        return b;
      });

      return {
        ...state,
        user: updatedUserData,
        role: getUserRole(action.payload.fk_role_id),
        loading: false,
        error: null,
      };

    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isUserUpdated: true,
      };
    case userConstants.GET_USER_FAIL:
    case userConstants.UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case authConstants.LOGOUT:
      return {
        ...initialState,
        role: "",
        user: {},
      };
    default:
      return state;
  }
};
