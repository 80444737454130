import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "@material-ui/core";

const ThankYou = ({
  title = "Thank You",
  message = "We’ve received your feedback and will review asap.<br/>If a reply is requested we will do our best to get back to you within 48 hours.",
  buttonText = "Back to My Dashboard",
  buttonLink = `${process.env.PUBLIC_URL}/dashboard`,
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <img
        className="my-2"
        src={`${process.env.PUBLIC_URL}/img/mail-fly.png`}
        alt=""
      />
      <h2 className="heading3">{title}</h2>
      <p
        className="heading12 w-75 px-md-5 text-center"
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>
      <Button
        component={Link}
        to={buttonLink}
        className="my-4"
        variant="contained"
        color="primary"
        size="large"
        startIcon={<Icon>laptop</Icon>}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ThankYou;
