import { userConstants } from "../config/constants";

export const userActions = {
  getUser,
  updateUser,
};

function getUser(user) {
  return {
    type: userConstants.GET_USER_REQUEST,
    payload: user,
  };
}

function updateUser(user) {
  return {
    type: userConstants.UPDATE_USER_REQUEST,
    payload: user,
  };
}
