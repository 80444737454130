import React from "react";

import {
  getCampaignStatusText,
  campaignFormatHelper,
  getStatusCode,
} from "../../helpers/utils";
import LearnMoreButton from "../muiCustom/buttons/LearnMoreButton";
import StatusChip from "../muiCustom/chip/StatusChip";
import JoinNowButton from "./JoinNowButton";

const NotYetJoinedCampaignListViewCard = ({
  campaign,
  renderArchivedJoinNowButton,
}) => {
  const { status, is_enrolled, brand_contribution, brand_match_message } =
    campaign || {};

  return (
    <div className={`card h-100 ${brand_contribution ? "brand-match" : ""}`}>
      <div className="card-body py-2 pt-3">
        {brand_contribution === 1 && brand_match_message && (
          <div className="brand-match-tag" title="Eligible for Brand Match">
            <span className="tag">{brand_match_message}</span>
          </div>
        )}
        <div className="row align-items-center text-md-left">
          <div className="col-lg-9">
            <div className="row">
              <div className={`${is_enrolled ? "col-md-3" : "col-md-5"} py-2`}>
                <div className="detail">
                  <div className="title">Campaign Name</div>
                  <div
                    className={`title-val campaign-name ${
                      status === 1 || status === 3 ? "main" : ""
                    }`}
                  >
                    {campaignFormatHelper(campaign).name()}
                  </div>
                </div>
              </div>
              {is_enrolled && (status === 1 || status === 2) ? (
                <div className="col-md-2  py-2">
                  <div className="detail">
                    <div className="heading14">Status</div>
                    <StatusChip
                      label={getCampaignStatusText(status)}
                      status={getStatusCode(status)}
                    />
                  </div>
                </div>
              ) : null}
              <div className="col-md-3 col-lg-2 pr-lg-1 py-2">
                <div className="detail">
                  <div className="title">Channels</div>
                  <div className="title-val">
                    {campaignFormatHelper(campaign).getChannelIcons()}
                  </div>
                </div>
              </div>
              <div className="col-md-2  py-2">
                <div className="detail">
                  <div className="title">Start Date</div>
                  <div className="title-val">
                    {campaignFormatHelper(campaign).startDate("MMM DD, YYYY")}
                  </div>
                </div>
              </div>
              <div className="col-md-2  py-2">
                <div className="detail">
                  <div className="title">End Date</div>
                  <div className="title-val">
                    {campaignFormatHelper(campaign).endDate("MMM DD, YYYY")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-lg-0">
            <div className="row my-2">
              <div className="col-lg-7 pl-0 pr-1 mb-1">
                {renderArchivedJoinNowButton ? (
                  renderArchivedJoinNowButton()
                ) : (
                  <JoinNowButton campaign={campaign} />
                )}
              </div>
              <div className="col-lg-5 pl-0 text-right">
                <LearnMoreButton
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/dashboard/learn-more/digital-campaign/${campaign?.id}`,
                    state: { campaignId: campaign?.id, from: "/dashboard" },
                  }}
                  size="medium"
                  className="px-lg-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotYetJoinedCampaignListViewCard;
