import React, { useEffect, useMemo, useState } from "react";
import { Button, Icon, IconButton, Typography } from "@material-ui/core";
import BackLink from "../BackLink";
import RpMUIDialog from "../modals/RpMUIModal";
import { Link } from "react-router-dom";

export default function JoinCampaignPageSubHeader({
  onSaveAsDraft,
  isSubmitting,
  isAdvancedSettingsFormValid,
  selectedTab,
  allLocationsIds = [],
  isPostcardCampaign,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const unsubscribedLocations = document.querySelector(
      ".un-subscribed-locations"
    );

    if (unsubscribedLocations) {
      const disabledDates =
        unsubscribedLocations.querySelectorAll(".rp-date-input-box");

      if (disabledDates?.length) {
        for (let i = 0; i < disabledDates.length; i++) {
          disabledDates[i].addEventListener("mouseover", (e) => {
            e.target.removeAttribute("disabled");
          });
          disabledDates[i].addEventListener("mouseout", (e) => {
            e.target.setAttribute("disabled", true);
          });

          disabledDates[i].addEventListener("click", () => {
            setOpen(true);
          });
        }
      }

      const disabledBudget =
        unsubscribedLocations.querySelectorAll(".location-budget");

      if (disabledBudget?.length) {
        for (let i = 0; i < disabledBudget.length; i++) {
          disabledBudget[i].addEventListener("mouseover", (e) => {
            e.target.removeAttribute("disabled");
          });
          disabledBudget[i].addEventListener("mouseout", (e) => {
            e.target.setAttribute("disabled", true);
          });

          disabledBudget[i].addEventListener("click", () => {
            setOpen(true);
          });
        }
      }

      const handleEvent = (e) => {
        const targetElement = e.target;
        if (!targetElement) return;

        if (
          targetElement.classList.contains("MuiInputBase-input") ||
          targetElement.classList.contains("MuiCheckbox-input") ||
          targetElement.classList.contains("MuiInputAdornment-root") ||
          targetElement.classList.contains("MuiSelect-select") ||
          targetElement.classList.contains("MuiSwitch-track") ||
          targetElement.classList.contains("click-open") ||
          targetElement.classList.contains("MuiListItem-container") ||
          targetElement.classList.contains("MuiInput-root")
        ) {
          setOpen(true);
        }
      };

      if (unsubscribedLocations) {
        unsubscribedLocations.addEventListener("click", handleEvent);
      }

      return () => {
        if (unsubscribedLocations) {
          unsubscribedLocations.removeEventListener("click", () => {});
        }

        if (disabledDates?.length) {
          for (let i = 0; i < disabledDates.length; i++) {
            disabledDates[i].addEventListener("mouseover", () => {});
            disabledDates[i].addEventListener("mouseout", () => {});

            disabledDates[i].addEventListener("click", () => {});
          }
        }

        if (disabledBudget?.length) {
          for (let i = 0; i < disabledBudget.length; i++) {
            disabledBudget[i].addEventListener("mouseover", () => {});
            disabledBudget[i].addEventListener("mouseout", () => {});
            disabledBudget[i].addEventListener("click", () => {});
          }
        }
      };
    }
  }, []);

  const locationCount = useMemo(() => {
    return allLocationsIds?.length || 0;
  }, [allLocationsIds]);

  return (
    <>
      <div
        className={
          isPostcardCampaign
            ? `join-postcard-campaign-sub-header py-1 px-2`
            : `join-campaign-sub-header py-1 px-2`
        }
      >
        <div className="row align-items-center px-3 py-2">
          <div className="col-3 col-md-4 ">
            <BackLink
              navTo={
                isPostcardCampaign
                  ? "/postcard-campaigns"
                  : "/digital-campaigns"
              }
              disabled={isSubmitting}
              aria-disabled={isSubmitting}
            />
          </div>
          {/* <div className="col-9 col-lg-4 my-1 my-lg-auto d-flex-center justify-content-center text22M_Med00000b text-lg-center">
            {campaign_name}
          </div> */}
          <div className="col-md-8 my-1 my-lg-auto float-md-right">
            {locationCount ? (
              <div className="actions d-flex align-items-center justify-content-between justify-content-md-end">
                {selectedTab !== 1 ? (
                  <Button
                    onClick={(e) => {
                      if (selectedTab === 1) {
                        e.stopPropagation();
                        e.preventDefault();
                        if (locationCount) {
                          setOpen(true);
                        }
                      } else {
                        onSaveAsDraft();
                      }
                    }}
                    className="mr-sm-2 w-auto"
                    aria-disabled={isSubmitting}
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="outlined"
                    size="small"
                  >
                    Save for Later
                  </Button>
                ) : null}

                <div>
                  <Button
                    type="submit"
                    className="w-auto px-3"
                    disabled={isSubmitting || !isAdvancedSettingsFormValid}
                    aria-disabled={isSubmitting || !isAdvancedSettingsFormValid}
                    color="primary"
                    variant="contained"
                    size="small"
                    disableElevation
                    onClick={(e) => {
                      if (selectedTab === 1) {
                        e.stopPropagation();
                        e.preventDefault();
                        if (locationCount) {
                          setOpen(true);
                        }
                      }
                    }}
                  >
                    Confirm Selections
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <RpMUIDialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
        <>
          <div className="d-flex justify-content-end">
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              color="inherit"
              className="p-0"
            >
              <Icon fontSize="small">highlight_off</Icon>
            </IconButton>
          </div>
          <div className="text-center">
            <img
              src="/img/portrait-happy-young-colleagues.png"
              alt="alert-icon"
              className="img-fluid"
              width={170}
              height={170}
            />
          </div>
          <div className="container text-center my-3">
            <Typography variant="h6" gutterBottom>
              Looks like this location needs to be subscribed, or renewed, to
              launch this campaign.
            </Typography>
            <Typography variant="body1" className="px-md-5">
              Select a plan for this location now.
            </Typography>
            <div className="row mt-4 px-md-5 justify-content-center">
              <div className="col-md-6">
                <Button
                  component={Link}
                  to="/subscription-packages"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  View Plans
                </Button>
              </div>
            </div>
          </div>
        </>
      </RpMUIDialog>
    </>
  );
}
