import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";

const CampaignConfirmationThankyouPage = () => {
  return (
    <Box
      className="campaign-thankyou-page"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <div className="custom-thank-you-content text-center">
        <img
          className="my-4 ads-img"
          src={`${process.env.PUBLIC_URL}/img/ads.png`}
          alt="Success Icon"
        />
        <Typography color="primary" variant="h4" className="fw-b">
          Thank you for making your selections!
        </Typography>
        <Box className="d-flex align-items-center flex-column my-2 description-box">
          <Typography className="w-75 fw-sb description mt-4">
            Your local advertising campaign is now in motion, helping you
            connect with your community and reach more potential customers
            nearby. We’ll update your campaign based on your selections and
            maximize your impact in your local market.
          </Typography>
          <Typography className="w-75 fw-sb description my-4">
            As we roll out more opportunities for localized advertising, you’ll
            receive an email from us so you can easily join in and continue
            growing your local presence.
          </Typography>
        </Box>
        <Box className="d-flex-center my-1">
          <Button
            component={Link}
            to={`${process.env.PUBLIC_URL}/dashboard`}
            className="thankyou-btn"
            variant="contained"
            color="primary"
            size="large"
          >
            Back to My Dashboard
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default CampaignConfirmationThankyouPage;
