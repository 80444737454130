import { CurrencyFormateText } from "../../../components/CurrencyFormat";

const TotalCalculation = ({ totals }) => {
  return (
    <div className="card mb-2 totals">
      <div className="card-body">
        <div className="row justify-content-end mb-2">
          <div className="col-6 col-lg-3 col-xl-2 title">Media Fee:</div>
          <div className="col-6 col-lg-3 text-right val">
            <CurrencyFormateText
              value={totals.totalMediaAmount}
              suffix=" USD per month"
            />
          </div>
        </div>
        <div className="row justify-content-end mb-2">
          <div className="col-6 col-lg-3 col-xl-2 subs">Fee:</div>
          <div className="col-6 col-lg-3 text-right subs">
            <CurrencyFormateText
              value={totals.totalSubscriptingFee}
              suffix=" USD per month"
            />
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="col-6 col-lg-3 col-xl-2 total_title">
            Total to Pay:
          </div>
          <div className="col-6 col-lg-3 text-right total_val">
            <CurrencyFormateText value={totals.total} suffix=" USD per month" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCalculation;
