import React, { useState, useEffect } from "react";
import MuiSwitchComponent from "../../../../components/muiCustom/buttons/MuiSwitchComponent";
import { TextField, Typography, FormHelperText } from "@material-ui/core";
import TotalCoast from "./mailPostCard/TotalCoast";
import { joinCampaignHelper } from "../../../../helpers/joinCampaignHelper";

export default function BulkDrop({
  isBulkDropInit = false,
  onBulkDropCheck,
  bulkDropQnt = 0,
  onChangeBulkDropQnt,
  bulkDropCost,
  loc,
  initialDisabled,
}) {
  const [isBulkDrop, setIsBulkDrop] = useState(isBulkDropInit);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (onBulkDropCheck && typeof onBulkDropCheck === "function") {
      onBulkDropCheck(isBulkDrop);
    }
  }, [isBulkDrop]);

  const handleSwitchChange = () => {
    setIsBulkDrop((p) => !p);
    setMessage("");
  };

  const handleQuantity = (e) => {
    const newQuantity = parseFloat(e.target.value);
    if (newQuantity && newQuantity < 500) {
      setMessage("Enter a quantity higher than 500");
    } else {
      setMessage("");
    }
    if (onChangeBulkDropQnt && typeof onChangeBulkDropQnt === "function") {
      onChangeBulkDropQnt(newQuantity);
    }
  };

  const handleOnBlur = (e) => {
    const v = Number(e.target.value);
    setMessage("");
    if (v && v < 500) {
      setMessage("Enter a quantity higher than 500");
      if (onChangeBulkDropQnt && typeof onChangeBulkDropQnt === "function") {
        onChangeBulkDropQnt(0);
      }
    }
  };

  return (
    <>
      <div className="card-footer bg-white bulk-upload">
        <div className="card border-0">
          <div className="card-body px-0 py-0">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-8 p-0 d-flex">
                  <div className="row">
                    <div
                      className={`${
                        isBulkDrop ? "col-lg-11" : "col-12"
                      } d-flex  pt-lg-4`}
                    >
                      <MuiSwitchComponent
                        isSwitchChecked={isBulkDrop}
                        handleSwitchChange={handleSwitchChange}
                        label="Bulk Drop (Y/N)"
                        initialDisabled={initialDisabled}
                      />
                      <span className="pt-4">
                        {isBulkDrop && (
                          <>
                            <Typography variant="caption">
                              <label>Address: </label>{" "}
                              {joinCampaignHelper.getLocAddress(loc)}
                            </Typography>
                          </>
                        )}
                      </span>
                    </div>
                    {isBulkDrop && (
                      <>
                        <div className="col-5 ml-3 pl-lg-4">
                          <label className="label">Quantity</label>
                          <TextField
                            name="quantity"
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            placeholder="0.00"
                            value={bulkDropQnt}
                            error={message && bulkDropQnt !== "" ? true : false}
                            // helperText={err}
                            onChange={handleQuantity}
                            onBlur={handleOnBlur}
                          />
                          <FormHelperText>{message}</FormHelperText>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 bulk-cost">
                  <div className="row">
                    <div className="col-md">
                      <TotalCoast label="Bulk Cost" total={bulkDropCost} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
