import { userService } from "../services";
import { logger } from "./loggerHelper";

export const reportUserActivity = async (event = "") => {
  try {
    await userService.userActivity(event);
  } catch (err) {
    logger.error("Error in reportUserActivity", { err });
  }
};
