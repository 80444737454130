import React, { useState, useEffect } from "react";

import DataRangeDisplay from "../../../components/DataRangeDisplay";
import JoinedCampaignCard from "../../../components/joinCampaigns/JoinedCampaignCard";
import NoDataFound from "../../../components/NoDataFound";
import NotAvailable from "../../../components/NotAvailable";
import TablePagination from "../../../components/tables/TablePagination";

export default function JoinedCampaigns({
  joinedCampaigns = [],
  pageCount,
  pageIndex,
  onPageChange,
  recordsTotal,
  isLoading,
}) {
  const [campaignData, setCampaignData] = useState([]);

  useEffect(() => {
    setCampaignData(joinedCampaigns);
  }, [joinedCampaigns]);

  const renderPagination = () => {
    return (
      <div className="row">
        <div className="col-lg-6 mb-2 d-flex align-items-center">
          <DataRangeDisplay totalRecords={recordsTotal} pageIndex={pageIndex} />
        </div>
        <div className="col-6 float-right">
          <TablePagination
            count={pageCount}
            page={pageIndex + 1}
            onChange={onPageChange}
            className="float-right"
          />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <NoDataFound className="my-5" message="Loading..." />;
  }

  return (
    <>
      <div className="joined-campaigns mb-1 pt-3">
        {campaignData.length ? (
          <>
            <div className="row align-items-stretch">
              <div className="col-12 d-none d-lg-block px-2">
                <div className="card border-0 heading-card">
                  <div className="card-body py-4 px-3">
                    <div className="row">
                      <div className="col-lg-2 title">Campaign Name</div>
                      <div className="col-lg title pl-lg-1">Status</div>
                      <div className="col-lg title">Budget</div>
                      <div className="col-lg-1 title">Enrolled Locations</div>
                      <div className="col-lg title">Start Date</div>
                      <div className="col-lg title">End Date</div>
                      <div className="col-lg title">Channels</div>
                      <div className="col-lg-1 title text-left p-0">
                        Actions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {campaignData.map((c) => (
                <div
                  className="col-lg-12 col-md-6 mb-1 mb-lg-auto p-2 py-lg-0"
                  key={c.id}
                >
                  <JoinedCampaignCard campaign={c} />
                </div>
              ))}
            </div>
            {renderPagination()}
          </>
        ) : (
          <NotAvailable />
        )}
      </div>
    </>
  );
}
