import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";

function MuiSwitchComponent({
  label = "",
  isSwitchChecked,
  handleSwitchChange,
  initialDisabled = false,
}) {
  return (
    <FormGroup aria-label="position" row>
      <FormControlLabel
        value="top"
        control={
          <Switch
            value="top"
            checked={isSwitchChecked}
            onChange={handleSwitchChange}
            label={label}
          />
        }
        labelPlacement="top"
        label={label}
        className="postcard-switch align-items-start"
        disabled={initialDisabled}
      />
    </FormGroup>
  );
}

export default MuiSwitchComponent;
