import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, InputAdornment, TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      color: theme.palette.text.primary,
      borderRadius: "4px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.divider}`,
      },
      "& .MuiInputAdornment-root": {
        "& img": {
          filter: "none",
        },
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.text.primary,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
        "& .MuiInputAdornment-root": {
          "& img": {
            filter: "opacity(0.38)",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      "& input::placeholder": {
        color: theme.palette.text.secondary,
        opacity: 1,
      },
    },
  },
  darkTheme: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInputAdornment-root": {
        "& img": {
          filter: "brightness(0) invert(1)",
        },
      },
      "& input::placeholder": {
        color: theme.palette.primary.contrastText,
        opacity: 0.7,
      },
    },
  },
}));

// Custom number format component
const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, decimalScale, maxValue = 100000, value, ...other } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      getInputRef={ref}
      onValueChange={(values) => {
        // Only trigger onChange if the value actually changed
        if (values.value !== value) {
          onChange({
            target: {
              value: values.value,
            },
          });
        }
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      suffix=" USD"
      decimalScale={decimalScale}
      allowNegative={false}
      isAllowed={(values) => {
        const { value, floatValue } = values;
        const numValue = Number(value);
        if (floatValue === 0) {
          // If the value is 0, don't allow it
          return false;
        }
        return (
          !value ||
          (decimalScale === 0
            ? Number.isInteger(numValue) && numValue <= maxValue
            : numValue <= maxValue)
        );
      }}
    />
  );
});

const RpCurrencyField = ({
  value,
  onChange,
  disabled = false,
  error = false,
  helperText,
  placeholder = "$0.00 USD",
  darkTheme = false,
  decimalScale = 2,
  maxValue = 100000,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <TextField
      {...rest}
      className={`${classes.root} ${darkTheme ? classes.darkTheme : ""}`}
      variant="outlined"
      size="small"
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      disabled={disabled}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon component="img" src="/img/icons/money.svg" />
          </InputAdornment>
        ),
        inputComponent: NumberFormatCustom,
        inputProps: {
          decimalScale,
          maxValue,
          value, // Pass value to NumberFormatCustom
        },
      }}
    />
  );
};

export default RpCurrencyField;
