import React, { useState, Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, Button, Typography } from "@material-ui/core";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RpMUIDialog from "../../../../components/modals/RpMUIModal";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  listIcon: {
    minWidth: "auto",
  },
}));

const useCreativeOptionStyles = makeStyles((theme) => ({
  container: {
    paddingRight: 55,
    marginBottom: 5,
  },
  root: {
    border: "1px solid #c1c1c1",
    borderRadius: theme.shape.borderRadius,
  },
  selected: {
    background: `${theme.palette.primary.main}!important`,
    color: `${theme.palette.primary.contrastText}!important`,
  },
}));

export default function CreativeOptions({
  creativesOptions = [],
  initiallySelected,
  currentInView,
  onClickView,
  onSelect,
  creative_select_flag,
  creative_select_max_value,
  initialDisabled,
}) {
  const classes = useStyles();
  const optionClasses = useCreativeOptionStyles();
  const [checked, setChecked] = useState(initiallySelected);
  const [showMaxMsg, setShowMaxMsg] = useState(false);

  const handleSelectCreative = (v) => {
    let newChecked = [...checked];
    if (creative_select_flag) {
      // for multi select
      newChecked = [...checked];
      const idx = checked.indexOf(v);
      if (idx === -1) {
        newChecked.push(v);
        setChecked(newChecked);
      } else {
        newChecked.splice(idx, 1);
        setChecked(newChecked);
      }
    } else {
      // for single select
      newChecked = [v];
      const idx = checked.indexOf(v);
      if (idx !== -1) {
        newChecked = [];
      } else {
        // only works when selected
        onClickView(v); // show clicked creative images
      }
      setChecked(newChecked);
    }
    // update back

    onSelect(newChecked);

    onClickView(v);
  };

  const isMaxSelected = () => {
    if (creative_select_flag && checked?.length === creative_select_max_value) {
      return true;
    }
    return false;
  };

  const isSelectedOption = (v) => {
    return checked.length ? checked.indexOf(v) !== -1 : false;
  };

  return (
    <div className="creative-options">
      {/* <div className="pl-3 ml-1 py-0 my-2"></div> */}
      {/* <Typography component="p" variant="body2">
        {`Select ${
          creative_select_flag ? `up to ${creative_select_max_value}` : "One"
        }`}
      </Typography> */}
      <List className={classes.root}>
        {creativesOptions.map((c) => {
          const isSelected = isSelectedOption(c.value);
          return (
            <Fragment key={c.value}>
              <ListItem
                dense
                button
                classes={optionClasses}
                onClick={() => {
                  if (initialDisabled) {
                    return;
                  } else if (!isSelected && isMaxSelected()) {
                    setShowMaxMsg(true);
                  } else {
                    handleSelectCreative(c.value);
                  }
                }}
                className={isSelected ? "selected" : ""}
                // style={{ paddingRight: 55 }}
                selected={isSelected}
                disabled={initialDisabled}
              >
                <ListItemIcon className={classes.listIcon}>
                  <Checkbox
                    edge="start"
                    checked={isSelected}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": `checkbox-list-label-${c.value}`,
                    }}
                    icon={
                      <RadioButtonUncheckedOutlinedIcon
                        color="primary"
                        fontSize="small"
                      />
                    }
                    checkedIcon={
                      <CheckCircleIcon
                        className="checked-icon"
                        fontSize="small"
                      />
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  id={`checkbox-list-label-${c.value}`}
                  primary={c.label}
                  className="creative-option-label"
                />

                <ListItemSecondaryAction style={{ right: 0 }}>
                  <Button
                    onClick={() => onClickView(c.value)}
                    variant="text"
                    color={currentInView === c.value ? "primary" : "default"}
                    size="small"
                    className="fs-9 px-0"
                  >
                    <span
                      className={
                        currentInView === c.value
                          ? "font-weight-bolder text-underline"
                          : ""
                      }
                    >
                      Preview
                    </span>
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </Fragment>
          );
        })}
      </List>

      <RpMUIDialog
        open={showMaxMsg}
        onClose={() => setShowMaxMsg(false)}
        maxWidth="xs"
        persists
      >
        <div className="px-3">
          <div className="text-center mb-2">
            <InfoOutlined color="error" fontSize="large" />
          </div>
          <Typography>
            You've selected the max amount of creatives, to select another
            please unselect one of your currently selected creatives.
          </Typography>
          <div className="text-center mt-3">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowMaxMsg(false)}
            >
              OK
            </Button>
          </div>
        </div>
      </RpMUIDialog>
    </div>
  );
}
