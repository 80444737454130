import { useEffect, useMemo } from "react";
import { Field, useFormikContext } from "formik";
import FormikMUIAutocompleteSelectInput from "../../../components/muiCustom/select/FormikMUIAutocompleteSelectInput";
import { MSPackagesData } from "../../../helpers/staticData";

const TIER_MATCH_KEY = MSPackagesData?.[0]?.tier;

export default function DurationField() {
  const { values, setFieldValue } = useFormikContext();

  const { tier } = values;

  const durationOptions = useMemo(() => {
    const options = [
      {
        label: "6 Months",
        value: "6",
      },
      {
        label: "12 Months",
        value: "12",
      },
    ];
    if (tier && tier === TIER_MATCH_KEY) {
      options.shift();
      return options;
    }
    return options;
  }, [tier]);

  useEffect(() => {
    if (tier && tier === TIER_MATCH_KEY) {
      setFieldValue("duration", "12");
    }
  }, [tier, setFieldValue]);

  return (
    <Field
      name="duration"
      component={FormikMUIAutocompleteSelectInput}
      options={durationOptions}
    />
  );
}
