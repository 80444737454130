import { Icon, InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";

function TotalCoast({ total = 0, label = "" }) {
  return (
    <div className="location-budget-box">
      <div className="location-budget">
        {label && <label className="label">{label}</label>}
        <TextField
          name="mailingCost"
          id="mailingCost"
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: (
              <InputAdornment position="start">
                <Icon component="img" src="/img/icons/money1.png" />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
          autoComplete="off"
          placeholder="0.00"
          value={Number(total).toFixed(2)}
          disabled
        />
      </div>
    </div>
  );
}

export default TotalCoast;

const MAX_SPEND_BUDGET = 1000000;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.floatValue);
      }}
      thousandSeparator
      decimalScale={2}
      allowNegative={false}
      prefix="$"
      suffix=" USD"
      isAllowed={({ floatValue }) =>
        floatValue > MAX_SPEND_BUDGET ? false : true
      }
    />
  );
}
