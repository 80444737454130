import React, { useMemo, useCallback, useState } from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  Paper,
  TextField,
  withStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTheme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const CustomAutocomplete = withStyles((theme) => ({
  root: {
    zIndex: 1,
    borderRadius: theme.shape.borderRadius,
    marginTop: 19,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "4px 0px 3px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover, &:focus, &:focused": {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.primary.main,
    },
  },
  option: {
    fontSize: ".8rem",
    transition: theme.transitions.create([
      "border-color",
      "box-shadow",
      "background-color",
      "color",
    ]),
    "&:hover": {
      fontWeight: 600,
      backgroundColor: theme.palette.action.hover,
    },
    "&$focused, &$selected": {
      backgroundColor: theme.palette.action.selected,
    },
    "&$selected": {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  focused: {},
  selected: {},
  input: { fontSize: ".8rem" },
  popupIndicator: { color: "inherit" },
  popper: { boxShadow: "0px 5px 20px #0000001F" },
  paper: { boxShadow: "none" },
  noOptions: {
    fontSize: ".8rem",
    fontWeight: 600,
    padding: "10px 16px 5px",
  },
}))(Autocomplete);

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  label: {
    fontWeight: 600,
    color: "#1B262C",
    fontSize: ".92rem",
  },
}));

export function MUICustomAutocompleteSelect({
  label = "",
  name = "select",
  selectProps = {},
  labelProps = {},
  options = [],
  wrapperProps = {},
  all,
  allText = "All",
  isOutlined = false,
  hideLabel = false,
  placeholder = "Search..",
  ...rest
}) {
  const [inputValue, setInputValue] = useState("");
  const theme = useTheme();
  const classes = useStyles();

  const dropdownOptions = useMemo(
    () => (all ? [{ label: allText, value: "" }, ...options] : options),
    [options, all, allText]
  );

  const selectedValue = useMemo(() => {
    const v = dropdownOptions.find((o) => o.value === selectProps.value);
    return !v && all ? { label: allText, value: "" } : v;
  }, [selectProps.value, dropdownOptions, all, allText]);

  const handleChange = useCallback(
    (event, newValue) => selectProps.onChange?.(newValue?.value),
    [selectProps]
  );

  const handleInputChange = useCallback((event, newInputValue) => {
    setInputValue(newInputValue);
  }, []);

  const getStyles = useCallback(
    () => ({
      autocomplete: {
        background: rest.disabled
          ? "#eee"
          : isOutlined
            ? "transparent"
            : theme.palette.primary.main,
        color: rest.disabled
          ? "#c1c1c1"
          : isOutlined
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        border: "none",
      },
      input: {
        color: "inherit",
        border: "none",
      },
      popupIndicator: {
        color: rest.disabled
          ? "#c1c1c1"
          : isOutlined
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
      },
    }),
    [rest.disabled, isOutlined, theme]
  );

  const styles = getStyles();

  return (
    <Paper
      elevation={0}
      className="px-2"
      classes={{ root: classes.wrapper }}
      {...wrapperProps}
    >
      <FormControl fullWidth margin="dense" className="my-0">
        {!hideLabel && (
          <InputLabel
            shrink
            id={`${name}-label`}
            classes={{ root: classes.label }}
            {...labelProps}
            margin="dense"
            disableAnimation
            required={rest?.required}
          >
            {label}
          </InputLabel>
        )}
        <CustomAutocomplete
          {...rest}
          style={styles.autocomplete}
          disableClearable
          openOnFocus
          id={name}
          name={name}
          fullWidth
          value={selectedValue}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          options={dropdownOptions}
          getOptionLabel={(option) => option?.label || ""}
          getOptionSelected={(option, value) => option?.value === value?.value}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                style: styles.input,
              }}
            />
          )}
          popupIcon={<ArrowDropDownIcon style={styles.popupIndicator} />}
        />
      </FormControl>
    </Paper>
  );
}
