import React, { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Tab,
  Tabs,
  withStyles,
  IconButton,
  Icon,
  Typography,
} from "@material-ui/core";
import NotAvailable from "../../../../components/NotAvailable";
import CampaignList from "./CampaignList";
import NoDataFound from "../../../../components/NoDataFound";

const RpTabs = withStyles(() => ({
  root: {
    minWidth: "auto",
    minHeight: "auto",
    width: "auto",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const RpTab = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    minWidth: "auto",
    minHeight: "auto",
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    margin: "auto 5px",
    fontSize: ".7rem",
    fontWeight: 600,
    textTransform: "none",
    opacity: 1,
    paddingTop: 7,
    paddingBottom: 4,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}))(Tab);

const RpGridListButton = withStyles(() => ({
  label: {
    borderRadius: 3,
    border: 0,
  },
}))(IconButton);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`campaigns-tabpanel-${index}`}
      aria-labelledby={`campaigns-tab-${index}`}
      {...other}
      className="rp-campaign-tab"
    >
      {children}
    </div>
  );
}

const CampaignAvailableToJoin = ({
  getCampaigns = [],
  tabsCounts,
  selectedTab,
  handleTabChange,
  pageCount,
  onPageChange,
  currentPage,
  isLoading,
}) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  const [viewType, setViewType] = useState("grid");

  const [previousTabsCounts, setPreviousTabsCounts] = useState(tabsCounts);

  useEffect(() => {
    if (isMobileOrTablet) {
      setViewType("grid");
    }
  }, [isMobileOrTablet]);

  useEffect(() => {
    if (!isLoading) {
      setPreviousTabsCounts(tabsCounts);
    }
  }, [tabsCounts, isLoading]);

  const renderCampaigns = (campaigns) => {
    if (isLoading) {
      return <NoDataFound className="my-5" message="Loading..." />;
    }
    if (!campaigns || campaigns.length === 0) {
      return <NotAvailable className="my-5" />;
    }
    return (
      <CampaignList
        campaigns={campaigns}
        onArchived={getCampaigns}
        viewType={viewType}
        pageCount={pageCount}
        currentPage={currentPage}
        isLoading={isLoading}
        onPageChange={onPageChange}
      />
    );
  };

  const renderLimitedTimeCampaigns = useMemo(
    () => renderCampaigns(getCampaigns),
    [getCampaigns, isLoading, viewType, pageCount, currentPage]
  );

  const renderAlwaysAvailableCampaigns = useMemo(
    () => renderCampaigns(getCampaigns),
    [getCampaigns, isLoading, viewType, pageCount, currentPage]
  );

  const renderDraftCampaigns = useMemo(
    () => renderCampaigns(getCampaigns),
    [getCampaigns, isLoading, viewType, pageCount, currentPage]
  );

  return (
    <div className="post-card-campaign-available">
      <div className="row justify-content-end align-items-center mt-3">
        <div className="col">
          <Typography
            variant="h5"
            className="widget-title mb-4 mb-4 mb-md-auto"
          >
            Direct Mail
          </Typography>
        </div>
        <div className="col-md">
          <div className="heading14 main-color text-right d-flex align-items-center justify-content-lg-end">
            <div>
              <RpTabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="off"
              >
                <RpTab
                  label={`Limited Time Offers (${previousTabsCounts?.limited_time_offers})`}
                  icon={
                    <Icon fontSize="inherit" className="mb-0 mr-1">
                      dashboard
                    </Icon>
                  }
                />
                <RpTab
                  label={`Always Available (${previousTabsCounts?.always_available})`}
                  icon={
                    <Icon fontSize="inherit" className="mb-0 mr-1">
                      dashboard
                    </Icon>
                  }
                />
                <RpTab
                  label={`Drafts (${previousTabsCounts?.draft})`}
                  icon={
                    <Icon fontSize="inherit" className="mb-0 mr-1">
                      dashboard
                    </Icon>
                  }
                />
              </RpTabs>
            </div>
            <div className="ml-2 align-items-center d-none d-lg-flex">
              <RpGridListButton onClick={() => setViewType("list")}>
                <Icon
                  fontSize="small"
                  component="img"
                  src={`/img/icons/${
                    viewType === "list" ? "list_view" : "list_view_outlined"
                  }.png`}
                  alt="Grid"
                />
              </RpGridListButton>
              <RpGridListButton onClick={() => setViewType("grid")}>
                <Icon
                  fontSize="small"
                  component="img"
                  src={`/img/icons/${
                    viewType === "grid" ? "grid_view" : "grid_view_outlined"
                  }.png`}
                  alt="Grid"
                />
              </RpGridListButton>
            </div>
          </div>
        </div>
      </div>
      <div>
        <TabPanel value={selectedTab} index={0}>
          <div className="yet-to-join-campaigns">
            {renderLimitedTimeCampaigns}
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <div className="yet-to-join-campaigns">
            {renderAlwaysAvailableCampaigns}
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <div className="yet-to-join-campaigns">{renderDraftCampaigns}</div>
        </TabPanel>
      </div>
    </div>
  );
};

export default CampaignAvailableToJoin;
