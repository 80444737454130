import { useContext, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress, Typography } from "@material-ui/core";

import { stripeMicroService } from "../../services/stripe-microservice/stripeMicroService";
import { LoaderContext } from "../../helpers/contexts/contexts";
// import { campaignActions } from "../../actions/campaignActions";

function JoinCampaignPaymentReturnPage() {
  const [status, setStatus] = useState(null);
  const loader = useContext(LoaderContext);

  const history = useHistory();
  // const dispatch = useDispatch();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const success = urlParams.get("success");
    const sessionId = urlParams.get("session_id");

    async function confirmPayment(sessionId) {
      loader(true);
      try {
        const res = await stripeMicroService.joinCampaignPaymentConfirm(
          sessionId
        );

        setStatus(res.data?.result?.data?.status || "");
      } catch (error) {
        setStatus("");
      } finally {
        loader(false);
      }
    }
    if (success === "true" && sessionId) {
      confirmPayment(sessionId);
    }
  }, [loader]);

  if (status && status === "complete") {
    // dispatch(campaignActions.setSaveDraftSuccess(true));
    history.push("/dashboard/thank-you", { from: "join_campaign_confirm" });
  }

  if (status && status !== "complete") {
    return (
      <div className="text-center">
        <Typography variant="body1">Payment Status: {status}</Typography>
      </div>
    );
  }

  if (status === "") {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className="d-flex-center"
      >
        <Typography variant="body1">Something went wrong!</Typography>
      </div>
    );
  }

  return (
    <div style={{ height: "100vh", width: "100vw" }} className="d-flex-center">
      <div className="text-center">
        <div>
          <CircularProgress size={20} />
        </div>
        <Typography variant="caption">Please wait...</Typography>
      </div>
    </div>
  );
}

export default JoinCampaignPaymentReturnPage;
