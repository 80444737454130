import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, Formik } from "formik";
import NavigationPrompt from "react-router-navigation-prompt";
import { Button, Icon, InputAdornment } from "@material-ui/core";
import { Cancel as CancelIcon } from "@material-ui/icons";
import ImageRP from "../../components/ImageRP";
import { normalizePhone } from "../../helpers/normalizers";
import { validateProfile } from "../../helpers/validations";
import { isLoadingSelector } from "../../selectors/authSelectors";
import {
  isErrorSelector,
  isUserUpdatedSelector,
  userSelector,
} from "../../selectors/userSelector";
import { userActions } from "../../actions";
import { Fragment } from "react";
import { getBase64 } from "../../helpers/utils";
import {
  imageUploadFieldFormik,
  phoneMDInputFormik,
  textMDInputFormik,
} from "../../helpers/formInputs";
import ModalBootstrap from "../../components/ModalBootstrap";

const ProfileEdit = ({
  initialValues,
  loggedInUser,
  loggedInUserRole,
  setEdit,
  isLoading,
  isUserUpdated,
  updateUser,
  getUser,
  loader,
  brand = {},
}) => {
  /** Image preview state */
  const [imagePreview, setImagePreview] = useState(null);
  const [logoImagePreview, setLogoImagePreview] = useState();

  useEffect(() => {
    loader(isLoading);
  }, [isLoading, loader]);

  useEffect(() => {
    if (isUserUpdated) {
      getUser(loggedInUser);
      setEdit(false);
    }
  }, [isUserUpdated, setEdit, getUser, loggedInUser]);

  /**
   * @method handleOnChangeImage
   * @description on change image input update imagePreview state to show image preview
   * @param {*} file
   */
  const handleOnChangeImage = async (file) => {
    if (!file) {
      setImagePreview(null);
      return;
    }
    const base64Image = await getBase64(file);
    setImagePreview(base64Image);
  };

  /**
   * @method handleOnChangeLogoImage
   * @description on change image input update imagePreview state to show image preview
   * @param {*} file
   */
  const handleOnChangeLogoImage = async (file) => {
    if (!file) {
      setLogoImagePreview(null);
      return;
    }
    const base64Image = await getBase64(file);
    setLogoImagePreview(base64Image);
  };

  /**
   * @method onSubmitForm
   * @description handle on submit
   * @param {*} values
   */
  const onSubmitForm = async (values) => {
    let userData = {
      id: loggedInUser.logedInUser ? loggedInUser.logedInUser : loggedInUser.id,
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      mobile_no: values.mobile_no.replace(/-/g, ""),
      fk_role_id: values.fk_role_id,
      status: 1,
      is_deleted: 0,
      profile_name: values.profile_name,
      profile_base: "",
      logo_name: values.logo_name,
      logo_base: "",
      password: "",
    };

    if (values.profile_image) {
      const imageName = values.profile_image.name.split(".");
      imageName.pop();
      userData = {
        ...userData,
        profile_name: imageName.join(),
        profile_base: await getBase64(values.profile_image),
      };
    }

    if (values.logo_image) {
      const imageName = values.logo_image.name.split(".");
      imageName.pop();
      userData = {
        ...userData,
        logo_name: imageName.join(),
        logo_base: await getBase64(values.logo_image),
      };
    }

    updateUser(userData);
  };
  return (
    <Formik
      initialValues={initialValues || {}}
      enableReinitialize={true}
      onSubmit={onSubmitForm}
      validate={validateProfile}
    >
      {({
        handleSubmit,
        values,
        isSubmitting,
        dirty,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Fragment>
          <form onSubmit={handleSubmit}>
            <div
              className={`card profile-card ${loggedInUserRole === "PR" ? "profile-card-border" : ""}`}
            >
              <div className="card-header profile-card-header">
                <div className="profile-image-box w-100">
                  <div className="profile-image-container">
                    <ImageRP
                      className="profile-image"
                      src={imagePreview ? imagePreview : values.profile_name}
                      type={imagePreview ? "BASE64" : "PROFILE"}
                    />
                    {imagePreview && (
                      <div className="cancel-icon-box">
                        <CancelIcon
                          onClick={() => {
                            setTimeout(() => {
                              setFieldValue("profile_image", "");
                              setFieldTouched("profile_image", false);
                            }, 100);
                            setImagePreview(null);
                          }}
                          className="close-icon"
                          title="Remove"
                        />
                      </div>
                    )}
                  </div>
                  <Field
                    name="profile_image"
                    component={imageUploadFieldFormik}
                    onChangeImage={handleOnChangeImage}
                    errorClass="text-center"
                    hint="Max 2MB only"
                  />
                </div>
              </div>

              <div className="card-body text-center profile-content">
                {/* {loggedInUserRole === "PR" && (
                  <div className="user-id-box mb-2">
                    <div className="id">
                      {`Unique ID : ${loggedInUser?.participantData?.system_generated_participant_id}`}
                    </div>
                  </div>
                )} */}
                {loggedInUserRole === "PR" && (
                  <div className="mb-3">
                    {brand?.brand_name ? brand?.brand_name : ""}
                  </div>
                )}
                <h2 className="user-name mb-0">
                  <Field
                    label="First Name"
                    name="first_name"
                    component={textMDInputFormik}
                    className="user-name"
                    formGroupClass="mb-0 py-0"
                    size="small"
                  />
                  <Field
                    label="Last Name"
                    name="last_name"
                    component={textMDInputFormik}
                    className="user-name"
                    formGroupClass="mb-0 py-0"
                    size="small"
                  />
                </h2>
                <div className="user-email mb-0">
                  <Field
                    label="Email"
                    name="email"
                    component={textMDInputFormik}
                    formGroupClass="py-0 mb-0 w-100"
                    disabled={true}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon
                            className="icon"
                            fontSize="small"
                            component="img"
                            src="/img/icons/mail.png"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="user-phone mb-0">
                  <Field
                    label="Phone"
                    name="mobile_no"
                    component={phoneMDInputFormik}
                    formGroupClass="py-0 mb-0 w-100"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon
                            className="icon"
                            fontSize="small"
                            component="img"
                            src="/img/icons/phone.png"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {loggedInUserRole !== "PR" && (
                  <div className="brand-logo-box">
                    <h6 className="brand-logo-title">
                      {loggedInUserRole === "PC"
                        ? "Company Logo"
                        : "Brand Logo"}
                    </h6>
                    <div className="brand-logo-container">
                      <ImageRP
                        className="brand-logo"
                        src={
                          logoImagePreview ? logoImagePreview : values.logo_name
                        }
                        type={
                          logoImagePreview
                            ? "BASE64"
                            : loggedInUserRole === "PC"
                              ? "COMPANY"
                              : "BRAND"
                        }
                      />
                      {logoImagePreview && (
                        <div className="cancel-icon-box">
                          <CancelIcon
                            onClick={() => {
                              setTimeout(() => {
                                setFieldValue("logo_image", "");
                                setFieldTouched("logo_image", false);
                              }, 100);
                              setLogoImagePreview(null);
                            }}
                            className="close-icon"
                            title="Remove"
                          />
                        </div>
                      )}
                    </div>
                    <Field
                      name="logo_image"
                      component={imageUploadFieldFormik}
                      onChangeImage={handleOnChangeLogoImage}
                      errorClass="my-0 text-center"
                      hintClass="mt-0"
                      hint="Max 2MB only"
                    />
                  </div>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!dirty || isSubmitting}
                  fullWidth
                  className="action-btn"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setEdit(false)}
                  disabled={isSubmitting}
                  fullWidth
                  className="action-btn"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
          <NavigationPrompt when={dirty && !isUserUpdated}>
            {({ onConfirm, onCancel }) => (
              <ModalBootstrap
                show={true}
                size="sm"
                bodyClass="py-0"
                onClose={onCancel}
                onOk={onConfirm}
                headerStyles={{ display: "none" }}
                footer={
                  <div className="d-flex align-items-center">
                    <button
                      className="button secondary mr-1 small fs-14"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="button primary w-auto px-4 fs-14"
                      onClick={onConfirm}
                    >
                      Leave Anyway
                    </button>
                  </div>
                }
                footerStyles={{ border: 0 }}
              >
                <h4 className="heading61 text-center">
                  Changes made will be lost.
                </h4>
              </ModalBootstrap>
            )}
          </NavigationPrompt>
        </Fragment>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  const user = userSelector(state);
  const initialValues = {
    ...user,
    mobile_no: normalizePhone(user.mobile_no),
    profile_image: "",
  };
  return {
    initialValues,
    isLoading: isLoadingSelector(state),
    isError: isErrorSelector(state),
    isUserUpdated: isUserUpdatedSelector(state),
  };
};

export default connect(mapStateToProps, {
  getUser: userActions.getUser,
  updateUser: userActions.updateUser,
})(ProfileEdit);
