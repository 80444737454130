import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import PageLayout from "../components/layouts/page/PageLayout";
import RequestFromBrand from "../containers/request/brandRequest";
import { GlobalContext } from "../helpers/contexts/contexts";

function RequestFromBrandPage() {
  const { selectedBrand } = useContext(GlobalContext);

  if (!selectedBrand?.is_brand_request) {
    return <Redirect path="/" />;
  }

  return (
    <>
      <PageLayout>
        <RequestFromBrand />
      </PageLayout>
    </>
  );
}

export default RequestFromBrandPage;
