import React, { useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { GlobalContext, LoaderContext } from "../helpers/contexts/contexts";
import useFetcher from "../helpers/hooks/useFetcher";
import { Typography } from "@material-ui/core";

import PostcardCampaignJoined from "../containers/postcardCampaign/widgets/postcardCampaignJoined";
import PageLayout from "../components/layouts/page/PageLayout";
import { campaignService } from "../services";
import MuiSearchInput from "../components/muiCustom/search/MuiSearchInput";

const PostcardReportsList = () => {
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const [filters, setFilters] = useState({
    pageIndex: 0,
    searchKey: "",
    sortBy: "",
    sortByDir: "",
  });

  const reqData = useMemo(() => {
    return {
      id: selectedBrand?.id,
      userId: loggedInUser.id,
      page: filters?.pageIndex + 1,
      searchKey: filters?.searchKey || "",
      sortBy: filters?.sortBy || "",
      sortByDir: filters?.sortByDir || "",
      fk_participant_id: loggedInUser.participantData.id,
      campaignCat: "Postcard",
    };
  }, [selectedBrand?.id, loggedInUser.participantData.id, filters]);

  const { data, isLoading, isValidating } = useFetcher(
    reqData,
    campaignService.postcardReportListing,
    { revalidateOnFocus: false }
  );

  const handlePageChange = (event, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      pageIndex: value - 1,
    }));
  };
  const handleSearchChange = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchKey: value,
      pageIndex: 0,
    }));
  };

  const postcardCampaignData = data?.joinedCampaign ? data?.joinedCampaign : [];
  const pageCount = data?.pageCount || 0;
  const recordsTotal = data?.recordsTotal || 0;

  useEffect(() => {
    loader(isLoading || isValidating);
  }, [isLoading, loader, isValidating]);

  const renderPage = () => {
    return (
      <>
        <div className="dashboard participant-dashboard">
          <Typography variant="h5" className="widget-title mb-4 mb-md-auto">
            Direct Mail Reports
          </Typography>
          <div className="row">
            <div className="col-lg-4 offset-lg-8">
              <div className="search-container">
                <MuiSearchInput
                  value={filters.searchKey}
                  onChange={handleSearchChange}
                  name="searchKey"
                  id="searchKey"
                />
              </div>
            </div>
          </div>
          <PostcardCampaignJoined
            joinedCampaigns={postcardCampaignData || []}
            pageCount={pageCount}
            pageIndex={filters.pageIndex}
            onPageChange={handlePageChange}
            recordsTotal={recordsTotal}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  };
  return (
    <>
      <PageLayout className="participant-dashboard-container mx-0 mx-sm-2">
        {renderPage()}
      </PageLayout>
    </>
  );
};

export default PostcardReportsList;
