import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { isEmpty, uniqBy } from "lodash";

import { MSActivationBriefFormValidationSchema } from "../../../helpers/validations";
import MSActivationBriefForm from "./MSActivationBriefForm";
import { morganStanleyService } from "../../../services/morganStanleyService";
import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import { MSPackagesData } from "../../../helpers/staticData";
import { RpConfirmDialog } from "../../../helpers/utils";
import RpConfirm from "../../../components/RpConfirm";
import { locationService } from "../../../services";
import RpSnackbar from "../../../components/alerts/RpSnackbar";
import { userActions } from "../../../actions";
import { logger } from "../../../helpers/loggerHelper";

export default function MSActivationBrief() {
  const history = useHistory();
  const { locID } = useParams();
  const dispatch = useDispatch();

  const loader = useContext(LoaderContext);
  const { loggedInUser, loggedInUserRole, selectedBrand } =
    useContext(GlobalContext);

  const [msStaticData, setMSStaticData] = useState({});
  const [locationInfo, setLocationInfo] = useState({});
  const [briefDetail, setBriefDetail] = useState(null);
  const [formError, setFormError] = useState({});

  const fetchMSStaticData = useCallback(async () => {
    loader(true);
    try {
      const res = await morganStanleyService.getMSStaticData();
      let locRes;
      let briefRes;
      if (locID) {
        locRes = await locationService.getLocationDetails(locID);
        //briefRes = await morganStanleyService.getBriefDetails(locID);
      }
      if (locID && briefRes?.status === 200) {
        setBriefDetail(briefRes?.data || null);
      }
      if (res.status === 200) {
        setMSStaticData(res?.data || {});
      }
      if (locID && locRes?.status === 200) {
        setLocationInfo(locRes?.data || {});
      }
    } catch (err) {
      logger.error("fetchMSStaticData error", err);
    } finally {
      loader(false);
    }
  }, [loader, locID]);

  useEffect(() => {
    dispatch(userActions.getUser(loggedInUser));
    fetchMSStaticData();
  }, [fetchMSStaticData, dispatch, loggedInUser?.id]);

  const checkForMSIDAssociationWithEmail = useCallback(
    async ({ email, msid, index, setFieldError, setSubmitting }) => {
      try {
        if (email && msid) {
          const res = await morganStanleyService.checkValidationMsid({
            email: email || "",
            msid: msid || "",
          });
          if (res.status === 200 && !res.data) {
            setFieldError(
              `advisorDetail[${index}].msid`,
              "This MSID already associated with another email"
            );
            setSubmitting(false);

            return false;
          }
        }
        return true;
      } catch (err) {
        logger.error("checkForMSIDAssociationWithEmail error", err);
      }
    },
    []
  );

  const handleFormSubmit = (values, actions) => {
    setFormError({});

    const uniqMSID = uniqBy(values.advisorDetail, "msid");
    const uniqEmail = uniqBy(values.advisorDetail, "email");

    if (
      values.team_or_individual === "Team" &&
      uniqMSID.length < values.advisorDetail.length
    ) {
      // msid is duplicate
      setFormError({
        message: `You have entered duplicate MSID for advisor`,
      });
      actions.setSubmitting(false);
      return;
    }
    if (
      values.team_or_individual === "Team" &&
      uniqEmail.length < values.advisorDetail.length
    ) {
      // email is duplicate
      setFormError({
        message: `You have entered duplicate email for advisor`,
      });
      actions.setSubmitting(false);
      return;
    }

    // Check for MSID association with the given Email ID
    Promise.all(
      values.advisorDetail.map(async (a, i) => {
        let isERR = await checkForMSIDAssociationWithEmail({
          email: a.email,
          msid: a.msid,
          index: i,
          setFieldError: actions.setFieldError,
          setSubmitting: actions.setSubmitting,
        });

        return isERR ? Promise.resolve(isERR) : Promise.reject(isERR);
      })
    )
      .then(() => {
        RpConfirmDialog(
          <RpConfirm message="You want to save now?" />,
          () => {
            saveBrief();
          },
          () => {
            actions.setSubmitting(false);
            return;
          }
        );
      })
      .catch(() => {
        setFormError({
          message: `There are some errors. Please re-check the form`,
        });
      });

    async function saveBrief() {
      loader(true);
      try {
        const packageInfo = MSPackagesData.find((p) => p.tier === values?.tier);

        const reqData = {
          fk_brand_id: 1,
          system_generated_brand_id: "BD919943",
          brand_name: selectedBrand?.brand_name,
          ...values,
          amount: packageInfo?.price || "",
          extra_charge: 0,
          description: packageInfo?.description || "",
          is_recommended: packageInfo?.recommended || false,
          locationId: locID || "",
        };

        const res = await morganStanleyService.saveActionBrief(reqData);

        if (res.status === 200) {
          history.replace({
            pathname: "/dashboard/thank-you",
            state: {
              from: "ms_activation_brief",
            },
          });
        }
      } catch (err) {
        logger.error("saveBrief error", err);
      } finally {
        loader(false);
      }
    }
  };

  const initialValues = useMemo(() => {
    let initialFormData = {
      team_or_individual: "Individual",
      advisorDetail: [
        {
          first_name: "",
          last_name: "",
          email: "",
          msid: "",
        },
      ],
      approved_team_name: "",
      functional_title: "",
      line_of_business: "",
      area_of_focus: [],
      other_area_of_focus: "",
      street_in_your_area: "",
      state_registered_in: [],
      street_address: "",
      city: "",
      state: "",
      zip_code: "",
      phone: "",
      radius: "",
      landing_page_url: "",
      email_list: [],
      tier: MSPackagesData?.[0]?.tier || "",
      duration: "12",
    };

    if (briefDetail) {
      // get saved brief
      initialFormData = {
        ...initialFormData,
        ...briefDetail,
      };

      return initialFormData;
    } else if (loggedInUserRole === "PR") {
      // when participant
      const {
        email,
        first_name,
        last_name,
        participantData,
        // partLocationData,
      } = loggedInUser;
      const brand_define_participant_id =
        participantData?.brand_define_participant_id || "";

      initialFormData["advisorDetail"] = [
        {
          first_name: first_name || "",
          last_name: last_name || "",
          email: email || "",
          msid: brand_define_participant_id || "",
        },
      ];
    } else {
      const { participantData } = locationInfo;

      if (participantData?.[0]) {
        const { user_email, msid, first_name, last_name } =
          participantData?.[0] || {};
        initialFormData["advisorDetail"] = [
          {
            first_name: first_name || "",
            last_name: last_name || "",
            email: user_email || "",
            msid: msid || "",
          },
        ];
      }
    }

    const {
      location_name,
      street_address,
      landing_page_url,
      radius,
      city,
      zip_code,
    } = locationInfo;

    initialFormData.approved_team_name = location_name || "";
    initialFormData.street_address = street_address || "";
    initialFormData.landing_page_url = landing_page_url || "";
    initialFormData.radius = radius || "";
    initialFormData.city = city || "";
    initialFormData.zip_code = zip_code || "";

    return initialFormData;
  }, [loggedInUser, loggedInUserRole, locationInfo, briefDetail]);

  const shouldDisableMSID = useMemo(() => {
    if (loggedInUserRole === "PR") {
      const { participantData } = loggedInUser;
      const brand_define_participant_id =
        participantData?.brand_define_participant_id || "";
      return brand_define_participant_id ? true : false;
    } else {
      const { participantData } = locationInfo;

      if (participantData?.[0]) {
        const { msid } = participantData?.[0] || {};
        return msid ? true : false;
      }
      return false;
    }
  }, [locationInfo, loggedInUserRole, loggedInUser]);

  return (
    <div className="ms-activation-brief">
      <div className="text-center my-4">
        <h2 className="main-heading">
          1. Activation Brief for Financial Advisor Participation
        </h2>
        <div className="description">
          <p>
            Name will be used as entered for public-facing advertisements, so
            keep spelling and capitalization consistent. The website address
            will be the URL that all traffic is sent to.
          </p>
          <p>
            Team Name must be complete, approved name, including “at Morgan
            Stanley”
          </p>
        </div>
      </div>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={MSActivationBriefFormValidationSchema}
        enableReinitialize
      >
        <MSActivationBriefForm
          msStaticData={msStaticData}
          isNewSubmission={locID}
          shouldDisableMSID={shouldDisableMSID}
        />
      </Formik>
      {!isEmpty(formError) && <RpSnackbar show message={formError.message} />}
    </div>
  );
}
