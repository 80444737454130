import React, { useEffect, useContext, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import { reportService } from "../../services/reportService";
import { renderTemplate } from "../../helpers/reportHelper";
import NoDataFound from "../../components/NoDataFound";
import LocationDetails from "./LocationDetails";
import CampaignsList from "../admin/CampaignsList";
import PageLayout from "../../components/layouts/page/PageLayout";
import BackLink from "../../components/BackLink";
import PageHeader from "../../components/layouts/pageHeaders/PageHeader";
import UniversalWidgetTemplate from "../../components/templates/UniversalWidgetTemplate";
import useFetcher from "../../helpers/hooks/useFetcher";
import { CUSTOM_WIDGET_PARAM } from "../../config/constants";

const LocationReport = () => {
  const { locationSysId, partId } = useParams();
  const { selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const reqData = useMemo(() => {
    return {
      system_generated_location_id: locationSysId,
      fk_participant_id: partId,
      fk_brand_id: selectedBrand?.id,
      dataType: "location",
    };
  }, [locationSysId, selectedBrand?.id, partId]);

  const { data: report, isLoading: loading } = useFetcher(
    reqData,
    reportService.getLocationReport,
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    loader(loading);
  }, [loading, loader]);

  const renderChartWidget = () => {};

  const renderTable = (widget) => {
    if (!widget.data || widget.data.length === 0) {
      return <NoDataFound message="There is no record" />;
    }

    return (
      <CampaignsList
        campaignReportList={widget.data}
        showFilter={true}
        extraData={{ locationSysId, partId }}
        fromPage="location_report"
        hideSearchLabel={false}
        fetchCampaignsReportData={() => {}}
        totalRecords={widget?.data?.totalCountOfCampaign}
      />
    );
  };

  return (
    <PageLayout className="location-report-container mx-0 mx-sm-2">
      <BackLink />
      <PageHeader title="Location Report" />
      <div className="report admin-location-report mt-3 mb-5">
        {loading && <NoDataFound message="Loading..." />}
        {!loading && !report && (
          <NoDataFound
            message={
              <div>
                <div>No data found!</div>
                <Link
                  to={`${process.env.PUBLIC_URL}/dashboard`}
                  className="button primary btn btn-primary my-4"
                >
                  Back to dashboard
                </Link>
              </div>
            }
          />
        )}
        {!loading && report && (
          <>
            <LocationDetails report={report} />
            <UniversalWidgetTemplate
              rows={report?.template?.universal_widget_rows}
              type="location"
              requestParam={{ system_generated_location_id: locationSysId }}
              embedWidgetParams={{
                [CUSTOM_WIDGET_PARAM.LocationID]: locationSysId,
              }}
              hideDateSelector
            />
            {renderTemplate({
              report,
              renderTable,
              renderChartWidget,
              embedWidgetParams: {
                [CUSTOM_WIDGET_PARAM.LocationID]: locationSysId,
              },
            })}
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default LocationReport;
