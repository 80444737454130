import { takeLatest, call, put } from "redux-saga/effects";
import { statusCodeConstants, campaignConstants } from "../../config/constants";
import { campaignService } from "../../services";

function* joinCampaign(action) {
  try {
    const res = yield call(campaignService.joinCampaign, action.payload);
    if (res.status === statusCodeConstants.CREATED && res.data) {
      yield put({
        type: campaignConstants.SAVE_DRAFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    yield put({
      type: campaignConstants.SAVE_DRAFT_FAIL,
      payload: err,
    });
  }
}

function* joinPostCardCampaign(action) {
  try {
    const res = yield call(
      campaignService.joinPostCardCampaign,
      action.payload
    );
    if (res.status === statusCodeConstants.CREATED && res.data) {
      yield put({
        type: campaignConstants.SAVE_DRAFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    yield put({
      type: campaignConstants.SAVE_DRAFT_FAIL,
      payload: err,
    });
  }
}

function* getCampaignReport(action) {
  try {
    const res = yield call(campaignService.getCampaignReport, action.payload);

    if (res.status === statusCodeConstants.OK && res.data) {
      yield put({
        type: campaignConstants.GET_CAMPAIGN_REPORT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    yield put({
      type: campaignConstants.GET_CAMPAIGN_REPORT_FAIL,
      payload: err,
    });
  }
}

/**
 * @method campaignSaga
 * @description watch for action and act
 */
export default function* campaignSaga() {
  yield takeLatest(campaignConstants.SAVE_DRAFT_REQUEST, joinCampaign);
  yield takeLatest(
    campaignConstants.SAVE_DRAFT_POSTCARD_REQUEST,
    joinPostCardCampaign
  );
  yield takeLatest(
    campaignConstants.GET_CAMPAIGN_REPORT_REQUEST,
    getCampaignReport
  );
}
