import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import { Redirect, Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { emailMDInputFormik } from "../../helpers/formInputs";
import { isLoggedInSelector } from "../../selectors/authSelectors";
import { authActions } from "../../actions";
import {
  isEmailSendSuccessfullySelector,
  isLoadingSelector,
  isErrorSelector,
} from "../../selectors/resetPasswordSelectors";
import { validateEmail } from "../../helpers/validations";
import MESSAGE from "../../helpers/messages";
import LoadingButton from "../../components/loaders/LoadingButton";
import LeftView from "../../components/auth/LeftView";
import MainSEO from "../../components/seo/MainSEO";

const ForgotPassword = ({
  forgotPassword,
  resetResetPasswordStates,
  isLoggedIn,
  isEmailSend,
  isLoading,
}) => {
  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = (values, { setSubmitting }) => {
    forgotPassword(values);
    setSubmitting(false);
  };

  useEffect(() => {
    // on unmount component
    return () => resetResetPasswordStates();
  }, []);

  // redirect to home when already logged in
  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="row login-page">
      <MainSEO title="Forgot Password" url="forgot-password" />
      <div className="col-md-6 p-0">
        <LeftView />
      </div>
      <div className="col-md-6 d-flex flex-column justify-content-center px-md-5 py-5">
        <div className="login-form">
          {isEmailSend ? (
            <>
              <h4 className="heading8 text-center">{MESSAGE.emailSend}</h4>
              <div className="d-flex justify-content-center my-4">
                <Link className="button primary px-5" to={"/"}>
                  {" "}
                  Log In{" "}
                </Link>
              </div>
            </>
          ) : (
            <>
              <Typography
                component="h2"
                variant="h4"
                className="fs-39"
                gutterBottom
              >
                Forgot Password
              </Typography>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={onSubmitForm}
                validate={validateEmail}
              >
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit} className="mt-4 mt-md-5">
                      <Field
                        name="email"
                        label="E-Mail"
                        component={emailMDInputFormik}
                      />
                      <div className="form-group mt-2 d-flex justify-content-end">
                        <Link
                          to={"/"}
                          className={`button secondary small mr-3 ${
                            isLoading ? "disabled-link" : ""
                          }`}
                        >
                          Back
                        </Link>

                        <LoadingButton
                          type="submit"
                          className="primary"
                          text="Send instructions"
                          isLoading={isLoading}
                        />
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedInSelector(state),
  isEmailSend: isEmailSendSuccessfullySelector(state),
  isLoading: isLoadingSelector(state),
  isError: isErrorSelector(state),
});

export default connect(mapStateToProps, {
  ...authActions,
})(ForgotPassword);
