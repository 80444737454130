import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Icon,
  IconButton,
  InputLabel,
  Paper,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import RpMUIDialog from "../modals/RpMUIModal";
import "./RpCustomMonthPicker.scss";

const useMonthPickerWrapperStyle = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

export default function RpCustomMonthPicker({
  initialValue,
  onChange,
  displayButtonProps = {},
  label = "Select Month",
  labelProps = {},
  displayFormat = "MMM-YYYY",
}) {
  const [selectedMonth, setSelectedMonth] = useState(moment());

  const [open, setOpen] = useState(false);

  const wrapperClasses = useMonthPickerWrapperStyle();

  useEffect(() => {
    if (moment(initialValue).isValid()) {
      setSelectedMonth(initialValue);
    }
  }, [initialValue]);

  const renderSelectedMonth = () => {
    if (!selectedMonth) return "Select";

    return moment(selectedMonth).format(displayFormat);
  };

  const handleOnChange = (s) => {
    setSelectedMonth(s);
    setOpen(false);
    if (onChange) {
      onChange(s);
    }
  };

  return (
    <div className="rp-custom-month-picker">
      <Paper elevation={0} classes={wrapperClasses} className="px-2">
        <InputLabel shrink margin="dense" className="label" {...labelProps}>
          {label}
        </InputLabel>
        <Button
          onClick={() => setOpen(true)}
          variant="outlined"
          endIcon={<Icon>date_range</Icon>}
          size="small"
          className="text-capitalize px-3 py-2 month-picker-button"
          fullWidth
          disableElevation
          color="primary"
          {...displayButtonProps}
        >
          {renderSelectedMonth()}
        </Button>
      </Paper>
      <RpMUIDialog
        open={open}
        onClose={() => setOpen(false)}
        // heading="Select Period"
        maxWidth="xs"
      >
        <RpCustomMonthPickerPopup onOk={handleOnChange} value={selectedMonth} />
      </RpMUIDialog>
    </div>
  );
}

function RpCustomMonthPickerPopup({ onOk, value }) {
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

  const [selected, setSelected] = useState();

  useEffect(() => {
    if (moment(value).isValid()) {
      setSelectedYear(moment(value).format("YYYY"));
      setSelected({
        month: moment(value).format("M"),
        year: moment(value).format("YYYY"),
      });
    }
  }, [value]);

  const years = useMemo(() => {
    return [
      2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
      2027, 2028, 2029, 2030,
    ];
  }, []);

  const months = useMemo(() => {
    return [
      { id: 1, name: "Jan" },
      { id: 2, name: "Feb" },
      { id: 3, name: "Mar" },
      { id: 4, name: "Apr" },
      { id: 5, name: "May" },
      { id: 6, name: "Jun" },
      { id: 7, name: "Jul" },
      { id: 8, name: "Aug" },
      { id: 9, name: "Sep" },
      { id: 10, name: "Oct" },
      { id: 11, name: "Nov" },
      { id: 12, name: "Dec" },
    ];
  }, []);

  const selectedMonth = useMemo(() => {
    return `${selected?.year}-${selected?.month}`;
  }, [selected]);

  const handleOnClickMonth = (month) => {
    const s = {
      month: month,
      year: selectedYear,
    };
    setSelected(s);
  };

  const renderMonths = () => {
    return (
      <div className="months">
        <ul>
          {months.map((m) => (
            <li
              key={m.id}
              onClick={() => handleOnClickMonth(m.id)}
              className={`month ${
                selectedMonth === `${selectedYear}-${m.id}` ? "selected" : ""
              }`}
            >
              {m.name}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const handleOnClickPre = () => {
    const idx = years.findIndex((y) => String(selectedYear) === String(y));
    if (idx === 0) return;
    setSelectedYear(years[idx - 1]);
  };

  const handleOnClickNext = () => {
    const idx = years.findIndex((y) => String(selectedYear) === String(y));
    if (idx === years.length - 1) return;
    setSelectedYear(years[idx + 1]);
  };

  const renderHeader = () => {
    return (
      <div className="header">
        <IconButton
          onClick={handleOnClickPre}
          variant="outlined"
          color="primary"
        >
          <Icon>navigate_before</Icon>
        </IconButton>
        <Button
          color="primary"
          variant="text"
          disableElevation
          className="font-weight-bold bg-white"
          size="large"
        >
          {selectedYear}
        </Button>
        <IconButton
          onClick={handleOnClickNext}
          variant="outlined"
          color="primary"
        >
          <Icon>navigate_next</Icon>
        </IconButton>
      </div>
    );
  };

  const handleOnOK = () => {
    if (onOk && selected) {
      const d = moment(`${selected.year}-${selected.month}-01`, "YYYY-MM-DD");
      onOk(d);
    }
  };

  return (
    <div className="rp-custom-month-range-picker-popup">
      {renderHeader()}
      {renderMonths()}
      <div className="mt-4 text-center">
        <Button onClick={handleOnOK} variant="contained" color="primary">
          OK
        </Button>
      </div>
    </div>
  );
}
