import moment from "moment";
import * as Yup from "yup";
import MESSAGE from "./messages";

Yup.setLocale({
  mixed: { required: "This field is required" },
  string: {
    email: "Please enter a valid email address",
    min: ({ min }) => `Please enter min ${min} characters`,
    max: ({ max }) => `Please enter max ${max} characters only`,
    length: ({ length }) => `Must be exactly ${length} characters`,
    url: "Please enter a valid URL",
  },
});

/**
 * contains validation regex
 */
export const regexRP = {
  // email: /^([a-z0-9])+(\.[a-z0-9]+)*(\_[a-z0-9]+)*(\-[a-z0-9]+)*@([a-z0-9])+(\-[a-z0-9]+)*((\.[a-z]{2,3}){1,3})$/i,
  // email:
  //   /^([a-z0-9])+(\-[a-z0-9]+)*(\.[a-z0-9]+)*(\_+[a-z0-9]+)*(\-[a-z0-9]+)*@([a-z0-9])+(\-[a-z0-9]+)*((\.[a-z]{2,}){1,3})$/i,
  // email: /^[\w.-]+@[\w.-]+\.[a-zA-Z0-9]{1,}$/i,
  email: /^[^@\s]+@[^@\s]+\.[^@\s.]+$/i,
  /** match string with no whitespace characters (space, tab, line break) */
  whitespace: /^\S+$/i,
  //url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/im,
  url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/im,
  /** match at least single number any where in the string */
  atLeastOneNumber: /\d/im,
  /** match positive integers with zero , no decimal match*/
  positiveIntegers: /^\d+$/im,
  //positiveIntegers: /^[1-9]\d*$/im,
  /** match string which ends with .jpeg,.jpg or .png */
  imageType: /\.(jpe?g|png)$/im,
  /** match string which ends with .jpeg,.jpg, .png  or .gif*/
  imageTypeWithGif: /\.(jpe?g|png|gif)$/im,

  emoji:
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})(^\S*$)/gm,
};

/**
 * @method isValidEmail
 * @description validate email
 * @param {*} email
 */
export const isValidEmail = (email) => {
  //return /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/ig.test(email)
  // return /^([a-z0-9])+(\.[a-z0-9]+)*(\_[a-z0-9]+)*@([a-z0-9])+(\-[a-z0-9]+)*((\.[a-z]{2,3}){1,3})$/i.test(email)
  return regexRP.email.test(email);
};

/**
 * @method isValidPassword
 * @description validate password
 * @param {*} password
 */
export const isValidPassword = (password) => {
  let message = "";
  let isValid = true;
  if (!password || password.trim() === "") {
    message = MESSAGE.required;
  } else if (password.length < 6) {
    message = MESSAGE.minChar(6);
  }
  // else if (
  //   !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,18})(^\S*$)/gm.test(
  //     password
  //   )
  // ) {
  //   message =
  //     "Password should contain at least one lowercase, uppercase, number, and one special char ( !,@,#,$,%,^,&,* ) and no whitespace";
  // }
  else if (!regexRP.whitespace.test(password)) {
    message = MESSAGE.noWhiteSpace("password");
  } else if (password.length > 18) {
    message = MESSAGE.maxChar(18);
  }
  // else if (!regexRP.atLeastOneNumber.test(password)) {
  //   message = MESSAGE.atLeastOneNumber('Password');
  // }
  if (message.trim() !== "") {
    isValid = false;
  }
  return { isValid, message };
};

/**
 * @method validateLogin
 * @description validation function for login and signup and reset password
 * @param {*} values
 */
export const validateLogin = (values) => {
  const { email, password } = values;
  const errors = {};

  if (!email || email.trim() === "") {
    errors.email = MESSAGE.required;
  } else if (!isValidEmail(email)) {
    errors.email = MESSAGE.invalidEmail;
  } else if (email.length < 5) {
    errors.email = MESSAGE.minChar(5);
  } else if (email.length > 100) {
    errors.email = MESSAGE.maxChar(100);
  }

  const Pass = isValidPassword(password);

  if (!Pass.isValid) {
    errors.password = Pass.message;
  }

  return errors;
};

/**
 * @method validateEmail
 * @description validation function for email
 * @param {*} values
 */
export const validateEmail = (values) => {
  const { email } = values;
  const errors = {};

  if (!email || email.trim() === "") {
    errors.email = MESSAGE.required;
  } else if (!isValidEmail(email)) {
    errors.email = MESSAGE.invalidEmail;
  } else if (email.length < 5) {
    errors.email = MESSAGE.minChar(5);
  } else if (email.length > 100) {
    errors.email = MESSAGE.maxChar(100);
  }

  return errors;
};

/**
 * @method validPassConfPass
 * @description validation function for password and confirm password
 * @param {*} values
 */
export const validPassConfPass = (values) => {
  const { password, confirmPassword } = values;

  const errors = {};

  const Pass = isValidPassword(password);

  if (!Pass.isValid) {
    errors.password = Pass.message;
  }

  if (confirmPassword?.trim() === "") {
    errors.confirmPassword = MESSAGE.required;
  } else if (confirmPassword && confirmPassword !== password) {
    errors.confirmPassword = MESSAGE.passwordMisMatch;
  }

  return errors;
};

/**
 * @method validateProfile
 * @description validation function for profile
 * @param {*} values
 */
export const validateProfile = (values) => {
  const { first_name, last_name, email, mobile_no, profile_image, logo_image } =
    values;

  const errors = {};

  if (!first_name || first_name.trim() === "") {
    errors.first_name = MESSAGE.required;
  } else if (regexRP.emoji.test(first_name)) {
    errors.first_name = MESSAGE.emoji;
  } else if (first_name.length < 2) {
    errors.first_name = MESSAGE.minChar(2);
  } else if (first_name.length > 50) {
    errors.first_name = MESSAGE.maxChar(50);
  }

  if (!last_name || last_name.trim() === "") {
    errors.last_name = MESSAGE.required;
  } else if (regexRP.emoji.test(last_name)) {
    errors.last_name = MESSAGE.emoji;
  } else if (last_name.length < 2) {
    errors.last_name = MESSAGE.minChar(2);
  } else if (last_name.length > 50) {
    errors.last_name = MESSAGE.maxChar(50);
  }

  if (!email || email.trim() === "") {
    errors.email = MESSAGE.required;
  } else if (!isValidEmail(email)) {
    errors.email = MESSAGE.invalidEmail;
  } else if (email.length < 5) {
    errors.email = MESSAGE.minChar(5);
  } else if (email.length > 100) {
    errors.email = MESSAGE.maxChar(100);
  }

  if (!mobile_no || mobile_no.trim() === "") {
    errors.mobile_no = MESSAGE.required;
  } else if (mobile_no.charAt(0) === "0") {
    errors.mobile_no = MESSAGE.noZeroAtStart("Mobile number");
  } else if (mobile_no.length > 12 || mobile_no.length < 12) {
    errors.mobile_no = MESSAGE.exactDigit(10);
  }

  if (profile_image && profile_image.size > 2000000) {
    // 2MB
    errors.profile_image = MESSAGE.maxFileSize(2);
  } else if (profile_image && !regexRP.imageType.test(profile_image.name)) {
    errors.profile_image = MESSAGE.fileType("jpg, jpeg and png");
  }

  if (logo_image && logo_image.size > 2000000) {
    // 2MB
    errors.logo_image = MESSAGE.maxFileSize(2);
  } else if (logo_image && !regexRP.imageTypeWithGif.test(logo_image.name)) {
    errors.logo_image = MESSAGE.fileType("jpg, jpeg, png and gif");
  }

  return errors;
};

/**
 * @method validateJoinCampaign
 * @description validation function for Join Campaign
 * @param {*} values
 */
export const validateJoinCampaign = (values) => {
  const { start_date, end_date } = values;

  const errors = {};

  // if (!max_budget) {
  //   errors.max_budget = MESSAGE.required;
  // } else if (String(max_budget).length > 5) {
  //   errors.max_budget = MESSAGE.maxDigit(5);
  // }

  if (!start_date) {
    errors.start_date = MESSAGE.required;
  } else if (!moment(start_date).isValid()) {
    errors.start_date = MESSAGE.invalidDate;
  }

  if (!end_date) {
    errors.end_date = MESSAGE.required;
  } else if (!moment(end_date).isValid()) {
    errors.end_date = MESSAGE.invalidDate;
  }

  if (moment(end_date).isBefore(moment(start_date), "day")) {
    errors.end_date = MESSAGE.dateLessThanBefore;
  }

  return errors;
};

/**
 *@method fieldLevelValidation
 @description validation at field level
 * @param {*} values
 */
export const fieldLevelValidation = {
  validateEmail: (value) => {
    let error;

    if (!value || value.trim() === "") {
      error = MESSAGE.required;
    } else if (!isValidEmail(value)) {
      error = MESSAGE.invalidEmail;
    } else if (value.length < 5) {
      error = MESSAGE.minChar(5);
    } else if (value.length > 100) {
      error = MESSAGE.maxChar(100);
    }

    return error;
  },
  validateSecurityCode: (value) => {
    let error;
    if (!value || String(value).trim() === "") {
      error = MESSAGE.required;
    } else if (String(value).length < 6 || String(value).length > 6) {
      error = MESSAGE.exactDigit(6);
    }
    return error;
  },
};

/**
 *@method creativeFeedbackValidation
 @description validation at field level
 * @param {*} values
 */
export const creativeFeedbackValidation = (values) => {
  let errors = {};
  const { creative_name, feedback } = values;

  if (!creative_name || creative_name.trim() === "") {
    errors.creative_name = MESSAGE.required;
  } else if (creative_name.length > 50) {
    errors.creative_name = MESSAGE.invalidCreativeName;
  }

  if (!feedback || feedback.trim() === "") {
    errors.feedback = MESSAGE.required;
  } else if (feedback.length > 200) {
    errors.feedback = MESSAGE.invalidCreativeFeedback;
  }

  return errors;
};

/**
 * @method selfSubscribeFormValidation
 * @description self subscribe form validation
 * @param {*} values
 * @returns
 */
export const selfSubscribeFormValidation = (values) => {
  let errors = {};
  const { email, locations } = values;
  if (!email || email.trim() === "") {
    errors.email = MESSAGE.required;
  } else if (!isValidEmail(email)) {
    errors.email = MESSAGE.invalidEmail;
  }

  if (!locations || locations.length === 0) {
    errors.locations = MESSAGE.required;
  }

  return errors;
};

/**
 * @method validCreatePasswordForm
 * @description validate create password form with name
 * @param {*} values
 * @returns
 */
export const validCreatePasswordForm = (values) => {
  const { first_name, last_name, password, confirmPassword } = values;

  const errors = {};

  if (!first_name || first_name.trim() === "") {
    errors.first_name = MESSAGE.required;
  } else if (regexRP.emoji.test(first_name)) {
    errors.first_name = MESSAGE.emoji;
  } else if (first_name.length < 2) {
    errors.first_name = MESSAGE.minChar(2);
  } else if (first_name.length > 50) {
    errors.first_name = MESSAGE.maxChar(50);
  }

  if (!last_name || last_name.trim() === "") {
    errors.last_name = MESSAGE.required;
  } else if (regexRP.emoji.test(last_name)) {
    errors.last_name = MESSAGE.emoji;
  } else if (last_name.length < 2) {
    errors.last_name = MESSAGE.minChar(2);
  } else if (last_name.length > 50) {
    errors.last_name = MESSAGE.maxChar(50);
  }

  const Pass = isValidPassword(password);

  if (!Pass.isValid) {
    errors.password = Pass.message;
  }

  if (confirmPassword?.trim() === "") {
    errors.confirmPassword = MESSAGE.required;
  } else if (confirmPassword && confirmPassword !== password) {
    errors.confirmPassword = MESSAGE.passwordMisMatch;
  }

  return errors;
};

/**
 * @method MSActivationBriefFormValidationSchema
 * @description Yup Schema for MS Activation Brief
 */
export const MSActivationBriefFormValidationSchema = Yup.object().shape({
  team_or_individual: Yup.string().required("Please select Advisor type"),
  advisorDetail: Yup.array()
    .ensure()
    .compact()
    .of(
      Yup.object().shape({
        first_name: Yup.string()
          // .matches(/^[a-z]+$/i, "Please enter alphabets only")
          .min(2)
          .max(150)
          .required(),
        last_name: Yup.string()
          // .matches(/^[a-z]+$/i, "Please enter alphabets only")
          .min(2)
          .max(150)
          .required(),
        email: Yup.string()
          .email()
          .matches(
            /@morganstanley.com$/gi,
            "Email must belong to @morganstanley.com domain",
            { excludeEmptyString: true }
          )
          .max(100)
          .required(),
        msid: Yup.string()
          .matches(/^[a-z0-9]+$/i, "Please enter alphanumeric value only")
          .max(8)
          .required(),
        // .test(
        //   "is-associated-with-email",
        //   "This MSID already associated with another email",
        //   async (value, testContext) => {
        //     const {
        //       parent: { email },
        //     } = testContext;
        //     if (email && value) {
        //       const res = await morganStanleyService.checkValidationMsid({
        //         email: email || "",
        //         msid: value || "",
        //       });
        //       if (res.status === 200 && !res.data) {
        //         return false;
        //       }
        //     }
        //     return true;
        //   }
        // ),
      })
    )
    .required("Please enter advisor detail")
    .min(1, "At least one Advisor required"),
  approved_team_name: Yup.string().min(2).max(150).required(),
  functional_title: Yup.string().required(),
  line_of_business: Yup.string().required(),
  tier: Yup.string().required(),
  duration: Yup.string().required(),
  area_of_focus: Yup.array()
    .ensure()
    .compact()
    .min(1, "Please select at least 1")
    .max(5, "You can select upto 5 only")
    .required(),
  other_area_of_focus: Yup.string().when("area_of_focus", {
    is: (value) => {
      return value?.includes("-1") ? true : false;
    },
    then: Yup.string().min(2).max(150).required(),
    otherwise: Yup.string(),
  }),
  state_registered_in: Yup.array()
    .ensure()
    .compact()
    .min(1, "Please select at least 1")
    .required(),
  street_in_your_area: Yup.string().min(2).max(150).required(),
  street_address: Yup.string().min(2).max(150).required(),
  landing_page_url: Yup.string().url().max(1000).required(),
  radius: Yup.number()
    .positive("Must be a positive number")
    .integer("Must be an Integer")
    .min(1)
    .max(100)
    .required()
    .typeError("Please enter numbers only"),

  city: Yup.string().min(2).max(150).required(),
  state: Yup.string().required(),
  zip_code: Yup.string()
    .matches(/^[a-z0-9]+$/i, "Please enter alphanumeric value")
    .min(4)
    .max(6)
    .required(),
  phone: Yup.string()
    .matches(/^\d+$/, "Please enter numbers only")
    .min(10)
    .max(11)
    .required(),
});

export const MorganStanleyEmailListValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .matches(
      /@morganstanley.com$/gi,
      "Email must belong to @morganstanley.com domain",
      { excludeEmptyString: true }
    ),
});
