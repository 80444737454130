import React from "react";
import { Link } from "react-router-dom";

export default function UnRegisteredEmailMessage() {
  return (
    <div className="un-registered-email-message">
      <h2 className="heading mb-3">
        Looks like your email is not associated to your brand
      </h2>
      <p className="subheading">
        We couldn’t create a new Hyperlocology account because we couldn’t find
        your email address in our platform.
      </p>
      <p className="subheading">
        If you believe this is a mistake, please contact your brand and they
        will help you set up your Hyperlocology account.
      </p>

      <div className="text-center mt-5">
        <Link to="/" className="button primary mt-4 mx-auto">
          Back to website
        </Link>
      </div>
    </div>
  );
}
