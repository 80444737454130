import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Typography } from "@material-ui/core";
import PageLayout from "../../components/layouts/page/PageLayout";
import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import useFetcher from "../../helpers/hooks/useFetcher";
import { campaignService } from "../../services";
import CampaignsList from "./CampaignsList"; // Assuming CampaignsList handles the display

const CampaignReportsPage = () => {
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);
  const [filters, setFilters] = useState({
    pageSize: 10,
    pageIndex: 0,
    searchKey: "",
    sortBy: "",
    sortByDir: "",
  });

  const reqData = useMemo(
    () => ({
      id: selectedBrand?.id,
      userId: loggedInUser?.id,
      row: filters?.pageSize,
      page: filters?.pageIndex + 1,
      searchKey: filters?.searchKey || "",
      sortBy: filters?.sortBy || "",
      sortByDir: filters?.sortByDir || "",
    }),
    [selectedBrand?.id, loggedInUser?.id, filters]
  );

  const { data, isLoading, isValidating } = useFetcher(
    reqData,
    campaignService.getCampaignReportListing,
    {
      revalidateOnFocus: false,
    }
  );

  const campaignReportList = data?.result || [];
  const pageCount = data?.pageCount || 0;
  const totalRecords = data?.recordsTotal;
  useEffect(() => {
    loader(isLoading || isValidating);
  }, [isLoading, loader, isValidating]);

  const fetchCampaignsReportData = useCallback((data) => {
    setFilters((prev) => ({
      ...prev,
      ...data,
    }));
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      fetchCampaignsReportData({ pageSize: 10, pageIndex: 0, searchKey: "" });
    }
  }, [selectedBrand, fetchCampaignsReportData]);

  return (
    <PageLayout className="admin-dashboard-container mx-0 mx-sm-2">
      <div className="dashboard participant-dashboard">
        <Typography variant="h5" className="widget-title mb-4 mb-md-auto">
          Digital Campaign Reports
        </Typography>
        <CampaignsList
          campaignReportList={campaignReportList}
          pageCount={pageCount}
          fetchCampaignsReportData={fetchCampaignsReportData}
          isLoading={isLoading}
          totalRecords={totalRecords}
        />
      </div>
    </PageLayout>
  );
};

export default CampaignReportsPage;
