import axios from "axios";
import { toastr } from "react-redux-toastr";
import { authActions } from "../actions/authActions";
import store from "../config/store";
import { logger } from "./loggerHelper";

export const errorHandler = (error) => {
  if (axios.isCancel(error)) {
    logger.trace("Request cancelled", error.message);
    return;
  }

  if (error.response) {
    logger.info("Full error response", error.response);

    logger.error("API Error Response", {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response.status,
      message: error.response.data?.message,
    });

    if (error.response.status === 401) {
      logger.warn("Authentication failed, logging out user");
      if (typeof window !== undefined) {
        setTimeout(() => {
          store.dispatch(authActions.logout());
        }, 1000);
      }
    }

    if (error.response.data?.message) {
      toastr.error("Error", error.response.data.message);
    }
  } else if (error.request) {
    logger.error("API Request Error", {
      url: error.config?.url,
      method: error.config?.method,
      error: error.message,
    });

    const message =
      error.message || "Something went wrong while making the request";
    toastr.error("Error", message, { id: "toast-error" });
  } else {
    logger.error("Unexpected Error", {
      error: error.message,
      stack: error.stack,
    });
  }
};
