import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";

const CampaignPauseSuccess = () => {
  return (
    <Box
      className="campaign-thankyou-page"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <div className="custom-thank-you-content text-center">
        <img
          className="my-4 ads-img"
          src={`${process.env.PUBLIC_URL}/img/ads.png`}
          alt="Success Icon"
        />
        <Typography color="primary" variant="h4" className="fw-b">
          You’re all set!
        </Typography>
        <Box className="d-flex align-items-center flex-column my-2 description-box">
          <Typography className="fw-sb description mt-4">
            Your campaign has been updated.
          </Typography>
          <Typography className="fw-sb description mt-2 mb-5">
            Check your email for confirmation and additional details.
          </Typography>
        </Box>
        <Box className="d-flex-center my-1">
          <Button
            component={Link}
            to={`${process.env.PUBLIC_URL}/dashboard`}
            className="thankyou-btn"
            variant="contained"
            color="primary"
            size="large"
          >
            Back to My Dashboard
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default CampaignPauseSuccess;
