import React, { memo, useCallback, useEffect, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PostcardCampaignLocationBudget from "./PostcardCampaignLocationBudget";
import PostcardCampaignLocationHeader from "./PostcardCampaignLocationHeader";
import Creatives from "./creatives/Creatives";
import BulkDrop from "./BulkDrop";

import {
  roundDownToClosestRangeValue,
  roundUpToClosestRangeValue,
} from "../../../../helpers/utils";
import MailingPostcards from "./mailPostCard/MailingPostcards";
import { MAILING_TYPES } from "../../../../config/constants";

function PostcardCampaignLocation({
  campaign,
  loc,
  locFormData = {},
  expanded,
  setFieldValue,
  setExpanded,
  productList,
  getAdvanceSettingData,
  isUnsubscribed,
  subscriptionStatusType,
  is30DaysLeftToJoinCampaign,
}) {
  const calculateSaturatedMailingCoast = useCallback((qnt) => {
    if (!qnt) {
      return 0;
    }
    const { postcard_cost_mailings } = campaign;
    const mc_list = postcard_cost_mailings?.find(
      (m) => m.category === "Saturated"
    );

    if (!mc_list) {
      return qnt;
    }
    const costRange = Object.keys(mc_list).filter((k) => !isNaN(k));

    const mailing_const = roundDownToClosestRangeValue(qnt, costRange);

    const costFactor = mc_list[mailing_const];

    return qnt * costFactor;
  });

  const calculateTargetedMailingCoast = useCallback((qnt) => {
    if (!qnt) {
      return 0;
    }
    const { postcard_cost_mailings } = campaign;
    const mc_list = postcard_cost_mailings?.find(
      (m) => m.category === "Targeted"
    );
    if (!mc_list) {
      return qnt;
    }
    const costRange = Object.keys(mc_list).filter((k) => !isNaN(k));

    const mailing_const = roundDownToClosestRangeValue(qnt, costRange);

    const costFactor = mc_list[mailing_const] || 0;

    return qnt * costFactor;
  });

  const calculateBulkCost = useCallback((qnt) => {
    if (!qnt) {
      return 0;
    }
    const { postcard_cost_mailings, postcard_cost_shippings } = campaign;
    const bulk_drop_list = postcard_cost_mailings?.find(
      (m) => m.category === "Printing"
    );
    if (!bulk_drop_list || !postcard_cost_shippings?.length) {
      return qnt;
    }
    const costRange = Object.keys(bulk_drop_list).filter((k) => !isNaN(k));

    const bulk_drop_const = roundDownToClosestRangeValue(qnt, costRange);

    const costFactor = bulk_drop_list[bulk_drop_const];

    const shippingCostRange = postcard_cost_shippings?.[0]
      ? Object.keys(postcard_cost_shippings?.[0]).filter((k) => !isNaN(k))
      : [];
    const shippingFeeCost = roundUpToClosestRangeValue(qnt, shippingCostRange);
    const shippingFee = postcard_cost_shippings?.[0][shippingFeeCost];

    return qnt * costFactor + Number(shippingFee);
  });

  useEffect(() => {
    const oldVal = { ...locFormData };

    const mailingCost =
      oldVal?.postcard_address && oldVal?.mailingType === MAILING_TYPES.T
        ? calculateTargetedMailingCoast(oldVal.mailingQnt || 0)
        : calculateSaturatedMailingCoast(oldVal.mailingQnt || 0);

    const bulkDropCost = calculateBulkCost(oldVal.bulkDropQnt || 0);

    setFieldValue(loc.id, {
      ...oldVal,
      mailingCost,
      bulkDropCost,
    });
  }, []);

  const handleOnExpand = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((l) => l !== panel));
    }
  };

  const shouldExpandIt = useMemo(() => {
    return (id) => expanded.includes(id);
  }, [expanded]);

  const locName = useMemo(
    () =>
      `${loc.location_name}`
        .concat(`, ${loc.state}`)
        .concat(`${loc.brand_region ? ", " + loc.brand_region : ""}`),
    [loc]
  );

  const locTotalCoast = useMemo(() => {
    const { mailingCost, bulkDropCost } = locFormData;
    const t = (mailingCost || 0) + (bulkDropCost || 0);

    return t;
  }, [locFormData]);

  const initialWeek = useMemo(() => {
    const { schedule_date, campaign_type } = campaign;

    const draft_schedule_date = locFormData?.schedule_date;

    if (campaign_type === "LIMITED") {
      if (schedule_date && moment(schedule_date).isValid()) {
        return schedule_date;
        // return moment(schedule_date).startOf("isoWeek").toDate();
      } else {
        return new Date();
      }
    } else if (campaign_type === "EVERGREEN") {
      if (draft_schedule_date && moment(draft_schedule_date).isValid()) {
        return draft_schedule_date;
      }
      return moment().add(7, "week").startOf("isoWeek").toDate();
    }
  }, [campaign]);

  const handleOnSelectCreative = (selectedCreatives) => {
    const oldVal = { ...locFormData };
    setFieldValue(loc.id, { ...oldVal, creatives: selectedCreatives });
  };

  // to get advance settings data
  const handleOnFillSettingData = (selectedSettingsData) => {
    const oldVal = { ...locFormData };
    setFieldValue(loc.id, {
      ...oldVal,
      advance_setting: selectedSettingsData,
    });
  };

  const handleOnChangeMailQnt = (qnt) => {
    const oldVal = { ...locFormData };

    setFieldValue(loc.id, {
      ...oldVal,
      mailingCost: calculateSaturatedMailingCoast(qnt),
      mailingQnt: qnt,
    });
  };

  const handleOnCheckMailing = (v) => {
    const oldVal = { ...locFormData };
    setFieldValue(loc.id, {
      ...oldVal,
      isMailing: v,
      mailingType: v ? oldVal?.mailingType || "" : "",
      mailingQnt: v ? oldVal?.mailingQnt || "" : "",
      mailingCost: v
        ? calculateSaturatedMailingCoast(oldVal?.mailingQnt || 0)
        : 0,
    });
  };

  const handleOnChangeMailingType = (v) => {
    const oldVal = { ...locFormData };
    setFieldValue(loc.id, {
      ...oldVal,
      mailingType: v,
      mailingQnt: 0,
      mailingCost: 0,
      postcard_address: "",
    });
  };

  const handleOnCheckBulkDrop = (v) => {
    const oldVal = { ...locFormData };
    setFieldValue(loc.id, {
      ...oldVal,
      isBulkDrop: v,
      bulkDropQnt: v ? locFormData?.bulkDropQnt || "" : 0,
      bulkDropCost: v ? calculateBulkCost(locFormData?.bulkDropQnt || 0) : 0,
    });
  };

  const handleOnChangeBulkDropQnt = (qnt) => {
    const oldVal = { ...locFormData };

    setFieldValue(loc.id, {
      ...oldVal,
      bulkDropQnt: qnt || "",
      bulkDropCost: calculateBulkCost(qnt),
    });
  };

  const handleOnChangeWeek = (v) => {
    const oldVal = { ...locFormData };
    if (v && moment(v).isValid()) {
      setFieldValue(loc.id, {
        ...oldVal,
        week: moment(v).format("YYYY-MM-DD"),
      });
    }
  };

  const handleOnUploadAddress = (data) => {
    const oldVal = { ...locFormData };

    const qnt = data?.data;

    setFieldValue(loc.id, {
      ...oldVal,
      mailingCost: calculateTargetedMailingCoast(qnt),
      mailingQnt: qnt,
      postcard_address: data?.file_name,
    });
  };

  return (
    <Accordion
      expanded={shouldExpandIt(loc.id)}
      onChange={handleOnExpand(loc.id)}
      className="location"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="location-header"
        classes={{
          content: "py-0 my-0",
        }}
      >
        <PostcardCampaignLocationHeader
          loc={loc}
          locFormData={locFormData}
          locName={locName}
          campaign={campaign}
          isUnsubscribed={isUnsubscribed}
          isCreative={loc?.is_creative}
          subscriptionStatusType={subscriptionStatusType}
          is30DaysLeftToJoinCampaign={is30DaysLeftToJoinCampaign}
        />
      </AccordionSummary>
      <AccordionDetails className="location-details">
        <div className="card w-100 postcard-details">
          <div className="card-body">
            <Creatives
              creatives={loc.creatives}
              onSelectCreative={handleOnSelectCreative}
              initiallySelected={locFormData.creatives}
              locationName={loc?.location_name}
              loc={loc}
              campaign={campaign}
              productList={productList}
              getAdvanceSettingData={getAdvanceSettingData}
              onFillSettingsData={handleOnFillSettingData}
              initialAnswers={locFormData.advance_setting}
              initialDisabled={isUnsubscribed}
            />
          </div>
          <MailingPostcards
            onChangeQnt={handleOnChangeMailQnt}
            mailingCost={locFormData?.mailingCost || ""}
            mailingQnt={locFormData?.mailingQnt || ""}
            isMailingInit={locFormData?.isMailing || false}
            onMailing={handleOnCheckMailing}
            mailingType={locFormData?.mailingType || ""}
            onChangeMailType={handleOnChangeMailingType}
            campaign={campaign}
            loc={loc}
            onUpload={handleOnUploadAddress}
            initialDisabled={isUnsubscribed}
          />
          <BulkDrop
            isBulkDropInit={locFormData?.isBulkDrop || false}
            onBulkDropCheck={handleOnCheckBulkDrop}
            bulkDropCost={locFormData?.bulkDropCost}
            bulkDropQnt={locFormData?.bulkDropQnt}
            onChangeBulkDropQnt={handleOnChangeBulkDropQnt}
            loc={loc}
            initialDisabled={isUnsubscribed}
          />
          <PostcardCampaignLocationBudget
            onChangeWeek={handleOnChangeWeek}
            locTotalCoast={locTotalCoast}
            initialWeek={initialWeek}
            disableWeekSelection={
              isUnsubscribed || campaign?.campaign_type === "LIMITED"
                ? true
                : false
            }
            isEvergreenCampaign={
              campaign?.campaign_type === "EVERGREEN" ? true : false
            }
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default memo(PostcardCampaignLocation);
