import React from "react";
import { Button, Icon } from "@material-ui/core";

function LocationsExpandCollapseButtons({
  expanded,
  setExpanded,
  allLocationsIds,
}) {
  return (
    <Button
      disabled={!allLocationsIds?.length}
      size="small"
      onClick={() =>
        setExpanded((p) =>
          p.length !== allLocationsIds.length ? [...allLocationsIds] : []
        )
      }
      variant={
        allLocationsIds.length === expanded.length ? "outlined" : "contained"
      }
      endIcon={
        <Icon>
          {allLocationsIds.length === expanded.length
            ? "expand_less"
            : "expand_more"}
        </Icon>
      }
      color="primary"
    >
      {allLocationsIds.length === expanded.length
        ? "Collapse All"
        : "Expand All"}
    </Button>
  );
}

export default LocationsExpandCollapseButtons;
