import React, { memo, useCallback, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import LocationHeader from "./LocationHeader";
import AdvanceSettingForm from "../../../campaignAdvancedSettings/AdvanceSettingForm";
import PostcardCampaignLocationHeader from "../../../postcardCampaign/joinPostcardCampaign/activeLocations/PostcardCampaignLocationHeader";

const LocationAdvancedSetting = ({
  campaign,
  loc,
  locFormData = {},
  expanded,
  setFieldValue,
  setExpanded,
  productList,
  getAdvanceSettingData,
  handleOnChangeAdvancedSettingError,
  isDigitalCampaign = true,
}) => {
  const settingsData = getAdvanceSettingData?.settings;
  const handleOnExpand = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((l) => l !== panel));
    }
  };

  const shouldExpandIt = useMemo(() => {
    return (id) => expanded.includes(id);
  }, [expanded]);

  const locName = useMemo(
    () =>
      `${loc.location_name}`
        .concat(`, ${loc.state}`)
        .concat(`${loc.brand_region ? ", " + loc.brand_region : ""}`),
    [loc]
  );

  // to get advance settings data
  const handleOnFillSettingData = useCallback(
    (selectedSettingsData) => {
      const oldVal = { ...locFormData };
      setFieldValue(loc.id, {
        ...oldVal,
        advance_setting: selectedSettingsData,
      });
    },
    [setFieldValue, loc.id]
  );

  return (
    <Accordion
      expanded={shouldExpandIt(loc.id)}
      onChange={handleOnExpand(loc.id)}
      className="location"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="location-header"
      >
        {isDigitalCampaign && (
          <LocationHeader
            locFormData={locFormData}
            locName={locName}
            campaign={campaign}
            hideMeta
          />
        )}

        {!isDigitalCampaign && (
          <PostcardCampaignLocationHeader
            locFormData={locFormData}
            locName={locName}
            campaign={campaign}
          />
        )}
      </AccordionSummary>
      <AccordionDetails className="location-details">
        <div className="card w-100">
          <div className="card-body p-1 p-lg-auto">
            <AdvanceSettingForm
              settingsData={settingsData}
              onFillSettingsData={handleOnFillSettingData}
              productList={productList}
              initialAnswers={locFormData.advance_setting}
              locationName={loc?.location_name}
              isModal={false}
              handleOnChangeAdvancedSettingError={
                handleOnChangeAdvancedSettingError
              }
              locId={loc?.id}
              locationRadius={loc.radius}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(LocationAdvancedSetting);
