import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";

import { GlobalContext } from "../../helpers/contexts/contexts";
import { MUICustomAutocompleteSelect } from "../../components/muiCustom/select/MUICustomAutocompleteSelect";
import SelectDownloadReports from "../../components/pdf/SelectDownloadReports";
import PDFDownloadButton from "../../components/pdf/PDFDownloadButton";
import { brandsWithCsvDownloadOption } from "../../config/constants";
import MUIDateRange from "../../components/MUIDateRange";
import RpCustomMonthRangePicker from "../../components/datepicker/RpCustomMonthRangePicker";
import { isBrandHasDrillFeature } from "../../helpers/drillUpDownHelper";

const filterOptions = [
  { label: "Last Quarter", value: "last_quarter" },
  { label: "YTD", value: "ytd" },
  { label: "Custom", value: "custom" },
];
const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
  },
  inputLabel: {
    color: "#1B262C",
    fontSize: "11px",
    fontWeight: "600",
    marginBottom: ".1rem",
    fontFamily:
      'Open Sans, Montserrat, Roboto, Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  noUnderline: {
    "&:before": {
      borderBottom: "none !important",
    },
  },
  // Add custom styles for MUIDateRange
  dateRangeInput: {
    backgroundColor: "#04a999 !important",
    padding: "5px 12px !important",
    fontFamily: "inherit",
    borderRadius: "4px",
    border: "none",
    borderRadius: "4px !important",
    textAlign: "center",
    marginBottom: 5,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },

    // border: "1px solid red",
  },
  dateRangeText: {
    fontSize: "10px !important",
    color: "white !important",
  },
  dateRangeIcon: {
    color: "#1B262C !important",
    "& .MuiSvgIcon-root": {
      color: "white !important",
    },
  },
}));
const CampaignReportFilters = ({
  values,
  campaign,
  setFieldValue,
  initialRange,
}) => {
  const { loggedInUserRole, selectedBrand, loggedInUser } =
    useContext(GlobalContext);
  const classes = useStyles();

  const [groupList, setGroupList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    if (campaign && campaign?.group_list) {
      const groupList = campaign?.group_list.map((c) => ({
        label: c.group_name,
        value: c.groups_id,
      }));
      setGroupList(groupList);
    }
  }, [campaign]);

  useEffect(() => {
    if (campaign && campaign?.channel_list) {
      const channelList = campaign?.channel_list.map((c) => ({
        label: c.channel_name,
        value: c.channel_name,
      }));
      setChannelList(channelList);
    }
  }, [campaign]);

  useEffect(() => {
    if (campaign && campaign?.location_list) {
      const locationList = campaign?.location_list.map((c) => ({
        label: c.location_name,
        value: c.location_id,
      }));
      setLocationList(locationList);
    }
  }, [campaign]);

  const handleOnChangeRange = (range) => {
    setFieldValue("customRange", range);
  };

  return (
    <>
      <div className="row my-4 align-items-end campaign-filters">
        <div className="col-xl col-lg-4 col-md-6 d-flex flex-column justify-content-end mb-1 pr-xl-1">
          <MUICustomAutocompleteSelect
            name="filter"
            label="Report Period"
            selectProps={{
              onChange: (v) => {
                setFieldValue("filter", v);
              },
              value: values.filter,
            }}
            labelProps={{
              style: {
                color: "#000",
              },
            }}
            options={filterOptions}
          />
        </div>

        {values.filter === "custom" ? (
          isBrandHasDrillFeature(selectedBrand.system_generated_brand_id) ? (
            <div className="col-xl col-lg-4 col-md-6 px-xl-0">
              <MUIDateRange
                onChangeDate={handleOnChangeRange}
                label="Custom Date"
                initialRange={values.customRange || initialRange}
                classes={{
                  inputRoot: classes.dateRangeInput,
                  input: classes.dateRangeText,
                  adornment: classes.dateRangeIcon,
                }}
              />
            </div>
          ) : (
            <div className="col-xl col-lg-4 col-md-6 px-xl-0">
              <RpCustomMonthRangePicker
                onChange={handleOnChangeRange}
                initialRange={values.customRange || initialRange}
                label="Custom Dates"
                labelProps={{
                  style: {
                    color: "#000",
                    // marginTop: 5,
                  },
                }}
                displayButtonProps={{
                  style: {
                    fontSize: 10,
                    fontWeight: 600,
                    paddingLeft: "5px !important",
                    paddingRight: "5px !important",
                    marginBottom: 4,
                  },
                  variant: "contained",
                  size: "medium",
                }}
                buttonClassName="pl-2 pr-2"
              />
            </div>
          )
        ) : null}

        <div className="col-xl col-lg-4 col-md-6 my-1 px-xl-1">
          <MUICustomAutocompleteSelect
            name="channel_id"
            label="Channels"
            selectProps={{
              onChange: (v) => {
                setFieldValue("channel_id", v);
              },
              value: values.channel_id,
            }}
            labelProps={{
              style: {
                color: "#000",
              },
            }}
            options={channelList}
            all={true}
          />
        </div>
        {loggedInUserRole !== "PR" && (
          <>
            <div className="col-xl col-lg-4 col-md-6 my-1 px-xl-1">
              <MUICustomAutocompleteSelect
                name="group_id"
                label="Groups"
                selectProps={{
                  onChange: (v) => {
                    setFieldValue("group_id", v);
                  },
                  value: values.group_id,
                }}
                labelProps={{
                  style: {
                    color: "#000",
                  },
                }}
                options={groupList}
                all={true}
              />
            </div>
          </>
        )}

        <div className="col-xl col-lg-4 col-md-6 my-1 px-xl-1">
          <MUICustomAutocompleteSelect
            name="location_id"
            label="Locations"
            selectProps={{
              onChange: (v) => {
                setFieldValue("location_id", v);
              },
              value: values.location_id,
            }}
            labelProps={{
              style: {
                color: "#000",
              },
            }}
            options={locationList}
            all={true}
          />
        </div>
        <div className="col-xl col-lg-4 col-md-6 d-flex align-items-end mb-1 mt-4 mt-md-4 px-4 pr-xl-3 pl-xl-2">
          {loggedInUser?.is_master_pass ||
          brandsWithCsvDownloadOption?.includes(
            selectedBrand?.system_generated_brand_id
          ) ? (
            <SelectDownloadReports
              title={`CAMPAIGN REPORT`}
              fileName={`${campaign?.campaign_name} Report`}
              campaign={campaign}
              values={values}
              initialRange={initialRange}
              elId="campaignReport"
            />
          ) : (
            <PDFDownloadButton
              label="Download PDF"
              elId="campaignReport"
              title={`CAMPAIGN REPORT`}
              fileName={`${campaign?.campaign_name}_Report`}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CampaignReportFilters;
