import React, { Fragment } from "react";
import NoDataFound from "../components/NoDataFound";
import NumberFigure from "../components/NumberFigure";
import { addParamsToIframeSrc } from "./utils";
import { deviceInfo } from "./deviceDetectHelper";

/**
 * @method renderUniversalTemplate
 * @description render universal widget
 * @param {*} param0
 * @returns
 */
export const renderUniversalTemplate = ({
  rows,
  renderChartWidget,
  filters,
  embedWidgetParams,
}) => {
  if (!rows) return null;

  return (
    <div className="widget-template universal-template">
      {rows.map((row, mainRowIndex) => {
        const columnRows = row.columnRows;
        const sectionColor = getWidgetSectionColor(row.color);
        return (
          <div className="section bg-white" key={row.id}>
            {columnRows.map((colRow, columnRowIndex) => {
              const colClass = getColClass(colRow);
              const columns = colRow?.columns;
              return (
                <Fragment key={colRow.id}>
                  {columns.length &&
                  columnRowIndex === 0 &&
                  (row.title || (mainRowIndex === 0 && filters)) ? (
                    <div
                      className={`container-fluid ${
                        mainRowIndex === 0 ? "py-2" : ""
                      } section-header`}
                    >
                      <div className="row align-items-center">
                        <div className="col mb-2">
                          {row.title && (
                            <h2
                              className="section-title"
                              style={{
                                color: `${sectionColor}`,
                              }}
                            >
                              <span>{row.title}</span>
                            </h2>
                          )}
                        </div>

                        {mainRowIndex === 0 && filters ? (
                          <div className="col-sm-12 col-md-6 mb-2">
                            {filters()}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  <div className="container-fluid">
                    <div className="row align-items-stretch" key={colRow.id}>
                      {columns?.map((col) => {
                        const widget = col.widget;
                        return widget ? (
                          <div
                            className={`${colClass} my-3 d-flex-center`}
                            key={col.id}
                          >
                            <div
                              className={`card h-100 w-100 widget widget_type_${
                                widget.type || "none"
                              }`}
                            >
                              {widget ? (
                                renderWidget({
                                  widget,
                                  renderChartWidget,
                                  col,
                                  embedWidgetParams,
                                })
                              ) : (
                                <div className="card-body">
                                  <NoDataFound
                                    message="There is no widget defined here"
                                    height="auto"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="col my-1 d-flex-center"
                            key={col.id}
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

/**
 * @method renderTemplate
 * @description render report template
 * @param {*} report
 */
export const renderTemplate = ({
  report,
  renderTable,
  renderChartWidget,
  channel_name = "",
  reportType,
  embedWidgetParams,
  isLoading = false,
}) => {
  let selectedChannel = channel_name;

  const template = report?.template;

  if (template) {
    if (
      (!template?.rows && !template?.universal_widget_rows) ||
      (template?.rows?.length === 0 &&
        template?.universal_widget_rows?.length === 0)
    ) {
      return (
        <NoDataFound message="Your dashboard has not been set up yet. If you think this is an error, reach out to your brand" />
      );
    }
  } else {
    if (reportType && reportType === "campaign" && !isLoading) {
      return (
        <NoDataFound message="There is no template defined" height="20vh" />
      );
    }
    return null;
  }

  if (!template || !template.rows || template.rows.length === 0) {
    return null;
  }

  const rows = template?.rows;

  /**
   * @method getColRowColumns
   * @description filter columns according to the channel and return
   * @param {*} colRow
   */
  const getColRowColumns = (colRow) => {
    return selectedChannel && selectedChannel !== ""
      ? colRow.columns.filter(
          (col) => col.widget && col.widget.channel === selectedChannel
        )
      : colRow.columns;
  };

  /**
   * @method isThereAnyWidgetForSelectedChannel
   * @description check is there any widget for the selected channel
   * @param {*} rows
   */
  const isThereAnyWidgetForSelectedChannel = (rows) => {
    let isIt = false;
    if (!selectedChannel || selectedChannel === "") return true;

    isIt = rows.some((row) => {
      const columnRows = row.columnRows;
      return columnRows.some((colRow) => {
        const columns = colRow.columns;
        return columns.some((col) => {
          if (col.widget) {
            if (col.widget.channel === selectedChannel) {
              return true;
            }
          }
          return false;
        });
      });
    });

    return isIt;
  };

  if (!isThereAnyWidgetForSelectedChannel(rows)) {
    return (
      <NoDataFound message={`There are no widget for selected channel.`} />
    );
  }

  // if (!checkIsSomeWidgetHasData(rows)) {
  //   return (
  //     <NoDataFound
  //       message={`There is no record found for any of the added widgets.`}
  //     />
  //   );
  // }

  return (
    <div className="widget-template">
      {rows.map((row) => {
        const columnRows = row.columnRows;
        const sectionColor = getWidgetSectionColor(row.color);

        if (!doesRowHasWidgetByChannel(row, selectedChannel)) return null;

        return (
          <div className="section bg-white" key={row.id}>
            {columnRows.map((colRow, columnRowIndex) => {
              const colClass = getColClass(colRow);

              return (
                <Fragment key={colRow.id}>
                  {getColRowColumns(colRow).length &&
                  columnRowIndex === 0 &&
                  row.title ? (
                    <div className="container-fluid section-header">
                      {row.title && (
                        <h2
                          className="section-title"
                          style={{
                            color: `${sectionColor}`,
                          }}
                        >
                          <span>{row.title}</span>
                        </h2>
                      )}
                    </div>
                  ) : null}
                  <div className="container-fluid">
                    <div className="row align-items-stretch" key={colRow.id}>
                      {getColRowColumns(colRow).map((col) => {
                        const widget = col.widget;
                        return widget ? (
                          <div
                            className={`${colClass} my-3 d-flex-center`}
                            key={col.id}
                          >
                            <div
                              className={`card h-100 w-100 widget widget_type_${
                                widget.type || "none"
                              }`}
                            >
                              {widget ? (
                                renderWidget({
                                  widget,
                                  template: template,
                                  renderTable,
                                  renderChartWidget,
                                  col,
                                  embedWidgetParams,
                                })
                              ) : (
                                <div className="card-body">
                                  <NoDataFound
                                    message="There is no widget defined here"
                                    height="auto"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="col my-1 d-flex-center" key={col.id}>
                            {/* <div className="card-body">
                  <NoDataFound message="There is no widget" />
                 </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

/**
 * @method renderWidget
 * @description render widget
 * @param {*} widget
 * @param {*} template
 * @param {*} renderTable
 * @param {*} renderChartWidget
 */
export const renderWidget = ({
  widget,
  template,
  renderTable,
  renderChartWidget,
  col,
  embedWidgetParams,
}) => {
  if (!widget) {
    return (
      <div className="card-body">
        <NoDataFound message="There is no widget" />
      </div>
    );
  }

  const widgetTitle = getWidgetTitleFormatted(widget, col);
  let embedCode = col?.embedCode;
  if (widget.is_embed) {
    if (deviceInfo?.deviceType === "mobile") {
      embedCode = addParamsToIframeSrc(
        col?.mobileEmbedCode || col?.embedCode,
        embedWidgetParams
      );
    } else {
      embedCode = addParamsToIframeSrc(col?.embedCode, embedWidgetParams);
    }
  }
  return (
    <>
      {widget.type !== "stats" && widgetTitle.trim() !== "" && (
        <div className="card-header">
          <h3 className="widget-title">
            <span>{widgetTitle}</span>
          </h3>
        </div>
      )}
      {widget.is_embed ? (
        <div
          className={`card-body text-center d-block py-1 overflow-auto is_embed`}
        >
          <div
            className="iframe-container"
            dangerouslySetInnerHTML={{
              __html: embedCode,
            }}
          ></div>
        </div>
      ) : (
        <div className="card-body">
          {widget.type === "stats"
            ? renderKillerState(widget, col)
            : widget.type === "table"
              ? renderTable && typeof renderTable === "function"
                ? renderTable(widget)
                : null
              : renderChartWidget && typeof renderChartWidget === "function"
                ? renderChartWidget(widget, template)
                : null}
        </div>
      )}
    </>
  );
};

/**
 * @method renderKillerState
 * @description render killer state
 * @param {*} widget
 */
export const renderKillerState = (widget, col) => {
  return (
    <NumberFigure
      title={getWidgetTitleFormatted(widget, col)}
      value={widget.data}
      className="text-center text-md-left"
      titleClass="text18M_SemiBold83898E"
      valueClass="text38M_SemiBold09ABA7"
      decimalScale={0}
    />
  );
};

/**
 * @method isWidgetHasData
 * @description check is widget has data
 * @param {*} widget
 */
// const isWidgetHasData = (widget) => {
//   if (!widget) return false;
//   else if (!widget.data) return false;
//   else if (
//     widget.data &&
//     Array.isArray(widget.data) &&
//     widget.data.length === 0
//   )
//     return false;
//   return true;
// };

/**
 * @method checkIsSomeWidgetHasData
 * @description check is widget has data
 * @param {*} widget
 */
// const checkIsSomeWidgetHasData = (rows = []) => {
//   let isIt = false;

//   isIt = rows.some((row) => {
//     const columnRows = row.columnRows;
//     return columnRows.some((colRow) => {
//       const columns = colRow.columns;
//       return columns.some((col) => isWidgetHasData(col.widget));
//     });
//   });

//   return isIt;
// };

/**
 * @method getColClass
 * @description return bootstrap col class
 * @param {*} colRow
 */
function getColClass(colRow) {
  const totalCols = colRow.columns.length
    ? Math.floor(12 / colRow.columns.length)
    : 12;

  let colClassName = "col-md";

  if (colRow.columns.length === 5) {
    // special case for 5 columns only
    colClassName = "col-sm";
  } else if (totalCols === 12) {
    colClassName = "col-md-12";
  } else if (totalCols === 6) {
    colClassName = "col-md-6";
  } else if (totalCols === 4) {
    colClassName = "col-md-6 col-lg-4";
  } else if (totalCols === 3) {
    colClassName = "col-md-6 col-lg-3";
  } else if (totalCols === 2) {
    colClassName = "col-md-3 col-lg-2";
  } else if (totalCols === 3 || totalCols % 2 === 0) {
    colClassName = `col-md-${totalCols}`;
  }

  return colClassName;
}

export function getWidgetTitleFormatted(widget, col) {
  const formatName = (name) => {
    if (!name) return "";
    let n = name.replace(/_/g, " ");
    n = n.charAt(0).toUpperCase() + n.slice(1);
    return n;
  };

  if (col?.customName) {
    return col.customName;
  } else if (col?.universalWidgetName) {
    return formatName(col.universalWidgetName);
  }
  return widget.name;
}

export function getWidgetSectionColor(color = "#218F83") {
  return color === "#fff" || color === "#ffffff" ? "#218F83" : color;
}

function doesRowHasWidgetByChannel(row, selectedChannel) {
  // Return false if there is no widget in the row
  if (!row) return false;

  const columnRows = row?.columnRows;

  return columnRows.some((colRow) => {
    const cols =
      selectedChannel && selectedChannel !== ""
        ? colRow.columns.filter(
            (col) => col.widget && col.widget.channel === selectedChannel
          )
        : colRow.columns;

    return cols.length ? true : false;
  });
}
