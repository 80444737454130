import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, Typography, Button } from "@material-ui/core";

import { MUICustomAutocompleteSelect } from "../../../components/muiCustom/select/MUICustomAutocompleteSelect";
import SubscribedLocationList from "./SubscribedLocationList";
import { subscriptionService } from "../../../services/subscriptionService";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import NoDataFound from "../../../components/NoDataFound";
import ConfirmPackageSelection from "../confirmPackageSelection/ConfirmPackageSelection";
import RpAlert from "../../../components/alerts/RpAlert";
import PageLayout from "../../../components/layouts/page/PageLayout";
import BackLink from "../../../components/BackLink";
import { mergeArraysWithUniqueKey } from "../../../helpers/utils";
import { logger } from "../../../helpers/loggerHelper";
import MuiSearchInput from "../../../components/muiCustom/search/MuiSearchInput";

const LOCATIONS_PAGE_SIZE = 10;

const statusOptions = [
  { label: "Auto-Renew ON", value: 1 },
  { label: "Auto-Renew OFF", value: 0 },
  { label: "Upcoming Renewal", value: 2 },
  { label: "Past Due", value: 4 },
];

const statusOptionsOther = [
  { label: "Upcoming Renewal", value: 2 },
  { label: "Past Due", value: 4 },
];

export default function ManageLocationSubscription() {
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);

  const [pageInfo, setPageInfo] = useState(null);

  const [selections, setSelections] = useState([]);

  const [locations, setLocations] = useState([]);
  const [noLocation, setNoLocations] = useState(false);

  const [page, setPage] = useState(1);

  const [hasMoreLocations, setHasMoreLocations] = useState(true);

  const [locationFilters, setLocationFilters] = useState({
    searchKey: "",
    status: "",
  });

  const [filtering, setFiltering] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [loadingMore, setLoadingMore] = useState(false);

  const [confirmNow, setConfirmNow] = useState(false);

  const [totalLocationCount, setTotalLocationCount] = useState(0);

  const is_brand_auto_renew_on = selectedBrand.is_auto_renewal;

  useEffect(() => {
    fetchPageSettings();
    fetchLocations();
  }, []);

  useEffect(() => {
    if (page > 1) {
      fetchLocations();
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    setLocations([]);
    fetchLocations(true);
  }, [locationFilters.status]);

  useEffect(() => {
    if (
      locationFilters.searchKey.length >= 3 ||
      locationFilters.searchKey === ""
    ) {
      setPage(1);
      setLocations([]);
      fetchLocations(true);
    }
  }, [locationFilters.searchKey]);

  async function fetchPageSettings() {
    setIsLoading(true);
    try {
      const subnPageRes = await subscriptionService.getSubscriptionPage(
        selectedBrand.id
      );

      if (subnPageRes.status === 200) {
        setPageInfo(subnPageRes.data);
      }
    } catch (err) {
      logger.error("Error in fetchPageSettings", { err });
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * @method fetchLocations
   * @description fetch location function
   * @param {*} filtering
   */
  const fetchLocations = async (filtering = false) => {
    setNoLocations(false);
    if (filtering) {
      setFiltering(true);
      setLoadingMore(false);
    } else {
      setLoadingMore(true);
    }

    try {
      const res = await subscriptionService.getUserSubscribedLocations({
        userId: loggedInUser.id,
        brandId: selectedBrand.id,
        offset: page * LOCATIONS_PAGE_SIZE,
        limit: LOCATIONS_PAGE_SIZE,
        ...locationFilters,
      });

      if (res.status === 200) {
        const locationData = res.data?.locationData;
        const newLocations = locationData?.locations || [];
        const totalLocationCount = locationData?.totalLocationCount;

        setLocations((prevLocations) => {
          if (filtering) {
            return newLocations;
          }
          return mergeArraysWithUniqueKey(
            prevLocations,
            newLocations,
            "fk_location_id"
          );
        });

        setHasMoreLocations(
          (filtering ? 1 : page) * LOCATIONS_PAGE_SIZE < totalLocationCount
        );
        setTotalLocationCount(totalLocationCount);

        if (!newLocations?.length && page === 1) {
          setNoLocations(true);
        }
      }
    } catch (err) {
      if (err?.__CANCEL__) {
        setTimeout(() => {
          if (filtering) {
            setFiltering(true);
          }
          setLoadingMore(false);
        }, 100);
      } else {
        setNoLocations(true);
      }
    } finally {
      setFiltering(false);
      setLoadingMore(false);
      setIsLoading(false);
    }
  };

  /**
   * @method handleSelections
   * @description handler function on confirm upcoming package change/opt-in
   * @param {*} data
   */
  const handleSelections = (data) => {
    setSelections([data]);
  };

  /**
   * @method handleOnChangeFilters
   * @description handler function on change of filter input
   * @param {*} type
   * @returns
   */
  const handleOnChangeFilters = (type) => (value) => {
    setLocationFilters((f) => ({ ...f, [type]: value }));
    setPage(1);
  };

  /**
   * @method renderSubscribedLocationFilters
   * @description render location filters
   * @returns
   */
  const renderSubscribedLocationFilters = () => {
    return (
      <div className="location-filters mb-2">
        <div className="row">
          <div className="col-lg-3 mb-3 mb-lg-0">
            <MUICustomAutocompleteSelect
              label="Status"
              name="status"
              options={
                is_brand_auto_renew_on ? statusOptions : statusOptionsOther
              }
              selectProps={{
                onChange: handleOnChangeFilters("status"),
                value: locationFilters.status,
              }}
              wrapperProps={{
                className: "px-auto",
              }}
              all
            />
          </div>
          <div className="col-lg-3">
            <MuiSearchInput
              name="searchKey"
              id="searchKey"
              value={locationFilters.searchKey}
              onChange={(value) => handleOnChangeFilters("searchKey")(value)}
              className="search"
            />
          </div>
        </div>
        <div style={{ height: 15 }}>
          <Typography variant="caption" color="primary">
            {filtering && locations?.length ? (
              <>
                <CircularProgress size={10} className="mr-2" />
                Loading..
              </>
            ) : (
              ""
            )}
          </Typography>
        </div>
      </div>
    );
  };

  /**
   * @method loadMoreLocations
   * @description render load more button
   * @returns
   */
  const loadMoreLocations = () => {
    if (!hasMoreLocations) return null;
    return (
      <div className="mt-4 mb-5 text-center">
        <Button
          onClick={() => {
            setLoadingMore(true);
            setPage((prev) => prev + 1);
          }}
          type="button"
          className="mx-auto text-capitalize"
          color="secondary"
          size="small"
          variant="outlined"
          disabled={loadingMore}
        >
          {loadingMore ? "Loading..." : "Load More"}
        </Button>
      </div>
    );
  };

  /**
   * @method renderSubscribedLocations
   * @description render subscribed locations
   * @returns
   */
  const renderSubscribedLocations = () => {
    return (
      <div className="subscribed-locations">
        <SubscribedLocationList
          locations={locations}
          onSelectPackage={handleSelections}
          onConfirm={() => setConfirmNow(true)}
          selections={selections}
          loadMoreLocations={loadMoreLocations}
          fetchLocations={fetchLocations}
          totalLocationCount={totalLocationCount}
          subscriptionDescription={pageInfo?.description}
        />
      </div>
    );
  };

  const handlePrev = () => {
    setConfirmNow(false);
  };

  if (confirmNow) {
    const isChangingUpcomingPackage = selections?.[0]?.is_changing;
    const isOptIn = selections?.[0]?.is_optIn;
    const isNewSubscription = selections?.[0]?.is_new_subscription;

    return (
      <PageLayout className="location-subscription">
        <ConfirmPackageSelection
          selections={selections}
          onPrev={handlePrev}
          pageInfo={pageInfo}
          isChangingUpcomingPackage={isChangingUpcomingPackage}
          isOptIn={isOptIn}
          isNewSubscription={isNewSubscription}
          onConfirm={() => {
            fetchLocations();
            setConfirmNow(false);
          }}
        />
      </PageLayout>
    );
  }

  const renderNoPageMessage = () => {
    if (isLoading || (!noLocation && !locations?.length))
      return <NoDataFound height={"50vh"} message="Loading..." />;
    return noLocation ? (
      <div className="mt-5 h-100">
        <NoDataFound
          message={
            <RpAlert severity="info">
              There are no subscription information available. Please contact to
              Admin
            </RpAlert>
          }
          height="30vh"
        />
      </div>
    ) : null;
  };

  return (
    <>
      <PageLayout className="mx-0 mx-sm-2">
        {isLoading ? (
          <div className="mt-5 h-100">
            <NoDataFound message="Loading..." height="90vh" />
          </div>
        ) : (
          <div className="pb-4 manage-active-subscription">
            <BackLink />
            <div className="row justify-content-center mb-2">
              <div className="col-md-10 col-lg-7">
                <Typography
                  component="h1"
                  variant="h5"
                  className="main-heading text-center my-4"
                  gutterBottom
                >
                  Current Media Plan
                </Typography>
              </div>
            </div>

            <>
              {renderSubscribedLocationFilters()}
              {locations.length
                ? renderSubscribedLocations()
                : renderNoPageMessage()}
            </>
          </div>
        )}
      </PageLayout>
    </>
  );
}
