import React from "react";
import PageLayout from "../../components/layouts/page/PageLayout";
import CampaignPause from "../../containers/campaign/campaignPause/CampaignPause";

const CampaignPausePage = () => {
  return (
    <div>
      <PageLayout className="pause-campaign-page">
        <CampaignPause />
      </PageLayout>
    </div>
  );
};

export default CampaignPausePage;
