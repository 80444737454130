import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Icon } from "@material-ui/core";

const BackLink = ({
  navTo = "",
  navReplaceTo = "",
  className = "",
  ...rest
}) => {
  const { goBack, push, replace } = useHistory();
  return (
    <div className={`${className}`}>
      <Button
        startIcon={<Icon fontSize="small">arrow_circle_left</Icon>}
        onClick={() =>
          navTo !== "" || navReplaceTo !== ""
            ? navReplaceTo !== ""
              ? replace(navReplaceTo)
              : push(navTo)
            : goBack()
        }
        style={{ color: "#164456" }}
        // color="black"
        size="small"
        {...rest}
      >
        Back
      </Button>
    </div>
  );
};

export default memo(BackLink);
