import React, { useMemo, useState } from "react";
import { Field, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  validCreatePasswordForm,
  validPassConfPass,
} from "../../../helpers/validations";
import {
  passwordMDInputFormik,
  textMDInputFormik,
} from "../../../helpers/formInputs";
import LoadingButton from "../../../components/loaders/LoadingButton";
import { authService } from "../../../services";
import { logger } from "../../../helpers/loggerHelper";

export default function CreatePasswordForm({
  token,
  email,
  subscribe,
  first_name,
  last_name,
  isNameRequired,
}) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const shouldAskForName = isNameRequired
    ? true
    : first_name || last_name
    ? false
    : true;

  async function createPassword(data) {
    setIsLoading(true);
    try {
      const res = await authService.setNewPassword(data);
      if (res.status === 201) {
        toastr.success(
          "Success",
          `${shouldAskForName ? "Account" : "Password"} created successfully`
        );
        history.replace(
          `/login?email=${data?.email}${subscribe ? "&subscribe=true" : ""}`
        );
      }
    } catch (err) {
      logger.error("Error in createPassword", { err });
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmitForm = (values) => {
    const reqData = {
      token,
      email,
      isNameRequired,
      ...values,
    };
    createPassword(reqData);
  };

  const initialValues = useMemo(() => {
    const initialV = { password: "", confirmPassword: "" };
    if (shouldAskForName) {
      initialV.first_name = "";
      initialV.last_name = "";
    }
    return initialV;
  }, [shouldAskForName]);

  return (
    <div className="create-password-form">
      <h2 className="heading">{`Create ${
        shouldAskForName ? "your account" : "your password"
      }`}</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validate={
          shouldAskForName ? validCreatePasswordForm : validPassConfPass
        }
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              {shouldAskForName ? (
                <>
                  <Field
                    name="first_name"
                    label="First Name"
                    component={textMDInputFormik}
                    autoFocus={true}
                    autoComplete="new-password"
                  />
                  <Field
                    name="last_name"
                    label="Last Name"
                    component={textMDInputFormik}
                    autoComplete="new-password"
                  />
                </>
              ) : null}

              <Field
                name="password"
                label="Password"
                component={passwordMDInputFormik}
                autoFocus={shouldAskForName ? false : true}
                autoComplete="new-password"
              />
              <Field
                name="confirmPassword"
                label="Confirm Password"
                component={passwordMDInputFormik}
                autoComplete="new-password"
              />

              <div className="mt-4">
                <LoadingButton
                  type="submit"
                  text={`Create ${shouldAskForName ? "Account" : "Password"}`}
                  isLoading={isLoading}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
