import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import PageLayout from "../components/layouts/page/PageLayout";
import LocationList from "../containers/admin/LocationList";
import { GlobalContext } from "../helpers/contexts/contexts";

const LocationListPage = () => {
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  return (
    <PageLayout className="participant-dashboard-container mx-0 mx-sm-2">
      <Typography variant="h5" className="widget-title mb-4 mb-md-auto">
        <span>Location Reports</span>
      </Typography>
      <LocationList selectedBrand={selectedBrand} loggedInUser={loggedInUser} />
    </PageLayout>
  );
};

export default LocationListPage;
