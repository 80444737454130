import axios from "axios";
import { API_BASE_URL, STRIPE_MICRO_SERVICE_API_BASE_URL } from "./appConfig";
import { errorHandler } from "../helpers/errorHandler";
import { getLocal } from "../helpers/localStore";
import { localStorageConstant } from "./constants";

const AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

export const StripeMicroServiceAxiosInstance = axios.create({
  baseURL: STRIPE_MICRO_SERVICE_API_BASE_URL,
});

/**
 * Holds axios cancel tokens
 */
let requestCall = {};
/**
 * @method takeLatestRequest
 * @description cancel previouse request of same url and type if not fullfilled
 * @param {*} config
 * @param {*} requestType
 */
export const takeLatestRequest = (
  config = {},
  requestType = "rahulppatidar"
) => {
  if (requestCall[requestType]) {
    requestCall[requestType].cancel(
      // { canceledReq: 'Only one request allowed at a time. Cancelling first' }
      { canceledReq: "Canceled due to multiple request" }
    );
  }
  requestCall[requestType] = axios.CancelToken.source();
  config.cancelToken = requestCall[requestType].token;
  return AxiosInstance(config);
};

/**
 * @method check is error because of cancel request
 * @param {*} error
 */
export const isCancelRequest = (error) => axios.isCancel(error);

const axiosInstanceReqInterceptor = function (config) {
  const token = getLocal(localStorageConstant.TOKEN_NAME);
  config.headers.apiAccessPrivateKey = process.env.REACT_APP_API_ACCESS_KEY;
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  // Do something before request is sent
  return config;
};

// A request interceptor
AxiosInstance.interceptors.request.use(
  axiosInstanceReqInterceptor,
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// A response interceptor
AxiosInstance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    errorHandler(error);
    return Promise.reject(error);
  }
);

const stripeMicroServiceAxiosInstanceReqInterceptor = function (config) {
  const token = getLocal(localStorageConstant.TOKEN_NAME);
  config.headers.apiAccessPrivateKey = process.env.REACT_APP_API_ACCESS_KEY;
  config.headers.app_token = process.env.REACT_APP_STRIPE_APP_TOKEN;
  config.headers.app_secret = process.env.REACT_APP_STRIPE_APP_SECRET;
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  // Do something before request is sent
  return config;
};

// A request interceptor
StripeMicroServiceAxiosInstance.interceptors.request.use(
  stripeMicroServiceAxiosInstanceReqInterceptor,
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// A response interceptor
StripeMicroServiceAxiosInstance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    errorHandler(error);
    return Promise.reject(error);
  }
);

export default AxiosInstance;
