import React, { useContext, useRef, useState } from "react";
import { campaignService } from "../../../../../services";
import { GlobalContext } from "../../../../../helpers/contexts/contexts";
import { FormHelperText } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import DownloadButton from "../../../../../components/muiCustom/buttons/DownloadButton";
import { toastr } from "react-redux-toastr";
import { CircularProgress } from "@material-ui/core";

function UploadAddressList({ campaign, onUpload }) {
  const fileInputRef = useRef();
  const { loggedInUser } = useContext(GlobalContext);
  const [err, serErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOnChange = async (e) => {
    const files = e.target.files;
    serErr("");

    setLoading(true);

    if (!files?.length) {
      setLoading(false);
      return onUpload({
        data: 0,
        file_name: "",
      });
    }

    try {
      // const reqData = {
      //   fileToUpload: files[0],
      //   campaign_id: campaign.id,
      //   fk_participant_id: loggedInUser?.participantData?.id,
      //   system_generated_campaign_id: campaign?.system_generated_campaign_id,
      //   fk_brand_id: campaign?.fk_brand_id,
      //   fk_location_id: loc.id,
      // };

      const fd = new FormData();
      fd.append("campaign_id", campaign?.id);
      fd.append("fk_participant_id", loggedInUser?.participantData?.id);
      fd.append(
        "system_generated_campaign_id",
        campaign?.system_generated_campaign_id
      );
      fd.append("fk_brand_id", campaign?.fk_brand_id);
      fd.append("fk_location_id", campaign?.fk_brand_id);
      fd.append("fileToUpload", files[0]);

      const res = await campaignService.postcardAddressUpload(fd);
      if (res.status === 200) {
        const data = res.data;
        if (typeof data?.data === "number" && Number(data.data) >= 500) {
          onUpload(data);
          toastr.success("Success", `Address List Uploaded`);
        } else {
          onUpload({
            data: 0,
            file_name: "",
          });
          serErr("Enter a quantity higher than 500");
        }
      }
    } catch (err) {
      onUpload({
        data: 0,
        file_name: "",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    const fileUrl = `${process.env.PUBLIC_URL}/document/Address%20Sample.xlsx`;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "Address Sample.xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      {/* <input type="file" onChange={handleOnChange} accept=".xlsx, .csv" /> */}

      <div className="col-12 pl-lg-0">
        <div className="div-content">
          <label>Upload Address List</label>
          <br />
          <Typography variant="caption" className="caption-sg">
            Upload the addresses you would like to target with this campaign
            (Minimum 500)
          </Typography>
          <div className="row d-flex align-items-center">
            <div className="col-lg-4 mb-2">
              <span>
                <DownloadButton
                  text="Download Template"
                  onClick={handleDownload}
                />
              </span>
            </div>
            <div className="col-lg-8 mb-lg-2">
              <span className="upload-template">
                <input
                  type="file"
                  onChange={handleOnChange}
                  accept=".xlsx, .csv"
                  className="file-selector-button"
                  ref={fileInputRef}
                  value={null}
                  onClick={() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.value = null;
                    }
                  }}
                ></input>
                {loading && (
                  <>
                    <CircularProgress size={10} className="mr-2" />
                    <span style={{ fontSize: "10px" }}>
                      Uploading Address...
                    </span>
                  </>
                )}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-lg-6">
              {" "}
              {err && (
                <FormHelperText component="div" color="error">
                  <div className="error-message">{err}</div>
                </FormHelperText>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Typography
                variant="caption"
                className="caption-sg format-caption"
              >
                Allowed File Type Only{" "}
                <span className="format">CSV(Comma delimited) and XLSX</span>{" "}
                Type File.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadAddressList;
