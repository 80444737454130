import React from "react";
import { Img } from "react-image";
import { CircularProgress } from "@material-ui/core";
import styled, { css } from "styled-components";
import { getImage, getFallbackImage } from "../helpers/utils";

const ImgLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  transition: all 0.2s;
  ${(props) =>
    css`
      ${props.style}
    `}
`;

const ImageRP = ({
  src = "",
  type = "",
  loaderBoxClass = "",
  loaderBoxStyle = {},
  loader = <CircularProgress size={20} />,
  ...rest
}) => {
  return (
    <Img
      src={type === "BASE64" ? src : getImage(src, type)}
      loader={loader}
      loaderContainer={(children) => (
        <ImgLoader className={` ${loaderBoxClass}`} style={loaderBoxStyle}>
          {children}
        </ImgLoader>
      )}
      unloader={<img src={getFallbackImage(type)} alt="" {...rest} />}
      loading="lazy"
      {...rest}
    />
  );
};

export default ImageRP;
