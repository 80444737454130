import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import { campaignActions } from "../../../actions/campaignActions";
import PageLayout from "../../../components/layouts/page/PageLayout";
import NoDataFound from "../../../components/NoDataFound";
import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import { joinCampaignHelper } from "../../../helpers/joinCampaignHelper";
import { canJoinCampaign } from "../../../helpers/utils";
import {
  isErrorCampaignsSelector,
  isLoadingCampaignsSelector,
  isSavedSelector,
} from "../../../selectors/campaignSelectors";
import { campaignService } from "../../../services/campaignService";
import JoinCampaignForm from "./JoinCampaignForm";
import { logger } from "../../../helpers/loggerHelper";

export default function JoinCampaignPage() {
  const { campaignId } = useParams();
  const history = useHistory();
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const dispatch = useDispatch();
  const isSavedAsDraft = useSelector(isSavedSelector);
  const isError = useSelector(isErrorCampaignsSelector);
  const isProcessingCampaign = useSelector(isLoadingCampaignsSelector);

  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [productList, setProductList] = useState(null);
  const [getAdvanceSettingData, setAdvancedSettingData] = useState(null);

  useEffect(() => {
    loader(loading || isProcessingCampaign);
  }, [loading, loader, isProcessingCampaign]);

  useEffect(() => {
    async function fetchCampaign() {
      setLoading(true);
      try {
        const campaignReqData = {
          id: campaignId,
          brand_id: selectedBrand.id,
          fk_participant_id: loggedInUser.participantData.id,
        };
        const res = await campaignService.getSingleCampaign(campaignReqData);

        if (res.status === 200) {
          let campaignData = res.data?.rows[0];
          const getProductList = res.data?.productList;
          let campaignAdvancedSettings =
            campaignData?.campaign_advanced_setting;

          if (!campaignData) {
            setCampaign(null);
          } else {
            if (campaignData.is_brand_paid) {
              campaignData.locations.subscribed = campaignData.locations
                .brandPaid?.length
                ? campaignData.locations.brandPaid
                : campaignData.locations.subscribed;
            }
            setCampaign(campaignData);
            setProductList(getProductList);
            setAdvancedSettingData(campaignAdvancedSettings);
          }
        }
      } catch (err) {
        logger.error("Error in getCampaign", { err });
      } finally {
        setLoading(false);
      }
    }
    fetchCampaign();
  }, [campaignId, selectedBrand.id, loggedInUser]);

  useEffect(() => {
    if (isSavedAsDraft) {
      dispatch(campaignActions.resetIsSavedDraftSuccess());

      if (isDraft) {
        history.replace({
          pathname: `${process.env.PUBLIC_URL}/digital-campaigns`,
        });
      } else {
        history.replace({
          pathname: `${process.env.PUBLIC_URL}/participant/confirm`,
          state: { campaignId: campaignId, from: "join-campaign" },
        });
      }
    }
  }, [isSavedAsDraft, isDraft, dispatch, campaignId]);

  useEffect(() => {
    if (isError?.response?.data) {
      const redirect = isError?.response?.data?.redirect;
      if (redirect) {
        dispatch(campaignActions.resetError());
        history.replace(`${process.env.PUBLIC_URL}/join-campaign`);
      }
    }
  }, [isError, history, dispatch]);

  const getInitialValues = useMemo(() => {
    if (!campaign) return {};
    return joinCampaignHelper.getInitialValues(campaign);
  }, [campaign]);

  if (!campaign && !loading) {
    return (
      <PageLayout>
        <NoDataFound message={`No campaign found!`} />
      </PageLayout>
    );
  } else if (!canJoinCampaign(campaign) && !loading) {
    return (
      <PageLayout>
        <NoDataFound
          message={
            <div className="d-flex-col-center">
              <div>
                You have already joined this campaign or it is
                in-active/completed/not customizable.
              </div>
              <Link
                to={`${process.env.PUBLIC_URL}/dashboard`}
                className="button primary btn btn-primary my-4"
              >
                Back to dashboard
              </Link>
            </div>
          }
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout className="join-campaign-page">
      {loading ? (
        <NoDataFound message="Loading..." />
      ) : (
        <JoinCampaignForm
          campaign={campaign}
          initialValues={getInitialValues}
          loggedInUser={loggedInUser}
          submit={(data) => dispatch(campaignActions.joinCampaign(data))}
          setIsDraft={setIsDraft}
          selectedBrand={selectedBrand}
          productList={productList}
          getAdvanceSettingData={getAdvanceSettingData}
          isDraft={isDraft}
        />
      )}
    </PageLayout>
  );
}
