import React, { memo, useMemo } from "react";
import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import PostcardCampaignLocation from "./activeLocations/PostcardCampaignLocation";
import { LocSubsStatus } from "../../../config/constants";
import RpVirtualList from "../../../components/virtual/RpVirtualList";

const InActivePostCardCampaignLocations = ({
  fallOutSideLocations = [],
  unSubscribedLocations = [],
  selectedBrand,
  values,
  campaign,
  setExpanded,
  expanded,
  setFieldValue,
  setFieldError,
}) => {
  const isThereNoInactiveLocations = useMemo(() => {
    return [...fallOutSideLocations, ...unSubscribedLocations].length === 0;
  }, [fallOutSideLocations, unSubscribedLocations]);

  const renderFallOutSideLocations = () => {
    return (
      <RpVirtualList
        items={fallOutSideLocations}
        renderItem={(loc) => {
          const locName = `${loc.location_name}`
            .concat(`, ${loc.state}`)
            .concat(`, ${loc.brand_region}`);
          return (
            <PostcardCampaignLocation
              subscriptionStatusType={LocSubsStatus.FALLOUT}
              name={locName}
              is30DaysLeftToJoinCampaign={loc.is30DaysLeftToJoinCampaign}
              key={loc.id}
              loc={loc}
              locFormData={values[loc.id]}
              campaign={campaign}
              setExpanded={setExpanded}
              expanded={expanded}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              isUnsubscribed={true}
            />
          );
        }}
      />
    );
  };

  const renderUnsubscribedLocations = () => {
    return (
      <RpVirtualList
        items={unSubscribedLocations}
        renderItem={(loc) => {
          const locName = `${loc.location_name}`
            .concat(`, ${loc.state}`)
            .concat(`, ${loc.brand_region}`);
          return (
            <PostcardCampaignLocation
              subscriptionStatusType={LocSubsStatus.UNSUBSCRIBED}
              name={locName}
              key={loc.id}
              loc={loc}
              locFormData={values[loc.id]}
              campaign={campaign}
              setExpanded={setExpanded}
              expanded={expanded}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              isUnsubscribed={true}
            />
          );
        }}
      />
    );
  };

  if (isThereNoInactiveLocations) {
    return (
      <>
        <div className="my-5 text-center">
          <Alert severity="info">
            <strong>There is no inactive location.</strong>
          </Alert>
        </div>
      </>
    );
  }

  return (
    <div className="un-subscribed-locations my-5">
      {!isThereNoInactiveLocations && selectedBrand.id === 3 && (
        <div className="mb-1 d-flex justify-content-end">
          <Button
            component={Link}
            to="/self-subscribe/jinya-ramen-bar"
            color="primary"
            className="text-case-initial"
            // variant="outlined"
            // startIcon={<Icon>info</Icon>}
          >
            <u>Click here to subscribe your location(s)</u>
          </Button>
        </div>
      )}

      {renderFallOutSideLocations()}
      {renderUnsubscribedLocations()}
    </div>
  );
};
export default memo(InActivePostCardCampaignLocations);
