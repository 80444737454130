import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const RpSweetAlert = withReactContent(Swal).mixin({
  animation: false,
  allowOutsideClick: false,
  allowEscapeKey: false,
  showCancelButton: true,
  reverseButtons: true,
  showLoaderOnConfirm: true,
  confirmButtonText: "Confirm",
  confirmButtonColor: "#04a999",
  cancelButtonColor: "transparent",
  customClass: {
    container: "",
    popup: "shadow-lg",
    cancelButton: "text-dark px-5",
    confirmButton:
      "px-5 MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary",
    actions: "w-100",
  },
  //   showClass: {
  //     backdrop:''
  //   },
});
