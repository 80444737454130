import React, { useMemo } from "react";
import { ErrorMessage, Field } from "formik";
import LoadingButton from "../../components/loaders/LoadingButton";
import { formikReactSelectInput } from "../../helpers/formInputs";

const SubscriptionForm = ({
  planData,
  values,
  onClick,
  participantLocations,
  isLoadingLocations = false,
  handleBlur,
  handleChange,
  onBlurEmail,
}) => {
  const getParticipantLocationOptions = useMemo(() => {
    if (!participantLocations || participantLocations.length === 0) {
      return [];
    }
    return participantLocations.map((l) => ({
      label: l.location_name,
      value: l.id,
    }));
  }, [participantLocations]);

  return (
    <div className="card subscription-plan shadow-sm">
      <div className="card-body">
        <div className="title-box">
          <h4 className="title">{planData.title}</h4>

          <h5 className="discount-text">
            {planData.discount ? `${planData.discount}% discount` : ""}
          </h5>
        </div>

        <h3 className="subscription-amount">
          $<span>{values.amount}</span>/mo
        </h3>
        <small className="amount-description">
          {`(Per restaurant, paid ${
            planData.period === "12" ? "annually" : "semi-annually"
          })`}
        </small>
        <div
          className="package-description"
          dangerouslySetInnerHTML={{ __html: planData.description }}
        ></div>
      </div>
      <div className="card-footer bg-white border-0">
        <form className="subscription-form">
          <div className="form-group">
            <label>1. Enter your email</label>
            <input
              name="email"
              className={`form-control ${
                values.email
                  ? getParticipantLocationOptions.length
                    ? "is-valid"
                    : "is-invalid"
                  : ""
              }`}
              autoComplete="off"
              onBlur={(e) => {
                handleBlur(e);
                //handleChange(e);
                onBlurEmail(values.email);
              }}
              onChange={handleChange}
              value={values.email}
            />
            <ErrorMessage name="email" component="div" className="error" />
          </div>
          <div className="form-group">
            <label>2. Select your location(s) to subscribe</label>
            <Field
              name="locations"
              className="form-control"
              component={formikReactSelectInput}
              disabled={
                getParticipantLocationOptions.length === 0 ? true : false
              }
              options={getParticipantLocationOptions}
              isMulti={true}
              isLoading={isLoadingLocations}
              noOptionsMessage={() =>
                values.email !== ""
                  ? "No option"
                  : "Please enter an email above to fetch locations"
              }
            />

            {/* <ErrorMessage name="locations" component="div" className="error" /> */}
          </div>
          <div className="my-4 text-center">
            <LoadingButton
              type="button"
              text="Register"
              className="mx-auto button primary"
              onClick={onClick}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubscriptionForm;
