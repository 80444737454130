import React from "react";
import { Typography } from "@material-ui/core";

import "../assets/css/MaintenancePage.scss";
import { STATIC_IMAGES } from "../helpers/staticImages";

const MaintenancePage = () => {
  return (
    <div className="container maintenance-container">
      <div className="inner">
        <div className="img-box">
          <img
            src={STATIC_IMAGES.maintenance}
            alt="Info"
            className="img-fluid"
          />
        </div>
        <Typography variant="h2" className="heading">
          Good things are on the way
        </Typography>

        <Typography variant="caption" component="p" className="description">
          Our site is undergoing scheduled maintenance and will be back shortly.
          <br />
          This will not affect your live ads—everything will continue running as
          usual. During this time we’ll be rolling out updates to make the
          platform more efficient for your local marketing.
        </Typography>
      </div>
    </div>
  );
};

export default MaintenancePage;
