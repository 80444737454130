import React from "react";
import { TextField, MenuItem, InputLabel } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import teal from "@material-ui/core/colors/teal";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import NumberFormat from "react-number-format";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PhotoCamera as PhotoCameraIcon } from "@material-ui/icons";
import { normalizePhone } from "./normalizers";
import Select from "react-select";
import InfoIcon from "@material-ui/icons/Info";

export const emailInput = ({
  input,
  label,
  placeholder = "",
  meta: { touched, error, warning },
}) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <input
          {...input}
          placeholder={placeholder}
          type="email"
          className="form-control input"
        />
        {touched &&
          ((error && <span className="error">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export const passwordInput = ({
  input,
  label,
  placeholder = "",
  meta: { touched, error, warning },
}) => {
  return (
    <div className="form-group py-4">
      <label>{label}</label>
      <div>
        <input
          {...input}
          placeholder={placeholder}
          type="password"
          className="form-control input"
        />
        {touched &&
          ((error && <span className="error">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export const textInput = ({
  input,
  label,
  placeholder = "",
  meta: { touched, error, warning },
}) => {
  return (
    <div className="form-group">
      <label className="heading09">{label}</label>
      <div>
        <input
          {...input}
          placeholder={placeholder}
          type="text"
          className="form-control input"
        />
        {touched &&
          ((error && <span className="error">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export const textAreaInput = ({
  input,
  label,
  placeholder = "",
  meta: { touched, error, warning },
}) => {
  return (
    <div className="form-group">
      <label className="heading09">{label}</label>
      <div>
        <textarea
          {...input}
          placeholder={placeholder}
          className="form-control input"
          rows="3"
        ></textarea>
        {touched &&
          ((error && <span className="error">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

/** Material inputs */

export const emailMDInput = ({
  input,
  label,
  placeholder = "",
  meta: { touched, error },
}) => {
  const err = touched && error ? error : "";
  return (
    <div className="form-group py-2">
      <div>
        <TextField
          label={label}
          variant="outlined"
          placeholder={placeholder}
          type="email"
          helperText={err}
          error={err ? true : false}
          {...input}
        />
        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

export const passwordMDInput = ({
  input,
  label,
  placeholder = "",
  autoFocus = false,
  autoComplete = "current-password",
  meta: { touched, error },
}) => {
  const err = touched && error ? error : "";
  return (
    <div className="form-group py-2">
      <div>
        <TextField
          label={label}
          variant="outlined"
          placeholder={placeholder}
          type="password"
          helperText={err}
          error={err ? true : false}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          {...input}
        />
        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

export const passwordMDInput1 = ({
  input,
  label,
  variant = "outlined",
  placeholder = "",
  className = "",
  formGroupClass = "",
  style = {},
  formGroupStyle = {},
  InputProps = {},
  disabled = false,
  size = "small",
  meta: { touched, error },
}) => {
  const err = touched && error ? error : "";
  return (
    <div className={`form-group ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <TextField
          label={label}
          variant={variant}
          placeholder={placeholder}
          type="password"
          className={`${className}`}
          style={style}
          helperText={err}
          error={err ? true : false}
          InputProps={InputProps}
          size={size}
          disabled={disabled}
          margin="dense"
          {...input}
        />
        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

export const textMDInput = ({
  input,
  label,
  placeholder = "",
  className = "",
  formGroupClass = "",
  style = {},
  formGroupStyle = {},
  InputProps = {},
  disabled = false,
  size = "small",
  meta: { touched, error },
}) => {
  const err = touched && error ? error : "";
  return (
    <div className={`form-group ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <TextField
          label={label}
          placeholder={placeholder}
          type="text"
          className={`${className}`}
          style={style}
          helperText={err}
          error={err ? true : false}
          InputProps={InputProps}
          size={size}
          disabled={disabled}
          margin="dense"
          {...input}
        />
        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

export const DateField = (props) => {
  const {
    meta: { submitting, error },
    input: { onBlur, value, ...inputProps },
    formGroupStyle = {},
    formGroupClass = "",
    label = "",
    minDate,
    ...others
  } = props;

  const onChange = (date) => {
    // Date.parse(date) ? inputProps.onChange(date.toISOString()) : inputProps.onChange(null);
    date && date.isValid()
      ? inputProps.onChange(date.toISOString())
      : inputProps.onChange(null);
  };

  const materialTheme = createMuiTheme({
    palette: {
      primary: teal,
    },
    overrides: {
      MuiPickersCalendarHeader: {
        switchHeader: {
          //  backgroundColor: teal.A200,
          color: teal.A700,
          allowKeyboardControl: {
            color: teal.A700,
          },
        },
      },
      MuiFormLabel: {
        asterisk: {
          color: `#EA3A3D!important`,
        },
      },
      InputLabel: {
        asterisk: {
          color: `#EA3A3D!important`,
        },
      },
    },
  });

  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={`form-group ${formGroupClass}`} style={formGroupStyle}>
          <label>{label}</label>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            {...inputProps}
            {...others}
            format="MMMM Do,YYYY"
            value={value && moment(value).isValid() ? moment(value) : null}
            disabled={submitting}
            onBlur={() =>
              onBlur(
                value && moment(value).isValid()
                  ? moment(value).toISOString()
                  : null
              )
            }
            error={error ? true : false}
            helperText={""}
            onChange={onChange}
            minDate={
              moment(minDate).isValid()
                ? moment(minDate).toISOString()
                : moment().toISOString()
            }
          />
          {error && <div className="error">{error}</div>}
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export const checkboxMDInput = ({
  label,
  formGroupClass = "",
  formGroupStyle = {},
  field,
  // form: { errors, touched, setFieldError, setFieldValue },
}) => {
  return (
    <div className={`form-group ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <FormControlLabel
          control={<Checkbox checked={field.value} {...field} />}
          label={label}
        />
      </div>
    </div>
  );
};

// For formik
export const DatePickerField = ({
  field,
  form: { errors, setFieldValue, isValid },
  formGroupStyle = {},
  formGroupClass = "",
  label,
  minDateMessage = "",

  disablePast = false,
  readOnly,
  variantType = "default", // default, styled
  format = "MMM Do, YYYY",
  ...other
}) => {
  const err = !isValid && errors[field.name];
  const materialTheme = createMuiTheme({
    palette: {
      primary: teal,
    },
    overrides:
      variantType === "styled"
        ? {
            MuiPickersCalendarHeader: {
              switchHeader: {
                color: teal.A700,
                allowKeyboardControl: {
                  color: teal.A700,
                },
              },
            },
            MuiInput: {
              root: {
                border: `1px solid ${teal[500]}`,
                borderRadius: 4,
                backgroundColor: "#fff",
                height: "40px",
                padding: "0 10px",
                marginTop: "0",
                width: "90%",
                "& .MuiInputBase-input": {
                  fontSize: ".8rem",
                  padding: "8px 0",
                  width: "100%",
                },
              },
              underline: {
                "&:before": {
                  content: "none",
                },
                "&:after": {
                  content: "none",
                },
              },
            },
            MuiFormLabel: {
              asterisk: {
                color: `#EA3A3D!important`,
              },
            },
            InputLabel: {
              asterisk: {
                color: `#EA3A3D!important`,
              },
            },
            MuiFormHelperText: {
              root: {
                marginLeft: 0,
              },
            },
            MuiPickersInlineKeyboardDatePicker: {
              root: {
                width: "100%",
              },
            },
            MuiInputBase: {
              root: {
                width: "100%",
              },
              asterisk: {
                color: `#EA3A3D!important`,
              },
            },
          }
        : {
            MuiFormLabel: {
              asterisk: {
                color: `#EA3A3D!important`,
              },
            },
            InputLabel: {
              asterisk: {
                color: `#EA3A3D!important`,
              },
            },
          },
  });

  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div
          className={`form-group ${formGroupClass}`}
          style={{
            ...formGroupStyle,
            width: "100%",
          }}
        >
          {variantType === "styled" && (
            <InputLabel
              shrink
              margin="dense"
              disableAnimation
              style={{
                color: "#1B262C",
                fontSize: ".92rem",
                fontWeight: "600",
                padding: "4px 0px 3px 10px",
              }}
              required={other?.required}
            >
              {label}
            </InputLabel>
          )}

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            inputVariant={variantType === "styled" ? "standard" : "outlined"}
            disablePast={disablePast}
            name={field.name}
            value={field.value}
            format={format}
            helperText={errors[field.name]}
            // invalidDateMessage={invalidDateMessage || "Invalid Date Format"}
            minDateMessage={
              minDateMessage || "Date should not be before minimal date"
            }
            //  error={Boolean(errors[field.name])}
            // onError={(error, value) => {
            //   if (
            //     error !== errors[field.name] &&
            //     value !== null &&
            //     !moment(value).isValid()
            //   ) {
            //     setFieldError(field.name, error);
            //   } else if (error !== errors[field.name] && value !== null) {
            //     setFieldError(field.name, error);
            //   }
            // }}

            error={err ? true : false}
            onChange={(date) => setFieldValue(field.name, date)}
            {...other}
            InputProps={
              variantType === "styled"
                ? {
                    style: {
                      fontSize: ".8rem",
                      width: "100%",
                    },
                    readOnly: readOnly,
                  }
                : {}
            }
            style={{ width: "100%" }}
          />
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export const selectMDInput = ({
  input,
  label,
  placeholder = "",
  className = "",
  formGroupClass = "",
  style = {},
  formGroupStyle = {},
  options = [],
  InputProps = {},
  size = "small",
  meta: { touched, error },
}) => {
  const err = touched && error ? error : "";
  return (
    <div className={`form-group ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <TextField
          select
          label={label}
          SelectProps={{
            native: true,
          }}
          InputProps={InputProps}
          helperText={err}
          error={err ? true : false}
          className={`${className}`}
          placeholder={placeholder}
          style={style}
          size={size}
          margin="dense"
          {...input}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>

        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

// formik currency input
export const currencyInput = ({
  className = "",
  style = {},
  formGroupClass = "",
  formGroupStyle = {},
  disabled = false,
  readOnly = false,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
}) => {
  return (
    <div className={`form-group ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <NumberFormat
          name={field.name}
          prefix="$"
          allowNegative={false}
          allowLeadingZeros={false}
          isNumericString={true}
          thousandSeparator={true}
          className={`form-control ${className}`}
          value={field.value}
          onValueChange={(val) => setFieldValue(field.name, val.floatValue)}
          onBlur={(e) => field.onBlur(e)}
          disabled={disabled}
          readOnly={readOnly}
          style={style}
        />
        {touched[field.name] && errors[field.name] && (
          <span className="error">{errors[field.name]}</span>
        )}
      </div>
    </div>
  );
};

export const budgetCurrencyInput = ({
  contribution,
  label,
  disabled = false,
  readOnly = false,
  placeholder = "",
  title = "",
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  subscriptionInfo,
}) => {
  //
  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <div className="label">{label}</div>
        </div>
        <div className="col-md-10">
          <div className="row">
            {subscriptionInfo !== 1 ? (
              <small className="col text-danger font-weight-bold">
                <InfoIcon
                  fontSize="small"
                  className={`mr-1 ${
                    subscriptionInfo === 0 ? "error-yellow" : "error-orange"
                  }`}
                  style={{ fontSize: "1rem" }}
                />
                {subscriptionInfo === 0
                  ? "Unsubscribed"
                  : "Campaign Out Of Subscription Period"}
              </small>
            ) : (
              <>
                <div className="col-md-6 col-lg-8">
                  <NumberFormat
                    allowNegative={false}
                    allowLeadingZeros={false}
                    thousandSeparator={true}
                    decimalScale={2}
                    // fixedDecimalScale
                    prefix={"$"}
                    name={`${field.name}`}
                    id={`${field.name}`}
                    placeholder={placeholder}
                    title={title}
                    onBlur={(e) => field.onBlur(e)}
                    value={field.value ? field.value : ""}
                    onValueChange={(values) => {
                      const { value } = values;
                      // formattedValue = $2,223
                      // value ie, 2223
                      setFieldValue(`${field.name}`, value);
                      // if (onChangeVal) {
                      //   onChangeVal({ locationId: field.name, amount: value });
                      // }
                    }}
                    disabled={disabled}
                    readOnly={readOnly}
                    style={{
                      width: "100%",
                      backgroundColor: readOnly ? "#eeeeee" : "",
                    }}
                    className={`budget_currency_input ${
                      readOnly ? "border-0" : ""
                    }`}
                  />
                  {/* {errors[field.name] && <div> {errors[field.name]} </div>} */}
                </div>
                <div className="col">{contribution}</div>
              </>
            )}

            {/* {subscriptionInfo !== 1 && (
              <small className="col text-danger font-weight-bold">
                {subscriptionInfo === 0 ? (
                  <RpIconPopover message="Unsubscribed" />
                ) : (
                  <RpIconPopover message="Campaign out of this location's subscription period" />
                )}
              </small>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

// formik email input
export const emailMDInputFormik = ({
  label,
  placeholder = "",
  labelShrink = false,
  formGroupClass = "",
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => {
  const err =
    touched[field.name] && errors[field.name] ? errors[field.name] : "";
  return (
    <div className={`form-group py-2 ${formGroupClass}`}>
      <div>
        <TextField
          label={label}
          variant="outlined"
          placeholder={placeholder}
          type="email"
          helperText={err}
          error={err ? true : false}
          InputLabelProps={{
            shrink: labelShrink ? labelShrink : undefined,
          }}
          id={field.name}
          {...field}
          {...rest}
        />
        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

export const passwordMDInputFormik = ({
  label,
  placeholder = "",
  autoFocus = false,
  autoComplete = "current-password",
  labelShrink = false,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => {
  const err =
    touched[field.name] && errors[field.name] ? errors[field.name] : "";
  return (
    <div className="form-group py-2">
      <div>
        <TextField
          {...rest}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          type="password"
          helperText={err}
          error={err ? true : false}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          InputLabelProps={{
            shrink: labelShrink ? labelShrink : undefined,
          }}
          id={field.name}
          {...field}
        />
        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

export const textMDInputFormik = ({
  label,
  placeholder = "",
  labelShrink = false,
  className = "",
  formGroupClass = "",
  style = {},
  formGroupStyle = {},
  InputProps = {},
  disabled = false,
  variant = "outlined",
  multiline = false,
  rows = 0,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => {
  const err =
    touched[field.name] && errors[field.name] ? errors[field.name] : "";

  return (
    <div className={`form-group py-2 ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <TextField
          label={label}
          variant={variant}
          placeholder={placeholder}
          type="text"
          helperText={err}
          error={err ? true : false}
          className={className}
          InputLabelProps={{
            shrink: labelShrink ? labelShrink : undefined,
          }}
          style={style}
          InputProps={InputProps}
          disabled={disabled}
          multiline={multiline}
          rows={rows ? rows : undefined}
          {...field}
          {...rest}
        />
        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

export const numberMDInputFormik = ({
  label,
  placeholder = "",
  className = "",
  labelShrink = false,
  field, // { name, value, onChange, onBlur }
  size = "large",
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
}) => {
  const err =
    touched[field.name] && errors[field.name] ? errors[field.name] : "";
  return (
    <div className={`form-group py-2 ${className}`}>
      <div>
        <TextField
          label={label}
          variant="outlined"
          placeholder={placeholder}
          type="number"
          helperText={err}
          error={err ? true : false}
          InputLabelProps={{
            shrink: labelShrink ? labelShrink : undefined,
          }}
          size={size}
          {...field}
        />
        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

/**
 * @method imageUploadFieldFormik
 * @description formik field for file input type
 */
export const imageUploadFieldFormik = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  onChangeImage,
  errorClass = "",
  hintClass = "",
  hint = "",
}) => (
  <>
    <label className="upload-btn" htmlFor={field.name}>
      <PhotoCameraIcon fontSize="small" /> Change
      <input
        id={field.name}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(event) => {
          const file = event.currentTarget.files[0];
          setFieldValue(field.name, file);
          onChangeImage(file);
        }}
        className="d-none"
      />
    </label>
    {hint !== "" && (
      <div className={`hint text-muted mt-2 ${hintClass}`}>
        <small>{hint}</small>
      </div>
    )}
    {touched[field.name] && errors[field.name] && (
      <div className={`error ${errorClass}`}>{errors[field.name]}</div>
    )}
  </>
);

export const phoneMDInputFormik = ({
  label,
  placeholder = "",
  labelShrink = false,
  className = "",
  formGroupClass = "",
  style = {},
  formGroupStyle = {},
  InputProps = {},
  disabled = false,
  variant = "outlined",
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, handleBlur, setFieldValue, validateField }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => {
  const err =
    touched[field.name] && errors[field.name] ? errors[field.name] : "";
  return (
    <div className={`form-group py-2 ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <TextField
          label={label}
          variant={variant}
          placeholder={placeholder}
          type="tel"
          helperText={err}
          error={err ? true : false}
          className={className}
          InputLabelProps={{
            shrink: labelShrink ? labelShrink : undefined,
          }}
          style={style}
          InputProps={InputProps}
          disabled={disabled}
          {...field}
          // onChange={(e) => {
          //   const onlyNum = e.target.value.replace(/[^\d]/g, "");
          //   // if (String(onlyNum).length > 10) {
          //   //   return;
          //   // }
          //   handleChange(e);
          //   setFieldValue(
          //     field.name,
          //     normalizePhone(String(onlyNum).slice(0, 10))
          //   );
          // }}

          // onChange={(e) => {
          //   handleBlur(e);
          //   setFieldValue(field.name, normalizePhone(e.target.value));
          // }}

          onBlur={(e) => {
            handleBlur(e);
            setFieldValue(field.name, normalizePhone(e.target.value));
            validateField(field.name);
          }}
          {...rest}
        />
        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

export const textAreaMDInputFormik = ({
  label,
  placeholder = "",
  labelShrink = false,
  className = "",
  formGroupClass = "",
  style = {},
  formGroupStyle = {},
  InputProps = {},
  disabled = false,
  variant = "outlined",
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => {
  const err =
    touched[field.name] && errors[field.name] ? errors[field.name] : "";
  return (
    <div className={`form-group py-2 ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <TextField
          label={label}
          variant={variant}
          placeholder={placeholder}
          type="text"
          helperText={err}
          error={err ? true : false}
          className={className}
          InputLabelProps={{
            shrink: labelShrink ? labelShrink : undefined,
          }}
          style={style}
          InputProps={InputProps}
          disabled={disabled}
          multiline
          rows={4}
          //size={size}
          {...field}
          {...rest}
        />
        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

export const formikSelectMDInput = ({
  label,
  placeholder = "",
  className = "",
  formGroupClass = "",
  style = {},
  formGroupStyle = {},
  options = [],
  InputProps = {},
  size = "small",
  variant = "outlined",
  menuOption = false,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => {
  const err =
    touched[field.name] && errors[field.name] ? errors[field.name] : "";
  return (
    <div className={`form-group ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <TextField
          select
          variant={variant}
          label={label}
          SelectProps={{
            native: menuOption ? false : true,
          }}
          InputProps={InputProps}
          helperText={err}
          error={err ? true : false}
          className={`${className}`}
          placeholder={placeholder}
          style={style}
          size={size}
          margin="dense"
          {...rest}
          {...field}
        >
          {options.map((option) =>
            menuOption ? (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ) : (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          )}
        </TextField>

        {/* {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span>{warning}</span>))} */}
      </div>
    </div>
  );
};

export const formikReactSelectInput = ({
  isLoading = false,
  placeholder = "",
  formGroupClass = "",
  formGroupStyle = {},
  options = [],
  isMulti = false,
  isClearable = true,
  isSearchable = true,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.

  ...props
}) => {
  const err =
    touched[field.name] && errors[field.name] ? errors[field.name] : "";
  return (
    <div className={`form-group ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <Select
          options={options}
          onChange={(option) => {
            setFieldValue(field.name, option);
          }}
          isLoading={isLoading}
          value={field.value}
          name={field.name}
          onBlur={field.onBlur}
          placeholder={placeholder}
          isMulti={isMulti}
          isClearable={isClearable}
          isSearchable={isSearchable}
          {...props}
        />

        {err && <div className="error">{err}</div>}
      </div>
    </div>
  );
};

export const checkboxGroupMDInput = ({
  label,
  formGroupClass = "",
  formGroupStyle = {},
  field,
  form: { setFieldValue },
  ...rest
}) => {
  return (
    <div className={`form-group ${formGroupClass}`} style={formGroupStyle}>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              {...rest}
              {...field}
              inputProps={{
                value: rest.value,
              }}
              checked={field?.value?.includes(rest.value) ? true : false}
              onChange={(e) => {
                const { checked, value } = e.target;

                const currentValue = field.value || [];

                if (checked) {
                  // if (max && currentValue.length === max) {
                  //   return;
                  // }
                  setFieldValue(field.name, [...currentValue, value]);
                } else {
                  setFieldValue(
                    field.name,
                    currentValue.filter((v) => v !== value)
                  );
                }
              }}
            />
          }
          label={label}
        />
      </div>
    </div>
  );
};
