import React, { useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
  getChartColors,
  makeUniqChartData,
  RPNumberFormat,
} from "../../helpers/utils";
import { datasetDefaultOptions } from "./ChartJsConfig";

const StackedBarChart = ({
  data = [],
  option = {},
  scales = {},
  formats = null,
}) => {
  const [chartData, setChartData] = useState({});

  const labels = Object.keys(data?.[0]?.data?.[0]);

  const options = useMemo(
    () => ({
      tooltips: {
        //intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            const { datasetIndex, value } = tooltipItem;

            const label = data.datasets[datasetIndex].label;

            const format = formats ? formats[labels[1]] : null;
            if (!value) return "";
            if (formats && format) {
              return `${label}: ${RPNumberFormat({
                prefix: format.prefix,
                suffix: format.suffix,
              }).to(parseFloat(value))}`;
            }
            return `${label}: ${RPNumberFormat().to(parseFloat(value))}`;
          },
          title: (tooltipItem) => {
            const label = tooltipItem[0].label;
            return label;
          },
        },
      },
      legend: {
        display: false,
        align: "center",
        position: "bottom",
        labels: {
          boxWidth: 10,
          fontColor: "#54545B",
          padding: 20,
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        fontColor: "#808080",
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              callback: (value) => {
                return value.slice(0, 7) + `${value.length > 7 ? ".." : ""}`;
              },
            },
          },
        ],
        yAxes: [
          {
            id: "y-axis-0",
            position: "left",
            stacked: true,
            ticks: {
              min: 0,
              maxTicksLimit: 5,
              callback: (value) => {
                const format = formats ? formats[labels[1]] : null;
                if (!value) return "";
                if (formats && format) {
                  return RPNumberFormat({
                    prefix: format.prefix,
                    suffix: format.suffix,
                  }).to(value);
                }
                return RPNumberFormat().to(value);
              },
            },
          },
        ],
        ...scales,
      },
      ...option,
    }),
    [option, scales]
  );

  const getDataSet = () => {
    const datasets = Object.keys(data)?.map((key, i) => {
      const d = data[key];
      const formatData = makeUniqChartData(d.data, labels[0]).map((d) => ({
        x: d[labels[0]],
        y: d[labels[1]],
      }));

      let ds = {
        label: d?.title,
        data: formatData,
        yAxisID: "y-axis-0",
        ...datasetDefaultOptions,
        backgroundColor: getChartColors(3)[i],
        hoverBackgroundColor: getChartColors(3)[i],
      };
      return ds;
    });

    return datasets;
  };

  useEffect(() => {
    const initData = {
      labels: getDataSet()[0].data ? getDataSet()[0].data.map((d) => d.x) : [],
      datasets: getDataSet(),
    };
    setChartData(initData);
  }, [data]);

  return (
    <div className="area-chart">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default StackedBarChart;
