import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    "@media (max-width: 576px)": {
      width: 44,
      height: 24,
    },
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#37DE64",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#37DE64",
    },
    "&$disabled": {
      "& + $track": {
        opacity: 0.5,
        backgroundColor: "rgba(0, 0, 0, 0.12)",
      },
      "& $thumb": {
        backgroundColor: "#bdbdbd",
      },
    },
    "@media (max-width: 576px)": {
      "&$checked": {
        transform: "translateX(20px)",
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
    backgroundColor: "#ffffff",
    boxShadow:
      "0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
    "@media (max-width: 576px)": {
      width: 22,
      height: 22,
    },
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#e0e0e0",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  disabled: {},
}))(({ classes, checked, onChange, disabled, className, ...props }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={className}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      {...props}
    />
  );
});

CustomSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default React.memo(CustomSwitch);
