import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Axios from "axios";

import {
  MAINTENANCE_MODE,
  MAINTENANCE_MODE_ALLOWED_IPS,
} from "../../config/appConfig";
import { authActions } from "../../actions";
import { logger } from "../../helpers/loggerHelper";

function LogoutWhenMaintenanceOn() {
  const dispatch = useDispatch();
  const location = useLocation();

  const maintenanceOnLogout = useCallback(async () => {
    try {
      // Check if IP is already cached in sessionStorage
      let ipAdd = sessionStorage.getItem("userIP");

      // If no IP stored, fetch it and store in sessionStorage
      if (!ipAdd) {
        const { data } = await Axios.get("https://api.ipify.org/");
        ipAdd = data;
        sessionStorage.setItem("userIP", ipAdd); // Cache the IP address
      }

      // Check if the IP is not allowed in maintenance mode
      if (!MAINTENANCE_MODE_ALLOWED_IPS.includes(ipAdd)) {
        dispatch(authActions.logout());
        sessionStorage.setItem("hasLoggedOut", "true"); // Set logged out status
        window.location.reload(); // Reload the page after logging out
      }
    } catch (error) {
      logger.error("Error fetching IP: ", { error });
    }
  }, [dispatch]);

  useEffect(() => {
    if (MAINTENANCE_MODE) {
      const hasLoggedOut = sessionStorage.getItem("hasLoggedOut");

      // If not already logged out, proceed with logout and IP check
      if (!hasLoggedOut) {
        maintenanceOnLogout();
      } else {
        // Clear the logged-out status after the reload to allow further navigation
        sessionStorage.removeItem("hasLoggedOut");
      }
    }
  }, [location.pathname, maintenanceOnLogout]);

  return null;
}

export default LogoutWhenMaintenanceOn;
