import React, { useEffect, useState } from "react";
import moment from "moment";
import { Icon } from "@material-ui/core";

import { campaignFormatHelper, getFormatDate } from "../../../../helpers/utils";
import LearnMoreButton from "../../../../components/muiCustom/buttons/LearnMoreButton";
import PostcardJoinNowButton from "./PostcardJoinNowButton";
import ImageRP from "../../../../components/ImageRP";

function ListViewCampaignCard({ campaign, renderArchivedJoinNowButton }) {
  const { status, brand_contribution } = campaign || {};
  const isLimited = campaign?.campaign_type === "LIMITED";

  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    if (campaign && campaign.campaign_type === "LIMITED") {
      const currentDate = moment();
      const parsedDate = moment(campaign.join_by_date);

      const duration = moment.duration(parsedDate.diff(currentDate));
      const hoursRemaining = duration.hours();
      const daysRemaining = parsedDate.diff(currentDate, "days");

      const formattedTimeRemaining = `${daysRemaining}d, ${hoursRemaining}h`;
      setTimeRemaining(formattedTimeRemaining);
    }
  }, [campaign]);

  return (
    <div className={`card h-100 ${brand_contribution ? "brand-match" : ""}`}>
      <div className="card-body py-2 pt-3">
        {campaign?.campaign_type === "LIMITED" ? (
          <div className="time-left list-view-time">
            <div className="time">{timeRemaining}</div>
            <div>Left to Join</div>
          </div>
        ) : null}

        <div className="row align-items-center text-md-left">
          <div className="col-lg-9">
            <div className="row align-items-center">
              <div className={`${isLimited ? "col-md-3" : "col-md-5"} py-2`}>
                <div className="detail">
                  <div className="title">Campaign Name</div>
                  <div
                    className={`title-val campaign-name ${
                      status === 1 || status === 3 ? "main" : ""
                    }`}
                  >
                    {campaignFormatHelper(campaign).name()}
                  </div>
                </div>
              </div>
              <div className={`${isLimited ? "col-md-3" : "col-md-4"} py-2`}>
                <div className="detail">
                  {/* <img
                    src="/img/postcard/postcard-default.png"
                    alt=""
                    className="campaign-image img-fluid"
                    style={{ maxHeight: 70 }}
                  /> */}
                  <ImageRP
                    src={campaign?.banner_image[0]?.attachment}
                    type="CREATIVE"
                    alt=""
                    className="img-fluid rounded campaign-image"
                    style={{ maxHeight: 150, objectFit: "contain" }}
                  />
                </div>
              </div>
              {campaign?.campaign_type === "LIMITED" && (
                <>
                  <div className="col-md-3  py-2">
                    <div className="detail">
                      <div className="title">Join By Date</div>
                      <div className="title-val">
                        {/* {joinByDateFormatHelper(campaign).joinByDate(
                          "MMM DD, YYYY"
                        )} */}
                        {campaign?.join_by_date ? (
                          <>
                            <Icon
                              component="img"
                              src="/img/icons/calendar.png"
                              fontSize="small"
                            />
                            {getFormatDate(campaign?.join_by_date)}
                          </>
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3  py-2">
                    <div className="detail">
                      <div className="title">Promotion Date</div>
                      <div className="title-val">
                        {campaignFormatHelper(campaign).startDate(
                          "MMM DD, YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-3 px-lg-0">
            <div className="row my-2">
              <div className="col-lg-7 pl-0 pr-1 mb-1">
                {renderArchivedJoinNowButton ? (
                  renderArchivedJoinNowButton()
                ) : (
                  <PostcardJoinNowButton campaign={campaign} />
                )}
              </div>
              <div className="col-lg-5 pl-0 text-right">
                <LearnMoreButton
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/dashboard/learn-more/postcard-campaign/${campaign?.id}`,
                    state: { campaignId: campaign?.id, from: "/dashboard" },
                  }}
                  size="medium"
                  className="px-lg-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListViewCampaignCard;
