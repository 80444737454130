import { useCallback, useContext, useEffect, useState } from "react";

import { stripeMicroService } from "../../services/stripe-microservice/stripeMicroService";
import { GlobalContext, LoaderContext } from "../contexts/contexts";
import { logger } from "../loggerHelper";

function useCustomerPortal() {
  const [isLoading, setIsLoading] = useState(false);

  const { loggedInUser } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  useEffect(() => {
    loader(isLoading);
  }, [isLoading]);

  const navigateToCustomerPortal = useCallback(async () => {
    try {
      setIsLoading(true);
      const reqData = {
        user_id: loggedInUser?.id,
        return_url: `${window?.location?.origin}/dashboard`,
      };
      const res = await stripeMicroService.openCustomerPortal(reqData);

      if (res.status === 200 && res.data?.result?.data?.url) {
        window.location.href = res.data?.result?.data?.url;
      }
    } catch (err) {
      logger.error("Error in navigateToCustomerPortal", { err });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [loggedInUser?.id]);
  return { isLoadingCustomerPortal: isLoading, navigateToCustomerPortal };
}

export default useCustomerPortal;
