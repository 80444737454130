import React, { useMemo } from "react";
import { Grid, Paper } from "@material-ui/core";
import ConfirmLocationItem from "./ConfirmLocationItem";
import { CurrencyFormateText } from "../CurrencyFormat";
import { getChannelIcons, getFormatDate } from "../../helpers/utils";

function BoostConfirmLocation({
  loc,
  channelsList,
  campaignHasOnlyPMaxChannel,
  total,
}) {
  const {
    locationInfo,
    boost_budget,
    // current_budget,
    start_date,
    end_date,
    channels,
  } = loc || {};

  const { location_name } = locationInfo || {};

  const renderChannels = useMemo(() => {
    const chArr = channels
      .map((c) => {
        const channelDetail = channelsList.find((ch) => ch.id === c);
        return channelDetail.label;
      })
      ?.sort();
    return getChannelIcons(chArr);
  }, [channelsList, channels]);

  return (
    <Paper className="my-3 px-2" elevation={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md>
          <ConfirmLocationItem
            imgSrc="/img/icons/location.svg"
            label="Location"
            value={campaignHasOnlyPMaxChannel ? "All Locations" : location_name}
          />
        </Grid>
        <Grid item xs={6} md>
          <ConfirmLocationItem
            imgSrc="/img/icons/calendar.svg"
            label="Boost Start Date"
            value={getFormatDate(start_date)}
          />
        </Grid>
        <Grid item xs={6} md>
          <ConfirmLocationItem
            imgSrc="/img/icons/calendar.svg"
            label="Boost End Date"
            value={getFormatDate(end_date)}
          />
        </Grid>
        {/* <Grid item xs={6} md={2}>
          <ConfirmLocationItem
            imgSrc="/img/icons/wallet.svg"
            label="Current Budget"
            value={<CurrencyFormateText value={current_budget} suffix=" USD" />}
          />
        </Grid> */}
        <Grid item xs={6} md>
          <ConfirmLocationItem
            imgSrc="/img/icons/wallet.svg"
            label="Boost Budget"
            value={
              <CurrencyFormateText
                value={campaignHasOnlyPMaxChannel ? total : boost_budget}
                suffix=" USD"
              />
            }
            valueProps={{
              color: "primary",
            }}
          />
        </Grid>
        <Grid item xs={6} md>
          <ConfirmLocationItem
            imgSrc="/img/icons/images.svg"
            label="Boost Channel"
            value={renderChannels}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default BoostConfirmLocation;
