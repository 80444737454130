import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "@material-ui/core";
import { LaptopOutlined } from "@material-ui/icons";
import ImageRP from "../../components/ImageRP";
import { normalizePhone } from "../../helpers/normalizers";
import useCustomerPortal from "../../helpers/hooks/useCustomerPortal";
const ProfileCard = ({
  loggedInUserRole,
  user = {},
  setCheckCurrentPass,
  setEdit,
  brand,
  selectedBrand,
}) => {
  const { navigateToCustomerPortal } = useCustomerPortal();
  const { first_name, last_name, email, mobile_no, profile_name } = user || {};
  return (
    <div
      className={`card profile-card ${loggedInUserRole === "PR" ? "profile-card-border" : ""}`}
    >
      <div className="card-header profile-card-header">
        <div className="profile-image-box">
          <div className="profile-image-container">
            <ImageRP
              className="profile-image"
              src={profile_name}
              type="PROFILE"
            />
          </div>
        </div>
      </div>

      <div className="card-body text-center profile-content">
        {/* hiding the unique id for PR */}
        {/* {loggedInUserRole === "PR" && (
          <div className="user-id-box mb-3">
            <div className="id">
              {`Unique ID : ${loggedInUser?.participantData?.system_generated_participant_id}`}
            </div>
          </div>
        )} */}
        <h2
          className={`user-name ${loggedInUserRole === "PR" ? "mb-1" : "mb-3"}  fw-b`}
        >{`${first_name} ${last_name}`}</h2>
        {loggedInUserRole === "PR" && (
          <div className="mb-3">{brand?.brand_name}</div>
        )}
        <p className="user-email">
          <Icon
            className="icon"
            fontSize="small"
            component="img"
            src="/img/icons/mail.png"
          />

          <span className="ml-1">{email}</span>
        </p>
        <p className="user-phone">
          <Icon
            className="icon"
            fontSize="small"
            component="img"
            src="/img/icons/phone.png"
          />
          <span className="ml-1">
            {mobile_no ? normalizePhone(mobile_no) : "N/A"}
          </span>
        </p>

        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/dashboard"
          fullWidth
          className="action-btn"
          startIcon={<LaptopOutlined fontSize="small" />}
        >
          Dashboard
        </Button>

        <Button
          variant="outlined"
          color="primary"
          startIcon={
            <Icon
              component="img"
              src="/img/icons/lock.svg"
              className="profile-card-start-icon"
            />
          }
          onClick={() => setCheckCurrentPass(true)}
          fullWidth
          className="action-btn"
        >
          Change Password
        </Button>

        <Button
          variant="outlined"
          color="primary"
          startIcon={
            <Icon
              component="img"
              src="/img/icons/profile_edit.svg"
              className="profile-card-start-icon"
            />
          }
          onClick={() => setEdit(true)}
          fullWidth
          className="action-btn"
        >
          Edit Profile
        </Button>

        {loggedInUserRole === "PR" &&
        selectedBrand?.has_active_subscription_page &&
        brand?.id === selectedBrand?.id ? (
          <div>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="/subscription-packages"
              fullWidth
              className="action-btn"
              startIcon={
                <Icon
                  component="img"
                  src="/img/icons/add_media_plan.svg"
                  className="profile-card-start-icon w-100 h-100"
                />
              }
            >
              Add Media Plan
            </Button>
            {selectedBrand?.id !== 1 && (
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to="/manage-subscription"
                fullWidth
                className="action-btn"
                startIcon={
                  <Icon
                    component="img"
                    src="/img/icons/manage_active_plan.svg"
                    className="profile-card-start-icon"
                  />
                }
              >
                Manage Active Plans
              </Button>
            )}
          </div>
        ) : null}

        {selectedBrand?.id !== 1 &&
          selectedBrand?.id !== 2 &&
          loggedInUserRole === "PR" && (
            <Button
              variant="outlined"
              color="primary"
              onClick={navigateToCustomerPortal}
              fullWidth
              className="action-btn"
              startIcon={
                <Icon
                  component="img"
                  src="/img/icons/manage_payment.svg"
                  className="profile-card-start-icon w-100 h-100"
                />
              }
            >
              Manage Payments
            </Button>
          )}
      </div>
    </div>
  );
};

export default memo(ProfileCard);
