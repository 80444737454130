import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import { Row, Col, Card } from "react-bootstrap";
import { Button, CircularProgress, Typography } from "@material-ui/core";

import { getFormConfig } from "./formConfigs";
import { logger } from "../../../helpers/loggerHelper";
import FormField from "./FormField";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import { requestService } from "../../../services/requestService";
import RpMUIModal from "../../../components/modals/RpMUIModal";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";
/**
 * DynamicForm Component
 *
 * Renders a dynamic form based on the provided request type.
 * Handles form validation and submission using Formik.
 *
 * @param {Object} props
 * @param {string} props.requestType - The type of request form to render
 */

const DynamicForm = ({
  requestType,
  channels,
  brandRequestList,
  locations,
  setActiveTab = () => {},
  onSuccess,
  campaigns,
}) => {
  const { selectedBrand } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  // Get form configuration based on request type
  const formConfig = getFormConfig({
    requestType,
    channels,
    brandRequestList,
    locations,
    campaigns: campaigns || [],
  });
  /**
   * Handle form submission
   * @param {Object} values - Form values
   * @param {Object} actions - Formik actions
   */
  const handleSubmit = async (values, actions) => {
    setLoading(true);
    try {
      // Append requestType from props to form values

      logger.info("values------", values);

      const fd = new FormData();
      fd.append("fk_brand_id", selectedBrand?.id);
      fd.append("name", requestType);

      if (values?.budgetDateRange) {
        values.date_range_start_date = values?.budgetDateRange?.startDate;
        values.date_range_end_date = values?.budgetDateRange?.endDate;
        values.budgetDateRange = null;
      } else if (values?.reportRequestDateRange) {
        values.date_range_start_date =
          values?.reportRequestDateRange?.startDate;
        values.date_range_end_date = values?.reportRequestDateRange?.endDate;
      } else if (values?.flight_dates) {
        values.date_range_start_date = values?.flight_dates?.startDate;
        values.date_range_end_date = values?.flight_dates?.endDate;
      }

      if (values.budget_range && values.campaign_type === "limitedTime") {
        values.fixed_budget = null;
      } else if (values.fixed_budget && values.campaign_type === "OTS") {
        values.budget_range = null;
      }
      if (values.new_location) {
        values.fk_location_id = null;
      } else if (!values.new_location) {
        values.location_details = null;
      }
      if (
        values.campaign_type === "Fixed Dates" &&
        values.flight_dates.startDate &&
        values.flight_dates.endDate
      ) {
        values.flight_dates = null;
      }
      if (values.new_location) {
        values.new_location = values.new_location === true ? "Yes" : "No";
      }
      // Format budget_range as "min-max"
      if (values.budget_range && typeof values.budget_range === "object") {
        const { min, max } = values.budget_range;
        values.budget_range = `${min}-${max}`;
      }

      Object.keys(values).forEach((k) => {
        const value = values[k];
        if (Array.isArray(value) && value[0] instanceof File) {
          // Append each file separately with the same field name
          value.forEach((file) => {
            fd.append(k, file);
          });
        } else {
          values[k] && fd.append(k, values[k]);
        }
      });

      for (let pair of fd.entries()) {
        logger.info(pair[0] + ", " + pair[1]);
      }

      const res = await requestService.brandRequestCreate(fd);
      if (res.status === 200) {
        setShowSuccessDialog(true);
        actions.resetForm({
          values: formConfig.initialValues,
        });
        setTimeout(() => {
          setActiveTab(1);
          if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
          }
        }, 4000);
      }
    } catch (err) {
      setLoading(false);
      logger.trace(err);
    } finally {
      setLoading(false);
    }
  };
  const renderForm = () => {
    return (
      <Formik
        key={requestType} // Key prop ensures form reset on request type change
        initialValues={formConfig.initialValues}
        validationSchema={formConfig.validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={false} // Prevent validation on initial render
        enableReinitialize={true} // Enable form reinitialization on prop changes
        validateOnChange
      >
        {({ isSubmitting, values, errors }) => {
          logger.info("errors", errors);
          return (
            <Form noValidate>
              <Card className="border-0">
                <Card.Body className="p-0">
                  <Row>
                    {formConfig.fields.map(
                      (field) =>
                        (!field.conditional || field.conditional(values)) && (
                          <Col
                            key={field.name}
                            xs={12}
                            className="mt-2"
                            md={12}
                            lg={12}
                          >
                            <FormField {...field} channels={channels} />
                          </Col>
                        )
                    )}
                  </Row>
                </Card.Body>
                <Card.Footer className="bg-transparent px-0 pt-5 border-0">
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      size="large"
                    >
                      Submit Request
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: "fixed",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensure it is on top of other elements
          }}
        >
          <CircularProgress color="primary" />
        </div>
      )}

      {renderForm()}
      <RpMUIModal
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        size="xl"
        persists
      >
        <div className="text-center p-4">
          <img src="/img/ads.png" alt="success" style={{ width: "150px" }} />
          {/* <CheckCircleIcon style={{ fontSize: 100 }} color="primary" /> */}
          <Typography
            variant="h3"
            className="fw-b my-4"
            color="primary"
            gutterBottom
          >
            Success!
          </Typography>
          <Typography className="fs-16 fw-sb" color="initial">
            Your request has been successfully submitted and recorded. Check
            your email for a record of your request and next steps.
          </Typography>
        </div>
      </RpMUIModal>
    </div>
  );
};

export default DynamicForm;
