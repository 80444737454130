import AxiosInstance from "../config/AxiosInstance";
import { API_BASE_URL } from "../config/appConfig";

export const morganStanleyService = {
  getMSStaticData,
  getBriefDetails,
  saveActionBrief,
  checkValidationMsid,
};

function getMSStaticData() {
  return AxiosInstance.get(`${API_BASE_URL}/getActionBriefDropdownList`);
}
function getBriefDetails(locationId) {
  return AxiosInstance.get(`${API_BASE_URL}/getBriefDetails/${locationId}`);
}

function saveActionBrief(data) {
  return AxiosInstance.post(`${API_BASE_URL}/saveActionBrief`, data);
}
function checkValidationMsid(data) {
  return AxiosInstance.post(`${API_BASE_URL}/checkValidationMsid`, data);
}
