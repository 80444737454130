import React from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DateRangeIcon from "@material-ui/icons/DateRange";

const RPCustomDateRagePicker = ({ onChangeDate, range = [null, null] }) => {
  return (
    <div>
      <DateRangePicker
        onChange={onChangeDate}
        value={range}
        clearIcon={null}
        calendarIcon={<DateRangeIcon fontSize="small" color="action" />}
      />
    </div>
  );
};

export default RPCustomDateRagePicker;
