import React, { useContext } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { GlobalContext } from "../contexts/contexts";
import AdminBODashboard from "../../containers/admin/AdminBODashboard";
import CampaignReport from "../../containers/campaignReport";
import AdminLocationReport from "../../containers/locationReport";
import ParticipantDashboard from "../../containers/participant/dashboard/ParticipantDashboard";
import Profile from "../../containers/profile/Profile";
import ThankYouPage from "../../containers/ThankYouPage";
import UserLocationsSubscriptions from "../../containers/subscriptions/userLocationsSubscriptions/UserLocationsSubscriptions";
import postcardCampaign from "../../containers/postcardCampaign/campaignReport/index";
import LearnMorePage from "../../pages/LearnMorePage";

export default function DashboardRoutes() {
  const { path } = useRouteMatch();
  const { loggedInUserRole } = useContext(GlobalContext);

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          return loggedInUserRole === "PR" ? (
            <ParticipantDashboard />
          ) : (
            <AdminBODashboard />
          );
        }}
      />

      <Route
        exact
        path={`${path}/profile`}
        render={(props) => <Profile {...props} />}
      />

      <Route
        exact
        path={`${path}/location-report/:locationSysId/:partId`}
        render={() => {
          return loggedInUserRole !== "PR" && <AdminLocationReport />;
        }}
      />

      <Route
        exact
        path={`${path}/campaign-report/:campaignSysId`}
        component={CampaignReport}
      />
      <Route
        exact
        path={`${path}/postcard-campaign-report/:campaignSysId`}
        component={postcardCampaign}
      />

      <Route
        exact
        path={`${path}/learn-more/:campaignType/:campaignId`}
        component={LearnMorePage}
      />

      <Route exact path={`${path}/thank-you`} component={ThankYouPage} />

      <Route
        exact
        path={`${path}/subscriptions`}
        component={UserLocationsSubscriptions}
      />

      <Redirect to={`${process.env.PUBLIC_URL}/404`} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}
