import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import { Button } from "@material-ui/core";

import { passwordMDInputFormik } from "../../helpers/formInputs";
import { validPassConfPass } from "../../helpers/validations";
import LoadingButton from "../../components/loaders/LoadingButton";
import { authService } from "../../services";
import { statusCodeConstants } from "../../config/constants";
import AlertBootstrap from "../../components/AlertBootstrap";
import { LoaderContext } from "../../helpers/contexts/contexts";
import MESSAGE from "../../helpers/messages";
import { loggedInUserSelector } from "../../selectors/userSelector";

const CheckCurrentPassword = ({
  user,
  isCurrentPassValid,
  onChecked,
  onCancel,
  onUpdatePass,
}) => {
  const loader = useContext(LoaderContext);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = async (values, { resetForm }) => {
    setErrorMessage("");
    setMessage("");
    setIsLoading(true);
    const data = {
      id: user.logedInUser ? user.logedInUser : user.id,
      password: values.password,
    };

    if (isCurrentPassValid) {
      // update password
      try {
        const res = await authService.updatePassword(data);

        if (statusCodeConstants.CREATED === res.status) {
          if (res.data) {
            setMessage(MESSAGE.passwordUpdateSuccess);
            setTimeout(() => {
              onUpdatePass(res.data);
            }, 3000);
          } else {
            setErrorMessage(MESSAGE.passwordUpdateFail);
          }
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setErrorMessage(MESSAGE.somethingWrong("update"));
      }
    } else {
      // verify current password
      try {
        const res = await authService.checkCurrentPassword(data);

        if (statusCodeConstants.OK === res.status) {
          onChecked(res.data);
          if (res.data) {
            resetForm();
          } else {
            setErrorMessage(MESSAGE.passwordCurrentNotMatch);
          }
        }
        setIsLoading(false);
      } catch (err) {
        onChecked(false);
        setIsLoading(false);
        setErrorMessage(MESSAGE.somethingWrong("checking"));
      }
    }
  };

  useEffect(() => {
    loader(isLoading);
  }, [isLoading]);

  return (
    <div className="login-form">
      <div className="fs-14">
        {message ? (
          <h5 className="text-center">{message}</h5>
        ) : isCurrentPassValid ? (
          "Please enter new password"
        ) : (
          "Please enter your current password"
        )}
      </div>
      {errorMessage && (
        <AlertBootstrap
          show={errorMessage}
          variant="danger"
          className="mb-1"
          dismissible={false}
          persist={false}
        >
          {errorMessage}
        </AlertBootstrap>
      )}
      {!message && (
        <Formik
          initialValues={
            isCurrentPassValid
              ? { password: "", confirmPassword: "" }
              : { password: "" }
          }
          onSubmit={onSubmitForm}
          validate={validPassConfPass}
          enableReinitialize
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name="password"
                  label="Password"
                  component={passwordMDInputFormik}
                  autoFocus={true}
                  autoComplete="new-password"
                  size="small"
                />
                {isCurrentPassValid && (
                  <Field
                    name="confirmPassword"
                    label="Confirm Password"
                    component={passwordMDInputFormik}
                    autoComplete="new-password"
                    size="small"
                  />
                )}
                <div className="form-group mt-2">
                  <div className="row">
                    <div className="col col-sm-6 text-center">
                      <LoadingButton
                        type="submit"
                        className="order-1 py-auto"
                        text={isCurrentPassValid ? "Set" : "Verify"}
                        isLoading={isLoading}
                        size="small"
                      />
                    </div>
                    <div className="col col-sm-6 text-center">
                      <Button
                        onClick={onCancel}
                        disabled={isLoading}
                        type="button"
                        variant="text"
                        color="primary"
                        className="button mr-sm-2 order-0"
                        size="large"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: loggedInUserSelector(state),
});

export default connect(mapStateToProps)(CheckCurrentPassword);
