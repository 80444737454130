import React, { useState, useContext, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import ReCAPTCHA from "react-google-recaptcha";

import { paymentService } from "../../services/paymentService";
import { LoaderContext } from "../../helpers/contexts/contexts";
import LoadingButton from "../../components/loaders/LoadingButton";
import NavigationPrompt from "react-router-navigation-prompt";
import ModalBootstrap from "../ModalBootstrap";

const CARD_OPTIONS = {
  hidePostalCode: true,
  className: "rp-stripe-card-input",
  iconStyle: "solid",
  style: {
    base: {
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      padding: 20,
      marginBottom: 20,
    },
  },
};

const CheckoutForm = ({ success, billingDetails, data, extraData, api }) => {
  const stripe = useStripe();
  const elements = useElements();
  const loader = useContext(LoaderContext);
  const history = useHistory();

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [paySuccess, setPaySuccess] = useState(false);
  const [captchaSuccess, onCaptchaSuccess] = useState(false);

  useEffect(() => {
    loader(processing);
  }, [processing, loader]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      });
      if (!error) {
        let res;
        if (api) {
          res = await api({
            stripe_id: paymentMethod.id,
            ...data,
            // captcha: captchaSuccess,
          });
        } else {
          res = await paymentService.charge({
            stripe_id: paymentMethod.id,
            ...data,
            extraData,
            // captcha: captchaSuccess,
          });
        }
        if (res.status === 200) {
          setPaySuccess(true);
          success();
        }
      } else if (error && error?.message) {
        toastr.error("Error", error?.message || "Something went wrong!");
      }
    } catch (err) {
      const isRedirect = err?.response?.data?.redirect;
      if (isRedirect) {
        setTimeout(() => {
          setProcessing(false);
          history.replace("/dashboard");
        }, 1000);
      }
    } finally {
      loader(false);
      setProcessing(false);
    }
  };

  return paySuccess ? (
    <div className="text-center py-2 pay-success-message">
      <h4 className="mb-3 heading7">Payment successful</h4>
      <h5 className="heading09 my-2">Thank you for your payment.</h5>
    </div>
  ) : (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label>
            Card<sup>*</sup>
          </label>
          <CardElement
            options={CARD_OPTIONS}
            onChange={(e) => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </div>

        {error && <div className="error">{error.message}</div>}

        <div className="d-flex-center my-5">
          {captchaSuccess ? (
            <LoadingButton
              type="submit"
              loader={processing}
              disabled={!stripe || processing || error}
              text={processing ? "Processing..." : `Pay $${data.amount}`}
            />
          ) : (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
              onChange={(value) => {
                onCaptchaSuccess(value);
              }}
            />
          )}
        </div>
      </form>

      <NavigationPrompt when={processing}>
        {({ onConfirm, onCancel }) => (
          <ModalBootstrap
            show={true}
            size="sm"
            bodyClass="py-0"
            onClose={onCancel}
            onOk={onConfirm}
            headerStyles={{ display: "none" }}
            footer={
              <div className="d-flex align-items-center">
                {/* <button
                  className="button secondary mr-1 small fs-14"
                  onClick={onCancel}
                >
                  Cancel
                </button> */}
                {/* <button
                  className="button primary w-auto px-4 fs-14"
                  onClick={onConfirm}
                >
                  Leave Anyway
                </button> */}
              </div>
            }
            footerStyles={{ border: 0 }}
          >
            <div className="text-center py-2 pay-success-message">
              <h4 className="mb-3 heading7">Please wait..</h4>
              <h5 className="heading09 my-2">Transaction is in process..</h5>
            </div>
          </ModalBootstrap>
        )}
      </NavigationPrompt>
    </>
  );
};

export default CheckoutForm;
