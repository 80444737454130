import jwtDecode from "jwt-decode";
import { isEmpty } from "lodash";

import { getLocal, clearLocal } from "./localStore";
import { localStorageConstant } from "../config/constants";
import { postBroadcastChannelMessage } from "./broadcastChannelHelper";

export const isUserLoggedIn = (token = "") => {
  if (getLocal("selectedBrand")) {
    localStorage.clear();
    return false;
  }
  if (!isEmpty(getLocal(localStorageConstant.TOKEN_NAME))) {
    try {
      // clearLocal();
      const decoded =
        token !== ""
          ? jwtDecode(token)
          : jwtDecode(getLocal(localStorageConstant.TOKEN_NAME));

      // check is token expired if yes clear local
      if (decoded.exp < Date.now() / 1000) {
        clearLocal();
        return false;
      }

      // checking is token has this keys if not clear local
      if (!decoded.role_id || !decoded.name || !decoded.exp) {
        clearLocal();
        return false;
      }
    } catch (err) {
      return false;
    }

    return true;
  } else {
    return false;
  }
};

export const reloadOtherTabs = () => {
  postBroadcastChannelMessage("reload");
};
