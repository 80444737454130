import React, { useCallback } from "react";
import { Checkbox, Typography, Divider } from "@material-ui/core";

import { subscriptionHelper } from "../../../helpers/subscriptionHelper";
import StatusChip from "../../../components/muiCustom/chip/StatusChip";
import { getFormatDate } from "../../../helpers/utils";
import { MUICustomAutocompleteSelect } from "../../../components/muiCustom/select/MUICustomAutocompleteSelect";
import { stripeSubscriptionStatus } from "../../../config/constants";
import useConfirmDialog from "../../../helpers/hooks/useConfirmDialog 1";

const Location = ({
  location,
  formProps,
  packageOptions = [],
  isLocationBelongsToSameParticipant,
  isPackageBelongsToSamePaymentType,
  showConfirmDialog,
}) => {
  const isChecked = !!formProps.values.locPackSelections.find(
    (l) => String(l.locationId) === String(location.fk_location_id)
  );

  const isLocBelongsToSameParticipant = isLocationBelongsToSameParticipant(
    location.fk_location_id,
    formProps.values.locPackSelections
  );

  const hasUpcomingSubscription =
    // location.subcription_status === stripeSubscriptionStatus.PAST_DUE ||
    location?.taken_upcoming_subscription ||
    (location?.is_upcoming_subscription &&
      !location?.is_current_subscription_active) ||
    location?.upcoming_renewal === 0;

  const renderRenewalLabel = () => {
    const isRenewing = subscriptionHelper.isRenewing(location);
    const isDelayedRenewing = location?.isDelayed;
    if (!isRenewing && !isDelayedRenewing) return null;

    if (isDelayedRenewing) {
      return (
        <span className="mt-1 ml-1">
          <StatusChip label="Expired" status="warn" className="chip" />
        </span>
      );
    }

    return (
      <>
        <span className="mt-1 ml-1">
          {location?.upcoming_renewal === 1 &&
          !location?.taken_upcoming_subscription ? (
            <StatusChip
              label="Upcoming Renewal"
              status="info"
              className="chip"
            />
          ) : null}
        </span>
      </>
    );
  };

  const pastDueLabel = () => {
    const isPastDue =
      location?.subcription_status === stripeSubscriptionStatus.PAST_DUE;
    if (!isPastDue) return null;

    return (
      <span className="mt-1 ml-1">
        <StatusChip label="Past Due" status="error" className="chip" />
      </span>
    );
  };

  const handleOnChangePackage = (val) => {
    const packageInfo = location.packageData?.find(
      (p) => String(p.id) === String(val)
    );

    if (
      !isPackageBelongsToSamePaymentType(
        location?.fk_location_id,
        val,
        formProps.values.locPackSelections
      )
    ) {
      // alert("Please select same payment type package");
      showConfirmDialog(
        "Wrong Package Selection",
        <Typography>Please select same payment type package.</Typography>
      );
      return;
    }

    // New logic
    const isAlreadyAddedLocPack = formProps.values.locPackSelections.find(
      (l) =>
        String(l.locationId) === String(location.fk_location_id) && l?.packageId
    );
    let updatedLocPackSelections = formProps.values.locPackSelections.map((l) =>
      String(l.locationId) === String(location.fk_location_id)
        ? { ...l, packageId: val, packageInfo }
        : l
    );

    if (isAlreadyAddedLocPack) {
      if (!val) {
        //remove package related info from locPackSelections
        updatedLocPackSelections = updatedLocPackSelections.map((l) =>
          String(l.locationId) === String(location.fk_location_id)
            ? {
                locationId: l.locationId,
                locationInfo: l.locationInfo,
              }
            : l
        );
      }
      // update locPackSelections with new values
      formProps.setFieldValue("locPackSelections", updatedLocPackSelections);
    } else if (val) {
      formProps.setFieldValue("locPackSelections", updatedLocPackSelections);
    }
  };

  const handleOnChangeLocation = (e) => {
    const locId = e.target.value;

    const locationInfo = location;

    if (location.subcription_status === stripeSubscriptionStatus.PAST_DUE) {
      showConfirmDialog(
        "Location has past due payment",
        <Typography>
          There are unpaid invoices in your previous subscription, please
          contact your customer success manager
        </Typography>
      );
      return;
    } else if (!isLocBelongsToSameParticipant) {
      // alert(
      //   "You can't select location from different participant."
      // );

      showConfirmDialog(
        "Wrong Participant Selection",
        <Typography>
          You can't select location from different participant.
        </Typography>
      );
      return;
    }

    // New logic
    const isAlreadyAdded = formProps.values.locPackSelections.find(
      (l) => String(l.locationId) === String(locId)
    );

    if (isAlreadyAdded) {
      formProps.setFieldValue(
        "locPackSelections",
        formProps.values.locPackSelections.filter(
          (l) => String(l.locationId) !== String(locId)
        )
      );
    } else {
      formProps.setFieldValue("locPackSelections", [
        ...formProps.values.locPackSelections,
        { locationId: locId, locationInfo },
      ]);
    }
  };

  const renderSubscriptionDate = () => {
    if (location?.isDelayed || !location?.subscription_start_date) {
      return null;
    }

    return (
      <>
        <div className="row mb-2 align-items-center">
          <div className="col-md-6 d-flex align-items-center">
            <Typography
              className="subscription-detail mr-1 mt-1 mt-xl-2"
              variant="h6"
            >
              Current Subscription:{" "}
              <Typography
                component="span"
                className="subscription-detail fw-bb d-block"
              >
                {`${getFormatDate(
                  location?.subscription_start_date,
                  "MM/DD/YYYY"
                )} - ${getFormatDate(
                  location?.subscription_end_date,
                  "MM/DD/YYYY"
                )}`}
              </Typography>
            </Typography>
          </div>
          {location?.subscription_package ? (
            <>
              <div className="col-md-1">
                <Divider orientation="vertical" />
              </div>
              <div className="col-md-5">
                <Typography
                  className={`subscription-detail ${
                    hasUpcomingSubscription ? "package " : ""
                  }`}
                  variant="h6"
                >
                  Current Plan:{" "}
                  <Typography
                    component="span"
                    color="primary"
                    className={`subscription-detail fw-bb d-block ${
                      hasUpcomingSubscription ? "package" : ""
                    }`}
                  >
                    {location?.package_title} - {location?.duration} Months
                  </Typography>
                </Typography>
              </div>
            </>
          ) : null}
        </div>
        {location?.upcoming_start_date ? (
          <div className="row align-items-center">
            <div className="col-md-6">
              <Typography className="subscription-detail" variant="body2">
                Upcoming Subscription:{" "}
                <Typography
                  component="span"
                  className="subscription-detail fw-bb d-block"
                >
                  {`${getFormatDate(
                    location?.upcoming_start_date,
                    "MM/DD/YYYY"
                  )} - ${getFormatDate(
                    location?.upcoming_end_date,
                    "MM/DD/YYYY"
                  )}`}
                </Typography>
              </Typography>
            </div>
            <div className="col-md-1 d-none d-md-block">
              <Divider orientation="vertical" />
            </div>
            <div className="col-md-5">
              <Typography
                className={`subscription-detail ${
                  hasUpcomingSubscription ? "package" : ""
                }`}
                variant="body2"
              >
                Upcoming Plan:{" "}
                <Typography
                  component="span"
                  className="subscription-detail fw-bb d-block"
                  color="dark"
                >
                  {location?.upcoming_pacakge_title}
                </Typography>
              </Typography>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div
      className={`row align-items-center p-2 location ${
        hasUpcomingSubscription ? "upcoming-subscription-renewd" : ""
      }`}
      disabled={hasUpcomingSubscription}
    >
      <div className="col-md-7 col-lg-8 mb-2 mb-md-0">
        <div className="d-flex align-items-start">
          {!hasUpcomingSubscription ? (
            <Checkbox
              edge="start"
              inputProps={{
                "aria-label": `Location ${location.fk_location_id} ${location.location_name} (${location.participantName}) checkbox`,
              }}
              color="primary"
              name="selectedLocations"
              value={location.fk_location_id}
              onChange={handleOnChangeLocation}
              onBlur={formProps.handleBlur}
              checked={isChecked}
              className="py-0"
              disabled={hasUpcomingSubscription ? true : false}
            />
          ) : (
            <span style={{ width: 0 }}></span>
          )}
          <div>
            <Typography
              color={hasUpcomingSubscription ? "dark" : "primary"}
              className="ml-1 mb-0 mr-1 location-name"
              component="label"
              variant="body2"
            >{`${location.location_name} (${location.participantName})`}</Typography>
            <span>
              {renderRenewalLabel()} {pastDueLabel()}
            </span>
          </div>
        </div>
        {renderSubscriptionDate()}
      </div>
      <div className="col-md-5 col-lg-4">
        <MUICustomAutocompleteSelect
          options={packageOptions}
          selectProps={{
            onChange: (v) => {
              handleOnChangePackage(v);
            },
            value:
              formProps.values.locPackSelections.find(
                (p) => String(p.locationId) === String(location.fk_location_id)
              )?.packageId || "",
          }}
          disabled={!isChecked}
          hideLabel
          className="mt-0"
          all
          allText="Select"
          style={{ border: 0, color: !isChecked && "#C1C1C1!important" }}
          key={isChecked}
        />
      </div>
    </div>
  );
};

export default function UserLocationList({
  locations = [],
  formProps,
  loadMoreLocations,
  totalLocationCount,
}) {
  const { showConfirmDialog, MUIConfirmDialogBox } = useConfirmDialog();
  const packageOptions = useCallback((location) => {
    return (
      location?.packageData?.map((p) => ({
        label: `${p.title}- ${p.period} Months`,
        value: p.id,
      })) || []
    );
  }, []);

  const isLocationBelongsToSameParticipant = useCallback(
    (locId, locPackSelections) => {
      if (!locPackSelections?.length) return true;

      const selectedLocationParticipants = locPackSelections.map((l) =>
        String(l?.locationInfo?.fk_participant_id)
      );

      const selectingLocationParticipantID = locations?.find(
        (l) => String(l.fk_location_id) === String(locId)
      )?.fk_participant_id;

      return selectedLocationParticipants?.some(
        (p) => String(p) === String(selectingLocationParticipantID)
      );
    },
    [locations]
  );

  const isPackageBelongsToSamePaymentType = useCallback(
    (locId, packageId, locPackSelections) => {
      // If no locations are selected or no package is selected for any location, allow selection or when packageId is not provided
      if (
        !locPackSelections?.length ||
        locPackSelections.every((l) => !l?.packageId) ||
        !packageId
      ) {
        return true;
      }

      // Get the package details for the package being selected
      const selectingPackageDetails = locations[0]?.packageData?.find(
        (p) => String(p.id) === String(packageId)
      );

      // If package details not found, prevent selection
      if (!selectingPackageDetails) {
        return false;
      }

      // Get all locations that have packages selected
      const locationsWithPackages = locPackSelections.filter(
        (l) => l?.packageId && l?.packageInfo
      );

      // If this is the first package being selected, allow it
      if (locationsWithPackages.length === 0) {
        return true;
      }

      // If we're updating the package for a location that already has one,
      // we need to exclude its current package from the payment type check
      const otherLocationsWithPackages = locationsWithPackages.filter(
        (l) => String(l.locationId) !== String(locId)
      );

      // If no other locations have packages after filtering, allow selection
      if (otherLocationsWithPackages.length === 0) {
        return true;
      }

      // Check if the new package's payment type matches all other selected packages
      const hasDifferentPaymentType = otherLocationsWithPackages.some(
        (l) =>
          String(l.packageInfo?.payment_type) !==
          String(selectingPackageDetails?.payment_type)
      );

      return !hasDifferentPaymentType;
    },
    [locations]
  );

  return (
    <>
      <div className="user-location-list">
        <div className="locations upcoming-renewal-locations px-3">
          {locations.map((location) => (
            <Location
              location={location}
              key={location.fk_location_id}
              formProps={formProps}
              packageOptions={packageOptions(location)}
              isLocationBelongsToSameParticipant={
                isLocationBelongsToSameParticipant
              }
              isPackageBelongsToSamePaymentType={
                isPackageBelongsToSamePaymentType
              }
              showConfirmDialog={showConfirmDialog}
            />
          ))}
        </div>
        {totalLocationCount ? (
          <Typography className="fs-10 mt-3" gutterBottom>
            Locations {locations?.length} of {totalLocationCount}
          </Typography>
        ) : null}
        {loadMoreLocations()}
      </div>
      <MUIConfirmDialogBox />
    </>
  );
}
