import AxiosInstance from "../config/AxiosInstance";
import { API_BASE_URL } from "../config/appConfig";

export const settingsService = { checkResourceCenter };

function checkResourceCenter(brandId) {
  return AxiosInstance.get(
    `${API_BASE_URL}/brand/brandResouceChecker/${brandId}`
  );
}
