import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

function PostcardJoinNowButton({ campaign }) {
  const { id } = campaign || {};

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to={{
        pathname: `${process.env.PUBLIC_URL}/participant/join-postcard-campaign/${id}`,
        state: {
          campaignId: id,
          from: "/dashboard",
        },
      }}
      disableElevation
      // disabled={status === 3}
      className="fs-11"
      fullWidth
    >
      Join Now
    </Button>
  );
}
export default PostcardJoinNowButton;
