import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
function MUIConfirmDialog(props) {
  const {
    show = false,
    onClose = () => {},
    title = "",
    description = "",
    dialogButtonConfig = {
      primaryButtonText: "Ok",
      secondaryButtonText: "Cancel",
      secondaryEnable: false,
      onOkAction: onClose,
      onCancelAction: onClose,
    },
  } = props;
  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button
          onClick={dialogButtonConfig?.onOkAction}
          color="primary"
          autoFocus
        >
          {dialogButtonConfig?.primaryButtonText}
        </Button>

        {dialogButtonConfig?.secondaryEnable && (
          <Button
            onClick={dialogButtonConfig?.onCancelAction}
            color="secondary"
            autoFocus
          >
            {dialogButtonConfig?.secondaryButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default MUIConfirmDialog;
