import AxiosInstance, { takeLatestRequest } from "../config/AxiosInstance";
import { API_BASE_URL } from "../config/appConfig";

export const subscriptionService = {
  getPackages,
  selfSubscribe,
  checkAndFetchParticipantLocations,
  getPackagesNew,
  getUserUnsubscribedLocations,
  getUserSubscribedLocations,
  saveSubscriptionSelection,
  clickHerePackageEmail,
  getUserLocationsSubscriptionDetail,
  getOptedOutData,
  getSubscriptionPage,
  optOutAutoRenewal,
  optInAutoRenewal,
  changePackageDetails,
  // getCampaignsToLaunch
};

function getPackages(brandId) {
  return AxiosInstance.get(`${API_BASE_URL}/subscription-packages/${brandId}`);
}

function getPackagesNew(brandId) {
  return AxiosInstance.get(
    `${API_BASE_URL}/subscriptionPackages?brandId=${brandId}`
  );
}

function selfSubscribe(data) {
  return AxiosInstance.post(`${API_BASE_URL}/self-subscribe`, data);
}

function checkAndFetchParticipantLocations({ brandId, email, packageId }) {
  // return AxiosInstance.get(
  //   `${API_BASE_URL}/brand/${brandId}/isEmailExists/${email}`
  // );

  const config = {
    method: "get",
    url: `${API_BASE_URL}/brand/${brandId}/isEmailExists/${email}`,
  };

  return takeLatestRequest(
    config,
    `checkAndFetchParticipantLocations${packageId}`
  );
}

function getUserUnsubscribedLocations(reqData) {
  const config = {
    method: "get",
    url: `${API_BASE_URL}/unsubscribed-location-listing`,
    params: reqData,
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  };

  return takeLatestRequest(config, `unsubscribedLocationListing`);
}
function getUserSubscribedLocations(reqData) {
  const config = {
    method: "get",
    url: `${API_BASE_URL}/subscribed-location-listing`,
    params: reqData,
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  };

  return takeLatestRequest(config, `subscribedLocationListing`);
}

function saveSubscriptionSelection(data) {
  return AxiosInstance.post(
    `${API_BASE_URL}/save-subscription-selection`,
    data
  );
}

function clickHerePackageEmail(data) {
  return AxiosInstance.post(`${API_BASE_URL}/click-here-package-email`, data);
}

function getUserLocationsSubscriptionDetail(reqData = {}, isSWR = false) {
  const config = {
    method: "get",
    url: `${API_BASE_URL}/subscriptions-list`,
    params: reqData,
  };
  if (isSWR) {
    return takeLatestRequest(config, "userLocationsSubscriptions").then(
      (r) => r.data
    );
  }

  if (reqData.download) {
    // to download file
    config.responseType = "arraybuffer";
  }

  return takeLatestRequest(config, "userLocationsSubscriptions");
}

function getOptedOutData(reqData = {}, isSWR = false) {
  const config = {
    method: "get",
    url: `${API_BASE_URL}/optout-list`,
    params: reqData,
  };

  if (isSWR) {
    return takeLatestRequest(config, "userLocationsSubscriptions").then(
      (r) => r.data
    );
  }

  if (reqData.download) {
    config.responseType = "arraybuffer";
  }

  return takeLatestRequest(config, "subscriptionOptedOut");
}

function getSubscriptionPage(brandId) {
  return AxiosInstance.get(`${API_BASE_URL}/subscriptionPages/${brandId}`);
}

function optOutAutoRenewal(data) {
  return AxiosInstance.post(
    `${API_BASE_URL}/subscription/locationOptOut`,
    data
  );
}

function changePackageDetails(data) {
  return AxiosInstance.post(
    `${API_BASE_URL}/subscription/changePackageDetails`,
    data
  );
}

function optInAutoRenewal(data) {
  return AxiosInstance.post(`${API_BASE_URL}/subscription/locationOptIn`, data);
}

// function getCampaignsToLaunch(brandId) {
//   return AxiosInstance.get(`${API_BASE_URL}/digital-campaigns`);
// }

// function getCampaignsToLaunch(brandId) {
//   return AxiosInstance.get(`${API_BASE_URL}/postcard-campaigns`);
// }
