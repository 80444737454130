import React, { memo, useContext, useMemo, useState } from "react";
import {
  Icon,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { GlobalContext } from "../helpers/contexts/contexts";
import NotAvailable from "./NotAvailable";
import StatusChip from "./muiCustom/chip/StatusChip";
import { getFormatDate } from "../helpers/utils";
import { stripeSubscriptionStatus } from "../config/constants";
import RpVirtualList from "./virtual/RpVirtualList";

const ProfileBrandLocations = ({ brand }) => {
  const { loggedInUser } = useContext(GlobalContext);
  const [expanded, setExpanded] = useState("0");
  const locations = useMemo(() => {
    if (brand) {
      const brandLocations = loggedInUser?.partLocationData?.filter(
        (l) => l.fk_brand_id === brand?.id
      );
      return brandLocations;
    }
    return [];
  }, [brand, loggedInUser]);

  const handleOnClickAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderSubscription = ({
    heading = "Current Subscription",
    packageName = "N/A",
    startDate = "N/A",
    endDate = "N/A",
  }) => {
    return (
      <div className="package-detail">
        <h3 className="heading">{heading}</h3>
        <div className="row p-0 d-flex align-item-center">
          <div className="col p-1 ml-2">
            <h4 className="title pl-1">Plan</h4>
            <p className="value fw-b pl-1">{packageName}</p>
          </div>
          <div className="col p-1">
            <h4 className="title">Start Date</h4>
            <p className="value fw-b">{startDate}</p>
          </div>
          <div className="col p-1">
            <h4 className="title">End Date</h4>
            <p className="value fw-b">{endDate}</p>
          </div>
        </div>
      </div>
    );
  };

  const renderSubscriptionStatus = (location = {}) => {
    const { current_subscription_status, isDelayed, is_available_for_renewal } =
      location;

    const isPastDue =
      current_subscription_status === stripeSubscriptionStatus.PAST_DUE;

    let status = (
      <>
        <StatusChip
          label="Inactive"
          status="error"
          className="location-status p-2"
        />
        {isDelayed ? (
          <StatusChip
            label="Expired"
            status="warn"
            className="location-status p-2"
          />
        ) : null}
      </>
    );

    if (current_subscription_status && Number(current_subscription_status)) {
      status = (
        <>
          <StatusChip
            label={isPastDue ? "Past Due" : "Active"}
            status={isPastDue ? "error" : "success"}
            className="location-status p-2"
          />
          {is_available_for_renewal ? (
            <StatusChip
              className="location-status p-2"
              label="Upcoming Renewal"
              status="info"
            />
          ) : null}
        </>
      );
    }
    return (
      <div className="ml-2 d-flex align-items-center gap-2 mb-2 mb-md-0">
        {status}
      </div>
    );
  };
  const renderLocations = () => {
    return (
      <RpVirtualList
        style={{
          height: 590,
          overflowX: "hidden",
        }}
        useWindowScroll={false}
        items={locations}
        renderItem={(location, i) => (
          <Accordion
            expanded={expanded === `${i}`}
            onChange={handleOnClickAccordion(`${i}`)}
            key={location.id}
            className="location"
            // TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
          >
            <AccordionSummary
              className="profile-brand-location-accordion"
              expandIcon={<ExpandMoreIcon />}
            >
              <div className="py-1 w-100">
                <div className="d-flex flex-column flex-md-row justify-content-between">
                  <div className="d-block d-md-flex align-items-center gap-2">
                    <div className="location-name-box mb-2 mb-md-0">
                      <div className="icon">
                        <Icon
                          component="img"
                          // src={STATIC_IMAGES.locationYellow}
                          src="/img/icons/location.png"
                          width="16"
                          height="16"
                          className="mr-2"
                        />
                      </div>
                      <span className="location-name mt-1">
                        {location.location_name}
                      </span>
                    </div>
                    {renderSubscriptionStatus(location)}
                  </div>
                  {location &&
                    location?.radius > 0 &&
                    location?.radius != null && (
                      <Typography
                        variant="caption"
                        component="p"
                        className="fw-b mt-1 ml-2 default-target-radius"
                      >
                        Default geotargeting: {location?.radius || "NA"} Miles
                      </Typography>
                    )}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="p-1 row location-subscription-detail ">
                <div className="col-12 col-md-6 mb-2 mb-md-0 border-bottom location-subscription-border">
                  {renderSubscription({
                    heading: "Current Plan",
                    packageName: location?.current_package || "N/A",
                    startDate: location?.subscription_start_date
                      ? getFormatDate(location?.subscription_start_date)
                      : "N/A",
                    endDate: location?.subscription_end_date
                      ? getFormatDate(location?.subscription_end_date)
                      : "N/A",
                  })}
                </div>

                <div className="col-md-6">
                  {renderSubscription({
                    heading: "Upcoming Plan",
                    packageName: location?.upcoming_package || "N/A",
                    startDate: location?.upcoming_start_date
                      ? getFormatDate(location?.upcoming_start_date)
                      : "N/A",
                    endDate: location?.upcoming_end_date
                      ? getFormatDate(location?.upcoming_end_date)
                      : "N/A",
                  })}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      />
    );
  };

  return (
    <div className="card brand-locations profile-card-border">
      <div className="card-body">
        <div className="row text-start mb-2">
          <div className="col-12 mb-2">
            <div>
              <Typography variant="h6" component="h2">
                Locations
              </Typography>
              <Typography
                variant="caption"
                component="p"
                className="brand-name mt-2"
              >
                List of your <strong>{brand?.brand_name || ""}</strong>{" "}
                locations
              </Typography>
            </div>
          </div>
        </div>

        {locations && locations.length ? (
          <div className="locations br-6 ">{renderLocations()}</div>
        ) : (
          <div className="my-3">
            <NotAvailable />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ProfileBrandLocations);
