import * as Yup from "yup";
import { debounce } from "lodash";
import { toastr } from "react-redux-toastr";
import {
  BRAND_REQUEST_TYPE,
  BRAND_REQUEST_TEMPLATE_URL,
} from "../../../config/constants";
import { requestService } from "../../../services/requestService";
import { logger } from "../../../helpers/loggerHelper";

// File type constants
const AD_COPY_FILE_TYPES = [".csv", ".xlsx", ".zip", ".pdf"];
const CREATIVE_FILE_TYPES = [
  ".jpeg",
  ".png",
  ".pdf",
  ".gif",
  ".psd",
  ".mp4",
  ".ai",
];

const ATTACHMENTS_FILE_TYPES = [
  ".csv",
  ".xlsx",
  ".zip",
  ".jpeg",
  ".png",
  ".pdf",
  ".doc",
  ".gif",
  ".psd",
];

const COMMON_FILE_TYPE = [".xlsx", ".csv"];

const BUDGET_FILE_TYPES = [".xlsx", ".csv"];
const AUDIENCE_FILE_TYPES = [".xlsx", ".csv", ".zip"];
const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

export const getTemplateUrl = async (templateConfig) => {
  const { type, params } = templateConfig;
  try {
    const response = await requestService.downloadBudgetTemplateService(
      type,
      params
    );
    logger.info(response, "response");
    return response?.data || "";
  } catch (error) {
    logger.error("Error fetching template URL:", error);
    return "";
  }
};

/**
 * Form Configuration Object
 *
 * Contains configurations for different types of request forms including:
 * - Field definitions
 * - Initial values
 * - Validation schemas
 */
export const formInputConfigs = ({
  channels = [],
  locations = [],
  campaigns = [],
} = {}) => ({
  // Group similar configurations together
  // Request Forms
  [BRAND_REQUEST_TYPE.CREATIVE_REQUEST]: {
    fields: [
      {
        type: "dropdown",
        name: "fk_channel_id",
        label: "Channels",
        options:
          channels?.map((v) => ({
            label: v.channel_name,
            value: v.id,
          })) || [],
        required: true,
      },
      {
        type: "dropdown",
        name: "fk_campaign_id",
        label: "Campaign",
        options:
          campaigns?.map((v) => ({
            label: v.campaign_name,
            value: v.id,
          })) || [],
        required: true,
        mutiple: false,
      },
      {
        type: "file",
        name: "ad_copy_file",
        label: "Ad Copy",
        accept: AD_COPY_FILE_TYPES.join(","),
        required: true,
        showTemplate: true,
        multiple: true,
        templateConfig: {
          buttonText: "Search Ad Copy Template",
          downloadLink: BRAND_REQUEST_TEMPLATE_URL.SEARCH_CREATIVE_TEMPLATE,
        },

        secondaryTemplateConfig: {
          buttonText: "Social Ad Copy Template",
          downloadLink: BRAND_REQUEST_TEMPLATE_URL.SOCIAL_AD_COPY_TEMPLATE,
        },
      },
      {
        type: "file",
        name: "creative_asset_upload_file",
        label: "Creative Asset Upload",
        accept: CREATIVE_FILE_TYPES.join(","),
        required: true,
        showTemplate: true,
        templateConfig: {
          buttonText: "Download Template",
          downloadLink:
            BRAND_REQUEST_TEMPLATE_URL.CREATIVE_SPECIFICATION_TEMPLATE,
        },
      },
    ],
    initialValues: {
      fk_channel_id: "",
      fk_campaign_id: "",
      ad_copy_file: null,
      creative_asset_upload_file: null,
    },
    validationSchema: Yup.object().shape({
      fk_channel_id: Yup.array()
        .min(1, "Please select at least one channel")
        .required("Please select at least one channel"),
      fk_campaign_id: Yup.string().required("Campaign is required"),
      ad_copy_file: Yup.mixed()
        .required("Ad Copy is required")
        .test("fileCount", "Maximum 2 files can be uploaded", (value) => {
          if (!value) return false;
          const files = Array.isArray(value) ? value : [value];
          return files.length > 0 && files.length <= 2;
        })
        .test(
          "fileType",
          "Invalid file type. Only XLSX, DOCX, or CSV files are allowed",
          (value) => {
            if (!value) return true;

            const files = Array.isArray(value) ? value : [value];

            return files.every(
              (file) =>
                file &&
                file.name &&
                AD_COPY_FILE_TYPES.some((type) =>
                  file.name.toLowerCase().endsWith(type)
                )
            );
          }
        ),
      creative_asset_upload_file: Yup.mixed()
        .required("Creative Asset is required")
        .test(
          "fileType",
          "Invalid file type. Allowed types: AI, PSD, JPEG, PNG, ZIP, GIF, MP4",
          (value) =>
            !value ||
            (value &&
              CREATIVE_FILE_TYPES.some((type) =>
                value.name.toLowerCase().endsWith(type)
              ))
        ),
    }),
  },
  // ... other configurations

  // Approval Forms
  [BRAND_REQUEST_TYPE.BUDGET_APPROVAL_CONFIRMATION_REQUEST]: {
    fields: [
      {
        type: "dateRange",
        name: "budgetDateRange",
        label: "Timeframe",
        required: true,
      },
      {
        type: "file",
        name: "budget_sheet_upload_file",
        label: "Budget Sheet Upload",
        accept: BUDGET_FILE_TYPES.join(","),
        required: true,
        buttonText: "Download Template",
        showTemplate: true,
        templateConfig: {
          type: BRAND_REQUEST_TYPE.BUDGET_APPROVAL_CONFIRMATION_REQUEST,
        },
      },
    ],
    initialValues: {
      budgetDateRange: {
        startDate: today,
        endDate: today,
      },
      budget_sheet_upload_file: null,
    },
    validationSchema: Yup.object().shape({
      budgetDateRange: Yup.object({
        startDate: Yup.string()
          .required("Start date is required")
          .matches(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format (YYYY-MM-DD)"),
        endDate: Yup.string()
          .required("End date is required")
          .matches(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format (YYYY-MM-DD)"),
      }),
      budget_sheet_upload_file: Yup.mixed()
        .required("Budget Sheet is required")
        .test(
          "fileType",
          "Invalid file type. Only XLSX or CSV files are allowed",
          (value) =>
            !value ||
            (value &&
              BUDGET_FILE_TYPES.some((type) =>
                value.name.toLowerCase().endsWith(type)
              ))
        ),
    }),
  },

  // Location Forms
  [BRAND_REQUEST_TYPE.PAUSE_REQUEST_CLOSED_LOCATION]: {
    fields: [
      {
        type: "dropdownWithAll",
        name: "fk_location_id",
        label: "Location Name",
        options:
          locations?.map((v) => ({
            label: v.location_name,
            value: v.id,
          })) || [],
        required: true,
      },
      {
        type: "date",
        name: "estimated_reopening_date",
        label: "Estimated Reopening",
        required: true,
      },
      {
        type: "text",
        name: "reason",
        label: "Reason",
        multiline: true,
      },
    ],
    initialValues: {
      fk_location_id: [],
      estimated_reopening_date: null,
      reason: "",
    },
    validationSchema: Yup.object().shape({
      fk_location_id: Yup.array()
        .min(1, "Please select at least one location")
        .required("Please select at least one location"),
      estimated_reopening_date: Yup.date()
        .typeError("Estimated Reopening date  is required")
        .required("Estimated Reopening date  is required"),
      reason: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s.,!?;:'"()-]+$/,
          "Please provide a valid reason using letters, numbers, and basic punctuation. Special characters like $, #, @, etc. are not allowed."
        )
        .min(10, "Reason must be at least 10 characters long")
        .max(500, "Reason cannot exceed 500 characters"),
    }),
  },
  [BRAND_REQUEST_TYPE.NEW_STORE_OPENING]: {
    fields: [
      {
        type: "checkbox",
        name: "new_location",
        label:
          "This Grand Opening is for a location that has not been provided to Hyperlocology",
        dependentFields: [
          {
            field: "fk_location_id",
            clearValue: [],
            whenChecked: true,
          },
          {
            field: "location_details",
            clearValue: null,
            whenChecked: false,
          },
        ],
      },
      {
        type: "dropdown",
        name: "fk_location_id",
        label: "Location Name",
        options:
          locations?.map((v) => ({
            label: v.location_name,
            value: v.id,
          })) || [],
        required: true,
        conditional: (values) => !values.new_location,
      },
      {
        type: "file",
        name: "location_detail_file",
        label: "Location Details",
        accept: COMMON_FILE_TYPE.join(","),
        required: true,
        showTemplate: true,
        templateConfig: {
          buttonText: "Download Template",
          downloadLink: BRAND_REQUEST_TEMPLATE_URL.LOCATION_UPLOAD_SHEET,
        },
        conditional: (values) => values.new_location,
      },
      {
        type: "date",
        name: "grand_opening_date",
        label: "Grand Opening Date",
      },
      {
        type: "date",
        name: "ad_start_date",
        label: "Ad Start Date",
        required: true,
      },
      {
        type: "date",
        name: "ad_end_date",
        label: "Ad End Date",
      },
      {
        type: "file",
        name: "all_creative_messaging_ad_copy_file",
        label: "All Creative Messaging Ad Copy",
        accept: AD_COPY_FILE_TYPES.join(","),
        required: true,
        multiple: true,
        showTemplate: true,
        templateConfig: {
          buttonText: "Search Ad Copy Template",
          downloadLink: BRAND_REQUEST_TEMPLATE_URL.SEARCH_CREATIVE_TEMPLATE,
        },

        secondaryTemplateConfig: {
          buttonText: "Social Ad Copy Template",
          downloadLink: BRAND_REQUEST_TEMPLATE_URL.SOCIAL_AD_COPY_TEMPLATE,
        },
      },
      {
        type: "file",
        name: "budget_sheet_upload_file",
        label: "Budget Sheet Upload",
        accept: BUDGET_FILE_TYPES.join(","),
        required: true,
        buttonText: "Download Template",
        showTemplate: true,
        templateConfig: {
          downloadLink: BRAND_REQUEST_TEMPLATE_URL.BUDGET_SHEET,
        },
      },
    ],
    initialValues: {
      new_location: false,
      fk_location_id: [],
      grand_opening_date: null,
      ad_start_date: null,
      ad_end_date: null,
      all_creative_messaging_ad_copy_file: null,
      location_detail_file: null,
      budget_sheet_upload_file: null,
    },
    validationSchema: Yup.object().shape({
      new_location: Yup.boolean(),
      fk_location_id: Yup.array().when("new_location", {
        is: false,
        then: Yup.array()
          .min(1, "Please select at least one location")
          .required("Please select at least one location"),
      }),
      location_detail_file: Yup.mixed().when("new_location", {
        is: true,
        then: Yup.mixed()
          .required("Location Details is required")
          .test(
            "fileType",
            "Invalid file type. Only XLSX, or CSV files are allowed",
            (value) =>
              !value ||
              (value &&
                COMMON_FILE_TYPE.some((type) =>
                  value.name.toLowerCase().endsWith(type)
                ))
          ),
      }),
      ad_start_date: Yup.date()
        .typeError("Ad Start Date is required")
        .required("Ad Start Date is required")
        .nullable()
        .test(
          "check-ad-start-date",
          "Ad Start Date must be after Grand Opening Date",
          function (value) {
            const { grand_opening_date } = this.parent;
            // Both dates must be present for validation
            if (!value || !grand_opening_date) {
              return true;
            }

            try {
              const adStartDate = new Date(value).getTime();
              const grandOpeningDate = new Date(grand_opening_date).getTime();

              // Return false to show error if ad start date is not before grand opening date
              return adStartDate > grandOpeningDate;
            } catch (error) {
              return false;
            }
          }
        ),
      ad_end_date: Yup.date()
        .nullable()
        .test(
          "check-ad-end-date",
          "Ad End Date must be after Ad Start Date and Grand Opening Date",
          function (value) {
            const { ad_start_date, grand_opening_date } = this.parent;

            if (!value || !ad_start_date || !grand_opening_date) {
              return true; // Skip validation if any date is missing
            }

            try {
              const adEndDate = new Date(value).getTime();
              const adStartDate = new Date(ad_start_date).getTime();
              const grandOpeningDate = new Date(grand_opening_date).getTime();

              // Get the latest date between ad_start_date and grand_opening_date
              const latestStartDate = Math.max(adStartDate, grandOpeningDate);

              return adEndDate > latestStartDate;
            } catch (error) {
              return false;
            }
          }
        ),

      all_creative_messaging_ad_copy_file: Yup.mixed()
        .required("Creative Messaging is required")
        .test("fileCount", "Maximum 2 files can be uploaded", (value) => {
          if (!value) return false;
          const files = Array.isArray(value) ? value : [value];
          return files.length > 0 && files.length <= 2;
        })
        .test(
          "fileType",
          "Invalid file type. Only XLSX, DOCX, or CSV files are allowed",
          (value) => {
            if (!value) return true;

            const files = Array.isArray(value) ? value : [value];

            return files.every(
              (file) =>
                file &&
                file.name &&
                AD_COPY_FILE_TYPES.some((type) =>
                  file.name.toLowerCase().endsWith(type)
                )
            );
          }
        ),
      budget_sheet_upload_file: Yup.mixed()
        .required("Budget Sheet is required")
        .test(
          "fileType",
          "Invalid file type. Only XLSX or CSV files are allowed",
          (value) =>
            !value ||
            (value &&
              BUDGET_FILE_TYPES.some((type) =>
                value.name.toLowerCase().endsWith(type)
              ))
        ),
    }),
  },

  // Campaign Forms
  [BRAND_REQUEST_TYPE.NEW_CAMPAIGN]: {
    fields: [
      {
        type: "text",
        name: "campaign_name",
        label: "Campaign Name",
        required: true,
      },
      {
        type: "dropdown",
        name: "campaign_type",
        label: "Campaign Type",
        options: [
          { value: "Flexible Dates", label: "Flexible Dates" },
          { value: "Fixed Dates", label: "Fixed Dates" },
        ],
        mutiple: false,
        required: true,
      },
      {
        type: "dateRange",
        name: "flight_dates",
        label: "Flight Dates",
        required: false,
        disablePast: true,
      },
      {
        type: "dropdown",
        name: "fk_channel_id",
        label: "Channels",
        options:
          channels?.map((v) => ({
            label: v.channel_name,
            value: v.id,
          })) || [],
        required: true,
      },

      {
        type: "text",
        name: "fixed_budget",
        label: "Fixed Budget",
        conditional: (values) => values.campaign_type === "Flexible Dates",
      },
      {
        type: "text",
        name: "budget_range",
        label: "Budget Range",
        conditional: (values) => values.campaign_type === "Fixed Dates",
        required: true,
      },
      {
        type: "dropdownWithAll",
        name: "fk_location_id",
        label: "Eligible Locations",
        options:
          locations?.map((v) => ({
            label: v.location_name,
            value: v.id,
          })) || [], // Will be populated dynamically with user's associated locations
        required: true,
      },
      {
        type: "dropdown",
        name: "payment_method",
        label: "Payment Method",
        options: [
          { value: "Credit Card", label: "Credit Card" },
          { value: "Invoice", label: "Invoice" },
        ],
        required: true,
      },
      {
        type: "file",
        name: "creative_asset_upload_file",
        label: "Creative Upload",
        accept: CREATIVE_FILE_TYPES.join(","),
        required: true,
        showTemplate: true,
        templateConfig: {
          buttonText: "Download Template",
          downloadLink:
            BRAND_REQUEST_TEMPLATE_URL.CREATIVE_SPECIFICATION_TEMPLATE,
        },
      },
      {
        type: "file",
        name: "ad_copy_file",
        label: "Ad Copy Upload",
        accept: AD_COPY_FILE_TYPES.join(","),
        required: true,
        showTemplate: true,
        multiple: true,
        templateConfig: {
          buttonText: "Search Ad Copy Template",
          downloadLink: BRAND_REQUEST_TEMPLATE_URL.SEARCH_CREATIVE_TEMPLATE,
        },

        secondaryTemplateConfig: {
          buttonText: "Social Ad Copy Template",
          downloadLink: BRAND_REQUEST_TEMPLATE_URL.SOCIAL_AD_COPY_TEMPLATE,
        },
      },
    ],
    initialValues: {
      campaign_name: "",
      campaign_type: "",
      flight_dates: {
        startDate: today,
        endDate: today,
      },
      fk_channel_id: [],
      creative_asset_upload_file: null,
      ad_copy_file: null,
      fixed_budget: "",
      budget_range: "",
      fk_location_id: [],
      payment_method: "",
    },
    validationSchema: Yup.object().shape({
      campaign_name: Yup.string()
        .required("Campaign Name is required")
        .max(100, "Campaign Name must be at most 100 characters")
        .test(
          "check-name-exists",
          "This campaign name is already exists",
          debounce(async function (value) {
            try {
              if (!value) return true; // Skip validation if no value entered yet

              const res = await requestService.checkCampaignNameExists({
                searchKey: value,
              });

              return res?.data?.message === "true" ? false : true; // Return true if name does not exist
            } catch (error) {
              toastr.error(
                "Error",
                "Something went wrong while checking campaign name",
                {
                  timeOut: 1000,
                }
              );
              return true;
            }
          }, 300)
        ),
      campaign_type: Yup.string().required("Campaign Type is required"),

      fixed_budget: Yup.number()
        .positive("Fixed budget must be a positive number")
        .typeError("Fixed budget must be a number"),
      fk_channel_id: Yup.array()
        .min(1, "Please select at least one channel")
        .required("Please select at least one channels"),
      budget_range: Yup.string().when("campaign_type", {
        is: "Fixed Dates",
        then: Yup.string()
          .required("Budget range is required")
          .matches(
            /^[1-9]\d{0,8}(-[1-9]\d{0,8})?$/,
            "Budget range must be in the format 'min-max' with positive numbers up to 9 digits only"
          )
          .test(
            "valid-range",
            "Minimum value should be less than the maximum value",
            (value) => {
              if (!value) return true; // Skip validation if empty
              const [min, max] = value.split("-").map(Number);
              // Only validate if both min and max exist
              if (min && max) {
                return min < max;
              }
              return true; // Pass validation if either min or max is missing
            }
          ),
        otherwise: Yup.string().nullable(),
      }),

      ad_copy_file: Yup.mixed()
        .required("Ad Copy Upload is required")
        .test("fileCount", "Maximum 2 files can be uploaded", (value) => {
          if (!value) return false;
          const files = Array.isArray(value) ? value : [value];
          return files.length > 0 && files.length <= 2;
        })
        .test(
          "fileType",
          "Invalid file type. Only XLSX, DOCX, or CSV files are allowed",
          (value) => {
            if (!value) return true;

            const files = Array.isArray(value) ? value : [value];

            return files.every(
              (file) =>
                file &&
                file.name &&
                AD_COPY_FILE_TYPES.some((type) =>
                  file.name.toLowerCase().endsWith(type)
                )
            );
          }
        ),
      creative_asset_upload_file: Yup.mixed()
        .required("Creative Upload is required")
        .test(
          "fileType",
          "Invalid file type. Allowed types: AI, PSD, JPEG, PNG, ZIP, GIF, MP4",
          (value) =>
            !value ||
            (value &&
              CREATIVE_FILE_TYPES.some((type) =>
                value.name.toLowerCase().endsWith(type)
              ))
        ),
      // fixedBudget: Yup.string().required("Fixed Budget is required"),
      // budgetRange: Yup.number().required("Budget Range is required"),
      fk_location_id: Yup.array()
        .min(1, "Please select at least one location")
        .required("Please select at least one location"),
      payment_method: Yup.string().required("Payment Method is required"),
    }),
  },

  // Other Forms
  [BRAND_REQUEST_TYPE.GENERAL_REQUEST]: {
    fields: [
      {
        type: "text",
        name: "details",
        label: "Details",
        rows: 8,
        multiline: true,
        required: true,
      },
      {
        type: "file",
        name: "attachments",
        label: "Attachments",
        accept: ATTACHMENTS_FILE_TYPES.join(","),
        required: false,
      },
    ],
    initialValues: {
      details: "",
      attachments: null,
    },
    validationSchema: Yup.object().shape({
      details: Yup.string()
        .required("Details are required")
        .test(
          "no-url",
          "Details should not start with URL",
          (value) => !/^(https?:\/\/[^\s]+)/.test(value) // Validates that input does not start with a URL
        ),
    }),
  },
  [BRAND_REQUEST_TYPE.REPORT_REQUEST]: {
    fields: [
      {
        type: "dropdownWithAll",
        name: "fk_location_id",
        label: "Locations",
        options:
          locations?.map((v) => ({
            label: v.location_name,
            value: v.id,
          })) || [],
        required: true,
      },
      {
        type: "dateRange",
        name: "reportRequestDateRange",
        label: "Date Range",
        required: true,
      },
      {
        type: "text",
        name: "preferred_metrics",
        label: "Preferred Metrics",
        rows: 4,
        multiline: true,
        required: true,
      },
      {
        type: "date",
        name: "due_date",
        label: "Due Date",
        minDate: new Date(),
        required: true,
      },
    ],
    initialValues: {
      fk_location_id: [],
      reportRequestDateRange: {
        startDate: today,
        endDate: today,
      },
      preferred_metrics: "",
      due_date: null,
    },
    validationSchema: Yup.object().shape({
      fk_location_id: Yup.array()
        .min(1, "Please select at least one location")
        .required("Please select at least one location"),
      reportRequestDateRange: Yup.object({
        startDate: Yup.string()
          .required("Start date is required")
          .matches(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format (YYYY-MM-DD)"),
        endDate: Yup.string()
          .required("End date is required")
          .matches(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format (YYYY-MM-DD)"),
      }),

      preferred_metrics: Yup.string()
        .required("Preferred Metrics are required")
        .max(1000, "Preferred Metrics cannot exceed 1000 characters"),
      due_date: Yup.date()
        .typeError("Due Date is required")
        .required("Due Date is required"),
    }),
  },
  [BRAND_REQUEST_TYPE.TARGETING_CHANGES]: {
    fields: [
      {
        type: "dropdownWithAll",
        name: "fk_location_id",
        label: "Location Name",
        options:
          locations?.map((v) => ({
            label: v.location_name,
            value: v.id,
          })) || [],
        required: true,
      },
      {
        type: "text",
        name: "radius_or_zipcodes",
        label: "Radius or Zipcodes",
        placeholder: "Enter zipcodes separated by commas...",
        required: true,
      },
      {
        type: "file",
        name: "audience_file_upload_file",
        label: "Audience File Uploads",
        accept: AUDIENCE_FILE_TYPES.join(","),
      },
    ],
    initialValues: {
      fk_location_id: [],
      radius_or_zipcodes: "",
      audience_file_upload_file: null,
    },
    validationSchema: Yup.object().shape({
      fk_location_id: Yup.array()
        .min(1, "Please select at least one location")
        .required("Please select at least one location"),
      radius_or_zipcodes: Yup.string()
        .required("Radius or Zipcodes are required")
        .test(
          "is-valid",
          "Must be a valid number or comma-separated zip codes",
          (value) => {
            // Check if the value is a valid number or a comma-separated list of zip codes
            return (
              !value || // Allow empty value
              /^\d+$/.test(value.trim()) || // Check for a single numeric value
              /^(\d{5}(-\d{4})?)(,\s*(\d{5}(-\d{4})?))*$/.test(value.trim()) // Check for comma-separated zip codes (5 or 9 digits)
            );
          }
        )
        .typeError(
          "Radius or Zipcodes must be a valid number or comma-separated zip codes"
        ),

      audience_file_upload_file: Yup.mixed().test(
        "fileType",
        "Invalid file type. Only XLSX, CSV, or ZIP files are allowed",
        (value) =>
          !value ||
          (value &&
            AUDIENCE_FILE_TYPES.some((type) =>
              value.name.toLowerCase().endsWith(type)
            ))
      ),
    }),
  },
});

/**
 * Get form configuration for a specific request type
 *
 * @param {string} requestType - The type of request form
 * @returns {Object} Form configuration including fields, initial values, and validation schema
 */
export const getFormConfig = ({
  requestType,
  channels,
  brandRequestList,
  locations,
  campaigns,
}) => {
  return (
    formInputConfigs({
      requestType,
      channels,
      brandRequestList,
      locations,
      campaigns,
    })[requestType] || {
      fields: [],
      initialValues: {},
      validationSchema: Yup.object({}),
    }
  );
};

export const REQUEST_FIELD_LABELS = {
  [BRAND_REQUEST_TYPE.CREATIVE_REQUEST]: {
    channel: "Channels",
    master_campaign_name: "Campaign",
    ad_copy_files: "Ad Copy Files",
    creative_asset_upload_url: "Creative Asset Upload",
  },

  [BRAND_REQUEST_TYPE.BUDGET_APPROVAL_CONFIRMATION_REQUEST]: {
    budgetDateRange: "Timeframe",
    budget_sheet_upload_url: "Budget Sheet Upload",
    date_range: "Timeframe",
  },

  [BRAND_REQUEST_TYPE.PAUSE_REQUEST_CLOSED_LOCATION]: {
    locations: "Location Name",
    estimated_reopening_date: "Estimated Reopening",
    details: "Reason",
  },

  [BRAND_REQUEST_TYPE.NEW_STORE_OPENING]: {
    details:
      "This Grand Opening is for a location that has not been provided to Hyperlocology",
    locations: "Location Name",
    grand_opening_date: "Grand Opening Date",
    ad_start_date: "Ad Start Date",
    ad_end_date: "Ad End Date",
    all_creative_messaging_ad_copy_url: "All Creative Messaging Ad Copy",
    budget_sheet_upload_url: "Budget Sheet Upload",
    creative_asset_upload_url: "Location Details",
  },

  [BRAND_REQUEST_TYPE.NEW_CAMPAIGN]: {
    brand_request_campaign_name: "Campaign Name",
    campaign_type: "Campaign Type",
    date_range: "Flight Dates",
    channel: "Channels",
    fixed_budget: "Fixed Budget",
    budget_range: "Budget Range",
    locations: "Eligible Locations",
    payment_method: "Payment Method",
    creative_asset_upload_url: "Creative Upload",
    ad_copy_url: "Ad Copy Upload",
    ad_copy_files: "Ad Copy Files",
  },

  [BRAND_REQUEST_TYPE.GENERAL_REQUEST]: {
    details: "Details",
    ad_copy_url: "Attachments",
  },

  [BRAND_REQUEST_TYPE.REPORT_REQUEST]: {
    locations: "Locations",
    date_range: "Date Range",
    preferred_metrics: "Preferred Metrics",
    due_date: "Due Date",
  },

  [BRAND_REQUEST_TYPE.TARGETING_CHANGES]: {
    locations: "Location Name",
    radius_or_zipcodes: "Radius or Zipcodes",
    audience_file_upload_url: "Audience File Uploads",
  },

  // Common fields that might appear in multiple request types
  common: {
    date_range: "Date Range",
    locations: "Locations",
    channel: "Channels",
    submitted_date: "Submitted Date",
    estimated_reopening_date: "Estimated Reopening Date",
    details: "Details",
    reason: "Reason",
  },
};

export const removeNotToShowFields = [
  "id",
  "fk_user_id",
  "fk_brand_id",
  "fk_brand_request_id",
  "user_name",
  "fk_channel_id",
  "fk_location_id",
  "audience_file_upload_filename",
  "creative_asset_upload_filename",
  "ad_copy_filename",
  "budget_sheet_upload_filename",
  "all_creative_messaging_ad_copy_filename",
  "brand_request_type",
  "system_generated_request_id",
  "fk_campaign_id",
  "submitted_date",
  // Keep submitted_date as we'll format it specially
];

// Function to get the label for a field
export const getFieldLabel = (key, requestType) => {
  // First check in the specific request type labels
  const requestTypeLabels = REQUEST_FIELD_LABELS[requestType] || {};
  if (requestTypeLabels[key]) {
    return requestTypeLabels[key];
  }
  // If not found, check in common labels
  if (REQUEST_FIELD_LABELS.common[key]) {
    return REQUEST_FIELD_LABELS.common[key];
  }

  // If no predefined label found, format the key as before
  return key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
};
