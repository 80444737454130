import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import StatusChip from "../../../components/muiCustom/chip/StatusChip";
import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import { MSPackagesData } from "../../../helpers/staticData";
import { getFormatDate } from "../../../helpers/utils";
import { subscriptionService } from "../../../services/subscriptionService";
import MSPackageCard from "./MSPackageCard";
import NoDataFound from "../../../components/NoDataFound";
import RpAlert from "../../../components/alerts/RpAlert";
import PageLayout from "../../../components/layouts/page/PageLayout";
import MuiSearchInput from "../../../components/muiCustom/search/MuiSearchInput";

const statusOptions = [
  { label: "All", value: "" },
  { label: "Unsubscribed", value: 0 },
  { label: "Upcoming Renewal", value: 2 },
  { label: "Expired", value: 3 },
];

const locationsRowsSize = 10;

export default function MSLocationSubscription() {
  const { loggedInUser, selectedBrand, loggedInUserRole } =
    useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const [locationList, setLocationList] = useState([]);

  const [locationsRows, setLocationsRows] = useState(locationsRowsSize);
  const [hasMoreLocations, setHasMoreLocations] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [filtering, setFiltering] = useState(false);

  const [locationFilters, setLocationFilters] = useState({
    searchKey: "",
    status: "",
  });

  const fetchLocations = useCallback(
    async (reqData = {}, loadingStatus = "") => {
      if (loadingStatus === "loadMore") {
        setLoadingMore(true);
      } else if (loadingStatus === "filter") {
        setFiltering(true);
      } else {
        loader(true);
      }
      try {
        const res = await subscriptionService.getUserUnsubscribedLocations({
          userId: loggedInUser.id,
          brandId: selectedBrand.id,
          offset: locationsRowsSize,
          ...reqData,
        });

        if (res.status === 200) {
          const locations = res.data?.locationData?.locations || [];
          const totalLocationCount = res.data?.locationData?.totalLocationCount;
          setLocationList(locations);
          setHasMoreLocations(locations.length < totalLocationCount);
        }
      } catch (err) {
        if (err?.__CANCEL__) {
          setTimeout(() => {
            if (loadingStatus === "loadMore") {
              setLoadingMore(true);
            } else if (loadingStatus === "filter") {
              setFiltering(true);
            }
          }, 100);
        }
      } finally {
        setLoadingMore(false);
        setFiltering(false);
        loader(false);
      }
    },
    [loggedInUser?.id, selectedBrand?.id, loader]
  );

  useEffect(() => {
    const isLM = locationsRowsSize !== locationsRows && locationList?.length;
    fetchLocations(
      {
        offset: locationsRows,
        ...locationFilters,
      },
      isLM ? "loadMore" : ""
    );
  }, [fetchLocations, locationsRows]);

  useEffect(() => {
    fetchLocations(
      {
        offset: locationsRowsSize,
        ...locationFilters,
      },
      "filter"
    );
  }, [fetchLocations, locationFilters]);

  const loadMoreLocations = () => {
    if (!hasMoreLocations) return null;
    return (
      <div className="mt-4 mb-5 text-center">
        <Button
          onClick={() => setLocationsRows((s) => s + locationsRowsSize)}
          type="button"
          className="mx-auto text-capitalize"
          color="secondary"
          size="small"
          variant="outlined"
        >
          {loadingMore ? "Loading..." : "Load More"}
        </Button>
      </div>
    );
  };

  const handleOnChangeFilters = (type) => (value) => {
    setLocationFilters((f) => ({ ...f, [type]: value }));
    setFiltering(true);
    // reset paging
    setLocationsRows(locationsRowsSize);
  };

  const renderLocationFilters = () => {
    if (!locationList?.length) return null;

    return (
      <div className="row">
        <div className="col-md-6">
          <MuiSearchInput
            value={locationFilters.searchKey}
            onChange={handleOnChangeFilters("searchKey")}
            name="searchKey"
            className="search"
            id="searchKey"
          />
        </div>
        <div className="col-md-6 text-left">
          <TextField
            select
            value={locationFilters.status}
            onChange={handleOnChangeFilters("status")}
            variant="outlined"
            size="small"
            placeholder="Select status"
            label="Status"
            margin="dense"
            className="status"
            color="primary"
            SelectProps={{
              displayEmpty: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="col-12 text-left" style={{ height: 25 }}>
          <div>
            <Typography variant="caption" color="primary">
              {filtering ? (
                <>
                  <CircularProgress size={10} className="mr-2" />
                  Loading..
                </>
              ) : (
                ""
              )}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const renderLocations = () => {
    if (!locationList?.length) {
      return (
        <div className="mt-2">
          <NoDataFound
            message={<RpAlert severity="info">No Location Found!</RpAlert>}
            height="30vh"
          />
        </div>
      );
    }
    return (
      <>
        {locationList.map((l) => (
          <MSLocationCard key={l.fk_location_id} location={l} />
        ))}

        {loadMoreLocations()}
      </>
    );
  };

  return (
    <>
      <PageLayout className="location-subscription ms-location-subscription mx-0 mx-sm-2">
        <main className="pb-5 mt-0">
          <div className="subscription-packages">
            <div className="container-fluid">
              <h2 className="main-heading">
                Let's get your Google advertising program launched
              </h2>
              <h3 className="sub-heading">
                1. Learn about the Google Advertising Program Tier Options
              </h3>
              <div className="page-description">
                There are three tiers that you can participate in within this
                program: Tier 1, Tier 2 and Tier 3. tier 1 branded keywords run
                in all states you're registered in, Tier 2 non-branded keywords
                and Tier 3 banner ads run in a specified radius surrounding your
                location. For more information about the three program options
                visit the Google Adwords program 3DR page.
              </div>
              <div className="row align-items-stretch my-5 text-left">
                {MSPackagesData.map((p) => (
                  <div className="col-lg-4 mb-5" key={p.id}>
                    <MSPackageCard plan={p} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="container-fluid text-center">
            <h3 className="sub-heading mb-5">
              2. Submit your activation brief
            </h3>
            <div>{renderLocationFilters()}</div>
            <div>{renderLocations()}</div>
            {loggedInUserRole === "PR" ||
              (loggedInUserRole === "BU" && (
                <div className="row justify-content-center mt-5">
                  <div className="col-md-4">
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="/morgan-stanley-activation"
                      className="py-2"
                      fullWidth
                    >
                      Submit New Brief
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </main>
      </PageLayout>
    </>
  );
}

function MSLocationCard({ location }) {
  const RenderStatus = useMemo(() => {
    const { is_current_subscription_active, isDelayed } = location;
    if (is_current_subscription_active) {
      return <StatusChip label="Renewal" status="info" className="chip mr-1" />;
    } else if (isDelayed) {
      return <StatusChip label="Expired" status="warn" className="chip mr-1" />;
    } else {
      return (
        <StatusChip label="Unsubscribed" status="error" className="chip mr-1" />
      );
    }
  }, [location]);

  const RenderDate = useMemo(() => {
    const { is_current_subscription_active, subscription_end_date } = location;
    if (is_current_subscription_active) {
      return `Subscribed until ${getFormatDate(subscription_end_date)}`;
    }
  }, [location]);

  return (
    <div className="card mb-2 ms-location-card border-0 shadow-sm">
      <div className="card-body text-left py-2">
        <div className="row">
          <div className="col-md-10 mb-2">
            <Typography color="primary" variant="h6" gutterBottom>
              {`${location?.location_name} (${location?.participantName})`}
            </Typography>
            <div className="d-flex-center justify-content-start">
              {RenderStatus}
              <Typography variant="body2" color="textSecondary">
                {RenderDate}
              </Typography>
            </div>
          </div>
          <div className="col-md-2 mb-2 d-flex-center">
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              disableElevation
              component={Link}
              to={`/morgan-stanley-activation/${location?.system_generated_location_id}`}
            >
              {location?.is_current_subscription_active ? "Renew" : "Subscribe"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
