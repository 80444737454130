import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Button, Typography } from "@material-ui/core";

import BoostConfirmLocation from "../../../../components/confirmScreen/BoostConfirmLocation";
import BoostConfirmTotal from "../../../../components/confirmScreen/BoostConfirmTotal";
import { campaignService } from "../../../../services";
import {
  GlobalContext,
  LoaderContext,
} from "../../../../helpers/contexts/contexts";
import ConfirmStripeEmbedCheckoutPopup from "../../../../components/stripe/embedCheckout/ConfirmStripeEmbedCheckoutPopup";
import { boostCampaignHelper } from "../../../../helpers/boostCampaignHelper";
import { stripeMicroService } from "../../../../services/stripe-microservice/stripeMicroService";
import { BOOST_CAMP_PAY_TYPE } from "../../../../config/constants";
import { logger } from "../../../../helpers/loggerHelper";
// import RpVirtualList from "../../../../components/virtual/RpVirtualList";

function ConfirmBoostCampaign({
  selections,
  campaign,
  onMakeChange,
  campaignHasOnlyPMaxChannel,
}) {
  const history = useHistory();

  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);
  const [openPayment, setOpenPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loader(isLoading);
  }, [isLoading]);

  const total = useMemo(
    () => boostCampaignHelper.getBoostTotal(selections),
    [selections]
  );

  const reqData = useMemo(() => {
    return {
      locationData: selections,
      fk_campaign_id: campaign?.id,
      system_generated_campaign_id: campaign?.system_generated_campaign_id,
      fk_campaign_name: campaign?.campaign_name,
      email_id: loggedInUser?.email,
      user_id: loggedInUser?.id,
      user_name: `${loggedInUser?.first_name} ${loggedInUser?.last_name}`,
      fk_brand_id: selectedBrand?.id,
      system_generated_brand_id: selectedBrand?.system_generated_brand_id,
      fk_brand_name: selectedBrand?.brand_name,
      payment_status:
        campaign?.boost_payment_method === BOOST_CAMP_PAY_TYPE.STRIPE ? 0 : 1,
      payment_method: campaign?.boost_payment_method,
      total_budget: total,
      return_url: "/boost-campaign-return",
      is_only_PMax: campaignHasOnlyPMaxChannel ? 1 : 0,
    };
  }, [loggedInUser, selectedBrand, campaign, selections]);

  const handleConfirm = useCallback(async () => {
    if (!selections?.length) {
      toastr.error("Error", "Please select at least one location");
      return;
    }

    if (campaign?.boost_payment_method === BOOST_CAMP_PAY_TYPE.STRIPE) {
      setOpenPayment(true);
    } else {
      try {
        setIsLoading(true);
        const res = await campaignService.saveCampaignBoostBudget(reqData);
        if (res.status === 200) {
          history.push("/dashboard/thank-you?type=campaign_boost", {
            from: "boost_campaign_confirm",
          });
        }
      } catch (err) {
        logger.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [reqData, history, selections]);

  return (
    <>
      <div>
        {/* <div
          style={{
            marginBottom: `${Math.ceil((selections?.length || 1) / 2.3)}rem`,
          }}
        >
          <RpVirtualList
            items={selections}
            renderItem={(loc) => (
              <BoostConfirmLocation
                key={loc?.locationId}
                loc={loc}
                channelsList={campaign?.channelNames}
              />
            )}
          />
        </div> */}

        {selections?.slice(0, 1)?.map((loc) => (
          <BoostConfirmLocation
            key={loc?.locationId}
            loc={loc}
            channelsList={campaign?.channelNames}
            campaignHasOnlyPMaxChannel={campaignHasOnlyPMaxChannel}
            total={total}
          />
        ))}

        <BoostConfirmTotal selections={selections} />
        <div className="row my-5 align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <Typography variant="caption">
              Once 'Confirm' is selected, you can't make changes, your
              selections will be final for this campaign
            </Typography>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-lg-end">
              <Button className="px-4 mr-1" onClick={onMakeChange}>
                Make changes
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="px-5"
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>

      {openPayment ? (
        <ConfirmStripeEmbedCheckoutPopup
          makePayment={openPayment}
          title="Payment"
          data={{
            email: loggedInUser.email,
            amount: total,
            fk_brand_id: selectedBrand.id,
            system_generated_brand_id: selectedBrand.system_generated_brand_id,
            fk_brand_name: selectedBrand.brand_name,
            ...reqData,
          }}
          onClose={() => setOpenPayment(false)}
          return_uri={`/boost-campaign-return`}
          api={stripeMicroService.boostCampaignPayment}
        />
      ) : null}
    </>
  );
}

export default ConfirmBoostCampaign;
