import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const MuiDarkButton = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.dark.main,
  },
  contained: {
    background: theme.palette.dark.main,
    color: theme.palette.dark.contrastText,
    "&:hover": {
      background: theme.palette.dark.dark,
      color: theme.palette.dark.contrastText + "!important",
    },
  },
  outlined: {
    "&:hover": {
      background: theme.palette.dark.dark,
      color: theme.palette.dark.contrastText,
    },
  },
}))(Button);

export default MuiDarkButton;
