import React, { useMemo } from "react";
import { Button, Typography } from "@material-ui/core";

import { subscriptionHelper } from "../../../helpers/subscriptionHelper";
import { CurrencyFormateText } from "../../../components/CurrencyFormat";

const SelectedPackagesPerLocation = ({ selections = [] }) => {
  return selections.map((s) => (
    <div key={s.locationInfo.fk_location_id} className="my-2">
      <Typography
        className="location-name"
        color="primary"
        variant="body2"
        gutterBottom
      >
        {s.locationInfo.location_name}
      </Typography>
      <Typography className="media-fee" variant="caption">
        <span>{`${s.packageInfo?.title} - ${s.packageInfo?.period} Months`}</span>
        <span className="ml-1 fw-b min-w-px-125">
          <CurrencyFormateText value={s.packageInfo?.amount} /> per month
        </span>
      </Typography>
      <Typography className="subscription-fee" variant="caption">
        <span>Fee</span>
        <span className="ml-1 fw-b min-w-px-125">
          <CurrencyFormateText value={s.packageInfo?.extra_charge} /> per month
        </span>
      </Typography>
    </div>
  ));
};

export default function PackageSelectionSummary({ onContinue, formProps }) {
  const selections = useMemo(() => {
    const { locPackSelections } = formProps.values;

    const selected = locPackSelections.filter((d) => d.packageId);

    const total =
      subscriptionHelper.calculateMediaAndSubscriptionTotal(selected);
    const brandMatchAmount = subscriptionHelper.totalBrandMatchAmount(selected);

    return { selected, total, brandMatchAmount };
  }, [formProps]);

  const disableContinue = useMemo(() => {
    const { locPackSelections } = formProps.values;

    const atLeastOneLocHasPackage = locPackSelections.some(
      (l) => l.packageId && l.packageInfo
    );

    return (
      !formProps.isValid ||
      locPackSelections.length === 0 ||
      !atLeastOneLocHasPackage
    );
  }, [formProps]);

  return (
    <div className="card package-selection-summary">
      <div className="card-body">
        <h4 className="heading">Summary</h4>
        {selections?.selected?.length ? (
          <>
            <div className="selections">
              <SelectedPackagesPerLocation selections={selections?.selected} />
            </div>
            <hr />
            <div className="total">
              <span>Total:</span>
              <CurrencyFormateText
                value={selections?.total}
                suffix=" USD per month"
              />
            </div>
            {selections?.brandMatchAmount ? (
              <div className="total">
                <span>Brand Match:</span>
                <CurrencyFormateText value={selections.brandMatchAmount} />
              </div>
            ) : null}
          </>
        ) : (
          <p className="note">You have not selected a media plan yet!</p>
        )}

        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={onContinue}
            fullWidth
            type="submit"
            disabled={disableContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
