import queryString from "query-string";
import AxiosInstance from "../config/AxiosInstance";
import { API_BASE_URL } from "../config/appConfig";
import { BRAND_REQUEST_TYPE } from "../config/constants";

export const requestService = {
  getChannelAndRequests,
  getBrandLocations,
  getCampaignListing,
  getBrandRequestListing,
  getBrandRequestGetById,
  brandRequestCreate,
  checkCampaignNameExists,
  downloadBudgetTemplateService,
};

function getChannelAndRequests() {
  return AxiosInstance.get(`${API_BASE_URL}/channel-and-requests`);
}

function getBrandLocations() {
  return AxiosInstance.get(`${API_BASE_URL}/getBrandLocations`);
}

function getCampaignListing(data) {
  const { id, userId } = data;
  return AxiosInstance.get(
    `${API_BASE_URL}/campaignListing?id=${id}&userId=${userId}`
  );
}

function getBrandRequestListing(data, isSWR) {
  const q = queryString.stringify(data);
  if (isSWR) {
    return AxiosInstance.get(
      `${API_BASE_URL}/brandRequestListing${q ? "?" + q : ""}`
    ).then((res) => res.data);
  }
  return AxiosInstance.get(
    `${API_BASE_URL}/brandRequestListing${q ? "?" + q : ""}`
  );
}

function getBrandRequestGetById(id, isSWR) {
  if (isSWR) {
    return AxiosInstance.get(`${API_BASE_URL}/brandRequestGetById/${id}`).then(
      (res) => res.data
    );
  }
  return AxiosInstance.get(`${API_BASE_URL}/brandRequestGetById/${id}`);
}

function brandRequestCreate(data) {
  return AxiosInstance.post(`${API_BASE_URL}/brandRequestCreate`, data);
}

function checkCampaignNameExists(data) {
  const query = queryString.stringify(data);
  return AxiosInstance.get(`${API_BASE_URL}/getBrandCampaigns?${query}`);
}

function downloadBudgetTemplateService(type, params) {
  const endpoints = {
    [BRAND_REQUEST_TYPE.BUDGET_APPROVAL_CONFIRMATION_REQUEST]:
      "/download-template-budget-location",
  };

  const endpoint = endpoints[type];
  if (!endpoint) throw new Error("Invalid template type");

  return AxiosInstance.get(
    `${API_BASE_URL}${endpoint}${params ? `?${queryString.stringify(params)}` : ""}`
  );
}
