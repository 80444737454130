import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@material-ui/core";

function BoostCampaignButton({ to = {}, ...rest }) {
  return (
    <Link to={to}>
      <Icon
        component="img"
        src="/img/icons/rocket.svg"
        title="Boost Campaign"
        {...rest}
      ></Icon>
    </Link>
  );
}

export default BoostCampaignButton;
