import React, { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";

import { logger } from "../../../helpers/loggerHelper";
import MuiDarkButton from "./MuiDarkButton";
import { CircularProgress } from "@material-ui/core";

const DownloadLinkButton = ({
  buttonText = "Download Template",
  startIcon = <GetAppIcon />,
  className = "",
  size = "small",
  customStyle,
  downloadLink = "",
  color = "dark",
  variant = "contained",
  getDownloadUrl = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDownload = async () => {
    try {
      setIsLoading(true);

      await new Promise((resolve) => setTimeout(resolve, 3000)); // Delay for 3 seconds

      if (downloadLink) {
        window.open(downloadLink, "_blank");
      }

      const dynamicDownloadLink = await getDownloadUrl();
      logger.info("Download URL:", dynamicDownloadLink);

      if (dynamicDownloadLink) {
        window.open(dynamicDownloadLink, "_blank");
      }
    } catch (error) {
      logger.error("Error fetching the download URL:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}
      <MuiDarkButton
        variant={variant}
        onClick={handleDownload}
        startIcon={startIcon}
        size={size}
        disableElevation
        className={`${className} cursor-p`}
        color={color}
        style={customStyle}
      >
        {buttonText}
      </MuiDarkButton>
    </>
  );
};

export default DownloadLinkButton;
