export const isEmailSendSuccessfullySelector = (state) =>
  state.resetPassword.isEmailSend;
export const isValidRequestSelector = (state) =>
  state.resetPassword.isValidRequest;
export const validatedEmailSelector = (state) => state.resetPassword.email;
export const isPasswordUpdatedSelector = (state) =>
  state.resetPassword.isPasswordUpdated;

export const isLoadingSelector = (state) => state.resetPassword.loading;
export const isErrorSelector = (state) => state.resetPassword.error;
