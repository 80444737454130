import React from "react";
import { Link } from "react-router-dom";

export default function AlreadyHaveAccountMessage({ email = "", subscribe }) {
  return (
    <div className="already-have-account-message">
      <h2 className="heading">Looks like you already have an account</h2>

      <h6 className="subheading">
        We couldn’t create a new Hyperlocology account because there’s already
        an account with your email address <strong>{email}</strong>.
      </h6>
      <h6 className="subheading">
        You can log in to this account – and reset the password if you need to –
        here:
      </h6>
      <div className="text-center mt-5">
        <Link
          to={`/login?email=${email}${subscribe ? "&subscribe=true" : ""}`}
          className="button primary mx-auto"
        >
          Login
        </Link>
      </div>
    </div>
  );
}
