import TagManager from "react-gtm-module";

const isProduction =
  process.env.REACT_APP_ENV === "production" &&
  process.env.NODE_ENV === "production";

export const rpGTM = {
  login: ({ userId, userEmail, userRole }) => {
    if (isProduction) {
      TagManager.dataLayer({
        dataLayer: {
          event: "login",
          userId: userId,
          userEmail: userEmail,
          userRole: userRole,
          time: new Date().toISOString(),
        },
      });
    }
  },
  logout: () => {
    if (isProduction) {
      TagManager.dataLayer({
        dataLayer: {
          event: "logout",
          time: new Date().toISOString(),
        },
      });
    }
  },
  setUserDetails: ({ userId, userEmail, userRole }) => {
    if (isProduction) {
      TagManager.dataLayer({
        dataLayer: {
          event: "user_details",
          userId: userId,
          userEmail: userEmail,
          userRole: userRole,
          time: new Date().toISOString(),
        },
      });
    }
  },
};
