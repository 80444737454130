import { campaignConstants, authConstants } from "../config/constants";

const initialState = {
  error: null,
  loading: false,
  isSaved: false,
  campaignReport: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case campaignConstants.SAVE_DRAFT_REQUEST:
    case campaignConstants.SAVE_DRAFT_POSTCARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        isSaved: false,
      };
    case campaignConstants.SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isSaved: true,
      };
    case campaignConstants.SAVE_DRAFT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isSaved: false,
      };
    case campaignConstants.RESET_IS_DRAFT_SUCCESS:
      return {
        ...state,
        isSaved: false,
      };
    case campaignConstants.GET_CAMPAIGN_REPORT_REQUEST:
      return { ...state, loading: true, error: false, campaignReport: null };
    case campaignConstants.GET_CAMPAIGN_REPORT_SUCCESS:
      return {
        ...state,
        campaignReport: action.payload,
        loading: false,
        error: false,
      };
    case campaignConstants.GET_CAMPAIGN_REPORT_FAIL:
      return { ...state, error: action.payload, loading: false };
    case campaignConstants.RESET_ERROR:
      return { ...state, error: null };
    case authConstants.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
