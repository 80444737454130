import React, { memo, useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";

const RpSnackbar = ({ message, severity = "error", show }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (show) {
      setOpen(show);
    }
  }, [show]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={severity}
        elevation={6}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default memo(RpSnackbar);
