import { authConstants, resetPasswordConstants } from "../config/constants";
import { clearLocal } from "../helpers/localStore";

export const authActions = {
  login,
  logout,
  forgotPassword,
  validatePwdResetRequest,
  resetPassword,
  resetResetPasswordStates,
  updateBrandData,
  resetHasLoggedOut,
};

function login(user) {
  return {
    type: authConstants.LOGIN_REQUEST,
    payload: user,
  };
}

function logout() {
  // clear local storage
  clearLocal();
  // redirect to login page
  // history.replace("/");
  return {
    type: authConstants.LOGOUT,
  };
}

function forgotPassword(data) {
  return {
    type: resetPasswordConstants.FORGOT_PASSWORD_REQUEST,
    payload: data,
  };
}

function validatePwdResetRequest(token) {
  return {
    type: resetPasswordConstants.VALIDATE_RESET_PASS_TOKEN_REQUEST,
    payload: token,
  };
}

function resetPassword(data) {
  return {
    type: resetPasswordConstants.RESET_PASSWORD_REQUEST,
    payload: data,
  };
}

function resetResetPasswordStates() {
  return {
    type: resetPasswordConstants.RESET_STATE,
  };
}

function updateBrandData(data) {
  return {
    type: authConstants.UPDATE_BRAND_DATA,
    payload: data,
  };
}

function resetHasLoggedOut() {
  return {
    type: authConstants.RESET_HAS_LOGGED_OUT,
  };
}
