import moment from "moment";
import businessMoment from "moment-business-days";
import { logger } from "./loggerHelper";
import { CAMPAIGN_CONST } from "../config/constants";
// import { getCreditCardProcessingFee } from "../config/appConfig";

export const joinCampaignHelper = {
  getValidLocations,
  getTotal,
  isAllLocationsAreNotOrPartiallyFilled,
  isNormalCampaignConstraintViolated,
  isEvergreenCampaignConstraintViolated,
  getFormattedLocationData,
  getInitialValues,
  getBrandContributionAmount,
  getTotalCalculations,
  getRequiredMinMaxFactor,
  getRequiredMinMax,
  // canChangeBudget,
  canChangeDate,
  validateMinCreativeSelection,
  getPostCardValidLocations,
  getPostCardLocTotal,
  getPostCardCampFormattedLocationData,
  getLocAddress,
  getPostCardCampInitialValues,
  isPostCardCostFactorExist,
  isCampaignAdvancedSettingsViolated,
  isFullyFilledLocation,
  isFullyFilledPostCardLocation,
};

/**
 * @method isFullyFilledLocation
 * @description check is the location is filled with required details so, consider it as filled
 * @param {*} locData
 * @param {*} budget_type
 * @returns
 */
function isFullyFilledLocation(locData, budget_type) {
  const filledBudget =
    budget_type === "Pre-set" && parseFloat(locData?.budget) === 0
      ? true
      : Number(locData?.budget);

  return (
    filledBudget &&
    locData?.creatives &&
    locData?.creatives?.length &&
    locData?.start_date &&
    locData?.end_date
  );
}

/**
 * @method isFullyFilledPostCardLocation
 * @description check is a post card location is fully filled with the required details
 * @param {*} locData
 * @returns
 */
function isFullyFilledPostCardLocation(locData) {
  return (
    locData?.creatives &&
    locData?.creatives?.length &&
    ((locData?.isMailing && locData?.mailingCost) ||
      (locData?.isBulkDrop && locData?.bulkDropCost))
  );
}

/**
 * @method getValidLocations
 * @description return locations & locations ids which has all data filled. i.e. Has Budget, Start Date, End Date & at least one selected creative
 * @param {*} values
 * @param {*} returnLocationsAsArray
 * @returns
 */
function getValidLocations(
  values = {},
  campaign,
  returnLocationsAsArray = true
) {
  const { budget_type } = campaign;

  const locs = Object.keys(values);
  if (locs.length === 0)
    return {
      locIds: [],
      locs: returnLocationsAsArray ? [] : {},
    };

  const fullyFilledLocationsIds = locs.length
    ? locs.filter((key) => {
        if (isFullyFilledLocation(values[key], budget_type)) {
          return true;
        }
        return false;
      })
    : [];

  let fullyFilledLocations = {
    ...fullyFilledLocationsIds.reduce(
      (acc, key) => ({ ...acc, [key]: values[key] }),
      {}
    ),
  };

  if (returnLocationsAsArray) {
    fullyFilledLocations = Object.values(fullyFilledLocations);
  }

  return {
    locIds: fullyFilledLocationsIds,
    locs: fullyFilledLocations,
  };
}

/**
 * @method getTotal
 * @description return sum of the locations budget
 * @param {*} values
 * @returns
 */
function getTotal(values = {}) {
  const locs = Array.isArray(values) ? values : Object.values(values);

  let budgetTotal = 0.0;
  let brandContributionTotal = 0.0;
  if (locs.length) {
    budgetTotal = locs.reduce(
      (t, v) => (Number(v.budget) ? parseFloat(v.budget) + t : t),
      0.0
    );
    brandContributionTotal = locs.reduce(
      (t, v) =>
        Number(v.brand_contribution_amount)
          ? parseFloat(v.brand_contribution_amount) + t
          : t,
      0.0
    );
  }
  return budgetTotal + brandContributionTotal;
}

/**
 * @method isAllLocationsAreNotOrPartiallyFilled
 * @description check all locations whether they are partially or not filled at all and return true or false
 * @param {*} values
 * @returns
 */
function isAllLocationsAreNotOrPartiallyFilled(values = {}) {
  const locs = Array.isArray(values) ? values : Object.values(values);
  return locs.every((l) => {
    if (
      !Number(l.budget) ||
      !l.creatives ||
      (l.creatives && l.creatives.length === 0)
    ) {
      return true;
    }
    return false;
  });
}

/**
 * @method getFormattedLocationData
 * @description return formatted location data to save in DB
 * @param {*} values
 * @param {*} campaign
 * @returns
 */
function getFormattedLocationData(values = {}, campaign) {
  const {
    locations: { subscribed },
    budget_type,
  } = campaign;

  const locs = Object.keys(values);

  if (locs.length === 0) return {};

  const fullyFilledLocationsIds = locs.length
    ? locs.filter((key) => {
        if (isFullyFilledLocation(values[key], budget_type)) {
          return true;
        }
        return false;
      })
    : [];

  // As array
  return [
    ...fullyFilledLocationsIds.reduce((acc, key) => {
      const locData = subscribed.find((l) => String(l.id) === String(key));

      if (locData) {
        const creativeData = values[key].creatives
          .map((cid) => {
            const creative = locData.creatives.find(
              (cr) => cr.creative_id === cid
            );

            if (!creative) return undefined;
            return {
              creative_id: cid,
              creative_name: creative.creative_name,
            };
          })
          .filter((d) => d);

        return [
          ...acc,
          {
            ...values[key],
            locationId: key,
            locationName: locData.location_name,
            system_generated_location_id: locData.system_generated_location_id,
            creativeData,
            amount: values[key].budget,
          },
        ];
      }
      return acc;
    }, []),
  ];

  // As object
  // return {
  //   ...fullyFilledLocationsIds.reduce((acc, key) => {
  //     const locData = subscribed.find((l) => String(l.id) === String(key));
  //

  //     if (locData) {
  //       return {
  //         ...acc,
  //         [key]: {
  //           ...values[key],
  //           location_id: key,
  //           location_name: locData.location_name,
  //           system_generated_location_id: locData.system_generated_location_id,
  //           creativeData: values[key].creatives.map((cid) => {
  //             const creative = locData.creatives.find(
  //               (cr) => cr.creative_id === cid
  //             );
  //             return {
  //               creative_id: cid,
  //               creative_name: creative.creative_name,
  //             };
  //           }),
  //         },
  //       };
  //     }
  //     return acc;
  //   }, {}),
  // };
}

/**
 * @method isAnyLocationHasZeroBudget
 * @description check is there any given location has budget set to $0
 * @param {*} values
 * @returns
 */
// function isAnyLocationHasZeroBudget(values = {}) {
//   const locs = Array.isArray(values) ? values : Object.values(values);

//   return locs.some((l) => parseFloat(l.budget) === 0);
// }

/**
 * @method isNormalCampaignConstraintViolated
 * @description check for Normal campaign constraints and set error if any and stop submit
 * @param {*} campaign
 * @param {*} values
 * @param {*} setFormError
 * @returns
 */
function isNormalCampaignConstraintViolated(
  campaign,
  values = {},
  setFormError
) {
  const filledLocations = Array.isArray(values)
    ? values
    : Object.values(values);

  const { budget_type, min_budget, max_budget, locations } = campaign;

  // if (campaign_type !== "Normal") {
  // }

  const subscribedLocations = locations?.subscribed;

  if (budget_type === "Location Specific") {
    const isOutOfRange = filledLocations.some((l) => {
      if (
        Number(l.budget) &&
        (Number(l.budget) > max_budget || Number(l.budget) < min_budget)
      ) {
        return true;
      }
      return false;
    });
    if (isOutOfRange) {
      // The amount is out of range somewhere
      setFormError({
        message: `Please enter location budget between $${min_budget} & $${max_budget} only`,
      });
      return true;
    }
  } else if (budget_type === "Pre-set") {
    const totalPresetBudget = locations.subscribed.reduce(
      (t, l) => (l.budget ? parseFloat(l.budget?.budget) + t : t),
      0
    );

    const totalFilledBudget = filledLocations.reduce(
      (t, l) => (Number(l.budget) ? parseFloat(l.budget) + t : t),
      0
    );
    if (subscribedLocations.length !== filledLocations.length) {
      setFormError({
        message: `Some location(s) are filled partially. Please fill for all the locations`,
      });
      return true;
    } else if (totalFilledBudget !== totalPresetBudget) {
      setFormError({
        message: `The sum of location's budget must be equal to the sum of pre-set budget $${totalPresetBudget}`,
      });
      return true;
    }
  }
  return false;
}

/**
 * @method isEvergreenCampaignConstraintViolated
 * @description check for Evergreen campaign constraints and set error if any and stop submit
 * @param {*} campaign
 * @param {*} values
 * @param {*} setFormError
 * @returns
 */
function isEvergreenCampaignConstraintViolated(
  campaign,
  values = {},
  setFormError
) {
  const filledLocations = Array.isArray(values)
    ? values
    : Object.values(values);

  const {
    budget_type,
    min_budget,
    max_budget,
    locations,
    evergreen_participant_date_flag,
  } = campaign;

  // if (campaign_type !== "Evergreen") {
  // }

  const subscribedLocations = locations?.subscribed;

  if (budget_type === "Location Specific") {
    const isOutOfRange = filledLocations.some((l) => {
      let budgetMultiplyFactor = 1;
      if (evergreen_participant_date_flag) {
        // If Participant date select is true. We required to calculate new factor for min max budget
        budgetMultiplyFactor = getRequiredMinMaxFactor(
          l.start_date,
          l.end_date
        );
      }

      if (
        Number(l.budget) &&
        (Number(l.budget) > max_budget * budgetMultiplyFactor ||
          Number(l.budget) < min_budget * budgetMultiplyFactor)
      ) {
        return true;
      }
      return false;
    });

    if (isOutOfRange) {
      // The amount is out of range somewhere
      setFormError({
        message: evergreen_participant_date_flag
          ? "There are some location(s) with out of range budget."
          : `Please enter location budget between $${min_budget} & $${max_budget} only`,
      });
      return true;
    }
  } else if (budget_type === "Pre-set") {
    const totalPresetBudget = locations.subscribed.reduce(
      (t, l) => (l.budget ? parseFloat(l.budget?.budget) + t : t),
      0
    );

    const totalFilledBudget = filledLocations.reduce(
      (t, l) => (Number(l.budget) ? parseFloat(l.budget) + t : t),
      0
    );

    if (subscribedLocations.length !== filledLocations.length) {
      setFormError({
        message: `Some location(s) are filled partially. Please fill for all the locations`,
      });
      return true;
    } else if (totalFilledBudget !== totalPresetBudget) {
      setFormError({
        message: `The sum of location's budget must be equal to the sum of pre-set budget $${totalPresetBudget}`,
      });
      return true;
    }
  }
  return false;
}

/**
 * @method validateMinCreativeSelection
 * @param {*} campaign
 * @param {*} values
 * @param {*} setFormError
 */
function validateMinCreativeSelection(campaign, values, setFormError) {
  const filledLocations = Array.isArray(values)
    ? values
    : Object.values(values);

  const { creative_select_flag, creative_select_min_value } = campaign;

  const isInvalid = filledLocations.some((l) => {
    if (
      creative_select_flag &&
      l?.creatives?.length < creative_select_min_value
    ) {
      return true;
    }
    return false;
  });

  if (isInvalid) {
    setFormError({
      message: `There some location(s) having less than min ${creative_select_min_value} selected creatives. Please select min ${creative_select_min_value} creatives for a location.`,
    });
    return true;
  }
  return false;
}

/**
 * @method getInitialValues
 * @description make initial values for the join campaign form
 * @param {*} campaign
 * @returns
 */
function getInitialValues(campaign) {
  if (!campaign) return {};

  const {
    start_date,
    end_date,
    participant_campaign,
    locations: { subscribed },
    campaign_type,
    budget_type,
    evergreen_participant_date_flag,
    ability_to_set_budget,
    brand_contribution,
    brand_contribution_type,
    contribution_max_limit,
    contribution_percentage,
    creative_select_flag,
    creative_select_max_value,
  } = campaign;

  let startDate = start_date;
  let endDate = end_date;

  let locationsData = {};

  locationsData = subscribed.reduce((acc, l) => {
    let budget = 0;
    let creatives = [];

    const locationDraftData = participant_campaign
      ? participant_campaign.locationData.find(
          (dl) => String(dl.locationId) === String(l.id)
        )
      : null;

    if (budget_type === "Pre-set") {
      /* For preset we always take budget from the budget set by admin, even if there is draft data */
      budget = l.budget?.budget || 0;

      /* if there is budget in draft the consider that (Check for total sum at the time of submit, that should be valid even the location budget is altered by the admin i.e. When altered new sum will be according to the new location's budget)
       */
      if (locationDraftData && ability_to_set_budget) {
        budget = locationDraftData.budget;
      }
    } else if (locationDraftData) {
      // if budget type is Location Specific and there is draft data
      budget = locationDraftData.budget;
    }

    // to set initially selected creative
    if (creative_select_flag && locationDraftData) {
      if (locationDraftData?.creatives.length > creative_select_max_value) {
        creatives = [];
      } else {
        const onlyAvailableInCreativeList =
          locationDraftData?.creatives?.filter((c) =>
            l?.creatives?.find((cr) => String(cr.creative_id) === String(c))
          ) || [];
        creatives = onlyAvailableInCreativeList;
      }
    } else {
      const firstCreative = l.creatives?.[0]?.creative_id;
      if (firstCreative) {
        creatives.push(firstCreative);
      }
    }

    if (campaign_type === "Evergreen") {
      // for Evergreen take start and end date from location
      startDate = l.campaignStartDate;
      endDate = l.campaignEndDate;

      if (evergreen_participant_date_flag) {
        /*
        startDate = moment().add(evergreen_participant_min_value, "month");
        endDate = moment().add(evergreen_participant_min_value, "month");
        */
        startDate = null;
        endDate = null;
        if (locationDraftData) {
          startDate = locationDraftData.start_date;
          endDate = locationDraftData.end_date;

          const minStartDate =
            CAMPAIGN_CONST.BUFFER.JOIN_CAL_DAYS_BRAND.includes(
              campaign?.fk_brand_id
            )
              ? moment().add(CAMPAIGN_CONST.BUFFER.JOIN_CAL_DAYS, "day")
              : businessMoment().businessAdd(
                  CAMPAIGN_CONST.BUFFER.JOIN_BUSINESS_DAYS
                );
          if (
            moment(locationDraftData.start_date).isBefore(
              minStartDate,
              "day"
            ) ||
            !moment(locationDraftData.start_date).isValid()
          ) {
            // flushed saved dates if it is before 5 business days
            startDate = null;
            endDate = null;
          }
        }
      }
    }

    let brand_contribution_amount = 0;
    if (brand_contribution) {
      if (brand_contribution_type && brand_contribution_type === "preset") {
        brand_contribution_amount =
          l?.budget?.brand_match_preset_amount * 1 || 0;
      } else {
        brand_contribution_amount = getBrandContributionAmount({
          amount: budget,
          brand_contribution,
          brand_contribution_type,
          contribution_max_limit,
          contribution_percentage,
          brand_match_preset_amount:
            l?.budget?.brand_match_preset_amount * 1 || 0,
        });
      }
    }

    return {
      ...acc,
      [l.id]: {
        start_date: startDate,
        end_date: endDate,
        budget: budget || 0,
        creatives: creatives || [],
        brand_contribution_amount: brand_contribution_amount,
        advance_setting: locationDraftData?.advance_setting,
      },
    };
  }, {});

  return locationsData;
}

/**
 * @method canChangeDate
 * @description check whether user can enter/change dates
 * @param {*} campaign
 * @returns
 */
function canChangeDate(campaign) {
  if (!campaign) return false;
  const { campaign_type, evergreen_participant_date_flag } = campaign;
  if (campaign_type === "Normal") {
    return false;
  } else if (!evergreen_participant_date_flag) {
    return false;
  }

  return true;
}

/**
 * @method canChangeBudget
 * @description check whether user can enter/change budget
 * @param {*} campaign
 * @returns
 */
// function canChangeBudget(campaign) {
//   if (!campaign) return false;
//   const { ability_to_set_budget } = campaign;
//   if (!ability_to_set_budget) {
//     return false;
//   }
//   return true;
// }

/**
 * @method getBrandContributionAmount
 * @description return brand brand contribution amount for a location
 * @param {*} amount
 * @param {*} brand_contribution
 * @param {*} contribution_percentage
 * @param {*} contribution_max_limit
 * @param {*} brand_contribution_type
 * @param {*} brand_match_preset_amount
 * @returns
 */
function getBrandContributionAmount({
  amount,
  brand_contribution,
  brand_contribution_type,
  contribution_percentage,
  contribution_max_limit,
  brand_match_preset_amount,
}) {
  if (!brand_contribution || brand_contribution === 0) return 0;

  if (brand_contribution_type && brand_contribution_type === "preset") {
    return parseFloat(brand_match_preset_amount);
  }

  let contributionAmount = 0;

  if (contribution_max_limit && contribution_percentage) {
    const brandPercent = (amount * 1 * contribution_percentage) / 100;
    contributionAmount =
      contribution_max_limit < brandPercent
        ? contribution_max_limit
        : brandPercent;
  } else if (contribution_percentage) {
    contributionAmount = (amount * 1 * contribution_percentage) / 100;
  } else if (contribution_max_limit) {
    contributionAmount = contribution_max_limit;
  }

  return parseFloat(contributionAmount);
}

/**
 * @method getTotalCalculations
 * @description return total calculations for confirm page
 * @param {*} campaign
 * @returns
 */
function getTotalCalculations(campaign) {
  const {
    brand_contribution,
    contribution_percentage,
    contribution_max_limit,
    brand_contribution_type,
    participant_campaign,
    // enable_payment_through_portal,
    // locations,
  } = campaign;
  let brandContribution = 0;

  // const subscribedLocations = locations?.subscribed;

  // const country = subscribedLocations?.some(
  //   (l) => l?.country?.toLocaleLowerCase() === "canada"
  // )
  //   ? "canada"
  //   : subscribedLocations?.[0]?.country;

  participant_campaign?.locationData?.forEach((l) => {
    brandContribution += getBrandContributionAmount({
      amount: l.budget * 1,
      brand_contribution,
      brand_contribution_type,
      contribution_percentage,
      // contribution_max_limit:
      //   contribution_max_limit *
      //   (participant_campaign?.locationData?.length || 1),
      contribution_max_limit: contribution_max_limit,
      brand_match_preset_amount: l.brand_contribution_amount * 1,
    });
  });

  // campaign budget is sum of locations budget + brand contribution
  const totalCampaignBudget =
    participant_campaign?.total_budget + brandContribution;

  // just to show
  const budgetYouPay = participant_campaign?.total_budget;

  // calculate credit card processing fee on sum of locations budget only
  // const creditCardProcessingFee = enable_payment_through_portal
  //   ? getCreditCardProcessingFee(budgetYouPay, country)
  //   : 0;

  // final amount
  // const amountYouPay = budgetYouPay + creditCardProcessingFee;
  const amountYouPay = budgetYouPay;

  return {
    totalCampaignBudget,
    brandContribution,
    budgetYouPay,
    // creditCardProcessingFee,
    amountYouPay: amountYouPay ? parseFloat(amountYouPay.toFixed(2)) : 0,
  };
}

/**
 * @method getRequiredMinMaxFactor
 * @description return min max budget requirement factor according to selected dates
 * @param {*} start_date
 * @param {*} end_date
 * @returns
 */
function getRequiredMinMaxFactor(start_date, end_date) {
  if (!start_date && !end_date) return 1;
  const s = moment(start_date);
  const e = moment(end_date);
  if (s.isValid() && e.isValid()) {
    const days_diff = e.diff(s, "days") + 1; // added 1 to include the start and end date
    const diff = Math.ceil(days_diff / 30);
    // const diff = Math.ceil(e.diff(s, "months", true));

    return diff <= 0 ? 1 : diff;
  }
  return 1;
}

/**
 * @method getRequiredMinMax
 * @description return required min & max amount when budget type is location specific
 * @param {*} locFormData
 * @param {*} campaign
 * @returns
 */
function getRequiredMinMax(locFormData, campaign) {
  const {
    campaign_type,
    budget_type,
    evergreen_participant_date_flag,
    min_budget,
    max_budget,
  } = campaign;

  if (budget_type === "Location Specific") {
    let minRequiredBudget = min_budget;
    let maxRequiredBudget = max_budget;
    const enteredBudget = Number(locFormData.budget) || 0;
    if (enteredBudget === 0 || enteredBudget) {
      if (campaign_type === "Evergreen" && evergreen_participant_date_flag) {
        const factor = getRequiredMinMaxFactor(
          locFormData.start_date,
          locFormData.end_date
        );

        minRequiredBudget = min_budget * factor;
        maxRequiredBudget = max_budget * factor;
        if (
          locFormData.start_date &&
          locFormData.end_date &&
          (enteredBudget > maxRequiredBudget ||
            enteredBudget < minRequiredBudget)
        ) {
          return {
            minRequiredBudget,
            maxRequiredBudget,
          };
        }
      } else if (
        enteredBudget > maxRequiredBudget ||
        enteredBudget < minRequiredBudget
      ) {
        return {
          minRequiredBudget,
          maxRequiredBudget,
        };
      }
    }
  }
  return null;
}

/** Post Card Campaign */

function getPostCardValidLocations(
  values = {},
  campaign,
  returnLocationsAsArray = true
) {
  const locs = Object.keys(values);
  if (locs.length === 0)
    return {
      locIds: [],
      locs: returnLocationsAsArray ? [] : {},
    };

  const fullyFilledLocationsIds = locs.length
    ? locs.filter((key) => {
        if (isFullyFilledPostCardLocation(values[key])) {
          return true;
        }
        return false;
      })
    : [];

  let fullyFilledLocations = {
    ...fullyFilledLocationsIds.reduce(
      (acc, key) => ({ ...acc, [key]: values[key] }),
      {}
    ),
  };

  if (returnLocationsAsArray) {
    fullyFilledLocations = Object.values(fullyFilledLocations);
  }

  return {
    locIds: fullyFilledLocationsIds,
    locs: fullyFilledLocations,
  };
}

function getPostCardLocTotal(values = {}) {
  const locs = Array.isArray(values) ? values : Object.values(values);
  let budgetTotal = 0.0;
  if (locs.length) {
    budgetTotal = locs.reduce(
      (t, v) =>
        (Number(v?.bulkDropCost) || 0) + (parseFloat(v?.mailingCost) || 0) + t,
      0.0
    );
    // brandContributionTotal = locs.reduce(
    //   (t, v) =>
    //     Number(v.brand_contribution_amount)
    //       ? parseFloat(v.brand_contribution_amount) + t
    //       : t,
    //   0.0
    // );
  }
  return budgetTotal;
}

function getPostCardCampFormattedLocationData(values = {}, campaign) {
  try {
    const {
      locations: { subscribed },
    } = campaign;

    const locations = Object.keys(values);

    if (locations.length === 0) return {};

    const { locIds: fullyFilledLocationsIds } = locations.length
      ? getPostCardValidLocations(values, campaign, true)
      : [];

    // As array
    return [
      ...fullyFilledLocationsIds.reduce((acc, key) => {
        const locData = subscribed.find((l) => String(l.id) === String(key));

        if (locData) {
          const creativeData = values[key].creatives.map((cid) => {
            const creative = locData.creatives.find(
              (cr) => cr.creative_id === cid
            );
            return {
              creative_id: cid,
              creative_name: creative.creative_name,
              attachment: creative.package_channels?.map(
                (channel) => channel.attachment
              ),
            };
          });
          const isMailing =
            values[key]?.isMailing && values[key]?.mailingQnt ? true : false;

          const isBulkDrop =
            values[key]?.isBulkDrop && values[key]?.bulkDropQnt ? true : false;

          return [
            ...acc,
            {
              ...values[key],
              locationId: key,
              locationName: locData.location_name,
              system_generated_location_id:
                locData.system_generated_location_id,
              creativeData,

              postcard_address: isMailing
                ? values[key]?.postcard_address || ""
                : "",
              isMailing: isMailing,
              mailingType: isMailing ? values[key]?.mailingType : "",
              mailingQnt: values[key]?.mailingQnt,
              mailingCost: values[key]?.mailingCost,
              isBulkDrop: isBulkDrop,
              bulkDropQnt: values[key]?.bulkDropQnt,
              bulkDropCost: values[key]?.bulkDropCost,
              // schedule_date: moment(values[key]?.week).isValid()
              //   ? moment(values[key]?.week)
              //       .startOf("week")
              //       .add(2, "day")
              //       .startOf("isoWeek")
              //       .format("YYYY-MM-DD")
              //   : "",
              schedule_date: values[key]?.week
                ? moment(values[key]?.week).isValid()
                  ? moment(values[key]?.week)
                      .startOf("isoWeek")
                      .format("YYYY-MM-DD")
                  : ""
                : values[key]?.schedule_date,
              amount:
                (values[key]?.mailingCost || 0) +
                (values[key]?.bulkDropCost || 0),
              budget:
                (values[key]?.mailingCost || 0) +
                (values[key]?.bulkDropCost || 0),
            },
          ];
        }
        return acc;
      }, []),
    ];
  } catch (err) {
    logger.error("getPostCardCampFormattedLocationData error", err);
  }
}

function getLocAddress(loc) {
  return `${loc?.street_address || ""}${loc?.city ? `, ${loc?.city}` : ""}${
    loc.state ? `, ${loc.state}` : ""
  }${loc.zip_code ? `, ${loc.zip_code}` : ""}`;
}

function getPostCardCampInitialValues(campaign) {
  if (!campaign) return {};

  const {
    participant_campaign,
    locations: { subscribed },
    campaign_type,
  } = campaign;

  let locationsData = {};

  locationsData = subscribed.reduce((acc, l) => {
    const locationDraftData = participant_campaign
      ? participant_campaign.locationData.find(
          (dl) => String(dl.locationId) === String(l.id)
        )
      : null;

    /** Schedule date */
    let schedule_date = campaign?.schedule_date || "";

    if (campaign_type === "EVERGREEN") {
      const sevenWeekFromNow = moment()
        .add(7, "week")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      schedule_date = sevenWeekFromNow;

      if (locationDraftData && locationDraftData?.schedule_date) {
        if (
          moment(locationDraftData?.schedule_date).isValid() &&
          moment(locationDraftData?.schedule_date).isAfter(
            moment(sevenWeekFromNow)
          )
        ) {
          schedule_date = locationDraftData?.schedule_date;
        }
      }
    }

    return {
      ...acc,
      [l.id]: {
        ...locationDraftData,
        schedule_date,
      },
    };
  }, {});

  return locationsData;
}

function isPostCardCostFactorExist(campaign) {
  if (!campaign?.locations?.subscribed?.length) {
    return true;
  }
  if (
    !campaign?.postcard_cost_mailings?.length &&
    !campaign?.postcard_cost_shippings?.length
  ) {
    return false;
  }
  return true;
}

function isCampaignAdvancedSettingsViolated(
  campaign,
  locationData,
  setFormError
) {
  const { campaign_advanced_setting } = campaign;

  const { is_required, settings } = campaign_advanced_setting || {};

  if (!settings?.length || !is_required) {
    return false;
  }

  if (!locationData.length) {
    setFormError({
      message: `At least one valid filled location is required`,
    });
    return true;
  }

  let isAdvancedSettingsNotFilled = locationData?.some((l) => {
    const { advance_setting } = l;
    if (!advance_setting?.length) {
      return true;
    }
    return false;
  });

  if (isAdvancedSettingsNotFilled) {
    // setFormError({
    //   message: `Please fill Advanced Settings for each valid locations you have filled in the form.`,
    // });
    return true;
  }

  return false;
}
