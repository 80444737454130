import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import SwitchTabs from "../../../components/tabs/SwitchTabs";
import RequestTypeSelector from "./RequestTypeSelector";
import RequestListing from "./RequestListing";
import { logger } from "../../../helpers/loggerHelper";
import DynamicForm from "./DynamicForm";
import { requestService } from "../../../services/requestService";
import { toastr } from "react-redux-toastr";
import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import { BRAND_REQUEST_TYPE } from "../../../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2), // Default padding
    margin: theme.spacing(1), // Default padding
    [theme.breakpoints.down("sm")]: {
      padding: 0, // No padding for small screens (mobile view)
    },
  },
}));

const RequestFromBrand = () => {
  const loader = useContext(LoaderContext);
  const [selectedRequestType, setSelectedRequestType] = useState("");
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  logger.info("selectedRequestType", selectedRequestType);
  const [activeTab, setActiveTab] = useState(0);

  const [settings, setSettings] = useState(null);
  logger.info("settings", settings);
  const classes = useStyles();

  useEffect(() => {
    setSelectedRequestType("");
  }, [activeTab]);

  const fetchSettings = useCallback(async () => {
    try {
      loader(true);
      const [channelRes, locRes, campaignRes] = await Promise.all([
        requestService.getChannelAndRequests(),
        requestService.getBrandLocations(),
        selectedRequestType === BRAND_REQUEST_TYPE.CREATIVE_REQUEST
          ? requestService.getCampaignListing({
              id: selectedBrand?.id,
              userId: loggedInUser?.id,
            })
          : Promise.resolve({ data: [] }),
      ]);

      const channelNRequestType = channelRes.data;
      const locations = locRes.data;
      const campaigns = campaignRes.data.result || [];
      if (channelRes.status === 200 && locRes.status === 200) {
        const data = Object.assign(
          {},
          channelNRequestType || {},
          locations ? { locations } : {},
          campaigns ? { campaigns } : {}
        );
        logger.info("Settings data:", {
          channelNRequestType,
          locations,
          campaigns,
        });
        setSettings(Object.keys(data) ? data : null);
      } else {
        toastr.error(
          "Error",
          "Something went wrong while fetching the settings!"
        );
      }
    } catch (err) {
      logger.trace(err);
    } finally {
      loader(false);
    }
  }, [selectedRequestType, selectedBrand?.id, loggedInUser?.id]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const handleRequestTypeChange = useCallback((type) => {
    setSelectedRequestType(type);
  }, []);

  const handleOnSuccess = useCallback(() => {
    // setSelectedRequestType("");
  }, []);

  const tabs = ["Submit Request", "Request History"];

  return (
    <Container className="px-0 px-lg-auto">
      <Grid alignItems="center">
        <Grid item>
          <div className="mb-4 d-flex justify-content-center">
            <SwitchTabs
              tabs={tabs}
              selectedTab={activeTab}
              onChange={setActiveTab}
              // centered
              tabProps={{
                disableRipple: true,
              }}
              styleType={1}
            />
          </div>
          <Card elevation={0} className="shadow-sm">
            <CardContent
              className={`${classes.root} ${activeTab === 1 ? "px-0 mx-0" : ""}`}
            >
              {activeTab === 0 && (
                <>
                  <Typography
                    variant="h4"
                    className="text-center mb-4"
                    gutterBottom
                  >
                    Submit Request
                  </Typography>
                  <div className="mb-3 p-0">
                    <RequestTypeSelector
                      onChange={handleRequestTypeChange}
                      selectedRequestType={selectedRequestType}
                      brandRequestList={settings?.brandRequests}
                    />
                  </div>
                  {selectedRequestType && (
                    <DynamicForm
                      requestType={selectedRequestType}
                      channels={settings?.channels}
                      brandRequestList={settings?.brandRequests}
                      locations={settings?.locations}
                      setActiveTab={setActiveTab}
                      onSuccess={handleOnSuccess}
                      campaigns={settings?.campaigns}
                    />
                  )}
                </>
              )}
              {activeTab === 1 && (
                <div>
                  <Typography
                    variant="h4"
                    className="text-center mb-4"
                    gutterBottom
                  >
                    Request History
                  </Typography>
                  <RequestListing />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RequestFromBrand;
