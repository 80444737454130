import React, { useState, useEffect, useContext, useMemo } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import { useHistory } from "react-router";

import { Typography } from "@material-ui/core";
import { LoaderContext, GlobalContext } from "../../helpers/contexts/contexts";
import { reportService } from "../../services/reportService";
import NoDataFound from "../../components/NoDataFound";
import { renderTemplate } from "../../helpers/reportHelper";
import LocationList from "./LocationList";
import CampaignsList from "./CampaignsList";
import DoughnutChart from "../../components/charts/DoughnutChart";
import RPCustomDateRagePicker from "../../components/RPCustomDateRagePicker";
import TotalSpendChart from "../../components/charts/TotalSpendChart";
import BarChart from "../../components/charts/BarChart";
import {
  getTotalSpendSum,
  removeCampaignByNameLike,
} from "../../helpers/utils";
import PageLayout from "../../components/layouts/page/PageLayout";
import PageHeader from "../../components/layouts/pageHeaders/PageHeader";
import UniversalWidgetTemplate from "../../components/templates/UniversalWidgetTemplate";
import { MUICustomAutocompleteSelect } from "../../components/muiCustom/select/MUICustomAutocompleteSelect";
import PostcardCampaignList from "./PostcardCampaignList";
import useFetcher from "../../helpers/hooks/useFetcher";
import { CUSTOM_WIDGET_PARAM } from "../../config/constants";
import StackedBarChart from "../../components/charts/StackedBarChart";
import { getRedirectPath } from "../../helpers/localStore";

const AdminBODashboard = () => {
  const loader = useContext(LoaderContext);
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  const history = useHistory();

  const [dataFilters, setDataFilters] = useState({});

  const reqData = useMemo(() => {
    const reqData = {
      id: selectedBrand?.id,
      userId: loggedInUser?.id,
      dataType: "brand",
      system_generated_brand_id: selectedBrand?.system_generated_brand_id,
    };

    if (dataFilters && !isEmpty(dataFilters)) {
      const filter_widget_id = [];
      Object.keys(dataFilters).map((key) => {
        if (dataFilters[key].from && dataFilters[key].to) {
          filter_widget_id.push(key);
          reqData[`fromdate_${key}`] = moment(dataFilters[key].from).format(
            "DD-MM-YYYY"
          );
          reqData[`todate_${key}`] = moment(dataFilters[key].to).format(
            "DD-MM-YYYY"
          );
        } else if (dataFilters[key].filter) {
          filter_widget_id.push(key);
          reqData[key] = dataFilters[key].filter
            .toLowerCase()
            .replace(/\s/g, "_");
        }
      });
      reqData["filter_widget_id"] = filter_widget_id;
    }

    return reqData;
  }, [
    selectedBrand?.id,
    selectedBrand?.system_generated_brand_id,
    loggedInUser?.id,
    dataFilters,
  ]);

  // const isCustomWithoutRange = Object.keys(dataFilters)?.some((key) => {
  //   if (dataFilters[key].filter === "Custom Date Range") {
  //     if (!dataFilters[key].from || !dataFilters[key].to) {
  //       return true;
  //     }
  //   }
  //   return false;
  // });

  // const shouldFetch =
  //   dataFilters && !isEmpty(dataFilters) && !isCustomWithoutRange;

  const { data: report, isLoading: loading } = useFetcher(
    selectedBrand?.id && reqData,
    reportService.getDashboardReport,
    { revalidateOnFocus: false }
  );

  const handleOnChangeDataFilter = (id, filter) => {
    setDataFilters({ ...dataFilters, [id]: { filter } });
  };

  const handleDateRange = (id, range) => {
    if (range && range[0] && range[1]) {
      setDataFilters({
        ...dataFilters,
        [id]: { ...dataFilters[id], from: range[0], to: range[1] },
      });
    }
  };

  useEffect(() => {
    if (selectedBrand && selectedBrand.id) {
      // Retrieve the stored redirect path from sessionStorage or default to "/dashboard"
      const redirectPath = getRedirectPath() || "/dashboard";

      if (history.location.pathname !== redirectPath) {
        history.replace(redirectPath);
      } else {
        history.replace(`${process.env.PUBLIC_URL}/dashboard`);
      }
    }
  }, [selectedBrand, history]);

  useEffect(() => {
    loader(loading);
  }, [loading, loader]);

  useEffect(() => {
    return () => loader(false);
  }, []);

  // if (!loading && !report) {
  //   return <NoDataFound />;
  // }

  if (loading) {
    return (
      <PageLayout>
        <NoDataFound message="Loading..." />
      </PageLayout>
    );
  }

  if (!report || !report.template.rows || report.template.rows.length === 0) {
    return (
      <PageLayout>
        <NoDataFound message="There is no template defined for this brand" />
      </PageLayout>
    );
  }

  const renderChartWidget = (widget, template) => {
    let filterToRender = null;
    let chartToRender = null;

    const filters = template?.filters ? template.filters?.split(",") : [];
    if (template?.apply_filter && filters && filters.length) {
      // formatting filter value
      const formattedFilters = filters.map((f) => {
        let value = f;
        return { label: f, value };
      });

      // for total spend of brand report added a new filter manually
      if (widget.widget_for === "brand" && widget.id === 7) {
        formattedFilters.unshift({
          label: "Last 3 Months",
          value: "Last 3 Months",
        });
      }

      filterToRender = (
        <div className="row text16P_Bold548E8D justify-content-end align-items-center">
          {widget.chart_type === "total_spend" && widget?.data?.length ? (
            <div className="col mb-1">
              <Typography color="primary" variant="body2" className="fw-b">
                Total: {getTotalSpendSum(widget)}
              </Typography>
            </div>
          ) : null}
          <div className="col mb-1">
            <div className="form-group d-flex align-items-center mb-0 justify-content-end">
              <MUICustomAutocompleteSelect
                options={formattedFilters.map((f) => ({
                  label: f.label,
                  value: f.value,
                }))}
                selectProps={{
                  onChange: (v) => {
                    handleOnChangeDataFilter(widget.id, v);
                  },
                  value: dataFilters[widget.id]
                    ? dataFilters[widget.id].filter
                    : "Last 3 Months",
                }}
                wrapperProps={{
                  style: { width: 150 },
                }}
                name="total_spend_filter"
                size="small"
                hideLabel
                className="mt-0"
              />
            </div>
          </div>

          {dataFilters[widget.id] &&
          dataFilters[widget.id].filter === "Custom Date Range" ? (
            <div className="col-auto mb-1 d-flex justify-content-end custom-date-range-picker">
              <RPCustomDateRagePicker
                onChangeDate={(d) => handleDateRange(widget.id, d)}
                range={
                  dataFilters[widget.id] &&
                  dataFilters[widget.id].from &&
                  dataFilters[widget.id].to
                    ? [dataFilters[widget.id].from, dataFilters[widget.id].to]
                    : [null, null]
                }
              />
            </div>
          ) : null}
        </div>
      );

      if (widget.id === 8) {
        // only for single widget
        filterToRender = null;
      }
    }

    if (!widget.data || widget.data.length === 0) {
      return (
        <>
          {filterToRender}
          <NoDataFound message="There is no record" height="100%" />
        </>
      );
    }

    if (!Array.isArray(widget.data)) {
      return (
        <>
          {filterToRender}
          <NoDataFound message="There is not in Array format" height="100%" />
        </>
      );
    }

    const labels = Object.keys(widget.data[0]);

    if (!labels || labels.length === 0) {
      return (
        <>
          {filterToRender}
          <NoDataFound message="There is no record" height="100%" />
        </>
      );
    }

    if (widget.chart_type === "pie" || widget.chart_type === "doughnut") {
      chartToRender = (
        <DoughnutChart
          data={widget.data}
          colorSet={1}
          labels={labels}
          labelHeading="Campaign Name"
          valueHeading="Participant Rate"
          formats={widget?.format}
        />
      );
    } else if (widget.chart_type === "bar") {
      chartToRender = (
        <BarChart
          data={widget.data}
          labels={labels}
          title={widget.alt_name || widget.name}
          formats={widget?.format}
        />
      );
    } else if (widget.chart_type === "stacked_bar") {
      chartToRender = (
        <StackedBarChart
          data={widget.data}
          title={widget.alt_name || widget.name}
          formats={widget?.format}
        />
      );
    } else if (widget.chart_type === "total_spend") {
      // Only total spend chart
      chartToRender = (
        <TotalSpendChart
          data={widget.data}
          labels={labels}
          title={widget.alt_name || widget.name}
          formats={widget?.format}
        />
      );
    }

    return (
      <>
        <div>{filterToRender}</div>
        {chartToRender}
      </>
    );
  };

  const renderTable = (widget) => {
    if (!widget.data || widget.data.length === 0) {
      return <NoDataFound message="There is no record" height="100%" />;
    }

    if (widget.id === 11) {
      // Location Listing widget
      return (
        <LocationList
          selectedBrand={selectedBrand}
          loggedInUser={loggedInUser}
        />
      );
    } else if (widget.id === 12) {
      // Campaign Listing widget
      const noBoostCamp = removeCampaignByNameLike(widget.data, [
        "boost",
        "boost:",
      ]);
      return <CampaignsList campaigns={noBoostCamp} fromPage="dashboard" />;
    } else if (widget.id === 195) {
      const noBoostCamp = removeCampaignByNameLike(widget.data, [
        "boost",
        "boost:",
      ]);
      return <PostcardCampaignList campaigns={noBoostCamp} />;
    }

    return null;
  };

  return (
    <PageLayout className="admin-dashboard-container mx-0 mx-sm-2">
      <PageHeader showName showLastUpdate showButton />
      <div className="pb-5 dashboard admin report">
        <UniversalWidgetTemplate
          rows={report?.template?.universal_widget_rows}
          report={report}
          renderChartWidget={renderChartWidget}
          type="brand"
          embedWidgetParams={{
            [CUSTOM_WIDGET_PARAM.BrandID]:
              selectedBrand?.system_generated_brand_id,
          }}
          hideDateSelector
        />
        {renderTemplate({
          report,
          renderTable,
          renderChartWidget,
          embedWidgetParams: {
            [CUSTOM_WIDGET_PARAM.BrandID]:
              selectedBrand?.system_generated_brand_id,
          },
        })}
      </div>
    </PageLayout>
  );
};

export default AdminBODashboard;
