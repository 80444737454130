import React from "react";
import { Icon, Typography } from "@material-ui/core";

function ConfirmLocationItem({
  imgSrc,
  label,
  value,
  valueClass = "",
  valueProps,
}) {
  return (
    <div className="confirm-location-item">
      <Typography variant="caption" className="label">
        <Icon
          component="img"
          src={imgSrc}
          fontSize="small"
          className="icon-img mr-1"
        />
        {label}
      </Typography>
      <Typography
        variant="body2"
        className={`value ${valueClass}`}
        {...valueProps}
      >
        {value}
      </Typography>
    </div>
  );
}

export default ConfirmLocationItem;
