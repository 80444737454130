import React, { useCallback, useMemo } from "react";
import { withStyles, Slider } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { QUESTION_TYPE_RADIUS } from "../../../config/constants";

const marks = [
  {
    value: QUESTION_TYPE_RADIUS.MIN,
    label: `${QUESTION_TYPE_RADIUS.MIN} mile${QUESTION_TYPE_RADIUS.MIN > 1 ? "s" : ""}`,
  },
  {
    value: QUESTION_TYPE_RADIUS.MAX,
    label: `${QUESTION_TYPE_RADIUS.MAX} miles`,
  },
];

const RpCustomSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.main,
    border: "2px solid #fff",
    marginTop: -6,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% - 6px)",
    top: 26,
    fontSize: 15,
    fontWeight: 700,

    color: "transparent",
    "& *": {
      background: "transparent",
      color: theme.palette.primary.main,
      width: 68,
    },
  },
  track: {
    height: 12,
    borderRadius: 4,
  },
  rail: {
    height: 12,
    borderRadius: 4,
  },
  mark: {
    background: "transparent",
  },
  markLabel: {
    color: "#C1C1C1",
    fontSize: 10,
    fontWeight: 700,
    top: -10,
  },
  // markLabelActive: {
  //   color: theme.palette.primary.main,
  // },
}))(Slider);

function RpMuiFromikSlider({ name, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const value = useMemo(() => {
    if (Number(field.value) > QUESTION_TYPE_RADIUS.MAX)
      return QUESTION_TYPE_RADIUS.MAX;
    if (Number(field.value) < QUESTION_TYPE_RADIUS.MIN)
      return QUESTION_TYPE_RADIUS.MIN;
    return Number(field.value);
  }, [field.value]);

  const handleChange = useCallback((_event, newValue) => {
    setFieldValue(name, newValue);
  });

  return (
    <div>
      <RpCustomSlider
        valueLabelDisplay="on"
        valueLabelFormat={(x) => `${x} MILE${Number(x) > 1 ? "S" : ""}`}
        aria-label="Rp slider"
        value={value}
        onChange={handleChange}
        name={name}
        min={QUESTION_TYPE_RADIUS.MIN}
        max={QUESTION_TYPE_RADIUS.MAX}
        marks={marks}
        {...props}
      />
    </div>
  );
}

export default RpMuiFromikSlider;
