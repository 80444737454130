import React, { useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import {
  getChannelIcons,
  getFormattedCampaignEndDate,
  getFormattedCampaignStartDate,
  getStatusCode,
} from "../../helpers/utils";
import { CurrencyFormateText } from "../../components/CurrencyFormat";
import { GlobalContext } from "../../helpers/contexts/contexts";
import LearnMoreButton from "../../components/muiCustom/buttons/LearnMoreButton";
import StatusChip from "../../components/muiCustom/chip/StatusChip";
import RpImageTextInline from "../../components/RpImageTextInline";
import TableServer from "../../components/tables/TableServer";
import TableClient from "../../components/tables/TableClient";
import PauseLocationAddIcon from "../campaign/campaignPause/PauseLocationAddIcon";

import { MUICustomAutocompleteSelect } from "../../components/muiCustom/select/MUICustomAutocompleteSelect";

const CampaignsList = ({
  // selectedBrand,
  // loggedInUser,
  campaignReportList,
  pageCount,
  fetchCampaignsReportData,
  isLoading,
  // filters,
  showFilter = false,
  fromPage = "",
  extraData = {},
  hideSearchLabel = true,
  totalRecords,
}) => {
  const { loggedInUserRole, loggedInUser, selectedBrand } =
    useContext(GlobalContext);

  const campaignsList = useMemo(() => {
    if (
      [
        "sarah.dion@thetenspot.com",
        "angela.casullo@thetenspot.com",
        "jessica.scott@thetenspot.com",
      ].includes(loggedInUser.email)
    ) {
      // hard coded to hide the campaign from these users
      return campaignReportList?.filter(
        (c) => c.system_generated_campaign_id !== "FTDX7289"
      );
    }
    return campaignReportList;
  }, [campaignReportList, loggedInUser]);

  const columns = useMemo(
    () => [
      {
        Header: "Campaign Name",
        accessor: "campaign_name",
        id: "campaign_name",
        width: 205,
        sortType: "string",
        Cell: ({ row }) => {
          const {
            original: {
              id,
              campaign_name,
              campaign_status,
              system_generated_campaign_id,
              campaign_type,
              participant_template,
              brand_owner_template,
              parent_company_template,
            },
          } = row;
          let searchQuery = "";

          if (
            fromPage &&
            fromPage === "location_report" &&
            extraData?.locationSysId
          ) {
            searchQuery = `?locationID=${extraData?.locationSysId}`;
          }

          const isTemplateExists =
            (loggedInUserRole === "PR" && participant_template) ||
            ((loggedInUserRole === "BO" || loggedInUserRole === "BU") &&
              brand_owner_template) ||
            (loggedInUserRole === "PC" && parent_company_template);

          const showLink =
            isTemplateExists &&
            (campaign_type === "Evergreen" ||
              campaign_status === "Active" ||
              campaign_status === "Completed" ||
              campaign_status === "Paused");

          if (showLink) {
            return (
              <Link
                to={{
                  pathname: `${process.env.PUBLIC_URL}/dashboard/campaign-report/${system_generated_campaign_id}`,
                  state: {
                    campaignId: id,
                    campaignSysId: system_generated_campaign_id,
                    from: "/dashboard",
                  },
                  search: searchQuery ? searchQuery : "",
                }}
                className={`${showLink ? "main-color link" : "gray-color"}`}
              >
                {campaign_name}
              </Link>
            );
          }

          return <span className={`gray-color`}>{campaign_name}</span>;
        },
      },
      {
        Header: "Status",
        accessor: (d) =>
          // d.campaign_status === "Active" ? "Live" : d.campaign_status,
          d.campaign_status,
        id: "status",
        // width: 100,
        Cell: ({ row }) => {
          const {
            original: { campaign_status },
          } = row;

          const camp_status =
            campaign_status === "Active" ? "Live" : campaign_status;

          return (
            <StatusChip
              label={camp_status}
              status={getStatusCode(camp_status, "hold")}
            />
          );
        },
      },

      {
        Header: "Budget",
        accessor: "campaignBudget",
        Cell: ({ row }) => {
          const {
            original: { campaignBudget },
          } = row;
          return (
            <span className="fw-b">
              {campaignBudget || campaignBudget === 0 ? (
                <CurrencyFormateText value={campaignBudget} />
              ) : (
                "N/A"
              )}
            </span>
          );
        },
      },
      {
        Header: "Start Date",
        accessor: (originalRow) => {
          return new Date(originalRow.start_date);
        },
        id: "start_date",
        sortType: "datetime",
        Cell: ({ row }) => {
          const { original } = row;

          const sd = getFormattedCampaignStartDate(original, {
            loggedInUserRole,
            selectedBrand,
          });
          return (
            <RpImageTextInline
              image={sd !== "Evergreen" ? "/img/icons/calendar.png" : ""}
              text={sd}
            />
          );
        },
      },
      {
        Header: "End Date",
        accessor: (originalRow) => {
          return new Date(originalRow.end_date);
        },
        sortType: "datetime",
        id: "end_date",
        Cell: ({ row }) => {
          const { original } = row;
          const ed = getFormattedCampaignEndDate(original, {
            loggedInUserRole,
            selectedBrand,
          });
          return (
            <RpImageTextInline
              image={ed !== "Evergreen" ? "/img/icons/calendar.png" : ""}
              text={ed}
            />
          );
        },
      },
      {
        Header: "Channels",
        accessor: "channelNames",
        id: "channels",
        canSort: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const {
            original: { channelNames = "" },
          } = row;
          return getChannelIcons(channelNames.split(",").sort());
        },
      },
      {
        Header: () => <span className="">Actions</span>,
        id: "faq",
        width: 120,
        canSort: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <div className="d-flex align-items-center justify-content-end justify-content-lg-start">
              <LearnMoreButton
                to={{
                  pathname: `${process.env.PUBLIC_URL}/dashboard/learn-more/digital-campaign/${original?.id}`,
                  state: { campaignId: original?.id, from: "/dashboard" },
                }}
                className="mr-1"
                icon
              />
              {original?.pause_campaign_available &&
              loggedInUser &&
              loggedInUser?.fk_role_id !== 2 ? (
                <PauseLocationAddIcon
                  campaignId={original?.id}
                  campaign={original}
                />
              ) : null}
            </div>
          );
        },
      },
    ],
    [loggedInUserRole, selectedBrand, fromPage, extraData]
  );

  const filters = (setFilter, selectedFilters) => {
    // Should be defined in tha parent, but for the time being defining here
    return (
      <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
        <MUICustomAutocompleteSelect
          name="status"
          label="Status"
          selectProps={{
            onChange: (v) => {
              setFilter("status", v);
            },
            value: selectedFilters?.find((f) => f.id === "status")?.value || "",
          }}
          options={[
            { label: "Live", value: "Active" },
            { label: "Scheduled", value: "Scheduled" },
            { label: "Completed", value: "Completed" },
          ]}
          all
        />
      </div>
    );
  };

  if (fromPage === "location_report") {
    return (
      <>
        <TableClient
          columns={columns}
          data={campaignsList}
          showSearch={true}
          filters={filters}
          showFilter={showFilter}
          hideSearchLabel={hideSearchLabel}
          totalRecords={totalRecords}
        />
      </>
    );
  }
  return (
    <>
      <TableServer
        columns={columns}
        data={campaignsList}
        showSearch={true}
        fetchData={fetchCampaignsReportData}
        isLoading={isLoading}
        pageCount={pageCount}
        showFilter={showFilter}
        useLayout={false}
        hideSearchLabel={hideSearchLabel}
        // filters={filters}
        totalRecords={totalRecords}
      />
    </>
  );
};

export default CampaignsList;
