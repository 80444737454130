import queryString from "query-string";
import { StripeMicroServiceAxiosInstance } from "../../config/AxiosInstance";

export const stripeMicroService = {
  subscriptionPayment,
  subscriptionPaymentConfirm,
  pendingInvoiceCheck,
  openCustomerPortal,
  getInvoiceHistory,
  getCurrentSubscriptions,
  getSubscriptionHistory,
  joinCampaignPayment,
  joinCampaignPaymentConfirm,
  boostCampaignPayment,
  boostCampaignPaymentConfirm,
};

function subscriptionPayment(data) {
  return StripeMicroServiceAxiosInstance.post(`/subscription/create`, data);
}

function subscriptionPaymentConfirm(session_id) {
  return StripeMicroServiceAxiosInstance.get(
    `/subscription/return?session_id=${session_id}`
  );
}

function pendingInvoiceCheck(data) {
  return StripeMicroServiceAxiosInstance.post(
    `/subscription/pending-invoice-check`,
    data
  );
}

function openCustomerPortal(data) {
  return StripeMicroServiceAxiosInstance.post(`/customer/portal`, data);
}

function getInvoiceHistory(data) {
  const query = queryString.stringify(data);
  return StripeMicroServiceAxiosInstance.get(`/invoice/list?${query}`);
}

function getCurrentSubscriptions(data) {
  const query = queryString.stringify(data);
  return StripeMicroServiceAxiosInstance.get(
    `/subscription/current-list?${query}`
  );
}

function getSubscriptionHistory(data) {
  const query = queryString.stringify(data);
  return StripeMicroServiceAxiosInstance.get(`/subscription/history?${query}`);
}

function joinCampaignPayment(data) {
  return StripeMicroServiceAxiosInstance.post(`/campaign/participant`, data);
}

function joinCampaignPaymentConfirm(session_id) {
  return StripeMicroServiceAxiosInstance.get(
    `/campaign/participant/return?session_id=${session_id}`
  );
}

function boostCampaignPayment(data) {
  return StripeMicroServiceAxiosInstance.post(
    `/boost-campaign/participant`,
    data
  );
}

function boostCampaignPaymentConfirm(session_id) {
  return StripeMicroServiceAxiosInstance.get(
    `/boost-campaign/participant/return?session_id=${session_id}`
  );
}
