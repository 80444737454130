import React, { memo } from "react";
import TextClamp from "react-string-clamp";

const NumberFigure = ({
  className = "",
  titleClass = "",
  valueClass = "",
  title = "",
  value = "",
}) => {
  const formattedValue = String(value) === "0" || value ? value : "--";

  return (
    <div
      className={`rp-number-figure h-100 mb-2 text-center text-md-left ${className}`}
      title={title}
    >
      <TextClamp
        text={title}
        lines={2}
        className={`widget-title rp-line-clamp ${titleClass} `}
      />

      <div
        title={formattedValue === "--" ? "N/A" : formattedValue}
        className={`fs-20 font-weight-bold count widget-state-value ${valueClass} `}
      >
        {formattedValue}
      </div>
    </div>
  );
};

export default memo(NumberFigure);
