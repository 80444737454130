import React, { memo, useState } from "react";
import { Img } from "react-image";
import styled, { css } from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Dialog from "@material-ui/core/Dialog";

import RpVideoPlayer from "../../components/RpVideoPlayer";
import { isYouTubeUrl, extractVideoIdFromUrl } from "../../helpers/utils";
import { getFormatDate } from "../../helpers/utils";
import DialogContent from "@material-ui/core/DialogContent";
import { STATIC_IMAGES } from "../../helpers/staticImages";
import TablePagination from "../../components/tables/TablePagination";

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    height: "100%",
  },
  image: {
    minHeight: "187px",
  },
  overlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: "rgb(0 0 0 / 27%);",
    color: "#fff",
    opacity: 1,
  },
  dateLine: {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      left: "0",
      bottom: "-2px",
      width: "11%",
      height: "2px",
      backgroundColor: "#04a999",
    },
  },
  title: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
  },
});

const ImgLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  transition: all 0.2s;
  ${(props) => css`
    ${props.style}
  `}
`;

function EducationalVideoSection({
  educationalVideos = [],
  pageIndex,
  pageSize,
  handlePageChange,
  totalVideos,
  loader = <CircularProgress size={20} />,
  loaderBoxClass = "",
  loaderBoxStyle = {},
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleThumbnailClick = (url) => {
    setSelectedVideoUrl(url);
    setOpenDialog(true);
  };

  const totalCount = Math.ceil(totalVideos / pageSize);

  return (
    <div>
      <section className="rc-section text-center my-4">
        <div className="row my-5 justify-content-center position-relative align-items-stretch">
          {educationalVideos &&
            educationalVideos.map((u) => {
              if (!isYouTubeUrl(u.video_url)) return null;
              const videoId = extractVideoIdFromUrl(u.video_url);
              const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
              return (
                <div className="col-md-4 col-lg-3 mb-4" key={u.id}>
                  <Card className={classes.root}>
                    <CardActionArea
                      className={classes.image}
                      onClick={() => handleThumbnailClick(u.video_url)}
                    >
                      <Img
                        src={thumbnailUrl}
                        alt="thumbnail"
                        loader={loader}
                        loaderContainer={(children) => (
                          <ImgLoader
                            className={` ${loaderBoxClass}`}
                            style={loaderBoxStyle}
                          >
                            {children}
                          </ImgLoader>
                        )}
                      ></Img>
                      <div className={classes.overlay}>
                        <img
                          src={STATIC_IMAGES.youtubeIcon}
                          alt=""
                          className="img-fluid sg-youtube-img"
                        />
                      </div>
                    </CardActionArea>
                    <CardContent>
                      <Typography
                        component="span"
                        className={`d-flex justify-content-start rc-date mt-3 ${classes.dateLine}`}
                      >
                        {getFormatDate(
                          u?.video_date ? u?.video_date : u?.createdAt
                        )}
                      </Typography>
                      <Typography
                        component="h5"
                        variant="body1"
                        className={`rc-title pt-3 ${classes.title}`}
                      >
                        {u.video_title}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
        </div>
        <div className="row d-flex row d-flex justify-content-center paging">
          <TablePagination
            count={totalCount}
            page={pageIndex}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            className="float-right"
          />
        </div>
      </section>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
        className="video-dialog"
      >
        <DialogContent className="p-0">
          <div style={{ overflow: "hidden" }}>
            <RpVideoPlayer url={selectedVideoUrl} light={null} playing={true} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default memo(EducationalVideoSection);
