import React from "react";
import moment from "moment";
import {
  campaignFormatHelper,
  getCampaignStatusText,
  getStatusCode,
} from "../../../../helpers/utils";
import { CurrencyFormateText } from "../../../../components/CurrencyFormat";
import StatusChip from "../../../../components/muiCustom/chip/StatusChip";
import LearnMoreButton from "../../../../components/muiCustom/buttons/LearnMoreButton";

function JoinedPostcardCampaignCard({ campaign }) {
  const { id, budget, brand_contribution, total_of_budget_and_brand_match } =
    campaign || {};

  // const renderLocations = () => {
  //   if (participant_campaign && participant_campaign.is_draft === false) {
  //     const locs = participant_campaign.locationData || [];
  //     const popContent = locs.map((l) => l.locationName).join(", ");

  //     return locs.length ? (
  //       <RpPopover popContent={popContent}>
  //         <span className="cursor-p">{locs.length}</span>
  //       </RpPopover>
  //     ) : (
  //       0
  //     );
  //   }
  //   return null;
  // };

  const renderBudget = () => {
    let campaignBudget = budget || 0;
    if (brand_contribution) {
      campaignBudget = total_of_budget_and_brand_match || 0;
    }

    return <CurrencyFormateText value={campaignBudget} />;
  };

  const getScheduleDates = (campaign) => {
    const start = moment(campaign?.schedule_date);
    const end = start.isValid() ? moment(start).add(4, "day") : null;
    if (campaign?.schedule_date && start.isValid()) {
      return `${start.format("M/D/YYYY")} - ${end.format("M/D/YYYY")}`;
    }
    return "NA";
  };

  return (
    <div className="card h-100">
      <div className="card-body p-3">
        <div className="row">
          <div className="col-7 col-lg-3 fw-sb">
            {campaignFormatHelper(campaign).name(
              `${process.env.PUBLIC_URL}/dashboard/postcard-campaign-report`
            )}
          </div>
          <div className="col-5 col-lg-2 text-right text-lg-left pl-lg-0">
            <StatusChip
              label={getCampaignStatusText(2)}
              status={getStatusCode(2)}
            />
          </div>
          <div className="col-6 col-lg-2 mt-2 mt-lg-0 order-1 order-lg-0">
            <div className="d-lg-none title">Active Budget: </div>
            <span className="title-val fw-b">{renderBudget()}</span>
          </div>

          <div className="col-12 col-lg-3 mt-2 mt-lg-0 order-0 order-lg-1">
            <div className="d-lg-none title">Schedule Date: </div>
            <span className="title-val">{getScheduleDates(campaign)}</span>
          </div>

          <div className="col-12 col-lg-2 mt-lg-0 order-1">
            <div className="d-lg-none invisible title mb-1">
              Helpful Content :{" "}
            </div>
            <div className="d-flex align-items-center justify-content-end justify-content-lg-start">
              <LearnMoreButton
                to={{
                  pathname: `${process.env.PUBLIC_URL}/dashboard/learn-more/postcard-campaign/${id}`,
                  state: { campaignId: id, from: "/dashboard" },
                }}
                className="px-1 fs-8"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinedPostcardCampaignCard;
