import React, { useCallback, useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

import RpMUIModal from "../modals/RpMUIModal";

function NotSupportedAmountMsg({ onOkNotSupportedAmount, makePayment }) {
  const [openInfo, setOpenInfo] = useState(true);

  useEffect(() => {
    setOpenInfo(true);
  }, [makePayment]);

  const handleClose = useCallback(() => {
    setOpenInfo(false);
    if (
      onOkNotSupportedAmount &&
      typeof onOkNotSupportedAmount === "function"
    ) {
      onOkNotSupportedAmount();
    }
  });

  return (
    <RpMUIModal
      open={openInfo ? true : false}
      onClose={() => setOpenInfo(false)}
      maxWidth="sm"
      style={{ zIndex: "9999999" }}
      persists
    >
      <div className="text-center">
        <WarningIcon className="text-warning" fontSize="large" />
      </div>
      <Typography variant="h6" className="text-center" gutterBottom>
        Amount not supported
      </Typography>
      <Typography variant="body1" className="text-center" gutterBottom>
        Please note that Stripe supports a maximum payment amount of{" "}
        <strong> $999,999.99 </strong>
        for a single checkout transaction.
      </Typography>
      <Typography variant="body1" className="text-center">
        Thank you for your understanding!
      </Typography>
      <div className="text-center mt-5 mb-3">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          className="px-5"
        >
          OK
        </Button>
      </div>
    </RpMUIModal>
  );
}

export default NotSupportedAmountMsg;
