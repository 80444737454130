import React from "react";
import { useParams } from "react-router-dom";

import BoostCampaign from "../../containers/campaign/boostCampaign/BoostCampaign";
import PageLayout from "../../components/layouts/page/PageLayout";

function BoostCampaignPage() {
  const { campaignID } = useParams();

  return (
    <div>
      <PageLayout className="participant-dashboard-container mx-0 mx-sm-2">
        <BoostCampaign campaignId={campaignID} />
      </PageLayout>
    </div>
  );
}

export default BoostCampaignPage;
