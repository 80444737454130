import { useMemo, useState } from "react";
import { Field, useFormikContext } from "formik";
import {
  Button,
  Chip,
  FormHelperText,
  FormLabel,
  TextField,
} from "@material-ui/core";

import { MorganStanleyEmailListValidationSchema } from "../../../helpers/validations";
import { logger } from "../../../helpers/loggerHelper";

export default function EmailListFields() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);

  const { values, setFieldValue } = useFormikContext();

  const currentValue = useMemo(() => {
    return values?.email_list || [];
  }, [values?.email_list]);

  const handleOnAdd = () => {
    if (!emailError && email.trim() !== "") {
      if (currentValue.includes(email)) {
        setEmailError("Entered email already in list");
      } else {
        setFieldValue("email_list", [...currentValue, email]);
        setEmail("");
      }
    }
  };

  const handleOnDelete = (email) => () => {
    setFieldValue("email_list", currentValue?.filter((m) => m !== email) || []);
  };

  const handleOnChange = (e) => {
    const val = e.target.value;
    setEmail(val);
    if (val) {
      MorganStanleyEmailListValidationSchema.validate({ email: val })
        .then(() => {
          setEmailError(null);
        })
        .catch((err) => {
          logger.error("EmailListFields error", err);
          setEmailError(err?.errors?.[0] || "Please enter a valid email");
        });
    } else {
      setEmailError(null);
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <FormLabel>Email Addresses for Distribution List</FormLabel>
        <div>
          <small>
            (Include additional email addresses to include them in the
            distribution list for monthly reporting, subscription renewal, and
            all Hyperlocology communication.)
          </small>
        </div>
        <div className="d-flex-center">
          <Field
            name="email"
            component={TextField}
            size="small"
            variant="outlined"
            color="primary"
            className="mr-2"
            value={email}
            onChange={handleOnChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnAdd}
            size="small"
            type="button"
          >
            +
          </Button>
        </div>

        {emailError && (
          <FormHelperText className="text-danger">{emailError}</FormHelperText>
        )}
      </div>
      <div className="col-12">
        <div className="email-list-box my-4">
          {currentValue?.map((m, i) => (
            <Chip
              label={m}
              onDelete={handleOnDelete(m)}
              size="small"
              key={i}
              className="mr-2 mb-2"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
