import React, { useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";

import { makeUniqChartData, RPNumberFormat } from "../../helpers/utils";
import { datasetDefaultOptions } from "./ChartJsConfig";

const BarChart = ({
  data = [],
  labels,
  option = {},
  scales = {},
  title = "",
  formats = null,
}) => {
  const [chartData, setChartData] = useState({});

  const options = useMemo(
    () => ({
      tooltips: {
        //intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.datasets[0].label;

            const val = data.datasets[0].data[tooltipItem.index] || {};

            const format = formats ? formats[labels[1]] : null;
            if (!val.y) return "";
            if (formats && format) {
              return `${label}: ${RPNumberFormat({
                prefix: format.prefix,
                suffix: format.suffix,
              }).to(parseFloat(val.y))}`;
            }
            return `${label}: ${RPNumberFormat().to(parseFloat(val.y))}`;
          },
          title: (tooltipItem) => {
            const label = tooltipItem[0].label;
            return label;
          },
        },
      },
      legend: {
        display: false,
        align: "start",
        labels: {
          boxWidth: 10,
          fontColor: "#54545B",
          padding: 5,
          marginBottom: 10,
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        fontColor: "#808080",
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              callback: (value) => {
                return value.slice(0, 7) + `${value.length > 7 ? ".." : ""}`;
              },
            },
          },
        ],
        yAxes: [
          {
            id: "y-axis-0",
            position: "left",
            ticks: {
              min: 0,
              maxTicksLimit: 5,
              callback: (value) => {
                const format = formats ? formats[labels[1]] : null;
                if (!value) return "";
                if (formats && format) {
                  return RPNumberFormat({
                    prefix: format.prefix,
                    suffix: format.suffix,
                  }).to(value);
                }
                return RPNumberFormat().to(value);
              },
            },
          },
        ],
        ...scales,
      },
      ...option,
    }),
    [option, scales]
  );

  const getDataSet = () => {
    let formatData = [];

    formatData = makeUniqChartData(data, labels[0]).map((d) => ({
      x: d[labels[0]],
      y: d[labels[1]],
    }));

    const datasets = [
      {
        label: title,
        data: formatData,
        yAxisID: "y-axis-0",
        ...datasetDefaultOptions,
      },
    ];

    return datasets;
  };

  useEffect(() => {
    const initData = {
      labels: getDataSet()[0].data ? getDataSet()[0].data.map((d) => d.x) : [],
      datasets: getDataSet(),
    };
    setChartData(initData);
  }, [data]);

  return (
    <div className="area-chart">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;
