import { AUTO_LOGOUT } from "../config/constants";
import { logger } from "./loggerHelper";

let channel = null;

export const createBroadcastChannel = (
  channelName = AUTO_LOGOUT.CHANNEL_NAME
) => {
  try {
    if (!channel) {
      channel = new BroadcastChannel(channelName);
    }
    return channel;
  } catch (err) {
    logger.warn("Failed to create broadcast channel", err);
    return null;
  }
};

export const postBroadcastChannelMessage = (message) => {
  try {
    if (!channel) {
      channel = new BroadcastChannel(AUTO_LOGOUT.CHANNEL_NAME);
    }
    if (channel && channel instanceof BroadcastChannel) {
      channel?.postMessage(message);
    }
  } catch (err) {
    logger.warn("Failed to post broadcast message", err);
  }
};
