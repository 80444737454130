import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { isEmpty, sortBy } from "lodash-es";
import { Icon, Tab, Tabs, Typography, withStyles } from "@material-ui/core";
import JoinCampaignPageSubHeader from "../../../components/joinCampaigns/JoinCampaignPageSubHeader";
import SubscribedLocations from "./subscribedLocations/SubscribedLocations";
import RpSnackbar from "../../../components/alerts/RpSnackbar";
import InActiveLocations from "./inActiveLocations/InActiveLocations";
import { joinCampaignHelper } from "../../../helpers/joinCampaignHelper";
import { RpConfirmDialog } from "../../../helpers/utils";
import RpConfirm from "../../../components/RpConfirm";
import LocationsExpandCollapseButtons from "../../../components/buttons/LocationsExpandCollapseButtons";

const RpTabs = withStyles(() => ({
  root: {
    minWidth: "auto",
    minHeight: "auto",
    width: "auto",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const RpTab = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    minWidth: "auto",
    minHeight: "auto",
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    // border: `1px solid ${theme.palette.primary.main}`,
    margin: "auto 5px",
    padding: "2px 12px",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "none",
    opacity: 1,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}))(Tab);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`campaigns-tabpanel-${index}`}
      aria-labelledby={`campaigns-tab-${index}`}
      {...other}
      className="rp-campaign-tab"
    >
      {/* {value === index && children} */}
      {children}
    </div>
  );
}

export default function JoinCampaignForm({
  campaign,
  initialValues,
  loggedInUser,
  selectedBrand,
  submit,
  setIsDraft,
  productList,
  getAdvanceSettingData,
  isDraft,
}) {
  const [selectedTab, setSelectedTab] = useState(0);

  const [formError, setFormError] = useState({});

  const [fillAdvancedSetting, setFillAdvancedSetting] = useState(false);
  const [advancedSettingFormError, setAdvancedSettingFormError] = useState({});

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (!fillAdvancedSetting) {
      setAdvancedSettingFormError({});
    }
  }, [fillAdvancedSetting]);

  const isAdvancedSettingsFormValid = !Object.keys(advancedSettingFormError)
    ?.length;

  const validLocations = useMemo(() => {
    const {
      locations: { subscribed },
    } = campaign;
    return subscribed && subscribed.length ? sortBy(subscribed, "id") : [];
  }, [campaign]);

  const inValidLocationsCount = useMemo(() => {
    const {
      locations: { fallOutSide, unSubscribed },
    } = campaign;
    return fallOutSide.length + unSubscribed.length;
  }, [campaign]);

  const allLocationsIds = useMemo(() => {
    const {
      locations: { fallOutSide, unSubscribed },
    } = campaign;

    if (selectedTab === 0) {
      return validLocations && validLocations.length
        ? validLocations.map((l) => l.id)
        : [];
    } else if (selectedTab === 1) {
      const fos =
        fallOutSide && fallOutSide.length ? fallOutSide.map((l) => l.id) : [];
      const us =
        unSubscribed && unSubscribed.length
          ? unSubscribed.map((l) => l.id)
          : [];
      return [...fos, ...us];
    }
  }, [campaign, selectedTab, validLocations]);

  useEffect(() => {
    if (validLocations.length === 0 && inValidLocationsCount > 0) {
      setSelectedTab(1);
    }
  }, [inValidLocationsCount, validLocations]);

  useEffect(() => {
    if (allLocationsIds.length) {
      if (allLocationsIds.length <= 5) {
        setExpanded([...allLocationsIds]);
      } else {
        setExpanded([allLocationsIds[0]]);
      }
    } else {
      setExpanded([]);
    }
  }, [allLocationsIds]);

  const handleOnChangeAdvancedSettingError = useCallback(
    ({ locId, errors }) => {
      // const hasKey = Object.keys(advancedSettingFormError)?.includes(locId);
      const hasErr = errors && Object.keys(errors)?.length;
      if (hasErr) {
        setAdvancedSettingFormError((p) => ({ ...p, [locId]: errors }));
      } else {
        setAdvancedSettingFormError((p) => {
          // eslint-disable-next-line no-unused-vars
          const { [locId]: _, ...rest } = p;
          return { ...rest };
        });
      }
    },
    []
  );

  /**
   * @method handleFormSubmit
   * @description handle on submit form
   * @param {*} values
   * @param {*} actions
   * @returns
   */
  const handleFormSubmit = (values, actions) => {
    setFormError({});

    const { campaign_advanced_setting } = campaign;
    const { is_required } = campaign_advanced_setting || {};

    // consider only fully filled locations
    const { locs, locIds } = joinCampaignHelper.getValidLocations(
      values,
      campaign
    );
    const totalEnrollingLocations = locIds.length;

    const locationsFilledData = locs;

    // check of there is no fully filled location then prevent to submit
    if (locIds.length === 0) {
      setFormError({
        message: `Please make your selections!`,
      });
      actions.setSubmitting(false);
      return;
    }

    // Check for campaign constraints
    const { campaign_type } = campaign;
    if (campaign_type === "Normal") {
      if (
        joinCampaignHelper.isNormalCampaignConstraintViolated(
          campaign,
          locationsFilledData,
          setFormError,
          locIds
        )
      ) {
        actions.setSubmitting(false);
        return;
      }
    } else {
      // Check for Evergreen campaign constraints
      if (
        joinCampaignHelper.isEvergreenCampaignConstraintViolated(
          campaign,
          locationsFilledData,
          setFormError,
          locIds
        )
      ) {
        actions.setSubmitting(false);
        return;
      }
    }

    const totalActiveLocations = validLocations.length;

    if (totalEnrollingLocations === 0) {
      // if there is no fully filled location prevent to submit
      setFormError({
        message: `Please make your selections!`,
      });
      actions.setSubmitting(false);
      return;
    }

    const locationData = joinCampaignHelper.getFormattedLocationData(
      values,
      campaign
    );

    // checking for advanced settings required

    if (!isDraft && !fillAdvancedSetting && is_required) {
      actions.setSubmitting(false);
      setFillAdvancedSetting(true);
      return;
    }

    // if (
    //   joinCampaignHelper.isCampaignAdvancedSettingsViolated(
    //     campaign,
    //     locationData,
    //     setFormError
    //   )
    // ) {
    //   setFillAdvancedSetting(true);
    //   actions.setSubmitting(false);
    //   return;
    // }

    const saveDraftData = {
      email_id: loggedInUser.email,
      user_id: loggedInUser.id,
      user_name: `${loggedInUser.first_name} ${loggedInUser.last_name}`,
      fk_participant_id: loggedInUser?.participantData?.id,
      fk_campaign_id: campaign.id,
      // system_generated_campaign_id: campaign.system_generated_campaign_id,
      fk_campaign_name: campaign.campaign_name,
      campaign_type: campaign.campaign_type,
      duration: campaign.duration,
      start_date: campaign.start_date,
      end_date: campaign.end_date,
      locationData: JSON.stringify(locationData),
      payment_status: 0,
      total_budget: joinCampaignHelper.getTotal(locationsFilledData),
      is_draft: true,
      splitBudgetEqually: false,
      budget_type: campaign?.budget_type,
    };
    if (campaign.budget_type === "Pre-set" && campaign.local_selection) {
      // Auto enrolled and now only creatives are selecting
      saveDraftData.is_draft = false;
      saveDraftData.is_enrolled = 1;
      saveDraftData.is_creative_selected = 1;
    }

    // actions.setSubmitting(false);

    if (totalActiveLocations === totalEnrollingLocations) {
      submit(saveDraftData);
    } else {
      RpConfirmDialog(
        <RpConfirm
          message={`You're enrolling ${totalEnrollingLocations} of ${totalActiveLocations} locations in this campaign. Are you sure you want to proceed?`}
        />,

        () => {
          submit(saveDraftData);
        },
        () => {
          setIsDraft(false);
          actions.setSubmitting(false);
          return;
        }
      );
    }
  };

  const renderPage = ({
    values,
    handleSubmit,
    setFieldValue,
    setFieldError,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <JoinCampaignPageSubHeader
          onSaveAsDraft={() => setIsDraft(true)}
          campaign_name={campaign?.campaign_name}
          isSubmitting={isSubmitting}
          isAdvancedSettingsFormValid={isAdvancedSettingsFormValid}
          selectedTab={selectedTab}
          allLocationsIds={allLocationsIds}
        />
        <div className="join-campaign-form mx-lg-5 mx-3">
          <div className="join-campaign-form-page">
            <div className="my-1 pt-2 row">
              <div className="col-xl-5 mb-4 mb-xl-0">
                <Typography component="h2" variant="h6">
                  <Icon
                    component="img"
                    src="/img/icons/campaign.png"
                    className="mr-2"
                  />
                  {`${campaign?.campaign_name} Campaign Selections`}
                </Typography>
              </div>
              <div className="col-xl col-xl-7 d-flex justify-content-start justify-content-lx-end pl-xl-0">
                <div className="row flex-fill align-items-center">
                  <div className="col mb-3 mb-lg-0 d-flex justify-content-center justify-content-sm-start justify-content-xl-end px-xl-0">
                    <RpTabs
                      value={selectedTab}
                      onChange={(event, newValue) => setSelectedTab(newValue)}
                      variant="scrollable"
                      scrollButtons="off"
                    >
                      <RpTab
                        label={`Eligible Locations (${validLocations.length})`}
                        icon={
                          <Icon fontSize="inherit" className="mt-1 mr-1">
                            dashboard
                          </Icon>
                        }
                      />
                      <RpTab
                        label={`Not Eligible Locations (${inValidLocationsCount})`}
                        icon={
                          <Icon fontSize="inherit" className="mt-1 mr-1">
                            dashboard
                          </Icon>
                        }
                      />
                    </RpTabs>
                  </div>
                  <div className="col-lg-3 pl-0 d-flex justify-content-end">
                    <LocationsExpandCollapseButtons
                      setExpanded={setExpanded}
                      expanded={expanded}
                      allLocationsIds={allLocationsIds}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="locations my-3">
              <TabPanel value={selectedTab} index={0}>
                <SubscribedLocations
                  values={values}
                  subscribedLocations={validLocations}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  campaign={campaign}
                  isSubmitting={isSubmitting}
                  onSaveAsDraft={() => setIsDraft(true)}
                  productList={productList}
                  getAdvanceSettingData={getAdvanceSettingData}
                  fillAdvancedSetting={fillAdvancedSetting}
                  setFillAdvancedSetting={setFillAdvancedSetting}
                  isAdvancedSettingsFormValid={isAdvancedSettingsFormValid}
                  handleOnChangeAdvancedSettingError={
                    handleOnChangeAdvancedSettingError
                  }
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <InActiveLocations
                  fallOutSideLocations={campaign?.locations?.fallOutSide}
                  unSubscribedLocations={campaign?.locations?.unSubscribed}
                  selectedBrand={selectedBrand}
                  campaign={campaign}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              </TabPanel>
            </div>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleFormSubmit}
      >
        {(props) => renderPage(props)}
      </Formik>

      {!isEmpty(formError) && <RpSnackbar show message={formError.message} />}
    </>
  );
}
