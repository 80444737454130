import React, { useEffect, useContext, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import PageLayout from "../../../components/layouts/page/PageLayout";
import BackLink from "../../../components/BackLink";
import PageHeader from "../../../components/layouts/pageHeaders/PageHeader";
import { isEmpty } from "lodash";
import { withFormik } from "formik";
import useQuery from "../../../helpers/hooks/useQuery";
import { reportService } from "../../../services/reportService";
import NoDataFound from "../../../components/NoDataFound";
import PostCardCampaignReport from "./PostCardCampaignReport";
import PostcardCampaignReportFilters from "./PostcardCampaignReportFilters";
import { renderTemplate } from "../../../helpers/reportHelper";
import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import TableChart from "../../../components/charts/TableChart";
import useFetcher from "../../../helpers/hooks/useFetcher";

const PostcardCampaign = ({ values, errors, handleChange, setFieldValue }) => {
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);
  const { campaignSysId } = useParams();
  const { locationID } = useQuery();
  const [contentLoaded, setContentLoaded] = useState(false);

  const [campaignReport, setCampaignReport] = useState(null);

  const campaignReportRequestData = useMemo(() => {
    return {
      system_generated_campaign_id: `${campaignSysId}`,
      fk_brand_id: selectedBrand.id,
      fk_participant_id: loggedInUser?.participantData
        ? loggedInUser?.participantData?.id
        : 0,
      participant_system_id: loggedInUser?.participantData
        ? loggedInUser?.participantData?.system_generated_participant_id
        : "",
      userId: loggedInUser.id,
      fk_role_id: loggedInUser.fk_role_id,
      regions_id: values.region_id ? values.region_id : "",
      group_id: values.group_id ? values.group_id : "",
      location_id: values.location_id ? values.location_id : "",
      channel_id: values.channel_id ? values.channel_id : "",
      filter: values.filter,
      start_period:
        values.filter === "custom" && values.customRange
          ? `${values.customRange.from.year}-${values.customRange.from.month}`
          : "",
      end_period:
        values.filter === "custom" && values.customRange
          ? `${values.customRange.to.year}-${values.customRange.to.month}`
          : "",
    };
  }, [selectedBrand?.id, loggedInUser, campaignSysId, values]);

  const { data: report, isLoading: loading } = useFetcher(
    Object.values(errors).length === 0 ||
      Object.values(errors).every((v) => v === "")
      ? campaignReportRequestData
      : null,
    reportService.getPostcardCampaignReport,
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (locationID) {
      setFieldValue("location_id", locationID);
    }
  }, [locationID, setFieldValue]);

  useEffect(() => {
    loader(loading);
  }, [loading, loader]);

  useEffect(() => {
    if (!loading) {
      setContentLoaded(true);
    }
    if (!isEmpty(report?.data)) {
      setCampaignReport(report?.data);
    }
  }, [loading]);

  const reportData =
    !loading && !isEmpty(report?.data) ? report?.data : campaignReport;

  if ((!reportData || isEmpty(reportData)) && !loading) {
    return (
      <NoDataFound
        message={
          <div>
            <div>No Data found!</div>
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard`}
              className="button primary btn btn-primary my-4"
            >
              Back to dashboard
            </Link>
          </div>
        }
      />
    );
  }

  const renderTable = (widget) => {
    if (!widget.data || widget.data.length === 0) {
      return <NoDataFound message="There is no record" height="100%" />;
    }
    if (Array.isArray(widget.data)) {
      let headings = Object.keys(widget.data[0]);
      if (headings.includes("creative_image")) {
        headings = headings.filter((h) => h !== "creative_image");
      }
      if (headings.includes("creative_image_two")) {
        headings = headings.filter((h) => h !== "creative_image_two");
      }
      return (
        <TableChart
          data={widget.data}
          headings={headings}
          formats={widget?.format}
        />
      );
    }
    return null;
  };

  return (
    <>
      <PageLayout className="campaign-report-container mx-0 mx-sm-2">
        <BackLink />
        <PageHeader title="Campaign Report" showLastUpdate />
        {contentLoaded ? (
          <div id="campaignReport" className="report campaign-report mt-0 mb-5">
            <div className="card campaign-detail">
              <div className="card-body">
                <PostCardCampaignReport campaign={reportData} values={values} />
              </div>
            </div>
            <PostcardCampaignReportFilters
              handleChange={handleChange}
              values={values}
              campaign={report?.data}
              setFieldValue={setFieldValue}
            />
            {renderTemplate({
              report: reportData,
              renderTable,
              // renderChartWidget,
              // channel_name: values.channel_id,
              // reportType: "campaign",
            })}
          </div>
        ) : (
          <NoDataFound message="Loading..." />
        )}
      </PageLayout>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      start_date: "",
      end_date: "",
      // start_date:  moment().startOf("M"),
      // end_date: moment().endOf("M"),
      location_id: "",
      filter: "custom",
      customRange: "",
    };
  },
  enableReinitialize: true,
})(PostcardCampaign);
