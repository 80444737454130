import React, { useContext, useMemo } from "react";
import { useLocation, Link } from "react-router-dom";
import { Button, Icon, Typography } from "@material-ui/core";
import { GlobalContext } from "../helpers/contexts/contexts";

import ThankYou from "../components/ThankYou";
import PageLayout from "../components/layouts/page/PageLayout";
import CustomizeAds from "../components/thankyouMessages/CustomizeAds";
import CampaignConfirmationThankyouPage from "../components/thankyouMessages/CampaignConfirmationThankyouPage";
import BoostCampaignConfirmationThankyouPage from "../components/thankyouMessages/BoostCampaignConfirmationThankyouPage";
import CampaignPauseSuccess from "../components/thankyouMessages/CampaignPauseSuccess";

const ThankYouPage = () => {
  const { state } = useLocation();
  const { loggedInUserRole } = useContext(GlobalContext);
  const { is_redirect, campaign_id } = state || {};

  const message = useMemo(() => {
    switch (state?.from) {
      case "join_campaign_confirm":
        return <CampaignConfirmationThankyouPage />;
      case "package_selection":
        return (
          <ThankYou
            message={`<div>
                <p>
                  We've received your subscriptions!
                </p>       
                <p>      
                  You'll receive an email from us with a confirmation and helpful next steps.
                </p>
              </div>
            `}
          />
        );
      case "ms_activation_brief":
        return <MSActivationBriefThankYouMessage />;
      case "boost_campaign_confirm":
        return <BoostCampaignConfirmationThankyouPage />;
      case "campaign_pause":
        return <CampaignPauseSuccess />;
      default:
        return <ThankYou />;
    }
  }, [state]);

  if (loggedInUserRole === "PR" && (is_redirect || campaign_id)) {
    return <CustomizeAds is_redirect={is_redirect} campaign_id={campaign_id} />;
  }

  return (
    <PageLayout className="thank-you-container mx-0 mx-sm-2">
      <div className="thank-you-page">{message}</div>
    </PageLayout>
  );
};

function MSActivationBriefThankYouMessage() {
  return (
    <div className="text-center">
      <Typography variant="h4" gutterBottom className="heading mb-5">
        Thank you for submitting your activation brief to get started with the
        Google Advertising Program.
      </Typography>
      <Typography gutterBottom className="mb-3">
        We will review your information and let you know if we have any
        questions or need additional information.
      </Typography>

      <Typography gutterBottom className="mb-3">
        We will provide you with your keyword and spend recommendation for
        approval within 5-7 business days, you will be notified via email when
        those are ready in platform for your review.
      </Typography>
      <Typography gutterBottom className="mb-3">
        If you have any questions about the process, you can reply to this email
        or reach out to a Morgan Stanley Marketing team member at
        localpaidsearch@morganstanley.com.
      </Typography>
      <Typography gutterBottom className="mb-3">
        To find additional information about the program and answers to
        Frequently Asked Questions, visit the program 3DR page.
      </Typography>

      <DashboardNavButton />
    </div>
  );
}

function DashboardNavButton({
  buttonText = "Back to My Dashboard",
  buttonLink = `${process.env.PUBLIC_URL}/dashboard`,
}) {
  return (
    <div className="d-flex-center">
      <Button
        component={Link}
        to={buttonLink}
        className="my-4"
        variant="contained"
        color="primary"
        size="large"
        startIcon={<Icon>laptop</Icon>}
        replace
      >
        {buttonText}
      </Button>
    </div>
  );
}

export default ThankYouPage;
