import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import ProfileBrandsGrid from "../../components/ProfileBrandsGrid";
import {
  isLoadingSelector,
  loggedInUserSelector,
  userSelector,
} from "../../selectors/userSelector";
import ProfileBrandLocations from "../../components/ProfileBrandLocations";
import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import NoDataFound from "../../components/NoDataFound";
import CheckCurrentPassword from "../auth/CheckCurrentPassword";
import { brandActions, userActions } from "../../actions";
import PageLayout from "../../components/layouts/page/PageLayout";
import RpMUIDialog from "../../components/modals/RpMUIModal";
import ProfileCard from "./ProfileCard";
import ProfileEdit from "./ProfileEdit";
import PageHeader from "../../components/layouts/pageHeaders/PageHeader";

const Profile = ({ loggedInUser, user, isLoading, getUser, getBrands }) => {
  const loader = useContext(LoaderContext);
  const { loggedInUserRole, selectedBrand, userBrands } =
    useContext(GlobalContext);

  const { email } = user || {};

  // update password states
  const [checkCurrentPass, setCheckCurrentPass] = useState(false);
  const [isCurrentPassValid, setIsCurrentPassValid] = useState(false);

  const [brandForLocation, setBrandForLocation] = useState(null);

  const [edit, setEdit] = useState(false);

  /**
   * @method handleChangePassModalClose
   * @description handle on close modal
   */
  const handleChangePassModalClose = () => {
    setCheckCurrentPass(false);
    setIsCurrentPassValid(false);
  };

  useEffect(() => {
    let getBrandsRequestData = {
      fk_participant_id: 0,
      fk_company_id: 0,
    };

    if (loggedInUserRole === "PR") {
      getBrandsRequestData.fk_participant_id = loggedInUser?.participantData
        ? loggedInUser?.participantData?.id
        : 0;
    } else if (loggedInUserRole === "PC") {
      getBrandsRequestData.fk_company_id = loggedInUser?.companyData
        ? loggedInUser?.companyData?.id
        : 0;
    } else {
      getBrandsRequestData.id = selectedBrand.id;
    }

    getBrands(getBrandsRequestData);
    getUser(loggedInUser);
  }, []);

  useEffect(() => {
    loader(isLoading);
  }, [isLoading, loader]);

  //fetch the brand for location only when the user is PR
  useEffect(() => {
    if (userBrands && userBrands?.length) {
      if (loggedInUserRole === "PR") {
        const b = userBrands.find((b) => b.id === selectedBrand.id);
        handleOnClickBrand(b);
      }
      if (loggedInUserRole !== "PR") {
        return;
      }
    }
  }, [userBrands, loggedInUserRole, selectedBrand]);

  const handleOnClickBrand = useCallback((brand) => {
    setBrandForLocation(brand);
  }, []);

  if (isLoading && !email) {
    return <NoDataFound message="Loading..." />;
  }

  return (
    <PageLayout className="profile-container mx-0 mx-sm-2">
      <PageHeader showName />

      <div className="profile participant-profile mt-4">
        <div className="row mb-4">
          <div
            className={`${loggedInUserRole === "PR" ? "col-xl-4 col-lg-4" : "col-lg-4 col-xl-3"}   mb-3 mb-lg-0`}
          >
            {edit ? (
              <ProfileEdit
                loggedInUser={loggedInUser}
                loggedInUserRole={loggedInUserRole}
                user={user}
                setCheckCurrentPass={setCheckCurrentPass}
                edit={edit}
                setEdit={setEdit}
                loader={loader}
                brand={brandForLocation}
              />
            ) : (
              <ProfileCard
                loggedInUser={loggedInUser}
                loggedInUserRole={loggedInUserRole}
                user={user}
                setCheckCurrentPass={setCheckCurrentPass}
                setEdit={setEdit}
                brand={brandForLocation}
                selectedBrand={selectedBrand}
              />
            )}
          </div>
          {loggedInUserRole !== "PR" && (
            <div className="col mb-2">
              <ProfileBrandsGrid
                onClickBrand={handleOnClickBrand}
                user={user}
              />
            </div>
          )}
          {loggedInUserRole === "PR" && (
            <div className="col-xl-8 col-lg-8">
              <ProfileBrandLocations
                brand={brandForLocation}
                loggedInUserRole={loggedInUserRole}
                selectedBrand={selectedBrand}
              />
            </div>
          )}
        </div>

        {/* <Notice
          message={
            loggedInUserRole === "PR" ? (
              <span>
                Have other locations with a different brand on Hyperlocology?
                You can easily link your other brand accounts to see everything
                in one place <LinkAccount />.
              </span>
            ) : null
          }
        /> */}
        {checkCurrentPass && (
          <RpMUIDialog
            open={checkCurrentPass}
            onClose={handleChangePassModalClose}
            heading={
              isCurrentPassValid ? "Update Password" : "Verify current password"
            }
            maxWidth="sm"
            fullWidth
          >
            <CheckCurrentPassword
              onCancel={handleChangePassModalClose}
              onChecked={(v) => setIsCurrentPassValid(v)}
              isCurrentPassValid={isCurrentPassValid}
              onUpdatePass={handleChangePassModalClose}
            />
          </RpMUIDialog>
        )}
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  loggedInUser: loggedInUserSelector(state),
  user: userSelector(state),
  isLoading: isLoadingSelector(state),
});

export default connect(mapStateToProps, {
  getUser: userActions.getUser,
  getBrands: brandActions.getBrands,
})(Profile);
