import React, { useState, useEffect, useContext } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";

import RpSteps from "./RpSteps";
import PackageSelection from "./packageSelection/PackageSelection";
import ConfirmPackageSelection from "./confirmPackageSelection/ConfirmPackageSelection";
import { subscriptionService } from "../../services/subscriptionService";
import { GlobalContext } from "../../helpers/contexts/contexts";
import NoDataFound from "../../components/NoDataFound";
import RpAlert from "../../components/alerts/RpAlert";
import { MUICustomAutocompleteSelect } from "../../components/muiCustom/select/MUICustomAutocompleteSelect";
import { Link } from "react-router-dom";
import MuiDarkButton from "../../components/muiCustom/buttons/MuiDarkButton";
import PageLayout from "../../components/layouts/page/PageLayout";
import { mergeArraysWithUniqueKey } from "../../helpers/utils";
import { logger } from "../../helpers/loggerHelper";
import MuiSearchInput from "../../components/muiCustom/search/MuiSearchInput";

const statusOptions = [
  { label: "Unsubscribed", value: 0 },
  { label: "Expired", value: 3 },
  { label: "Past Due", value: 4 },
];

const LOCATIONS_PAGE_SIZE = 10;

export default function LocationSubscription() {
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  const steps = ["Choose your plan", "Review your order", "Payment"];

  const [pageInfo, setPageInfo] = useState(null);

  const [packages, setPackages] = useState([]);

  const [locations, setLocations] = useState([]);

  const [selections, setSelections] = useState([]);

  const [activeStep, setActiveStep] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [filtering, setFiltering] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMoreLocations, setHasMoreLocations] = useState(true);
  const [page, setPage] = useState(1);
  const [locationFilters, setLocationFilters] = useState({
    searchKey: "",
    status: "",
  });

  const [totalLocationCount, setTotalLocationCount] = useState(0);

  async function fetchLocations(filtering = false) {
    if (filtering) {
      setFiltering(true);
      setLoadingMore(false);
    } else {
      setLoadingMore(true);
    }

    try {
      const res = await subscriptionService.getUserUnsubscribedLocations({
        userId: loggedInUser.id,
        brandId: selectedBrand.id,
        offset: page * LOCATIONS_PAGE_SIZE,
        limit: LOCATIONS_PAGE_SIZE,
        ...locationFilters,
      });

      if (res.status === 200) {
        const locationData = res.data?.locationData;
        const newLocations = locationData?.locations || [];
        const totalLocationCount = locationData?.totalLocationCount;

        setLocations((prevLocations) => {
          if (filtering) {
            return newLocations;
          }
          return mergeArraysWithUniqueKey(
            prevLocations,
            newLocations,
            "fk_location_id"
          );
        });

        setHasMoreLocations(
          (filtering ? 1 : page) * LOCATIONS_PAGE_SIZE < totalLocationCount
        );
        setTotalLocationCount(totalLocationCount);
      }
    } catch (err) {
      if (err?.__CANCEL__) {
        setTimeout(() => {
          if (filtering) {
            setFiltering(true);
          }
          setLoadingMore(false);
        }, 100);
      }
    } finally {
      setFiltering(false);
      setLoadingMore(false);
    }
  }

  async function fetchPackages() {
    setIsLoading(true);
    try {
      const [subnPageRes, res] = await Promise.all([
        subscriptionService.getSubscriptionPage(selectedBrand.id),
        subscriptionService.getPackagesNew(selectedBrand.id),
      ]);

      if (subnPageRes.status === 200) {
        setPageInfo(subnPageRes.data);
      }
      if (res.status === 200) {
        setPackages(res.data.packageData);
      }
    } catch (err) {
      logger.error("fetchPackages error", err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchPackages();
    fetchLocations();
  }, []);

  useEffect(() => {
    if (page > 1) {
      fetchLocations();
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    fetchLocations(true);
  }, [locationFilters.status]);

  useEffect(() => {
    if (
      locationFilters.searchKey.length >= 3 ||
      locationFilters.searchKey === ""
    ) {
      setPage(1);
      fetchLocations(true);
    }
  }, [locationFilters.searchKey]);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.scrollTo(0, 0);
    }
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((s) => (s < steps.length - 1 ? s + 1 : s));
  };
  const handlePrev = () => {
    setActiveStep((s) => (s > 0 ? s - 1 : s));
  };

  const handleOnPackageSelectionContinue = (data) => {
    const { locPackSelections } = data;

    setSelections(locPackSelections);
    handleNext();
  };

  const loadMoreLocations = () => {
    if (!hasMoreLocations) return null;
    return (
      <div className="mt-4 mb-5 text-center">
        <Button
          onClick={() => {
            setLoadingMore(true);
            setPage((prev) => prev + 1);
          }}
          type="button"
          className="mx-auto text-capitalize"
          color="secondary"
          size="small"
          variant="outlined"
          disabled={loadingMore}
        >
          {loadingMore ? "Loading..." : "Load More"}
        </Button>
      </div>
    );
  };

  const handleOnChangeFilters = (type) => (value) => {
    setLocationFilters((f) => ({ ...f, [type]: value }));
    setPage(1);
  };

  const renderLocationFilters = () => {
    return (
      <div className="location-filters mb-2">
        <div className="row">
          <div className="col-lg-2 mb-3 mb-lg-0 pr-lg-1">
            <MUICustomAutocompleteSelect
              label="Status"
              name="status"
              options={statusOptions}
              selectProps={{
                onChange: handleOnChangeFilters("status"),
                value: locationFilters.status,
              }}
              wrapperProps={{
                className: "px-auto",
              }}
              all
            />
          </div>
          <div className="col-lg-3 pr-lg-1 pl-lg-1">
            <MuiSearchInput
              name="searchKey"
              id="searchKey"
              value={locationFilters.searchKey}
              onChange={(value) => handleOnChangeFilters("searchKey")(value)}
            />
          </div>
          <div className="col-lg-3 d-flex align-items-center pt-1">
            <MuiDarkButton
              variant="contained"
              component={Link}
              to="/manage-subscription"
              fullWidth
            >
              Manage Active Plans
            </MuiDarkButton>
          </div>
        </div>
        <div style={{ height: 15 }}>
          <Typography variant="caption" color="primary">
            {filtering ? (
              <>
                <CircularProgress size={10} className="mr-2" />
                Loading..
              </>
            ) : (
              ""
            )}
          </Typography>
        </div>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <>
        {activeStep === 0 ? (
          <PackageSelection
            packages={packages}
            locations={locations}
            selections={selections}
            onContinue={handleOnPackageSelectionContinue}
            loadMoreLocations={loadMoreLocations}
            pageInfo={pageInfo}
            selectedBrand={selectedBrand}
            renderLocationFilters={renderLocationFilters}
            totalLocationCount={totalLocationCount}
          />
        ) : (
          <ConfirmPackageSelection
            selections={selections}
            onPrev={handlePrev}
            pageInfo={pageInfo}
            setActiveStep={setActiveStep}
          />
        )}
      </>
    );
  };

  const renderNoPageMessage = () => {
    return (
      <div className="mt-5 h-100">
        <NoDataFound
          message={
            <RpAlert severity="info">
              There are no subscription information available. Please contact to
              Admin
            </RpAlert>
          }
          height="70vh"
        />
      </div>
    );
  };

  return (
    <>
      <PageLayout className="location-subscription">
        <div className="m-0">
          <div className="rp-steps head">
            <RpSteps activeStep={activeStep} steps={steps} />
          </div>
        </div>
        {isLoading ? (
          <div className="mt-5 h-100">
            <NoDataFound message="Loading..." />
          </div>
        ) : (
          <>
            {/* <main className="pb-5"> */}
            {pageInfo ? renderPage() : renderNoPageMessage()}
            {/* </main> */}
          </>
        )}
      </PageLayout>
    </>
  );
}
