import React, { memo } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { getFormatDate } from "../../helpers/utils";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ImageRP from "../../components/ImageRP";
import TablePagination from "../../components/tables/TablePagination";

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    height: "100%",
  },
  image: {
    minHeight: "200px",
  },
  dateLine: {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      left: "0",
      bottom: "-2px",
      width: "32%",
      height: "2px",
      backgroundColor: "#04a999",
    },
  },
  title: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
  },
});

export default memo(function ArticleSection({
  articles,
  totalArticles,
  articlesPageIndex,
  articlesPageSize,
  handleArticlePageChange,
}) {
  const classes = useStyles();
  const totalCount = Math.ceil(totalArticles / articlesPageSize);
  return (
    <div>
      <section className="rc-section text-center my-4">
        <div className="row my-5 justify-content-center position-relative align-items-stretch">
          {articles &&
            articles.map((u) => {
              return (
                <div className="col-md-4 col-lg-3 mb-4" key={u.id}>
                  <Card className={`${classes.root} h-100`}>
                    <CardActionArea className={classes.image}>
                      <a
                        href={u.article_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="title_url"
                      >
                        <ImageRP
                          src={u.article_img}
                          type="IMG"
                          className="sg-image"
                        ></ImageRP>
                      </a>
                    </CardActionArea>
                    <CardContent>
                      <div className="d-flex justify-content-between">
                        <Typography
                          component="span"
                          className={`rc-date mt-3 ${classes.dateLine}`}
                        >
                          {getFormatDate(
                            u?.article_date ? u?.article_date : u?.createdAt
                          )}
                        </Typography>
                      </div>
                      <Typography
                        component="h5"
                        variant="body1"
                        className={`rc-title pt-3 ${classes.title}`}
                      >
                        <a
                          href={u.article_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="title_url"
                        >
                          {u.article_title}
                        </a>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
        </div>
        <div className="row d-flex row d-flex justify-content-center paging">
          <TablePagination
            count={totalCount}
            page={articlesPageIndex}
            onChange={handleArticlePageChange}
            variant="outlined"
            shape="rounded"
            className="float-right"
          />
        </div>
      </section>
    </div>
  );
});
