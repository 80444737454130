import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";

import BoostSettingTable from "./BoostSettingTable";
import { campaignService } from "../../../services";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import { logger } from "../../../helpers/loggerHelper";
import ConfirmBoostCampaign from "./confirmBoostCampaign/ConfirmBoostCampaign";
import BackLink from "../../../components/BackLink";
import RpAlert from "../../../components/alerts/RpAlert";

function BoostCampaign({ campaignId }) {
  const { loggedInUser } = useContext(GlobalContext);

  const [campaignData, setCampaignData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selections, setSelections] = useState(null);
  const [step, setStep] = useState(1);

  const fetchCampaignDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const reqData = {
        fk_campaign_id: campaignId,
        fk_participant_id: loggedInUser?.participantData?.id || "",
      };
      const res = await campaignService.getAllCampaignBoostLocations(reqData);
      if (res.status === 200) {
        const data = res.data;
        setCampaignData(data);
      }
    } catch (err) {
      logger.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [campaignId, loggedInUser]);

  useEffect(() => {
    fetchCampaignDetails();
  }, [fetchCampaignDetails]);

  const campaignHasOnlyPMaxChannel = useMemo(() => {
    if (!campaignData?.channelNames || campaignData?.channelNames?.length > 1)
      return false;
    return campaignData?.channelNames?.some(
      (c) => c?.label?.toLowerCase() === "pmax"
    );
  }, [campaignData?.channelNames]);

  const handleOnContinue = useCallback((data) => {
    logger.info("\n____Selected data", data);
    setSelections(data?.reverse());
    setStep(2);
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex-center">
        <Typography>Loading...</Typography>
      </div>
    );
  }

  if (campaignData && !campaignData?.is_campaign_boost) {
    return <Redirect to={`/dashboard`} replace={true} />;
  }

  return (
    <>
      <div className="boost-campaign">
        <div className={step !== 1 ? "d-none" : ""}>
          <BackLink />
          <Typography
            component="h1"
            variant="h5"
            gutterBottom
            className="mt-2 boost-page-title"
          >
            {`Boost the ${campaignData?.campaign_name} Campaign`}
          </Typography>
          <Typography gutterBottom>
            Boosting, or adding more money to an active ad campaign, is a great
            way to amplify results! It's recommended to boost "all" channels to
            ensure optimal results. However, you may choose to boost only
            selected channels using the drop down selector.
          </Typography>
        </div>

        <div className={step !== 2 ? "d-none" : "mb-5"}>
          <Typography component="h1" variant="h5" color="primary" gutterBottom>
            Confirmation
          </Typography>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            Review your selections and press confirm if it looks good
          </Typography>
        </div>

        {campaignData?.locationData?.length ? (
          <BoostSettingTable
            locationData={campaignData?.locationData}
            is_selected_boost_type={campaignData?.is_selected_boost_type}
            boost_payment_method={campaignData?.boost_payment_method}
            boosted_duration={campaignData?.boosted_duration}
            channelsList={campaignData?.channelNames}
            campaignId={campaignData?.id}
            onContinue={handleOnContinue}
            selections={selections}
            hide={step !== 1}
          />
        ) : (
          <div className="d-flex-center w-100 py-5">
            <RpAlert severity="info">
              <Typography variant="caption">
                No eligible locations found for this campaign to boost.
              </Typography>
            </RpAlert>
          </div>
        )}

        {step === 2 && (
          <ConfirmBoostCampaign
            selections={selections}
            campaign={campaignData}
            onMakeChange={() => setStep(1)}
            campaignHasOnlyPMaxChannel={campaignHasOnlyPMaxChannel}
          />
        )}
      </div>
    </>
  );
}

export default BoostCampaign;
