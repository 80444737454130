import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const RpPreventNavigation = ({ prevent = false }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (prevent) {
      // Prevent Back Button
      const unblock = history.block((location, action) => {
        if (action === "POP") {
          // alert("Back button is disabled on the payment page");
          return false; // Prevent navigation
        }
        return true;
      });

      return () => {
        unblock(); // Cleanup when leaving the page
      };
    }
  }, [history, location, prevent]);

  return null;
};

export default RpPreventNavigation;
