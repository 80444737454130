import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound";
import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import { brandService } from "../../services/brandService";
import { subscriptionService } from "../../services/subscriptionService";
import CurrentAvailableCampaignCard from "./CurrentAvailableCampaignCard";
import SubscriptionPlan from "./SubscriptionPlan";
import WhatYouGetBySubscribe from "./WhatYouGetBySubscribe";
import { logger } from "../../helpers/loggerHelper";

const SelfSubscription = () => {
  const { loggedInUser } = useContext(GlobalContext);
  const history = useHistory();
  const { slug } = useParams();

  const loader = useContext(LoaderContext);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [brandData, setBrandData] = useState(null);

  const fetchPackages = async () => {
    setLoading(true);
    try {
      const brandRes = await brandService.getBrandBySlug(slug);
      if (brandRes.status === 200) {
        const brand = brandRes.data;
        setBrandData(brand);
        const res = await subscriptionService.getPackages(brand.id);
        if (res.status === 200) {
          const data = res?.data?.packages;
          if (data && data.length) {
            setPackages(res.data.packages);
          }
        }
      }
    } catch (err) {
      logger.error("Error in fetchPackages", { err });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  useEffect(() => {
    loader(loading);
  }, [loading]);

  if (loading) {
    return <NoDataFound message="Loading" />;
  } else if (!loading && !brandData) {
    return <NoDataFound message="Coming Soon.." height="50vh" />;
  }

  const renderSubscriptionPackages = () => {
    if (!loading && packages.length === 0) {
      return <NoDataFound message="No Plan found for this brand" />;
    }
    return (
      <>
        <h2 className="secondary-heading">
          Choose the plan that works for you
        </h2>
        <div className="plans">
          <div className="row justify-content-center align-items-stretch">
            {packages.map((p) => (
              <div className="col-md-5 my-3" key={p.id}>
                <SubscriptionPlan
                  planData={p}
                  brandData={brandData}
                  history={history}
                  loggedInUser={loggedInUser}
                />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="self-subscription-page">
      <section className="top-section">
        <div className="container">
          <h1 className="main-heading">{`Welcome, ${brandData.brand_name}!`}</h1>
          {renderSubscriptionPackages()}
        </div>
      </section>
      <section className="bottom-section">
        <WhatYouGetBySubscribe />
        <div className="container contact-us my-5 py-5 text-center">
          <h2 className="main-heading text-center">Questions? Need Help?</h2>
          <p className="contact-email my-4">
            Email us at{" "}
            <a href="mailto:JINYARamenBar@hyperlocology.com">
              JINYARamenBar@hyperlocology.com
            </a>
          </p>
        </div>
        <div className="container current-campaigns-available">
          <h2 className="main-heading text-left">
            Current campaigns available for you with subscription:
          </h2>
          <div className="row my-5">
            <div className="col-md-6 my-3">
              <CurrentAvailableCampaignCard
                campaignName="Lunch Campaign"
                img={`${process.env.PUBLIC_URL}/img/subscription/lunch-campaign.png`}
                date="June 15th, 2021 - July 31st, 2021"
              />
            </div>
            <div className="col-md-6 my-3">
              <CurrentAvailableCampaignCard
                campaignName="Dinner Campaign"
                img={`${process.env.PUBLIC_URL}/img/subscription/dinner-campaign.png`}
                date="June 15th, 2021 - July 31st, 2021"
              />
            </div>
            <div className="col-md-6 my-3">
              <CurrentAvailableCampaignCard
                campaignName="Hiring Campaign"
                img={`${process.env.PUBLIC_URL}/img/subscription/hiring-campaign.png`}
                date="June 15th, 2021 - July 31st, 2021"
              />
            </div>
            <div className="col-md-6 my-3">
              <CurrentAvailableCampaignCard
                campaignName="Beer, Cocktails and Spirits Campaign"
                img={`${process.env.PUBLIC_URL}/img/subscription/beer-cocktail-campaign.png`}
                date="June 15th, 2021 - July 31st, 2021"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SelfSubscription;
