import AxiosInstance from "../config/AxiosInstance";
import { API_BASE_URL } from "../config/appConfig";

export const locationService = {
  getLocationDetails,
};

function getLocationDetails(id) {
  return AxiosInstance.get(`${API_BASE_URL}/getLocationDetails/${id}`);
}
