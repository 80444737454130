import React from "react";
import { Checkbox, Link as MUILink } from "@material-ui/core";

const TermsAndConditions = ({ isChecked, handleTermsAndCondition }) => {
  return (
    <div className="row mt-3">
      <div className="col-12 d-flex justify-content-end">
        <MUILink
          variant="body2"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hyperlocology.com/user-terms-and-conditions"
          className="mr-1 p-1 text-underline"
        >
          Accept terms and conditions
        </MUILink>
        <Checkbox
          edge="start"
          color="primary"
          name="termsAndConditions"
          className="py-0"
          checked={isChecked}
          onChange={handleTermsAndCondition}
        />
      </div>
    </div>
  );
};

export default TermsAndConditions;
