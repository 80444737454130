import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { createStyles } from "@material-ui/styles";
import { IconButton, withStyles } from "@material-ui/core";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const CustomElements = ({
  classes,
  onChange,
  initialVal,
  disableWeekSelection,
  isEvergreenCampaign,
}) => {
  const [selectedDate, setSelectedDate] = useState(moment().startOf("isoWeek"));

  useEffect(() => {
    if (initialVal) {
      const m = moment(initialVal);
      if (m.isValid()) {
        setSelectedDate(m.startOf("isoWeek"));
      }
    }
  }, [initialVal]);

  const handleWeekChange = (date) => {
    // setSelectedDate(date.startOf("isoWeek"));
    // if (onChange && typeof onChange === "function") {
    //   onChange(date.startOf("isoWeek"));
    // }
    setSelectedDate(date);
    if (onChange && typeof onChange === "function") {
      onChange(date);
    }
  };

  const formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = date;

    return dateClone && dateClone.isValid()
      ? `${dateClone.startOf("isoWeek").format("MM/DD/YYYY")} - ${dateClone
          .startOf("isoWeek")
          .add(4, "days")
          .format("MM/DD/YYYY")}`
      : invalidLabel;
  };

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    let dateClone = date.clone();
    let selectedDateClone = selectedDate.clone();

    const start = selectedDateClone.startOf("isoWeek").toDate();
    // const end = selectedDateClone.endOf("week").toDate();
    const end = moment(start).add(4, "days").toDate();

    const dayIsBetween = dateClone.isBetween(start, end, null, []);
    const isFirstDay = dateClone.isSame(start, "day");
    const isLastDay = dateClone.isSame(end, "day");

    const isWeekEnd =
      date.toDate().getDay() === 6 || date.toDate().getDay() === 0;

    const isPastWeekDay = isEvergreenCampaign
      ? moment(date).isBefore(moment().add(7, "week").startOf("isoWeek"))
      : false;

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]:
        !dayInCurrentMonth || isWeekEnd || isPastWeekDay,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div>
        <div className={wrapperClassName}>
          <IconButton className={dayClassName}>
            <span>{dateClone.format("DD")}</span>
          </IconButton>
        </div>
      </div>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        value={selectedDate}
        onChange={handleWeekChange}
        renderDay={renderWrappedWeekDay}
        labelFunc={formatWeekSelectLabel}
        disableToolbar
        variant="inline"
        format="MM/DD/YYYY"
        disabled={disableWeekSelection}
        minDate={
          isEvergreenCampaign && moment().add(7, "weeks").startOf("isoWeek")
        }
        // shouldDisableDate={(d) => {
        //   const dayOfWeek = moment(d).toDate().getDay();
        //   if (dayOfWeek === 6 || dayOfWeek === 0) return true;
        //   return moment(d).isBefore(moment().add(7, "week").startOf("isoWeek"));
        // }}
      />
    </MuiPickersUtilsProvider>
  );
};

const styles = createStyles((theme) => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  disabled: {
    // color: "#f2f2f2",
    color: "yellow",
  },
}));

export default withStyles(styles)(CustomElements);
