import React, { useState, useEffect } from "react";
import { formateKeyName, getFormattedData } from "../../helpers/utils";
import CreativeImagePopup from "../modals/CreativeImagePopup";
import StatusChip from "../muiCustom/chip/StatusChip";
import TablePagination from "../tables/TablePagination";

const TableChart = ({ data = [], headings = [], rows = 6, formats = null }) => {
  const [chartData, setChartData] = useState([]);

  // pagination
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = rows;
  const getPageDataIndex = () => {
    const from = (currentPage - 1) * pageSize;
    const to = from + pageSize;
    return { from, to };
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const total = Math.ceil(data.length / pageSize);
    setTotalPage(total);

    setChartData(data.slice(getPageDataIndex().from, getPageDataIndex().to));
  }, [data]);

  useEffect(() => {
    setChartData(data.slice(getPageDataIndex().from, getPageDataIndex().to));
  }, [currentPage]);

  const styles = {
    thead: {
      height: 35,
    },
  };

  const renderStatus = (status) => {
    const lowerStatus = status?.toLowerCase();

    if (lowerStatus === "scheduled") {
      return (
        <StatusChip
          label="Scheduled"
          status="warn"
          className="location-status"
        />
      );
    } else if (lowerStatus === "delivered") {
      return (
        <StatusChip
          label="Delivered"
          status="arStatus"
          className="location-status"
        />
      );
    }
  };

  return (
    <div className="chart table-cart">
      <div className="table-responsive">
        <table className="table table-borderless table-sm">
          <thead style={styles.thead}>
            <tr className="heading">
              {headings.map((h, i) => (
                <th key={i}>
                  <span className="upper">{formateKeyName(h)}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {chartData.length
              ? chartData.map((d, i) => (
                  <tr key={i}>
                    {headings.map((heading) => (
                      <td key={heading}>
                        {(heading === "creative_package" ||
                          heading === "creative_name" ||
                          heading === "hour") &&
                        d?.creative_image ? (
                          <CreativeImagePopup
                            src={d["creative_image"]}
                            srcTwo={d["creative_image_two"]}
                            heading={getFormattedData(
                              d[heading],
                              heading,
                              formats
                            )}
                          >
                            <span className="link">
                              {getFormattedData(d[heading], heading, formats)}
                            </span>
                          </CreativeImagePopup>
                        ) : heading === "STATUS" || heading === "status" ? (
                          renderStatus(
                            getFormattedData(d[heading], heading, formats)
                          )
                        ) : (
                          <span>
                            {getFormattedData(d[heading], heading, formats)}
                          </span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
      <div className="container-fluid">
        <div className="row mt-1 align-items-center">
          {/* <div className="col-lg-6 mb-1 mb-lg-0">
            <DataRangeDisplay
              pageIndex={currentPage - 1}
              totalRecords={data?.length || 0}
              pageSize={pageSize}
            />
          </div> */}
          <div className="col-lg float-right">
            <TablePagination
              page={currentPage}
              count={totalPage}
              onChange={handlePageChange}
              className="float-right"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableChart;
