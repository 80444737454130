import moment from "moment";

export const campaignReportHelper = {
  getInitialCustomMonthRange,
  formatCustomRangeToSend,
  getInitialCustomDateRange,
};

function formatCustomRangeToSend(range) {
  const { from, to } = range;
  return {
    from: `${from.year}/${from.month}/01`,
    to: `${to.year}/${to.month}/01`,
  };
}

function getInitialCustomDateRange(campaign) {
  const endMonth = moment().startOf("month");
  const startMonth = moment(endMonth).subtract(2, "month");
  let from = {
    year: startMonth.format("YYYY"),
    month: startMonth.format("MM"),
    date: startMonth.format("DD"),
  };
  let to = {
    year: endMonth.format("YYYY"),
    month: endMonth.format("MM"),
    date: endMonth.format("DD"),
  };

  if (!campaign) {
    return {
      from: {
        year: parseInt(from.year),
        month: parseInt(from.month),
        date: parseInt(from.date),
      },
      to: {
        year: parseInt(to.year),
        month: parseInt(to.month),
        date: parseInt(to.date),
      },
    };
  }

  const { reportingPeriod } = campaign;

  if (reportingPeriod) {
    from = reportingPeriod?.from;
    to = reportingPeriod?.to;
  }

  // if (campaign_type === "Normal") {
  //   from = {
  //     year: moment(start_date).format("YYYY"),
  //     month: moment(start_date).format("MM"),
  //   };
  //   to = {
  //     year: moment(end_date).format("YYYY"),
  //     month: moment(end_date).format("MM"),
  //   };
  //   if (reportingPeriod) {
  //     from = reportingPeriod?.from;
  //     to = reportingPeriod?.to;
  //   }
  // } else if (campaign_type === "Evergreen") {
  //   from = {
  //     year: moment().subtract(1, "year").format("YYYY"),
  //     month: moment().subtract(1, "year").format("MM"),
  //   };
  //   to = {
  //     year: moment().format("YYYY"),
  //     month: moment().format("MM"),
  //   };
  //   if (reportingPeriod) {
  //     from = reportingPeriod?.from;
  //     to = reportingPeriod?.to;
  //   }
  // }
  return {
    from: {
      year: parseInt(from.year),
      month: parseInt(from.month),
      date: parseInt(from.date),
    },
    to: {
      year: parseInt(to.year),
      month: parseInt(to.month),
      date: parseInt(to.date),
    },
  };
}

function getInitialCustomMonthRange(campaign) {
  const endMonth = moment().startOf("month");
  const startMonth = moment(endMonth).subtract(2, "month");
  let from = {
    year: startMonth.format("YYYY"),
    month: startMonth.format("MM"),
    date: startMonth.format("DD"),
  };
  let to = {
    year: endMonth.format("YYYY"),
    month: endMonth.format("MM"),
    date: endMonth.format("DD"),
  };

  if (!campaign) {
    return {
      from: {
        year: parseInt(from.year),
        month: parseInt(from.month),
        date: parseInt(from.date),
      },
      to: {
        year: parseInt(to.year),
        month: parseInt(to.month),
        date: parseInt(to.date),
      },
    };
  }

  const { reportingPeriod } = campaign;

  if (reportingPeriod) {
    from = reportingPeriod?.from;
    to = reportingPeriod?.to;
  }

  return {
    from: {
      year: parseInt(from.year),
      month: parseInt(from.month),
      date: parseInt(from.date),
    },
    to: {
      year: parseInt(to.year),
      month: parseInt(to.month),
      date: parseInt(to.date),
    },
  };
}
