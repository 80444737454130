import React from "react";
import { canJoinCampaign } from "../../helpers/utils";
import { Button } from "@material-ui/core";

export default function ArchivedJoinNowButton({
  campaign,
  removeCampaignFromArchive,
}) {
  const { status, budget_type } = campaign?.campaign || {};

  if (!canJoinCampaign(campaign?.campaign)) return null;

  const customizeOrJoinText = () => {
    // return enable_payment_through_portal === 1 ? "Join Now" : "Customize Now"
    if (budget_type === "Location Specific") {
      return "Join Now";
    } else {
      return "Customize Now";
    }
  };

  return (
    // <button
    //   className={`button primary thin w-sm-100 mw-100 mx-auto fs-12 ${
    //     status === 3 ? "disabled-link" : ""
    //   }`}
    //   onClick={() => removeCampaignFromArchive(campaign, campaign?.archive_id)}
    // >
    //   {budget_type === "Location Specific" ? "Join Now" : "Customize Now"}
    // </button>
    <Button
      variant="contained"
      color="primary"
      onClick={() =>
        removeCampaignFromArchive(campaign?.campaign, campaign?.id)
      }
      disableElevation
      className={`fs-11 ${status === 3 ? "disabled-link" : ""}`}
      fullWidth
      disabled={status === 3}
    >
      {customizeOrJoinText()}
    </Button>
  );
}
