import React, { memo, useContext } from "react";
import { Button } from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import { RpConfirmDialog } from "../../helpers/utils";
import { campaignService } from "../../services/campaignService";
import RpConfirm from "../RpConfirm";
import { logger } from "../../helpers/loggerHelper";

const NotInterestedButton = function ({ campaignId = "", disabled = false }) {
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const history = useHistory();

  const handleNotInterest = () => {
    if (!campaignId) return;

    async function moveToArchie() {
      loader(true);
      const data = {
        fk_campaign_id: campaignId,
        fk_participant_id: loggedInUser.participantData?.id,
        fk_brand_id: selectedBrand.id,
      };
      try {
        const res = await campaignService.addCampaignToArchive(data);

        if (res.status === 200) {
          toastr.success("Success", "Campaign archived successfully");
          history.replace(`${process.env.PUBLIC_URL}/dashboard`);
        }
      } catch (err) {
        logger.error("moveToArchie error", err);
      } finally {
        loader(false);
      }
    }

    RpConfirmDialog(
      <RpConfirm message="You want to archive this campaign?" />,
      () => {
        moveToArchie();
      }
    );
  };

  return (
    <Button
      color="primary"
      size="large"
      disabled={disabled}
      aria-disabled={disabled}
      onClick={handleNotInterest}
      type="button"
    >
      Not Interested
    </Button>
  );
};

export default memo(NotInterestedButton);
