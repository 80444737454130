import React, { useContext, useState, useEffect, memo } from "react";

import { GlobalContext } from "../helpers/contexts/contexts";
import { getFormatDate } from "../helpers/utils";
import ImageRP from "./ImageRP";
import StatusChip from "./muiCustom/chip/StatusChip";
import RpImageTextInline from "./RpImageTextInline";

const ProfileBrandsGrid = ({ onClickBrand, user }) => {
  const { selectedBrand, loggedInUserRole, userBrands } =
    useContext(GlobalContext);
  const [brands, setBrands] = useState([]);

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (userBrands) {
      setBrands(userBrands);
    }
  }, [userBrands]);

  useEffect(() => {
    if (brands.length) {
      if (loggedInUserRole === "PR") {
        const b = brands.find((b) => b.id === selectedBrand.id);
        setSelected(b);
        if (onClickBrand) {
          onClickBrand(b);
        }
      }
    }
  }, [brands, loggedInUserRole, selectedBrand, onClickBrand]);

  const handleOnBrandClick = (brand) => {
    if (loggedInUserRole !== "PR") {
      return;
    }
    setSelected(brand);
    if (onClickBrand) {
      onClickBrand(brand);
    }
  };

  const BrandCard = ({ brand }) => {
    const {
      subcription_details,
      subcription_end_date,
      subcription_start_date,
      subcription_status,
    } = brand;

    const participantView = (
      <div className="row">
        <div
          className="col text-center"
          onClick={() => handleOnBrandClick(brand)}
        >
          <div className="d-flex justify-content-center">
            <div className="brand-img">
              <ImageRP
                src={brand?.brand_logo}
                type="BRAND"
                className="img-fluid"
              />
            </div>
          </div>
          <h3 className="brand-name my-3">{brand?.brand_name}</h3>
        </div>
      </div>
    );

    return (
      <div
        className={`card brand mb-2 ${
          selected?.id === brand?.id ? "shadow" : ""
        }`}
      >
        <div className="card-body">
          {loggedInUserRole === "PC" || loggedInUserRole === "BO" ? (
            <div className="row">
              <div className="col-md-6 mb-3 mb-md-0">
                <div className="d-flex-center">
                  <div className="brand-img">
                    <ImageRP
                      src={brand?.brand_logo}
                      type="BRAND"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <h3 className="brand-name text-center my-2">
                  {brand?.brand_name}
                </h3>

                <div className="subscription-status text-center">
                  {subcription_status ? (
                    <StatusChip
                      label="Subscribed"
                      status="success"
                      size="medium"
                    />
                  ) : (
                    <StatusChip
                      label="Unsubscribed"
                      status="error"
                      size="medium"
                    />
                  )}
                </div>

                <div className="subscription-dates">
                  <RpImageTextInline
                    image="/img/icons/calendar.png"
                    text={
                      <>
                        <div className="title">Subscription Start</div>
                        <div className="value">
                          {subcription_start_date
                            ? getFormatDate(
                                subcription_start_date,
                                "MMM DD, YYYY"
                              )
                            : "---"}
                        </div>
                      </>
                    }
                    alignCenter={false}
                  />

                  <RpImageTextInline
                    image="/img/icons/calendar.png"
                    text={
                      <>
                        <div className="title">Subscription End</div>
                        <div className="value">
                          {subcription_end_date
                            ? getFormatDate(
                                subcription_end_date,
                                "MMM DD, YYYY"
                              )
                            : "---"}
                        </div>
                      </>
                    }
                    alignCenter={false}
                  />
                </div>
              </div>
              <div className="col-md-6">
                {subcription_details && (
                  <div
                    className="justify-content-md-center subscription-detail"
                    dangerouslySetInnerHTML={{ __html: subcription_details }}
                  ></div>
                )}
              </div>
            </div>
          ) : (
            participantView
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="card user-brands">
      <div className="card-header">
        <h2 className="heading">Your Profile Brand</h2>
        {loggedInUserRole === "PC" && (
          <div className="logo">
            <ImageRP
              className="brand-logo"
              src={user?.logo_name}
              type={loggedInUserRole === "PC" ? "COMPANY" : "BRAND"}
            />
          </div>
        )}
      </div>
      <div className="card-body">
        <div className="brands">
          {brands.map((brand) => (
            <BrandCard brand={brand} key={brand.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(ProfileBrandsGrid);
