import React, { memo, useMemo } from "react";
import { Icon } from "@material-ui/core";

import { joinCampaignHelper } from "../../helpers/joinCampaignHelper";
import { getFormatDate } from "../../helpers/utils";
import { CurrencyFormateText } from "../CurrencyFormat";
import RpImageTextInline from "../RpImageTextInline";

export default memo(function ConfirmJoinCampaignCard({ location, campaign }) {
  const { creativeData, locationName, start_date, end_date } = location;

  const withBrandContributionAmount = useMemo(() => {
    const { budget, brand_contribution_amount } = location;

    return (
      parseFloat(budget) +
      joinCampaignHelper.getBrandContributionAmount({
        amount: parseFloat(budget),
        brand_contribution: campaign?.brand_contribution,
        contribution_percentage: campaign?.contribution_percentage,
        contribution_max_limit: campaign?.contribution_max_limit,
        brand_contribution_type: campaign?.brand_contribution_type,
        brand_match_preset_amount: brand_contribution_amount * 1,
      })
    );
  }, [location, campaign]);

  return (
    <div className="card h-100">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-3 mb-2">
            <RpImageTextInline
              alignCenter={false}
              image="/img/icons/location.png"
              text={
                <>
                  <div className="title d-none d-lg-block">Location</div>
                  <div className="title-val">{locationName}</div>
                </>
              }
            />
          </div>
          <div className="col-6 col-lg-2 mb-2">
            <RpImageTextInline
              alignCenter={false}
              image="/img/icons/calendar.png"
              text={
                <>
                  <div className="title">Start Date</div>
                  <div className="title-val">
                    {getFormatDate(start_date, "MMM Do, YYYY")}
                  </div>
                </>
              }
            />
          </div>
          <div className="col-6 col-lg-2 mb-2">
            <RpImageTextInline
              alignCenter={false}
              image="/img/icons/calendar.png"
              text={
                <>
                  <div className="title">End Date</div>
                  <div className="title-val">
                    {getFormatDate(end_date, "MMM Do, YYYY")}
                  </div>
                </>
              }
            />
          </div>
          <div className="col-6 col-lg-3 mb-2">
            <RpImageTextInline
              alignCenter={false}
              image="/img/icons/calendar.png"
              text={
                <>
                  <div className="title">Selected Offer</div>
                  <div className="title-val">
                    {/* {creativeData.map((c) => c.creative_name).join(", ")} */}
                    <ul className="pl-0 mb-0 list-s-none">
                      {creativeData.map((c, i) => (
                        <li key={i}>{c.creative_name}</li>
                      ))}
                    </ul>
                  </div>
                </>
              }
            />
          </div>
          <div className="col-6 col-lg-2 mb-2 text-lg-right d-lg-none">
            <RpImageTextInline
              alignCenter={false}
              image="/img/icons/money.png"
              text={
                <>
                  <div className="title">Budget</div>
                  <div className="title-val">
                    <CurrencyFormateText
                      value={withBrandContributionAmount}
                      suffix=" USD"
                    />
                  </div>
                </>
              }
            />
          </div>
          <div className="col-6 col-lg-2 mb-2 text-lg-right d-none d-lg-block">
            <div className="title">
              <Icon
                component="img"
                src="/img/icons/money.png"
                fontSize="small"
                className="mr-1"
              />
              Budget
            </div>
            <div className="title-val">
              <CurrencyFormateText
                value={withBrandContributionAmount}
                suffix=" USD"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
