import { takeEvery, call, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import {
  userConstants,
  statusCodeConstants,
  brandConstants,
} from "../../config/constants";
import { userService } from "../../services";
import MESSAGE from "../../helpers/messages";

function* getUser(action) {
  try {
    const res = yield call(userService.getUser, action.payload);
    if (res.status === statusCodeConstants.OK && res.data) {
      let userData = {};
      if (res.data.rows[0]) {
        userData = { ...res.data.rows[0] };
        if (userData.id) {
          // not update id in local storage
          delete userData.id;
        }
      }

      yield put({
        type: brandConstants.GET_BRANDS_SUCCESS,
        payload: userData.brandData,
      });

      yield put({
        type: userConstants.GET_USER_SUCCESS,
        payload: userData,
      });
    }
  } catch (err) {
    yield put({
      type: userConstants.GET_USER_FAIL,
      payload: err,
    });
  }
}

function* updateUser(action) {
  try {
    const res = yield call(userService.updateUser, action.payload);
    if (res.status === statusCodeConstants.OK) {
      getUser(action);
      yield put({
        type: userConstants.UPDATE_USER_SUCCESS,
        payload: res.data,
      });
      toastr.success("Success", MESSAGE.updateSuccess("User"));
    }
  } catch (err) {
    yield put({
      type: userConstants.UPDATE_USER_FAIL,
      payload: err,
    });
  }
}

/**
 * @method userSaga
 * @description watch for action and act
 */
export default function* userSaga() {
  yield takeEvery(userConstants.GET_USER_REQUEST, getUser);
  yield takeEvery(userConstants.UPDATE_USER_REQUEST, updateUser);
}
