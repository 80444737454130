import React from "react";
import NumberFormat from "react-number-format";

export const CurrencyFormateText = ({
  value,
  renderText,
  prefix = "$",
  suffix = "",
  decimalScale = 2,
  fixedDecimalScale = true,
}) => {
  if (value === undefined || value === null || value === "" || isNaN(value)) {
    return null;
  }
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      prefix={prefix}
      suffix={suffix}
      renderText={
        renderText
          ? renderText
          : (price) => <span className="currency">{price}</span>
      }
    />
  );
};
