import React, { memo, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid, Paper } from "@material-ui/core";
import BoostLocationSetting from "./BoostLocationSetting";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import RpAlert from "../../../components/alerts/RpAlert";
import BoostHeaderTooltip from "./BoostHeaderTooltip";
import useSearchFilter from "../../../helpers/hooks/useSearchFilter";
import MuiSearchInput from "../../../components/muiCustom/search/MuiSearchInput";
import BoostContinueError from "./BoostContinueError";
import { CHANNELS } from "../../../config/constants";

const LOC_INIT_STATE = {
  locationId: "",
  start_date: "", // Ensure empty strings, not null
  end_date: "", // Ensure empty strings, not null
  boost_budget: "",
  current_budget: "",
  channels: [],
};

// Yup schema validation
const validationSchema = ({ is_selected_boost_type }) =>
  Yup.object({
    selectedLoc: Yup.object()
      .test("min-one-data-required", "Please select location(s)", (value) => {
        if (!value) return false;
        return Object.values(value).some((l) => l.locationId);
      })
      .test("validate-selected-locations", null, (value, context) => {
        if (!value) return true;

        const errors = [];

        Object.entries(value).forEach(([locationId, loc]) => {
          // Only validate if location is selected
          if (loc.locationId && loc.locationId !== "") {
            // Validate channels if boost type is selected
            if (is_selected_boost_type && !loc?.channels?.length) {
              errors.push(
                context.createError({
                  path: `selectedLoc.${locationId}.channels`,
                  message: "Please select at least one channel",
                })
              );
            }

            // Validate start_date
            if (!loc.start_date) {
              errors.push(
                context.createError({
                  path: `selectedLoc.${locationId}.start_date`,
                  message: "Start date is required",
                })
              );
            }

            // Validate end_date
            if (!loc.end_date) {
              errors.push(
                context.createError({
                  path: `selectedLoc.${locationId}.end_date`,
                  message: "End date is required",
                })
              );
            }

            // Validate boost_budget
            if (!loc.boost_budget) {
              errors.push(
                context.createError({
                  path: `selectedLoc.${locationId}.boost_budget`,
                  message: "Boost budget is required",
                })
              );
            } else if (loc.boost_budget) {
              const budgetNum = Number(loc.boost_budget);

              // Check if it's a valid number and greater than 0
              if (
                isNaN(budgetNum) ||
                budgetNum <= 0 ||
                !Number.isInteger(budgetNum) ||
                budgetNum > 100000
              ) {
                errors.push(
                  context.createError({
                    path: `selectedLoc.${locationId}.boost_budget`,
                    message:
                      "Boost budget must be a whole number not more than $100,000",
                  })
                );
              }
              // Only check minimum budget if both dates are selected
              else if (loc.start_date && loc.end_date) {
                const diff = moment(loc.end_date)
                  .add(1, "day")
                  .diff(moment(loc.start_date), "months", true);
                const rounded = Math.ceil(diff);
                const minBudget = Number(rounded) * 100;

                if (budgetNum < minBudget) {
                  errors.push(
                    context.createError({
                      path: `selectedLoc.${locationId}.boost_budget`,
                      message: `Boost Budget cannot be less than $${Number(rounded) * 100} for the selected period`,
                    })
                  );
                }
              }
            }

            // Validate budget should not be entered before dates
            if (loc.boost_budget && (!loc.start_date || !loc.end_date)) {
              errors.push(
                context.createError({
                  path: `selectedLoc.${locationId}.boost_budget`,
                  message: "Please select dates first",
                })
              );
            }
          }
        });

        if (errors.length > 0) {
          throw new Yup.ValidationError(errors);
        }

        return true;
      }),
  });

function BoostSettingTable({
  locationData,
  channelsList,
  is_selected_boost_type,
  boosted_duration,
  campaignId,
  onContinue,
  hide = false,
}) {
  const history = useHistory();

  const [searchKey, handleSearchChange, filteredLocations] = useSearchFilter(
    locationData,
    "location_name"
  );

  const initialValue = useMemo(() => {
    // Create an object with location IDs as keys for easier lookup
    const selectedLoc = {};
    locationData?.forEach((loc) => {
      selectedLoc[loc.fk_location_id] = { ...LOC_INIT_STATE };
    });

    return {
      select_all: false,
      selectedLoc,
    };
  }, [locationData]);

  const campaignHasOnlyPMaxChannel = useMemo(() => {
    if (!channelsList || channelsList?.length > 1) return false;
    return channelsList?.some(
      (c) =>
        c?.id === CHANNELS.PMAX.id ||
        c?.label?.toLowerCase() === CHANNELS.PMAX.lowerCaseName
    );
  }, [channelsList]);

  const campaignHasSearchChannel = useMemo(() => {
    return channelsList?.some(
      (c) =>
        c?.id === CHANNELS.SEARCH.id ||
        c?.label?.toLowerCase() === CHANNELS.SEARCH.lowerCaseName ||
        c?.id === CHANNELS.PMAX.id ||
        c?.label?.toLowerCase() === CHANNELS.PMAX.lowerCaseName
    );
  }, [channelsList]);

  const handleSubmit = (values) => {
    // Convert object to array and filter out unselected locations
    let data = Object.entries(values?.selectedLoc || {}).map(
      ([_locationId, locData]) => ({
        ...locData,
        locationId: locData.locationId || "",
      })
    );
    if (!campaignHasOnlyPMaxChannel) {
      data = data.filter((l) => l.locationId);
    }

    if (campaignHasOnlyPMaxChannel) {
      const locHasData = data.find((l) => l.locationId && l.boost_budget);
      const boost_budget = locHasData.boost_budget / locationData?.length;

      data = locationData.map((l) => ({
        ...locHasData,
        locationId: l.fk_location_id,
        boost_budget,
      }));
    }

    let selectedChannels = [];
    if (!is_selected_boost_type) {
      // if is_selected_boost_type is OFF, apply location boosting to all channels
      selectedChannels = channelsList.map((c) => c.id);
      data = data.map((l) => ({ ...l, channels: selectedChannels }));
    }

    data = data.map((l) => {
      const locationInfo = locationData.find(
        (lc) => lc.fk_location_id === l.locationId
      );
      return {
        ...l,
        locationInfo,
        fk_participant_id: locationInfo?.fk_participant_id,
        system_generated_participant_id:
          locationInfo?.system_generated_participant_id,
        fk_participant_id: locationInfo?.fk_participant_id,
        location_name: locationInfo?.location_name,
        system_generated_location_id:
          locationInfo?.system_generated_location_id,
      };
    });

    if (onContinue && typeof onContinue === "function") {
      onContinue(data);

      // clear the search key
      if (handleSearchChange && typeof handleSearchChange === "function") {
        handleSearchChange({ target: { value: "" } });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema({ is_selected_boost_type })}
      validateOnChange={true}
      debug
    >
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className={hide ? "d-none" : "mt-1"}>
          {!campaignHasOnlyPMaxChannel && (
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MuiSearchInput
                  value={searchKey}
                  onChange={handleSearchChange}
                  size="small"
                  type="search"
                  variant="outlined"
                  placeholder="Search locations..."
                  className="mt-3 mt-lg-1 mt-lx-0"
                />
              </Grid>
            </Grid>
          )}

          <Paper elevation={2} className="pb-2 mt-3">
            <Paper elevation={0} className="px-2">
              <Grid
                container
                spacing={2}
                className="table-heading d-none d-lg-flex py-3"
              >
                <Grid item xs={12} md className="pl-2">
                  Location
                  <BoostHeaderTooltip label="Location" />
                </Grid>
                {!!is_selected_boost_type && (
                  <Grid item xs={12} md>
                    Boost Channel
                    <BoostHeaderTooltip label="Boost Channel" />
                  </Grid>
                )}
                <Grid item xs={12} md>
                  Boost Start Date
                  <BoostHeaderTooltip label="Boost Start Date" />
                </Grid>
                <Grid item xs={12} md>
                  Boost End Date
                  <BoostHeaderTooltip label="Boost End Date" />
                </Grid>
                {/* <Grid item xs={12} md>
                  Current budget for the selected dates
                  <BoostHeaderTooltip label="Current budget for the selected dates" />
                </Grid> */}
                <Grid item xs={12} md>
                  Add Boost Budget
                  <BoostHeaderTooltip label="Add Boost Budget" />
                </Grid>
              </Grid>
            </Paper>

            {searchKey && !filteredLocations?.length ? (
              <div className="d-flex justify-content-center py-3">
                <RpAlert severity="info">No location found</RpAlert>
              </div>
            ) : null}
            <div className="mt-1">
              <FieldArray
                name="selectedLoc"
                render={(arrayHelpers) => (
                  <BoostLocationSetting
                    {...arrayHelpers}
                    locationData={
                      campaignHasOnlyPMaxChannel
                        ? filteredLocations.slice(0, 1)
                        : filteredLocations
                    }
                    campaignId={campaignId}
                    is_selected_boost_type={is_selected_boost_type}
                    channelsList={channelsList}
                    campaignHasSearchChannel={campaignHasSearchChannel}
                    campaignHasOnlyPMaxChannel={campaignHasOnlyPMaxChannel}
                    boosted_duration={boosted_duration}
                    LOC_INIT_STATE={LOC_INIT_STATE}
                  />
                )}
              />
            </div>
          </Paper>

          {/* Only show the general error for min-one-data-required test */}
          {errors?.selectedLoc &&
          !Array.isArray(errors?.selectedLoc) &&
          !errors?.selectedLoc?.inner ? (
            <RpAlert severity="error" className="my-2">
              <div className="error">{errors.selectedLoc}</div>
            </RpAlert>
          ) : null}

          <BoostContinueError />

          <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-lg-end my-4">
            <Button className="px-4 mr-1" onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="px-5"
              disabled={!!errors?.selectedLoc}
            >
              Continue
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default memo(BoostSettingTable);
