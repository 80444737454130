import React, { useContext, useMemo } from "react";
import { GlobalContext } from "../../helpers/contexts/contexts";
import ImageRP from "../../components/ImageRP";
import { getFormattedCampaignDate } from "../../helpers/utils";
import { CurrencyFormateText } from "../../components/CurrencyFormat";
import RpImageTextInline from "../../components/RpImageTextInline";
import LearnMoreButton from "../../components/muiCustom/buttons/LearnMoreButton";
import BoostCampaignButton from "../../components/muiCustom/buttons/BoostCampaignButton";
import PauseLocationAddIcon from "../campaign/campaignPause/PauseLocationAddIcon";

const Detail = ({ title = "", children, image = "" }) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
      <RpImageTextInline
        image={image}
        text={
          <>
            <div className="title">{title}</div>
            {children}
          </>
        }
        alignCenter={false}
      />
    </div>
  );
};

const CampaignReportDetail = ({ campaign, values }) => {
  const { loggedInUserRole, loggedInUser, selectedBrand } =
    useContext(GlobalContext);

  const formattedCampaignDate = useMemo(() => {
    return getFormattedCampaignDate(campaign, {
      loggedInUserRole,
      selectedBrand,
    });
  }, [campaign, loggedInUserRole, selectedBrand]);

  const channelName = useMemo(() => {
    // const { campaignChannelName } = campaign;
    if (campaign?.campaignChannelName[0]?.channel_name) {
      return campaign?.campaignChannelName[0]?.channel_name.replace(/,/g, ", ");
    }
    return "N/A";
  }, [campaign]);

  const groupName = useMemo(() => {
    const { group_id } = values;
    // const { group_name, group_list } = campaign;

    const group = campaign?.group_list.find(
      (g) => String(g.groups_id) === String(group_id)
    );
    if (campaign?.group_name) {
      return campaign?.group_name?.[0]?.group_name;
    } else if (group) {
      return group.group_name;
    }
    return "All";
  }, [campaign, values]);

  const renderBudget = () => {
    // const { brand_contribution } = campaign;
    if (!campaign?.brand_contribution) {
      return (
        <Detail title="Total Campaign Budget" image="/img/icons/money.png">
          <div className="value main">
            {campaign?.budgetResult[0]?.total_budget ? (
              <CurrencyFormateText
                value={campaign?.budgetResult?.[0]?.total_budget}
              />
            ) : (
              "N/A"
            )}
          </div>
        </Detail>
      );
    } else {
      return (
        // <Detail className="col-lg-6">
        //   <div className="row">
        <>
          <Detail title="Local Budget" image="/img/icons/money.png">
            <div className="value">
              {campaign?.budgetResult[0]?.local_budget ||
              Number(campaign?.budgetResult[0]?.local_budget) === 0 ? (
                <CurrencyFormateText
                  value={campaign?.budgetResult?.[0]?.local_budget}
                />
              ) : (
                "N/A"
              )}
            </div>
          </Detail>
          <Detail title="Brand Match" image="/img/icons/money.png">
            <div className="value">
              {campaign?.budgetResult[0]?.brand_match ||
              Number(campaign?.budgetResult[0]?.brand_match) === 0 ? (
                <CurrencyFormateText
                  value={campaign?.budgetResult?.[0]?.brand_match}
                />
              ) : (
                "N/A"
              )}
            </div>
          </Detail>
          <Detail title="Total Budget" image="/img/icons/money.png">
            <div className="value main">
              {campaign?.budgetResult[0]?.total_budget ||
              Number(campaign?.budgetResult[0]?.total_budget) === 0 ? (
                <CurrencyFormateText
                  value={campaign?.budgetResult?.[0]?.total_budget}
                />
              ) : (
                "N/A"
              )}
            </div>
          </Detail>
        </>
        //   </div>
        // </Detail>
      );
    }
  };

  const actionButtons = useMemo(() => {
    if (!campaign) return null;
    return (
      <>
        <Detail title="Actions" image="/img/icons/down_arrow.svg">
          <div className="value d-flex align-items-center mt-1">
            <LearnMoreButton
              to={{
                pathname: `${process.env.PUBLIC_URL}/dashboard/learn-more/digital-campaign/${campaign?.id}`,
                state: { campaignId: campaign?.id, from: "/dashboard" },
              }}
              className="mr-1"
              icon
              style={{ width: 24, height: 24 }}
            />
            {loggedInUserRole === "PR" &&
            !!campaign?.is_campaign_boost &&
            (campaign?.status === 1 || campaign?.status === 2) ? (
              <BoostCampaignButton
                to={{
                  pathname: `${process.env.PUBLIC_URL}/boost-campaign/${campaign?.id}`,
                  state: { campaignId: campaign?.id, from: "/dashboard" },
                }}
                style={{ width: 24, height: 24 }}
              />
            ) : null}
            {campaign &&
            campaign.pause_campaign_available &&
            loggedInUser &&
            loggedInUser?.fk_role_id !== 2 ? (
              <PauseLocationAddIcon
                campaignId={campaign?.id}
                campaign={campaign}
              />
            ) : null}
          </div>
        </Detail>
      </>
    );
  }, [campaign, loggedInUserRole]);

  const renderDetails = () => (
    <div className="row h-100">
      {loggedInUserRole === "PR" && (
        <Detail title="Name" image="/img/icons/user.png">
          <div className="value">
            {`${loggedInUser.first_name} ${loggedInUser.last_name}`}
          </div>
        </Detail>
      )}
      <Detail title="Campaign Name" image="/img/icons/campaign.png">
        <div className="value main">{campaign?.campaign_name}</div>
      </Detail>
      <Detail title="Unique ID" image="/img/icons/id.png">
        <div className="value us-all">
          {campaign?.system_generated_campaign_id}
        </div>
      </Detail>
      <Detail title="Campaign Date" image="/img/icons/calendar.png">
        <div className="value">{formattedCampaignDate}</div>
      </Detail>
      <Detail title="Channel" image="/img/icons/thumb.png">
        <div className="value" title={channelName}>
          {channelName}
        </div>
      </Detail>
      {loggedInUserRole !== "PR" && (
        <Detail title="Participants" image="/img/icons/users.png">
          <div className="value">
            {campaign?.campaignDetails?.[0]?.participant_count || 0}
          </div>
        </Detail>
      )}
      <Detail title="Locations" image="/img/icons/location.png">
        <div className="value">
          {campaign?.campaignDetails?.[0]?.location_count || 0}
        </div>
      </Detail>
      {loggedInUserRole !== "PR" && (
        <Detail title="Group(s)" image="/img/icons/home.png">
          <div className="value main">{groupName}</div>
        </Detail>
      )}
      {renderBudget()}
      {actionButtons}
    </div>
  );

  return (
    <div className="row align-items-stretch">
      {loggedInUserRole === "PR" && (
        <div className="col-md-3 col-lg-2 text-center d-none d-md-block">
          <ImageRP
            src={loggedInUser.profile_name}
            type="PROFILE"
            style={{ maxHeight: 130 }}
            className="img-fluid img-thumbnail"
          />
        </div>
      )}
      <div className="col">{renderDetails()}</div>
    </div>
  );
};

export default CampaignReportDetail;
