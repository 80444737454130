import { takeEvery, call, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { rpGTM } from "../../config/rpGTM";

import {
  authConstants,
  userConstants,
  localStorageConstant,
  statusCodeConstants,
  brandConstants,
} from "../../config/constants";
import { authService } from "../../services";
import { setLocal } from "../../helpers/localStore";
import MESSAGE from "../../helpers/messages";
import { getUserRole } from "../../config/appConfig";

function* login(action) {
  try {
    const res = yield call(authService.login, action.payload);

    const { token, user_detail } = res.data;
    if (statusCodeConstants.OK === res.status && token) {
      if (user_detail.brandData && user_detail?.brandData.length) {
        setLocal(localStorageConstant.TOKEN_NAME, token);

        rpGTM.login({
          userId: user_detail.id,
          userEmail: user_detail.email,
          userRole: user_detail?.is_master_pass
            ? "Admin"
            : user_detail?.role?.name || getUserRole(user_detail?.fk_role_id),
        });

        yield put({
          type: userConstants.GET_USER_SUCCESS,
          payload: user_detail,
        });
        yield put({
          type: brandConstants.GET_BRANDS_SUCCESS,
          payload: user_detail.brandData || [],
        });

        yield put({ type: authConstants.LOGIN_SUCCESS, payload: res.data });

        if (
          getUserRole(user_detail?.fk_role_id) === "PC" ||
          getUserRole(user_detail?.fk_role_id) === "BO" ||
          getUserRole(user_detail?.fk_role_id) === "BU"
        ) {
          // if Parent Company or Brand Owner logged in set first one as selected brand
          if (user_detail?.brandData && user_detail?.brandData.length) {
            yield put({
              type: brandConstants.SET_GLOBAL_BRAND,
              payload: user_detail.brandData[0],
            });
          }
        }
      } else {
        toastr.error("Error", MESSAGE.brandDataMissing);
        yield put({
          type: authConstants.LOGIN_FAIL,
          payload: MESSAGE.brandDataMissing,
        });
      }
    }
  } catch (err) {
    //errorHandler(err)
    yield put({ type: authConstants.LOGIN_FAIL, payload: err });
  }
}

/**
 * @method authSaga
 * @description watch for action and act
 */
export default function* authSaga() {
  yield takeEvery(authConstants.LOGIN_REQUEST, login);
}
