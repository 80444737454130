import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import LeftView from "../../../components/auth/LeftView";
import NoDataFound from "../../../components/NoDataFound";
import useQuery from "../../../helpers/hooks/useQuery";
import { isLoggedInSelector } from "../../../selectors/authSelectors";
import { authService } from "../../../services";
import InvalidLinkMessage from "./InvalidLinkMessage";
import { logger } from "../../../helpers/loggerHelper";

export default function ValidateEmailSignupLink() {
  const { token, subscribe } = useQuery();

  const isLoggedIn = useSelector(isLoggedInSelector);

  const [data, setDate] = useState(null);
  const [isValidLink, setIsValidLink] = useState(false);
  const [hasCredential, setHasCredential] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function validateEmailLink() {
      setIsLoading(true);
      try {
        const res = await authService.validateLinkToken(token);

        if (res.status === 200) {
          const data = res.data;
          if (data) {
            setDate(data);
            setIsValidLink(data.isValidLink);
            setHasCredential(data.hasCredential);
          }
        }
      } catch (err) {
        logger.error("Error in validateLinkToken", { err });
      } finally {
        setIsLoading(false);
      }
    }
    if (token) {
      validateEmailLink();
    }
  }, [token]);

  const renderScreen = () => {
    if (isValidLink) {
      if (hasCredential) {
        return (
          <Redirect
            to={`/login?email=${data?.email}${
              subscribe ? "&subscribe=true" : ""
            }`}
          />
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: `${process.env.PUBLIC_URL}/create-password`,
              state: {
                userData: data,
                token,
                subscribe,
              },
            }}
          />
        );
      }
    } else {
      return <InvalidLinkMessage email={data?.email} subscribe={subscribe} />;
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="row login-page">
      <div className="col-md-6 p-0">
        <LeftView />
      </div>
      <div className="col-md-6 d-flex flex-column justify-content-center px-md-5 py-1 py-sm-5 validate-email-signup-link">
        {isLoading ? <NoDataFound message="Validating..." /> : renderScreen()}
      </div>
    </div>
  );
}
