import React, { useContext, useMemo, useState } from "react";
import { Typography, Button, Icon } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import RpMUIModal from "../../../components/modals/RpMUIModal";
import { campaignService } from "../../../services/campaignService";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../helpers/contexts/contexts";

/**
 * Modal component for confirming location status changes (pause/activate)
 * @param {Object} props - Component props
 * @param {boolean} props.open - Controls modal visibility
 * @param {Function} props.onClose - Handler for modal close
 * @param {Array} props.locations - Current locations with updated status
 * @param {Array} props.initialLocations - Initial locations before status changes
 * @param {string} props.campaignId - ID of the campaign
 */
const PauseConfirmationModal = ({
  open,
  onClose,
  locations = [],
  campaignId,
  isPMax,
}) => {
  const { selectedBrand } = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  // Calculate status changes by comparing current and initial states
  const statusChanges = useMemo(() => {
    return locations.reduce(
      (acc, loc) => {
        if (loc.originalStatus !== loc.is_pause_campaign) {
          loc.is_pause_campaign ? acc.toActivate++ : acc.toPause++;
        }
        return acc;
      },
      { toPause: 0, toActivate: 0 }
    );
  }, [locations]);

  // Generate appropriate message based on status changes
  const getMessage = useMemo(() => {
    const { toPause, toActivate } = statusChanges;

    // Helper for pluralization
    const pluralize = (count, word) =>
      `${count} ${count === 1 ? word : word + "s"}`;

    // Case 1: Only pausing locations
    if (toPause > 0 && toActivate === 0) {
      return `You're pausing ${isPMax ? `${locations?.length} locations` : pluralize(toPause, "location")}. Keep in mind that when ads stop and re-start, performance may suffer due to a re-ramp period.`;
    }

    // Case 2: Only activating locations
    if (toActivate > 0 && toPause === 0) {
      return `You're setting ${isPMax ? `${locations?.length} locations` : pluralize(toActivate, "location")} live. Keep in mind that when ads stop and re-start, performance may suffer due to a re-ramp period.`;
    }

    // Case 3: Both pausing and activating locations
    return `You're setting ${pluralize(toActivate, "location")} live. You’re pausing ${pluralize(toPause, "location")}. Keep in mind that when ads stop and re-start, performance may suffer due to a re-ramp period.`;
  }, [statusChanges]);

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true);

      // Get locations with changed status
      const statusWhenPMax = locations?.[0]?.is_pause_campaign;
      const changedLocations = isPMax
        ? locations.map((l) => ({ ...l, is_pause_campaign: statusWhenPMax }))
        : locations.filter(
            (location) => location.originalStatus !== location.is_pause_campaign
          );

      // Prepare data for API
      const requestData = {
        campaign_id: campaignId,
        location_ids: changedLocations.map((loc) => ({
          system_generated_location_id: loc.system_generated_location_id,
          is_pause_campaign: loc.is_pause_campaign,
        })),
        brand_id: selectedBrand?.id || null,
        brand_name: selectedBrand?.brand_name || null,
        isPMax,
      };

      const response = await campaignService.requestPauseCampaign(requestData);

      if (response?.status === 200) {
        toastr.success("Success", "Location status updated successfully!");

        history.replace({
          pathname: "/dashboard/thank-you",
          search: `?type=campaign_pause`,
          state: {
            from: "campaign_pause",
          },
        });
      } else {
        throw new Error("Failed to update campaign status");
      }
    } catch (error) {
      toastr.error(
        "Error",
        error.response?.data?.message || "Failed to update location status"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <RpMUIModal open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {isSubmitting && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}
      <div className="status-change-confirmation text-center">
        <Icon
          component="img"
          src="/img/icons/info.svg"
          className="warning-icon mb-2"
        />

        <Typography variant="body1" className="text-center" gutterBottom>
          {getMessage}
        </Typography>

        <Typography variant="h6" className="text-center" gutterBottom>
          Are you sure you want to continue?
        </Typography>
      </div>

      <div className="d-flex justify-content-center gap-3 mt-4">
        <Button
          onClick={onClose}
          color="white"
          disabled={isSubmitting}
          variant="outlined"
          className="mr-3 px-5"
          size="large"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          className="confirm-btn px-5"
          disabled={isSubmitting}
        >
          Confirm
        </Button>
      </div>
    </RpMUIModal>
  );
};

export default PauseConfirmationModal;
