import React, { useState, useRef, useEffect, useMemo } from "react";
import { Doughnut, Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  formateKeyName,
  getChartColors,
  getFormattedData,
} from "../../helpers/utils";
import TablePagination from "../tables/TablePagination";

Chart.pluginService.register({
  plugins: [ChartDataLabels],
});

const legendOptions = {
  display: false,
  position: "right",
};

const DoughnutChart = ({
  data = [],
  labels = [],
  colorSet = 1,
  formats = null,
}) => {
  const chartRef = useRef();
  const [chartData, setChartData] = useState({});

  // pagination
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const options = useMemo(
    () => ({
      cutoutPercentage: 60,
      aspectRatio: 3,
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      plugins: {
        // labels: {
        //     render: 'value',
        //     fontColor: '#fff'
        // },
        datalabels: {
          color: "#fff",
          font: {
            weight: "600",
            family: "Open Sans",
            size: 10,
          },
          formatter: function (value) {
            const format = formats ? formats[labels[1]] : null;
            if (formats && format) {
              return `${format.prefix}${value}${format.suffix}`;
            }
            return value;
          },
        },
      },
      tooltips: {
        cornerRadius: 0,

        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.labels[tooltipItem.index];
            const val = data.datasets[0].data[tooltipItem.index] || "";
            const format = formats ? formats[labels[1]] : null;
            if (formats && format) {
              return `${label}: ${format.prefix}${val}${format.suffix}`;
            }
            return `${label}: ${val}`;
          },
        },
      },
    }),
    [formats]
  );

  const getPageDataIndex = () => {
    const from = (currentPage - 1) * pageSize;
    const to = from + pageSize;
    return { from, to };
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const total = Math.ceil(data.length / pageSize);
    setTotalPage(total);

    const initData = {
      labels: data
        .slice(getPageDataIndex().from, getPageDataIndex().to)
        .map((d) => d[labels[0]]),
      datasets: [
        {
          data: data.slice(currentPage - 1, pageSize).map((d) => d[labels[1]]),
          backgroundColor: getChartColors(colorSet),
          borderColor: getChartColors(colorSet),
        },
      ],
    };
    setChartData(initData);
  }, [data]);

  useEffect(() => {
    const initData = {
      labels: data
        .slice(getPageDataIndex().from, getPageDataIndex().to)
        .map((d) => d[labels[0]]),
      datasets: [
        {
          data: data
            .slice(getPageDataIndex().from, getPageDataIndex().to)
            .map((d) => d[labels[1]]),
          backgroundColor: getChartColors(colorSet),
          borderColor: getChartColors(colorSet),
        },
      ],
    };
    setChartData(initData);
  }, [currentPage]);

  return (
    <div className="charts doughnut-chart">
      <div className="row pb-2">
        <div className="col-lg-5 text-center px-md-0 ">
          <div className="chart-container">
            <Doughnut
              ref={chartRef}
              data={chartData}
              legend={legendOptions}
              options={options}
              width={250}
              height={250}
              cutoutPercentage="60"
            />
          </div>
        </div>
        <div className="col-lg-7">
          <table
            className="table table-borderless table-hover table-sm"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                {labels.map((label, i) => (
                  <th
                    className={`text16P_Bold548E8D heading upper ${
                      i % 2 !== 0 ? "text-right" : ""
                    }`}
                    key={`${label}_${i}`}
                  >
                    {formateKeyName(label)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data
                .slice(getPageDataIndex().from, getPageDataIndex().to)
                .map((d, i) => (
                  <tr key={`${d[labels[0]]}_${i}`}>
                    {Object.keys(d).map((key, j) => (
                      <td
                        className={`text16P_Reg626262 text-truncate ${
                          j % 2 !== 0 ? "text-right" : ""
                        }`}
                        key={`${key}_${i}_${j}`}
                      >
                        {getFormattedData(d[key], key, formats)}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-12 float-right">
          <TablePagination
            page={currentPage}
            count={totalPage}
            onChange={handlePageChange}
            className="float-right"
          />
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;
