import { useEffect, useState, useContext, useMemo, memo } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import moment from "moment";
import { isEmpty } from "lodash";

import { renderUniversalTemplate } from "../../helpers/reportHelper";
import { reportService } from "../../services/reportService";
import useFetcher from "../../helpers/hooks/useFetcher";
import { GlobalContext } from "../../helpers/contexts/contexts";
import { MUICustomAutocompleteSelect } from "../muiCustom/select/MUICustomAutocompleteSelect";
import { CUSTOM_WIDGET_PARAM } from "../../config/constants";
import RpCustomMonthPicker from "../datepicker/RpCustomMonthPicker";

export default memo(function UniversalWidgetTemplate({
  rows = [],
  renderChartWidget,
  type,
  requestParam,
  embedWidgetParams = {},
  hideDateSelector = false,
}) {
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);

  const locationsList = useMemo(
    () => loggedInUser?.partLocationData || [],
    [loggedInUser]
  );

  const firstActiveLoc = useMemo(() => {
    // Try to find a location that has both data and an active subscription
    const l1 = loggedInUser?.partLocationData?.find(
      (l) => l.is_data_exists && l.is_current_subscription_active
    );

    // If no such location, try to find any location with an active subscription
    const l2 = loggedInUser?.partLocationData?.find(
      (l) => l.is_current_subscription_active
    );

    // If no such location, try to find one with data but no active subscription
    const l3 = loggedInUser?.partLocationData?.find(
      (l) => l.is_data_exists && !l.is_current_subscription_active
    );

    // Return the first found location from the conditions above, or fallback to the first location in the list
    return l1 || l2 || l3 || loggedInUser?.partLocationData?.[0] || "";
  }, [loggedInUser]);

  const [month, setMonth] = useState();

  const [group, setGroup] = useState();

  const [location, setLocation] = useState(
    () =>
      // firstActiveLoc?.system_generated_location_id ||
      // loggedInUser?.partLocationData?.[0]?.system_generated_location_id ||
      ""
  );
  const [campaignReport, setCampaignReport] = useState(null);

  const [filter, setFilter] = useState(() =>
    location
      ? {
          system_generated_location_id: location,
        }
      : {}
  );

  useEffect(() => {
    setLocation(
      // firstActiveLoc?.system_generated_location_id ||
      //   loggedInUser?.partLocationData?.[0]?.system_generated_location_id ||
      ""
    );
  }, [loggedInUser?.partLocationData, firstActiveLoc]);

  const reqData = useMemo(() => {
    const d = {
      dataType: type,
      id: selectedBrand.id,
      ...filter,
      ...requestParam,
    };
    if (hideDateSelector) {
      // empty date if Report For is hidden
      d.date = "";
    }
    return d;
  }, [selectedBrand?.id, requestParam, filter, type, hideDateSelector]);

  const { data, isLoading: loading } = useFetcher(
    reqData,
    reportService.getUniversalWidgetReport,
    { revalidateOnFocus: false, fallback: rows }
  );

  const groupsList = data?.groupList?.groups || [];

  useEffect(() => {
    if (!month) {
      if (!data?.month_data) return;
      const d = moment.utc(data?.month_data);
      if (!d.isValid()) return;
      setMonth(moment(d).format("YYYY-MM"));
    }
  }, [data?.month_data, month]);

  useEffect(() => {
    if (!isEmpty(data?.data)) {
      setCampaignReport(data?.data);
    }
  }, [loading]);

  const rowData =
    !loading && !isEmpty(data?.data) ? data?.data : campaignReport;
  useEffect(() => {
    const reqData = {};
    if (month && moment(month).isValid()) {
      reqData.date = moment(month).format("MM-DD-YYYY") || "";
    }
    if (group) {
      reqData.system_generated_group_id = group || "";
    }
    if (location) {
      reqData.system_generated_location_id = location || "";
    }
    setFilter(reqData);
  }, [month, location, group]);

  const embedWidgetParamsData = useMemo(() => {
    let params = {
      ...embedWidgetParams,
    };
    if (type === "participant" && location) {
      params = {
        ...params,
        [CUSTOM_WIDGET_PARAM.LocationID]: location,
      };
    } else if (group) {
      params = {
        ...params,
        [CUSTOM_WIDGET_PARAM.GroupID]: group,
      };
    }

    // if (getFormattedMonthRange) {
    //   params = {
    //     ...params,
    //     [CUSTOM_WIDGET_PARAM.StartDate]: moment(
    //       getFormattedMonthRange?.from || new Date()
    //     )
    //       ?.startOf("month")
    //       .format("YYYY-MM-DD"),
    //     [CUSTOM_WIDGET_PARAM.EndDate]: moment(
    //       getFormattedMonthRange?.to || new Date()
    //     )
    //       ?.startOf("month")
    //       .format("YYYY-MM-DD"),
    //   };
    // }

    return params;
  }, [type, location, group, embedWidgetParams]);

  const filters = (labelColor = "#1B262C") => {
    return (
      <div className="universal-widget-filters">
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          {type === "participant" && (
            <Grid item xs={12} sm={hideDateSelector ? 6 : true}>
              <MUICustomAutocompleteSelect
                options={locationsList.map((l) => ({
                  label: l.location_name,
                  value: l.system_generated_location_id,
                }))}
                selectProps={{
                  onChange: (v) => {
                    setLocation(v);
                  },
                  value: location,
                }}
                labelProps={{
                  style: {
                    color: labelColor,
                  },
                }}
                name="location"
                label="Location"
                all
              />
            </Grid>
          )}
          {type === "brand" && (
            <Grid item xs={12} sm={hideDateSelector ? 6 : true}>
              <MUICustomAutocompleteSelect
                options={[
                  ...groupsList.map((g) => ({
                    label: g.group_name,
                    value: g.system_generated_group_id,
                  })),
                ]}
                selectProps={{
                  onChange: (v) => {
                    setGroup(v);
                  },
                  value: group,
                }}
                labelProps={{
                  style: {
                    color: labelColor,
                  },
                }}
                name="group"
                label="Groups"
                all
              />
            </Grid>
          )}
          {!hideDateSelector ? (
            <Grid item xs={12} sm={type === "location" ? 6 : true}>
              <RpCustomMonthPicker
                onChange={setMonth}
                initialValue={month}
                label="Report For"
                labelProps={{
                  style: {
                    color: labelColor,
                  },
                }}
                displayButtonProps={{
                  variant: "contained",
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </div>
    );
  };

  return (
    <div className={type === "participant" && rowData ? "mb-5" : ""}>
      <div>{loading && <LinearProgress />}</div>
      {renderUniversalTemplate({
        rows: rowData,
        renderChartWidget,
        filters,
        embedWidgetParams: embedWidgetParamsData,
      })}
    </div>
  );
});
