import React, { useCallback, useContext, useMemo, useState } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";

import TableServer from "../../../components/tables/TableServer";
import useFetcher from "../../../helpers/hooks/useFetcher";
import { stripeMicroService } from "../../../services/stripe-microservice/stripeMicroService";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import { getFormatDate, keyToName } from "../../../helpers/utils";
import StatusChip from "../../../components/muiCustom/chip/StatusChip";

function CurrentSubscriptions() {
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const [filter, setFilters] = useState({
    page: 0,
    row: 10,
    sortBy: "",
    sortByDir: "",
    user_id: loggedInUser.id,
  });

  const reqData = useMemo(() => {
    return {
      ...filter,
      page: filter.page ? filter.page + 1 : 1,
      list_type: "current_subscriptions",
      brandId: selectedBrand?.id,
    };
  }, [filter, selectedBrand?.id]);

  const { data, isLoading, isValidating } = useFetcher(
    reqData,
    stripeMicroService.getCurrentSubscriptions,
    { revalidateOnFocus: false }
  );

  const pageCount = data?.data?.result?.data?.pageCount || 0;
  const totalRecords = data?.data?.result?.data?.recordsTotal || 0;

  const columns = useMemo(
    () => [
      {
        Header: "Location",
        accessor: "location_name",
        width: 400,
      },
      {
        Header: "Location ID",
        accessor: "system_generated_location_id",
      },
      {
        Header: "Plan",
        accessor: "title",
        width: 400,
        Cell: ({ row }) => {
          const {
            original: { title },
          } = row;

          return title || "N/A";
        },
      },
      {
        Header: "Plan Start Date",
        accessor: "subscription_start_date",
        width: 270,
        Cell: ({ row }) => {
          const {
            original: { subscription_start_date },
          } = row;

          return getFormatDate(subscription_start_date) || "N/A";
        },
      },
      {
        Header: "Plan End Date",
        accessor: "subscription_end_date",
        width: 270,
        Cell: ({ row }) => {
          const {
            original: { subscription_end_date },
          } = row;

          return getFormatDate(subscription_end_date) || "N/A";
        },
      },
      {
        Header: "Status",
        accessor: "subscription_status",
        width: 200,
        Cell: ({ row }) => {
          const {
            original: { subscription_status },
          } = row;

          switch (subscription_status) {
            case "past_due":
            case "incomplete":
            case "incomplete_expired":
            case "cancel":
              return (
                <StatusChip
                  label={keyToName(subscription_status)}
                  status={"error"}
                />
              );
            case "active":
              return (
                <StatusChip
                  label={keyToName(subscription_status)}
                  status={"success"}
                />
              );
            default:
              return "-";
          }
        },
      },
    ],
    []
  );

  const dataList = useMemo(() => {
    return data?.data?.result?.data?.subscriptions || [];
  }, [data]);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, searchKey, sortBy, sortByDir }) => {
      setFilters((p) => ({
        ...p,
        page: pageIndex,
        row: pageSize,
        sortBy: sortBy,
        sortByDir: sortByDir,
        search: searchKey,
        user_id: loggedInUser.id,
      }));
    },
    []
  );

  return (
    <Card className="w-100 mb-4 border-0 card-shadow">
      <div className="card-header">
        <Typography variant="h6" className="table-title">
          Current Plans
        </Typography>
      </div>
      <CardContent className="px-0 subscription-invoice">
        <TableServer
          columns={columns}
          data={dataList}
          showSearch={true}
          fetchData={handleFetchData}
          isLoading={isLoading || isValidating}
          pageCount={pageCount}
          totalRecords={totalRecords}
        />
      </CardContent>
    </Card>
  );
}

export default CurrentSubscriptions;
