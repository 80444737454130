import React, { useState, useContext, useCallback, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";

import { stripeMicroService } from "../../services/stripe-microservice/stripeMicroService";
import { GlobalContext } from "../../helpers/contexts/contexts";
import RpMUIDialog from "../../components/modals/RpMUIModal";
import { useLocation } from "react-router-dom";
import useCustomerPortal from "../../helpers/hooks/useCustomerPortal";

const SHOW_ON_ROUTE = ["/dashboard"];

function PendingInvoiceCheck() {
  const { pathname } = useLocation();

  const { loggedInUser, loggedInUserRole } = useContext(GlobalContext);
  const [isPendingInvoice, setInPendingInvoice] = useState(false);

  const { isLoadingCustomerPortal, navigateToCustomerPortal } =
    useCustomerPortal();

  const checkPendingInvoice = useCallback(async () => {
    try {
      const reqData = {
        user_id: loggedInUser?.id,
      };
      const res = await stripeMicroService.pendingInvoiceCheck(reqData);

      if (res.status === 200 && res.data?.result) {
        setInPendingInvoice(
          res.data?.result?.data?.pending_invoice ? true : false
        );
      } else {
        setInPendingInvoice(false);
      }
    } catch (err) {
      setInPendingInvoice(false);
    }
  }, [loggedInUser?.id]);

  useEffect(() => {
    if (loggedInUser?.participantData?.id) checkPendingInvoice();
  }, [checkPendingInvoice, loggedInUser?.participantData?.id]);

  if (loggedInUserRole !== "PR" || !loggedInUser?.participantData?.id)
    return null;

  return isPendingInvoice && SHOW_ON_ROUTE.includes(pathname) ? (
    <>
      <RpMUIDialog
        open
        onClose={() => {
          setInPendingInvoice(false);
        }}
        persists
      >
        <div className="d-flex justify-content-end">
          <CancelRounded
            onClick={() =>
              !isLoadingCustomerPortal && setInPendingInvoice(false)
            }
            className="cursor-p"
          />
        </div>
        <div className="d-flex justify-content-center">
          <img
            src="/img/payment_method.png"
            alt=""
            className="img-fluid"
            style={{
              maxHeight: 150,
              objectFit: "contain",
            }}
          />
        </div>
        <Typography variant="h6" className="text-center my-4">
          Your subscription is past due, please update your payment method to
          continue
        </Typography>
        <div className="d-flex justify-content-center my-4">
          <Button
            variant="contained"
            color="primary"
            onClick={navigateToCustomerPortal}
            className="px-5"
            disabled={isLoadingCustomerPortal}
          >
            Update Payment Method
          </Button>
        </div>
      </RpMUIDialog>
    </>
  ) : null;
}

export default PendingInvoiceCheck;
