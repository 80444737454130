import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactPlayer from "react-player/youtube";
import { STATIC_IMAGES } from "../helpers/staticImages";

export default function RpVideoPlayer({
  url,
  videoThumbnail = STATIC_IMAGES.videoThumbnail,
  showPlayIcon = false,
  loaderTimeout = 2000, // Set the desired duration for the loader (in milliseconds)
  ...rest
}) {
  const [loading, setLoading] = useState(true);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    // Start the video after the loaderTimeout duration
    const timer = setTimeout(() => {
      setLoading(false);
      setPlay(true);
    }, loaderTimeout);

    return () => clearTimeout(timer);
  }, [loaderTimeout]);

  return (
    <div className="player-wrapper d-flex align-items-center justify-content-center">
      {loading ? (
        <div className="player-loader position-absolute">
          <CircularProgress />
        </div>
      ) : (
        <ReactPlayer
          className="react-player"
          width="100%"
          height="100%"
          playing={play}
          url={url}
          light={videoThumbnail}
          playIcon={showPlayIcon ? null : <span></span>}
          controls
          {...rest}
        />
      )}
    </div>
  );
}
