import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import { toastr } from "react-redux-toastr";

import { subscriptionHelper } from "../../../helpers/subscriptionHelper";
import { subscriptionService } from "../../../services/subscriptionService";
import {
  GlobalContext,
  LoaderContext,
} from "../../../helpers/contexts/contexts";
import BrandMatchCard from "./BrandMatchCard";
import LocationCalculation from "./LocationCalculation";
import TotalCalculation from "./TotalCalculation";
import TermsAndConditions from "./TermsAndConditions";
import PreviousSelectedPlan from "./PreviousSelectedPlan";
import { subscriptionPlanPaymentType } from "../../../config/constants";
import { stripeMicroService } from "../../../services/stripe-microservice/stripeMicroService";
import ConfirmStripeEmbedCheckoutPopup from "../../../components/stripe/embedCheckout/ConfirmStripeEmbedCheckoutPopup";
import { logger } from "../../../helpers/loggerHelper";

export default function ConfirmPackageSelection({
  selections = [],
  onPrev,
  isChangingUpcomingPackage,
  isOptIn,
  // isNewSubscription,
  setActiveStep,
  onConfirm,
}) {
  const history = useHistory();
  const loader = useContext(LoaderContext);
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [makePayment, setMakePayment] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    loader(isLoading);
  }, [isLoading, loader]);

  const paymentType = useMemo(() => {
    return selections?.[0]?.packageInfo?.payment_type;
  }, [selections]);

  const isBrandAutoOn = useMemo(() => {
    return selections?.[0]?.locationInfo?.brand_auto_renew;
  }, [selections]);

  const totals = useMemo(() => {
    return subscriptionHelper.calculateAllSubscriptionTotals(selections);
  }, [selections]);

  const save = async (data) => {
    setIsLoading(true);
    try {
      const res = await subscriptionService.saveSubscriptionSelection(data);
      if (res.status === 200) {
        loader(false);
        const { is_redirect = false, campaign_id = null } =
          res.data?.data || {};
        history.replace({
          pathname: "/dashboard/thank-you",
          state: {
            from: "package_selection",
            is_redirect,
            campaign_id,
          },
        });
      }
    } catch (err) {
      logger.error("Error in saveSubscriptionSelection", { err });
    } finally {
      setIsLoading(false);
    }
  };

  const saveChangeOnly = async (data = {}) => {
    setIsLoading(true);
    try {
      const locSelectionData = selections?.[0];
      const reqData = {
        fk_location_id: locSelectionData?.locationId,
        fk_brand_id: selectedBrand.id,
        fk_user_id: locSelectionData.locationInfo?.fk_user_id,
        new_package_id: locSelectionData?.packageId,
        system_generated_location_id:
          locSelectionData?.locationInfo?.system_generated_location_id,
        ...data,
      };

      const res = await subscriptionService.changePackageDetails(reqData);
      if (res.status === 200) {
        loader(false);
        toastr.success("Success", `Upcoming Plan Updated Successfully`);
        onConfirm();
      }
    } catch (err) {
      if (err.response.status === 406) {
        loader(false);
        onConfirm();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const saveOptIn = async (data) => {
    setIsLoading(true);
    try {
      const res = await subscriptionService.optInAutoRenewal(data);
      if (res.status === 200) {
        loader(false);
        toastr.success(
          "Success",
          `You have Opted-In for Auto-Renew Upcoming Subscription Successfully`
        );
        onConfirm(true);
      }
    } catch (err) {
      if (err.response.status === 406) {
        loader(false);
        onConfirm();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirm = () => {
    const isInvoice = paymentType === subscriptionPlanPaymentType.INVOICE;
    // const isStripe = paymentType === subscriptionPlanPaymentType.STRIPE;

    // if (isChangingUpcomingPackage && isInvoice) {
    //   return saveChangeOnly();
    // }

    const selectionData = selections.map((s) => {
      const { startDate, endDate } =
        subscriptionHelper.calculateSubscriptionDate(
          s?.packageInfo,
          s?.locationInfo
        );

      s.startDate = startDate.format("MMM D, YYYY");
      s.endDate = endDate.format("MMM D, YYYY");

      return s;
    });
    const dataToSave = {
      userId: selectionData?.[0]?.locationInfo?.fk_user_id,
      created_by: loggedInUser.id,
      selections: selectionData,
      brandId: selectedBrand.id,
      system_generated_brand_id: selectedBrand.system_generated_brand_id,
      fk_brand_name: selectedBrand.brand_name,
      payment_using_credit_card: paymentType,
      payment_type: paymentType,
    };

    if (
      !isBrandAutoOn &&
      selectionData?.[0]?.locationInfo?.upcoming_package_id
    ) {
      dataToSave.is_changing = true;
      dataToSave.is_optIn = false;
    }

    if (isChangingUpcomingPackage) {
      dataToSave.is_changing = true;
      dataToSave.is_optIn = false;
    }

    if (isOptIn) {
      dataToSave.is_changing = false;
      dataToSave.is_optIn = true;
    }

    if (isInvoice && isChangingUpcomingPackage) {
      return saveChangeOnly(dataToSave);
    } else if (isInvoice && isOptIn && isBrandAutoOn) {
      saveOptIn(dataToSave);
    } else if (paymentType === subscriptionPlanPaymentType.STRIPE) {
      setMakePayment({ ...dataToSave, amount: totals.total });
    } else {
      save(dataToSave);
    }
  };

  const isBrandMatch = selections.some(
    (check) => check?.packageInfo?.is_brand_match === 1
  );

  return (
    <div className="confirm-package-selection py-5">
      <div className="container">
        <Typography
          component="h2"
          variant="h5"
          gutterBottom
          className="sub-heading"
          color="primary"
        >
          Review and Confirm
        </Typography>

        {isChangingUpcomingPackage
          ? selections.map((locData) => (
              <PreviousSelectedPlan
                data={locData}
                key={locData.locationId}
                isChangingUpcomingPackage={isChangingUpcomingPackage}
              />
            ))
          : null}

        {selectedBrand.id === 2 &&
        isBrandMatch &&
        !isChangingUpcomingPackage ? (
          <BrandMatchCard selections={selections} />
        ) : null}

        {selections.map((loc) => (
          <LocationCalculation
            data={loc}
            key={loc.locationId}
            isChangingUpcomingPackage={isChangingUpcomingPackage}
          />
        ))}
        {totals && (
          <div>
            <TotalCalculation totals={totals} />
          </div>
        )}
        <TermsAndConditions
          isChecked={isChecked}
          handleTermsAndCondition={(e) => setIsChecked(e.target.checked)}
        />
        <div className="row my-4 align-items-center">
          <div className="col-md-8 mb-4 note">
            Once confirmed, your plan will be updated and you will be able to
            make changes until your billing date.
          </div>

          <div
            className={`col-md-4 mb-4 d-flex align-items-center justify-content-end`}
          >
            <Button color="primary" variant="outlined" onClick={onPrev}>
              Make Changes
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 px-5"
              onClick={handleConfirm}
              disabled={!isChecked}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>

      <ConfirmStripeEmbedCheckoutPopup
        setActiveStep={setActiveStep}
        title="Payment"
        makePayment={makePayment ? true : false}
        data={
          makePayment
            ? {
                email: loggedInUser.email,
                ...makePayment,
              }
            : {}
        }
        onClose={() => setMakePayment(false)}
        return_uri={
          isOptIn || isChangingUpcomingPackage
            ? `/subscription-return?manage_subscription=true`
            : `/subscription-return`
        }
        api={stripeMicroService.subscriptionPayment}
        locSubscription={true}
      />
    </div>
  );
}
