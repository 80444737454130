import React from "react";
import { InputLabel } from "@material-ui/core";

import { MUICustomAutocompleteSelect } from "../../../components/muiCustom/select/MUICustomAutocompleteSelect";

const RequestTypeSelector = ({
  onChange,
  selectedRequestType,
  brandRequestList,
}) => {
  return (
    <>
      <InputLabel
        shrink
        margin="dense"
        disableAnimation
        style={{
          color: "#1B262C",
          fontSize: ".92rem",
          fontWeight: "700",
          padding: "0px",
        }}
        className="px-2 pb-1"
        required
      >
        What Can We Help You With?
      </InputLabel>
      <MUICustomAutocompleteSelect
        options={
          brandRequestList
            ? Object.values(brandRequestList).map((v) => ({
                label: v.name,
                value: v.name,
              }))
            : []
        }
        selectProps={{
          value: selectedRequestType,
          onChange: onChange,
        }}
        className="w-100 px-2 py-2 pr-0 p-0 m-0"
        size="small"
        name="requestType"
        placeholder="Please select"
      />
    </>
  );
};

export default RequestTypeSelector;
