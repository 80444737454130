import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Field, Formik } from "formik";
import { Link, Redirect } from "react-router-dom";
import LeftView from "../../../components/auth/LeftView";
import LoadingButton from "../../../components/loaders/LoadingButton";
import { emailMDInputFormik } from "../../../helpers/formInputs";
import { validateEmail } from "../../../helpers/validations";
import { authService } from "../../../services";
import UnRegisteredEmailMessage from "./UnRegisteredEmailMessage";
import AlreadyHaveAccountMessage from "./AlreadyHaveAccountMessage";
import EmailLinkSendMessage from "../validateEmailSignupLink/EmailLinkSendMessage";
import useQuery from "../../../helpers/hooks/useQuery";
import { isLoggedInSelector } from "../../../selectors/authSelectors";
import MainSEO from "../../../components/seo/MainSEO";

export default function Signup() {
  const { subscribe } = useQuery();

  const isLoggedIn = useSelector(isLoggedInSelector);

  const [emailData, setEmailData] = useState(null);
  const [isAssociated, setIsAssociated] = useState(false);
  const [hasCredential, setHasCredential] = useState(false);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitForSignup = async (values) => {
    setIsLoading(true);
    try {
      const res = await authService.signUp({ ...values, subscribe });
      if (res.status === 200) {
        const data = res.data;
        setEmailData(data);
        setIsAssociated(data.isLocationAllocated);
        setHasCredential(data.hasCredential);
        setIsEmailSend(data.isEmailSend);
      }
    } catch (err) {
      setEmailData({});
    } finally {
      setIsLoading(false);
    }
  };
  const renderSignupForm = () => {
    return (
      <div className="sign-up-form">
        <h2 className="heading mb-3">
          You're a few clicks away from accessing Hyperlocology!
        </h2>
        <h6 className="subheading">
          In order to link you to your brand we need you to enter your brand
          email address
        </h6>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={onSubmitForSignup}
          validate={validateEmail}
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} className="mt-1 mt-sm-4 mt-md-5">
                <Field
                  name="email"
                  label="E-Mail"
                  component={emailMDInputFormik}
                  autoComplete="off"
                />

                <div className="d-flex align-items-center justify-content-between form-group mt-3 mt-sm-5">
                  <LoadingButton
                    type="submit"
                    className="primary"
                    text="Continue"
                    isLoading={isLoading}
                  />
                  <Link
                    to="/login"
                    className={`heading9 link link-w ${
                      isLoading ? "disabled-link" : ""
                    }`}
                  >
                    Back
                  </Link>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  };

  const renderScreen = () => {
    if (emailData) {
      if (isAssociated) {
        if (hasCredential) {
          return (
            <AlreadyHaveAccountMessage
              email={emailData?.email}
              subscribe={subscribe}
            />
          );
        } else {
          if (isEmailSend) {
            return (
              <EmailLinkSendMessage
                email={emailData?.email}
                subscribe={subscribe}
              />
            );
          } else {
            return (
              <h4 className="heading mx-auto">
                Something went wrong. Please try again!
              </h4>
            );
          }
        }
      } else {
        return <UnRegisteredEmailMessage />;
      }
    } else {
      return renderSignupForm();
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="row login-page">
      <MainSEO title="Signup" url="signup" />
      <div className="col-md-6 p-0">
        <LeftView />
      </div>
      <div className="col-md-6 d-flex flex-column justify-content-center px-md-5 py-1 py-sm-5 sign-up">
        {renderScreen()}
      </div>
    </div>
  );
}
