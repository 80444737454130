import AxiosInstance from "../config/AxiosInstance";
import { API_BASE_URL_ADMIN, API_BASE_URL } from "../config/appConfig";

export const userService = {
  getUser,
  updateUser,
  userActivity,
};

function getUser(user) {
  const userId = user.logedInUser ? user.logedInUser : user.id;
  return AxiosInstance.get(
    `${API_BASE_URL_ADMIN}/user?id=${userId}&status=0&row=25&page=1&is_deleted=0`
  );
}

function updateUser(data) {
  return AxiosInstance.put(`${API_BASE_URL_ADMIN}/user`, data);
}

function userActivity(event) {
  return AxiosInstance.get(`${API_BASE_URL}/store/user_activity/${event}`);
}
