import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import JoinCampaignConfirmationPage from "../../containers/participant/campaign/JoinCampaignConfirmationPage";
import JoinCampaignPage from "../../containers/participant/campaign/JoinCampaignPage";
import ParticipantFeedback from "../../containers/participant/ParticipantFeedback";
import JoinPostcardCampaign from "../../containers/postcardCampaign/joinPostcardCampaign";
import PostcardCampaignConfirmation from "../../containers/postcardCampaign/campaignConfirmation/PostcardCampaignConfirmation";

export default function ParticipantRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}/join-campaign/:campaignId`}
        component={JoinCampaignPage}
      />
      <Route
        exact
        path={`${path}/join-postcard-campaign/:campaignId`}
        component={JoinPostcardCampaign}
      />

      <Route exact path={`${path}/feedback`} component={ParticipantFeedback} />
      <Route
        exact
        path={`${path}/confirm`}
        component={JoinCampaignConfirmationPage}
      />
      <Route
        exact
        path={`${path}/postcard-confirm`}
        component={PostcardCampaignConfirmation}
      />
      <Redirect to={`${process.env.PUBLIC_URL}/404`} />

      <Redirect to={`/dashboard`} />
    </Switch>
  );
}
