import React, { useState, useEffect } from "react";

import GridViewCampaignCard from "./GridViewCampaignCard";
import ListViewCampaignCard from "./ListViewCampaignCard";
import TablePagination from "../../../../components/tables/TablePagination";
import NoDataFound from "../../../../components/NoDataFound";

function CampaignList({
  campaigns,
  pageCount,
  onArchived,
  viewType = "list",
  currentPage,
  onPageChange,
  isLoading,
}) {
  const [campaignData, setCampaignData] = useState([]);

  useEffect(() => {
    if (!Array.isArray(campaigns && campaigns)) {
      setCampaignData([]);
    } else {
      setCampaignData(campaigns && campaigns);
    }
  }, [campaigns && campaigns]);

  const renderListView = () => {
    return (
      <div className="row align-items-stretch list-view">
        {campaignData.map((campaign) => (
          <div className="col-12 p-2 my-lg-2" key={campaign.id}>
            <ListViewCampaignCard
              campaign={campaign}
              key={campaign.id}
              onArchived={onArchived}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderGridView = () => {
    return (
      <div className="row align-items-stretch grid-view ">
        {campaignData.map((campaign) => (
          <div className="col-xl-4 col-md-6 p-2 my-2" key={campaign.id}>
            <GridViewCampaignCard
              campaign={campaign}
              key={campaign.id}
              onArchived={onArchived}
            />
          </div>
        ))}
      </div>
    );
  };

  if (isLoading) {
    return <NoDataFound className="my-5" message="Loading..." />;
  }

  return (
    <div className="mt-4">
      {viewType === "list" ? renderListView() : renderGridView()}
      <div className="row">
        <div className="col-12 float-right">
          <TablePagination
            page={currentPage}
            count={pageCount}
            onChange={(event, page) => onPageChange(event, page)}
            className="float-right"
          />
        </div>
      </div>
    </div>
  );
}
export default CampaignList;
