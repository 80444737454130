import React, { useContext, useState, useEffect } from "react";
import { Formik, Field } from "formik";

import {
  textMDInputFormik,
  textAreaMDInputFormik,
} from "../../helpers/formInputs";
import { campaignService } from "../../services/campaignService";
import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import { creativeFeedbackValidation } from "../../helpers/validations";
import { logger } from "../../helpers/loggerHelper";
/**
 * Request Creative: When creatives need to be altered/modified or requesting for more creative
 */
const ParticipantFeedback = ({
  creativeId,
  creativeName,
  campaign,
  close,
  location,
}) => {
  const { loggedInUser } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);

  const [sending, setSending] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);

  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = async (values) => {
    try {
      setSending(true);
      const feedbackData = {
        fk_participant_id: loggedInUser.participantData?.id,
        email_id: loggedInUser.email,
        name: `${loggedInUser.first_name} ${loggedInUser.last_name}`,
        creative_id: creativeId.toString(),
        creative_name: values.creative_name,
        remark: values.feedback,
        fk_campaign_id: `${campaign?.id}`,
        system_generated_campaign_id: campaign?.system_generated_campaign_id,
        campaign_name: campaign?.campaign_name,
        fk_location_id: location.id,
      };
      const res = await campaignService.sendCreativeFeedback(feedbackData);

      if (res.status === 200) {
        setSendSuccess(true);
        setTimeout(() => {
          close();
        }, 5000);
      }
    } catch (err) {
      logger.error("onSubmitForm error", err);
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    loader(sending);
  }, [sending, loader]);

  return (
    <div className="participant-feedback p-2">
      <div className="card my-3">
        <div className="card-body px-1 px-sm-2">
          {sendSuccess ? (
            <h4 className="text24M_SemiBold00000b fs-14 text-center">
              Thank you for your feedback. We will contact you soon.
            </h4>
          ) : (
            <div className="row">
              <div className="col-md-4 text-center">
                <h4 className="text24M_SemiBold00000b fs-14">
                  Please share your feedback
                </h4>
                <img
                  className="mw-100 mx-auto mt-3"
                  src={`${process.env.PUBLIC_URL}/img/feedback.png`}
                  alt=""
                />
              </div>
              <div className="col-md-8">
                <Formik
                  initialValues={{
                    creative_name: creativeName || "",
                    feedback: "",
                  }}
                  onSubmit={onSubmitForm}
                  validate={creativeFeedbackValidation}
                >
                  {({ handleSubmit, isValid, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Field
                          name="creative_name"
                          component={textMDInputFormik}
                          label="Creative Name"
                          shrink={true}
                          disabled={values.creative_name ? true : false}
                        />
                        <Field
                          name="feedback"
                          component={textAreaMDInputFormik}
                          label="Description/ Change Required"
                          shrink={true}
                          autoFocus
                        />
                        <button
                          type="submit"
                          disabled={sending || !isValid}
                          className="button primary small float-right"
                        >
                          Submit
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParticipantFeedback;
