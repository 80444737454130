import React, { memo } from "react";
import { STATIC_IMAGES } from "../helpers/staticImages";

const RpConfirm = ({ message = "", heading = "Are you sure?", children }) => {
  return (
    <div className="rp-confirm">
      <div className="img-box">
        <img src={STATIC_IMAGES.infoWhite} alt="Info" className="img-fluid" />
      </div>
      <div>
        {message ? (
          <>
            <h4 className="heading">{heading}</h4>
            <p>{message}</p>
          </>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default memo(RpConfirm);
