import React, { useMemo } from "react";
import TableServer from "../../../components/tables/TableServer";
import { getFormatDate } from "../../../helpers/utils";
import { CurrencyFormateText } from "../../../components/CurrencyFormat";
import DownloadUserSubscriptionDetail from "./DownloadUserSubscriptionsDetail";
import { MUICustomAutocompleteSelect } from "../../../components/muiCustom/select/MUICustomAutocompleteSelect";
import StatusChip from "../../../components/muiCustom/chip/StatusChip";
import { subscriptionPlanLength } from "../../../config/constants";
import PageHeader from "../../../components/layouts/pageHeaders/PageHeader";

export default function UserSubscriptions({
  isLoading,
  locationData,
  groupList = [],
  fetchData,
  filtersData,
  is_brand_auto_renew_on,
}) {
  const { locations = [], totalLocationCount = 0 } = locationData || {};

  const pageCount = useMemo(
    () => Math.ceil(totalLocationCount / 10) || 0,
    [totalLocationCount]
  );

  const columns = useMemo(() => {
    const allColumns = [
      {
        Header: "Location",
        accessor: "location_name",
        Cell: ({ row: { original } }) => (
          <span className="text-wrap user-select-all fw-b">
            {original.location_name || "N/A"}
          </span>
        ),
      },
      {
        Header: "Participant",
        accessor: "participant_name",
        Cell: ({ row: { original } }) => (
          <span className="text-wrap user-select-all">
            {original.participant_name || "N/A"}
          </span>
        ),
      },
      {
        Header: "Media Plan Start",
        accessor: "current_subscription_start_date",
        width: 120,
        Cell: ({ row: { original } }) =>
          original.current_subscription_start_date || "N/A",
      },
      {
        Header: "Media Plan End",
        accessor: "current_subscription_end_date",
        width: 115,
        Cell: ({ row: { original } }) =>
          original.current_subscription_end_date || "N/A",
      },
      {
        Header: "Media Plan",
        accessor: "current_subscription_package",
        width: 130,
        Cell: ({ row: { original } }) =>
          original.current_subscription_package || "N/A",
      },
      {
        Header: "Monthly Media",
        accessor: "monthly_package_media",
        width: 100,
        Cell: ({ row: { original } }) =>
          original.monthly_package_media ? (
            <CurrencyFormateText value={original.monthly_package_media || 0} />
          ) : (
            "N/A"
          ),
      },
      {
        Header: "Upcoming Media Plan Start",
        accessor: "upcoming_subscription_start_date",
        width: 120,
        Cell: ({ row: { original } }) =>
          original.upcoming_subscription_start_date || "N/A",
      },
      {
        Header: "Upcoming Media Plan End",
        accessor: "upcoming_subscription_end_date",
        width: 115,
        Cell: ({ row: { original } }) =>
          original.upcoming_subscription_end_date || "N/A",
      },
      {
        Header: "Upcoming Media Plan",
        accessor: "upcoming_subscription_subscription_package",
        width: 130,
        Cell: ({ row: { original } }) =>
          original.upcoming_subscription_subscription_package || "N/A",
      },
      {
        Header: "Advertising Status",
        accessor: "subscription_status",
        width: 100,
        Cell: ({ row: { original } }) =>
          original.subscription_status ? (
            <StatusChip
              label={original.subscription_status}
              status={
                original.subscription_status === "Active" ? "info" : "warn"
              }
            />
          ) : (
            "N/A"
          ),
      },
      {
        Header: "Plan Status",
        accessor: "is_subscribed",
        width: 130,
        Cell: ({ row: { original } }) => {
          const { is_subscribed } = original;

          if (is_subscribed === 4) {
            return <StatusChip label={"Past Due"} status={"error"} />;
          }
          return (
            <StatusChip
              label={is_subscribed ? "Active" : "Inactive"}
              status={is_subscribed ? "success" : "error"}
            />
          );
        },
      },
      {
        Header: "Auto-Renew Status",
        accessor: "opt_out_flag",
        width: 130,
        Cell: ({ row: { original } }) => {
          const { opt_out_flag } = original;
          return (
            <StatusChip
              label={opt_out_flag ? "Auto-Renew OFF" : "Auto-Renew ON"}
              status={opt_out_flag ? "error" : "arStatus"}
            />
          );
        },
      },
    ];
    if (!is_brand_auto_renew_on) {
      return allColumns.filter((column) => column.accessor !== "opt_out_flag");
    }
    return allColumns;
  }, [is_brand_auto_renew_on]);

  const data = useMemo(() => {
    return locations.map((l) => {
      const locData = { ...l };
      // formatting dates
      locData.current_subscription_start_date = getFormatDate(
        l.current_subscription_start_date
      );
      locData.current_subscription_end_date = getFormatDate(
        l.current_subscription_end_date
      );

      locData.upcoming_subscription_start_date = getFormatDate(
        l.upcoming_subscription_start_date
      );
      locData.upcoming_subscription_end_date = getFormatDate(
        l.upcoming_subscription_end_date
      );

      locData.subscription_status =
        l.subscription_status === 1 ? "Active" : "Inactive";

      locData.is_subscribed =
        Number(l.subscription_status) === 4
          ? 4
          : l.subscription_status === 1 || l.is_upcoming_subscription === 1
          ? true
          : false;

      locData.locUniqId = l.defined_unique_id
        ? l.defined_unique_id
        : l.system_generated_location_id;

      return locData;
    });
  }, [locations]);

  const subscriptionStatusOptions = useMemo(() => {
    const options = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 0 },
      { label: "Upcoming Renewal", value: 2 },
      { label: "Renewed", value: 3 },
      { label: "Past Due", value: 6 },
    ];

    if (is_brand_auto_renew_on) {
      options.push(
        { label: "Auto-Renew ON", value: 4 },
        { label: "Auto-Renew OFF", value: 5 }
      );
    }
    return options;
  }, [is_brand_auto_renew_on]);

  const subscriptionLengthOptions = useMemo(() => {
    return subscriptionPlanLength.map((length) => ({
      label: `${length} Month`,
      value: length.toString(),
    }));
  }, [subscriptionPlanLength]);

  const filters = (setFilter, selectedValues) => {
    // Should be defined in tha parent, but for the time being defining here
    return (
      <>
        <div className="col-xl col-lg-8 px-4 px-xl-1 pt-2 px-lg-auto mb-md-2 mb-lg-0 d-flex align-items-end justify-content-end d-lg-none">
          {locationData?.locations?.length ? (
            <DownloadUserSubscriptionDetail filters={filtersData} />
          ) : null}
        </div>
        <div className="col-xl col-lg-4 mb-2 pr-xl-1">
          <MUICustomAutocompleteSelect
            options={groupList?.map((g) => ({
              label: g.group_name,
              value: g.id,
            }))}
            selectProps={{
              onChange: (v) => {
                setFilter("groupKey", v);
              },
              value: selectedValues.groupKey,
            }}
            name="location-group"
            label="Groups"
            all
          />
        </div>
        <div className="col-xl col-lg-4 mb-2 px-xl-1">
          <MUICustomAutocompleteSelect
            options={subscriptionStatusOptions}
            selectProps={{
              onChange: (v) => {
                setFilter("statusKey", v);
              },
              value: selectedValues.statusKey,
            }}
            name="location-status"
            label="Status"
            all
          />
        </div>
        <div className="col-xl col-lg-4 mb-2 px-xl-1">
          <MUICustomAutocompleteSelect
            options={subscriptionLengthOptions}
            selectProps={{
              onChange: (v) => {
                setFilter("subsLenKey", v);
              },
              value: selectedValues.subsLenKey,
            }}
            name="location-subscription-length"
            label="Length of Plan"
            all
          />
        </div>
        <div className="col-xl col-lg-9 px-4 px-xl-1 pt-2 px-lg-auto mb-md-2 mb-lg-0 d-lg-flex align-items-end d-none">
          {locationData?.locations?.length ? (
            <DownloadUserSubscriptionDetail filters={filtersData} />
          ) : null}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row align-items-center mb-4 ml-1">
        {/* <div className="col text-left">
          <Typography
            component="h2"
            variant="body2"
            className="font-weight-bold ml-2"
            gutterBottom
            style={{
              borderLeft: "4px solid #04a999",
              paddingLeft: 5,
            }}
          >
            Your Subscriptions
          </Typography>
        </div> */}
        <PageHeader title="Your Plan" showHr={false} />
      </div>

      <div>
        <TableServer
          columns={columns}
          data={data}
          showSearch={true}
          showFilter
          filters={filters}
          fetchData={fetchData}
          isLoading={isLoading}
          pageCount={pageCount}
          totalRecords={
            locationData?.totalLocationCount ? totalLocationCount : 0
          }
        />
      </div>
    </>
  );
}
