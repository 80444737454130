import React, { useEffect, useState, useMemo, useContext } from "react";
import { Typography } from "@material-ui/core";

import JoinedCampaigns from "../containers/participant/dashboard/JoinedCampaigns";
import PageLayout from "../components/layouts/page/PageLayout";
import { campaignService } from "../services";
import useFetcher from "../helpers/hooks/useFetcher";
import { LoaderContext, GlobalContext } from "../helpers/contexts/contexts";
import MuiSearchInput from "../components/muiCustom/search/MuiSearchInput";

const DigitalReportsList = () => {
  const loader = useContext(LoaderContext);
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);

  const [filters, setFilters] = useState({
    pageIndex: 0,
    searchKey: "",
    sortBy: "",
    sortByDir: "",
  });

  const [pageCount, setPageCount] = useState(0);

  const handleSearchChange = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchKey: value,
      pageIndex: 0,
    }));
  };

  const handlePageChange = (event, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      pageIndex: value - 1,
    }));
  };

  const reqData = useMemo(() => {
    return {
      id: selectedBrand?.id,
      userId: loggedInUser?.participantData?.id,
      page: filters.pageIndex + 1,
      searchKey: filters.searchKey || "",
      sortBy: filters.sortBy || "",
      sortByDir: filters.sortByDir || "",
      fk_participant_id: loggedInUser?.participantData?.id,
    };
  }, [selectedBrand?.id, loggedInUser?.participantData?.id, filters]);

  const { data, isLoading, isValidating } = useFetcher(
    reqData,
    campaignService.getCampaignReportListing,
    { revalidateOnFocus: false }
  );

  const recordsTotal = data?.recordsTotal || 0;

  useEffect(() => {
    if (data) {
      setPageCount(data.pageCount || 0);
    }
  }, [data]);

  useEffect(() => {
    loader(isLoading || isValidating);
  }, [isLoading, loader, isValidating]);

  const renderPage = () => (
    <>
      <div className="dashboard participant-dashboard">
        <Typography variant="h5" className="widget-title mb-4 mb-md-auto">
          Digital Campaign Reports
        </Typography>

        <div className="row">
          <div className="col-lg-4 offset-lg-8">
            <div className="search-container">
              <MuiSearchInput
                value={filters.searchKey}
                onChange={handleSearchChange}
                name="searchKey"
              />
            </div>
          </div>
        </div>
        <JoinedCampaigns
          joinedCampaigns={data?.result || []}
          pageCount={pageCount}
          pageIndex={filters.pageIndex}
          onPageChange={handlePageChange}
          recordsTotal={recordsTotal}
          isLoading={isLoading}
        />
      </div>
    </>
  );

  return (
    <PageLayout className="participant-dashboard-container mx-0 mx-sm-2">
      {renderPage()}
    </PageLayout>
  );
};

export default DigitalReportsList;
