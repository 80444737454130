import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const StyledButton = withStyles(() => ({
  root: {
    fontSize: 9,
    border: "#F5239C",
    background: "#F5239C",
    padding: "9px 32px",
    color: "#fff!important",
    "&:hover": {
      background: "#F5239C",
      color: "#fff!important",
    },
  },

  endIcon: {
    marginLeft: 3,
    icons: {
      fontSize: 10,
    },
  },
}))(Button);

export default function DownloadButton({
  to = {},
  text = "Download Template",
  ...rest
}) {
  return (
    <StyledButton
      component={Link}
      to={to}
      color="secondary"
      size="small"
      disableElevation
      {...rest}
    >
      {text}
    </StyledButton>
  );
}
