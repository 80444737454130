import React, { useContext, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import RpMUIModal from "../../../components/modals/RpMUIModal";
import { useHistory } from "react-router";
import { subscriptionService } from "../../../services/subscriptionService";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import { logger } from "../../../helpers/loggerHelper";

export default function ContactToWantToSpendMore() {
  const { loggedInUser } = useContext(GlobalContext);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const contact = async () => {
    setLoading(true);
    try {
      const res = await subscriptionService.clickHerePackageEmail({
        email: loggedInUser.email,
      });
      if (res.status === 200) {
        setSendEmail(true);
        // setTimeout(() => {
        //   history.replace("/dashboard");
        // }, 5000);
      }
    } catch (err) {
      logger.error("Error in clickHerePackageEmail", { err });
    } finally {
      setLoading(false);
    }
  };

  const handleOnClose = () => {
    if (loading) return;
    setOpen(false);
  };

  const handleOnOK = () => {
    if (loading) return;
    history.replace("/dashboard");
  };

  const renderThankYou = () => {
    return (
      <div className="text-center my-4">
        <Typography color="primary" variant="h5" gutterBottom>
          Thank You
        </Typography>
        <Typography
          color="secondary"
          variant="body1"
          className="font-weight-bold"
        >
          A Hyperlocologist will be in contact shortly.
        </Typography>

        <div className="mt-4 text-center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnOK}
            className="mx-auto"
            fullWidth
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Button
        color="primary"
        className="text-capitalize font-weight-bold"
        onClick={() => setOpen(true)}
      >
        Click here
      </Button>

      <RpMUIModal open={open} onClose={handleOnClose} maxWidth="xs" persists>
        {sendEmail ? (
          renderThankYou()
        ) : (
          <div>
            <Typography color="primary" variant="h6" gutterBottom>
              The plans are designed by your brand team to maximize your
              returns. The $300 monthly plan is highly recommended based on an
              analysis of past performance.
              <br />
              If you still need a different plan, hit send now and we'll get in
              touch.
            </Typography>

            {loading && (
              <Typography
                color="secondary"
                variant="subtitle2"
                className="my-3"
              >
                Sending request..
              </Typography>
            )}

            <div className="mt-4 d-flex align-items-center justify-content-center">
              <Button
                variant="outlined"
                onClick={handleOnClose}
                className="mr-2"
                color="primary"
                fullWidth
              >
                Don't send
              </Button>
              <Button
                variant="contained"
                onClick={contact}
                color="primary"
                fullWidth
              >
                Send
              </Button>
            </div>
          </div>
        )}
      </RpMUIModal>
    </>
  );
}
