import React, { useContext, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { GlobalContext } from "../../helpers/contexts/contexts";
import { reportService } from "../../services/reportService";
import { DRILL_LEVELS, FILTERS } from "../../config/constants";
import { isDateRangeOutOfBounds } from "../../helpers/drillUpDownHelper";
import { CircularProgress, Typography } from "@material-ui/core";
import { logger } from "../../helpers/loggerHelper";

const DrillControl = ({
  drillLevel,
  onDrillChange,
  widgetId,
  values,
  initialRange,
  setDrillLevel,
}) => {
  const { campaignSysId } = useParams();
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);
  const [isDrillDownDisabled, setIsDrillDownDisabled] = useState(false);
  const [isDrillUpDisabled, setIsDrillUpDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (year, month, day) =>
    `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

  const formatRange = (range) => {
    if (range && "from" in range && "to" in range) {
      return {
        from: formatDate(range.from.year, range.from.month, range.from.date),
        to: formatDate(range.to.year, range.to.month, range.to.date),
      };
    }
    return null;
  };

  useEffect(() => {
    setIsDrillUpDisabled(
      drillLevel === DRILL_LEVELS.YEAR ||
        (drillLevel === DRILL_LEVELS.QUARTER &&
          values.filter === FILTERS.LAST_QUARTER)
    );
  }, [drillLevel, values.filter]);

  useEffect(() => {
    if (setDrillLevel && drillLevel !== DRILL_LEVELS.MONTH) {
      setDrillLevel(DRILL_LEVELS.MONTH);
    }
  }, [values.filter, setDrillLevel]);

  useEffect(() => {
    if (
      values.filter === FILTERS.LAST_QUARTER &&
      drillLevel === DRILL_LEVELS.YEAR
    ) {
      if (typeof setDrillLevel === "function") {
        setDrillLevel(DRILL_LEVELS.MONTH);
      }
    }
  }, [values.filter, drillLevel, setDrillLevel]);

  useEffect(() => {
    setIsDrillDownDisabled(
      drillLevel === DRILL_LEVELS.DAY ||
        isDateRangeOutOfBounds(values, initialRange, drillLevel)
    );
  }, [values.filter, drillLevel, values.customRange, initialRange]);

  const getRequestData = useCallback(
    (drillType) => {
      const formattedRange =
        values.filter === FILTERS.CUSTOM && values.customRange
          ? { from: values.customRange[0], to: values.customRange[1] }
          : formatRange(initialRange);

      const reqData = {
        system_generated_campaign_id: `${campaignSysId}`,
        fk_brand_id: selectedBrand.id,
        fk_participant_id: loggedInUser?.participantData?.id || 0,
        participant_system_id:
          loggedInUser?.participantData?.system_generated_participant_id || "",
        userId: loggedInUser.id,
        fk_role_id: loggedInUser.fk_role_id,
        regions_id: values?.region_id || "",
        group_id: values?.group_id || "",
        location_id: values?.location_id || "",
        channel_id: values?.channel_id || "",
        filter: values?.filter,
        widgetId: widgetId,
        drill_type: drillType,
        start_period: formattedRange?.from,
        end_period: formattedRange?.to,
      };
      return reqData;
    },
    [
      campaignSysId,
      initialRange,
      selectedBrand,
      loggedInUser,
      values,
      formatRange,
      widgetId,
    ]
  );

  const handleDrillChange = useCallback(
    async (drillType) => {
      setIsLoading(true);
      const reqData = getRequestData(drillType);
      try {
        const response = await reportService.getWidgetDrillUpDownData(reqData);
        let newData = response?.data?.data || [];
        onDrillChange(drillType, newData);
      } catch (error) {
        logger.error("handleDrillChange error", error);
      } finally {
        setIsLoading(false);
      }
    },
    [getRequestData, onDrillChange]
  );

  const handleDrill = useCallback(
    (direction) => {
      let drillType;
      switch (drillLevel) {
        case DRILL_LEVELS.YEAR:
          drillType = direction === "down" ? DRILL_LEVELS.QUARTER : null;
          break;
        case DRILL_LEVELS.QUARTER:
          drillType =
            direction === "down" ? DRILL_LEVELS.MONTH : DRILL_LEVELS.YEAR;
          break;
        case DRILL_LEVELS.MONTH:
          drillType =
            direction === "down" ? DRILL_LEVELS.DAY : DRILL_LEVELS.QUARTER;
          break;
        case DRILL_LEVELS.DAY:
          drillType = direction === "up" ? DRILL_LEVELS.MONTH : null;
          break;
        default:
          return;
      }
      if (drillType) {
        handleDrillChange(drillType);
      }
    },
    [drillLevel, handleDrillChange, values.filter]
  );

  const getCurrentDrillType = () => {
    switch (drillLevel) {
      case DRILL_LEVELS.YEAR:
        return "Year";
      case DRILL_LEVELS.QUARTER:
        return "Quarter";
      case DRILL_LEVELS.MONTH:
        return "Month";
      case DRILL_LEVELS.DAY:
        return "Day";
      default:
        return "";
    }
  };
  return (
    <>
      <div className="drill-button-container">
        {isLoading ? (
          <CircularProgress size={10} className="mui-loader" />
        ) : (
          <Typography
            className="mui-loader fs-10"
            variant="caption"
            color="primary"
          >
            {getCurrentDrillType()}
          </Typography>
        )}

        <button
          className={`drill-button ${isDrillUpDisabled ? "disabled" : ""}`}
          onClick={() => handleDrill("up")}
          disabled={isDrillUpDisabled}
        >
          <ArrowUpwardIcon />
        </button>
        <button
          className={`drill-button ${isDrillDownDisabled ? "disabled" : ""}`}
          onClick={() => handleDrill("down")}
          disabled={isDrillDownDisabled}
        >
          <ArrowDownwardIcon />
        </button>
      </div>
    </>
  );
};

export default DrillControl;
