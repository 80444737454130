import React, { useContext, useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { Icon } from "@material-ui/core";
import { LoaderContext } from "../../helpers/contexts/contexts";
import MuiDarkButton from "../muiCustom/buttons/MuiDarkButton";
import pdfDownloadHelper from "../../helpers/pdfDownloadHelper";

Chart.defaults.global.animation.duration = 0;

const PDFDownloadButton = ({ elId, label, fileName = "report" }) => {
  const loader = useContext(LoaderContext);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    loader(processing);
  }, [processing, loader]);

  return (
    <MuiDarkButton
      onClick={() => {
        setProcessing(() => 1);
        setTimeout(() => {
          pdfDownloadHelper(processing, setProcessing, elId, fileName);
        }, 1000);
      }}
      startIcon={<Icon>file_download</Icon>}
      variant="contained"
      className="text-capitalize pdf-download-button"
      disableRipple
      fullWidth
      disableElevation
    >
      {label}
    </MuiDarkButton>
  );
};

export default PDFDownloadButton;
