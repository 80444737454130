import React, { useMemo } from "react";
import { Button, Icon, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import Location from "./Location";
import { joinCampaignHelper } from "../../../../helpers/joinCampaignHelper";
import NotInterestedButton from "../../../../components/joinCampaigns/NotInterestedButton";
import LocationAdvancedSetting from "./LocationAdvancedSetting";
import useSearchFilter from "../../../../helpers/hooks/useSearchFilter";
// import MuiSearchInput from "../../../../components/muiCustom/search/MuiSearchInput";
import RpVirtualList from "../../../../components/virtual/RpVirtualList";

export default function SubscribedLocations({
  campaign,
  values,
  subscribedLocations = [],
  isSubmitting,
  setFieldValue,
  setFieldError,
  onSaveAsDraft,
  productList,
  getAdvanceSettingData,
  fillAdvancedSetting,
  setFillAdvancedSetting,
  isAdvancedSettingsFormValid,
  handleOnChangeAdvancedSettingError,
  expanded,
  setExpanded,
}) {
  const { campaign_advanced_setting } = campaign;

  const filledLocations = useMemo(() => {
    const { locIds } = joinCampaignHelper.getValidLocations(values, campaign);

    return subscribedLocations.filter((l) => locIds.includes(String(l.id)));
  }, [values, campaign, subscribedLocations]);

  const [, , filteredSubscribedLocations] = useSearchFilter(
    subscribedLocations,
    ["location_name"]
  );

  const getTotal = useMemo(() => {
    // getting total of only fully filled locations
    return joinCampaignHelper
      .getTotal(joinCampaignHelper.getValidLocations(values, campaign)?.locs)
      .toFixed(2);
  }, [values, campaign]);

  const madeSelectionsIn = useMemo(() => {
    return joinCampaignHelper.getValidLocations(values, campaign)?.locIds
      ?.length;
  }, [values, campaign]);

  if (!subscribedLocations || subscribedLocations.length === 0) {
    return (
      <div className="my-5 text-center">
        <Alert severity="info">
          <strong>There is no active location.</strong>
        </Alert>
      </div>
    );
  }

  return (
    <div>
      {/* {fillAdvancedSetting === false && (
        <div className="row mb-3">
          <div className="col-md-4">
            <MuiSearchInput value={searchKey} onChange={handleSearchChange} />
          </div>
        </div>
      )} */}

      {fillAdvancedSetting === true && (
        <Typography variant="h6" className="mb-3">
          Advanced Settings
        </Typography>
      )}

      <div className="pb-5">
        {fillAdvancedSetting === false && (
          <RpVirtualList
            items={filteredSubscribedLocations}
            renderItem={(loc) => (
              <Location
                loc={loc}
                locFormData={values[loc.id]}
                key={loc.id}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                expanded={expanded}
                setExpanded={setExpanded}
                campaign={campaign}
                productList={productList}
                getAdvanceSettingData={getAdvanceSettingData}
              />
            )}
          />
        )}
      </div>
      <div className="pb-5">
        {fillAdvancedSetting === true && (
          <RpVirtualList
            items={filledLocations}
            renderItem={(loc) => (
              <LocationAdvancedSetting
                loc={loc}
                locFormData={values[loc.id]}
                key={loc.id}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                expanded={expanded}
                setExpanded={setExpanded}
                campaign={campaign}
                productList={productList}
                getAdvanceSettingData={getAdvanceSettingData}
                handleOnChangeAdvancedSettingError={
                  handleOnChangeAdvancedSettingError
                }
              />
            )}
          />
        )}
      </div>

      <div className="card total-budget-box mt-4">
        <div className="card-body">
          <div className="total-budget">
            <Typography
              className="mr-2 fw-b mb-2 mb-sm-0"
              component="span"
              variant="body2"
            >
              Your Campaign Total Budget
            </Typography>

            <Button
              component="span"
              variant="outlined"
              disableElevation
              disableRipple
              startIcon={<Icon component="img" src="/img/icons/money1.png" />}
            >{`$ ${getTotal}`}</Button>
          </div>
        </div>
      </div>
      {/* {!isEmpty(formError) && <RpSnackbar show message={formError.message} />} */}
      <div className="row justify-content-end align-items-center text-right my-4 actions">
        {subscribedLocations.length && (
          <div className="col-lg-5 mb-4 mb-lg-3">
            <Typography variant="caption">
              {`Selections complete for ${madeSelectionsIn} of ${subscribedLocations.length} locations.`}
            </Typography>
          </div>
        )}
        {campaign_advanced_setting?.is_required &&
          fillAdvancedSetting === false && (
            <>
              <div className="col-lg-2 mb-3 text-right">
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  disabled={isSubmitting}
                  aria-disabled={isSubmitting}
                  onClick={onSaveAsDraft}
                  type="submit"
                  fullWidth
                >
                  Save as Draft
                </Button>
              </div>
              <div className="col-lg-3 mb-3 text-right">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !isAdvancedSettingsFormValid}
                  aria-disabled={isSubmitting || !isAdvancedSettingsFormValid}
                  fullWidth
                  disableElevation
                  size="large"
                >
                  Next
                </Button>
              </div>
            </>
          )}

        {(!campaign_advanced_setting?.is_required ||
          fillAdvancedSetting === true) && (
          <>
            {campaign_advanced_setting?.is_required && (
              <div className="col-lg-2 mb-3 text-right">
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  disabled={isSubmitting}
                  aria-disabled={isSubmitting}
                  onClick={() => setFillAdvancedSetting(false)}
                  type="button"
                  fullWidth
                >
                  Back
                </Button>
              </div>
            )}
            <div className="col-lg-2 mb-3 text-right">
              <Button
                color="primary"
                size="large"
                variant="outlined"
                disabled={isSubmitting}
                aria-disabled={isSubmitting}
                onClick={onSaveAsDraft}
                type="submit"
                fullWidth
              >
                Save as Draft
              </Button>
            </div>
            <div className="col-lg-3 mb-3 text-right">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting || !isAdvancedSettingsFormValid}
                aria-disabled={isSubmitting || !isAdvancedSettingsFormValid}
                fullWidth
                disableElevation
                size="large"
              >
                Confirm Selections
              </Button>
            </div>
          </>
        )}
      </div>

      <div className="text-right link d-flex justify-content-end">
        <NotInterestedButton campaignId={campaign.id} disabled={isSubmitting} />
      </div>
    </div>
  );
}
