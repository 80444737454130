import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { getStatusCode } from "../../helpers/utils";
import { CurrencyFormateText } from "../../components/CurrencyFormat";
import { GlobalContext, LoaderContext } from "../../helpers/contexts/contexts";
import LearnMoreButton from "../../components/muiCustom/buttons/LearnMoreButton";
import StatusChip from "../../components/muiCustom/chip/StatusChip";
import RpImageTextInline from "../../components/RpImageTextInline";
import TableServer from "../../components/tables/TableServer";
import useFetcher from "../../helpers/hooks/useFetcher";
import { campaignService } from "../../services";

const PostcardCampaignList = ({
  // campaigns = [],
  showFilter = false,
  fromPage = "",
  extraData = {},
  hideSearchLabel = true,
  // fetchPostcardCampaignsReportData,
  // pageCount,
  // isLoading,
}) => {
  const { loggedInUserRole, loggedInUser, selectedBrand } =
    useContext(GlobalContext);
  const loader = useContext(LoaderContext);
  const [filters, setFilters] = useState({
    pageSize: 10,
    pageIndex: 0,
    searchKey: "",
    sortBy: "",
    sortByDir: "",
  });

  const reqData = useMemo(() => {
    return {
      id: selectedBrand?.id,
      userId: loggedInUser?.id,
      row: filters?.pageSize,
      page: filters?.pageIndex + 1,
      searchKey: filters?.searchKey || "",
      sortBy: filters?.sortBy || "",
      sortByDir: filters?.sortByDir || "",
      campaignCategory: "Postcard",
    };
  }, [selectedBrand?.id, loggedInUser?.id, filters]);

  const {
    data,
    isLoading: loading,
    isValidating,
  } = useFetcher(reqData, campaignService.postcardReportListing, {
    revalidateOnFocus: false,
  });

  const postcardReportList = data?.joinedCampaign || [];
  const pageCount = data?.pageCount || 0;
  const recordsTotal = data?.recordsTotal || 0;

  useEffect(() => {
    loader(loading || isValidating);
  }, [loading, loader, isValidating]);

  const fetchPostcardCampaignsReportData = useCallback((data) => {
    setFilters({
      pageSize: 10,
      pageIndex: 0,
      searchKey: "",
      sortBy: "",
      sortByDir: "",
      ...data,
    });
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      fetchPostcardCampaignsReportData({
        pageSize: 10,
        pageIndex: 0,
        searchKey: "",
      });
    }
  }, [selectedBrand, fetchPostcardCampaignsReportData]);

  const PostcardCampaignList = useMemo(() => {
    if (
      [
        "sarah.dion@thetenspot.com",
        "angela.casullo@thetenspot.com",
        "jessica.scott@thetenspot.com",
      ].includes(loggedInUser.email)
    ) {
      // hard codded hide the campaign from these users
      return postcardReportList?.filter(
        (c) => c.system_generated_campaign_id !== "FTDX7289"
      );
    }
    return postcardReportList;
  }, [postcardReportList, loggedInUser]);

  const columns = useMemo(
    () => [
      {
        Header: "Campaign Name",
        accessor: "campaign_name",
        id: "campaign_name",
        width: 215,
        sortType: "string",
        Cell: ({ row }) => {
          const {
            original: {
              id,
              campaign_name,
              system_generated_campaign_id,
              participant_template,
              brand_owner_template,
              parent_company_template,
            },
          } = row;
          let searchQuery = "";

          if (
            fromPage &&
            fromPage === "location_report" &&
            extraData?.locationSysId
          ) {
            searchQuery = `?locationID=${extraData?.locationSysId}`;
          }

          const isTemplateExists =
            (loggedInUserRole === "PR" && participant_template) ||
            ((loggedInUserRole === "BO" || loggedInUserRole === "BU") &&
              brand_owner_template) ||
            (loggedInUserRole === "PC" && parent_company_template);

          const showLink = isTemplateExists;

          // if (fromPage !== "location_report" || Number(campaignBudget)) {
          return showLink ? (
            <Link
              to={{
                pathname: `${process.env.PUBLIC_URL}/dashboard/postcard-campaign-report/${system_generated_campaign_id}`,
                state: {
                  campaignId: id,
                  campaignSysId: system_generated_campaign_id,
                  from: "/dashboard",
                },
                search: searchQuery ? searchQuery : "",
              }}
              className="main-color link"
            >
              {campaign_name}
            </Link>
          ) : (
            campaign_name
          );
          // }

          // return <span className={`gray-color`}>{campaign_name}</span>;
        },
      },
      {
        Header: "Status",
        accessor: (d) =>
          d.campaign_status === "Active" ? "Live" : d.campaign_status,
        id: "campaign_status",
        width: 200,
        Cell: ({ row }) => {
          const {
            original: { campaign_status },
          } = row;

          const camp_status =
            campaign_status === "Active" ? "Live" : campaign_status;

          return (
            <StatusChip
              label={camp_status}
              status={getStatusCode(camp_status)}
            />
          );
        },
      },

      {
        Header: "Cost",
        accessor: "total_budget",
        width: 200,
        Cell: ({ row }) => {
          const {
            original: { total_budget },
          } = row;
          return (
            <span className="fw-b">
              {total_budget || total_budget === 0 ? (
                <CurrencyFormateText value={total_budget} />
              ) : (
                "N/A"
              )}
            </span>
          );
        },
      },
      {
        Header: "Scheduled Date",
        accessor: "schedule_date", // Assuming you have the start date in your data
        id: "schedule_date",
        // sortType: "datetime",
        width: 260,
        Cell: ({ row }) => {
          const {
            original: { start_date, schedule_date }, // Make sure to have end_date in your data
          } = row;

          const getScheduleDates = (campaign) => {
            const start = moment(campaign?.schedule_date);
            const end = start.isValid() ? moment(start).add(4, "day") : null;
            if (campaign?.schedule_date && start.isValid()) {
              return `${start.format("M/D/YYYY")} - ${end.format("M/D/YYYY")}`;
            }
            return "NA";
          };

          const dateRangeText = getScheduleDates({ schedule_date });

          return (
            <RpImageTextInline
              image={
                start_date !== "Evergreen" ? "/img/icons/calendar.png" : ""
              }
              text={dateRangeText}
            />
          );
        },
      },

      {
        Header: () => <span className="">FAQ</span>,
        id: "faq",
        width: 200,
        canSort: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <LearnMoreButton
              to={{
                pathname: `${process.env.PUBLIC_URL}/dashboard/learn-more/postcard-campaign/${original.id}`,
                state: { campaignId: original.id, from: "/location-report" },
              }}
            />
          );
        },
      },
    ],
    [loggedInUserRole, selectedBrand, fromPage, extraData]
  );

  // const filter = (setFilter, selectedFilters) => {
  //   // Should be defined in tha parent, but for the time being defining here
  //   return (
  //     <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
  //       <MUICustomAutocompleteSelect
  //         name="status"
  //         label="Status"
  //         selectProps={{
  //           onChange: (v) => {
  //             setFilter("status", v);
  //           },
  //           value: selectedFilters?.find((f) => f.id === "status")?.value || "",
  //         }}
  //         options={[
  //           { label: "Live", value: "Active" },
  //           { label: "Scheduled", value: "Scheduled" },
  //           { label: "Completed", value: "Completed" },
  //         ]}
  //         all
  //       />
  //     </div>
  //   );
  // };

  return (
    <div className="location-list">
      <TableServer
        columns={columns}
        data={PostcardCampaignList}
        showSearch={true}
        fetchData={fetchPostcardCampaignsReportData}
        isLoading={loading}
        pageCount={pageCount}
        showFilter={showFilter}
        // filters={filters}
        //showPageSize={true}
        // useLayout={selectedBrand.id !== 1 ? true : false}
        useLayout={false}
        hideSearchLabel={hideSearchLabel}
        totalRecords={recordsTotal}
      />
    </div>
  );
};

export default PostcardCampaignList;
