import React, { useContext, useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { authActions } from "../../../../actions";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { GlobalContext } from "../../../../helpers/contexts/contexts";
import { settingSelector } from "../../../../selectors/settingSelectors";
import RpImageTextInline from "../../../RpImageTextInline";
import useCustomerPortal from "../../../../helpers/hooks/useCustomerPortal";
import Feedback from "../../../../containers/Feedback";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "flex",
  // },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
      // top: "none !important",
    },
  },
  appBar: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: "#fbfbfb",
    display: "flex",
    // flexDirection: "row",
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    color: theme.palette.primary.main,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    border: "none",
    boxShadow: theme.shadows[3],
    backgroundColor: "#102D39",
    color: "#bfc6ca",
    padding: "0px 9px",
  },
  listItem: {
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "#0f3e47",
    },
    marginBottom: "5px",
    borderRadius: "10px",
  },
  activeListItem: {
    backgroundColor: "#0f3e47",
    color: theme.palette.primary.main,
    fontWeight: "700",
  },
}));

const CREATE_CAMPAIGN_ALLOWED_BRAND = ["BD845257", "BD577784"];
function ResponsiveDrawer({ mobileOpen, handleDrawerToggle, setMobileOpen }) {
  const classes = useStyles();
  const theme = useTheme();
  const { selectedBrand, loggedInUserRole } = useContext(GlobalContext);
  const settings = useSelector(settingSelector);
  const [FeedbackFormOpen, setFeedbackFormOpen] = useState(false);

  const { navigateToCustomerPortal } = useCustomerPortal();

  const handleOpenDialog = useCallback(() => {
    setFeedbackFormOpen(true);
    setMobileOpen(false);
  }, [setFeedbackFormOpen]);

  const handleCloseDialog = useCallback(() => {
    setFeedbackFormOpen(false);
  }, [setFeedbackFormOpen]);

  const drawer = (
    <div className="side-nav">
      <div className={classes.toolbar} style={{ marginTop: "-62px" }} />

      <List>
        <ListItem>
          <ListItemText
            className="user-role nav-sections"
            primary="Analytics"
          />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/dashboard"
          exact
          className={classes.listItem}
          activeClassName={classes.activeListItem}
        >
          <ListItemIcon>
            <RpImageTextInline image="/img/icons/dashboard_nav.svg" />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        {loggedInUserRole === "PR" ? (
          <>
            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/campaign-report"
              isActive={(match, location) =>
                location.pathname.includes("/campaign-report")
              }
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/camp_reports.svg" />
              </ListItemIcon>
              <ListItemText primary="Campaign Reports" />
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/postcard-report"
              isActive={(match, location) =>
                location.pathname.includes("/postcard-report") ||
                location.pathname.includes("postcard-campaign-report")
              }
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/direct_mail_reports.svg" />
              </ListItemIcon>
              <ListItemText primary="Direct Mail Reports" />
            </ListItem>
          </>
        ) : null}
        {loggedInUserRole === "PR" && (
          <>
            <ListItem>
              <ListItemText
                className="user-role nav-sections"
                primary="Launch Advertising"
              />
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              component={NavLink}
              to="/digital-campaigns"
              isActive={(match, location) =>
                location.pathname.includes("/digital-campaigns") ||
                location.pathname.includes("/join-campaign") ||
                location.pathname.includes("/participant/confirm")
              }
              activeClassName={classes.activeListItem}
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/file_nav.svg" />
              </ListItemIcon>
              <ListItemText primary="Digital Campaigns" />
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              component={NavLink}
              to="/postcard-campaigns"
              isActive={(match, location) =>
                location.pathname.includes("/postcard-campaigns") ||
                location.pathname.includes("/join-postcard-campaign") ||
                location.pathname.includes("/postcard-confirm")
              }
              activeClassName={classes.activeListItem}
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/file_nav.svg" />
              </ListItemIcon>
              <ListItemText primary="Direct Mail" />
            </ListItem>
          </>
        )}
        {loggedInUserRole === "BO" ||
        loggedInUserRole === "BU" ||
        loggedInUserRole === "PC" ? (
          <>
            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/campaign-report"
              isActive={(match, location) =>
                location.pathname.includes("/campaign-report") ||
                location.pathname.includes("/dashboard/campaign-report")
              }
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/camp_reports.svg" />
              </ListItemIcon>
              <ListItemText primary="Campaign Reports" />
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/postcard-report"
              isActive={(match, location) =>
                location.pathname.includes("/postcard-report") ||
                location.pathname.includes("postcard-campaign-report")
              }
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/direct_mail_reports.svg" />
              </ListItemIcon>
              <ListItemText primary="Direct Mail Reports" />
            </ListItem>

            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/location-reports"
              isActive={(match, location) =>
                location.pathname.includes("/location-reports") ||
                location.pathname.includes("/location-report")
              }
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/loc_reports.svg" />
              </ListItemIcon>
              <ListItemText primary="Location Reports" />
            </ListItem>
          </>
        ) : null}
        {/* </Collapse> */}
        {loggedInUserRole === "BO" || loggedInUserRole === "BU" ? (
          <ListItem>
            <ListItemText
              className="user-role nav-sections"
              primary="Subscription Management"
            />
          </ListItem>
        ) : null}
        {loggedInUserRole === "BU" ||
        loggedInUserRole === "BO" ||
        loggedInUserRole === "PC" ? (
          <ListItem
            button
            className={classes.listItem}
            activeClassName={classes.activeListItem}
            component={NavLink}
            to="/dashboard/profile"
            exact
          >
            <ListItemIcon>
              <RpImageTextInline image="/img/icons/person.svg" />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </ListItem>
        ) : null}

        {(loggedInUserRole === "BU" && selectedBrand?.id !== 26) ||
        loggedInUserRole === "BO" ? (
          <>
            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/dashboard/subscriptions"
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/subs_overview.svg" />
              </ListItemIcon>
              <ListItemText primary="Media Plan Overview" />
            </ListItem>
          </>
        ) : null}
        {(loggedInUserRole === "BU" || loggedInUserRole === "BO") &&
        selectedBrand?.has_active_subscription_page ? (
          <>
            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/subscription-packages"
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/add_subscription.svg" />
              </ListItemIcon>
              <ListItemText primary="Add Media Plan" />
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/manage-subscription"
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/current_subscription.svg" />
              </ListItemIcon>
              <ListItemText primary="Current Media Plan" />
            </ListItem>
          </>
        ) : null}
        {loggedInUserRole === "PR" && (
          <>
            <ListItem>
              <ListItemText
                className="user-role nav-sections"
                primary="Account Management"
              />
            </ListItem>
          </>
        )}
        {loggedInUserRole === "PR" ? (
          <ListItem
            button
            className={classes.listItem}
            activeClassName={classes.activeListItem}
            component={NavLink}
            to="/dashboard/profile"
            exact
          >
            <ListItemIcon>
              <RpImageTextInline image="/img/icons/person.svg" />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </ListItem>
        ) : null}

        {loggedInUserRole === "PR" &&
        selectedBrand?.has_active_subscription_page ? (
          <>
            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/subscription-packages"
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/add_subscription.svg" />
              </ListItemIcon>
              <ListItemText primary="Add Media Plan" />
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/manage-subscription"
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/current_subscription.svg" />
              </ListItemIcon>
              <ListItemText primary="Current Media Plan" />
            </ListItem>
          </>
        ) : null}

        {loggedInUserRole === "PR" && (
          <>
            {selectedBrand?.id !== 1 ? (
              <ListItem
                button
                className={classes.listItem}
                activeClassName={classes.activeListItem}
                component={NavLink}
                to="/subscription-invoice"
                exact
              >
                <ListItemIcon>
                  <RpImageTextInline image="/img/icons/current_subscription.svg" />
                </ListItemIcon>
                <ListItemText primary="Media Plan History" />
              </ListItem>
            ) : null}
            {selectedBrand?.id !== 1 && selectedBrand?.id !== 2 ? (
              <ListItem
                button
                className={classes.listItem}
                onClick={navigateToCustomerPortal}
                component="div"
              >
                <ListItemIcon>
                  <RpImageTextInline image="/img/icons/current_subscription.svg" />
                </ListItemIcon>
                <ListItemText primary="Manage Payments" />
              </ListItem>
            ) : null}
          </>
        )}
        {settings?.brandSettings?.has_brand_resource ? (
          <>
            <ListItem>
              <ListItemText
                className="user-role nav-sections"
                primary="Resources"
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              to={`${process.env.PUBLIC_URL}/education-center/${selectedBrand?.id}`}
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/resource-center.svg" />
              </ListItemIcon>
              <ListItemText primary="Education Center" />
            </ListItem>
          </>
        ) : null}

        {loggedInUserRole === "PR" && (
          <>
            <ListItem
              button
              className={classes.listItem}
              onClick={handleOpenDialog}
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/feedback.svg" />
              </ListItemIcon>
              <ListItemText primary="Feedback" />
            </ListItem>
          </>
        )}

        {(loggedInUserRole === "BO" || loggedInUserRole === "BU") &&
        selectedBrand?.is_brand_request ? (
          <>
            <ListItem
              button
              component={NavLink}
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              to={`${process.env.PUBLIC_URL}/requests`}
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/rock.svg" />
              </ListItemIcon>
              <ListItemText primary="Request" />
            </ListItem>
          </>
        ) : null}

        {/* {loggedInUserRole === "PR" &&
        selectedBrand?.has_active_subscription_page ? (
          <>
            <ListItem
              button
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              component={NavLink}
              to="/subscription-packages"
              isActive={(match, location) =>
                location.pathname.includes("/subscription-packages") ||
                location.pathname.includes("/manage-subscription")
              }
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/file_nav.svg" />
              </ListItemIcon>
              <ListItemText primary="My Subscription(s)" />
            </ListItem>
          </>
        ) : null} */}
        {CREATE_CAMPAIGN_ALLOWED_BRAND.includes(
          selectedBrand?.system_generated_brand_id
        ) && // displayed only for Bojangles brand
          (loggedInUserRole === "BO" || loggedInUserRole === "BU") && (
            <ListItem
              button
              component={NavLink}
              to="/create-campaign"
              className={classes.listItem}
              activeClassName={classes.activeListItem}
              exact
            >
              <ListItemIcon>
                <RpImageTextInline image="/img/icons/file_nav.svg" />
              </ListItemIcon>
              <ListItemText primary="Create Campaign" />
            </ListItem>
          )}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden lgUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <Feedback open={FeedbackFormOpen} handleClose={handleCloseDialog} />
    </>
  );
}

ResponsiveDrawer.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, {
  logout: authActions.logout,
})(ResponsiveDrawer);
