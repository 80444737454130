import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";

const AlertBootstrap = ({
  show = false,
  variant = "success",
  icon = "",
  children,
  className = "",
  dismissible = true,
  persist = true,
}) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (show) {
      setDisplay(true);
    }
    if (!persist) {
      setTimeout(() => {
        setDisplay(false);
      }, 5000);
    }
  }, [show]);

  return (
    <Alert
      show={display}
      variant={variant}
      dismissible={dismissible}
      className={className}
    >
      {icon ? icon : null}
      {children}
    </Alert>
  );
};

export default AlertBootstrap;
