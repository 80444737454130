import React from "react";
import { useFormikContext, useField } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormGroup, FormHelperText } from "@material-ui/core";

const RpMuiCheckboxGroup = ({ name, options, ...props }) => {
  const { setFieldValue, errors } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (option) => {
    const isChecked = field?.value?.includes(option) ? true : false;
    const newValues = isChecked
      ? field.value.filter((value) => value !== option)
      : [...field.value, option];

    setFieldValue(field.name, newValues);
  };

  return (
    <>
      <FormGroup>
        {options?.map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                color="primary"
                {...props}
                checked={field?.value?.includes(option) ? true : false}
                onChange={() => handleChange(option)}
              />
            }
            label={option}
          />
        ))}
      </FormGroup>
      {errors[name] ? (
        <FormHelperText className="text-danger">{errors[name]}</FormHelperText>
      ) : null}
    </>
  );
};

export default RpMuiCheckboxGroup;
