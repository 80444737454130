import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import { Redirect, Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

import { passwordMDInputFormik } from "../../helpers/formInputs";
import { isLoggedInSelector } from "../../selectors/authSelectors";
import { authActions } from "../../actions";
import {
  isPasswordUpdatedSelector,
  isValidRequestSelector,
  isErrorSelector,
  isLoadingSelector,
  validatedEmailSelector,
} from "../../selectors/resetPasswordSelectors";
import useQuery from "../../helpers/hooks/useQuery";
import LeftView from "../../components/auth/LeftView";
import { validPassConfPass } from "../../helpers/validations";
import MESSAGE from "../../helpers/messages";
import LoadingButton from "../../components/loaders/LoadingButton";

const ResetPassword = ({
  isLoggedIn,
  isLoading,
  isError,
  isValidRequest,
  validatedEmail,
  isPasswordUpdated,
  validatePwdResetRequest,
  resetPassword,
}) => {
  const query = useQuery();

  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = (values, { setSubmitting }) => {
    const data = {
      token: query.token,
      email: validatedEmail,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    resetPassword(data);
    setSubmitting(false);
  };

  useEffect(() => {
    const token = query.token;
    validatePwdResetRequest(token);
  }, []);

  // redirect to home when already logged in
  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  const renderValidatingOrErrorView = () => {
    return (
      <>
        {" "}
        <h4 className="heading8 text-center">
          {isError ? isError : "Validating your request..."}
        </h4>
        {isError && (
          <div className="d-flex justify-content-center my-4">
            <Link className="button primary px-4" to={"/"}>
              {" "}
              Log In{" "}
            </Link>
          </div>
        )}
      </>
    );
  };

  const renderPasswordUpdatedView = () => {
    return (
      <>
        <h4 className="heading8 text-center">{MESSAGE.passwordResetSuccess}</h4>
        <div className="d-flex justify-content-center my-4">
          <Link className="button primary px-5" to={"/"}>
            {" "}
            Log In{" "}
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className="row login-page">
      <div className="col-md-6 p-0">
        <LeftView />
      </div>
      <div className="col-md-6 d-flex flex-column justify-content-center px-md-5 py-sm-5">
        <div className="login-form">
          {!isValidRequest ? (
            renderValidatingOrErrorView()
          ) : isPasswordUpdated ? (
            renderPasswordUpdatedView()
          ) : (
            <>
              <Typography
                component="h2"
                variant="h4"
                className="fs-39"
                gutterBottom
              >
                Reset Password
              </Typography>
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                onSubmit={onSubmitForm}
                validate={validPassConfPass}
              >
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit} className="mt-4 mt-md-5">
                      <Field
                        name="password"
                        label="Password"
                        component={passwordMDInputFormik}
                        autoFocus={true}
                      />

                      <Field
                        name="confirmPassword"
                        label="Confirm Password"
                        component={passwordMDInputFormik}
                      />

                      <div className="form-group mt-2 d-flex justify-content-end">
                        <LoadingButton
                          type="submit"
                          className="primary"
                          text="Reset Password"
                          isLoading={isLoading}
                        />
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedInSelector(state),
  isLoading: isLoadingSelector(state),
  isError: isErrorSelector(state),
  isValidRequest: isValidRequestSelector(state),
  isPasswordUpdated: isPasswordUpdatedSelector(state),
  validatedEmail: validatedEmailSelector(state),
});

export default connect(mapStateToProps, {
  ...authActions,
})(ResetPassword);
