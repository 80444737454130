import React, { useMemo } from "react";
import { Button } from "@material-ui/core";

import PackageCard from "../packageSelection/PackageCard";
import { subscriptionHelper } from "../../../helpers/subscriptionHelper";
import { subscriptionPlanPaymentType } from "../../../config/constants";

export default function RenewPackageCard({
  plan,
  onSelectPackage,
  isSelected,
  location,
  setSelectedPackagePlan,
}) {
  const handleOnClick = () => {
    onSelectPackage(plan);
    setSelectedPackagePlan(plan);
  };

  const isUpcomingPackage = useMemo(() => {
    return location.upcoming_package_id === plan.id;
  }, [location, plan]);

  const isCurrentPlan = useMemo(() => {
    return location.fk_package_id === plan.id;
  }, [location, plan]);

  const isNewSubscription = useMemo(() => {
    return subscriptionHelper.checkIsNewSubscription(location);
  }, [location]);

  const isSubscriptionTakenFromAdmin = useMemo(() => {
    return (
      location?.current_package_payment_type !==
        subscriptionPlanPaymentType.INVOICE &&
      location?.current_package_payment_type !==
        subscriptionPlanPaymentType.STRIPE
    );
  }, [location]);

  const isDisabled = useMemo(() => {
    if (isNewSubscription) {
      if (isCurrentPlan) {
        return true;
      } else {
        return false;
      }
    }

    if (isSubscriptionTakenFromAdmin) {
      if (location?.brand_auto_renew && !plan?.eligible_for_renew) {
        return true;
      } else {
        if (isCurrentPlan || isUpcomingPackage) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (
      !isNewSubscription &&
      location?.brand_auto_renew &&
      !plan?.eligible_for_renew
    ) {
      // disabled plan having auto-off when brand level auto-renew is ON. This is not applicable for the new subscription
      return true;
    }

    if (
      location?.brand_auto_renew &&
      !location?.auto_renewal &&
      isCurrentPlan
    ) {
      // when opt-in we don't disabled the current plan
      return false;
    }

    return isUpcomingPackage || isCurrentPlan;
  }, [location, isUpcomingPackage, isCurrentPlan, isNewSubscription]);

  const renewPackageCardClassName = `renew-package-card h-100 w-100 ${
    isSelected ? "selected" : ""
  } ${isDisabled ? "disabled" : ""}`;

  return (
    <>
      <div
        className={renewPackageCardClassName}
        onClick={isDisabled ? null : handleOnClick}
      >
        <PackageCard plan={plan} location={location} />
      </div>

      <div className="row justify-content-center mt-4">
        <div className="col-10 col-lg-8 text-center">
          <Button
            color="primary"
            variant={isSelected ? "contained" : "outlined"}
            onClick={isDisabled ? null : handleOnClick}
            fullWidth
            disabled={isDisabled}
            className={isDisabled ? "disable-button" : ""}
          >
            {isUpcomingPackage ? "Selected" : "Select"}
          </Button>
        </div>
      </div>
    </>
  );
}
