import { resetPasswordConstants } from "../config/constants";

const initialState = {
  loading: false,
  error: null,
  isEmailSend: false,
  isValidRequest: false,
  isPasswordUpdated: false,
  email: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case resetPasswordConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        isEmailSend: false,
        isValidRequest: false,
        isPasswordUpdated: false,
      };
    case resetPasswordConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isEmailSend: true,
        isValidRequest: false,
        isPasswordUpdated: false,
      };
    case resetPasswordConstants.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isEmailSend: false,
        isValidRequest: false,
        isPasswordUpdated: false,
      };

    case resetPasswordConstants.VALIDATE_RESET_PASS_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        isEmailSend: false,
        isValidRequest: false,
        isPasswordUpdated: false,
        email: "",
      };
    case resetPasswordConstants.VALIDATE_RESET_PASS_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isEmailSend: false,
        isValidRequest: true,
        isPasswordUpdated: false,
        email: action.payload.email,
      };
    case resetPasswordConstants.VALIDATE_RESET_PASS_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isEmailSend: false,
        isValidRequest: false,
        isPasswordUpdated: false,
      };

    case resetPasswordConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        isEmailSend: false,
        isValidRequest: true,
        isPasswordUpdated: false,
      };
    case resetPasswordConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isEmailSend: false,
        isValidRequest: true,
        isPasswordUpdated: true,
      };
    case resetPasswordConstants.RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isEmailSend: false,
        isValidRequest: true,
        isPasswordUpdated: false,
      };

    case resetPasswordConstants.RESET_STATE:
      return { ...initialState };

    default:
      return state;
  }
};
