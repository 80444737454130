/** Application configuration constants */
// eslint-disable-next-line
console.log("ENV", process.env.REACT_APP_ENV);

export const IS_PROD = process.env.REACT_APP_ENV === "production";
export const IS_DEV = process.env.REACT_APP_ENV === "development";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_BASE_URL = BASE_URL + "/api";

export const API_BASE_URL_OPEN = `${API_BASE_URL}/open`;
export const API_BASE_URL_ADMIN = `${API_BASE_URL}/admin`;

export const STRIPE_MICRO_SERVICE_API_BASE_URL =
  process.env.REACT_APP_STRIPE_MICRO_SERVICE_API_BASE_URL + "/api/v1";

/** User Role **/
export const getUserRole = (id) => {
  switch (parseInt(id)) {
    case 1:
      return "SA"; // super admin
    case 2:
      return "PC"; // parent company user
    case 3:
      return "BO"; // brand owner user
    case 4:
      return "PR"; // participant user
    case 5:
      return "BU"; // brand user
    default:
      return "";
  }
};

/** Stripe public key */
export const STRIPE = process.env.REACT_APP_STRIPE_KEY;

/** Image base URL */
export const IMAGE_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;

/** Maintenance Mode */
export const MAINTENANCE_MODE = false;
export const MAINTENANCE_MODE_ALLOWED_IPS = [
  "14.99.99.146",
  "183.182.84.76",
  "49.249.2.122",
];

/**
 * @method getCreditCardProcessingFee
 * @description return stripe credit card processing fee on given amount
 * @param {*} amount
 * @returns
 */
export const getCreditCardProcessingFee = (amount, country = "") => {
  let fee = parseFloat(amount + 0.3) / (1 - 0.04) - parseFloat(amount);

  if (country?.toLocaleLowerCase() === "canada") {
    fee = parseFloat(amount + 0.3) / (1 - 0.045) - parseFloat(amount);
  }

  return parseFloat(fee?.toFixed(2));
};

export const STRIPE_MAX_AMOUNT = 999999.98; // in USD

export const BRAND_REQUEST_TEMPLATE_BASE_URL = `${process.env.REACT_APP_BRAND_REQUEST_TEMPLATE_BASE_URL}/sample_templates`;
