/** Characters */
const maxChar = (char) => `Max ${char} characters are acceptable`;
const minChar = (char) => `Min ${char} characters are acceptable`;

/** Numbers */
const maxDigit = (digit) => `Max ${digit} digits are acceptable`;
const minDigit = (digit) => `Min ${digit} digits are acceptable`;
const equalOrGrater = (digit) => `Should be equal or greater than ${digit}`;
const equalOrLess = (digit) => `Should be equal or less than ${digit}`;

/** file */
const maxFileSize = (size) => `Max file size acceptable is ${size}MB`;

const MESSAGE = {
  minChar: (char) => minChar(char),
  maxChar: (char) => maxChar(char),
  exactChar: (char) => `Exactly ${char} characters required`,

  minDigit: (digit) => minDigit(digit),
  maxDigit: (digit) => maxDigit(digit),
  exactDigit: (digit) => `Only ${digit} digits are acceptable`,
  equalOrLess: (digit) => equalOrLess(digit),
  equalOrGrater: (digit) => equalOrGrater(digit),
  atLeastOneNumber: (field = "") =>
    `${field ? field : "This"} field should consists of at least one number`,
  onlyPositiveNumbers: `Only positive integers are allowed`,
  greaterThanZero: `Should be greater than zero`,
  noZeroAtStart: (msg) => `${msg} should not start with zero`,

  maxFileSize: (size) => maxFileSize(size),
  fileType: (types) => `Only ${types} files are acceptable`,

  required: `This field is required`,
  requiredField: (field) => `${field ? field : "This"} field is required`,
  invalidEmail: `Entered email id is incorrect`,
  invalidURL: `Invalid URL`,

  noWhiteSpace: (field = "") =>
    `Blank spaces ${field && `in ${field}`} is not accepted`,

  passwordMisMatch: `Password doesn't match`,
  passwordLength: `Password should consists of at least 6 characters`,
  passwordCurrentNotMatch: `Current password does not match`,

  /*****/
  emailSend: `Email has been sent, please check your inbox`,
  passwordResetSuccess: `Your password has been re-set successfully`,
  passwordResetFail: `Password reset failed, please try again`,
  passwordUpdateSuccess: `Your password has been updated successfully`,
  passwordUpdateFail: `Password update failed, please try again`,

  updateSuccess: (field) => `${field} updated successfully`,

  invalidDate: `Invalid End Date Format`,
  dateLessThanBefore: `Date should not be before start date`,

  somethingWrong: (operation) =>
    `Something went wrong ${
      operation ? `while ${operation}` : ""
    }, please try again`,

  emoji: `Emoji is not acceptable`,
  brandDataMissing: `Not getting brand data, please contact to admin`,

  /** Creative feedback */
  invalidCreativeName: `Maximum characters accepted for Creative Name are 50.`,
  invalidCreativeFeedback: `Maximum characters accepted for Description/Change required are 200.`,

  /** Budget plan */
  presetBudgetSumInvalid: (presetTotal) =>
    `The sum of distributed budget among the selected location must be equal to the pre-set total of $${presetTotal}`,
  budgetNotSet: `Please plan your budget`,
  locationSpecificBudgetInvalid: (min_budget, max_budget) =>
    `Please set individual location budget between $${min_budget} and $${max_budget}`,
  locationBudgetZero: `Location budget can not be zero`,
};

export default MESSAGE;
