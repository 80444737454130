import { useContext } from "react";
import { Redirect } from "react-router-dom";
import PageLayout from "../../components/layouts/page/PageLayout";
import { GlobalContext } from "../../helpers/contexts/contexts";
import RpSteps from "../subscriptionPackages/RpSteps";
import MSActivationBrief from "./activationBrief/MSActivationBrief";

const steps = [
  "Activation Brief",
  "Keywords and Customization",
  "Spend Confirmation",
];

export default function MSActivation() {
  const { selectedBrand } = useContext(GlobalContext);
  const activeStep = 0;
  const renderSteps = () => {
    if (activeStep === 0) {
      return <MSActivationBrief />;
    }
  };

  if (selectedBrand?.id !== 1) {
    // If not Morgan Stanley
    return <Redirect to="/dashboard" />;
  }

  return (
    <PageLayout className="ms-activation mx-0 mx-sm-2">
      <div className="head shadow-sm">
        <RpSteps activeStep={activeStep} steps={steps} type="ms_activation" />
      </div>
      <div className="container-fluid">{renderSteps()}</div>
    </PageLayout>
  );
}
