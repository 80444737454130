import React, { useCallback, useContext, useMemo, useState } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";

import TableServer from "../../../components/tables/TableServer";
import useFetcher from "../../../helpers/hooks/useFetcher";
import { stripeMicroService } from "../../../services/stripe-microservice/stripeMicroService";
import { MUICustomAutocompleteSelect } from "../../../components/muiCustom/select/MUICustomAutocompleteSelect";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import { getFormatDate } from "../../../helpers/utils";

function SubscriptionHistory() {
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);

  const [filter, setFilters] = useState({
    page: 0,
    row: 10,
    sortBy: "",
    sortByDir: "",
    user_id: loggedInUser.id,
    location_id: "",
  });

  const reqData = useMemo(() => {
    return {
      ...filter,
      page: filter.page ? filter.page + 1 : 1,
      list_type: "subscription_history",
      brandId: selectedBrand?.id,
    };
  }, [filter, selectedBrand?.id]);

  const { data, isLoading, isValidating } = useFetcher(
    reqData,
    stripeMicroService.getSubscriptionHistory,
    { revalidateOnFocus: false }
  );

  const pageCount = data?.data?.result?.data?.pageCount || 0;
  const totalRecords = data?.data?.result?.data?.recordsTotal || 0;
  const locationData = data?.data?.result?.data?.locationData || [];

  const columns = useMemo(
    () => [
      {
        Header: "Location",
        accessor: "location_name",
        width: 300,
      },
      {
        Header: "Location ID",
        accessor: "system_generated_location_id",
      },
      {
        Header: "Plan",
        accessor: "title",
        width: 300,
        Cell: ({ row }) => {
          const {
            original: { title },
          } = row;

          return title || "N/A";
        },
      },
      {
        Header: "Media Cost",
        accessor: "media_cost",
        canSort: false,
        disableSortBy: true,
        width: 200,
        Cell: ({ row }) => {
          const {
            original: { media_cost },
          } = row;

          return media_cost || "N/A";
        },
      },
      {
        Header: "Fee",
        accessor: "subscription_fee",
        disableSortBy: true,
        canSort: false,
        width: 200,
        Cell: ({ row }) => {
          const {
            original: { subscription_fee },
          } = row;

          return subscription_fee || "N/A";
        },
      },
      {
        Header: "Plan Start Date",
        accessor: "subscription_start_date",
        width: 200,
        Cell: ({ row }) => {
          const {
            original: { subscription_start_date },
          } = row;

          return getFormatDate(subscription_start_date) || "N/A";
        },
      },
      {
        Header: "Plan End Date",
        accessor: "subscription_end_date",
        width: 200,
        Cell: ({ row }) => {
          const {
            original: { subscription_end_date },
          } = row;

          return getFormatDate(subscription_end_date) || "N/A";
        },
      },
      {
        Header: "Plan Length",
        accessor: "duration",
        width: 145,
      },
    ],
    []
  );

  const dataList = useMemo(() => {
    return data?.data?.result?.data?.subscriptions || [];
  }, [data]);
  const handleFetchData = useCallback(
    ({
      pageIndex,
      pageSize,
      searchKey,
      sortBy,
      sortByDir,
      filters: fetchFilters = {},
    }) => {
      setFilters((p) => ({
        ...p,
        ...fetchFilters,
        page: pageIndex,
        row: pageSize,
        sortBy: sortBy,
        sortByDir: sortByDir,
        search: searchKey,
        location_id: fetchFilters?.location_id || "",
        user_id: loggedInUser.id,
      }));
    },
    []
  );

  const locationFilter = (setFilter, selectedValues) => {
    return (
      <>
        <div className="col-sm col-lg-3 col-xl-3 mb-3 ml-lg-2">
          <MUICustomAutocompleteSelect
            name="location_id"
            label="Locations"
            selectProps={{
              onChange: (v) => {
                setFilter("location_id", v);
              },
              value: selectedValues?.location_id,
            }}
            options={locationData?.map((l) => ({
              label: l.location_name,
              value: l.location_id,
            }))}
            all
          />
        </div>
      </>
    );
  };

  return (
    <Card className="w-100 mb-4 border-0 card-shadow">
      <div className="card-header">
        <Typography variant="h6" className="table-title">
          Plan History
        </Typography>
      </div>
      <CardContent className="px-0 subscription-invoice">
        <TableServer
          columns={columns}
          data={dataList}
          showSearch={true}
          fetchData={handleFetchData}
          isLoading={isLoading || isValidating}
          filters={locationFilter}
          pageCount={pageCount}
          totalRecords={totalRecords}
          isSubscriptionHistory={true}
        />
      </CardContent>
    </Card>
  );
}

export default SubscriptionHistory;
