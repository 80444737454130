import React from "react";
import { boostCampaignHelper } from "../../helpers/boostCampaignHelper";
import { Grid, Paper, Typography } from "@material-ui/core";
import { CurrencyFormateText } from "../CurrencyFormat";

function BoostConfirmTotal({ selections }) {
  const total = boostCampaignHelper.getBoostTotal(selections);

  return (
    <Paper className="my-4 px-2 py-3 boost-confirm-total" elevation={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} lg={6}></Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Typography
            color="primary"
            variant="h6"
            className="text-center text-sm-right"
          >
            Total Boost Budget:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <Typography
            color="primary"
            variant="h6"
            className="total-boost-budget"
          >
            <CurrencyFormateText value={total} suffix=" USD" />
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default BoostConfirmTotal;
