import React, { useContext } from "react";
import {
  campaignFormatHelper,
  getCampaignStatusText,
  getStatusCode,
} from "../../helpers/utils";
import { CurrencyFormateText } from "../CurrencyFormat";
import StatusChip from "../muiCustom/chip/StatusChip";
import LearnMoreButton from "../muiCustom/buttons/LearnMoreButton";
import BoostCampaignButton from "../muiCustom/buttons/BoostCampaignButton";
import PauseLocationAddIcon from "../../containers/campaign/campaignPause/PauseLocationAddIcon";
import LocationListModal from "../modals/LocationListModal";
import { GlobalContext } from "../../helpers/contexts/contexts";

export default function JoinedCampaignCard({ campaign }) {
  const {
    id,
    status,
    participant_campaign,
    budget,
    brand_contribution,
    total_of_budget_and_brand_match,
  } = campaign || {};
  const { loggedInUser } = useContext(GlobalContext);
  const renderLocations = () => {
    if (participant_campaign && participant_campaign.is_draft === false) {
      const locs = participant_campaign.locationData || [];
      return locs.length ? (
        <>
          <LocationListModal locations={locs} />
        </>
      ) : (
        0
      );
    }
    return null;
  };

  const renderBudget = () => {
    let campaignBudget = budget || 0;
    if (brand_contribution) {
      campaignBudget = total_of_budget_and_brand_match || 0;
    }
    // if (
    //   local_selection !== 0 &&
    //   participant_campaign &&
    //   participant_campaign.is_draft === false &&
    //   participant_campaign.total_budget
    // ) {
    //   campaignBudget = participant_campaign.total_budget;

    //   if (brand_contribution) {
    //     campaignBudget = total_of_budget_and_brand_match;
    //   }
    // }

    return <CurrencyFormateText value={campaignBudget} />;
  };

  return (
    <div className="card h-100">
      <div className="card-body p-3">
        <div className="row">
          <div className="col-7 col-lg-2 fw-sb">
            {campaignFormatHelper(campaign).name()}
          </div>
          <div className="col-5 col-lg text-right text-lg-left pl-lg-0">
            <StatusChip
              label={getCampaignStatusText(status)}
              status={getStatusCode(status, "hold")}
            />
          </div>
          <div className="col-6 col-lg mt-2 mt-lg-0">
            <div className="d-lg-none title">Active Budget: </div>
            <span className="title-val fw-b">{renderBudget()}</span>
          </div>
          {/* <div className="col-6 col-lg mt-2 mt-lg-0">
            <div className="d-lg-none title">Spend: </div>
            <span className="title-val">
              {spend || spend === 0 ? (
                <CurrencyFormateText value={spend} />
              ) : (
                "N/A"
              )}
            </span>
          </div> */}
          <div className="col-6 col-lg-1 mt-2 mt-lg-0">
            <div className="d-lg-none title">Enrolled Locations: </div>
            <span className="title-val fw-b">{renderLocations()}</span>
          </div>
          <div className="col-6 col-lg mt-2 mt-lg-0">
            <div className="d-lg-none title">Start Date: </div>
            <span className="title-val">
              {campaignFormatHelper(campaign).startDate("MMM DD, YYYY")}
            </span>
          </div>
          <div className="col-6 col-lg mt-2 mt-lg-0">
            <div className="d-lg-none title">End Date: </div>
            <span className="title-val">
              {campaignFormatHelper(campaign).endDate("MMM DD, YYYY")}
            </span>
          </div>
          <div
            className="col-6 col-lg mt-3 mt-lg-0"
            style={{ height: "fit-content" }}
          >
            <div className="d-lg-none title mr-2 mb-1">Channels: </div>
            <div className="title-val">
              {campaignFormatHelper(campaign).getChannelIcons()}
            </div>
          </div>
          <div className="col-6 col-lg-1 mt-3 mt-lg-0 px-lg-0">
            <div className="d-lg-none invisible title mb-1">FAQ: </div>
            <div className="d-flex align-items-center justify-content-end justify-content-lg-start">
              <LearnMoreButton
                to={{
                  pathname: `${process.env.PUBLIC_URL}/dashboard/learn-more/digital-campaign/${id}`,
                  state: { campaignId: id, from: "/dashboard" },
                }}
                className="mr-1"
                icon
              />
              {!!campaign?.is_campaign_boost &&
              (campaign?.status === 1 || campaign?.status === 2) ? (
                <BoostCampaignButton
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/boost-campaign/${id}`,
                    state: { campaignId: id, from: "/dashboard" },
                  }}
                />
              ) : null}
              {campaign &&
              campaign.pause_campaign_available &&
              loggedInUser &&
              loggedInUser?.fk_role_id !== 2 ? (
                <PauseLocationAddIcon campaignId={id} campaign={campaign} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
