import Bowser from "bowser";

import { logger } from "./loggerHelper";

/**
 * Utility function to detect the device type, OS, and browser.
 * @returns {Object} - Object containing device type, OS, and browser details.
 */
const detectDevice = () => {
  const browserParser = Bowser.getParser(window.navigator.userAgent);

  const deviceType = browserParser.getPlatformType(); // mobile | tablet | desktop
  const os = browserParser.getOS(); // OS details like name and version
  const browser = browserParser.getBrowser(); // Browser details like name and version

  // Return an object with detailed info
  return {
    deviceType, // 'mobile', 'tablet', or 'desktop'
    osName: os.name || "Unknown", // OS name (e.g., 'iOS', 'Windows', 'Linux', etc.)
    osVersion: os.version || "Unknown", // OS version
    browserName: browser.name || "Unknown", // Browser name (e.g., 'Chrome', 'Safari', 'Firefox')
    browserVersion: browser.version || "Unknown", // Browser version
  };
};

export const deviceInfo = detectDevice();
logger.info(deviceInfo);
