import React from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import {
  hasLoggedOutSelector,
  isLoggedInSelector,
} from "../selectors/authSelectors";
import { isEmpty } from "lodash";
import NotFound404 from "../components/NotFound404";
import {
  loggedInUserRoleSelector,
  loggedInUserSelector,
} from "../selectors/userSelector";
import { logger } from "./loggerHelper";
import { setRedirectPath } from "./localStore";
import { authActions } from "../actions";

const AuthMiddleware = ({
  component: Component,
  roles = [""],
  loggedInUserRole,
  user,
  isLoggedIn,
  hasLoggedOut,
  ...rest
}) => {
  const dispatch = useDispatch();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isEmpty(user) && isLoggedIn) {
          return roles.includes(loggedInUserRole) ? (
            <Component user={user} />
          ) : (
            <NotFound404 />
          );
        } else {
          logger.info("hasLoggedOut", hasLoggedOut);
          if (!hasLoggedOut) {
            logger.info("Setting redirect path");
            const redirectPath = location.pathname + location.search;
            logger.info("redirectPath", redirectPath);
            const success = setRedirectPath(redirectPath);
            if (success) {
              logger.info("Stored redirect path:", redirectPath);
            } else {
              logger.error("Failed to store redirect path");
            }
          } else {
            dispatch(authActions.resetHasLoggedOut());
          }
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  user: loggedInUserSelector(state),
  isLoggedIn: isLoggedInSelector(state),
  loggedInUserRole: loggedInUserRoleSelector(state),
  hasLoggedOut: hasLoggedOutSelector(state),
});

export default connect(mapStateToProps)(AuthMiddleware);
