import React, { useState, useEffect, useMemo, useContext } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";

import { RPNumberFormat } from "../../helpers/utils";
import {
  areaDatasetDefaultOptions,
  datasetDefaultOptions,
} from "./ChartJsConfig";
import DrillControl from "./DrillControl";
import {
  WIDGETS_WITH_DRILL_FEATURE,
  DRILL_LEVELS,
} from "../../config/constants";
import {
  formatAndSortData,
  isBrandHasDrillFeature,
} from "../../helpers/drillUpDownHelper";
import { GlobalContext } from "../../helpers/contexts/contexts";

const AreaChart = ({
  data = [],
  labels,
  option = {},
  scales = {},
  title = "",
  formats = null,
  widgetId,
  values,
  initialRange,
}) => {
  const { selectedBrand } = useContext(GlobalContext);

  const [chartData, setChartData] = useState({});
  const [drillLevel, setDrillLevel] = useState("month");

  const options = useMemo(
    () => ({
      tooltips: {
        intersect: false,
        callbacks: {
          label: function (tooltipItem, data) {
            const label =
              (data && data?.datasets[tooltipItem.datasetIndex].label) || "";

            const val =
              (data && data?.datasets[0]?.data[tooltipItem.index]) || "";

            const format = formats ? formats[labels[1]] : null;
            if (!val.y) return "";
            if (formats && format) {
              return `${label}: ${RPNumberFormat({
                prefix: format.prefix,
                suffix: format.suffix,
              }).to(parseFloat(val.y))}`;
            }
            return `${label}: ${RPNumberFormat().to(parseFloat(val.y))}`;
          },
          title: (tooltipItem) => {
            const label = tooltipItem[0].label;
            if (drillLevel === DRILL_LEVELS.DAY) {
              return moment(label, "YYYY-MM-DD").format("DD MMM YYYY");
            } else if (
              drillLevel === DRILL_LEVELS.QUARTER ||
              drillLevel === DRILL_LEVELS.YEAR
            ) {
              return label;
            } else {
              return moment(label, "YYYY-MM-DD").format("MMM YYYY");
            }
          },
        },
      },
      legend: {
        display: false,
        align: "start",
        labels: {
          boxWidth: 10,
          fontFamily: "Montserrat",
          fontColor: "#54545B",
          padding: 5,
          marginBottom: 10,
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      elements: {
        //point: { radius: 0 },
        line: {
          backgroundColor: "rgba(9, 171, 167, 0.1)",
        },
      },
      datasets: {
        backgroundColor: "rgba(9, 171, 167, 0.1)",
      },
      scales: {
        fontColor: "#808080",
        fontFamily: "Open Sans",
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              callback: (value) => {
                if (
                  drillLevel === DRILL_LEVELS.QUARTER ||
                  drillLevel === DRILL_LEVELS.YEAR
                ) {
                  return value;
                } else if (drillLevel === DRILL_LEVELS.DAY) {
                  return moment(value, "YYYY-MM-DD").format("DD");
                } else {
                  return moment(value, "YYYY-MM-DD").format("MMM YYYY");
                }
              },
            },
          },
        ],
        yAxes: [
          {
            id: "y-axis-0",
            position: "left",
            ticks: {
              min: 0,
              maxTicksLimit: 5,
              callback: (value = 0) => {
                const format = formats ? formats[labels[1]] : null;

                if (!value) return "";
                if (formats && format) {
                  return RPNumberFormat({
                    prefix: format.prefix,
                    suffix: format.suffix,
                  }).to(value);
                }
                return RPNumberFormat().to(value);
              },
            },
          },
        ],
        ...scales,
      },
      ...option,
    }),
    [option, scales, drillLevel]
  );

  const getDataSet = (data, currentDrillLevel = drillLevel) => {
    const formatData = formatAndSortData(data, labels, currentDrillLevel);

    const datasets = [
      {
        label: title,
        data: formatData,
        yAxisID: "y-axis-0",
        ...datasetDefaultOptions,
        ...areaDatasetDefaultOptions,
        lineTension: 0.01,
      },
    ];
    return {
      labels: formatData?.map((d) => d.x),
      datasets: datasets,
    };
  };

  useEffect(() => {
    const initData = {
      //labels: getMonthLabels().map((l) => l.label),
      labels: getDataSet()[0]?.data
        ? getDataSet()[0]?.data?.map((d) => d.x)
        : [],
      datasets: getDataSet(),
    };
    setChartData(initData);
  }, [data]);

  useEffect(() => {
    setChartData(getDataSet(data));
  }, [data]);

  const handleDrillChange = (newDrillLevel, newData) => {
    setDrillLevel(newDrillLevel);
    setChartData(() => getDataSet(newData, newDrillLevel));
  };

  return (
    <div className="area-chart">
      {isBrandHasDrillFeature(selectedBrand?.system_generated_brand_id) &&
        widgetId &&
        WIDGETS_WITH_DRILL_FEATURE.includes(widgetId) && (
          <DrillControl
            drillLevel={drillLevel}
            onDrillChange={handleDrillChange}
            widgetId={widgetId}
            values={values}
            initialRange={initialRange}
          />
        )}
      <Line data={chartData} options={options} />
    </div>
  );
};

export default AreaChart;
