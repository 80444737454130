import React, { useContext, useMemo } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextareaAutosize,
} from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toastr } from "react-redux-toastr";

import { brandService } from "../services";
import { GlobalContext } from "../helpers/contexts/contexts";
import { STATIC_IMAGES } from "../helpers/staticImages";
import RpMUIModal from "../components/modals/RpMUIModal";
import { reportUserActivity } from "../helpers/userActivityHelper";

const Feedback = ({ open, handleClose }) => {
  const { selectedBrand, loggedInUser } = useContext(GlobalContext);

  const userData = useMemo(
    () => ({
      userId: loggedInUser?.id,
      brandId: selectedBrand?.id,
      participantId: loggedInUser?.participantData?.id,
    }),
    [loggedInUser, selectedBrand]
  );

  const validationSchema = Yup.object({
    feedback: Yup.string()
      .required()
      .test(
        "not-only-spaces",
        "Please enter valid text.",
        (value) => value && value.trim().length > 0
      )
      .max(1500, "Feedback cannot exceed 1500 characters"),
  });

  const initialValues = { feedback: "" };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    reportUserActivity("pr_feedback");
    const feedbackData = {
      fk_user_id: userData.userId,
      fk_brand_id: userData.brandId,
      fk_participant_id: userData.participantId,
      feedback_message: values.feedback,
    };

    try {
      const response = await brandService.saveUserFeedback(feedbackData);

      if (response.status === 200) {
        toastr.success("Success", "Thank you for your feedback!");
        resetForm();
        handleClose();
      }
    } catch (error) {
      toastr.error("Error", "Failed to submit feedback. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <RpMUIModal
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      className="user-feedback"
    >
      <DialogTitle className="d-flex justify-content-center">
        <div className="img-box text-center my-3">
          <img
            src={STATIC_IMAGES.feedbackImg}
            alt="Info"
            className="img-fluid feedback-img"
          />
        </div>
        <Typography variant="h5" component="h2" className="fw-b text-center">
          We'd love your
          <Typography
            color="primary"
            variant="h5"
            component="span"
            className="fw-b ml-1 text-center"
          >
            Feedback!
          </Typography>
        </Typography>
        <Typography variant="subtitle2" className="text-center">
          What would you like to share?
        </Typography>
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent>
              <Field
                as={TextareaAutosize}
                minRows={5}
                maxRows={8}
                name="feedback"
                placeholder="Your message..."
                className="feedback-message p-2 w-100"
              />
              <ErrorMessage
                name="feedback"
                component="div"
                className="text-danger fs-12"
              />
            </DialogContent>

            <DialogActions className="d-flex justify-content-center my-3">
              <Button
                type="submit"
                className="w-auto feedback-btn"
                color="primary"
                variant="contained"
                size="small"
                disableElevation
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Send Feedback"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </RpMUIModal>
  );
};

export default Feedback;
