import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { Icon, Typography } from "@material-ui/core";
import RpSnackbar from "../../../components/alerts/RpSnackbar";
import PackageSelectionSummary from "./PackageSelectionSummary";
import UserLocationList from "./UserLocationList";
import RpAlert from "../../../components/alerts/RpAlert";

export default function UnsubscribedLocations({
  pageInfo,
  locations,
  onContinue,
  selections,
  loadMoreLocations,
  renderLocationFilters,
  isForUpcomingRenewal,
  totalLocationCount,
}) {
  const [error, setError] = useState(null);

  const initialValues = useMemo(() => {
    let locPackSelections = [];

    if (selections.length) {
      selections.forEach((s) => {
        locPackSelections.push({
          ...s,
          locationId: s.locationId,
          packageId: s.packageId,
        });
      });
    }
    return { locPackSelections };
  }, [selections]);

  const handleContinue = () => {};
  const onSubmit = (values, actions) => {
    setError(null);

    if (values.locPackSelections.length === 0) {
      setError("Please select at least one location to continue");
      actions.setSubmitting(false);
      return;
    }

    // remove selected locations which don't have selected package
    values.locPackSelections = values.locPackSelections.filter(
      (l) => l.packageId
    );

    onContinue(values);
  };

  const renderForm = () => {
    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="row">
              <div className="col-xl-8 ">
                {locations.length === 0 ? (
                  <div className="d-flex justify-content-center mt-4 mb-2">
                    <RpAlert severity="info">
                      There are no locations to subscribe.
                    </RpAlert>
                  </div>
                ) : (
                  <>
                    <UserLocationList
                      locations={locations}
                      formProps={props}
                      loadMoreLocations={loadMoreLocations}
                      totalLocationCount={totalLocationCount}
                    />

                    <div className="my-4 text-muted d-flex align-items-center location-note">
                      <Icon
                        className="mr-2"
                        component="img"
                        src="/img/icons/electric.png"
                      />
                      <Typography color="textSecondary" variant="body2">
                        Locations listed are those associated with your personal
                        brand email.{" "}
                        {pageInfo?.contact_email && (
                          <>
                            If you believe locations are missing from this list
                            please contact us at{" "}
                            <a href={`mailto:${pageInfo?.contact_email}`}>
                              {pageInfo?.contact_email}
                            </a>
                          </>
                        )}
                      </Typography>
                    </div>
                  </>
                )}
              </div>
              <div className="col-xl-4">
                <PackageSelectionSummary
                  onContinue={handleContinue}
                  formProps={props}
                  locations={locations}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <div className="unsubscribed-locations">
      {!isForUpcomingRenewal && (
        <Typography
          component="h3"
          variant="h5"
          className="sub-heading text-center my-5"
          gutterBottom
        >
          2.{" "}
          {pageInfo?.location_list_title ||
            "Select the centers to subscribe & a plan for each"}
        </Typography>
      )}
      <>
        {renderLocationFilters()}
        {renderForm()}
      </>
      {/* {locations.length === 0 ? (
        <div className="d-flex justify-content-center my-5">
          <RpAlert severity="info">
            There are no Unsubscribed locations.
          </RpAlert>
        </div>
      ) : (
        <>
          {renderLocationFilters()}
          {renderForm()}
        </>
      )} */}

      <RpSnackbar message={error} show={error} />
    </div>
  );
}
