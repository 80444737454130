import { useMemo } from "react";
import { Icon, Typography, Button } from "@material-ui/core";
import moment from "moment";

import { subscriptionHelper } from "../../../helpers/subscriptionHelper";
import RpImageTextInline from "../../../components/RpImageTextInline";
import { CurrencyFormateText } from "../../../components/CurrencyFormat";

export default function PreviousSelectedPlan({
  data,
  isChangingUpcomingPackage,
}) {
  const isNewSubscription = useMemo(() => {
    return subscriptionHelper.checkIsNewSubscription(data?.locationInfo);
  }, [data.locationInfo]);

  const planDetails = useMemo(() => {
    const { startDate, endDate } = subscriptionHelper.calculateSubscriptionDate(
      data?.packageInfo,
      data?.locationInfo,
      isNewSubscription ? false : isChangingUpcomingPackage
    );
    let details = {
      plan: data.locationInfo.upcoming_package,
      startDate: startDate.format("MMM D, YYYY"),
      endDate: endDate.format("MMM D, YYYY"),
      price: data?.locationInfo?.upcoming_package_amount,
      sub_fee: data?.locationInfo?.upcoming_subscription_fee,
      total:
        data?.locationInfo?.upcoming_package_amount +
        data?.locationInfo?.upcoming_subscription_fee,
      duration: data?.locationInfo?.upcoming_package_duration,
    };

    if (isNewSubscription) {
      const startDate = moment(data?.locationInfo?.subscription_start_date);
      const endDate = moment(startDate)
        .add(parseInt(data?.locationInfo?.duration), "month")
        .subtract(1, "day");

      details = {
        ...details,
        startDate: startDate.isValid() ? startDate.format("MMM D, YYYY") : "",
        endDate: endDate.isValid() ? endDate.format("MMM D, YYYY") : "",
        plan: data.locationInfo.subscription_package,
        price: data?.locationInfo?.media_fee,
        sub_fee: data?.locationInfo?.extra_charge,
        total: data?.locationInfo?.media_fee + data?.locationInfo?.extra_charge,
        CurrentPaymentType: data?.locationInfo?.current_package_payment_type,
        duration: data?.locationInfo?.duration,
      };
    }
    return details;
  }, [data, isNewSubscription]);

  return (
    <div className="card mb-2 previous-location-data">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-3 col-xl-4 mb-2 ">
            <Typography
              className="mr-2 location-name"
              color="primary"
              component="span"
              variant="body2"
            >
              {`${data.locationInfo.location_name} (${data.locationInfo.participantName})`}
            </Typography>
            <Typography className="tag" variant="body2">
              (OLD PLAN)
            </Typography>
          </div>
          <div className="col-lg-3 col-md-6 mb-2">
            <RpImageTextInline
              alignCenter={false}
              image="/img/icons/bucket.png"
              text={
                <>
                  <div className="title">Media plan</div>
                  <div className="val">
                    {`${planDetails.plan} - ${planDetails.duration} Months `}
                  </div>
                </>
              }
            />
          </div>
          <div className="col-lg-3 col-md-6 mb-2">
            <RpImageTextInline
              alignCenter={false}
              image="/img/icons/calendar.png"
              text={
                <>
                  <div className="title">Plan dates</div>
                  <div className="val">{`${planDetails.startDate} - ${planDetails.endDate}`}</div>
                </>
              }
            />
          </div>

          <div className="col-xl-2 col-lg-3 col-md-12 mb-2 text-lg-right d-none d-lg-block">
            <div className="title">
              <Icon
                component="img"
                src="/img/icons/money.png"
                fontSize="small"
                className="mr-1"
              />
              Price
            </div>
            <div className="val">
              <CurrencyFormateText
                value={planDetails.price}
                suffix=" USD per month"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-end my-2 d-lg-none">
          <div className="col-6 col-lg-3 val">Price</div>
          <div className="col-6 col-lg-3 col-xl-2 text-right val">
            <CurrencyFormateText
              value={planDetails.price}
              suffix=" USD per month"
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-lg-6 col-xl-7">
            {data?.locationInfo?.upcoming_is_brand_match === 1 ? (
              <Button
                variant="contained"
                onClick={() => {}}
                className="px-4 brand-match-btn"
                disabled
              >
                Brand Match &nbsp;{" "}
                <CurrencyFormateText
                  value={data?.locationInfo?.upcoming_brand_match_amount}
                  suffix=" USD"
                />
              </Button>
            ) : null}
          </div>
          <div className="col-lg-6 col-xl-5 d-flex justify-content-between">
            <div className="subs">Fee</div>
            <div className="subs">
              <CurrencyFormateText
                value={planDetails.sub_fee}
                suffix=" USD per month"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-12 col-xl-12">
            <hr />
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-3 total">Total Budget:</div>
          <div className="col-9 col-lg-3 col-xl-2 text-right total">
            <CurrencyFormateText
              value={planDetails.total}
              suffix=" USD per month"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
