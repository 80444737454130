import log from "loglevel";

const LOG_LEVEL = {
  TRACE: "trace",
  INFO: "info",
  WARN: "warn",
  ERROR: "error",
  SILENT: "silent",
};

// Default to TRACE if REACT_APP_ENV is not set
const currentEnv = process.env.REACT_APP_ENV || "development";

// Set different log levels based on environment
if (currentEnv === "production") {
  // log.setLevel(LOG_LEVEL.ERROR);
  log.setLevel(LOG_LEVEL.TRACE);
} else {
  log.setLevel(LOG_LEVEL.TRACE); // Full trace logs for all non-production environments
}

export const logger = {
  error: (message, error) => {
    log.error(message, error);
    // You could add error reporting service here (like Sentry)
  },
  warn: (message, ...args) => {
    log.warn(message, ...args);
  },
  info: (message, ...args) => {
    // For detailed logging in non-production
    if (currentEnv !== "production") {
      log.info(message, ...args);
    }
  },
  trace: (message, ...args) => {
    // For detailed logging in non-production
    if (currentEnv !== "production") {
      log.trace(message, ...args);
    }
  },
};

// Add method to change log level dynamically
export const setLogLevel = (level) => {
  if (LOG_LEVEL[level.toUpperCase()]) {
    log.setLevel(LOG_LEVEL[level.toUpperCase()]);
  }
};
