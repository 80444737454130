import React, { memo } from "react";
const LeftView = () => {
  const style = {
    background: `url(${process.env.PUBLIC_URL}/img/BCIMG.png)`,
  };
  return (
    <div className="auth-left-view w-100 h-100 py-1 py-sm-4" style={style}>
      <div className="logo-image-box position-relative mb-4">
        <img
          className="logo-image-bg img-fluid"
          src="/img/Group211.svg"
          alt=""
        />
        <img className="logo-image" src="/img/Group2146.svg" alt="Logo" />
      </div>
      <div className="heading1 white mb-3 heading">
        Brand directed.
        <br />
        Locally perfected.
      </div>
      <p className="white">
        The Hyperlocology advertising platform gives you the power of
        sophisticated digital marketing, backed by your brand, in an easy to use
        dashboard.
      </p>
      <p className="white">
        Promote your business like a pro in just a few clicks!
      </p>
    </div>
  );
};

export default memo(LeftView);
