import React from "react";
import { Button } from "@material-ui/core";
import { CheckCircle, RadioButtonUnchecked } from "@material-ui/icons";
import { MAILING_TYPES } from "../../../../../config/constants";

function MailingType({ onChange, selected, isMailing, selectedBrand }) {
  const isAddress = selected === MAILING_TYPES.T;
  const isTargeted = selected === MAILING_TYPES.S;

  const handleOnChange = (type) => {
    if (onChange && typeof onChange === "function") {
      onChange(type);
    }
  };

  return (
    <div className="container radio-ui">
      <div className="row mt-4 align-items-center justify-content-around">
        <div className="col-xl-4 col-md-5 px-xl-0 mb-2 mb-lg-0 click-open">
          <Button
            startIcon={isAddress ? <CheckCircle /> : <RadioButtonUnchecked />}
            size="small"
            variant={isAddress ? "contained" : "outlined"}
            color="primary"
            disableElevation
            onClick={() => handleOnChange(MAILING_TYPES.T)}
            disabled={isMailing ? false : true}
            fullWidth
          >
            Upload Address List
          </Button>
        </div>
        <div className="col-xl-1 col-md-1 px-0 text-center">
          <small>Or</small>
        </div>
        <div className="col-xl-6 col-md-6 px-xl-0 click-open">
          <Button
            startIcon={isTargeted ? <CheckCircle /> : <RadioButtonUnchecked />}
            size="small"
            variant={isTargeted ? "contained" : "outlined"}
            color="primary"
            disableElevation
            onClick={() => handleOnChange(MAILING_TYPES.S)}
            disabled={isMailing ? false : true}
            fullWidth
          >
            Target Ideal {selectedBrand?.brand_name} Customer
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MailingType;
