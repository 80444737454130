import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import authReducer from "./authReducer";
import resetPassword from "./resetPasswordReducer";
import userReducer from "./userReducer";
import brandReducer from "./brandReducer";
import campaignReducer from "./campaignReducer";
import settingsReducer from "./settingsReducer";

export default combineReducers({
  toastr: toastrReducer,
  auth: authReducer,
  resetPassword,
  user: userReducer,
  brand: brandReducer,
  campaign: campaignReducer,
  settings: settingsReducer,
});
