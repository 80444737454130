import { Link } from "react-router-dom";
import { Button, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const StyledButton = withStyles(() => ({
  root: {
    fontSize: 9,
    border: "1px solid #F5239C",
    color: "#F5239C",
    "&:hover": {
      background: "#F5239C",
      color: "#fff!important",
    },
  },

  endIcon: {
    marginLeft: 3,
    icons: {
      fontSize: 10,
    },
  },
}))(Button);

export default function LearnMoreButton({
  to = {},
  text = "Learn More",
  icon = false,
  ...rest
}) {
  if (icon) {
    return (
      <Link to={to}>
        <Icon
          component="img"
          src="/img/icons/bulb_pink.png"
          title="Learn More"
          {...rest}
        ></Icon>
      </Link>
    );
  }
  return (
    <StyledButton
      component={Link}
      to={to}
      variant="outlined"
      color="secondary"
      size="small"
      endIcon={<Icon fontSize="small">emoji_objects</Icon>}
      disableElevation
      {...rest}
    >
      {text}
    </StyledButton>
  );
}
