import React, { memo } from "react";
import Modal from "react-bootstrap/Modal";

const ModalBootstrap = ({
  show = false,
  onClose,
  size = "",
  centered = true,
  className = "",
  bodyClass = "",
  title = "",
  children,
  headerStyles = {},
  footer = "",
  footerStyles = {},
  backdrop = true, // static, false, true
  animation = true,
}) => {
  return (
    <Modal
      className={className}
      size={size}
      centered={centered}
      show={show}
      onHide={onClose}
      backdrop={backdrop}
      animation={animation}
    >
      <Modal.Header closeButton style={headerStyles}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={bodyClass}>{children}</Modal.Body>
      {footer && <Modal.Footer style={footerStyles}>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default memo(ModalBootstrap);
