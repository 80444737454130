import React from "react";
import { useFormikContext } from "formik";
import RpAlert from "../../../components/alerts/RpAlert";

function BoostContinueError() {
  const { errors } = useFormikContext();

  return (
    errors?.selectedLoc &&
    Array.isArray(errors?.selectedLoc) && (
      <RpAlert severity="error" className="my-2">
        <div className="error">
          There are some errors in the form. Please check.
        </div>
      </RpAlert>
    )
  );
}

export default BoostContinueError;
