import React, { useState } from "react";
import MainHeader from "../headers/MainHeader";
import { makeStyles } from "@material-ui/core";
import ResponsiveDrawer from "../headers/SideBarMenu/ResponsiveDrawer";

const drawerWidth = 240;

const PageStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: "#fbfbfb",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    paddingTop: "4.5rem",
  },
}));

export default function PageLayout({
  className = "",
  containerClassName = "",
  children,
}) {
  const classes = PageStyle();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <MainHeader handleDrawerToggle={handleDrawerToggle} />
      <ResponsiveDrawer
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
      />
      <div className={classes.appBar}>
        <div className="w-100">
          <main className={classes.content}>
            {/* <div className={classes.toolbar} /> */}
            <div className={`page ${className}`}>
              <div className={`container-fluid ${containerClassName}`}>
                {children}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
