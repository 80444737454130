import React, { useContext, useMemo } from "react";
import moment from "moment";
import { GlobalContext } from "../../../helpers/contexts/contexts";
import { CurrencyFormateText } from "../../../components/CurrencyFormat";
import RpImageTextInline from "../../../components/RpImageTextInline";

const Detail = ({ title = "", children, image = "" }) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
      <RpImageTextInline
        image={image}
        text={
          <>
            <div className="title">{title}</div>
            {children}
          </>
        }
        alignCenter={false}
      />
    </div>
  );
};

export default function PostCardCampaignReport({ campaign, values }) {
  const { loggedInUserRole, loggedInUser } = useContext(GlobalContext);

  const getScheduleDates = (campaign) => {
    const start = moment(campaign?.schedule_date);
    const end = start.isValid() ? moment(start).add(4, "day") : null;
    if (campaign?.schedule_date && start.isValid()) {
      return `${start.format("M/D/YYYY")} - ${end.format("M/D/YYYY")}`;
    }
    return "NA";
  };

  const groupName = useMemo(() => {
    const { group_id } = values;
    const { group_name, group_list } = campaign || {};

    const group = group_list?.find(
      (g) => String(g.groups_id) === String(group_id)
    );
    if (group_name) {
      return group_name?.[0]?.group_name;
    } else if (group) {
      return group.group_name;
    }
    return "All";
  }, [campaign, values]);

  const locationsCount =
    campaign?.campaignDetails?.[campaign.campaignDetails.length - 1]
      ?.location_count ?? 0;
  const participantCount =
    campaign?.campaignDetails?.[campaign.campaignDetails.length - 1]
      ?.participant_count ?? 0;

  const totalBudget = campaign?.campaignDetails
    ? campaign?.campaignDetails.reduce(
        (total, campaignDetail) => total + (campaignDetail?.total_budget || 0),
        0
      )
    : 0;

  const renderDetails = () => (
    <div className="row h-100">
      {loggedInUserRole === "PR" && (
        <Detail title="Name" image="/img/icons/user.png">
          <div className="value main">
            {`${loggedInUser.first_name} ${loggedInUser.last_name}`}
          </div>
        </Detail>
      )}
      <Detail title="Campaign Name" image="/img/icons/campaign.png">
        <div className="value main">{campaign?.campaign_name || "N/A"}</div>
      </Detail>

      <Detail title="Scheduled Date" image="/img/icons/calendar.png">
        <div className="value">{getScheduleDates(campaign)}</div>
      </Detail>

      <Detail title="Total Cost" image="/img/icons/id.png">
        <div className="value main">
          <CurrencyFormateText value={totalBudget.toFixed(2)} suffix=" USD" />
        </div>
      </Detail>

      <Detail title="Locations" image="/img/icons/location.png">
        <div className="value">{locationsCount}</div>
      </Detail>

      {loggedInUserRole !== "PR" && (
        <Detail title="Participants" image="/img/icons/users.png">
          <div className="value">{participantCount}</div>
        </Detail>
      )}

      <Detail title="Unique ID" image="/img/icons/id.png">
        <div className="value us-all">
          {campaign?.system_generated_campaign_id || "N/A"}
        </div>
      </Detail>
      {loggedInUserRole !== "PR" && (
        <Detail title="Group(s)" image="/img/icons/home.png">
          <div className="value main">{groupName}</div>
        </Detail>
      )}

      {/* {renderBudget()} */}
    </div>
  );

  return (
    <div className="row align-items-stretch postcard-campaign-report">
      <div className="col">{renderDetails()}</div>
    </div>
  );
}
