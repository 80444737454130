import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

const LoadingButton = ({
  isLoading = false,
  disabled = false,
  text = "",
  onClick,
  className = "",
  type = "button",
  ...rest
}) => {
  return (
    <Button
      className={`button ${className}`}
      disabled={isLoading || disabled}
      onClick={!isLoading ? onClick : null}
      type={type}
      variant="contained"
      color="primary"
      {...rest}
    >
      {isLoading ? (
        <CircularProgress size={20} color="white" aria-label="Loading" />
      ) : (
        <span>{text}</span>
      )}
    </Button>
  );
};

export default LoadingButton;
