import React, { useState } from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import { Icon } from "@material-ui/core";

import RpMUIModal from "./RpMUIModal";
import StatusChip from "../muiCustom/chip/StatusChip";
import useSearchFilter from "../../helpers/hooks/useSearchFilter";
import NoDataFound from "../NoDataFound";
import { getStatusCode } from "../../helpers/utils";
import MuiSearchInput from "../muiCustom/search/MuiSearchInput";

const LocationListModal = ({ locations = [] }) => {
  const [showLocationModal, setShowLocationModal] = useState(false);
  // Search functionality using custom hook

  const [searchTerm, handleSearchChange, filteredLocations] = useSearchFilter(
    locations,
    ["locationName"]
  );
  const onClose = () => {
    setShowLocationModal(false);
    handleSearchChange("");
  };
  return (
    <>
      <span className="cursor-p" onClick={() => setShowLocationModal(true)}>
        {locations.length}
      </span>

      <RpMUIModal
        open={showLocationModal}
        onClose={() => {
          onClose();
          handleSearchChange("");
        }}
        maxWidth="xs"
        fullWidth
        className="p-0"
      >
        <div className="location-list-modal">
          <Icon
            component="img"
            src="/img/icons/black-cross-icon.png"
            className="close-icon"
            onClick={() => {
              onClose();
              handleSearchChange("");
            }}
          />
          {/* Search Field */}
          <Grid
            container
            spacing={1}
            className="mb-1"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={11} md={11} lg={11}>
              <MuiSearchInput
                value={searchTerm}
                onChange={handleSearchChange}
                size="small"
                type="search"
                variant="outlined"
                placeholder="Search locations..."
                className="mt-3 mt-lg-1 mt-lx-0"
              />
            </Grid>
          </Grid>

          <Paper
            elevation={0}
            className="px-2 py-2 border-bottom d-none d-sm-flex"
          >
            <Grid container spacing={2} className="bg-light">
              <Grid
                item
                lg={6}
                md={6}
                xs={6}
                className="d-none d-sm-flex align-items-center justify-content-start"
              >
                <Typography className="fw-sb mb-0">Locations Name</Typography>
              </Grid>

              <Grid
                item
                lg={6}
                md={6}
                xs={6}
                className="d-none d-sm-flex align-items-center justify-content-end"
              >
                <Typography className="fw-sb mb-0 mr-3">Status</Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* Location List */}
          <div className="location-list-container">
            {filteredLocations.length === 0 ? (
              <NoDataFound message="No locations found" />
            ) : (
              <>
                {filteredLocations.map((loc) => (
                  <Paper elevation={0} className="px-2 py-3">
                    <Grid container spacing={2}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="d-flex align-items-center justify-content-start"
                      >
                        <Typography
                          className="text-truncate fw-sb"
                          title={loc.locationName}
                        >
                          {loc.locationName}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="d-flex align-items-center justify-content-sm-end justify-content-xs-start"
                      >
                        <StatusChip
                          label={loc.location_status}
                          status={getStatusCode(loc.location_status, "hold")}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
              </>
            )}
          </div>
        </div>
      </RpMUIModal>
    </>
  );
};

export default LocationListModal;
