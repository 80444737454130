import React from "react";
import DashboardRoutes from "../../../helpers/routes/DashboardRoutes";

export default function DashboardLayout() {
  return (
    <>
      <div className="main-dashboard" id="main-dashboard">
        <DashboardRoutes />
      </div>
    </>
  );
}
