import React, { useMemo, useState } from "react";
import {
  Button,
  Icon,
  IconButton,
  Paper,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import RpMUIDialog from "../modals/RpMUIModal";
import "./RpCustomMonthPicker.scss";

const useMonthPickerWrapperStyle = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const initialMonthRange = {
  from: {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("MM")),
  },
  to: {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("MM")),
  },
};

export default function RpCustomMonthRangePicker({
  initialRange,
  onChange,
  label = "Report Period",
  labelProps = {},
  displayButtonProps = {},
  buttonClassName,
}) {
  const [selectedRange, setSelectedRange] = useState(
    initialRange
      ? [initialRange.from, initialRange.to]
      : [initialMonthRange.from, initialMonthRange.to]
  );

  const [open, setOpen] = useState(false);

  const wrapperClasses = useMonthPickerWrapperStyle();

  const renderRange = () => {
    if (selectedRange.length !== 2) return "Select";
    const from = selectedRange[0];
    const to = selectedRange[1];
    return (
      moment(`${from.year}-${from.month}`, "YYYY-MM").format("MMM-YYYY") +
      " ~ " +
      moment(`${to.year}-${to.month}`, "YYYY-MM").format("MMM-YYYY")
    );
  };

  const handleOnChange = (newRange) => {
    setSelectedRange(newRange);
    setOpen(false);
    if (onChange) {
      onChange({ from: newRange[0], to: newRange[1] });
    }
  };

  return (
    <div className="rp-custom-month-range-picker">
      <Paper elevation={0} classes={wrapperClasses} className="px-2">
        <InputLabel
          shrink
          margin="dense"
          className="label"
          {...labelProps}
          disableAnimation
        >
          {label}
        </InputLabel>
        <Button
          onClick={() => setOpen(true)}
          variant="outlined"
          endIcon={<Icon fontSize="small">date_range</Icon>}
          size="small"
          className={`text-capitalize p-2 month-picker-button ${
            buttonClassName || ""
          }`}
          disableElevation
          color="primary"
          fullWidth
          {...displayButtonProps}
        >
          {renderRange()}
        </Button>
      </Paper>
      <RpMUIDialog
        open={open}
        onClose={() => setOpen(false)}
        // heading="Select Period"
        maxWidth="xs"
      >
        <RpCustomMonthRangePickerPopup
          onOk={handleOnChange}
          value={selectedRange}
        />
      </RpMUIDialog>
    </div>
  );
}

function RpCustomMonthRangePickerPopup({ onOk, value }) {
  const [selectedYear, setSelectedYear] = useState(
    value && value[0] ? value[0]?.year : moment().format("YYYY")
  );
  const [range, setRange] = useState(value || []);

  const years = useMemo(() => {
    return [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025];
  }, []);
  const months = useMemo(() => {
    return [
      { id: 1, name: "Jan" },
      { id: 2, name: "Feb" },
      { id: 3, name: "Mar" },
      { id: 4, name: "Apr" },
      { id: 5, name: "May" },
      { id: 6, name: "Jun" },
      { id: 7, name: "Jul" },
      { id: 8, name: "Aug" },
      { id: 9, name: "Sep" },
      { id: 10, name: "Oct" },
      { id: 11, name: "Nov" },
      { id: 12, name: "Dec" },
    ];
  }, []);

  const selectedMonths = useMemo(() => {
    if (!range || range.length === 0) return [];
    let from;
    let to;
    const selected = [];
    from = {
      month: range[0].month,
      year: range[0].year,
    };
    selected.push(`${from.year}-${from.month}`);
    if (range.length === 2) {
      to = {
        month: range[1].month,
        year: range[1].year,
      };
      selected.push(`${to.year}-${to.month}`);
    }

    return selected;
  }, [range]);

  const inRange = useMemo(() => {
    if (!range || range.length < 2) return [];

    const selected = [];
    const from = {
      month: range[0].month,
      year: range[0].year,
    };
    const to = {
      month: range[1].month,
      year: range[1].year,
    };

    const yearDiff = to.year - from.year;

    if (yearDiff) {
      for (let j = from.year; j <= to.year; j++) {
        if (j === from.year) {
          for (let i = from.month; i <= 12; i++) {
            selected.push(`${j}-${i}`);
          }
        } else if (j === to.year) {
          for (let i = 1; i <= to.month; i++) {
            selected.push(`${j}-${i}`);
          }
        } else {
          for (let i = 1; i <= 12; i++) {
            selected.push(`${j}-${i}`);
          }
        }
      }
    } else {
      for (let i = from.month; i <= to.month; i++) {
        selected.push(`${from.year}-${i}`);
      }
    }

    return selected;
  }, [range]);

  const handleOnClickMonth = (month) => {
    let r = [...range];
    if (r.length === 0) {
      r.push({ month: month, year: selectedYear });
    } else if (r.length === 1) {
      const current = moment(`${r[0].year}-${r[0].month}`, "YYYY-MM");
      if (
        moment(`${selectedYear}-${month}`, "YYYY-MM").isBefore(current, "month")
      ) {
        r = [{ month: month, year: selectedYear }, r[0]];
      } else {
        r.push({ month: month, year: selectedYear });
      }
    } else if (r.length === 2) {
      r = [{ month: month, year: selectedYear }];
    }
    setRange(r);
  };

  const renderMonths = () => {
    return (
      <div className="months">
        <ul>
          {months.map((m) => (
            <li
              key={m.id}
              onClick={() => handleOnClickMonth(m.id)}
              className={`month ${
                selectedMonths.includes(`${selectedYear}-${m.id}`)
                  ? "selected"
                  : ""
              } ${
                inRange.includes(`${selectedYear}-${m.id}`) ? "in-range" : ""
              }`}
            >
              {m.name}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const handleOnClickPre = () => {
    const idx = years.findIndex((y) => String(selectedYear) === String(y));
    if (idx === 0) return;
    setSelectedYear(years[idx - 1]);
  };

  const handleOnClickNext = () => {
    const idx = years.findIndex((y) => String(selectedYear) === String(y));
    if (idx === years.length - 1) return;
    setSelectedYear(years[idx + 1]);
  };

  const renderHeader = () => {
    return (
      <div className="header">
        <IconButton
          onClick={handleOnClickPre}
          variant="outlined"
          color="primary"
        >
          <Icon>navigate_before</Icon>
        </IconButton>
        <Button
          color="primary"
          variant="text"
          disableElevation
          className="font-weight-bold bg-white"
          size="large"
        >
          {selectedYear}
        </Button>
        <IconButton
          onClick={handleOnClickNext}
          variant="outlined"
          color="primary"
        >
          <Icon>navigate_next</Icon>
        </IconButton>
      </div>
    );
  };

  const handleOnOK = () => {
    if (onOk) {
      if (!range || range.length === 0) return;
      if (range.length === 1) {
        onOk([range[0], range[0]]);
      } else {
        onOk(range);
      }
    }
  };
  return (
    <div className="rp-custom-month-range-picker-popup">
      {renderHeader()}
      {renderMonths()}
      <div className="mt-4 text-center">
        <Button onClick={handleOnOK} variant="contained" color="primary">
          OK
        </Button>
      </div>
    </div>
  );
}
