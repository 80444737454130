import moment from "moment";
import { isInLastNBusinessDay, isTodayBetween25AndEndOfMonth } from "./utils";
import {
  subscriptionPaymentStatus,
  subscriptionPlanPaymentType,
} from "../config/constants";

export const subscriptionHelper = {
  calculateSubscriptionTotal,
  calculateAllSubscriptionTotals,
  calculateMediaTotal,
  isRenewing,
  isDelayedRenewing,
  calculateSubscriptionDate,
  isAutoRenewDateGone,
  totalBrandMatchAmount,
  calculateMediaAndSubscriptionTotal,
  checkIsNewSubscription,
};

function totalBrandMatchAmount(selections = []) {
  return selections.reduce(
    (t, v) => t + parseFloat(Number(v.packageInfo?.brand_match_amount)),
    0
  );
}

function calculateMediaAndSubscriptionTotal(selections = []) {
  return selections.reduce(
    (t, v) =>
      t + parseFloat(v.packageInfo?.amount + v.packageInfo?.extra_charge),
    0
  );
}

function calculateMediaTotal(selections = []) {
  return selections.reduce((t, v) => t + parseFloat(v.packageInfo?.amount), 0);
}

function calculateSubscriptionTotal(selections = []) {
  return selections.reduce(
    (t, v) => t + parseFloat(v.packageInfo?.extra_charge),
    0
  );
}

function calculateAllSubscriptionTotals(selections = []) {
  const totalMediaAmount = calculateMediaTotal(selections);

  const totalSubscriptingFee = selections.reduce(
    (t, v) => t + parseFloat(v.packageInfo?.extra_charge),
    0
  );

  const total = totalMediaAmount + totalSubscriptingFee;
  return { total, totalSubscriptingFee, totalMediaAmount };
}

/**
 * @method isRenewing
 * @description checking is renewing the location
 * @param {*} locationInfo
 * @returns
 */
function isRenewing(locationInfo = {}) {
  const { is_current_subscription_active } = locationInfo;

  if (!is_current_subscription_active) return false;

  // if (moment(subscription_end_date).diff(moment(), "days") > 90) {
  //   return false;
  // }
  return true;
}

/**
 * @method isDelayedRenewing
 * @description check is renewing after previous subscription already ended
 * @param {*} locationInfo
 * @returns
 */
function isDelayedRenewing(locationInfo = {}) {
  const { is_current_subscription_active, subscription_end_date } =
    locationInfo;

  if (!is_current_subscription_active && subscription_end_date) return true;

  return false;
}

/**
 * @method calculateSubscriptionDate
 * @description calculate & return subscription date
 * @param {*} packageInfo
 * @param {*} locationInfo
 * @returns
 */
function calculateSubscriptionDate(
  packageInfo,
  locationInfo,
  isChangingUpcomingPackage
) {
  const { period, payment_type } = packageInfo;

  const {
    is_current_subscription_active,
    subscription_end_date,
    isDelayed,
    upcoming_package_duration,
  } = locationInfo;

  const isNewSubscription = checkIsNewSubscription(locationInfo);

  const today = moment();

  const isTodayBetween25thAndEndOfMonth = isTodayBetween25AndEndOfMonth(
    moment(today.format("YYYY-MM-DD"))
  );

  const isTodayInLast4BusinessDays = isInLastNBusinessDay(
    moment(today.format("YYYY-MM-DD")),
    4
  );

  let startDate = moment(today).add(1, "month").startOf("month");

  if (is_current_subscription_active && subscription_end_date) {
    // if current subscription is active
    startDate = moment(subscription_end_date).add(1, "month").startOf("month");
    if (
      isTodayBetween25thAndEndOfMonth &&
      payment_type === subscriptionPlanPaymentType.STRIPE
    ) {
      /* When current subscription is active, user opt-out before 24, then opt-in on 26, if selected stripe plan while opt-in, or change then need to give next to next month start date. 
      https://hyperlocology.atlassian.net/browse/HYPEPROD-4963?focusedCommentId=15308
      */
      startDate = moment(today).add(2, "months").startOf("month");
    }
  } else if (!isNewSubscription && !isDelayed && subscription_end_date) {
    // when not new subscription and not delayed
    // previously subscribed but no current active subscription
    // treat it as default, no 5 day buffer
    startDate = moment(subscription_end_date).add(1, "month").startOf("month");
  } else if (
    payment_type === subscriptionPlanPaymentType.INVOICE
      ? isTodayInLast4BusinessDays
      : isTodayBetween25thAndEndOfMonth
  ) {
    // For new Subscribers, If today is between the 25th and end of the month then, start date will be 1st of next to next month

    startDate = moment(today).add(2, "months").startOf("month");
  }

  let endDate = moment(today).add(1, "month").startOf("month");
  // if (isNewSubscription) {
  //   startDate = moment(today).add(1, "month").startOf("month");
  // }
  if (isChangingUpcomingPackage) {
    endDate = moment(startDate)
      .add(parseInt(upcoming_package_duration), "month")
      .subtract(1, "day");
  } else {
    endDate = moment(startDate)
      .add(parseInt(period), "month")
      .subtract(1, "day");
  }

  return { startDate, endDate };
}

/**
 * @method isAutoRenewDateGone
 * @description return is auto renewal date which the 1st of subscription end month has gone
 * @param {*} location
 * @returns
 */
function isAutoRenewDateGone(location) {
  const { subscription_end_date } = location;
  const currentSubEndDate = moment(subscription_end_date);
  if (!currentSubEndDate.isValid()) {
    return false;
  }

  const firstDayOfEndMonth = currentSubEndDate.startOf("month");

  return moment().isAfter(firstDayOfEndMonth);
}

/**
 * @method checkIsNewSubscription
 * @description Only for stripe, Check if location is new subscription, by checking if payment status is UNPAID and stripe_subscription_id exists
 * @param {Object} location
 * @returns {Boolean}
 */
function checkIsNewSubscription(location) {
  const {
    payment_status,
    stripe_subscription_id,
    is_current_subscription_active,
  } = location;
  return !is_current_subscription_active &&
    (payment_status === subscriptionPaymentStatus.UNPAID ||
      payment_status === subscriptionPaymentStatus.PENDING) &&
    stripe_subscription_id
    ? true
    : false;
}
