import React, { useContext, useEffect } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { GlobalContext } from "../contexts/contexts";
import ProtectedRoute from "../ProtectedRoute";
import Home from "../../containers/Home";
import DashboardLayout from "../../components/layouts/dashboard/DashboardLayout";
import ParticipantLayout from "../../components/layouts/participant/ParticipantLayout";
import ResetPassword from "../../containers/auth/ResetPassword";
import NotFound404 from "../../components/NotFound404";
import ForgotPasswordForm from "../../containers/auth/ForgotPasswordForm";
import LoginForm from "../../containers/auth/LoginForm";
import SelfSubscription from "../../containers/selfSubscription/SelfSubscription";
import Signup from "../../containers/auth/signup/Signup";
import ValidateEmailSignupLink from "../../containers/auth/validateEmailSignupLink/ValidateEmailSignupLink";
import CreatePassword from "../../containers/auth/createPassword/CreatePassword";
import LocationSubscription from "../../containers/subscriptionPackages/LocationSubscription";
import MSActivation from "../../containers/morganStanley/MSActivation";
import MSLocationSubscription from "../../containers/morganStanley/subscriptionPackages/MSLocationSubscription";
import ManageLocationSubscription from "../../containers/subscriptionPackages/manageActiveSubscription/ManageLocationSubscription";
import ResourceCenter from "../../containers/resourceCenter/ResourceCenter";
import { useDispatch } from "react-redux";
import { settingActions } from "../../actions/settingActions";
import CreateCampaign from "../../pages/createCampaign";
import LaunchDigitalCampaigns from "../../pages/LaunchDigitalCampaigns";
import DigitalReportsList from "../../pages/DigitalReportsList";
import LaunchPostcardCampaigns from "../../pages/LaunchPostcardCampaigns";
import PostcardReportsList from "../../pages/PostcardReportsList";
import LocationListPage from "../../pages/LocationListPage";
import CampaignReportsPage from "../../containers/admin/CampaignReportsPage";
import PostcardReportsPage from "../../containers/admin/PostcardReportsPage";
import SubscriptionPaymentReturnPage from "../../pages/stripe/SubscriptionPaymentReturnPage";
import SubscriptionInvoice from "../../containers/subscriptionPackages/subscriptionInvoices";
import JoinCampaignPaymentReturnPage from "../../pages/stripe/JoinCampaignPaymentReturnPage";
import CampaignPausePage from "../../pages/campaign/CampaignPausePage";
import RequestFromBrandPage from "../../pages/RequestFromBrandPage";
import BoostCampaignPage from "../../pages/campaign/BoostCampaignPage";
import BoostCampaignPaymentReturnPage from "../../pages/stripe/BoostCampaignPaymentReturnPage";
import { rpGTM } from "../../config/rpGTM";

const MainRoutes = () => {
  const { loggedInUserRole, selectedBrand, loggedInUser } =
    useContext(GlobalContext);

  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedBrand?.id) {
      dispatch(settingActions.getSettings(selectedBrand?.id));
    }
  }, [selectedBrand?.id, dispatch]);

  useEffect(() => {
    if (loggedInUser?.id) {
      rpGTM.setUserDetails({
        userId: loggedInUser.id,
        userEmail: loggedInUser.email,
        userRole: loggedInUser?.is_master_pass
          ? "Admin"
          : loggedInUser?.role?.name || loggedInUserRole,
      });
    }
  }, [loggedInUserRole, loggedInUser]);

  return (
    <Switch>
      <Route exact path={path} component={LoginForm} />
      <Route exact path={`${path}login`} component={LoginForm} />
      <Route exact path={`${path}signup`} component={Signup} />
      <Route
        exact
        path={`${path}forgot-password`}
        component={ForgotPasswordForm}
      />
      <Route exact path={`${path}password-reset`} component={ResetPassword} />
      <Route
        exact
        path={`${path}validate-signup-link`}
        component={ValidateEmailSignupLink}
      />
      <Route exact path={`${path}create-password`} component={CreatePassword} />
      <Route
        exact
        path={`${path}self-subscribe/:slug`}
        component={SelfSubscription}
      />

      {loggedInUserRole === "PR" ? (
        <ProtectedRoute
          exact
          path={`${path}home`}
          component={Home}
          roles={["PR"]}
        />
      ) : (
        <Route
          exact
          path={`${path}home`}
          render={() => <Redirect to="dashboard" />}
        />
      )}

      {loggedInUserRole === "PR" && !selectedBrand ? (
        <Route
          path={`${path}`}
          render={() => <Redirect to={`${path}home`} />}
        />
      ) : null}

      <ProtectedRoute
        path={`${path}participant`}
        component={ParticipantLayout}
        roles={["PR"]}
      />
      <ProtectedRoute
        path={`${path}dashboard`}
        component={DashboardLayout}
        roles={["PR", "PC", "BO", "BU"]}
      />

      <ProtectedRoute
        path={`${path}subscription-packages`}
        component={
          selectedBrand?.id === 1
            ? MSLocationSubscription
            : LocationSubscription
        }
        roles={["PR", "BU", "BO"]}
      />

      <ProtectedRoute
        path={`${path}manage-subscription`}
        component={ManageLocationSubscription}
        roles={["PR", "BU", "BO"]}
      />

      <ProtectedRoute
        path={`${path}education-center/:brandId`}
        component={ResourceCenter}
        roles={["PR", "BU", "BO", "PC"]}
      />

      <ProtectedRoute
        path={`${path}digital-campaigns`}
        component={LaunchDigitalCampaigns}
        roles={["PR"]}
      />

      <ProtectedRoute
        path={`${path}postcard-campaigns`}
        component={LaunchPostcardCampaigns}
        roles={["PR"]}
      />

      <ProtectedRoute
        path={`${path}campaign-report`}
        component={
          loggedInUserRole === "PR" ? DigitalReportsList : CampaignReportsPage
        }
        roles={loggedInUserRole === "PR" ? ["PR"] : ["BO", "BU", "PC"]}
      />

      <ProtectedRoute
        path={`${path}postcard-report`}
        component={
          loggedInUserRole === "PR" ? PostcardReportsList : PostcardReportsPage
        }
        roles={loggedInUserRole === "PR" ? ["PR"] : ["BO", "BU", "PC"]}
      />

      <ProtectedRoute
        path={`${path}morgan-stanley-activation/:locID?`}
        component={MSActivation}
        roles={["PR", "BU"]}
      />

      {/* <ProtectedRoute
        path={`${path}CampaignConfirmation`}
        component={PostcardCampaignConfirmation}
        roles={["PR"]}
      /> */}

      <ProtectedRoute
        path={`${path}location-reports`}
        component={LocationListPage}
        roles={["BO", "BU", "PC"]}
      />

      <ProtectedRoute
        path={`${path}create-campaign`}
        component={CreateCampaign}
        roles={["BO", "BU"]}
      />

      <ProtectedRoute
        exact
        path={`${path}subscription-return`}
        component={SubscriptionPaymentReturnPage}
        roles={["BO", "BU", "PC", "PR"]}
      />

      <ProtectedRoute
        exact
        path={`${path}join-campaign-return`}
        component={JoinCampaignPaymentReturnPage}
        roles={["BO", "BU", "PC", "PR"]}
      />

      <ProtectedRoute
        exact
        path={`${path}boost-campaign-return`}
        component={BoostCampaignPaymentReturnPage}
        roles={["BO", "BU", "PC", "PR"]}
      />

      <ProtectedRoute
        exact
        path={`${path}campaign-pause/:campaignId`}
        component={CampaignPausePage}
        roles={["BO", "BU", "PC", "PR"]}
      />

      <ProtectedRoute
        path={`${path}subscription-invoice`}
        component={SubscriptionInvoice}
        roles={["PR"]}
      />

      <ProtectedRoute
        path={`${path}requests`}
        component={RequestFromBrandPage}
        roles={["BO", "BU"]}
      />

      <ProtectedRoute
        path={`${path}boost-campaign/:campaignID`}
        component={BoostCampaignPage}
        roles={["BO", "BU", "PR"]}
      />

      <Route path={`${process.env.PUBLIC_URL}/404`} component={NotFound404} />

      <Redirect to={`${process.env.PUBLIC_URL}/404`} />
      <Route component={NotFound404} />
      <Redirect to={path} />
    </Switch>
  );
};

export default MainRoutes;
