import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import resetPasswordSaga from "./resetPasswordSaga";
import userSaga from "./userSaga";
import brandSaga from "./brandSaga";
import campaignSaga from "./campaignSaga";
import settingsSaga from "./settingsSaga";

/**
 * @method rootSaga
 * @description root saga watch for all other watcher sagas and act
 */
export default function* rootSaga() {
  yield all([
    authSaga(),
    resetPasswordSaga(),
    userSaga(),
    brandSaga(),
    campaignSaga(),
    settingsSaga(),
  ]);
  // code after all-effect
}
