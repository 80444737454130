import React, { useMemo } from "react";
import { Chip, makeStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const commonStyles = {
  border: 0,
  borderRadius: 3,
  fontWeight: 700,
  fontSize: ".6rem",
  letterSpacing: 0,
  height: 18,
  minWidth: 65,
  paddingTop: 2,
};

const errorStyle = makeStyles(() => ({
  root: {
    ...commonStyles,
    background: "#FFE3E3", // green[100],
    color: "#FD3737", //green[500],
    borderColor: "#FFE3E3!important",
  },
}));

const successStyle = makeStyles(() => ({
  root: {
    ...commonStyles,
    background: "#1AD59833",
    color: "#1AD598",
  },
}));

const infoStyle = makeStyles(() => ({
  root: {
    ...commonStyles,
    background: "#1e8fff1a", // "#E0F2F1",
    color: "#1E8FFF", // "#009688",
  },
}));

const upcomingRenewalStyle = makeStyles(() => ({
  root: {
    ...commonStyles,
    background: "#11525b",
    color: "#04A999",
  },
}));

const holdStyle = makeStyles(() => ({
  root: {
    ...commonStyles,
    background: "#7777771A",
    color: "#707070",
  },
}));
const warnStyle = makeStyles(() => ({
  root: {
    ...commonStyles,
    background: "#F9B95933",
    color: "#F9B959",
  },
}));

const arStyle = makeStyles(() => ({
  root: {
    ...commonStyles,
    background: "#04a99933",
    color: "#04a999",
  },
}));

const defaultStyle = makeStyles(() => ({
  root: {
    ...commonStyles,
  },
}));

const StatusChip = ({
  label = "",
  status = "default",
  showIcon = false,
  ...props
}) => {
  const classes = () => {
    if (status === "success") {
      return successStyle();
    } else if (status === "error") {
      return errorStyle();
    } else if (status === "warn") {
      return warnStyle();
    } else if (status === "arStatus") {
      return arStyle();
    } else if (status === "info") {
      return infoStyle();
    } else if (status === "hold") {
      return holdStyle();
    } else if (status === "upcoming renewal") {
      return upcomingRenewalStyle();
    } else {
      return defaultStyle();
    }
  };

  const icon = useMemo(() => {
    if (status === "inactive") {
      return <InfoOutlinedIcon fontSize="small" />;
    }
    return null;
  }, [status]);

  return (
    <Chip
      size="small"
      label={label}
      color="primary"
      icon={showIcon ? icon : null}
      classes={classes()}
      {...props}
    />
  );
};

export default StatusChip;
