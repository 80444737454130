import React from "react";
import SubscriptionPackages from "./SubscriptionPackages";
import UnsubscribedLocations from "./UnsubscribedLocations";
import ParticipantPageLayout from "../../../components/layouts/page/ParticipantPageLayout";

export default function PackageSelection({
  selectedBrand,
  pageInfo,
  packages,
  locations,
  selections,
  onContinue,
  loadMoreLocations,
  renderLocationFilters,
  totalLocationCount,
}) {
  return (
    <ParticipantPageLayout className="subscription-package-selection mx-lg-5 mx-3">
      <SubscriptionPackages
        packages={packages}
        pageInfo={pageInfo}
        selectedBrand={selectedBrand}
      />
      <UnsubscribedLocations
        packages={packages}
        locations={locations}
        onContinue={onContinue}
        selections={selections}
        loadMoreLocations={loadMoreLocations}
        pageInfo={pageInfo}
        renderLocationFilters={renderLocationFilters}
        totalLocationCount={totalLocationCount}
      />
    </ParticipantPageLayout>
  );
}
