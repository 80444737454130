import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { createStyles } from "@material-ui/styles";
import { IconButton, Paper, withStyles } from "@material-ui/core";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { logger } from "../helpers/loggerHelper";

const MUIDateRange = ({
  classes,
  onChangeDate,
  initialRange,
  disableRangeSelection,
  label,
  labelProps,
  isEndDateRequired = true,
  format = "DD MMM, YYYY",
  disablePast = false,
  isOptional = false,
}) => {
  const today = moment().startOf("day");

  const validInitialRange =
    initialRange && initialRange.from && initialRange.to;

  const initialStart = isOptional
    ? null
    : validInitialRange
      ? moment(
          `${initialRange.from.year}-${initialRange.from.month}-${initialRange.from.date}`,
          "YYYY-MM-DD"
        )
      : today;

  const initialEnd = isOptional
    ? null
    : validInitialRange
      ? moment(
          `${initialRange.to.year}-${initialRange.to.month}-${initialRange.to.date}`,
          "YYYY-MM-DD"
        )
      : today;

  const [selectedRange, setSelectedRange] = useState([
    initialStart,
    initialEnd,
  ]);
  const [selectingEndDate, setSelectingEndDate] = useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  useEffect(() => {
    if (isOptional && !validInitialRange) {
      setSelectedRange([null, null]);
      onChangeDate([null, null]);
    } else if (validInitialRange) {
      setSelectedRange([
        moment(
          `${initialRange.from.year}-${initialRange.from.month}-${initialRange.from.date}`,
          "YYYY-MM-DD"
        ),
        moment(
          `${initialRange.to.year}-${initialRange.to.month}-${initialRange.to.date}`,
          "YYYY-MM-DD"
        ),
      ]);
    }
  }, [initialRange, validInitialRange, isOptional]);

  const handleDateChange = (date) => {
    if (!date.isValid()) {
      logger.error("Invalid date format", { date });
      return;
    }

    let newRange;

    if (selectingEndDate) {
      if (date.isBefore(selectedRange[0], "day")) {
        isEndDateRequired && (newRange = [date, null]);
        !isEndDateRequired && (newRange = [date, date]);
        setSelectingEndDate(true);
      } else {
        newRange = [selectedRange[0], date];
        setSelectingEndDate(false);
        setIsPickerOpen(false);
      }
    } else {
      isEndDateRequired && (newRange = [date, null]);
      !isEndDateRequired && (newRange = [date, date]);
      setSelectingEndDate(true);
      setIsPickerOpen(true);
    }

    if (date && date.isValid()) {
      setSelectedRange(newRange);
    } else {
      logger.error("Invalid date input", { date });
    }

    if (newRange[0] && newRange[1]) {
      onChangeDate(newRange.map((d) => d.format("YYYY-MM-DD")));
    }
  };

  const isDateDisabled = useMemo(
    () => (date) => {
      const [start, end] = selectedRange;

      if (disablePast && moment(date).isBefore(today, "day")) {
        return true;
      }

      const minStartDate = end
        ? end.clone().subtract(5, "years").startOf("day")
        : moment().subtract(5, "years").startOf("day");

      const maxEndDate = start
        ? start.clone().add(5, "years").endOf("day")
        : moment().add(5, "years").endOf("day").subtract(1, "day");
      const adjustedMaxEndDate = maxEndDate.subtract(1, "day");

      return selectingEndDate
        ? moment(date).isAfter(adjustedMaxEndDate)
        : moment(date).isBefore(minStartDate);
    },
    [selectedRange, selectingEndDate, disablePast, today]
  );

  const renderDay = useMemo(
    // eslint-disable-next-line react/display-name
    () => (date, _selectedDate, dayInCurrentMonth) => {
      let dateClone = date.clone();
      const [start, end] = selectedRange;

      const isInRange = dateClone.isBetween(start, end, null, "[]");
      const isStart = dateClone.isSame(start, "day");
      const isEnd = dateClone.isSame(end, "day");

      const wrapperClassName = clsx({
        [classes.highlight]: isInRange || isStart || isEnd,
        [classes.highlightStart]: isStart,
        [classes.highlightEnd]: isEnd,
      });

      const dayClassName = clsx(classes.day, {
        [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
        [classes.disabled]: isDateDisabled(date),
      });

      return (
        <div className={wrapperClassName}>
          <IconButton className={dayClassName}>
            <span>{dateClone.format("DD")}</span>
          </IconButton>
        </div>
      );
    },
    [selectedRange, isDateDisabled, classes]
  );

  const handlePickerOpen = () => {
    setIsPickerOpen(true);
  };

  return (
    <Paper elevation={0} className={clsx("px-2 mui-date-picker", classes.root)}>
      {label && (
        <div className={classes.label} {...labelProps}>
          {label}
        </div>
      )}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          value={selectedRange[0]}
          onChange={handleDateChange}
          renderDay={renderDay}
          minDate={
            disablePast ? today : moment().subtract(5, "years").startOf("day")
          }
          maxDate={moment().add(5, "years").endOf("day")}
          shouldDisableDate={isDateDisabled}
          disabled={disableRangeSelection}
          format={format}
          disableToolbar
          variant="inline"
          labelFunc={(date) => {
            if (!date || !selectedRange[0] || !selectedRange[1]) {
              return "";
            }
            if (date.isValid()) {
              return `${selectedRange[0].format(
                format
              )} - ${selectedRange[1].format(format)}`;
            } else {
              return "Invalid Date";
            }
          }}
          open={isPickerOpen}
          onOpen={handlePickerOpen}
          onClose={() => setIsPickerOpen(false)}
          InputProps={{
            readOnly: true,
            classes: {
              root: classes.inputRoot,
              input: classes.input,
              underline: classes.noUnderline,
            },
          }}
          InputAdornmentProps={{
            classes: {
              root: classes.adornment,
            },
          }}
        />
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

MUIDateRange.displayName = "MUIDateRange";

const styles = createStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  highlight: {
    background: "#04a999",
    color: (props) =>
      props.backgroundColor ? "#1B262C" : theme.palette.common.white,
  },
  highlightStart: {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  highlightEnd: {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  inputRoot: {
    color: (props) => (props.backgroundColor ? "#1B262C" : "white"),
    backgroundColor: (props) =>
      props.backgroundColor ? props.backgroundColor : "#04a999",
    padding: (props) => (props.backgroundColor ? "5px 12px" : "8px 12px"),
    borderRadius: "4px",
    marginBottom: 4,
    border: (props) => (props.backgroundColor ? "1px solid #1B262C" : "none"),
    transition: "border-color 0.2s",
    "&:hover": {
      border: (props) => (props.backgroundColor ? "1px solid #1B262C" : "none"),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: (props) => (props.backgroundColor ? "#1B262C" : "white"),
    },
  },
  input: {
    fontSize: (props) => (props.backgroundColor ? "14px" : "10px"),
    color: (props) => (props.backgroundColor ? "#1B262C" : "white"),
    padding: (props) => (props.backgroundColor ? "8px 0" : "6px 0"),
    fontFamily: (props) =>
      props.backgroundColor
        ? "Open Sans, Montserrat, Roboto, -apple-system"
        : "inherit",
  },
  adornment: {
    color: (props) => (props.backgroundColor ? "#1B262C" : "white"),
    "& .MuiIconButton-root": {
      color: "inherit",
    },
  },
  label: {
    fontSize: "11px",
    fontWeight: 600,
    marginBottom: ".1rem",
    fontFamily:
      'Open Sans, Montserrat, Roboto, Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  noUnderline: {
    "&:before": {
      borderBottom: "none !important",
    },
  },
}));

export default withStyles(styles)(MUIDateRange);
