import React, { useMemo } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Icon,
  StepConnector,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import clsx from "clsx";

export default function RpSteps({ activeStep = 0, steps = [], type = "" }) {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<RpStepConnector />}
    >
      {steps.map((label) => {
        return (
          <Step key={label}>
            <StepLabel
              StepIconComponent={StepIcon}
              StepIconProps={{
                type: type,
              }}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

const useStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    background: "#239084",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  // completed: {
  //   backgroundImage:
  //     "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  // },
});

function StepIcon(props) {
  const classes = useStepIconStyles();
  const { icon, active, completed, type } = props;

  const icons = useMemo(() => {
    if (type === "ms_activation") {
      return {
        1: "assignment",
        2: "auto_fix_high",
        3: "request_quote",
      };
    }
    return {
      1: "fact_check",
      2: "receipt",
      3: "payment",
    };
  }, [type]);
  return (
    <Icon
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
      color="primary"
    >
      {icons[icon]}
    </Icon>
  );
}

const RpStepConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#239084",
    },
  },
  completed: {
    "& $line": {
      backgroundImage: "none !important",
      backgroundColor: "#239084",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);
