import React from "react";
import { Virtuoso } from "react-virtuoso";

const RpVirtualList = ({
  items = [],
  renderItem,
  useWindowScroll = true,
  ...rest
}) => {
  return (
    <Virtuoso
      useWindowScroll={useWindowScroll}
      totalCount={items?.length || 0} // Total number of items to render
      itemContent={(index) => (
        <div id={`item-${index}`} className="custom-list-item">
          {renderItem(items?.[index], index)}{" "}
          {/* Dynamically render each item */}
        </div>
      )}
      {...rest}
    />
  );
};

export default RpVirtualList;
