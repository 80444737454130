import React from "react";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { InputLabel, makeStyles } from "@material-ui/core";

import FormikMUIAutocompleteSelectInput from "../../../components/muiCustom/select/FormikMUIAutocompleteSelectInput";
import RpMuiSelect from "../../../components/muiCustom/select/RpMuiSelect";
import { RpDropzone } from "../../../components/fileUploadInput/RpDropzone";
import NumberRangeMDInputFormik from "../../../components/muiCustom/number/NumberRangeMDInputFormik";
import {
  DatePickerField,
  emailMDInputFormik,
  numberMDInputFormik,
  textMDInputFormik,
} from "../../../helpers/formInputs";
import MUIDateRange from "../../../components/MUIDateRange";
import DownloadLinkButton from "../../../components/muiCustom/buttons/DownloadLinkButton";
import { getTemplateUrl } from "./formConfigs";

const useStyles = makeStyles(() => ({
  inputLabel: {
    color: "#1B262C",
    fontSize: ".92rem",
    fontWeight: "700",
    margin: "0px",
    padding: "4px 0px 3px 10px",
  },
  inputWidth: {
    maxWidth: "1500px !important",
    margin: "0px !important",
    padding: "0px !important",
  },
  // Add custom styles for MUIDateRange
  dateRangeInput: {
    color: "#1B262C !important",
    backgroundColor: "white !important",
    padding: "6px 14px !important",
    borderRadius: "4px !important",
    marginTop: "8px !important",
    marginBottom: "10px !important",
    border: "1px solid #6c757dab !important",
    "&:hover": {
      border: "1px solid #1B262C !important",
    },
    "&:focus-within": {
      border: "2px solid #1B262C !important",
      padding: "7px 13px !important",
    },
  },
  dateRangeText: {
    fontSize: "14px !important",
    color: "#1B262C !important",
  },
  dateRangeIcon: {
    color: "#1B262C !important",
    "& .MuiSvgIcon-root": {
      color: "#1B262C !important",
    },
  },
}));

const FormField = ({ type, name, label, options, ...props }) => {
  const classes = useStyles();

  if (!type) {
    return null;
  }

  switch (type) {
    case "dropdownWithAll":
      return (
        <>
          <InputLabel
            shrink
            margin="dense"
            disableAnimation
            className={classes.inputLabel}
            required={props?.required}
          >
            {label}
          </InputLabel>
          <Field name={name}>
            {({ field, form }) => {
              const hasError =
                form.touched[field.name] && form.errors[field.name];
              return (
                <>
                  <RpMuiSelect
                    options={options}
                    multiple={props?.multiple}
                    value={field.value || (props?.multiple ? [] : "")}
                    onChange={(selectedValues) => {
                      if (props?.selectAll && selectedValues.includes("all")) {
                        // If "all" is selected, select all options
                        form.setFieldValue(
                          field.name,
                          options.map((opt) => opt.value)
                        );
                      } else {
                        form.setFieldValue(field.name, selectedValues);
                      }
                    }}
                    placeholder=""
                    size="small"
                    {...props}
                    required={props.required}
                    error={hasError}
                    className={`w-100 px-2 pr-0 mb-1 mt-2`}
                  />
                  {hasError && (
                    <div
                      className="text-danger px-2 mb-3 fs-12 fw-sb"
                      style={{ fontSize: "12px" }}
                    >
                      {form.errors[field.name]}
                    </div>
                  )}
                </>
              );
            }}
          </Field>
        </>
      );

    case "dropdown":
      return (
        <>
          <InputLabel
            shrink
            margin="dense"
            disableAnimation
            className={classes.inputLabel}
            required={props?.required}
          >
            {label}
          </InputLabel>
          <Field name={name}>
            {(fieldProps) => (
              <FormikMUIAutocompleteSelectInput
                options={options}
                multiple={
                  name === "campaign_type" ||
                  name === "payment_method" ||
                  name === "fk_campaign_id"
                    ? false
                    : true
                }
                size="large"
                {...fieldProps}
                {...props}
                className={`w-100 px-2 pr-0 p-0`}
                disableCloseOnSelect={
                  name === "campaign_type" ||
                  name === "payment_method" ||
                  name === "fk_campaign_id"
                    ? false
                    : true
                }
              />
            )}
          </Field>
        </>
      );

    case "file":
      return (
        <Field name={name}>
          {({ field, form }) => (
            <RpDropzone
              field={field}
              form={form}
              label={label}
              multiple={field?.multiple || false}
              {...props}
              className="w-100 px-2 py-2 pr-0 p-0"
              downloadTemplate={
                <>
                  {props?.showTemplate && props?.templateConfig && (
                    <DownloadLinkButton
                      downloadLink={props.templateConfig.downloadLink}
                      getDownloadUrl={() =>
                        getTemplateUrl(props?.templateConfig)
                      }
                      buttonText={
                        props?.templateConfig?.buttonText || "Download Template"
                      }
                      className={`${props?.className || ""}`}
                      // startIcon={props?.startIcon}
                      color="dark"
                      variant="contained"
                      customStyle={{ fontSize: ".62rem" }}
                    />
                  )}
                  {props?.showTemplate && props?.secondaryTemplateConfig && (
                    <DownloadLinkButton
                      downloadLink={props.secondaryTemplateConfig.downloadLink}
                      getDownloadUrl={() =>
                        getTemplateUrl(props?.secondaryTemplateConfig)
                      }
                      buttonText={
                        props?.secondaryTemplateConfig?.buttonText ||
                        "Download Template"
                      }
                      color="dark"
                      variant="contained"
                      className={`${props?.className || ""} ml-3`}
                      customStyle={{ fontSize: ".62rem" }}
                      // startIcon={props?.startIcon}
                    />
                  )}
                </>
              }
            />
          )}
        </Field>
      );

    case "date":
      return (
        <>
          <Field name={name}>
            {(fieldProps) =>
              DatePickerField({
                disablePast: true,
                variantType: "styled",
                className: `w-100 px-2 pr-0 p-0 m-0`,
                size: "small",
                label,
                ...fieldProps,
                ...props,
                readOnly: true,
                format: "MM/DD/YYYY",
              })
            }
          </Field>
        </>
      );

    case "text":
      return (
        <>
          <InputLabel
            shrink
            margin="dense"
            disableAnimation
            className={classes.inputLabel}
            required={props?.required}
          >
            {label}
          </InputLabel>
          <Field name={name}>
            {(fieldProps) =>
              textMDInputFormik({
                row: 4,
                size: "small",
                formGroupClass: classes.inputWidth,
                ...fieldProps,
                ...props,
                className: "w-100 px-2 py-2 pr-0 p-0",
              })
            }
          </Field>
        </>
      );

    case "email":
      return (
        <Field name={name}>
          {(fieldProps) =>
            emailMDInputFormik({
              label,
              ...fieldProps,
              ...props,
            })
          }
        </Field>
      );

    case "number":
      return (
        <>
          <InputLabel
            shrink
            margin="dense"
            disableAnimation
            className={classes.inputLabel}
            required={props?.required}
          >
            {label}
          </InputLabel>
          <Field name={name}>
            {(fieldProps) =>
              numberMDInputFormik({
                // label,
                type: "number",
                size: "small",
                className: `${classes.inputWidth} p-0`,
                ...fieldProps,
                ...props,
              })
            }
          </Field>
        </>
      );

    case "radio":
      return (
        <Field name={name}>
          {({ field, meta }) => (
            <Form.Group>
              <Form.Label required={props?.required}>{label}</Form.Label>
              {options?.map((option) => (
                <Form.Check
                  key={option.value}
                  type="radio"
                  label={option.label}
                  value={option.value}
                  checked={field.value === option.value}
                  onChange={() =>
                    field.onChange({
                      target: { name: field.name, value: option.value },
                    })
                  }
                  isInvalid={meta.touched && meta.error}
                />
              ))}
              <Form.Control.Feedback type="invalid">
                {meta.error}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Field>
      );

    case "dateRange":
      return (
        <Field
          name={name}
          validate={(value) => {
            if (
              name !== "flight_dates" &&
              (!value || !value?.startDate || !value?.endDate)
            ) {
              return "Please select a valid start and end date.";
            }

            return undefined; // No error
          }}
        >
          {({ field, form }) => {
            const hasError =
              form.touched[field.name] &&
              (form.errors[field.name]?.startDate ||
                form.errors[field.name]?.endDate);
            const isOptional = field.name === "flight_dates";
            return (
              <>
                <InputLabel
                  shrink
                  margin="dense"
                  disableAnimation
                  className={`${classes.inputLabel} `}
                  required={!isOptional ? true : props?.required}
                >
                  {label}
                </InputLabel>
                <MUIDateRange
                  initialRange={isOptional ? null : form.values[field.name]}
                  onChangeDate={(dates) => {
                    form.setFieldValue(field.name, {
                      startDate: dates[0],
                      endDate: dates[1] ? dates[1] : dates[0],
                    });
                  }}
                  error={hasError}
                  isEndDateRequired={false}
                  helperText={
                    hasError && !isOptional
                      ? `Start: ${form.errors[field.name]?.startDate || ""}, End: ${
                          form.errors[field.name]?.endDate || ""
                        }`
                      : ""
                  }
                  classes={{
                    inputRoot: classes.dateRangeInput,
                    input: classes.dateRangeText,
                    adornment: classes.dateRangeIcon,
                  }}
                  format="MM/DD/YYYY"
                  disablePast={props?.disablePast || false}
                  isOptional={isOptional}
                />
                {hasError && !isOptional && (
                  <div
                    className="text-danger mt-1"
                    style={{ fontSize: "12px" }}
                  >
                    Start: {form.errors[field.name]?.startDate}, End:{" "}
                    {form.errors[field.name]?.endDate}
                  </div>
                )}
              </>
            );
          }}
        </Field>
      );

    case "numberRange":
      return (
        <Field name={name}>
          {(fieldProps) =>
            NumberRangeMDInputFormik({
              label,
              ...fieldProps,
              ...props,
            })
          }
        </Field>
      );

    case "checkbox":
      return (
        <Field name={name}>
          {({ field, form }) => (
            <div className="w-100 px-2">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      form.setFieldValue(field.name, isChecked);
                      // Clear dependent fields based on checkbox state
                      props.dependentFields?.forEach(
                        ({ field, clearValue, whenChecked }) => {
                          if (isChecked === whenChecked) {
                            form.setFieldValue(field, clearValue);
                          }
                        }
                      );
                    }}
                    size="small"
                    color="primary"
                    {...props}
                  />
                }
                label={label}
                className="mb-2 pb-2 mr-2"
                labelPlacement="center"
              />
            </div>
          )}
        </Field>
      );

    default:
      return null;
  }
};

export default FormField;
