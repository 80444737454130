import React, { memo } from "react";

const WhatYouGetBySubscribe = () => {
  return (
    <div className="container what-you-get-by-subscribe">
      <div className="row justify-content-center align-items-stretch">
        <div className="col-12 my-3 mb-5">
          <h2 className="main-heading">What you get by subscribing</h2>
          <div className="descriptive-text">
            Hyperlocology and JINYA Ramen Bar have teamed up to provide
            sophisticated
            <br />
            brand advertising for every JINYA Ramen Bar restaurant.
          </div>
        </div>
        <div className="col-md-4 my-3">
          <div className="card benefit shadow-sm">
            <a
              href="https://youtu.be/NcTUak3LUGA"
              target="_blank"
              className="card-img-top text-center"
              rel="noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/subscription/videocam.svg`}
                alt=""
                className="img-fluid"
              />
            </a>
            {/* <div className="card-img-top text-center py-4">
              <IconButton>
                <VideocamIcon fontSize="large" color="primary" />
              </IconButton>
            </div> */}
            <div className="card-body">
              <h6 className="content">
                Check out this one minute video to see why JINYA Ramen Bar
                partnered with Hyperlocology!
              </h6>
            </div>
          </div>
        </div>

        <div className="col-md-4 my-3">
          <div className="card benefit shadow-sm">
            <a
              href="https://trello-attachments.s3.amazonaws.com/5f8884836497c160f5a61192/6076dc8c0c2c7d520e04d556/5de5846067e28c4d1f2e88e4e10b701d/JINYA_Ramen_Bar_Franchisee_FAQ_Booklet.pdf"
              target="_blank"
              className="card-img-top text-center"
              rel="noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/subscription/material-question-answer.svg`}
                alt=""
                className="img-fluid"
              />
            </a>
            {/* <div className="card-img-top text-center py-4">
              <IconButton>
                <CommentIcon fontSize="large" color="primary" />
              </IconButton>
            </div> */}
            <div className="card-body">
              <h6 className="content">
                Click here to learn more about advertising with Hyperlocology!
              </h6>
            </div>
          </div>
        </div>

        <div className="col-md-4 my-3">
          <div className="card benefit shadow-sm">
            <a
              href="https://trello-attachments.s3.amazonaws.com/5f8884836497c160f5a61192/6076dc8c0c2c7d520e04d556/cfc19296ae84e85d27b4b228b510a8a8/JINYA_Dashboard_Instruction_Manual.pdf"
              target="_blank"
              className="card-img-top text-center"
              rel="noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/subscription/open-document.svg`}
                alt=""
                className="img-fluid"
              />
            </a>
            {/* <div className="card-img-top text-center py-4">
              <IconButton>
                <DescriptionIcon fontSize="large" color="primary" />
              </IconButton>
            </div> */}
            <div className="card-body">
              <h6 className="content">
                See how to easily launch campaigns to drive more customers to
                your restaurant!
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(WhatYouGetBySubscribe);
