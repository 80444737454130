import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { GlobalContext, LoaderContext } from "../helpers/contexts/contexts";
import PageLayout from "../components/layouts/page/PageLayout";
import CampaignAvailableToJoin from "../containers/postcardCampaign/widgets/campaignAvailableToJoin";
import useFetcher from "../helpers/hooks/useFetcher";
import { campaignService } from "../services";

const LaunchPostcardCampaigns = () => {
  const isFirstLoadComplete = useRef(false);
  const { loggedInUser, selectedBrand } = useContext(GlobalContext);
  const loader = useContext(LoaderContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabType, setTabType] = useState("limited_time_offers");
  const [currentPage, setCurrentPage] = useState(1);

  const reqData = useMemo(() => {
    return {
      id: selectedBrand.id,
      fk_participant_id: loggedInUser.participantData.id,
      userId: loggedInUser.participantData.id,
      tabType: tabType,
      page: currentPage,
      time: Date.now().toString(),
    };
  }, [selectedBrand, loggedInUser, tabType, currentPage]);

  const { data, isLoading, isValidating } = useFetcher(
    reqData,
    campaignService.launchPostcardCampaigns,
    { revalidateOnFocus: false }
  );

  const launchCampaignData = data?.postcardTabsData?.data || [];
  const pageCount = data?.postcardTabsData?.pageCount || 0;
  const totalRecordsData = data?.postcardTabsData?.totalRecordsData || 0;
  const tabsCounts = data?.postcardTabsCountData || {
    limited_time_offers: 0,
    always_available: 0,
    draft: 0,
  };

  useEffect(() => {
    loader(isLoading || isValidating);
  }, [isLoading, loader, isValidating]);

  useEffect(() => {
    if (!isFirstLoadComplete.current && !isLoading) {
      isFirstLoadComplete.current = true;

      if (
        tabType === "limited_time_offers" &&
        !tabsCounts?.limited_time_offers &&
        tabsCounts?.always_available
      ) {
        setSelectedTab(1);
      }
    }
  }, [isLoading, tabType, tabsCounts]);

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        setTabType("limited_time_offers");
        break;
      case 1:
        setTabType("always_available");
        break;
      case 2:
        setTabType("draft");
        break;
      default:
        setTabType("limited_time_offers");
    }
  }, [selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCurrentPage(1);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const renderPage = () => {
    return (
      <>
        <div className="dashboard participant-dashboard">
          <CampaignAvailableToJoin
            selectedBrand={selectedBrand}
            isThereAnyActiveSubscription={
              launchCampaignData?.isThereAnyActiveSubscription
            }
            getCampaigns={launchCampaignData}
            tabsCounts={tabsCounts}
            totalRecordsData={totalRecordsData}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
            pageCount={pageCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <PageLayout className="participant-dashboard-container mx-0 mx-sm-2">
        {renderPage()}
      </PageLayout>
    </>
  );
};

export default LaunchPostcardCampaigns;
