import React, { useMemo, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export default function FormikMUIAutocompleteSelectInput({
  label,
  placeholder = "",
  className = "",
  options = [],
  size = "small",
  variant = "outlined",
  multiple = false,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) {
  const [inputValue, setInputValue] = useState("");

  const selectedValue = useMemo(() => {
    if (multiple) {
      return options?.filter((o) => field.value?.includes(o.value)) || [];
    }

    return options?.find((o) => o.value === field.value) || "";
  }, [field.value, options, multiple]);

  const err =
    touched[field.name] && errors[field.name] ? errors[field.name] : "";

  return (
    <Autocomplete
      className={className}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      getOptionLabel={(option) => {
        return option?.label || "";
      }}
      disableClearable
      openOnFocus
      autoHighlight
      autoComplete
      size={size}
      {...rest}
      multiple={multiple}
      onBlur={field.onBlur}
      id={field.name}
      name={field.name}
      value={selectedValue}
      onChange={(event, newValue) => {
        if (multiple) {
          if (!newValue) {
            setFieldValue(field.name, []);
            return;
          } else if (Array.isArray(newValue)) {
            setFieldValue(
              field.name,
              newValue.map((o) => o.value)
            );
            return;
          }
        } else {
          if (!newValue) {
            setFieldValue(field.name, "");
            return;
          } else if (typeof newValue === "object") {
            setFieldValue(field.name, newValue.value);
            return;
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          margin="dense"
          helperText={err}
          error={err ? true : false}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
