import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useBlockLayout,
  useSortBy,
} from "react-table";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";

import NoDataFound from "../NoDataFound";
import TableGlobalFilter from "./TableGlobalFilter";
import TablePageSize from "./TablePageSize";
import TablePagination from "./TablePagination";
import DataRangeDisplay from "../DataRangeDisplay";

export default function TableServer({
  columns = [],
  data = [],
  initialPageSize = 10,
  pageCount,
  isLoading,
  filters = null,
  showPageSize = false,
  showPagination = true,
  fetchData,
  useLayout = false,
  initialSortedBy = [],
  hideSearchLabel = false,
  totalRecords,
  showSearch = false,
  isSubscriptionHistory = false,
  initialPage = 0,
  containerClass = "",
}) {
  const [selectedFilters, setSelectedFilters] = useState({});

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: initialPage,
        pageSize: initialPageSize,
        sortBy: initialSortedBy,
      },
      manualPagination: true,
      pageCount,
      manualGlobalFilter: true,
      manualSortBy: true,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useLayout && useBlockLayout
  );

  // Listen for changes in pagination and use the state to fetch our new data

  useEffect(() => {
    // reset page on change filter
    gotoPage(0);
  }, [pageSize, state.globalFilter, selectedFilters, gotoPage]);

  useEffect(() => {
    if (fetchData && typeof fetchData === "function") {
      let sortParams;
      let sortByDir;
      if (sortBy.length > 0) {
        sortParams = sortBy[0];
        sortByDir = sortParams.desc ? "desc" : "asc";
      }

      fetchData({
        pageIndex: pageIndex,
        pageSize,
        searchKey: state.globalFilter,
        filters: selectedFilters,
        sortBy: sortParams?.id || "",
        sortByDir: sortByDir || "",
      });
    }
  }, [
    pageIndex,
    pageSize,
    state.globalFilter,
    selectedFilters,
    fetchData,
    sortBy,
  ]);

  const handlePageChange = (event, value) => {
    gotoPage(value - 1);
  };

  const handleOnChangeFilter = (filterKey, filterValue) => {
    if (filterKey) {
      setSelectedFilters({ ...selectedFilters, [filterKey]: filterValue });
    }
  };

  const getHederTitle = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? `Sorted DESC` : `Sorted ASC`;
    }

    return "";
  };

  const noOfSpan = useMemo(() => {
    const { values } = page;
    return Object.keys(values)?.length || 100;
  }, [page]);

  return (
    <div className="rp-react-table table-server">
      {showSearch && (
        <div
          className={`${
            isSubscriptionHistory
              ? "justify-content-between"
              : "justify-content-end"
          } row my-1 table-search align-items-center`}
        >
          {filters && filters(handleOnChangeFilter, selectedFilters)}
          <div
            className={`${filters ? "col col-lg-3 col-xl-3" : "col-md-4 "} ${
              isSubscriptionHistory ? "col col-lg-4 col-xl-4 mb-3" : ""
            } mb-3`}
          >
            <TableGlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search.."
              debounceTime={500}
              hideSearchLabel={hideSearchLabel}
            />
          </div>
        </div>
      )}

      <div className={`table-responsive ${containerClass}`}>
        <table {...getTableProps()} className="table table-borderless">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        title: getHederTitle(column),
                      })
                    )}
                  >
                    {column.render("Header")}
                    <div
                      style={{
                        position: "absolute",
                        right: 2,
                        top: 0,
                        bottom: 0,
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      {column.canSort ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            height: "100%",
                          }}
                        >
                          <ArrowUpward
                            style={{ fontSize: 9 }}
                            color={
                              column.isSorted && !column.isSortedDesc
                                ? "inherit"
                                : "disabled"
                            }
                          />
                          <ArrowDownward
                            style={{ fontSize: 9 }}
                            color={
                              column.isSorted && column.isSortedDesc
                                ? "inherit"
                                : "disabled"
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading ? (
              <tr>
                <td colSpan={noOfSpan}>
                  <NoDataFound
                    message="Loading..."
                    height={page?.length ? page?.length * 50 : "auto"}
                  />
                </td>
              </tr>
            ) : page && page.length ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="42">
                  <NoDataFound height="auto" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="mt-1">
        <div className="row align-items-center">
          {showPageSize === true && (
            <div className="col-1">
              <TablePageSize pageSize={pageSize} setPageSize={setPageSize} />
            </div>
          )}
          <div className="col-md-6 mb-1 ml-1">
            <DataRangeDisplay
              pageIndex={pageIndex}
              totalRecords={totalRecords}
            />
          </div>
          {showPagination === true && pageCount > 0 && (
            <div className="col d-flex justify-content-end">
              <TablePagination
                count={pageCount}
                page={pageIndex + 1}
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
