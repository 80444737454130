import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { brandService } from "../../services/brandService";
import TableServer from "../../components/tables/TableServer";
import { makeFirstCharCap } from "../../helpers/utils";
import RpImageTextInline from "../../components/RpImageTextInline";
import useFetcher from "../../helpers/hooks/useFetcher";
import { MUICustomAutocompleteSelect } from "../../components/muiCustom/select/MUICustomAutocompleteSelect";
import { LoaderContext } from "../../helpers/contexts/contexts";

const LocationList = ({ selectedBrand, loggedInUser }) => {
  const loader = useContext(LoaderContext);
  const [filters, setFilters] = useState({
    pageSize: 10,
    pageIndex: 0,
    searchKey: "",
    sortBy: "",
    sortByDir: "",
    groupId: "",
  });
  const [groupList, setGroupList] = useState([]);

  const reqData = useMemo(() => {
    return {
      id: selectedBrand?.id,
      userId: loggedInUser?.id,
      row: filters?.pageSize,
      page: filters?.pageIndex + 1,
      searchKey: filters?.searchKey || "",
      sortBy: filters?.sortBy || "",
      sortByDir: filters?.sortByDir || "",
      groupId: filters?.groupId || "",
    };
  }, [selectedBrand?.id, loggedInUser?.id, filters]);

  const { data, isLoading } = useFetcher(
    reqData,
    brandService.getBrandLocations,
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    loader(isLoading);
  }, [isLoading, loader]);

  const brandLocationsData = data?.result || [];
  const pageCount = data?.pageCount || 0;
  const recordsTotal = data?.recordsTotal || 0;

  useEffect(() => {
    if (data && data?.groupResult) {
      const groupList = data?.groupResult?.map((c) => ({
        label: c.group_name,
        value: c.id,
      }));
      setGroupList(groupList);
    }
  }, [data]);

  const handleFetchData = useCallback((data) => {
    const {
      pageIndex,
      pageSize,
      searchKey,
      filters: fetchFilters,
      sortBy,
      sortByDir,
    } = data;

    const reqData = {
      ...fetchFilters,
      pageSize: pageSize,
      pageIndex: pageIndex,
      searchKey: searchKey || "",
      sortBy,
      sortByDir,
      groupId: fetchFilters?.groupId || "",
    };

    setFilters((f) => ({ ...f, ...reqData }));
  }, []);

  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Location Name",
        accessor: "location_name",
        id: "location_name",
        width: 261,
        Cell: ({
          row: {
            original: {
              location_name,
              system_generated_location_id,
              fk_participant_id,
            },
          },
        }) => {
          return (
            <Link
              to={`/dashboard/location-report/${system_generated_location_id}/${fk_participant_id}`}
              className="link text-break"
            >
              <RpImageTextInline
                image="/img/icons/location.png"
                text={makeFirstCharCap(location_name)}
              />
            </Link>
          );
        },
      },
      {
        Header: "Participant Name",
        accessor: "participants_name",
        id: "participant_name",
        width: 215,
        Cell: ({
          row: {
            original: { participants_name },
          },
        }) => {
          return <span>{participants_name ? participants_name : "N/A"}</span>;
        },
      },
    ];

    if (selectedBrand.id !== 1) {
      // if not Morgan Stanley
      columns.push({
        Header: () => <span className="text-center w-100">Live Campaigns</span>,
        accessor: "getTotalCampaignActive",
        Cell: ({ row: { original } }) => {
          return (
            <div className="text-center">
              <RpImageTextInline
                image={
                  Number(original.getTotalCampaignActive)
                    ? "/img/icons/campaign.png"
                    : "/img/icons/zero_campaign.png"
                }
                text={original.getTotalCampaignActive}
              />
            </div>
          );
        },
      });
      columns.push({
        Header: () => (
          <span className="text-center w-100">Total Campaigns</span>
        ),
        accessor: "getTotalCampaign",
        Cell: ({ row: { original } }) => {
          return <div className="text-left">{original.getTotalCampaign}</div>;
        },
      });
    }
    return columns;
  }, [selectedBrand]);

  const groupFilter = (setFilter, selectedValues) => {
    return (
      <div className="col-sm col-lg-3 col-xl-3 mb-3">
        <MUICustomAutocompleteSelect
          options={groupList}
          selectProps={{
            onChange: (v) => {
              setFilter("groupId", v);
            },
            value: selectedValues?.groupId,
          }}
          name="location-group"
          label="Groups"
          all
        />
      </div>
    );
  };

  return (
    <div className="location-list">
      <TableServer
        columns={columns}
        data={brandLocationsData}
        showSearch={true}
        fetchData={handleFetchData}
        isLoading={isLoading}
        pageCount={pageCount}
        filters={groupFilter}
        useLayout={false}
        hideSearchLabel={false}
        totalRecords={recordsTotal}
      />
    </div>
  );
};

export default LocationList;
