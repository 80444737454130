import { useMemo } from "react";
import useSWR from "swr";

function useFetcher(key, fetcherFn, options = {}) {
  const fetcher = useMemo(() => {
    return () => fetcherFn(key, true);
  }, [fetcherFn, key]);

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    key,
    fetcher,
    options
  );
  return { data, error, isLoading, isValidating, mutate };
}

export default useFetcher;
