import React, { useEffect, useState, useContext } from "react";
import MuiSwitchComponent from "../../../../../components/muiCustom/buttons/MuiSwitchComponent";
import MailingType from "./MailingType";
import TargetIdeal from "./TargetIdeal";
import TotalCoast from "./TotalCoast";
import UploadAddressList from "./UploadAddressList";
import { MAILING_TYPES } from "../../../../../config/constants";
import { GlobalContext } from "../../../../../helpers/contexts/contexts";

function MailingPostcards({
  isMailingInit,
  onMailing,
  onChangeQnt,
  mailingQnt,
  mailingCost,
  mailingType,
  onChangeMailType,
  campaign,
  loc,
  onUpload,
  initialDisabled,
}) {
  const [isMailing, setIsMailing] = useState(isMailingInit);
  const { selectedBrand } = useContext(GlobalContext);

  useEffect(() => {
    if (onMailing && typeof onMailing === "function") {
      onMailing(isMailing);
    }
  }, [isMailing]);

  const handleSwitchChange = () => {
    setIsMailing((p) => !p);
  };

  return (
    <div className="card-footer bg-white mailing-postcards">
      <div className="card border-0">
        <div className="card-body px-0 py-0">
          <div className="container-fluid p-0">
            <div className="row align-item-stretch">
              <div className="col-lg-2 col-md-3 p-0 d-flex pt-lg-2 align-items-start">
                <MuiSwitchComponent
                  isSwitchChecked={isMailing}
                  handleSwitchChange={handleSwitchChange}
                  label="Mail Postcards (Y/N)"
                  initialDisabled={initialDisabled}
                />
              </div>
              <div className="col-lg-6 col-md-9 py-2 px-0">
                <div className="row">
                  <div className="col-12 mb-2">
                    <MailingType
                      onChange={onChangeMailType}
                      selected={mailingType}
                      isMailing={isMailing}
                      selectedBrand={selectedBrand}
                    />
                  </div>
                  {mailingType === MAILING_TYPES.S && isMailing && (
                    <div className="col-12 d-flex justify-content-end">
                      <TargetIdeal
                        mailingQnt={mailingQnt}
                        onChange={onChangeQnt}
                      />
                    </div>
                  )}

                  {mailingType === MAILING_TYPES.T && isMailing && (
                    <div className="col-12 d-flex justify-content-end ">
                      <UploadAddressList
                        campaign={campaign}
                        loc={loc}
                        onUpload={onUpload}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mailing-cost">
                <TotalCoast total={mailingCost} label="Mailing Cost" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MailingPostcards;
